import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserRoles} from '../../../../shared/models/userRoles';
import {Subject} from 'rxjs';
import {DeviceCommandType} from '../../../../shared/models/device-command/deviceCommandType';
import {Router} from '@angular/router';
import {AdministratorApiService} from '../../../../shared/services/administrator-api.service';
import {ApiService} from '../../../../shared/services/api.service';
import {PassDataService} from '../../../../shared/services/pass-data/pass-data.service';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {
  ConfirmationDialogComponent
} from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  DeviceCommandTypeDialogComponent
} from '../../../../shared/dialogs/device-command-type-dialog/device-command-type-dialog.component';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-device-command-type',
  templateUrl: './device-command-type.component.html',
  styleUrls: ['./device-command-type.component.scss']
})
export class DeviceCommandTypeComponent implements OnInit, OnDestroy {

  deviceCommandTypeColumns = ['Name', 'Detail'];
  public deviceCommandTypes: DeviceCommandType[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: DeviceCommandType[] = [];
  public isAdmin = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(private router: Router,
              private administratorService: AdministratorApiService,
              private passDataService: PassDataService,
              private mainService: MainSubscriptionsService,
              public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this._initList();
    });
  }

  public addDeviceCommandType(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const addDeviceCommandTypeDialogRef = this.dialog.open(DeviceCommandTypeDialogComponent);

      addDeviceCommandTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newDeviceCommandType => {
        if (newDeviceCommandType) {
          const addDeviceCommandTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          addDeviceCommandTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.administratorService.addDeviceCommandType(newDeviceCommandType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            }
          });
        }
      });
    }
  }

  public deviceCommandTypeDetail(deviceCommandType: DeviceCommandType): void {
    if (!deviceCommandType || deviceCommandType === undefined) {
      return;
    }

    const updateDialogRef = this.dialog.open(DeviceCommandTypeDialogComponent, {
      data: {
        deviceCommandType: deviceCommandType
      },
      width: '50%',
      maxWidth: 500
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorService.updateDeviceCommandType(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initList();
              }
            });
          }
        });
      }
    });
  }

  public deviceCommandTypeDelete(deviceCommandType: DeviceCommandType): void {
    if (!deviceCommandType || deviceCommandType === undefined) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const deleteDeviceCommandTypeDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteDeviceCommandTypeDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorService.deleteDeviceCommandType(deviceCommandType.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  private _initList(): void {
    this.administratorService.listDeviceCommandType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.deviceCommandTypes = x;
        this.filteredData = this.deviceCommandTypes;
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceCommandTypes?.filter((key: DeviceCommandType) =>
      [key.Name?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
