<div [ngClass]="[nested ? 'panelBG' : '', compressed ? 'p-0' : 'container-fluid py-3']">
    <span class="title subheadText d-block">{{ title | translate }}{{ subtitle ? ' - ' + (subtitle | translate) : '' }}</span>
    <div class="d-flex" [ngClass]="[compressed ? 'compressedLayout' : 'defaultLayout', sheer ? 'panelBG' : '' , (sheer && !nested) ? 'parent' : '']">
        <div class="chartInfo">
            <div class="d-flex align-items-start">
                <p>{{ colorLegend.MinAmount | translate }}</p>
                <div class="amountCircle2 ml-3"></div>
            </div>
            <div class="d-flex align-items-start">
                <p>{{ colorLegend.MaxAmount | translate }}</p>
                <div class="amountCircle ml-3"></div>
            </div>
        </div>
        <div class="flex-grow-1">
            <div #chartContainer class="bubbleChartContainer" [ngClass]="{'bordered': sheer}">
                <div [id]="chartDivId" class="bubbleChart"></div>
                <p *ngIf="noDataOnChart">{{ 'STATISTICS.NO_CHART_DATA' | translate }}</p>
            </div>
        </div>
    </div>
</div>
