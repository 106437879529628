<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">
        <mat-card class="d-flex flex-row p-0 m-4">

            <div class="loginExtraSection p-4">

                <h6>{{ 'LOGIN.EXTRA_TITLE' | translate }}</h6>
                <p class="darkText">{{ 'LOGIN.EXTRA_SUBTITLE' | translate }}</p>
                <!-- <img mat-card-image [src]="'/assets/img/backgroundTitleTransparent.png'"> -->
            </div>

            <div class="d-flex flex-column loginMainSection p-4">
                <mat-card-title>{{ 'LOGIN.TITLE' | translate }}</mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (ngSubmit)="submit()">

                            <mat-form-field>
                                <mat-label>{{ 'LOGIN.USERNAME' | translate }}</mat-label>
                                <input type="text" matInput (keydown)="deleteError()" formControlName="username" autocomplete="off">
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
                                <input [type]="hide ? 'password' : 'text'" matInput
                                    formControlName="password"
                                    (keydown)="deleteError()" autocomplete="off">
                                <i class="passwordIcon" matSuffix [ngClass]="hide ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" (click)="hide = !hide"></i>
                            </mat-form-field>

                        <mat-error *ngIf="error && error !== undefined && error !== ''" class="error">
                            {{ error }}
                        </mat-error>

                        <div class="custom-button d-flex flex-column justify-content-center">
                            <a routerLink="../forgot-password" class="text-right pb-2">{{ 'LOGIN.FORGOT' | translate }}</a>
                            <button type="submit" mat-raised-button>{{ 'LOGIN.LOGIN' | translate }}</button>
                        </div>
                    </form>
                </mat-card-content>
            </div>


        </mat-card>

        <br>
    </div>
</div>
