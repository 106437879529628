import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserRoles} from "../../../../shared/models/userRoles";
import {Subject} from "rxjs";
import {PaginationInstance} from "ngx-pagination";
import {Router} from "@angular/router";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {ScheduleRule} from "../../../../shared/models/content-management/scheduleRule";
import {
  ScheduleRuleDialogComponent
} from "../../../../shared/dialogs/schedule-rule-dialog/schedule-rule-dialog.component";

@Component({
  selector: 'urban-schedule-rules',
  templateUrl: './schedule-rules.component.html',
  styleUrls: ['./schedule-rules.component.scss']
})
export class ScheduleRulesComponent implements OnInit, OnDestroy {

  rulesColumns = ['Name', 'Detail'];
  public rules: ScheduleRule[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: ScheduleRule[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private contentManagementService: ContentManagementService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
      this._initList();
    });
  }

  public add(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const addDialogRef = this.dialog.open(ScheduleRuleDialogComponent);

      addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newRule => {
        if (newRule) {
          const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.contentManagementService.addScheduleRule(newRule).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            }
          });
        }
      });
    }
  }

  private _initList(): void {
    this.contentManagementService.listScheduleRule().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.rules = x;
        this.filteredData = this.rules;
      }
    });
  }

  public detail(rule: ScheduleRule): void {
    if (!rule) {
      return;
    }
    const updateDialogRef = this.dialog.open(ScheduleRuleDialogComponent, {
      data: {
        scheduleRule: rule
      },
      width: '50%',
      maxWidth: 500
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.contentManagementService.updateScheduleRule(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initList()
              }
            });
          }
        });
      }
    });
  }

  public delete(rule: ScheduleRule): void {
    if (!rule) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.contentManagementService.deleteScheduleRule(rule.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.rules?.filter((key: ScheduleRule) =>
      [key.Name?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
