import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Domain } from '../../models/domain';

@Component({
  selector: 'urban-set-domain-dialog',
  templateUrl: './set-domain-dialog.component.html',
  styleUrls: ['./set-domain-dialog.component.scss']
})
export class SetDomainDialogComponent implements OnInit {
  public domainChoosed: Domain | null = null;

  constructor(
    public dialogRef: MatDialogRef<SetDomainDialogComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { domains: Domain[] }) {
  }

  ngOnInit(): void {
  }

  public setDomain(domain: Domain): void {
    this.domainChoosed = domain;
  }

  public continue(): void {
    this.dialogRef.close(this.domainChoosed);
  }

  cancel() {
    this.dialogRef.close();
  }
}
