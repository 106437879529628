import {takeUntil} from 'rxjs/operators';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import {SearchDates} from 'src/app/shared/models/searchDates';
import {ApiService} from 'src/app/shared/services/api.service';
import {LoaderService} from 'src/app/shared/services/loader/loader.service';
import {PassDataService} from 'src/app/shared/services/pass-data/pass-data.service';
import {
  DeviceByModel,
  DeviceByModelResponse,
  DeviceEventByModelPassage
} from 'src/app/shared/models/deviceEventByModel';
import {ChartDataDescription, ChartMultiDataList} from 'src/app/shared/models/ChartDataList';

@Component({
  selector: 'urban-people-passage',
  templateUrl: './people-passage.component.html',
  styleUrls: ['./people-passage.component.scss']
})
export class PeoplePassageComponent implements OnInit, OnDestroy {
  private events: DeviceByModel[];
  public eventsMapped: DeviceEventByModelPassage[];
  public totalPeoplePassages: ChartMultiDataList;
  public allCamerasPassage: Record<string, number>;
  public dataDescriptions: ChartDataDescription[] = [];
  public legendKeys: string[] = ['In', 'Out', 'Total'];
  public legendColors: Record<string, string>;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  private isDarkActive: boolean;
  public translationsReady: boolean = false;
  private translateSubscription: Subscription = new Subscription();
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private startDate: Date;
  private endDate: Date;

  constructor(
    private apiService: ApiService,
    private passDataService: PassDataService,
    private loader: LoaderService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.isDarkActive = res === true;

        this.legendColors = this.getLegendColors();
      });

    this.loader.disable();

    this.setDataDescriptions();
    this.loadData();
  }

  private setDataDescriptions(): void {
    this.translateSubscription.unsubscribe();
    let prefix: string = 'IMX_CAMERA_WIDGET.';
    let phrases: string[] = ['In', 'Out', 'Total'];

    this.getTranslations(phrases, prefix);
    this.translateSubscription = this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, prefix);
    });
  }

  private getTranslations(phrases: string[], prefix: string): void {
    this.translate.get(phrases.map((phrase: string) => prefix + phrase.toUpperCase())).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.dataDescriptions = [];
      phrases.forEach((phrase: string) => {
        let translation: string = res[prefix + phrase.toUpperCase()];
        let dataDescription: ChartDataDescription = {
          Original: phrase,
          Translated: translation !== (prefix + phrase.toUpperCase()) ? translation : phrase
        }
        this.dataDescriptions.push(dataDescription);
      });
      this.translationsReady = true;
    });
  }

  private loadData(): void {
    let startDate: number, endDate: number;
    let now: Date = new Date();
    endDate = Math.round(now.getTime() / 1000);
    now.setDate(now.getDate() - 1);
    startDate = Math.round(now.getTime() / 1000);

    let selectedDates: SearchDates = {
      startDate,
      endDate,
      last24hSearch: true
    };

    this.startDate = new Date(startDate * 1000);
    this.endDate = new Date(endDate * 1000);

    this.newSearch(selectedDates);
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;

    this.apiService.getDeviceEventsByModelAndDatetime('IMX500', selectedDates.startDate, selectedDates.endDate)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceByModelResponse) => {
      this.resetAllData();

      if (res?.Items) {
        this.events = res.Items;
      }
      if (this.events.length > 0) {
        this.setData();
      }

      this.loadingData = false;
    });
  }

  downloadSelection() {
    const header = ['Camera', 'In', 'Out', 'Total'];

    let totalIn : number = 0;
    let totalOut : number = 0;
    let total : number = 0;

    const csv = Object.entries(this.totalPeoplePassages).map((row) =>
      header
        .map((fieldName) => {
          switch (fieldName) {
            case 'Camera':
              return row[0];
            case 'In':
              // @ts-ignore
              totalIn += row[1].In;
              return row[1].In.toString();
            case 'Out':
              // @ts-ignore
              totalOut += row[1].Out;
              return row[1].Out.toString();
            case 'Total':
            default:
              // @ts-ignore
              total += row[1].Total;
              return row[1].Total.toString();
          }
        })
        .join(',')
    );
    csv.push(`Total,${totalIn},${totalOut},${total}`)
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `IMX-export-${this.startDate.toString()}-${this.endDate.toString()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public hasElements(): boolean {
    return this.totalPeoplePassages && Object.keys(this.totalPeoplePassages)?.length > 0;
  }

  private resetAllData(): void {
    this.events = [];
    this.eventsMapped = [];
  }

  private setData(): void {
    this.eventsMapped = this.mapResponse(this.events);
    this.totalPeoplePassages = this.getTotalPeoplePassage(this.eventsMapped);
    this.allCamerasPassage = this.getAllCamerasPassage(this.eventsMapped);
  }

  private mapResponse(events: DeviceByModel[]): DeviceEventByModelPassage[] {
    let eventsMapped: DeviceEventByModelPassage[] = [];

    for (const event of events) {
      let eventMapped = eventsMapped.find(em => em.Id === event.Id);

      if (!eventMapped) {
        eventMapped = {
          Id: event.Id,
          Name: event.Name,
          Aliases: event.Aliases?.join(';'),
          In: event.Events.reduce((curr, next) => {
            return +curr + (+next.Body.in)
          }, 0),
          Out: event.Events.reduce((curr, next) => {
            return +curr + (+next.Body.out)
          }, 0)
        }

        eventsMapped.push(eventMapped);
      }
    }

    return eventsMapped;
  }

  private getTotalPeoplePassage(passageEvents: DeviceEventByModelPassage[]): ChartMultiDataList {
    let totalPeoplePassage: ChartMultiDataList = {};

    passageEvents.forEach(passageEvent => {
      totalPeoplePassage[passageEvent.Name] = {
        In: passageEvent.In,
        Out: passageEvent.Out,
        Total: passageEvent.In - passageEvent.Out
      };
    });

    return totalPeoplePassage;
  }

  private getAllCamerasPassage(passageEvents: DeviceEventByModelPassage[]): Record<string, number> {
    let allCamerasPassage: Record<string, number> = {
      In: 0,
      Out: 0
    };

    passageEvents.forEach(passageEvent => {
      allCamerasPassage.In += passageEvent.In;
      allCamerasPassage.Out += passageEvent.Out;
    });
    allCamerasPassage.Total = allCamerasPassage.In - allCamerasPassage.Out;

    return allCamerasPassage;
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  private getLegendColors(): Record<string, string> {
    let colors: Record<string, string> = {};

    colors['In'] = this.getColor(this.isDarkActive ? 'dark' : 'light', 'primary');
    colors['Out'] = window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color');
    colors['Total'] = '#5352e4';

    return colors;
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.loader.enable();
  }
}
