<div class="goToPageBtn">
    <button mat-button class="btn outlineActionButton" (click)="goToCrosswalkDashboard()">
        {{ 'GENERAL.GO_TO_PAGE' | translate }}
    </button>
</div>

<div class="position-relative mt-2" [ngClass]="dataReady && translationsReady ? 'finalHeight' : 'initialHeight'">
    <div *ngIf="dataReady && translationsReady" class="appearing">
        <urban-polar-area-chart [passedData]="totalCrossingPerColor" [darkModeStatus]="isDarkActive" [dataDescriptions]="dataDescriptions"></urban-polar-area-chart>
    
        <p class="paragraphText text-right mr-3 secondaryTextColor">{{ 'GENERAL.LAST_24H' | translate }}</p>
    </div>

    <div *ngIf="noDataOnChart" class="d-flex align-items-center justify-content-center h-100">
        <p class="paragraphText">{{ 'GENERAL.NO_DATA_IN_24H' | translate }}</p>
    </div>

    <urban-loader [localized]="true" [disappearCondition]="(dataReady && translationsReady) || noDataOnChart"></urban-loader>
</div>
