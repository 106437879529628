import { Observable, of } from 'rxjs';
import { News } from './../models/news';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from './loader/loader.service';
import { Store } from '@ngrx/store';
import { MainState } from '../../store/main/main.reducer';
import * as MainActions from '../../store/main/main.actions';
@Injectable({
  providedIn: 'root'
})
export class NewsApiService {

  constructor(private http: HttpClient, private readonly loaderService: LoaderService, private store: Store<MainState>) { }


  public getAllNews(): Observable<News[]> {
    return this.http.get<any>(`uv.news.list.api`).pipe(
      map(res => res.News),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addNews(request: News) {
    return this.http.post<News[]>(
      `uv.news.add.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }
  public updateNews(request: News) {
    return this.http.post<News>(
      `uv.news.update.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }
  public deleteNews(id: string): Observable<any> {
    return this.http.get<any>(`uv.news.delete.api?id=${id}`).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getNews(newsId: string): Observable<News> {
    return this.http.get<any>(`uv.news.get.api?id=${newsId}`).pipe(
      map(res => res.News),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }
}

