<h1 mat-dialog-title>{{this.deviceGroup}}</h1>
<mat-dialog-content>
    <p>
        {{ 'DEVICE_GROUP_DETAIL.SUBTITLE' | translate }}
    </p>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="go()">{{ 'GENERAL.GO'| translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>

