import { PaginationInstance } from 'ngx-pagination';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceBrandDialogComponent } from '../../../../shared/dialogs/device-brand-dialog/device-brand-dialog.component';
import { DeviceBrand } from '../../../../shared/models/deviceBrand';
import { UserRoles } from '../../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { ApiService } from '../../../../shared/services/api.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-device-brands',
  templateUrl: './device-brands.component.html',
  styleUrls: ['./device-brands.component.scss']
})
export class DeviceBrandsComponent implements OnInit, OnDestroy {

  brandColumns = ['Name', 'Detail'];
  public brands: DeviceBrand[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: DeviceBrand[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private administratorService: AdministratorApiService,
    private apiService: ApiService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
      this.apiService.getDevicesBrands().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
        if (x) {
          this.brands = x;
          this.filteredData = this.brands;
        }
      });
    });
  }

  public addBrand(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const addBrandDialogRef = this.dialog.open(DeviceBrandDialogComponent);

      addBrandDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newBrand => {
        if (newBrand) {
          const addBrandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          addBrandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.administratorService.addDeviceBrand(newBrand.Name).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this.apiService.getDevicesBrands().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                    if (x) {
                      this.brands = x;
                      this.filteredData = this.brands;
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  public brandDetail(brand: DeviceBrand): void {
    if (!brand || brand === undefined) {
      return;
    }
    this.mainService.setNavigationInfoComand({ Id: brand.Id, BackRoute: 'device-brands' });
    this.router.navigate(['main/device-brand']);
  }

  public brandDelete(brand: DeviceBrand): void {
    if (!brand || brand === undefined) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const deleteBrandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteBrandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorService.deleteDeviceBrand(brand.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this.apiService.getDevicesBrands().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this.brands = x;
                  this.filteredData = this.brands;
                }
              });
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.brands?.filter((setting: DeviceBrand) =>
      [setting.Name?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
