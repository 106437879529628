import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Subscription, timer } from 'rxjs';
import { DeviceEventLatestRequest } from '../../models/deviceEventLatest';
import { DeviceEventTrashBin } from '../../models/deviceEventTrashBin';
import { GoogleMarker } from '../../models/googleMarker';

@Component({
  selector: 'urban-heatmap-trash-bin-maps',
  templateUrl: './heatmap-trash-bin-maps.component.html',
  styleUrls: ['./heatmap-trash-bin-maps.component.scss']
})
export class HeatmapTrashBinMapsComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  public heatmap: google.maps.visualization.HeatmapLayer;
  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public mapZoom: number;
  private timerSubscription: Subscription = new Subscription();
  private firstTimeZoomed: boolean = false;
  public gradient: string[] = [
    "rgba(0, 255, 255, 0)",
    "#69D27D",
    "#E7CD77",
    "#EB565D"
  ]
  @Input('trashBinElements') public passedTrashBinElements: DeviceEventTrashBin[];
  @Input('deviceId') public deviceId : string;
  @Input('maxValueHeatmap') public heatmapMaxValue: number;
  @Input('passedLastUpdate') public passedLastUpdate: number;
  @Input('passedMarkers') public markers: GoogleMarker[] = [];
  @Output('mapDragEnd') private mapDragEnd: EventEmitter<DeviceEventLatestRequest> = new EventEmitter<DeviceEventLatestRequest>();
  @Input('darkModeStatus') public isDarkActive: boolean;

  constructor() { }

  ngOnInit(): void {
    this.mapOptions = {
      mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
      gestureHandling: "cooperative",
      mapTypeId: "roadmap"
    } as google.maps.MapOptions
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  public initMap(): void {
    this.setHeatmap();
    this.setBounds();

    timer(0).subscribe(() => {
      this.map.fitBounds(this.mapBounds);
    });
  }

  public reInitMap(): void {
    this.map.googleMap = new google.maps.Map(this.map.googleMap.getDiv(), this.mapOptions);
    
    this.setHeatmap();
    this.setBounds();

    timer(0).subscribe(() => {
      this.map.fitBounds(this.mapBounds, 0);
    });
  }

  public setHeatmap(): void {
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getPoints(),
      maxIntensity: this.heatmapMaxValue,
      map: this.map.googleMap,
      radius: 70,
      gradient: this.gradient
    });
  }

  private setBounds(): void {
    if (this.mapBounds === undefined) {
      this.mapBounds = new google.maps.LatLngBounds();
      if (this.mapBounds && this.passedTrashBinElements != null && this.passedTrashBinElements.length !== undefined) {
        for (let i = 0; i < this.passedTrashBinElements.length; i++) {
          this.mapBounds.extend(new google.maps.LatLng(this.passedTrashBinElements[i].Device?.Latitude, this.passedTrashBinElements[i].Device?.Longitude));
        }
      }
    }
  }

  public getPoints() {
    let pointsToShow: google.maps.visualization.WeightedLocation[] = [];
    for (let element of this.passedTrashBinElements) {
      pointsToShow.push({
        location: new google.maps.LatLng(element.Device?.Latitude, element.Device?.Longitude),
        weight: element.Percentage
      });
    }
    return pointsToShow;
  }

  onViewChanged(): void {
    // this.timerSubscription = timer(2000).subscribe(() => {
    //   let position: google.maps.LatLng = this.map.getCenter();
    //   let bounds: google.maps.LatLngBounds = this.map.getBounds();
    //   let latRange: number = Math.abs(bounds.getNorthEast().lat() - position.lat());
    //   let lngRange: number = Math.abs(bounds.getNorthEast().lng() - position.lng());
    //   let range: number = Math.sqrt(Math.pow(latRange, 2) + Math.pow(lngRange, 2));
    //   range = (range * 111139) / 1000;
    //   let request : DeviceEventLatestRequest = {
    //     Latitude: position.lat(),
    //     Longitude : position.lng()
    //   }

    //   if(this.deviceId){
    //     request.DeviceId = this.deviceId;
    //   }
    //   else{
    //     request.DeviceType = 'trash-bin';
    //   }

    //   this.mapDragEnd.emit(request);
    //   this.timerSubscription.unsubscribe();
    // });
  }

  onMapDragStart(): void {
    // this.timerSubscription.unsubscribe();
  }

  onZoomChanged(): void {
    // if (this.firstTimeZoomed) {
    //   this.timerSubscription.unsubscribe();
    //   this.onViewChanged();
    // }
    // else {
    //   this.firstTimeZoomed = true;
    // }
  }

  openInfoWindow(marker: MapMarker, windowIndex: number, info: string) {
    if (info.startsWith('Location:')) {
      return
    }
    else {
      /// stores the current index in forEach
      let curIdx = 0;
      this.infoWindowsView.forEach((window: MapInfoWindow) => {
        if (windowIndex === curIdx) {
          window.open(marker);
          curIdx++;
        } else {
          curIdx++;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['passedTrashBinElements'] && !changes['passedTrashBinElements'].firstChange) {
      this.setHeatmap();
    }

    if (changes.isDarkActive && changes.isDarkActive.currentValue !== changes.isDarkActive.previousValue && !changes.isDarkActive.firstChange) {
      this.mapZoom = this.map.getZoom();

      this.mapOptions = {
        mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
        gestureHandling: "cooperative",
        mapTypeId: "roadmap",
        zoom: this.mapZoom
      } as google.maps.MapOptions,
      
      this.mapBounds = this.map.getBounds();
      this.reInitMap();
    }
  }
}
