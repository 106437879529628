<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 flex-wrap pageTop">
        <span class="title2">
            {{ 'ADMINISTRATION.PHRASE_MANAGEMENT' | translate }}
        </span>

        <div class="d-flex flex-wrap">
            <button type="button" mat-button class="btn warnButton mr-1" (click)="deleteLanguage()">
                <span class="linkText">{{ 'ADD_LANGUAGE_DIALOG.DELETE_LANGUAGE' | translate }}</span>
            </button>

            <button type="button" mat-button class="btn actionButton" (click)="addNewLanguage()">
                <span class="linkText">{{ 'ADD_LANGUAGE_DIALOG.NEW_LANGUAGE' | translate }}</span>
            </button>
        </div>
    </div>

    <div class="mb-1">
        <form [formGroup]="form">
            <mat-form-field *ngIf="languages?.length > 7">
                <mat-label>{{ 'ADMINISTRATION.SET_LANGUAGE' | translate }}</mat-label>
                <mat-select formControlName="languageSelected" (selectionChange)="updateLanguage()">
                    <mat-option *ngFor="let language of languages" value={{language.Name}}>
                        {{language.Description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="mb-4" *ngIf="languages?.length < 8">
                <span *ngFor="let language of languages" class="mr-4 pointer linkText"
                        [ngClass]="form.controls.languageSelected.value === language.Name ? 'activeUnderline active' : 'secondaryTextColor'"
                        (click)="languageSelectedChange(language.Name)">
                        {{language.Description}}
                </span>
            </div>
        </form>
    </div>

    <div *ngIf="this.languageDiffPhrasesLength > 0 && missingPhraseWarn">
        <urban-alert-panel alertType="warning" [boldPrefix]="languageDiffPhrasesLength" [titleText]="'PHRASE.MISSING_PHRASES' | translate"
            [descriptionText]="'PHRASES.UPDATE_INSTRUCTIONS' | translate" [buttonLabel]="'GENERAL.UPDATE' | translate"
            (buttonAction)="goToMissingPhrases()" (close)="missingPhraseWarn = false">
        </urban-alert-panel>
    </div>

    <div class="customTable">
        <div class="customTableRadius d-flex flex-column px-4 pt-4 flex-wrap">
            <div class="d-flex justify-content-between align-items-center flex-wrap mb-4">
                <span class="title3">{{'PHRASE.TEXT_TRANSLATION' | translate }} - {{'PHRASE.' + currentLanguage?.toUpperCase() | translate }}</span>

                <button mat-button class="btn actionButton" (click)="addPhrase()">
                    {{ 'GENERAL.ADD' | translate }}
                </button>
            </div>

            <div class="d-flex justify-content-between align-items-baseline flex-wrap">
                <mat-form-field class="tableSearch" appearance="outline">
                    <div matPrefix class="prefix">
                        <i class="urbanicon-search"></i>
                    </div>
                    <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event)" autocomplete="off" [(ngModel)]="actualFilter">
                    <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                        (click)="applyFilterString(''); actualFilter = ''"
                        matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                        <i class="urbanicon-close"></i>
                    </button>
                </mat-form-field>

                <mat-form-field class="customSelect p-0" appearance="fill">
                    <mat-select (valueChange)="sortBy($event)" placeholder="{{'GENERAL.SORT_BY' | translate }}">
                        <mat-option value="Key">{{'GENERAL.KEY' | translate }}</mat-option>
                        <mat-option value="Page">{{'GENERAL.PAGE' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="tableHeight" *ngIf="phrases?.length > 0">
            <table class="w-100 h-70 noTableHover" mat-table
                [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Language">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%"> {{ 'PHRASE.LANGUAGE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Language }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Page">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{ 'PHRASE.PAGE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Page }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Key">
                    <th mat-header-cell *matHeaderCellDef> {{ 'PHRASE.KEY' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Key }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef> {{ 'PHRASE.VALUE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Value }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 8%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}" (click)="phraseDetail(element)"
                                class="iconInfoButton urbanicon-info-outline">
                            </i>
                            <i matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="phraseDelete(element)"
                                class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="phraseColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: phraseColumns;"></tr>
            </table>
        </div>

        <div class="m-3 customTableRadius" *ngIf="phrases?.length === 0">
            {{ 'PHRASE.NO_TRANSLATIONS_MESSAGE' | translate}}
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
