import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { AddTaskScheduleDialogComponent } from '../../../shared/dialogs/add-task-schedule-dialog/add-task-schedule-dialog.component';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TaskExecution, TaskSchedule, TaskScheduleExecutionsRequest, TaskScheduleUpdateRequest } from '../../../shared/models/taskScheduleElements';
import { TaskScheduleApiService } from '../../../shared/services/task-schedule-api.service';
import { TranslateService } from '@ngx-translate/core';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-task-schedule-detail',
  templateUrl: './task-schedule-detail.component.html',
  styleUrls: ['./task-schedule-detail.component.scss']
})
export class TaskScheduleDetailComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private passedTaskId: string;
  public passedTask: TaskSchedule;
  public executionsList: TaskExecution[] = [];
  public displayedExecutionsColumns: string[] = ['Created', 'End'];
  public filteredData: TaskExecution[] = [];
  private executionsRequest: TaskScheduleExecutionsRequest = null;
  public readyToShow: boolean = false;
  public isDarkModeActive: boolean;
  public last24hSearch: boolean = true;
  public noDataIn24h: boolean = false;
  public currentLanguage: string;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public isOverlayBG: boolean = true;
  public actualFilter: string = '';
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public weekDays: string[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

  constructor(
    private router: Router,
    private taskScheduleApiService: TaskScheduleApiService,
    private dialog: MatDialog,
    private passDataService: PassDataService,
    private translate: TranslateService,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        this.passedTaskId = navInfo.Id;
      }
      else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.loadUI();
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkModeActive = res == true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });


    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private loadUI(): void {
    this.taskScheduleApiService.getTaskScheduleDetail(this.passedTaskId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resTask => {
      if (resTask) {
        this.passedTask = resTask;
        this.newSearch();
      }
    });
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.readyToShow = false;
    this.loadingData = true;
    this.executionsRequest = {
      Start: selectedDates?.startDate ? selectedDates.startDate : undefined,
      End: selectedDates?.endDate ? selectedDates.endDate : undefined,
      TaskSchedule: this.passedTask.Id,
      Page: 0,
      Limit: 10
    }
    this.taskScheduleApiService.getTaskScheduleExecutionsList(this.executionsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(execs => {
      if (execs) {
        this.executionsList = execs;
        this.filteredData = this.executionsList;

        if (this.executionsList.length == 0) {
          this.noDataIn24h = true;
        }
      }
    });

    if (selectedDates !== null && selectedDates !== undefined) {
      this.last24hSearch = selectedDates.last24hSearch;
    }
    this.readyToShow = true;
    this.loadingData = false;
  }

  public updateTaskSchedule(): void {
    const addTaskDialogRef = this.dialog.open(AddTaskScheduleDialogComponent, {
      data: { targetTaskSchedule: this.passedTask },
      width: '50%'
    });

    addTaskDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newUpdateRequest: TaskScheduleUpdateRequest) => {
      if (newUpdateRequest) {
        const addTaskConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTaskConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.taskScheduleApiService.updateTaskSchedule(newUpdateRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
              if (res) {
                this.loadUI();
              }
            });
          }
        });
      }
    });
  }

  public deleteTaskSchedule(): void {
    const addTaskConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addTaskConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.taskScheduleApiService.deleteDeviceTaskSchedule(this.passedTask.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.mainService.setNavigationInfoComand({ Back: true });
            this.router.navigate(['main/task-schedule']);
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.executionsList?.filter((task: any) =>
      [
        task.Created ? new Date(task.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): '',
        task.End ? new Date(task.End * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): ''
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
