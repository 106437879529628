<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <input matInput formControlName="description" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.START' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="startPicker" (dateChange)="setDate($event.target.value)"
                   formControlName="start"
                   autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startPicker [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.END' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="endPicker" (dateChange)="setDate($event.target.value)"
                   formControlName="end"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endPicker [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ error }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
</div>
