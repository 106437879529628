import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../../../shared/models/loginResponse';
import { Role, UserRoles } from '../../../shared/models/userRoles';
import { ApiService } from '../../../shared/services/api.service';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Domain } from '../../../shared/models/domain';
import { AddUserDomainDialogComponent } from '../../../shared/dialogs/add-user-domain-dialog/add-user-domain-dialog.component';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { takeUntil } from 'rxjs/operators';
import { PhonePrefix } from '../../../shared/models/phonePrefix';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  public isAdmin: boolean = false;
  public isDomainAdmin: boolean = false;
  public displayedColumns = ['Username', 'Email', 'Firstname', 'Lastname', 'Roles', 'Actions'];
  public users: User[] = [];
  public roles: Role[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: User[] = [];
  public currentDomain: Domain;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public prefixesList: PhonePrefix[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public colors: Record<string, string> = {
    'Administrators': this.getRoleColors()[0].trim(),
    'Domain admin': this.getRoleColors()[1].trim(),
    'Translator': this.getRoleColors()[2].trim(),
    'App users': this.getRoleColors()[3].trim(),
    'Camera viewer': this.getRoleColors()[4].trim(),
    'Users': this.getRoleColors()[5].trim(),
    'Other': "gray"
  };

  constructor(
    private apiService: ApiService,
    private router: Router,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
  ) { }


  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;

      if (this.userRoles.some(role => role.Name === 'Administrators')) {
        this.isAdmin = true;
      } else if (this.userRoles.some(role => role.Name === 'Domain admin')) {
        this.isDomainAdmin = true;
      }
    });

    this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.currentDomain = res;
    });

    this.loadData();
  }

  private loadData(): void {
    this.apiService.listUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.users = res;
        this.filteredData = this.users;
      }
    });
  }

  public goToDetail(id: string): void {
    this.mainService.setNavigationInfoComand({ Id: id, BackRoute: 'user-list' });
    this.router.navigate(['main/user-domain-detail']);
  }

  public addUser(): void {
    if (this.isAdmin || this.isDomainAdmin) {
      this.apiService.getPhonePrefixes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(prefixes => {
        this.prefixesList = prefixes;

        this.apiService.roleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          this.roles = res;
          const addUserDialogRef = this.dialog.open(AddUserDomainDialogComponent, {
            data: {
              myPrefixesList: this.prefixesList,
              myDomain: this.currentDomain,
              myRolesList: this.roles
            }
          });

          addUserDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(finalResult => {
            if (finalResult) {
              const addUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
                disableClose: false
              });

              addUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(ok => {
                if (ok) {
                  this.apiService.addUser(finalResult.newUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(newUserId => {
                    if (newUserId) {
                      this.apiService.addSpecificUserProperty({ Name: 'PhoneNumber', Value: finalResult.phoneNumber, UserId: newUserId }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                        this.apiService.listUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(users => {
                          this.users = users;
                          this.filteredData = this.users;
                        });
                      });
                    }
                  });
                }
              });
            }
          });
        });
      });
    }
  }

  public deleteUser(id: string): void {
    if (this.isAdmin || this.isDomainAdmin) {
  
      const deleteUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.apiService.deleteUser(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.loadData();
            }, error: () => {}
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.users?.filter((key: User) =>
      [key.Username?.toLowerCase(), 
        key.Firstname?.toLowerCase(), 
        key.Lastname?.toLowerCase(), 
        key.Email?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  private getRoleColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-success-icon-text-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-light-warn-accent-color'));

    return colors;
  }

  public canDelete(element: User): boolean {
    const forbiddenRoles: string[] = [];

    if (this.isAdmin) {
      forbiddenRoles.push("Administrators"); 
    }
    if (this.isDomainAdmin) {
      forbiddenRoles.push("Domain admin", "Administrators");
    }
  
    return !element.Authorizations.some(auth => forbiddenRoles.includes(auth.Role.Name));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
