import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../shared/services/api.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'urban-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit, OnDestroy {

  public success: string | null;
  public token: string | null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isLittleScreen: boolean;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private translate: TranslateService,
    private router: Router
  ) {
    if (window.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      this.token = resp.params['t'];
      this.apiService.confirmDeleteUser(this.token).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        success => {
          this.translate.get('CONFIRM_DELETE.SUCCESS').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.success = res;
          });

        },
        err => this.router.navigate(['login'])
      )
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
