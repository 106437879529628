import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { Widget } from '../../models/dashboardList';

@Component({
  selector: 'urban-add-widget-dialog',
  templateUrl: './add-widget-dialog.component.html',
  styleUrls: ['./add-widget-dialog.component.scss']
})
export class AddWidgetDialogComponent implements OnInit {

  public passedWidgets: Widget[];
  public selectedWidgets: Widget[];

  constructor(
    public dialogRef: MatDialogRef<AddWidgetDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        addableWidgets: Widget[]
      }
  ) { }

  ngOnInit(): void {
    this.passedWidgets = this.data.addableWidgets;
  }

  onGroupsChange(options: MatListOption[]) {
    this.selectedWidgets = options.map(o => o.value);
  }

  addWidget() {
    this.dialogRef.close(this.selectedWidgets);
  }

  cancel() {
    this.dialogRef.close();
  }
}
