<div class="container-fluid">
    <div class="row d-flex justify-content-start mx-0 pageTop">
        <span class="title2">
            <span *ngIf="consumer?.Name" class="title2 appearingTextLeft">
                {{ consumer.Name + ' -' }}
            </span>{{ 'GENERAL.DETAIL' | translate }}
        </span>
    </div>

    <div class="consumerInfo panelBG">
        <form [formGroup]="form">
            <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                <span class="title3 secondaryTextColor">{{ 'GENERAL.INFO' | translate }}</span>

                <div>
                    <button type="button" mat-button class="btn actionButton" *ngIf="editMode" (click)="saveInfo()" [disabled]="!dataChanged">
                        {{ 'GENERAL.SAVE' | translate }}
                    </button>

                    <button type="button" mat-button class="btn actionButton" *ngIf="!editMode" (click)="enableEdit()">
                        {{ 'GENERAL.EDIT' | translate }}
                    </button>

                    <button  type="button" mat-button class="btn outlineActionButton ml-0 cancelBtn mt-2 mt-sm-0" *ngIf="editMode" (click)="cancelEdit()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                </div>
            </div>

            <div class="d-flex infoCol">
                <div class="col-6 customCol d-flex flex-column pl-0">
                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.ID' | translate }}</mat-label>
                        <input matInput readonly class="paragraphText" [value]="consumer?.Id" disabled/>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.CREATED' | translate }}</mat-label>
                        <input matInput readonly class="paragraphText" [value]="consumer?.Created * 1000 | date: 'short'" disabled/>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.NAME' | translate }}</mat-label>
                        <input matInput formControlName="name" [readonly]="!editMode" class="paragraphText"/>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
                        <input matInput formControlName="description" [readonly]="!editMode" class="paragraphText"/>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div class="customTable marginTop-32">
        <div class="customTableRadius d-flex justify-content-between align-items-baseline px-4 pt-4">
            <span class="title3">{{ 'CONSUMER_DETAIL.ADV_SERVICES' | translate }}</span>

            <button mat-button class="btn actionButton" (click)="addServiceToConsumer()"
                [disabled]="!(services && consumer && services.length !== 0 &&
                (!consumer.Services || services.length !== consumer.Services.length))">
                {{ 'GENERAL.ADD' | translate }}
            </button>
        </div>

        <div class="customTableRadius d-flex justify-content-start align-items-start px-4 pt-lg-4">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event, 'servicesData')"
                    autocomplete="off" [(ngModel)]="servicesActualFilter">
                <button *ngIf="servicesActualFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="applyFilterString('', 'servicesData'); servicesActualFilter = ''"
                    matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none" mat-table [dataSource]="filteredServicesData | paginate: servicesConfig">

                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="usernameShown">{{ element.Name }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.DESCRIPTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="usernameShown">{{ element.Description }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 10%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                class="iconInfoButton urbanicon-info-outline" (click)="goToServiceDetail(element.Id)">
                            </i>
                            <i matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="removeServiceFromConsumer(element.Id)"
                                class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="servicesColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: servicesColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event, 'servicesData')">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{servicesConfig.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredServicesData?.length / servicesConfig.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage('servicesData')}}
                    {{(filteredServicesData?.length / servicesConfig.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="servicesConfig.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="customTable marginTop-32">
        <div class="customTableRadius d-flex justify-content-between align-items-baseline px-4 pt-4">
            <span class="title3">{{ 'GENERAL.PROPERTIES' | translate }}</span>

            <button mat-button class="btn actionButton" (click)="setConsumerProperty()">
                {{ 'GENERAL.ADD' | translate }}
            </button>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event, 'propertiesData')"
                       autocomplete="off" [(ngModel)]="propertiesActualFilter">
                <button *ngIf="propertiesActualFilter" matSuffix mat-icon-button aria-label="Clear"
                        (click)="applyFilterString('', 'propertiesData'); propertiesActualFilter = ''"
                        matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none noTableHover" mat-table [dataSource]="filteredPropertiesData | paginate: propertiesConfig">

                <ng-container matColumnDef="Key">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.KEY' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.Key}} </td>
                </ng-container>

                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.VALUE' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.Value.length > 20 ? element.Value.slice(0, 20) +
                        "..." : element.Value }} </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 10%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="iconInfoButton urbanicon-edit"
                               matTooltip="{{'GENERAL.DETAIL' | translate }}"
                               (click)="setConsumerProperty(element)">
                            </i>
                            <i class="iconDeleteButton urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                               (click)="removePropertyFromConsumer(element.Key)">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="propertiesColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: propertiesColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                                     [maxSize]="7"
                                     [directionLinks]="true"
                                     [previousLabel]="''"
                                     [nextLabel]="''"
                                     [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                                     [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                                     [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                                     (pageChange)="paginatorOnPageChange($event, 'propertiesData')">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{propertiesConfig.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredPropertiesData?.length / propertiesConfig.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage('propertiesData')}}
                    {{(filteredPropertiesData?.length / propertiesConfig.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase() : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="propertiesConfig.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
