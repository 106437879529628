import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { NEW_NOTE, Note } from 'src/app/shared/models/Note';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'urban-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public notes: Note[];
  public MAX_NOTES_LIMIT: number = 20;
  public temporaryNotes: Note[] = [];
  public colors: string[] = ['lavender', 'lightRed', 'blue', 'yellow'];
  public touchedNotes: number[] = [];
  public activeNote: string = '';
  public dragEnabled: boolean = false;

  constructor(private passDataService: PassDataService, private mainService: MainSubscriptionsService) {
    this.passDataService.currentUserNotes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.notes = response.reduce((acc, note) => {
        if (!acc.find(n => n.id === note.id)) {
          acc.push(note);
        }
        return acc;
      }, []); 
    });
  }

  ngOnInit(): void {
    for (let note of this.notes) {
      this.temporaryNotes.push(note);
    }
  }

  public onMouseDown(): void {
    this.dragEnabled = true;
  }

  public addNote(): void {
    let newId: number = +Date.now();
    let maxPos = this.temporaryNotes.reduce((max, x) => x.position > max ? x.position : max, 0);

    this.temporaryNotes.push({ ...NEW_NOTE, id: newId, position: maxPos + 1 });
  }

  public deleteNote(passedId: number): void {
    if (this.notes.some(n => n.id === passedId)) {
      this.notes = this.notes.filter(note => note.id !== passedId);
      this.temporaryNotes = this.temporaryNotes.filter(note => note.id !== passedId);
      this.updateProfileNotes();
    } else if (!this.notes.some(n => n.id === passedId) && this.temporaryNotes.some(n => n.id === passedId)) {
      this.temporaryNotes = this.temporaryNotes.filter(note => note.id !== passedId);
    }
  }

  public editContentNote(id: number, text: string): void {
    this.temporaryNotes.find(note => note.id === id).content = text;
  }

  public changeColorNote(color: string, noteId: number, activeId: number): void {
    if (this.temporaryNotes.some(note => note.id === noteId)) {
      this.temporaryNotes.find(x => x.id === noteId).color = color;
      this.temporaryNotes.find(x => x.id === noteId).colorActive = activeId;
    }
    if (this.notes.some(note => note.id === noteId)) {
      this.notes.find(x => x.id === noteId).color = color;
      this.notes.find(x => x.id === noteId).colorActive = activeId;
    }
  }

  public updateNotesArray(passedId: number): void {
    let selectedNote = this.temporaryNotes.find(note => note.id === passedId);
    if (this.notes.includes(selectedNote)) {
      let elementIndex = this.notes.indexOf(selectedNote);
      this.notes.splice(elementIndex, 1, selectedNote);
      this.touchedNotes = this.touchedNotes.filter(x => x !== passedId);
      this.updateProfileNotes();
    } else {
      this.notes.push(selectedNote)
      this.touchedNotes = this.touchedNotes.filter(x => x !== passedId);
      this.updateProfileNotes();
    }
  }

  private updateProfileNotes(): void {
    this.mainService.changeProfileNotesComand(this.notes);
    this.activeNote = '';
  }

  public addModifiedNote(noteId: number): void {
    this.touchedNotes.push(noteId)
  }

  public drop(event: CdkDragDrop<Note[]>): void {
    if (this.dragEnabled) {
      moveItemInArray(this.temporaryNotes, event.previousIndex, event.currentIndex);

      for (let temp of this.temporaryNotes) {
        temp.position = this.temporaryNotes.indexOf(temp);
      }

      this.notes = this.temporaryNotes.filter(note => this.notes.some(x => x.id === note.id));
      this.updateProfileNotes();
      this.dragEnabled = false;
    }
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
