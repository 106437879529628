import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaderResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {environment} from '../environments/environment';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as AuthActions from '../app/store/auth/auth.actions'
import {LoaderService} from '../app/shared/services/loader/loader.service';
import {Router} from '@angular/router';
import {AuthState} from '../app/store/auth/auth.reducer';
import * as AuthSelectors from '../app/store/auth/auth.selectors';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private store: Store, private loaderService: LoaderService, private router: Router, private authStore: Store<AuthState>) {
  }

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Access-Control-Allow-Origin',
  })

  private isAuthFlow(url: string): boolean {
    if (
      url.includes('system.login.api') ||
      url.includes('system.token.api') ||
      url.includes('system.domains.api') ||
      url.includes('system.domain.api') ||
      url.includes('system.roles.api') ||
      url.includes('uv.domain.style.api') ||
      url.includes('system.resources.api') ||
      url.includes('uv.domain.property.list.api') ||
      url.includes('uv.dashboard.list.api') ||
      url.includes('uv.widget.list.api') ||
      url.includes('uv.device.list.api') ||
      url.includes('uv.devicebrand.list.api') ||
      url.includes('uv.devicemodel.list.api') ||
      url.includes('system.property.api') ||
      url.includes('uv.utility.prefix.list.api')
    ) return true
    else return false
  }

  private isSiralabRCFlow(url: string): boolean {
    if (
      url.includes('int.sl.setentitystate.api') ||
      url.includes('system.clientsettings.api') ||
      url.includes('uv.languages.phrases.api?')
    ) return true
    else return false
  }

  private isSiralabLoading(url: string): boolean {
    if (
      url.includes('int.sl.getdevicereport.api')
    ) return true
    else return false
  }

  private isNotificationsFlow(url: string): boolean {
    if (
      url.includes('uv.notification.list.api')
    ) return true
    else return false
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isAuthFlow(request.url) && !this.isSiralabRCFlow(request.url) && !this.isSiralabLoading(request.url) && !this.isNotificationsFlow(request.url)) {
      this.loaderService.show();
    } else if (this.isSiralabLoading(request.url)) {
      this.loaderService.RCLoaderShow();
    }

    this.authStore.select(AuthSelectors.getCurrentJwtToken).pipe().subscribe(token => {
      if (token) {
        this.headers.append('ZestyApiBearer', token);
      }

      request = request.clone({
        withCredentials: true,
        url: `${environment.apiUrl}/${request.url}`,
        headers: this.headers,
        setHeaders: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        }
      });
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse)
        //-------------------------------------
        /* console.log(request)
        if (event && (event?.status == 401 || event?.status == 403)){
            console.log(event.status)
            this.store.dispatch(AuthActions.logout())
        } else console.log(event)
         */
        return event
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.Message == 'Password expired') {
          this.router.navigate(['auth/reset-expired']);
        } else if (error && (error?.status == 401 || error?.status == 403)) {
          this.store.dispatch(AuthActions.logout())
        }
        return throwError(error);
      }),
      finalize(() => {
        if (!this.isAuthFlow(request.url) && !this.isSiralabRCFlow(request.url) && !this.isSiralabLoading(request.url)) {
          setTimeout(() => {
            this.loaderService.hide()
          }, 500);
        } else if (this.isSiralabLoading(request.url)) {
          setTimeout(() => {
            this.loaderService.RCLoaderHide()
          }, 500);
        }

      })
    )
  }
}
