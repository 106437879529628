import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from '../../../store/auth/auth.reducer';
import * as AuthSelectors from '../../../store/auth/auth.selectors';
import { UserRoles } from '../../models/userRoles';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  public userRoles: UserRoles['Roles'];
  public isAdmin: boolean;

  constructor(private store: Store<AuthState>, public router: Router) {
    this.store.select(AuthSelectors.getUserRoles).subscribe(
      res => this.userRoles = res
    )
    if (this.userRoles.some(x => x.Name === 'Administrators')) {
      this.isAdmin = true;
    }
  }

    public canActivate(): boolean {
      if (this.isAdmin) return true;
      else {
        this.router.navigate(['main']);
        return false;
      }
    }

  /* public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  } */

}
