import {createReducer, on} from '@ngrx/store';
import {Configuration} from '../../shared/models/configuration';

import * as AppActions from './app.actions';
import {AppState} from './app.state';
import {StyleHelper} from "../../helpers/style-helper";
import {Theme} from "../../shared/models/theme";

export const appFeatureKey = 'app';

export const initialState: AppState = {
  configuration: null,
  theme: null
};

export const appReducer = createReducer(
  initialState,
  on(AppActions.setConfiguration, (state, action) => ({...state, configuration: {Settings: action.Settings}})),
  on(AppActions.setTheme, (state, action) => ({...state, theme: action.theme}))
);

export const selectConfiguration = (state: AppState): Configuration => state.configuration;

export const selectTheme = (state: AppState): Theme => state.theme;

