<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">
        <mat-card class="d-flex flex-column p-0 m-3">
            <div class="resetExtraSection d-flex flex-column p-4" *ngIf="!isShortScreen">
                <h6>{{ 'RESET_EXPIRED.INSTRUCTION' | translate }}</h6>
            </div>
            <div class="d-flex flex-column resetMainSection p-4">
                <mat-card-title>{{ 'RESET_EXPIRED.TITLE' | translate }}</mat-card-title>
                <mat-card-content>
                    <div [formGroup]="form">
                        <mat-form-field>
                            <mat-label>{{ 'LOGIN.USERNAME' | translate }}</mat-label>
                            <input type="text" matInput
                                formControlName="username" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.OLD_PASSWORD' | translate }}</mat-label>
                            <input [type]="hideOldPassword ? 'password' : 'text'" matInput
                                formControlName="oldPassword" autocomplete="off">
                            <i class="passwordIcon" [ngClass]="hideOldPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" matSuffix (click)="hideOldPassword = !hideOldPassword"></i>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.NEW_PASSWORD' | translate }}</mat-label>
                            <input [type]="hideNewPassword ? 'password' : 'text'" matInput
                                formControlName="newPassword" autocomplete="off">
                            <i class="passwordIcon" [ngClass]="hideNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" matSuffix (click)="hideNewPassword = !hideNewPassword"></i>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.CONFIRMED_NEW_PASSWORD' | translate }}</mat-label>
                            <input [type]="hideConfirmedNewPassword ? 'password' : 'text'" matInput
                                formControlName="confirmedNewPassword" autocomplete="off">
                            <i class="passwordIcon" matSuffix [ngClass]="hideConfirmedNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'"
                                (click)="hideConfirmedNewPassword = !hideConfirmedNewPassword"></i>
                        </mat-form-field>

                        <mat-error *ngIf="error">
                            {{ 'CHANGE_PASSWORD_DIALOG.' + error | translate }}
                        </mat-error>

                        <div class="custom-button d-flex justify-content-between align-items-baseline">
                            <button mat-button class="mat-primary" (click)="back()">{{ 'GENERAL.BACK' | translate }}</button>
                            <button id="confirmButton" mat-button type="submit" (click)="update()">{{ 'CHANGE_PASSWORD_DIALOG.CONFIRM' | translate }}</button>
                        </div>
                    </div>
                </mat-card-content>
            </div>

        </mat-card>

        <br>
    </div>
</div>
