<div class="bg-login d-flex justify-content-center align-items-center">
    <mat-card class="d-flex flex-row p-0 m-4">
        <div class="recoveryMainSection p-4">
            <mat-card-title>{{ 'ACTIVATE.TITLE' | translate }}</mat-card-title>
            <mat-card-content>
                <p *ngIf="success" class="success">
                    {{ success }}
                </p>
            </mat-card-content>
        </div>
        <div class="recoveryExtraSection p-4 d-flex flex-column" *ngIf="!isLittleScreen">
            <h6>{{ 'ACTIVATE.EXTRA_TITLE' | translate }}</h6>
            <p class="darkText">{{ 'ACTIVATE.EXTRA_SUBTITLE' | translate }}</p>
        </div>
    </mat-card>
</div>
