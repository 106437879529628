export class Dictionary {
    /**
     *
     */
    constructor(key: string, value: string, translation: string) {
        this.Key = key;
        this.Value = value;
        this.Translation = translation !== undefined && translation !== null && translation !== '' ? translation : key;
    }

    Key: string
    Value: string
    Translation: string
}