import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DeviceEventRequest} from "../../models/administrator/deviceEventRequest";

@Component({
  selector: 'urban-manual-event-dialog',
  templateUrl: './manual-event-dialog.component.html',
  styleUrls: ['./manual-event-dialog.component.scss']
})
export class ManualEventDialogComponent implements OnInit {
  private deviceId: string;

  public types = ['add', 'update', 'delete'];
  public levels = ['info', 'debug', 'warning', 'error'];

  public form: UntypedFormGroup = this.formBuilder.group({
    type: [{value: '', disabled: false}, Validators.required],
    level: [{value: '', disabled: false}, Validators.required],
    created: [{value: 0, disabled: false}],
    body: [{value: '{}', disabled: false}, Validators.required]
  });

  public error: string | null;
  public invalidBody: string | null;

  constructor(
    public dialogRef: MatDialogRef<ManualEventDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        deviceId: string
      }
  ) {
  }

  ngOnInit(): void {
    this.deviceId = this.data?.deviceId;
  }

  addition(): void {
    if (this.validateForm()) {
      const eventEntity: DeviceEventRequest = {
        Device: this.deviceId,
        Type: this.form.controls.type.value,
        Level: this.form.controls.level.value,
        Body: this.form.controls.body.value,
        Created: Math.floor(+this.form.controls.created.value / 1000)
      }
      this.dialogRef.close(eventEntity);
    }
  }

  public validateForm(): boolean {
    let isValid: boolean;
    
    if (this.form.valid) {
      try {
        JSON.parse(this.form.controls.body.value);
        isValid = true;
      } catch (err) {
        isValid = false;
        this.invalidBody = 'INVALID_BODY';
      }
    } else {
      this.error = 'ERROR_EMPTY';
      isValid = false;
    }

    return isValid
  }

  public setDate(date: Date): number {
    return +date;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
