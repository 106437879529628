<div class="container-fluid">

    <div
        class="row mb-1 d-flex justify-content-between align-items-baseline bg-light-grey border-radius-15 m-0">
        <span class="h4 color-dark mb-2 mt-2 thin">
            {{ 'RESELLER.TITLE' | translate }}
        </span>
    </div>
    <br>

    <div class="devices-table mat-elevation-z4">
        <table class="w-100 tableSimpleHover" mat-table [dataSource]="myDataSource">

            <!-- Company Name Column -->
            <ng-container matColumnDef="CompanyName">
                <th mat-header-cell *matHeaderCellDef> {{ 'RESELLER.COMPANY_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <a id="resellerCompanyNameShown"> {{ element.CompanyName }}</a>
                </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="EmailAddress">
                <th mat-header-cell *matHeaderCellDef> {{ 'RESELLER.EMAIL' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.EmailAddress}} </td>
            </ng-container>

            <ng-container matColumnDef="Detail">
                <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 10%"></th>
                <td mat-cell *matCellDef="let element" class="text-center pr-1">
                    <div class="pr-2">
                        <i matTooltip="{{'GENERAL.DETAIL' | translate }}" (click)="goToManagers(element.id)"></i>
                        <span class="urbanicon-profile" matTooltip="{{'SMSS.ADVERTISERS' | translate }}"
                            (click)="goToAdvertisers(element.id)">
                        </span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>