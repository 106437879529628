import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BroadcastClientResponse} from "../../models/content-management/broadcastClientResponse";
import {BroadcastClientType} from "../../models/content-management/broadcastClientType";
import {BroadcastClientRequest} from "../../models/content-management/broadcastClientRequest";

@Component({
  selector: 'urban-broadcast-client-dialog',
  templateUrl: './broadcast-client-dialog.component.html',
  styleUrls: ['./broadcast-client-dialog.component.scss']
})
export class BroadcastClientDialogComponent implements OnInit {

  private broadcastClientResponse : BroadcastClientResponse;

  private broadcastClientRequest : BroadcastClientRequest;

  public isAdd : boolean = true;

  public types : BroadcastClientType[] = [];

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    description: [{value: '', disabled: false}, Validators.required],
    type: [{value: '', disabled: false}, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<BroadcastClientDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        client: BroadcastClientResponse,
        types: BroadcastClientType[]
      }
  ) {
  }

  ngOnInit(): void {
    this.types = this.data?.types;

    if (this.data && this.data.client) {
      this.broadcastClientResponse = this.data.client;
      this.isAdd = false;
      this.form.controls.name.setValue(this.broadcastClientResponse.Name);
      this.form.controls.description.setValue(this.broadcastClientResponse.Description);
      this.form.controls.type.setValue(this.broadcastClientResponse.BroadcastClientType.Id);
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.broadcastClientRequest = {
        id: this.broadcastClientResponse?.Id,
        broadcastClientType : this.form.controls.type.value,
        name: this.form.controls.name.value,
        description: this.form.controls.description.value
      };

      this.dialogRef.close(this.broadcastClientRequest);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
