import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceGroup } from '../../models/deviceGroup';

@Component({
  selector: 'urban-device-group-dialog',
  templateUrl: './device-group-dialog.component.html',
  styleUrls: ['./device-group-dialog.component.scss']
})
export class DeviceGroupDialogComponent implements OnInit {

  public error: string | null;
  private deviceGroup: DeviceGroup;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    color: [{ value: '', disabled: false }, Validators.required]
  });
  constructor(
    public dialogRef: MatDialogRef<DeviceGroupDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        group: DeviceGroup
      }
  ) { }

  ngOnInit(): void {
    this.deviceGroup = this.data?.group;

    if (this.deviceGroup) {
      this.form.controls.name.setValue(this.deviceGroup.Name);
    }
  }

  addition() {
    if (this.form.valid) {
      this.dialogRef.close({ name: this.form.controls.name.value, color: this.form.controls.color.value })
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
