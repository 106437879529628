import { ChartDataList } from '../../../shared/models/ChartDataList';
import { Component, HostListener, OnInit, OnDestroy, SimpleChanges, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'urban-google-areachart',
  templateUrl: './google-areachart.component.html',
  styleUrls: ['./google-areachart.component.scss']
})
export class GoogleAreachartComponent implements OnInit, OnDestroy {

  @Input('areaChartTitle') public areaChartTitle: string = 'Area Chart Title';
  @Input('horizontalMeasure') private hAxisMeasure: string = 'Horizontal Axis Measure';
  @Input('passedElementsDescription') private areasNames: string[] = [];
  @Input('passedDataList') private passedDataList: ChartDataList[] = [];
  @Input('limitValue') private maxValue: number;
  @Input('valuesUnit') private unit: string = '';
  @Input('darkThemeActive') private darkThemeActive: boolean;

  public noDataOnChart: boolean = false;
  private chart: google.visualization.AreaChart;
  private chartOptions: google.visualization.AreaChartOptions;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private googleChartLoaded: boolean = false;
  private chartColors: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  constructor() {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.onLoadedChartDraw.bind(this));
  }

  ngOnInit(): void {
    this.chartColors = this.getChartColors();

    if(this.unit !== '' && this.unit !== '%') {
      this.unit = ` ${this.unit}`;
    }
  }

  tryDrawChart(): void {
    if(this.googleChartLoaded) {
      this.drawChart();
    }
  }

  onLoadedChartDraw(): void {
    this.googleChartLoaded = true;
    this.drawChart();
  }

  drawChart(): void {
    if (this.passedDataList && this.areasNames.length > 0 && Object.keys(this.passedDataList).length > 0) {
      this.noDataOnChart = false;
      let myData = new google.visualization.DataTable();
      myData.addColumn('datetime', this.hAxisMeasure);
      this.areasNames.forEach((areaName: string) => {
         myData.addColumn('number', areaName);
      });

      for (let hAxisKey in this.passedDataList) {
        let chartRow: any[] = [new Date(hAxisKey)];
        for (let areaKey in this.passedDataList[hAxisKey]) {
          let value = this.passedDataList[hAxisKey][areaKey];
          let describedValue = { v: value, f: `${value}${this.unit}` };
          chartRow.push(describedValue);
        }
        myData.addRow(chartRow);
      }

      if(this.darkThemeActive) {
        this.chartOptions = {
          height: 500,
          chartArea: { width: '90%', height: '80%' },
          legend: {
            position: 'top',
            textStyle: { color: 'white' }
          },
          backgroundColor: 'transparent',
          hAxis: {
            textStyle: { color: 'white' },
            format: 'dd/MM/yyyy HH:mm',
            textPosition: 'out',
            titleTextStyle: { color: 'white' },

          },
          vAxis: {
            textStyle: { color: 'white' },
            viewWindow: {
              min: 0,
              max: this.maxValue ? this.maxValue : null
            },
            titleTextStyle: { color: 'white' },
            format: this.unit !== '' ? `#'${this.unit}'` : '#'
          },
          interpolateNulls: true,
          series: {
            0: {
              color: this.chartColors[2].trim()
            },
            1: {
              color: this.chartColors[1].trim()
            },
            2: {
              color: this.chartColors[0].trim()
            },
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          }
        }
      }
      else {
        this.chartOptions = {
          height: 500,
          chartArea: { width: '90%', height: '80%' },
          legend: {
            position: 'top'
          },
          backgroundColor: 'transparent',
          hAxis: {
            format: 'dd/MM/yyyy HH:mm',
            textPosition: 'out',
          },
          vAxis: {
            viewWindow: {
              min: 0,
              max: this.maxValue ? this.maxValue : null
            },
            format: this.unit !== '' ? `#'${this.unit}'` : '#'
          },
          interpolateNulls: true,
          series: {
            0: {
              color: this.chartColors[2].trim()
            },
            1: {
              color: this.chartColors[1].trim()
            },
            2: {
              color: this.chartColors[0].trim()
            },
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          }
        }
      }
      this.chart = new google.visualization.AreaChart(document.getElementById('divAreaChart'));
      this.chart.draw(myData, this.chartOptions);
    }
    else {
      if(this.chart) {
        this.chart.clearChart();
      }
      this.noDataOnChart = true;
    }
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    
    return colors;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['darkThemeActive']) {
      const isDarkActive = changes['darkThemeActive'];
      if (isDarkActive.currentValue !== isDarkActive.previousValue && isDarkActive.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['passedDataList']) {
      const updatedDataList = changes['passedDataList'];
      if (updatedDataList.currentValue !== updatedDataList.previousValue && updatedDataList.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if(changes['areasNames']) {
      const updatedTranslations = changes['areasNames'];
      if (updatedTranslations.currentValue !== updatedTranslations.previousValue && updatedTranslations.firstChange == false) {
        this.tryDrawChart();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
