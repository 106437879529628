<div [ngClass]="overlayBG ? 'overlayBG' : 'panelBG'"
    class="d-md-inline-flex d-block-flex flex-sm-wrap align-items-center searchBar">
    <form [formGroup]="formDate">
        <mat-form-field class="pl-2 pr-2">
            <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="sdt"
                (dateChange)="onStartDateUpdate($event.target.value); removeRangeBtnActive()" [min]="this.minStartDate"
                [max]="this.maxStartDate"
                formControlName="startDate" autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="sdt" (click)="onBlankDatesFocus()"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #sdt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="pl-2 pr-2">
            <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="edt"
                (dateChange)="onEndDateUpdate($event.target.value); removeRangeBtnActive()" [min]="this.minEndDate"
                [max]="this.maxEndDate" formControlName="endDate"
                autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="edt" (click)="onBlankDatesFocus()"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #edt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>
    </form>
    <div class="mr-4 mb-4 mb-md-0">
        <button type="submit"  class="btn ml-3 mb-3 smallActionButton" [disabled]="loadingEvents !== false" (click)="onSearch()"
            matTooltip="{{ 'WEATHERSTATION.SEARCH' | translate }}">
            <i class="urbanicon-search"></i>
        </button>
        <button type="submit" class="btn smallOutlineActionButton marginClearBtn mb-3" (click)="clearDateAndUnsubscribe()"
            matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
            <i class="urbanicon-close"></i>
        </button>
    </div>
    <div>
        <mat-button-toggle-group aria-label="Time Range Set" class="searchRangeBtn mb-4"
            (valueChange)="setTimeRange($event)" [(ngModel)]="buttonToggleSelected">
            <mat-button-toggle *ngFor="let period of filterPeriods" [value]="period" [disabled]="loadingEvents !== false">
                <div
                    [ngClass]="{'button-toggle-wrap lineheightIT' : currentLanguage === 'it', 'fontSizeToggleEn' : currentLanguage === 'en'}">
                    <span [ngClass]="{'uppercaseText' : currentLanguage === 'it'}">{{'GENERAL.HOURS_LAST' | translate}}
                    </span><span>{{period}}{{'GENERAL.HOURS_H' | translate}}</span></div>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>