<div class="container-fluid">
    <div class="row">
        <div class="myToggleContainer col-12 pl-0 pr-0 d-flex align-items-baseline justify-content-start" *ngIf="!devices" [ngClass]="{'flex-xl-row flex-column' : devices}">
            <label class="mr-3" for="positionType">{{'AROUND_ME.CHOOSE' | translate }}</label>
            <mat-button-toggle-group
                name="positionType"
                aria-label="positionType"
                [(ngModel)]="typePosition"
                [vertical]="this.verticalAlign"
                class="toggleButtons"
                [ngClass]="{'flex-column flex-sm-row': devices}">   
                <mat-button-toggle (click)="updatePositionType()" value="current">{{'AROUND_ME.CURRENT' | translate }}</mat-button-toggle>
                <mat-button-toggle (click)="updatePositionType()" value="manual">{{'AROUND_ME.MANUAL' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-12 mt-3 text-center" *ngIf="!devices && errorGeolocation !== ''">
            <mat-error class="h5">
                {{ 'AROUND_ME.' + errorGeolocation | translate }}
            </mat-error>
        </div>

        <div class="myToggleContainer col-12 px-0 mt-3 d-flex align-items-baseline justify-content-start flex-xl-row flex-column" *ngIf="devices">
            <label class="mr-3" for="positionType">{{'AROUND_ME.CHOOSE_NEW' | translate }}</label>
            <mat-button-toggle-group [vertical]="this.verticalAlign"  class="toggleButtons devicesToggleGroup flex-column flex-sm-row" *ngIf="devices">
                <mat-button-toggle (click)="updatePositionType('current')">{{'AROUND_ME.SEARCH_CURRENT' | translate }}</mat-button-toggle>
                <mat-button-toggle (click)="updatePositionType('manual')">{{'AROUND_ME.SEARCH_MANUAL' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="col-12" *ngIf="devices && mapReady">
            <urban-maps-widget [devicesList]="devices" [darkModeStatus]="isDarkActive"></urban-maps-widget>
        </div>
    </div>
</div>