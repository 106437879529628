import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdvertiserDialogComponent } from '../../../../shared/dialogs/advertiser-dialog/advertiser-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SMSSAdvertiser } from '../../../../shared/models/smss/smssAdvertiser';
import { UserRoles } from '../../../../shared/models/userRoles';
import { ApiService } from '../../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-advertisers',
  templateUrl: './advertisers.component.html',
  styleUrls: ['./advertisers.component.scss']
})
export class AdvertisersComponent implements OnInit, OnDestroy {

  advertiserColumns = ['Id', 'CompanyName', 'Firstname', 'Lastname', 'Email', 'Detail'];
  public advertisers: SMSSAdvertiser[] = [];
  public advertisersDataSource = new MatTableDataSource(this.advertisers);
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public userRoles: UserRoles['Roles'];
  private resellerId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this._initialize();
  }

  private _initialize() {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;

      const resellerId = this.route.snapshot.paramMap.get('id');
      if (resellerId === undefined || resellerId === null || resellerId === '')
        this.router.navigate(['main/dashboard']);

      this.resellerId = resellerId;

      this.apiService.advertisers(resellerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.advertisers = result;
          this.advertisersDataSource = new MatTableDataSource(this.advertisers);
        }
      })
    })
  }

  add(): void {
    const addDialogRef = this.dialog.open(AdvertiserDialogComponent, {
      data : {
        reseller : this.resellerId
      },
      width: '50%',
      maxWidth: 500
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            data.resellerId = this.resellerId;
            this.apiService.advertiserWrite(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  detail(advertiser: SMSSAdvertiser) {
    if (!advertiser || advertiser === undefined) {
      return;
    }

    this.router.navigate(['main/advertiser', { id: advertiser.id }])
  }

  delete(advertiser: SMSSAdvertiser) {
    if (!advertiser || advertiser === undefined) {
      return;
    }
    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.advertiserDelete(advertiser.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            let message: string = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
            this.mainService.setSuccessMessageComand(message);
            this._initialize();
          }
        });
      }
    });
  }

  back() {
    this.router.navigate(['main/net-wifi/resellers']);
  }

}
