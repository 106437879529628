<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">
        <mat-card class="d-flex flex-column p-0 m-4">
            <div class="appSupportExtraSection d-flex flex-column p-4">
                <h6 *ngIf="!privacyPolicyResponse?.Options?.Target">{{'APP_PRIVACY.TITLE' | translate}}</h6>
                <h6 *ngIf="privacyPolicyResponse?.Options?.Target">{{this.privacyPolicyResponse.Options.Target}} privacy</h6>
                <br>
            </div>
            <div class="d-flex flex-column appSupportMainSection p-4 text-white">
                <mat-card-title style="font-size: larger;">{{ 'APP_SUPPORT.CONTACT' | translate}}</mat-card-title>
                <mat-card-content>
                    <div id="scrollable">
                        <div [innerHTML]="this.privacyPolicyResponse?.Content"></div>
                    </div>
                </mat-card-content>
            </div>
        </mat-card>
        <br>
        <div id="logoEnvision">
            <a href="http://envisioncompany.it/" target="_blank">
                <img [src]="'/assets/img/brand/envision-logo.png'"/>
            </a>
        </div>
    </div>
</div>
