import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {MainState} from "../../store/main/main.reducer";
import {LoaderService} from "./loader/loader.service";
import {DeviceNotificationListRequest} from "../models/device-command/deviceNotificationListRequest";
import {Observable, of} from "rxjs";
import {DeviceNotificationListResponse} from "../models/device-command/deviceNotificationListResponse";
import {catchError, map} from "rxjs/operators";
import * as MainActions from "../../store/main/main.actions";

@Injectable({
  providedIn: 'root'
})

export class DeviceNotificationPollingServiceService{
  constructor(private http: HttpClient, private store: Store<MainState>, private loaderService: LoaderService) {}

  public polling(request: DeviceNotificationListRequest): Observable<DeviceNotificationListResponse> {
    return this.http.post<DeviceNotificationListRequest>(
      `uv.device.notification.list.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }
}
