<h1 mat-dialog-title>{{ 'GENERAL.ADD_EVENT' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select required formControlName="type">
                <mat-option *ngFor="let type of types" value={{type}}>
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.LEVEL' | translate }}</mat-label>
            <mat-select required formControlName="level">
                <mat-option *ngFor="let level of levels" value={{level}}>
                    {{level}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.DATE' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="edt" (dateChange)="setDate($event.target.value)" 
                formControlName="created" autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="edt"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #edt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.BODY' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput formControlName="body"
                      matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=20 autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
        <mat-error *ngIf="invalidBody">
            {{ 'GENERAL.' + invalidBody | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
