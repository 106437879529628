import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddUserDomainRequest } from '../../models/addUserDomainRequest';
import { Domain } from '../../models/domain';
import { PhonePrefix } from '../../models/phonePrefix';
import { Role } from '../../models/userRoles';

@Component({
  selector: 'urban-add-user-domain-dialog',
  templateUrl: './add-user-domain-dialog.component.html',
  styleUrls: ['./add-user-domain-dialog.component.scss']
})
export class AddUserDomainDialogComponent implements OnInit {

  private createModel: AddUserDomainRequest;
  public error: string | null;

  public form: UntypedFormGroup = this.formBuilder.group({
    username: [{ value: '', disabled: false }, Validators.required],
    email: [{ value: '', disabled: false }, Validators.required],
    firstname: [{ value: '', disabled: false }, Validators.required],
    lastname: [{ value: '', disabled: false }, Validators.required],
    roleSelected: [{ value: '', disabled: false }, Validators.required],
    phonePrefix: [{ value: 39, disabled: false }, Validators.required],
    phoneNumber: [
      { value: null, disabled: false },
      Validators.compose([
        Validators.required, Validators.pattern(/^[(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,6}$/)
      ])
    ]
  });

  public roles: Role[];
  public domain: Domain;
  public prefixes: PhonePrefix[];

  constructor(public dialogRef: MatDialogRef<AddUserDomainDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        myPrefixesList: PhonePrefix[],
        myDomain: Domain,
        myRolesList: Role[]
      }) { }

  ngOnInit(): void {
    this.prefixes = this.data.myPrefixesList;
    this.roles = this.data.myRolesList;
    this.domain = this.data.myDomain;
  }

  cancel() {
    this.dialogRef.close();
  }


  addition() {
    if (this.form.valid) {
      this.createModel = {
        Email: this.form.controls.email.value,
        Firstname: this.form.controls.firstname.value,
        Lastname: this.form.controls.lastname.value,
        Username: this.form.controls.username.value,
        Role: this.form.controls.roleSelected.value
      }
      let formattedPhoneNumber: string;
      formattedPhoneNumber = "+" + this.form.controls.phonePrefix.value + " " + this.form.controls.phoneNumber.value
      let finalResult = {
        newUser: this.createModel,
        phoneNumber: formattedPhoneNumber,
        role: this.form.controls.roleSelected.value
      }
      this.dialogRef.close(finalResult);
    }
    else {
      if (this.form.controls.email.errors?.email == true) {
        this.error = "ERROR_EMAIL";
      } else {
        this.error = "ERROR_EMPTY";
      }
    }
  }

}
