import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventsDeviceLatest } from '../../models/deviceEventLatest';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-alert-events-dialog',
  templateUrl: './alert-events-dialog.component.html',
  styleUrls: ['./alert-events-dialog.component.scss']
})
export class AlertEventsDialogComponent implements OnInit {

  public isAdmin: boolean;
  public selectedType: EventsDeviceLatest[];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AlertEventsDialogComponent>,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) public data:
    {
      selectedType: EventsDeviceLatest[];
    }
  ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isAdmin = res?.some(x => x.Name == 'Administrators' || x.Name == 'Domain admin');
    });

    this.selectedType = this.data.selectedType;
  }

  goToDetail(deviceId: string) {
    this.dialogRef.close();
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: 'dashboard' });
    this.router.navigate(['main/device-events']);
  }

  cancel() {
    this.dialogRef.close();
  }

}
