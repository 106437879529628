import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CampaignDialogComponent } from '../../../../shared/dialogs/campaign-dialog/campaign-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SMSSAdvertiser } from '../../../../shared/models/smss/smssAdvertiser';
import { SMSSCampaign } from '../../../../shared/models/smss/smssCampaign';
import { UserRoles } from '../../../../shared/models/userRoles';
import { ApiService } from '../../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit, OnDestroy {

  public advertiser: SMSSAdvertiser;
  public campaigns: SMSSCampaign[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public error: string | null;
  public userRoles: UserRoles['Roles'];
  public campaignsDataSource = new MatTableDataSource(this.campaigns);
  campaignColumns = ['Id', 'Description', 'Detail'];

  public advertiserId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this._initialize();
  }

  private _initialize(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;

      const advertiserId = this.route.snapshot.paramMap.get('advertiserId');
      if (advertiserId === undefined || advertiserId === null || advertiserId === '')
        this.router.navigate(['main/dashboard']);

      this.apiService.advertiserRead(advertiserId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.advertiser = result;

          this.apiService.campaigns(advertiserId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            if (res) {
              this.campaigns = res;
              this.campaignsDataSource = new MatTableDataSource(this.campaigns);
            }
          })
        }
      })
    })
  }

  add() {
    const addCampaignDialogRef = this.dialog.open(CampaignDialogComponent, {
      data: { advertiser: this.advertiser }
    });

    addCampaignDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newAdvertiser => {
      if (newAdvertiser) {
        const addModelConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addModelConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.advertiserWrite(newAdvertiser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                let message = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
                this.mainService.setSuccessMessageComand(message);
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  detail(campaign: SMSSCampaign) {
    if (!campaign || campaign === undefined) {
      return;
    }

    this.router.navigate(['main/campaign', { advertiserId: this.advertiser.id, campaignId: campaign.id }])
  }

  delete(campaign: SMSSCampaign) {
    if (!campaign || campaign === undefined) {
      return;
    }

    const deleteCampaignConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteCampaignConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.campaignDelete(campaign.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            if (x) {
              let message = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
              this.mainService.setSuccessMessageComand(message);
              this._initialize();
            }
          }
        });
      }
    });
  }

  back() {
    this.router.navigate(['main/advertiser', { id: this.advertiser.id }]);
  }
}
