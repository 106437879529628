import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ForgotComponent} from '../../pages/auth/forgot/forgot.component';
import {RecoveryComponent} from '../../pages/auth/recovery/recovery.component';
import {ResetExpiredComponent} from '../../pages/auth/reset-expired/reset-expired.component';
import {LoginComponent} from '../../pages/auth/login/login.component';
import {AuthComponent} from '../../pages/auth/auth.component';
import {ActivateComponent} from '../../pages/auth/activate/activate.component';
import {ConfirmDeleteComponent} from '../../pages/auth/confirm-delete/confirm-delete.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent},
      {path: 'forgot-password', component: ForgotComponent},
      {path: 'recovery-password/:token', component: RecoveryComponent},
      {path: 'reset-expired', component: ResetExpiredComponent},
      {path: 'app-activation/:t', component: ActivateComponent},
      {path: 'confirm-delete/:t', component: ConfirmDeleteComponent},
      {path: '**', redirectTo: 'login'}
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
