import { Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UpdateUserRequest } from '../../../shared/models/administrator/updateUserRequest';
import { Domain } from '../../../shared/models/domain';
import { Authorization, User } from '../../../shared/models/loginResponse';
import { Role } from '../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../shared/services/administrator-api.service';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthorizeUserRequest } from '../../../shared/models/administrator/authorizeUserRequest';
import { AddAuthorizationDialogComponent } from '../../../shared/dialogs/add-authorization-dialog/add-authorization-dialog.component';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { takeUntil, first } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api.service';
import { PhonePrefix } from '../../../shared/models/phonePrefix';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  public isAdmin: boolean = false;
  public editMode: boolean = false;
  public dataChanged: boolean;
  public phoneNumber: string | null = null;
  public roles: Role[] = [];
  public domains: Domain[] = [];
  public currentUser: User;
  public authorizations: Authorization[] = [];
  public displayedUserDetailColumns: string[] = ['Domain', 'Role', 'Detail'];
  public filteredData: Authorization[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public profileImage: string | null = null;
  public profilePhoneNumber: string = null;
  public myPrefixesList: PhonePrefix[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  public form: UntypedFormGroup = this.formBuilder.group({
    username: [{ value: '', disabled: false }, Validators.required],
    email: [{ value: '', disabled: false }, Validators.required],
    firstname: [{ value: '', disabled: false }, Validators.required],
    lastname: [{ value: '', disabled: false }, Validators.required],
    prefix: [{value: null}, Validators.required],
    telephone: [null, Validators.compose([Validators.required, Validators.pattern(/^[(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,6}$/)])]
  });

  constructor(public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private administrationApiService: AdministratorApiService,
    private apiService : ApiService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private passDataService: PassDataService
    ) { }


  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.some(x => x.Name === 'Administrators')) {
        this.isAdmin = true;
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }
    });

    let userId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        userId = navInfo.Id;
      }
      else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }
    });

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
      let prefix: string;
      let telephone: string;
  
      if (this.currentUser.Properties?.hasOwnProperty('PhoneNumber')) {
        this.phoneNumber = this.currentUser.Properties.PhoneNumber;

        prefix = this.phoneNumber.split(" ")[0].replace("+","");
        telephone = this.phoneNumber.split(" ")[1];
      }

      const userValues = {
        username: this.currentUser.Username,
        email: this.currentUser.Email,
        firstname: this.currentUser.Firstname,
        lastname: this.currentUser.Lastname,
        prefix: +prefix ?? null,
        telephone: telephone ?? null
      }

      this.dataChanged = false;

      for (let key in userValues) {
        if (userValues[key] !== values[key]) {
          this.dataChanged = true;
          break;
        }
      }
    });

    this.administrationApiService.roleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.roles = x
    });

    this.administrationApiService.domainList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.domains = x;
      }
    });

    this.preparePhoneData();

    this.loadData(userId);
  }

  private preparePhoneData(): void {
    this.apiService.getPhonePrefixes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.length > 0) {
        this.myPrefixesList = res;
      }
    });
  }

  private loadData(userId: string): void {
    this.administrationApiService.getUser(userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.currentUser = res;

        let prefix: string;
        let telephone: string;

        if (this.currentUser.Properties?.hasOwnProperty('PhoneNumber')) {
          this.phoneNumber = this.currentUser.Properties.PhoneNumber;
  
          prefix = this.phoneNumber.split(" ")[0].replace("+","");
          telephone = this.phoneNumber.split(" ")[1];
        }

        this.form.setValue({
          username: this.currentUser.Username,
          email: this.currentUser.Email,
          firstname: this.currentUser.Firstname,
          lastname: this.currentUser.Lastname,
          prefix: +prefix ?? null,
          telephone: telephone ?? null
        });

        this.currentUser.Properties?.hasOwnProperty('ProfileImage') ? this.profileImage = this.currentUser.Properties.ProfileImage : null;

        this.authorizations = this.currentUser.Authorizations;
        this.filteredData = this.authorizations;
      }
    });
  }

  private updateUserTelephone(newPhoneNumber: string): void {
    this.mainService.changeUserPhoneNumberComand({ Name: 'PhoneNumber', Value: newPhoneNumber, UserId : this.currentUser.Id });
  }

  public saveInfo(): void {
    if (this.form.valid && this.isAdmin) {
      let updateModel: UpdateUserRequest = {
        Id: '',
        Username: '',
        Email: '',
        Firstname: '',
        Lastname: ''
      }

      updateModel.Id = this.currentUser.Id;
      updateModel.Email = this.form.controls.email.value;
      updateModel.Firstname = this.form.controls.firstname.value;
      updateModel.Lastname = this.form.controls.lastname.value;
      updateModel.Username = this.form.controls.username.value;

      const updateUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          const formattedPhoneNumber: string = "+" + this.form.controls.prefix.value + " " + this.form.controls.telephone.value;

          if (this.phoneNumber !== formattedPhoneNumber) {
            this.updateUserTelephone(formattedPhoneNumber);
          }

          this.administrationApiService.updateUser(updateModel).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.dataChanged = false;
              this.editMode = false;

              this.loadData(this.currentUser.Id);
            }, error: () => {}
          });
        }
      });
    }
  }

  public delete(): void {
    if (this.isAdmin) {
      const deleteUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administrationApiService.deleteUser(this.currentUser.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.mainService.setNavigationInfoComand({ Back: true });
            this.router.navigate(['main/user-management']);
          });
        }
      });
    }
  }

  public hardDelete(): void {
    if (this.isAdmin) {
      const hardDeleteUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      hardDeleteUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administrationApiService.hardDeleteUser(this.currentUser.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.router.navigate(['main/user-management']);
          });
        }
      });
    }
  }

  public addAuthorization(): void {
    if (this.isAdmin) {
      const addAuthorizationDialogRef = this.dialog.open(AddAuthorizationDialogComponent, {
        data: {
          user: this.currentUser,
          domains: this.domains,
          roles: this.roles
        }
      });

      addAuthorizationDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(authorization => {
        if(authorization) {
          const addAuthorizationConfirmDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          addAuthorizationConfirmDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.administrationApiService.authorizeUser(authorization).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
                next: () => {
                  this.loadData(this.currentUser.Id);
                }, error: () => {}
              });
            }
          });
        }
      });
    }
  }

  public deauthorize(domainId: string, roleId: string): void {
    if (this.isAdmin) {
      const deauthorizeUserDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deauthorizeUserDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          let deauthorizeRequest: AuthorizeUserRequest = {
            User: this.currentUser.Id,
            Domain: domainId,
            Role: roleId
          };
          this.administrationApiService.deauthorizeUser(deauthorizeRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.loadData(this.currentUser.Id);
            }, error: () => {}
          });
        }
      });
    }
  }

  public enableEdit(): void {
    this.editMode = true;
  }

  public cancelEdit(): void {
    let prefix: string;
    let telephone: string;

    if (this.phoneNumber) {
      prefix = this.phoneNumber.split(" ")[0].replace("+","");
      telephone = this.phoneNumber.split(" ")[1];
    }

    this.form.reset({
      username: this.currentUser.Username,
      email: this.currentUser.Email,
      firstname: this.currentUser.Firstname,
      lastname: this.currentUser.Lastname,
      prefix: +prefix  ?? null,
      telephone: telephone ?? null
    });

    this.dataChanged = false;
    this.editMode = false;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.authorizations?.filter((authorization: Authorization) =>
      [authorization.Domain?.Name?.toLowerCase(), authorization.Role?.Name?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
