<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.LANGUAGE' | translate }}</mat-label>
            <mat-select required formControlName="languageSelected">
                <mat-option *ngFor="let language of languages" value={{language.Id}}>
                    {{language.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select required (selectionChange)="loadTemplateTypeOptions()"
                        formControlName="templateTypeSelected">
                <mat-option *ngFor="let templateType of templateTypes" value={{templateType.Id}}>
                    {{templateType.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="this.options && this.hasLength(this.options)">
            <div class="separator">{{ 'GENERAL.OPTIONS' | translate }}</div>
            <br>
            <mat-form-field *ngFor="let option of getKeys(this.options)">
                <mat-label>{{option}}</mat-label>
                <input matInput value="" id="{{option}}" type="text"
                       autocomplete="off" value="{{this.data.templateItem?.Options[option]}}" [required]="this.options[option]">
            </mat-form-field>
            <br>
        </div>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.FORMAT' | translate }}</mat-label>
            <mat-select required formControlName="templateFormatSelected">
                <mat-option *ngFor="let templateFormat of templateFormats" value={{templateFormat.Id}}>
                    {{templateFormat.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="contentArea">
            <mat-label>{{ 'GENERAL.CONTENT' | translate }}</mat-label>
            <textarea matInput formControlName="content"
                      matTextareaAutosize matAutosizeMinRows=15
                      matAutosizeMaxRows=30 required></textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
