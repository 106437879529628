import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SMSSManager } from '../../../../shared/models/smss/smssManager';
import { SMSSReseller } from '../../../../shared/models/smss/smssReseller';
import { MatTableDataSource } from '@angular/material/table';
import { SMSSDomain } from '../../../../shared/models/smss/smssDomain';
import { SMSSGateway } from '../../../../shared/models/smss/smssGateway';
import { WifiSmssService } from '../../../../shared/services/wifi-smss.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-managers',
  templateUrl: './managers.component.html',
  styleUrls: ['./managers.component.scss']
})

export class ManagersComponent implements OnInit, OnDestroy {
  public displayedManagersColumns = ['CompanyName', 'Country', 'EmailAddress', 'Detail'];
  public currentReseller: SMSSReseller;
  public tableToShow: string = 'managers';
  public domainSelected: SMSSDomain = null;
  public managers: SMSSManager[] = [];
  public myDataSource: MatTableDataSource<SMSSDomain | SMSSManager | SMSSGateway>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wifiSMSSService: WifiSmssService,
  ) { }

  ngOnInit(): void {
    const resellerId = this.route.snapshot.paramMap.get('id');
    if (resellerId === undefined || resellerId === null || resellerId === '') this.router.navigate(['main/dashboard'])
    this.wifiSMSSService.getSMSSManagers(resellerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe( res => {
      this.managers = res;
      this.myDataSource = new MatTableDataSource(res)
    })
  }

  back() {
    this.router.navigate(['main/net-wifi/resellers'])
  }

  goToDomains(managerId: string): void {
    this.router.navigate(['main/net-wifi/domains', { id: managerId }]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
