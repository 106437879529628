import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Device } from '../../../shared/models/device';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { ApiService } from '../../../shared/services/api.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.scss']
})

export class DevicesTableComponent implements OnInit, OnDestroy {

  displayedColumns = ['Name', 'Type', 'BrandName', 'BrandModel'];
  public devices: Device[] = [];
  public filteredData: Device[] = [];
  public deviceType: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isDarkModeActive: boolean;
  public mapReady: boolean = false;
  public noData: boolean = false;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private mainService: MainSubscriptionsService,
    private router: Router,
    private apiService: ApiService,
    private passDataService: PassDataService,
  ) {
    this.mainService.updateAllDataAndNavigateComand();
  }


  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if(navInfo?.Type) {
        this.deviceType = navInfo.Type;
      }
      else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.loadData();
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkModeActive = res == true;
    });
  }

  private loadData(): void {
    this.apiService.getDevicesByType(this.deviceType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res?.length > 0) {
        this.devices = res;
        this.filteredData = this.devices;
      }

      this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
        this.mapReady = mapLoading;
      });

      if (this.devices.length === 0) {
        this.noData = true;
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.devices?.filter((device: Device) =>
      [device.Name.toLowerCase(), 
        device.Model?.Type?.Name?.toLowerCase(), 
        device.Model?.Brand?.Name?.toLowerCase(), 
        device.Model?.Name?.toLowerCase()
      ].some((field: string) => field.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public goToDetail(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: 'devices-table' });
    this.router.navigate(['main/device']);
  }

  public goToMain(): void {
    let currentUrl: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
    this.mainService.updateAllDataAndGoToMainComand();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
