import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Device } from '../../models/device';
import { DeviceType } from '../../models/deviceType';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-search-device-widget',
  templateUrl: './search-device-widget.component.html',
  styleUrls: ['./search-device-widget.component.scss']
})

export class SearchDeviceWidgetComponent implements OnInit, OnDestroy {
  @Input('devicesList') public parentLevelDevices: Device[];
  @Input('widgetInColumn') public widgetInColumn: boolean;

  public allLevelsDevices: Device[] = [];
  public allTypes: DeviceType[] = [];
  private typesTranslations: { [key: string]: string };

  public myControl = new UntypedFormControl();
  public filteredOptions: Observable<Device[]>;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private mainService: MainSubscriptionsService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadDevices(this.parentLevelDevices);
    this.listTypes();
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.Name),
      map(name => name ? (this.allLevelsDevices.length > 10 ? this._filter(name).slice(0, 10) : this._filter(name)) : [])
    );
  }

  private loadDevices(devicesToIterate: Device[]): void {
    if (devicesToIterate && devicesToIterate.length > 0) {
      this.allLevelsDevices = this.normalizeDevices(devicesToIterate);
      //ordinamento in ordine alfabetico sul Name
      this.allLevelsDevices.sort((a, b) => a.Name.localeCompare(b.Name))
    }
  }

  private normalizeDevices(devices: Device[]): Device[] {
    let allLevelsDevices: Device[] = [];
    devices.forEach((singleDevice: Device) => {
      allLevelsDevices.push(singleDevice);
      if(singleDevice.Childs?.length > 0) {
        allLevelsDevices.push(...this.normalizeDevices(singleDevice.Childs));
      }
    });
    return allLevelsDevices;
  }

  public listTypes(): void {
    // questa funzione controlla tutti i types dei devices e crea un array con soltanto i valori unici dei nomi dei types che trova
    let devicesTypes: DeviceType[] = [];
    this.typesTranslations = {};
    for( let device of this.allLevelsDevices ) {
      this.typesTranslations['REPORT.' + device.Model.Type.Name] = '';
      if(!devicesTypes.some(x => x.Id === device.Model.Type.Id)) {
        devicesTypes.push(device.Model.Type);
      }
    }

    this.sortTypes(devicesTypes);

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.sortTypes(devicesTypes);
    });

  }

  public sortTypes(devicesTypes: DeviceType[]): void {
    if (Object.keys(this.typesTranslations)?.length > 0) {
      this.translate.get(Object.keys(this.typesTranslations)).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if(res && Object.keys(res)) {
          Object.keys(this.typesTranslations).forEach((translationKey: string) => {
            this.typesTranslations[translationKey] = res[translationKey];
          });
        }

        devicesTypes.sort((a, b) => {
          const nameA = this.typesTranslations['REPORT.' + a.Name].toUpperCase();
          const nameB = this.typesTranslations['REPORT.' + b.Name].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        this.allTypes = [...new Set(devicesTypes)];
      });
    }
  }

  public countDevicesOfType(type: string): number {
    return this.allLevelsDevices.filter( x => x.Model.Type.Name == type).length;
  }

  public anyDeviceOfType(type: string): boolean {
    return (this.allLevelsDevices.find((device: Device) => device.Model.Type.Name === type) !== undefined);
  }

  public displayFn(device: Device): string {
    return device && device.Name ? device.Name : '';
  }

  private _filter(name: string): Device[] {
    const filterValue: string = name.toLowerCase();

    return this.allLevelsDevices.filter(
      (option: Device) =>
      option.Name.toLowerCase().indexOf(filterValue) === 0 ||
      option.Model.Name.toLowerCase().indexOf(filterValue) === 0 ||
      option.Model.Type.Name.toLowerCase().indexOf(filterValue) === 0 ||
      option.Model.Brand.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public goToTable(selectedType: string): void {
    this.mainService.setNavigationInfoComand({ Type: selectedType, BackRoute: 'dashboard' });
    this.mainService.updateAllDataAndNavigateComand('devices-table');
  }

  public goToDeviceDetail(device: Device): void {
    if (device.Id) {
      this.mainService.setNavigationInfoComand({ Id: device.Id, BackRoute: 'dashboard' });
      this.router.navigate(['main/device']);
    }
  }

  public clearSelection(): void {
    this.myControl.setValue('');
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }
}
