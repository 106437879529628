<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'ROAD_RISK.TITLE' | translate }}
        </span>
        <span *ngIf="dangerRateChecked && mapReady && (mostDangerousPlaces?.length > 0 && dataForHeatmap?.length > 0)" id="roadrisk-helper" class="btn material-icons-outlined helpButton"
            [ngClass]="loadingBoxesData ? 'disappearing-helper' : 'appearing'" (click)="startIntro()">
            live_help
        </span>
    </div>

    <div #boxesDiv id="intro-roadrisk-boxes" class="d-flex flex-row flex-wrap justify-content-center ml-3 mr-3"
        *ngIf="totalEvents !== null && dangerEvents !== null && dangerRateChecked"
        [ngClass]="loadingBoxesData ? 'disappearing-boxes' : 'appearing-boxes'">
        <div class="box panelBG" [ngClass]="isDarkActive ? 'panelShadowDark' : 'panelShadowLight'">
            <h6>{{'ROAD_RISK.TOTAL_EVENTS' | translate }}</h6>
            <hr class="mb-2 mt-2"
                style="background-color: cornflowerblue; border: 3px solid cornflowerblue; border-radius: 10px; width: 60%;">
            <p>{{ totalEvents }}</p>
        </div>
        <div class="box panelBG" [ngClass]="isDarkActive ? 'panelShadowDark' : 'panelShadowLight'">
            <h6>{{'ROAD_RISK.DANGER_EVENTS' | translate }}</h6>
            <hr class="mb-2 mt-2"
                style="background-color: red; border: 3px solid red; border-radius: 10px; width: 60%;">
            <p>{{ dangerEvents }}</p>
        </div>
        <div *ngIf="totalEvents > 0" class="box panelBG" [ngClass]="isDarkActive ? 'panelShadowDark' : 'panelShadowLight'">
            <h6>{{'ROAD_RISK.DANGER_RATE_AVERAGE' | translate }}</h6>
            <hr class="mb-2 mt-2"
                style="background-color: orange; border: 3px solid orange; border-radius: 10px; width: 60%;">
            <p>{{ this.dangerEvents / this.totalEvents | percent:'1.0-3' }}</p>
            <mat-progress-bar mode="determinate" class="warnProgressBar" [value]="(this.dangerEvents / this.totalEvents) * 100">
            </mat-progress-bar>
        </div>
    </div>

    <div *ngIf="mapReady && (mostDangerousPlaces !== undefined && dataForHeatmap !== undefined)"
        id="intro-roadrisk-map" class="appearing mt-5">

        <h2 class="text-center mt-4">{{ 'ROAD_RISK.DANGER_EVENTS' | translate }}</h2>
        <urban-heatmap-people-maps
            [heatmapElements]='dataForHeatmap' [maxValueHeatmap]='maxValueForHeatmap' class="mapPanel"
            [passedLastUpdate]='lastUpdate' [passedMarkers]="mostDangerousPlaces" [darkModeStatus]="isDarkActive" (mapDragEnd)="onMapDragEnd($event)">
        </urban-heatmap-people-maps>

        <div id="intro-roadrisk-meters" class="d-flex flex-row justify-content-between mt-2">
            <div class="d-flex flex-row flex-wrap mr-2 justify-content-center align-items-center">
                <span class="text-center">{{ 'ROAD_RISK.MIN_SEVERITY_RATE' | translate }}</span>
                <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <mat-slider class="pt-0 pb-0 ml-1 mr-1" max="5" min="1" step="1" color="primary" thumbLabel=true
                        tickInterval=1 (change)="changeMinRate($event)" [value]="mostDangerousEventsRequest.Rate">
                    </mat-slider>
                    <span>{{ mostDangerousEventsRequest.Rate }}</span>
                </div>
            </div>
            <div class="d-flex flex-row flex-wrap ml-2 justify-content-center align-items-center">
                <span class="text-center">{{ 'ROAD_RISK.MAX_ACCIDENTS' | translate }}</span>
                <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <mat-slider class="pt-0 pb-0 ml-1 mr-1" max="50" min="10" step="10" color="primary" thumbLabel=true
                        tickInterval=10 (change)="changeMaxNumberOfMarkers($event)" [value]="maxNumberOfMarkers">
                    </mat-slider>
                    <span>{{ maxNumberOfMarkers }}</span>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="mapReady && dataForHeatmap?.length === 0 && mostDangerousPlaces !== undefined"
        class="d-flex flex-column align-items-center justify-content-center appearing noDataIllustration">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (mostDangerousPlaces?.length > 0 ? 'HEATMAP_WEATHER.NO_DATA' : 'GENERAL.NO_DATA') | translate }}</h2>
        <div class="noDataActions">
            <button type="button" class="btn outlineActionButton" (click)="goToMain()">{{ 'GENERAL.RETURN_DASHBOARD' | translate }}</button>
        </div>
    </div>
</div>
