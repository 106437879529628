import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MainState } from '../../store/main/main.reducer';
import * as MainActions from '../../store/main/main.actions';
import { WeatherStationRequest } from '../models/weatherstation/weatherstationRequest';
import { LoaderService } from './loader/loader.service';
import { WeatherEventsAverageDomainRequest, WeatherEventsChartDeviceRequest, WeatherEventsDeviceRequest, WeatherEventsLatestDomainRequest } from '../models/weatherstation/weatherstationEvents';
import { WeatherStationLimit } from '../models/weatherStationLimit';
import { WeatherStationEvent } from '../models/weatherstation/weatherStationEvent';

@Injectable({
    providedIn: 'root'
})

export class WeatherStationApiService {
    constructor(private http: HttpClient, private readonly loaderService: LoaderService, private store: Store<MainState>) { }

    public getWeatherStationsData(request: WeatherStationRequest): Observable<any> {
        return this.http.post(
            `int.env.data.api`,
            {
                "device": request.Device,
                "start": request.Start,
                "end": request.End
            }
        )
    }

    public weatherStationLimits(): Observable<WeatherStationLimit[]> {
      return this.http.get<any>(`uv.domain.weatherstationlimits.api`).pipe(
        map(res => res.Limits),
        catchError(err => {
          this.loaderService.hide()
          return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
        })
      )
    }
}
