import { NewsDialogComponent } from '../../../shared/dialogs/news-dialog/news-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { NewsApiService } from '../../../shared/services/news-api.service';
import { News } from '../../../shared/models/news';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { UserRoles } from '../../../shared/models/userRoles';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from '../../../shared/services/api.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public newsList: News[] = [];
  public userRoles: UserRoles['Roles'];
  public isAdmin: boolean = false;
  public cliccedSeeMore: boolean = false;
  public maxLength: number = 100;
  public filteredData: News[] = [];
  public displayedColumns = ['Date', 'Created', 'Body', 'Source', 'Detail'];
  private maxFileSize: number;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private apiService: ApiService,
    private newsApiService: NewsApiService,
    private passDataService: PassDataService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      this.isAdmin = this.userRoles.some(x => x.Name == 'Administrators' || x.Name == 'Domain admin');
    });

    this.loadNews();
  }

  private loadNews(): void {
    this.newsApiService.getAllNews().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.newsList = result;
        this.filteredData = this.newsList;

        this.apiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clientSettings => {
          if (clientSettings !== undefined && clientSettings.max_news_image_size !== undefined) {
            let value = clientSettings.max_news_image_size;
            this.maxFileSize = +value;
          }
        });
      }
    });
  }

  public editNews(news: News): void {
    this.newsApiService.getNews(news.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(remoteNews => {
      if (remoteNews) {
        const editNewsDialogRef = this.dialog.open(NewsDialogComponent, {
          data: { currentNews: remoteNews, maxFileSize: this.maxFileSize },
          width: '50%',
          minWidth: 450
        });
        editNewsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedNews => {
          if (updatedNews) {
            const editNewsConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
              disableClose: false
            });
            editNewsConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                this.newsApiService.updateNews(updatedNews).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                  if (x) {
                    this.newsApiService.getAllNews().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedNews => {
                      this.newsList = updatedNews;
                    });
                  }
                  this.loadNews();
                });
              }
            });
          }
        });
      }
    });
  }

  public deleteNews(id: string): void {
    const addNewsConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addNewsConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.newsApiService.deleteNews(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this.newsApiService.getAllNews().pipe(takeUntil(this.ngUnsubscribe)).subscribe(news => {
              this.newsList = news;
              this.loadNews();
            });
          }
        });
      }
    });
  }

  public addNews(): void {
    const addNewsDialogRef = this.dialog.open(NewsDialogComponent, {
      data: { maxFileSize : this.maxFileSize},
      width: '50%',
      minWidth: 450
    });

    addNewsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(news => {
      if (news) {
        const addNewsConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addNewsConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.newsApiService.addNews(news).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this.newsApiService.getAllNews().pipe(takeUntil(this.ngUnsubscribe)).subscribe(news => {
                  this.newsList = news;
                  this.loadNews();
                });
              }
            });
          }
        });
      }
    });
  }

  public seeMore(news: News): void {
    this.cliccedSeeMore = true;

    const seeMoreNewsDialogRef = this.dialog.open(NewsDialogComponent, {
      data: { currentNews: news, seeMore: this.cliccedSeeMore, maxFileSize : this.maxFileSize },
      width: '50%'
    });

    seeMoreNewsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.cliccedSeeMore = false
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.newsList?.filter((news: News) =>
      [
        news.Date ? new Date(news.Date * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): '',
        news.Date ? new Date(news.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): '',
        news.Title?.toLowerCase(), 
        news.Source?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
