import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataList } from '../../models/ChartDataList';
import { ServiceEventItem } from '../../models/service/serviceEventResponse';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-crosswalk-widget',
  templateUrl: './crosswalk-widget.component.html',
  styleUrls: ['./crosswalk-widget.component.scss']
})
export class CrosswalkWidgetComponent implements OnChanges {
  @Input('darkModeStatus') public isDarkActive: boolean;
  @Input('crosswalkEvents') public crosswalkEvents: ServiceEventItem[];

  public dataReady: boolean = false;
  public translationsReady: boolean = false;
  public noDataOnChart: boolean = true;
  public totalCrossingPerColor: ChartDataList;
  public dataDescriptions: string[];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private translate: TranslateService,
    private mainService: MainSubscriptionsService,
    private router: Router)
  { }

  private calculateTotalStats(data: ServiceEventItem[]): ChartDataList {
    let totalCrossingPerColor: ChartDataList = {
      '0': 0, //Red
      '1': 0, //Yellow
      '2': 0 //Green
    };

    return data.reduce((totalCrossingPerColor, currentData) => {
      currentData.Events.forEach((event) => {
        if (['Total', 'Status'].every(key => Object.keys(event.Body).includes(key))) {
          totalCrossingPerColor[event.Body.Status] += event.Body.Total;
        }
      });

      this.dataReady = true;
      return totalCrossingPerColor;
    }, totalCrossingPerColor);
  }

  private getTranslations(): void {
    this.translate.get(['CROSSWALK.GREEN', 'CROSSWALK.YELLOW', 'CROSSWALK.RED']).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.dataDescriptions = [];
      const colorTranslations = {
        '0': 'CROSSWALK.RED',
        '1': 'CROSSWALK.YELLOW',
        '2': 'CROSSWALK.GREEN',
      };

      Object.values(colorTranslations).forEach((value) => {
        this.dataDescriptions.push(res[value]);
      });

      this.translationsReady = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['crosswalkEvents']) {
      const crosswalkEvents = changes['crosswalkEvents'];
      if (crosswalkEvents.currentValue !== crosswalkEvents.previousValue && crosswalkEvents.firstChange == false) {
        if (this.crosswalkEvents?.length > 0) {
          this.noDataOnChart = false;
          this.totalCrossingPerColor = this.calculateTotalStats(this.crosswalkEvents);
          this.getTranslations();

          this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.getTranslations();
          });
        } else {
          this.noDataOnChart = true;
        }
      }
    }
  }

  public goToCrosswalkDashboard(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'dashboard' });
    this.router.navigate(['main/smart-crosswalk']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
