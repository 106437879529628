import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'urban-app-support',
  templateUrl: './app-support.component.html',
  styleUrls: ['./app-support.component.scss']
})
export class AppSupportComponent implements OnInit {

  public isLittleScreen: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
