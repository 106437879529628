import { Data, Params, RouterStateSnapshot } from "@angular/router"
import { RouterStateSerializer } from "@ngrx/router-store"

interface CustomRouterState {
  url: string;
  params: Params,
  queryParams: Params,
  data: Data
}

//per informazioni sul perché di questo file controllare qui: https://github.com/ngrx/platform/issues/1781
//con i moduli lazy loaded non era possibile far funzionare la AuthGuard.

export class CustomRouterStateSerializer implements RouterStateSerializer<CustomRouterState> {
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    let route = routerState.root

    while (route.firstChild) {
      route = route.firstChild
    }

    const state: CustomRouterState = {
      url: routerState.url || '/',                  // this is the important line
      params: route.params,
      queryParams: routerState.root.queryParams,
      data: route.data
    }
    return state
  }
}
