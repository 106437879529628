import { LocationProperty } from './../../../shared/models/location';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {UserRoles} from "../../../shared/models/userRoles";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AdministratorApiService} from "../../../shared/services/administrator-api.service";
import {ApiService} from "../../../shared/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {PassDataService} from "../../../shared/services/pass-data/pass-data.service";
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {ConfirmationDialogComponent} from "../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {LocationEvent} from "../../../shared/models/locationEvent";
import {LocationEventRequest} from "../../../shared/models/administrator/locationEventRequest";
import {PropertyDialogComponent} from "../../../shared/dialogs/property-dialog/property-dialog.component";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-location-event',
  templateUrl: './location-event.component.html',
  styleUrls: ['./location-event.component.scss']
})
export class LocationEventComponent implements OnInit, OnDestroy {

  public locationEvent: LocationEvent;
  public propertiesColumns: string[] = ['Key', 'Value', 'Detail'];
  public properties: LocationProperty[] = [];
  public filteredData: LocationProperty[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public error: string | null;
  public userRoles: UserRoles['Roles'];
  private locationEventRequest: LocationEventRequest;
  private locationEventId: string;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    description: [{value: '', disabled: false}, Validators.required],
    start: [{value: 0, disabled: 0}],
    end: [{value: null, disabled: 0}]
  });

  constructor(
    private router: Router,
    private administratorApiService: AdministratorApiService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          this.locationEventId = navInfo.Id;
        } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
          return;
        }

        this._initialize();
      });
    });
  }

  private _initialize(): void {
    this.apiService.locationEventGet(this.locationEventId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.locationEvent = result;

        this.form.controls.name.setValue(this.locationEvent.Name);
        this.form.controls.description.setValue(this.locationEvent.Description);
        this.form.controls.start.setValue(new Date(this.locationEvent.Start * 1000));
        this.form.controls.end.setValue(this.locationEvent.End && this.locationEvent.End > 0 ? new Date(this.locationEvent.End * 1000) : null);
      
        this.properties = this.getPropertiesTableData(this.locationEvent.Properties);
        this.filteredData = this.properties;
      }
      else {
        this.mainService.setNavigationInfoComand({ Back: true });
        this.router.navigate(['main/location-events']);
      }
    });
  }

  private getPropertiesTableData(properties: Record<string, string>): LocationProperty[] {
    let locationProperties: LocationProperty[] = [];

    Object.keys(properties).forEach(key => {
      locationProperties.push({
        Key: key,
        Value: properties[key]
      });
    });

    return locationProperties;
  }

  public update(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {

          this.locationEventRequest = {
            Id: this.locationEvent.Id,
            Name: this.form.controls.name.value,
            Description: this.form.controls.description.value,
            Start: this.reformatDate(this.form.controls.start.value),
            End: this.form.controls.end.value ? this.reformatDate(this.form.controls.end.value) : null,
            Location: this.locationEvent.Location.Id
          }

          this.administratorApiService.locationEventUpdate(this.locationEventRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this._initialize();
            }
          })
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  public addProperty(): void {
    const addPropertyDialogRef = this.dialog.open(PropertyDialogComponent);

    addPropertyDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newProperty => {
      if (newProperty) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.setLocationEventProperty(this.locationEventId, newProperty.key, newProperty.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public openPropertyDetail(key: string, value: string): void {
    const openPropertyDialogRef = this.dialog.open(PropertyDialogComponent, {
      data: {key: key, value: value}
    });

    openPropertyDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedProperty => {
      if (updatedProperty) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.setLocationEventProperty(this.locationEventId, updatedProperty.key, updatedProperty.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public deleteProperty(key: string): void {
    if (!key) {
      return;
    }

    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.administratorApiService.setLocationEventProperty(this.locationEventId, key, '').pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    });
  }

  public reformatDate(dateToFormat: string): number {
    let currentDate = new Date(dateToFormat);
    return Math.round(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).getTime() / 1000);
  }

  public setDate(date: Date): number {
    return +date;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.properties?.filter((property: LocationProperty) =>
      [property.Key?.toLowerCase(), property.Value?.toLowerCase()]
      .some((field: string) => field.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
