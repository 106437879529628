import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthSubscriptionsService } from '../../../shared/services/auth-subscriptions/auth-subscriptions.service';
import { PasswordValidator } from '../recovery/password-validator';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-reset-expired',
  templateUrl: './reset-expired.component.html',
  styleUrls: ['./reset-expired.component.scss']
})
export class ResetExpiredComponent implements OnInit, OnDestroy {

  public regexPassword : string;
  public form: UntypedFormGroup;
  public error: string | null;
  public hideOldPassword: boolean = true;
  public hideNewPassword: boolean = true;
  public hideConfirmedNewPassword: boolean = true;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isShortScreen: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerHeight > 700) {
      this.isShortScreen = false;
    } else {
      this.isShortScreen = true;
    }
  }

  constructor(
    public fb: UntypedFormBuilder,
    private router: Router,
    private passDataService: PassDataService,
    private authService: AuthSubscriptionsService
    ) { }

  ngOnInit(): void {
    this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
      this.regexPassword = config?.Settings?.regex_password;
    });

    this.form = this.fb.group({
      username: ['', Validators.required],
      oldPassword: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.pattern(this.regexPassword)])],
      confirmedNewPassword: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.pattern(this.regexPassword)])]
    }, {
      validator: PasswordValidator.MatchPassword // your validation method
    })

    if (window.innerHeight > 700) {
      this.isShortScreen = false;
    } else {
      this.isShortScreen = true;
    }
  }

  back(){
    this.router.navigateByUrl('auth/login');
  }

  async update(){
    if(this.form.valid){

      this.form.controls.oldPassword.setValue(await this.getSha256(this.form.controls.oldPassword.value));
      this.form.controls.newPassword.setValue(await this.getSha256(this.form.controls.newPassword.value));
      this.form.controls.confirmedNewPassword.setValue(await this.getSha256(this.form.controls.confirmedNewPassword.value));

      this.authService.changePasswordComand(this.form.value);
      this.router.navigateByUrl('auth/login');
    } else {
      if(
        this.form.controls.username.errors?.required == true ||
        this.form.controls.oldPassword.errors?.required == true ||
        this.form.controls.newPassword.errors?.required == true ||
        this.form.controls.confirmedNewPassword.errors?.required == true
        ) {
          this.error = "ERROR_EMPTY";
      }
      else {
        if(this.form.controls.oldPassword.errors?.minlength){
          this.error = "ERROR_LENGTH_1";
        }
        else {
          if(this.form.controls.oldPassword.errors?.pattern){
            this.error = "ERROR_SYNTAX";
          }
          else {
            if(this.form.controls.newPassword.errors?.minlength){
              this.error = "ERROR_LENGTH_2";
            }
            else {
              if(this.form.controls.newPassword.errors?.pattern){
                this.error = "ERROR_SYNTAX";
              }
              else {
                if (this.form.controls.confirmedNewPassword.errors?.MatchPassword == true) {
                  this.error = "ERROR_EQUAL";
                }
              }
            }
          }
        }
      }
    }
  }

  async getSha256(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);

    let hashBuffer: ArrayBuffer = await window.crypto.subtle.digest('SHA-256', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));                     
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); 
    return hashHex;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
