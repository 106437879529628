import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ServerSetting} from "../../models/administrator/serverSetting";

@Component({
  selector: 'urban-server-setting-dialog',
  templateUrl: './server-setting-dialog.component.html',
  styleUrls: ['./server-setting-dialog.component.scss']
})
export class ServerSettingDialogComponent implements OnInit {

  private serverSetting: ServerSetting;

  public isAdd = true;

  public form: UntypedFormGroup = this.formBuilder.group({
    key: [{value: '', disabled: false}, Validators.required],
    value: [{value: '', disabled: false}, Validators.required],
    order: [{value: 0, disabled: false}]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<ServerSettingDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        serverSetting: ServerSetting
      }
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.serverSetting) {
      this.serverSetting = this.data.serverSetting;
      this.isAdd = false;
      this.form.controls.key.setValue(this.serverSetting.Key);
      this.form.controls.value.setValue(this.serverSetting.Value);
      this.form.controls.order.setValue(this.serverSetting.Order);
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.serverSetting = {
        Id: this.serverSetting?.Id || null,
        Key: this.form.controls.key.value,
        Value: this.form.controls.value.value,
        Order: +this.form.controls.order.value
      };

      this.dialogRef.close(this.serverSetting);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
