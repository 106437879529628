import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddNotificationDialogComponent } from '../../../../shared/dialogs/add-notification-dialog/add-notification-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { User } from '../../../../shared/models/loginResponse';
import { UVNotification, UVNotificationDetail } from '../../../../shared/models/notification';
import { ApiService } from '../../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { NotificationApiService } from '../../../../shared/services/notification-api.service';

@Component({
  selector: 'urban-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {

  public notificationDetails: UVNotificationDetail[] = [];
  private userList: User[] = [];
  public notifications: UVNotification[] = [];
  public displayedColumns: string[] = ['Created', 'DestinationType', 'Kind', 'Expiration', 'Detail'];
  public filteredData: UVNotification[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public todayTimestamp: number = 0;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private notificationApiService: NotificationApiService,
    private dialog: MatDialog,
    private apiService: ApiService,
    private mainService: MainSubscriptionsService,
  ) { }



  ngOnInit(): void {
    this.todayTimestamp = new Date()?.getTime();
    this._loadNotifications();
    this._loadUsers();
  }

  private _loadNotifications(): void {
    this.notificationApiService.getAllNotificationsList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response && response !== undefined) {
        this.notifications = [];
        response.forEach((notification) => {
          let extendedDestinationType: string;

          if (notification.DestinationType === 'u') {
            extendedDestinationType = 'User';
          } else if (notification.DestinationType === 'd') {
            extendedDestinationType = 'Domain';
          } else if (notification.DestinationType === 'a') {
            extendedDestinationType = 'Broadcast';
          }

          this.notifications.push({
            Id: notification.Id,
            Created: notification.Created,
            DestinationType: extendedDestinationType,
            Destination: notification.Destination,
            Kind: notification.Kind,
            Payload: notification.Payload,
            Expiration: notification.Expiration
          });
        });

        this.filteredData = this.notifications;
      }
    });
  }

  private _loadUsers(): void {
    this.apiService.listUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response && response !== undefined) {
        this.userList = response;
      }
    });
  }

  public setToExpired(notification: UVNotification): void {
    if (!notification && notification === undefined) {
      return;
    }

    const expireDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    expireDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.notificationApiService.expireNotification(notification.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(expireResponse => {
          if (expireResponse) {
            this._loadNotifications();
          }
        })
      }
    });
  }

  public goToNotificationDetail(notification: UVNotification): void {

    if (!notification || notification === undefined) {
      return;
    }

    this.mainService.setNotificationToShowComand(notification);
    this.mainService.setNavigationInfoComand({ Id: notification.Id, BackRoute: 'notification-list' });
    this.mainService.updateAllDataAndNavigateComand('notification-detail');
  }

  public openAddNotificationDialog(): void {
    const addNotificationDialogRef = this.dialog.open(AddNotificationDialogComponent, {
      data: { users: this.userList },
      width: '50%'
    });

    addNotificationDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newNotification => {
      if (newNotification) {
        const addNotificationConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addNotificationConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.notificationApiService.createNotification(newNotification).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._loadNotifications();
                this._loadUsers();
              }
            });
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }
  
  public applyFilterString(filterValue: string): void {
    this.filteredData = this.notifications?.filter((notification: UVNotification) =>
      [
        notification.DestinationType.toLowerCase(), 
        notification.Created ? new Date(notification.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : '',
        notification.DestinationType?.toLowerCase(),
        notification.Expiration ? new Date(notification.Expiration * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : ''
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
