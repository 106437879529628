<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{currentRole?.Name}}
        </span>
    </div>

    <div class="customTable">
        <div class="customTableRadius justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Url">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.RESOURCE_URL' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.Url}} </td>
                </ng-container>

                <ng-container matColumnDef="Title">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.RESOURCE_TITLE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.Title">
                            {{ 'SIDEBAR.' + element.Title | translate }}
                        </span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="EditState">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div>
                            <mat-slide-toggle [checked]="isToggled(element.Id)"
                                (change)="toggleResource($event, element.Id)" color="primary">
                            </mat-slide-toggle>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
