import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ChartDataDescription, ChartDataList } from '../../models/ChartDataList';
import { ServiceEventItem } from '../../models/service/serviceEventResponse';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'urban-traffic-widget',
  templateUrl: './traffic-widget.component.html',
  styleUrls: ['./traffic-widget.component.scss']
})
export class TrafficWidgetComponent implements OnChanges, OnDestroy {
  @Input('darkModeStatus') public isDarkActive: boolean;
  @Input('trafficEvents') public trafficEvents: ServiceEventItem[];

  public averageDataToPass: ChartDataList = {};
  public dataDescriptions: ChartDataDescription[] = [];
  public dataTranslations: string[] = [];
  private translateSubscription: Subscription = new Subscription();
  public trafficLevelTranslation: string;
  public dataReady: boolean = false;
  public translationsReady: boolean = false;
  public noDataOnChart: boolean = true;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private translate: TranslateService,
    private mainService: MainSubscriptionsService,
    private router: Router)
  { }

  private calculateAverageStats(data: ServiceEventItem[]): ChartDataList {
    let stats: ChartDataList = {};
    let totalEvents = 0;

    if (data?.length > 0) {
      data.forEach(item => {
        item.Events.forEach(event => {
          totalEvents++;
          event.Body.Categories?.forEach((category, index) => {
            stats[this.dataTranslations[index]] = (stats[this.dataTranslations[index]] || 0) + category.Level
          });
        });
      });

      Object.keys(stats).forEach(key => {
        stats[key] = Math.round(stats[key] / totalEvents);
      });

      this.dataReady = true;
      return stats;
    }
  }

  private initializeData(trafficEvents: ServiceEventItem[]): void {
    if (trafficEvents?.length > 0) {
      const categoryNames = [];

      trafficEvents.forEach(person => {
        person.Events.forEach(event => {
          event.Body.Categories?.forEach(category => {
            if (!categoryNames.includes(category.Name)) {
              categoryNames.push(category.Name);
            }
          });
        });
      });

      this.setDataDescriptionsAndCalculateAverages(categoryNames);
    }
  }

  private setDataDescriptionsAndCalculateAverages(phrases: string[]): void {
    this.translateSubscription.unsubscribe();
    let prefix: string = 'SMART_TRAFFIC.';

    this.getTranslations(phrases, prefix).subscribe(() => {
      this.averageDataToPass = this.calculateAverageStats(this.trafficEvents);
    });

    this.translateSubscription = this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, prefix).subscribe();
    });
  }

  private getTranslations(phrases: string[], prefix: string): Observable<void> {
    return new Observable<void>(translations => {
      this.translate.get([...phrases.map((phrase: string) => prefix + phrase.toUpperCase()), prefix + 'LEVEL']).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.dataDescriptions = [];
        this.dataTranslations = [];
  
        phrases.forEach((phrase: string) => {
          let translation: string = res[prefix + phrase.toUpperCase()];
          let dataDescription: ChartDataDescription = {
            Original: phrase,
            Translated: translation !== (prefix + phrase.toUpperCase()) ? translation : phrase
          };
          this.dataDescriptions.push(dataDescription);
          this.dataTranslations.push(dataDescription.Translated);
        });
  
        this.trafficLevelTranslation = res[prefix + 'LEVEL'];
        this.translationsReady = true;

        translations.next();
        translations.complete();
      });
    });
  }

  public goToTrafficDashboard(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'dashboard' });
    this.router.navigate(['main/smart-traffic']);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['trafficEvents']) {
      const trafficEvents = changes['trafficEvents'];
      if (trafficEvents.currentValue !== trafficEvents.previousValue && trafficEvents.firstChange == false) {
        if (this.trafficEvents?.length > 0) {
          this.noDataOnChart = false;
          this.initializeData(this.trafficEvents);
        } else {
          this.noDataOnChart = true;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
