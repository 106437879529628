import { EntityField } from './../../models/content-management/entityField';
import { Component, Inject, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileContentRequest } from '../../models/content-management/contentRequest';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'urban-content-data-dialog',
  templateUrl: './content-data-dialog.component.html',
  styleUrls: ['./content-data-dialog.component.scss']
})
export class ContentDataDialogComponent implements OnInit {

  private contentName: string;
  public contentDataModel: EntityField[];
  public contentData: any;
  private schema: EntityField[];
  private schemaMatrixes: any[][][] = [];
  private schemaMatrixTypes: string[] = ['string', 'double', 'bool'];
  private typesTranslations: Record<string, string> = {
    string: 'string',
    double: 'number',
    bool: 'boolean'
  };
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ContentDataDialogComponent>,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    @Inject(MAT_DIALOG_DATA) public data:
    {
      contentName: string,
      contentModel: EntityField[],
      contentData?: any
    }
  ) { }

  ngOnInit(): void {
    this._initialize();

    this.passDataService.currentDarkModeStatus$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(res => {
            this.isDarkActive = res === true;
          });
  }

  private _initialize(): void {
    let wrongContentData: boolean;
      if (this.data.contentName) {
        this.contentName = this.data.contentName;
      }

      if (this.data.contentModel) {
        this.contentDataModel = this.data.contentModel;

        if(this.data.contentData) {
          this.contentData = this.data.contentData;

          this.searchSchema(this.contentDataModel, this.contentData);
          wrongContentData = !this.checkChildren(this.contentDataModel, this.contentData);
        }
        else {
          wrongContentData = true;
        }

        if (wrongContentData) {
          this.contentData = new Object();
          this.createContentData(this.contentDataModel, this.contentData);
        }
      }
  }

  public addArrayElement(array: any[], field: EntityField): void {
    if (field.type === 'object[]' && field.children && field.children.length > 0) {
      //array of objects known
      let newElement: Object = this.addObjectTree(field.children);
      array.push(newElement);
    }
    else if (!field.children) {
      //array of simple types
      if (field.type === 'string[]') {
        array.push('');
      }
      else if (field.type === 'number[]') {
        array.push(0);
      }
      else if (field.type === 'schemaMatrix' && this.schema?.length > 0) {
        let arrayElement: Array<any> = [];

        this.schema.forEach((field: EntityField) => {
          if (field.name !== '') {
            if (field.type === 'string') {
              arrayElement.push('');
            }
            else if (field.type === 'double') {
              arrayElement.push(0);
            }
            else if (field.type === 'bool') {
              arrayElement.push(false);
            }
          }
        });

        array.push(arrayElement);
      }
      else {
        array.push(null);
      }
    }
  }

  private addObjectTree(children: EntityField[]): Object {
    let newElement: Object = new Object();
    children.forEach((property: EntityField) => {
      if (property.type && property.type !== 'object') {
        if(property.type.includes('[]')) {
          //field of type array
          newElement[property.name] = [];
        }
        else {
          //field of simple type
          if (property.type === 'string') {
            newElement[property.name] = '';
          }
          else if(property.type === 'number') {
            newElement[property.name] = 0;
          }
          else if(property.type === 'boolean') {
            newElement[property.name] = false;
          }
          else if(property.type === 'schemaMatrix') {
          //schemaMatrix type
            newElement[property.name] = [];
            this.schemaMatrixes.push(newElement[property.name]);
          }
          else {
            newElement[property.name] = null;
          }
        }
      }
      else if (property.children) {
        //field of type object
        newElement[property.name] = this.addObjectTree(property.children);
      }
    });
    return newElement;
  }

  public updateSchemaProperty(newValue: string, data: EntityField, fieldName: string, schemaData: EntityField[]): void {
    let previousValue: string = data[fieldName];
    let schemaIndexInvolved: number = this.schema.findIndex(schemaProperty => schemaProperty.name === data.name);
    let schemaFieldIndex: number = schemaData.findIndex(schemaProperty => schemaProperty.name === data.name);
    let newFieldIndex: number = -1;
    if (schemaFieldIndex !== -1) {
      newFieldIndex = this.schema.findIndex((schemaProperty: EntityField) =>
        schemaData.find((child: EntityField, index: number) => index > schemaFieldIndex && child.name === schemaProperty.name) !== undefined
      );
    }
    if (newFieldIndex === -1) {
      newFieldIndex = this.schema.length;
    }

    newValue = newValue.replace('#', '');
    data[fieldName] = newValue;
    if (fieldName === 'name' && newValue !== '') {
      newValue = this.checkSchemaPropertyNameOccurrences(schemaData, data);
    }

    if (data.name !== undefined && data.type !== undefined && newValue !== previousValue) {
      if (fieldName === 'name' && this.schemaMatrixTypes.includes(data.type)) {
        if (newValue === '') { //new name empty
          if (schemaIndexInvolved !== -1) {
            this.schema.splice(schemaIndexInvolved, 1);
            this.updateSchemaMatrixes('delete', schemaIndexInvolved);
          }
        }
        else if (previousValue === '') { //old name empty => new name not empty (they are disequal)
          if (schemaIndexInvolved === -1) {
            let newSchemaProperty: EntityField = {
              name: data.name,
              type: data.type
            };
            if (newFieldIndex < this.schema.length) {
              this.schema.splice(newFieldIndex, 0, newSchemaProperty);
            }
            else {
              this.schema.push(newSchemaProperty);
            }
          }
          this.updateSchemaMatrixes('add', newFieldIndex);
        }
        else if (schemaIndexInvolved !== -1) {
          this.schema[schemaIndexInvolved].name = newValue;
        }
      }
      else if (fieldName === 'type' && data.name !== '') {
        if (this.schemaMatrixTypes.includes(previousValue) && !this.schemaMatrixTypes.includes(newValue)) { //new type doesn't match anymore
          if (schemaIndexInvolved !== -1) {
            this.schema.splice(schemaIndexInvolved, 1);
            this.updateSchemaMatrixes('delete', schemaIndexInvolved);
          }
        }
        else if (!this.schemaMatrixTypes.includes(previousValue) && this.schemaMatrixTypes.includes(newValue)) { // new type matches
          if (schemaIndexInvolved === -1 && newFieldIndex && newFieldIndex !== -1) {
            let newSchemaProperty: EntityField = {
              name: data.name,
              type: data.type
            };
            if (newFieldIndex < this.schema.length) {
              this.schema.splice(newFieldIndex, 0, newSchemaProperty);
            }
            else {
              this.schema.push(newSchemaProperty);
            }
          }
          this.updateSchemaMatrixes('add', newFieldIndex);
        }
        else if (schemaIndexInvolved !== -1) {
          this.schema[schemaIndexInvolved].type = newValue;
        }
      }
    }
  }

  private checkSchemaPropertyNameOccurrences(schemaData: EntityField[], schemaDataProperty: EntityField): string {
    let schemaPropertiesSameNamed: EntityField[] = schemaData.filter((schemaProperty: EntityField) =>
    schemaProperty.name.split('#')[0] === schemaDataProperty.name);
    let schemaPropertyNameOccurences: number = schemaPropertiesSameNamed.length;
    let lastNameOccurenceCount: number = Math.max(...schemaPropertiesSameNamed.map((schemaProperty: EntityField) => {
      let nameSplitted: string[] = schemaProperty.name.split('#');
      if (nameSplitted.length === 2) {
        return +nameSplitted[1];
      }
      else {
        return 0;
      }
    }));

    if (schemaPropertyNameOccurences > 1 || lastNameOccurenceCount > 0) {
      schemaDataProperty.name += '#' + Math.max(schemaPropertyNameOccurences, lastNameOccurenceCount + 1);
    }

    return schemaDataProperty.name;
  }

  private updateSchemaMatrixes(operation: 'add' | 'delete', index: number): void {
    if (this.schemaMatrixes?.length > 0) {
      this.schemaMatrixes.forEach((schemaMatrix: any[][]) => {
          if (schemaMatrix.length > 0) {
          schemaMatrix.forEach((arrayElement: any[]) => {
            if (operation === 'add') { //ADD
              let newSchemaProperty: EntityField = this.schema[index];
              if (newSchemaProperty.type === 'string') {
                if (index < arrayElement.length) {
                  arrayElement.splice(index, 0, '');
                }
                else {
                  arrayElement.push('');
                }
              }
              else if (newSchemaProperty.type === 'double') {
                if (index < arrayElement.length) {
                  arrayElement.splice(index, 0, 0);
                }
                else {
                  arrayElement.push(0);
                }
              }
              else if (newSchemaProperty.type === 'bool') {
                if (index < arrayElement.length) {
                  arrayElement.splice(index, 0, false);
                }
                else {
                  arrayElement.push(false);
                }
              }
            }
            else if (index < arrayElement.length) { //REMOVE
              arrayElement.splice(index, 1);
            }
          });
        }
      });
    }
  }

  public removeArrayElement(array: any[], index: number, isSchema: boolean ): void {
    if (array?.[index] !== undefined) {
      const updateUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          array.splice(index, 1);
          if (isSchema) {
            this.updateSchemaMatrixes('delete', index);
          }
        }
      });
    }
  }

  public async saveChanges(): Promise<void> {
    let fileType: string = 'application/json';
    let fileName: string = (this.contentName ? this.contentName : 'test') + '.json';
    let base64Content: string;
    let fileContent: FileContentRequest;

    let file: File = new File([JSON.stringify(this.contentData)], fileName, { type: fileType });
    base64Content = await this.readAsyncFile(file);

    if (base64Content) {
      fileContent = {
        base64Content,
        fileName,
        mimeType: fileType
      };

      this.dialogRef.close(fileContent);
    }
  }

  private readAsyncFile(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (_) => {
        if (_.loaded === _.total){
          resolve(reader.result.toString().split(',')[1]);
        }
        else {
          reject();
        }
      };

      reader.onerror = function (error) {
        console.log("Error FileReader: ", error)
        reject();
      };
    });
  }

  public trackByFn(i: number) {
    return i;
  }

  public correctFieldNumberType(data: any, field: string, index?: number): void {
    if (index === undefined) {
      data[field] = +data[field];
    }
    else {
      data[field][index] = +data[field][index];
    }
  }

  private searchSchema(fields: EntityField[], data: any): boolean {
    let found: boolean = false;

    fields.forEach(field => {
      if (!found) {
        if (
          field.name === 'schema' && field.type === 'object[]' && field.children?.length >= 2 &&
          field.children.find(child => child.name === 'name') &&
          field.children.find(child => child.name === 'type') &&
          Array.isArray(data[field.name]) && data[field.name].length > 0 &&
          data[field.name].every((child: EntityField) =>
            child.name && this.schemaMatrixTypes.includes(child.type)
          ) &&
          this.schema === undefined
        ) {
          this.schema = [...data[field.name]];
          found = true;
        }
        else if (field.children?.length > 0 && (!field.type || ['object', 'object[]'].includes(field.type))) {
          found = this.searchSchema(field.children, data[field.name]);
        }
      }
    });

    return found;
  }

  private createContentData(fields: EntityField[], data: any): any {
    this.schema = undefined;
    fields.forEach(field => {
      if (field.type && field.type !== 'object') {
        if(!field.type.includes('[]')) { //SIMPLE TYPE
          if (field.type === 'string') {
            data[field.name] = "";
          }
          else if(field.type === 'number') {
            data[field.name] = 0;
          }
          else if(field.type === 'boolean') {
            data[field.name] = false;
          }
          else if(field.type === 'schemaMatrix') { //SCHEMA MATRIX TYPE
            data[field.name] = [];
            this.schemaMatrixes.push(data[field.name]);
          }
          else {
            data[field.name] = null;
          }
        }
        else if (field.type === 'string[]') {
            data[field.name] = new Array<string>();
        }
        else if(field.type === 'number[]') {
          data[field.name] = new Array<number>();
        }
        else if (field.type === 'object[]') { //ARRAY OF KNOWN OBJECT
          data[field.name] = new Array<any>();
        }
        else { //ARRAY OF UNKNOWN OBJECT (like product)
          //manage unknown children
          data[field.name] = new Array<any>();
        }
      }
      else if(field.children) { //OBJECT
        data[field.name] = new Object();
        this.createContentData(field.children, data[field.name]);
      }
    });
  }

  private checkChildren(fields: EntityField[], data: any): boolean {
    let typeCheck: boolean = true
    fields.forEach((field: EntityField) => {
      if (typeCheck) {
        if (field.type && field.type !== 'object') {
          if(!field.type.includes('[]') && data[field.name]) {
            if ((typeof data[field.name]).toString() !== field.type && field.type !== 'schemaMatrix') { //SIMPLE TYPE
              typeCheck = false;
            }
            else if (field.type === 'schemaMatrix' && this.schema?.length > 0 && data[field.name]) {
              if (data[field.name] && !Array.isArray(data[field.name])) {
                typeCheck = false;
              }
              else {
                data[field.name].forEach(schemaArrayElement => {
                  if (typeCheck && schemaArrayElement) {
                    if (!Array.isArray(schemaArrayElement) || schemaArrayElement.length !== this.schema.length) {
                      typeCheck = false;
                    }
                    else {
                      schemaArrayElement.forEach((schemaSingleElement: any, index: number) => {
                        if (
                          this.schema.length <= index ||
                          (
                            schemaSingleElement !== null && schemaSingleElement !== undefined &&
                            (typeof schemaSingleElement).toString() !== this.typesTranslations[this.schema[index].type]
                          )
                        ) {
                          typeCheck = false;
                        }
                      });
                    }
                  }
                });
              }
            }
          }
          else if(['string[]', 'number[]'].includes(field.type)) { //ARRAY OF SIMPLE TYPE
            if (data[field.name] && (
                !Array.isArray(data[field.name]) ||
                data[field.name].find(element => (typeof element).toString() !== field.type.substring(0, field.type.length - 2)) !== undefined
              )
            ) {
              typeCheck = false;
            }
          }
          else if (field.type === 'object[]') { //ARRAY OF KNOWN OBJECT
            if (
              data[field.name] &&
              (
                !Array.isArray(data[field.name]) ||
                (
                  field.children &&
                  data[field.name].find(element => !this.checkChildren(field.children, element)) !== undefined
                )
              )
            ) {
              typeCheck = false;
            }
          }
          else { //ARRAY OF UNKNOWN OBJECT (like product)
            //manage unknown children
          }
        }
        else if (!this.checkChildren(field.children, data[field.name])) { //OBJECT
          typeCheck = false;
        }
      }
    });

    return typeCheck;
  }
}
