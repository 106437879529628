import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { Subject } from 'rxjs';
import { User } from 'src/app/shared/models/loginResponse';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';

@Component({
  selector: 'urban-deleted-users',
  templateUrl: './deleted-users.component.html',
  styleUrls: ['./deleted-users.component.scss']
})
export class DeletedUsersComponent implements OnInit, OnDestroy {

  public displayedColumns = ['Username', 'Email', 'Firstname', 'Lastname', 'Deleted', 'Actions'];
  public users: User[] = [];
  public filteredData: User[] = [];
  public isAdmin: boolean = false;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private passDataService: PassDataService,    
    private mainService: MainSubscriptionsService,
    private administrationApiService: AdministratorApiService,
    private router: Router) 
  { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.some(x => x.Name === 'Administrators')) {
        this.isAdmin = true;
        this.loadData();
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
    });
  }

  private loadData(): void {
    this.administrationApiService.listUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.users = res.filter(user => +user.Deleted >= 0);
      this.filteredData = this.users;
    });
  }

  public hardDelete(userId: string): void {
    if (this.isAdmin) {

      const hardDeleteUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      hardDeleteUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administrationApiService.hardDeleteUser(userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadData();
          });
        }
      });
    }
  }

  public sortBy(field: string): void {
    if (field === 'Deleted') {
      this.filteredData.sort((a, b) => {
        return new Date(b[field]).getTime() - new Date(a[field]).getTime();
      });
    } else {
      this.filteredData.sort((a, b) =>
        a[field].localeCompare(b[field])
      );
    }
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
