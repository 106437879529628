<div class="alertPanel d-flex justify-content-between align-items-center position-relative" [ngClass]="{'warnColorBorder': type === 'error',
    'warnAccentColorBorder': type === 'warning', 'accentColorBorder': type === 'info', 'overlayPanel' : overlayPanel}">
    <div class="alertContent d-flex align-items-start position-relative">
        <div>
            <i class="alertInfo" [ngClass]="{
                'warnColorText urbanicon-alert': type === 'error',
                'warnAccentColorText': type === 'warning', 
                'accentColorText': type === 'info',
                'urbanicon-info-outline' : type === 'info' || type === 'warning'}">
            </i>
        </div>
        <div class="d-flex flex-column">
            <p class="paragraphText" [ngClass]="{'darkText' : overlayPanel}"><strong *ngIf="boldPrefix !== ''">{{ boldPrefix }}</strong>
                {{ titleText }}
            </p>
            <p class="paragraphText" [ngClass]="{'darkText' : overlayPanel}">{{ descriptionText }}</p>
        </div>
    </div>

    <div class="alertContent d-flex align-items-center">
        <button *ngIf="buttonLabel" mat-stroked-button class="btn actionButton"
            (click)="execAction()"> {{ buttonLabel }}</button>
        <div class="pipeElement" [ngClass]="{'warnColorBG': type === 'error',
        'warnAccentColorBG': type === 'warning', 'accentColorBG': type === 'info'}"></div>
        <i class="urbanicon-close" (click)="closePanel()"></i>
    </div>
    <div class="position-absolute backgroundOpacity" [ngClass]="{'warnColorBG': type === 'error',
        'warnAccentColorBG': type === 'warning', 'accentColorBG': type === 'info'}">
    </div>
</div>