<h1 mat-dialog-title *ngIf="isAdd">{{ 'TASK_SCHEDULE.ADD' | translate }}</h1>
<h3 mat-dialog-title *ngIf="!isAdd">{{ 'TASK_SCHEDULE.UPDATE' | translate }} - {{ updateData.targetTaskSchedule.Id }}</h3>

<mat-dialog-content class="d-flex flex-column align-content-center">
    <form *ngIf="isAdd">
        <mat-form-field class="tableSearch mt-2" appearance="outline">
            <div matPrefix class="prefix">
                <i class="urbanicon-search"></i>
            </div>
            <mat-label>{{ 'DEVICE.SEARCH' | translate }}</mat-label>
            <input matInput [formControl]="selectedDeviceControl" [matAutocomplete]="auto" required autofocus="false">
            <button matSuffix mat-icon-button type="submit" *ngIf="selectedDeviceControl.value !== null && selectedDeviceControl.value !== ''" (click)="clearSelection()" matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                <i class="urbanicon-close"></i>
            </button>

            <mat-autocomplete class="overlayBG" #auto="matAutocomplete" [displayWith]="displayFn"
                (optionSelected)='deviceSelected($event.option.value)'>
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    <span class="urbanicon-{{ option.Model.Type?.Icon }}"></span>
                    {{option.Name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>

    <form class="example-section" [formGroup]="daysForm">
        <p>{{ 'TASK_SCHEDULE.SELECT_DATE' | translate }}</p>
        <p class="mb-1"><mat-checkbox color="primary" formControlName="mondayCheck">{{ 'GENERAL.MONDAY' | translate}}</mat-checkbox></p>
        <p class="mb-1"><mat-checkbox color="primary" formControlName="tuesdayCheck">{{ 'GENERAL.TUESDAY' | translate}}</mat-checkbox></p>
        <p class="mb-1"><mat-checkbox color="primary" formControlName="wednesdayCheck">{{ 'GENERAL.WEDNESDAY' | translate}}</mat-checkbox></p>
        <p class="mb-1"><mat-checkbox color="primary" formControlName="thursdayCheck">{{ 'GENERAL.THURSDAY' | translate}}</mat-checkbox></p>
        <p class="mb-1"><mat-checkbox color="primary" formControlName="fridayCheck">{{ 'GENERAL.FRIDAY' | translate}}</mat-checkbox></p>
        <p class="mb-1"><mat-checkbox color="primary" formControlName="saturdayCheck">{{ 'GENERAL.SATURDAY' | translate}}</mat-checkbox></p>
        <p class="mb-1"><mat-checkbox color="primary" formControlName="sundayCheck">{{ 'GENERAL.SUNDAY' | translate}}</mat-checkbox></p>

        <br>

        <mat-form-field class="messageArea">
            <mat-label>{{ 'TASK_SCHEDULE.JSON' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput [formControl]="jsonControl"
                      matTextareaAutosize required></textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ error | translate }}
        </mat-error>
    </form>

    <br>
    <p>{{ 'TASK_SCHEDULE.SELECT_HOUR' | translate }}</p>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <ngx-mat-timepicker [(ngModel)]="hourSelected" [disabled]=false [showSpinners]=true [showSeconds]=false>
        </ngx-mat-timepicker>
    </div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="update()">{{ 'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
