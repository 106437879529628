import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DeviceCommandType} from '../../models/device-command/deviceCommandType';

@Component({
  selector: 'urban-device-command-type-dialog',
  templateUrl: './device-command-type-dialog.component.html',
  styleUrls: ['./device-command-type-dialog.component.scss']
})
export class DeviceCommandTypeDialogComponent implements OnInit {

  private deviceCommandType: DeviceCommandType;

  public isAdd = true;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    options: [{value: '{}', disabled: false}]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<DeviceCommandTypeDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        deviceCommandType: DeviceCommandType
      }
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.deviceCommandType) {
      this.deviceCommandType = this.data.deviceCommandType;
      this.isAdd = false;
      this.form.controls.name.setValue(this.deviceCommandType.Name);

      if (this.deviceCommandType.Options) {
        this.form.controls.options.setValue(JSON.stringify(this.deviceCommandType.Options));
      }
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.deviceCommandType = {Id: this.deviceCommandType?.Id, Name: this.form.controls.name.value};

      if (this.form.controls.options.value) {
        this.deviceCommandType.Options = JSON.parse(this.form.controls.options.value);
      }

      this.dialogRef.close(this.deviceCommandType);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
