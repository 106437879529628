<h1 mat-dialog-title>{{ 'GENERAL.ADD' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.DEVICE_GROUP' | translate }}</mat-label>
            <mat-select formControlName="groupSelected">
                <mat-option *ngFor="let group of groups" value={{group.Id}}>{{group.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.DEVICE' | translate }}</mat-label>
            <mat-select formControlName="deviceSelected">
                <mat-option *ngFor="let device of devices" value={{device.Id}}>
                    <span class="urbanicon-{{ device.Model.Type?.Icon }}"></span>
                    {{device.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
