export class GooglePolygon {
  path: google.maps.LatLng[];
  info: InfoData;
  options : google.maps.PolygonOptions;

  constructor(path : google.maps.LatLng[], info: InfoData, options : google.maps.PolygonOptions) {
      this.path = path;
      this.info = info;
      this.options = options;
  }
}

export class InfoData {
  constructor(id: string, name: string, color: string) {
      this.id = id;
      this.name = name;
      this.color = color;
  }

  id: string;
  name: string;
  color: string;
}
