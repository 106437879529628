import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {interval, Subject} from "rxjs";
import {DeviceCommandRequest} from "../../../shared/models/device-command/deviceCommandRequest";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {ApiService} from "../../../shared/services/api.service";
import {PassDataService} from "../../../shared/services/pass-data/pass-data.service";
import {map, first, switchMap, takeUntil, tap} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Device} from "../../../shared/models/device";
import {DeviceCommandType} from "../../../shared/models/device-command/deviceCommandType";
import {AdministratorApiService} from "../../../shared/services/administrator-api.service";
import {ConfirmationDialogComponent} from "../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {DeviceNotification} from "../../../shared/models/device-command/deviceNotification";
import {
  DeviceNotificationDetailDialogComponent
} from "../../../shared/dialogs/device-notification-detail-dialog/device-notification-detail-dialog.component";
import {DeviceCommandService} from "../../../shared/services/device-command.service";
import {DeviceNotificationPollingServiceService} from "../../../shared/services/device-notification-polling.service";
import {LoaderService} from "../../../shared/services/loader/loader.service";
import {ClientSettings} from "../../../shared/models/clientSettings";
import {DeviceNotificationListResponse} from "../../../shared/models/device-command/deviceNotificationListResponse";

@Component({
  selector: 'urban-device-command',
  templateUrl: './device-command.component.html',
  styleUrls: ['./device-command.component.scss']
})
export class DeviceCommandComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup = this.formBuilder.group({
    command: [{value: '', disabled: false}, Validators.required],
    deviceCommandTypeSelected: [{value: 0, disabled: 0}, Validators.required],
    autoDelivery: [{value: false, disabled: 0}],
    expiration: [{value: null, disabled: 0}]
  });

  public deviceNotifications: DeviceNotification[] = [];
  displayedColumns: string[] = ['Id', 'Created', 'Device', 'Command', 'Detail'];
  deviceNotificationsDataSource = new MatTableDataSource<DeviceNotification>();

  private deviceCommandRequest: DeviceCommandRequest;
  public error: string | null = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public currentLanguage: string;
  public currentDevice: Device;
  public deviceCommandTypes: DeviceCommandType[];
  private notificationsPolling$: Subject<void> = new Subject<void>();
  private clientSettings: ClientSettings = new ClientSettings();
  private notificationPollingIntervalMs: number = 10000;
  public commandSent: boolean = false;
  public minDate: Date = new Date();
  public options: { [key: string]: boolean } = {}

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private administrationService: AdministratorApiService,
    private apiSync: ApiSynchronizerService,
    private deviceNotificationPollingService: DeviceNotificationPollingServiceService,
    private deviceCommandService: DeviceCommandService,
    private translate: TranslateService,
    private router: Router,
    private passDataService: PassDataService,
    private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.loaderService.disable();

    let deviceId: string;
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
      } else {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadData(deviceId);
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.apiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clientSettings => {
      if (clientSettings !== undefined) {
        this.clientSettings.Settings = clientSettings;

        if (this.clientSettings.Settings["notification_polling_ms"]) {
          this.notificationPollingIntervalMs = +this.clientSettings.Settings["notification_polling_ms"];
        }
      }
    });
  }

  private loadData(deviceId: string): void {
    let commandTypesFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues.every(value => value)) {
        this.deviceCommandTypes = data[commandTypesFeature];
      }
    }, context);

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        this.apiSync.loadedFeature(context);
      }
      else {
        this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.administrationService.listDeviceCommandType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(deviceCommandTypeListResult => {
      if (deviceCommandTypeListResult) {
        commandTypesFeature = this.apiSync.loadedFeatureWithData(deviceCommandTypeListResult, context);
      }
      else {
        this.apiSync.failedFeature(context);
      }
    });
  }

  public loadDeviceCommandOptions() {
    if (this.form.controls.deviceCommandTypeSelected.value) {
      const deviceCommandType: DeviceCommandType = this.deviceCommandTypes.find(x => x.Id === +this.form.controls.deviceCommandTypeSelected.value);

      this.options = deviceCommandType.Options;
    }
  }

  send() {
    if (this.form.valid) {
      this.deviceCommandRequest = {
        Body: this.form.controls.command.value.toString(),
        DeviceCommandType: this.deviceCommandTypes.find(x => x.Id === +this.form.controls.deviceCommandTypeSelected.value)?.Name,
        Device: this.currentDevice.Id,
        AutoDelivery: this.form.controls.autoDelivery.value === "true",
        Expiration: this.form.controls.expiration.value ? this.reformatDate(this.form.controls.expiration.value) : null
      }

      const sendCommandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      sendCommandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          for (let key of Object.keys(this.options)) {
            const value = (<HTMLInputElement>document.getElementById(key))?.value;
            if (value) {
              if (!this.deviceCommandRequest.Options) {
                this.deviceCommandRequest.Options = {};
              }

              this.deviceCommandRequest.Options[key] = value;
            }
          }

          this.deviceCommandService.add(this.deviceCommandRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(deviceCommandResponse => {
            if (deviceCommandResponse) {
              this.mainService.setSuccessMessageComand('Command sent!');

              this.commandSent = true;

              this.getNotifications(deviceCommandResponse.Id).subscribe();

              interval(this.notificationPollingIntervalMs).pipe(
                takeUntil(this.notificationsPolling$),
                switchMap(() => this.getNotifications(deviceCommandResponse.Id))
              ).subscribe();
            }
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  openNotificationDetailDialog(singleNotification: DeviceNotification): void {
    this.dialog.open(DeviceNotificationDetailDialogComponent, {
      data: {targetNotification: singleNotification},
      maxWidth: 1000,
      width: '70%'
    });
  }

  newCommand(): void {
    const newCommandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    newCommandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.notificationsPolling$.next();
        this.notificationsPolling$.complete();
        this.notificationsPolling$ = new Subject<void>();
        this.form.reset();
        this.deviceNotifications = [];
        this.deviceNotificationsDataSource = new MatTableDataSource<DeviceNotification>();
        this.commandSent = false;
        this.options = {};
        this.deviceCommandRequest = null;
      }
    });
  }

  private getNotifications(deviceCommandId) {
    return this.deviceNotificationPollingService.polling({
      DeviceCommand: deviceCommandId,
      Page: 0,
      Limit: 1000
    }).pipe(map((response: DeviceNotificationListResponse) => {
      this.deviceNotifications = response.Items?.filter(x => x.DeviceCommandId === deviceCommandId);
      this.deviceNotificationsDataSource = new MatTableDataSource<DeviceNotification>(this.deviceNotifications);
    }));
  }

  public setDate(date: Date): number {
    return +date;
  }

  reformatDate(dateToFormat: string): number {
    let currentDate = new Date(dateToFormat);
    return Math.round(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).getTime() / 1000);
  }

  public getKeys(element: object): string[] {
    if (element === undefined || element === null || Object.keys(element).length === 0) {
      return [];
    }

    return Object.keys(element);
  }

  public hasLength(element: object): boolean {
    return Object.keys(element).length > 0;
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.loaderService.enable();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.notificationsPolling$.next();
    this.notificationsPolling$.complete();
  }
}
