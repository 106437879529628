import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device } from '../../models/device';
import { TaskSchedule, TaskScheduleAddRequest, TaskScheduleUpdateRequest } from '../../models/taskScheduleElements';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'urban-add-task-schedule-dialog',
  templateUrl: './add-task-schedule-dialog.component.html',
  styleUrls: ['./add-task-schedule-dialog.component.scss']
})
export class AddTaskScheduleDialogComponent implements OnInit {

  public error: string | null;
  private addRequest: TaskScheduleAddRequest;
  private updateRequest: TaskScheduleUpdateRequest;
  public selectedDeviceControl = new UntypedFormControl(null, Validators.required);
  public jsonControl = new UntypedFormControl(null, Validators.required);
  public hourSelected: Date = null;
  public isAdd: boolean = true;
  public allLevelsDevices: Device[] = [];
  public filteredOptions: Observable<Device[]>;
  public daysForm: UntypedFormGroup = this.formBuilder.group({
    mondayCheck: false,
    tuesdayCheck: false,
    wednesdayCheck: false,
    thursdayCheck: false,
    fridayCheck: false,
    saturdayCheck: false,
    sundayCheck: false,
  });
  constructor(
    public dialogRef: MatDialogRef<AddTaskScheduleDialogComponent>,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public addData:
      {
        allDevices: Device[]
      },
    @Inject(MAT_DIALOG_DATA) public updateData:
      {
        targetTaskSchedule: TaskSchedule
      }
  ) { }

  ngOnInit(): void {
    if (this.addData?.allDevices) {
      this.allLevelsDevices = this.addData.allDevices;
    }

    this.filteredOptions = this.selectedDeviceControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value?.Name),
            map(name => name ? this._filter(name) : this.allLevelsDevices.slice())
          );

    if (this.updateData?.targetTaskSchedule && this.updateData?.targetTaskSchedule !== undefined) {
      this.isAdd = false;
      this.assignExistingValues();
    }
  }

  private assignExistingValues(): void {
    this.selectedDeviceControl.setValue(this.updateData.targetTaskSchedule.Device);
    this.daysForm.patchValue({
      mondayCheck: this.updateData.targetTaskSchedule.Date.includes('1'),
      tuesdayCheck: this.updateData.targetTaskSchedule.Date.includes('2'),
      wednesdayCheck: this.updateData.targetTaskSchedule.Date.includes('3'),
      thursdayCheck: this.updateData.targetTaskSchedule.Date.includes('4'),
      fridayCheck: this.updateData.targetTaskSchedule.Date.includes('5'),
      saturdayCheck: this.updateData.targetTaskSchedule.Date.includes('6'),
      sundayCheck: this.updateData.targetTaskSchedule.Date.includes('7')
    });
    this.jsonControl.setValue(this.updateData.targetTaskSchedule.Json);
    let nowTime = Date.now();
    let rebuiltDate = this.datePipe.transform(nowTime, 'shortDate').concat(' ', this.updateData.targetTaskSchedule.Hour);
    this.hourSelected = new Date(Date.parse(rebuiltDate));
  }

  displayFn(device: Device): string {
    return device && device.Name ? device.Name : '';
  }

  private _filter(name: string): Device[] {
    const filterValue = name.toLowerCase();
    return this.allLevelsDevices.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
  }

  deviceSelected(device: Device): void {
    if (device !== undefined && device !== null) {
      let deviceToUse = this.allLevelsDevices.find(x => x.Id == device.Id);

      if (deviceToUse !== undefined && deviceToUse !== null) {
        //TODO
        //this.taskScheduleApiService.getTaskScheduleList()
      }
    }
  }

  public clearSelection(): void {
    this.selectedDeviceControl.reset();
  }

  private createDateString(): string {
    let dateString: string = '';
    if (this.daysForm.value.mondayCheck) {
      dateString = dateString.concat('1-');
    }
    if (this.daysForm.value.tuesdayCheck) {
      dateString = dateString.concat('2-');
    }
    if (this.daysForm.value.wednesdayCheck) {
      dateString = dateString.concat('3-');
    }
    if (this.daysForm.value.thursdayCheck) {
      dateString = dateString.concat('4-');
    }
    if (this.daysForm.value.fridayCheck) {
      dateString = dateString.concat('5-');
    }
    if (this.daysForm.value.saturdayCheck) {
      dateString = dateString.concat('6-');
    }
    if (this.daysForm.value.sundayCheck) {
      dateString = dateString.concat('7-');
    }
    if (dateString.length > 0) {
      dateString = dateString.slice(0, -1);
    }
    return dateString;
  }

  public addition(): void {
    let checkboxError: boolean = Object.keys(this.daysForm.value).some(checkboxKey => this.daysForm.value[checkboxKey] === true);
    if (this.jsonControl?.valid && this.selectedDeviceControl?.value?.hasOwnProperty('Id') && checkboxError) {
      this.error = null;
      this.addRequest = {
        Device: this.selectedDeviceControl.value.Id,
        Date: this.createDateString(),
        Hour: this.datePipe.transform(this.hourSelected, 'shortTime'),
        Json: this.jsonControl.value
      }
      this.dialogRef.close(this.addRequest);
    }
    else if (this.selectedDeviceControl?.value && !this.selectedDeviceControl.value.hasOwnProperty('Id')) {
      this.error = 'TASK_SCHEDULE.SELECT_DEVICE';
    }
    else if (!(this.jsonControl?.valid && this.selectedDeviceControl?.valid)) {
      this.error = "GENERAL.ERROR_EMPTY";
    }
    else if (!checkboxError) {
      this.error = "TASK_SCHEDULE.NO_DAY_SELECTED_ERROR";
    }
  }

  public update(): void {
    let checkboxError: boolean = Object.keys(this.daysForm.value).some(checkboxKey => this.daysForm.value[checkboxKey] === true);
    if (this.jsonControl?.valid && this.selectedDeviceControl?.value?.hasOwnProperty('Id') && checkboxError) {
      this.error = null;
      this.updateRequest = {
        TaskSchedule: this.updateData.targetTaskSchedule.Id,
        Device: this.updateData.targetTaskSchedule.Device.Id,
        Date: this.createDateString(),
        Hour: this.datePipe.transform(this.hourSelected, 'shortTime'),
        Json: this.jsonControl.value
      }
      this.dialogRef.close(this.updateRequest);
    }
    else if (this.selectedDeviceControl?.value && !this.selectedDeviceControl.value.hasOwnProperty('Id')) {
      this.error = 'TASK_SCHEDULE.SELECT_DEVICE';
    }
    else if (!(this.jsonControl?.valid && this.selectedDeviceControl?.valid)) {
      this.error = "GENERAL.ERROR_EMPTY";
    }
    else if (!checkboxError) {
      this.error = "TASK_SCHEDULE.NO_DAY_SELECTED_ERROR";
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

}
