import { MenuSection, MenuList } from './../../models/sidebar';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { takeUntil, first } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Resource } from '../../models/resource';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { NavigationInfo } from '../../models/navigationExtra';

@Component({
  selector: 'urban-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('dashboardButton') dashboardButton: ElementRef<HTMLElement>;

  @Input('myResourcesList') public myResourcesListRef: Resource[];
  @Input('routeChangedSignal') private routeHasChanged: Subject<void>;
  @Input('sidebarOpened') public isSidebarOpened: boolean;
  @Output('closeSidebar') closeSidebar = new EventEmitter<any>();
  @Output('sidebarToggler') sidebarToggle = new EventEmitter<any>();

  public oneExpanded: string = '';
  public oneActive: string = '';
  public sidebarSections: MenuSection[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    public router: Router,
    private passDataService: PassDataService,
    ) {}

  ngOnInit(): void {
    if (this.myResourcesListRef && this.myResourcesListRef.length > 0) {
      let sidebarParentsMenuLists: MenuList[] = [];
      let sidebarOthersMenuLists: MenuList[] = [];
      let sidebarChildsMenuLists: MenuList[] = []

      const emptyValue = '00000000-0000-0000-0000-000000000000';

      this.myResourcesListRef.filter(e => (e.Label || e.Image)).forEach((resource: Resource) => {
        let newTitle: string = 'SIDEBAR.' + resource.Title;

        let menuList: MenuList = {
          Id: resource.Id,
          ParentId: resource.ParentId === emptyValue ? '' : resource.ParentId,
          Url: null,
          Title: newTitle,
          Section: '',
          Image: '',
          Childs: []
        };

        if (resource.Url == 'under-construction') {
          menuList.Url = 'wip-' + resource.Title.split('$').pop().replace('_', '-').toLowerCase();
        }
        else {
          menuList.Url = resource.Url;
        }

        if (resource.ParentId === emptyValue) {
          menuList.Image = resource.Image;
          if (resource.Label.includes('.')) {
            menuList.Section = resource.Label.split('.')[0];
            sidebarParentsMenuLists.push(menuList);
          }
          else {
            menuList.Section = 'others';
            sidebarOthersMenuLists.push(menuList);
          }
        }
        else {
          sidebarChildsMenuLists.push(menuList);
        }
      });

      sidebarParentsMenuLists.push(...sidebarOthersMenuLists);

      sidebarChildsMenuLists.forEach((child: MenuList) => {
        let itsParent: MenuList = sidebarParentsMenuLists.find((parent: MenuList) => parent.Id === child.ParentId);
        if (itsParent) {
          if (itsParent.Childs) {
            itsParent.Childs.push(child)
          }
          else {
            itsParent.Url = null;
            itsParent.Childs = [child];
          }
        }
      });

      let sectionName: string = '';
      let sectionIndex: number = -1;
      sidebarParentsMenuLists.forEach((menuList: MenuList) => {
        if (menuList.Section.toLowerCase() !== sectionName.toLowerCase()) {
          sectionName = menuList.Section;
          sectionIndex++;
          let section: MenuSection = {
            Title: 'SIDEBAR.'+sectionName.toUpperCase().replace(' ', '_'),
            List: [menuList]
          };
          this.sidebarSections.push(section);
        }
        else {
          this.sidebarSections[sectionIndex].List.push(menuList);
        }
      });

      this.getActiveButton();
      this.setActiveButton();
      this.routeHasChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.setActiveButton();
        }
      });
    }
  }

  private getActiveButton(): void {
    this.passDataService.navigationInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((navInfo: NavigationInfo) => {
      if (navInfo?.SidebarActive && navInfo.SidebarActive.Id !== this.oneActive) {
        this.oneActive = navInfo.SidebarActive.Id;
        this.oneExpanded = navInfo.SidebarActive.ExpandedId;
        if(this.oneActive === 'dashboard') {
          timer(0).subscribe(() => {
            if(this.dashboardButton?.nativeElement) {
              this.dashboardButton.nativeElement.scrollIntoView({ behavior: "smooth", block: 'center' });
            }
          });
        }
      }
    });
  }

  private setActiveButton(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe((navInfo: NavigationInfo) => {
      if (this.router.url.includes('devices-table')) {
        if (navInfo?.Type) {
          let currentActive: Resource = this.myResourcesListRef
            .filter(e => e.Label || e.Image).find(item => item.Url === 'devices-table;type=' + navInfo.Type);
          if (currentActive !== undefined) {
            this.mainService.setNavigationInfoComand({
              SidebarActive: {
                Id: currentActive.Id,
                ExpandedId: currentActive.ParentId
              }
            });
          }
        }
      }
      else if (this.router.url.split('/').pop() === 'dashboard') {
        this.mainService.setNavigationInfoComand({
          SidebarActive: {
            Id: 'dashboard',
            ExpandedId: ''
          }
        });
      }
      else if (this.router.url.includes('net-wifi/gateways')) {
        let currentActive: Resource = this.myResourcesListRef
          .filter(e => e.Label || e.Image).find(item => item.Url === 'net-wifi');
        if (currentActive !== undefined) {
          this.mainService.setNavigationInfoComand({
            SidebarActive: {
              Id: currentActive.Id,
              ExpandedId: currentActive.ParentId
            }
          });
        }
      }
      else {
        let urlParts: string[] = this.router.url.split('/');
        let currentUrl: string = urlParts[urlParts.indexOf('main') + 1].split(';')[0];;
        let activeMenuItem: MenuList;
        this.sidebarSections.forEach((section: MenuSection) => {
          if (activeMenuItem === undefined) {
            let tempMenuItem = this.findMenuListByUrl(currentUrl, section.List);
            if (tempMenuItem !== undefined) {
              activeMenuItem = tempMenuItem;
            }
          }
        });
        if (activeMenuItem !== undefined) {
          this.mainService.setNavigationInfoComand({
            SidebarActive: {
              Id: activeMenuItem.Id,
              ExpandedId: activeMenuItem.ParentId
            }
          });
        }
      }
    });
  }

  private findMenuListByUrl(url: string, list: MenuList[]): MenuList {
    let menuList: MenuList;

    list.forEach((item: MenuList) => {
      if(menuList === undefined) {
        if(url === item.Url) {
          menuList = item;
        }
        else if(item.Childs?.length > 0) {
          let childMenuList: MenuList = this.findMenuListByUrl(url, item.Childs);
          if(childMenuList !== undefined) {
            menuList = childMenuList;
          }
        }
      }
    });

    return menuList;
  }

  goToMain(): void {
    let currentUrl: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
    this.mainService.updateAllDataAndGoToMainComand();
  }

  public sidebarCloser(): void {
    this.closeSidebar.emit();
  }

  public sidebarToggler(): void {
    this.sidebarToggle.emit();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  public clickedEvent(clickedItemId: string): void {
    let listIndex: number;
    let section: MenuSection = this.sidebarSections.find(section => (listIndex = section.List.findIndex((item: MenuList) => item.Id === clickedItemId)) !== -1);

    if(section?.List && listIndex !== -1) {
      let itemClicked: MenuList = section.List[listIndex];
      if (itemClicked !== undefined) {
        if (itemClicked.Childs?.length > 0 ) {
          this.oneExpanded = clickedItemId;
        } else {
          this.oneActive = clickedItemId;
        }
      }
    }

    if(!this.isSidebarOpened) {
      this.sidebarToggler();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

