<h1 mat-dialog-title>{{this.domain?.Name}} - {{ 'ADMINISTRATION.ADD_AUTHORIZATION' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.ROLE' | translate }}</mat-label>
            <mat-select formControlName="roleSelected">
                <mat-option *ngFor="let role of roles" value={{role.Id}}>{{role.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="add()">{{ 'GENERAL.ADD'| translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>

