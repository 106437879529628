<div class="container-fluid px-1 px-md-3" [ngClass]="{'h-100' : noData}">
    <div
        class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span *ngIf="currentDevice" class="title2 appearingTextLeft">
                {{ this.currentDevice.Name + ' -' }}
            </span>
            <span class="title2">{{ 'WEATHERSTATION.TITLE' | translate }}</span>
        </div>
        <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span>
    </div>

    <urban-searchbar id="intro-ws-sensors-search" [passedMaxDaysRange]="6" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
            [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
            (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <div *ngIf="readyToShow">
        <div class="d-flex justify-content-end my-3 changeLayoutView">
            <button id="intro-ws-sensors-layout" *ngIf="!this.isLittleScreen" class="btn smallActionButton mr-5"
                (click)="changeBoxLayoutAndRedraw()" matTooltip="{{ 'WEATHERSTATION.CHANGE_LAYOUT' | translate }}">
                <i class="urbanicon-grid-view" *ngIf="!chartLayoutGrid"></i>
                <i class="urbanicon-row-view" *ngIf="chartLayoutGrid"></i>
            </button>
        </div>
        <div class="box-container">
            <div class="d-flex flex-column justify-content-between box tableEvidenceBG"
                [ngClass]="{'box-grid-layout': chartLayoutGrid, 'box-column-layout': !chartLayoutGrid }"
                *ngFor="let sensor of myWSSensors; let i = index">
                <h6 class="pt-3 pl-3">{{ ('WEATHERSTATION.' + sensor.Name) | translate }}</h6>
                <urban-google-linechart [sensor]="sensor" [linechartId]="sensor.Name"
                    maxValueDescription="LINECHART.ACCEPTABLE_LIMIT" valueDescription="LINECHART.SENSOR_VALUE"
                    [startDate]="startDate" [endDate]="endDate"
                    [darkThemeActive]="this.isDarkActive"></urban-google-linechart>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="noData">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
        <div class="d-flex">
            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                {{ 'GENERAL.NEW_SEARCH' | translate }}
            </button>
            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                {{ 'GENERAL.SHOW_DATA' | translate }}
            </button>
        </div>
    </div>

</div>
