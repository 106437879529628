import {Component, OnDestroy, OnInit} from '@angular/core';
import {BroadcastClientResponse} from "../../../../shared/models/content-management/broadcastClientResponse";
import {UserRoles} from "../../../../shared/models/userRoles";
import {Subject} from "rxjs";
import {PaginationInstance} from "ngx-pagination";
import {Router} from "@angular/router";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {BroadcastResponse} from "../../../../shared/models/content-management/broadcastResponse";
import {ScheduleRule} from "../../../../shared/models/content-management/scheduleRule";
import {BroadcastDialogComponent} from "../../../../shared/dialogs/broadcast-dialog/broadcast-dialog.component";
import {ChannelResponse} from "../../../../shared/models/content-management/channelResponse";
import {ContentResponse} from "../../../../shared/models/content-management/contentResponse";

@Component({
  selector: 'urban-broadcasts',
  templateUrl: './broadcasts.component.html',
  styleUrls: ['./broadcasts.component.scss']
})
export class BroadcastsComponent implements OnInit, OnDestroy {

  typesColumns = ['Start', 'End', 'Rule', 'Detail'];
  public broadcasts: BroadcastResponse[] = [];
  private channels: ChannelResponse[] = [];
  private contents: ContentResponse[] = [];
  private scheduleRules : ScheduleRule[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: BroadcastResponse[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private contentManagementService: ContentManagementService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this._initList();
    });
  }

  public add(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin')) {

      const addDialogRef = this.dialog.open(BroadcastDialogComponent, {
        data: {
          scheduleRules: this.scheduleRules,
          contents: this.contents,
          channels: this.channels
        }
      });

      addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newBroadcast => {
        if (newBroadcast) {
          const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false,
            width: '50%',
            maxWidth: 500,
          });

          addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.contentManagementService.addBroadcast(newBroadcast).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            }
          });
        }
      });
    }
  }

  private _initList(): void {
    this.contentManagementService.listBroadcast().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x && x.length > 0) {
        this.broadcasts = x;
        this.filteredData = this.broadcasts;
      }
    });

    this._initScheduleRuleList();
    this._initChannels();
    this._initContents();
  }

  private _initScheduleRuleList(): void {
    this.contentManagementService.listScheduleRule().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.scheduleRules = x;
      }
    });
  }

  private _initChannels(): void {
    this.contentManagementService.listChannel().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.channels = x;
      }
    });
  }

  private _initContents(): void {
    this.contentManagementService.listContent().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.contents = x;
      }
    });
  }

  public detail(broadcast: BroadcastClientResponse): void {
    if(!broadcast){
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin')) {

      const updateDialogRef = this.dialog.open(BroadcastDialogComponent,{
        data: {
          broadcast: broadcast,
          scheduleRules: this.scheduleRules,
          contents: this.contents,
          channels: this.channels
        }
      });

      updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedBroadcast => {
        if (updatedBroadcast) {
          const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false,
            width: '50%',
            maxWidth: 500
          });

          updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.contentManagementService.updateBroadcast(updatedBroadcast).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            }
          });
        }
      });
    }
  }

  public delete(broadcast: BroadcastResponse): void {
    if (!broadcast) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin')) {
      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.contentManagementService.deleteBroadcast(broadcast.ContentId, broadcast.ChannelId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.broadcasts?.filter((key: BroadcastResponse) =>
      [
        key.ScheduleRule?.Name?.toLowerCase(),
        key.Start ? new Date(key.Start * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : '',
        key.End ? new Date(key.End * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): ''
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
