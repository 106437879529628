import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'urban-around-me-dialog',
  templateUrl: './around-me-dialog.component.html',
  styleUrls: ['./around-me-dialog.component.scss']
})
export class AroundMeDialogComponent implements OnInit, AfterViewInit {

  public form: UntypedFormGroup;
  public addressVisible: boolean = false;
  public correctInput: boolean = true;
  public position: {lat: number, long: number} = { lat: 0,long: 0 }
  public positionBlocked: boolean = false;
  public autocomplete: google.maps.places.Autocomplete;

  constructor(
    public dialogRef: MatDialogRef<AroundMeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {type: string, lat: number, lng: number},
    private fb: UntypedFormBuilder,
    private apiService: ApiService
  ) {
    if(data.lat && data.lng && data.lat !== 0 && data.lng !== 0) {
      this.positionBlocked = true;
    } else { this.positionBlocked = false; }

    this.form = this.fb.group({
      latitude: new UntypedFormControl(data.lat ? data.lat : 0, Validators.required),
      longitude: new UntypedFormControl(data.lng ? data.lng : 0, Validators.required),
      range: new UntypedFormControl(0, Validators.required),
      device: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    if (this.data.lat !== 0 && this.data.lng !== 0) {
      this.addressVisible = false
    } else {
      this.addressVisible = true
    }
  }

  ngAfterViewInit(): void {
    // è importante che stia qui. Deve caricarsi prima il template.
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete') as HTMLInputElement);
    this.autocomplete.addListener('place_changed', () => this.onPlaceChanged())
  }

  private onPlaceChanged(){
    let place = this.autocomplete.getPlace();
    if (place.geometry) {
      //User selected a prediction.
      this.setLatLng(place.geometry.location);
    }
  }

  cancel(){
    this.dialogRef.close('cancel');
  }

  search(){
    this.correctInput = this.form.valid;
    console
    if (this.correctInput) {
      this.apiService.getDevicesAroundMe(
        Number(this.form.get('latitude').value),
        Number(this.form.get('longitude').value),
        Number(this.form.get('range').value),
        this.form.get('device').value.toString(),
      ).subscribe(
        res => this.dialogRef.close(res),
        err => this.dialogRef.close('Momentaneamente il server non risponde. Riprova più tardi.')
      )
    }
  }

  setLatLng(latLng: google.maps.LatLng) {
    this.form.patchValue({
      latitude: latLng.lat(),
      longitude: latLng.lng(),
    })
  }

}
