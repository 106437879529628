import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceType } from '../../models/deviceType';

@Component({
  selector: 'urban-device-type-dialog',
  templateUrl: './device-type-dialog.component.html',
  styleUrls: ['./device-type-dialog.component.scss']
})
export class DeviceTypeDialogComponent implements OnInit {

  public deviceType: DeviceType;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    icon: [{ value: '', disabled: false }, Validators.required],
    mapPin: [{ value: '', disabled: false }, Validators.required],
    color: [{ value: '', disabled: false }, Validators.required],
  });

  public error: string | null;

  public deviceTypes: DeviceType[] = [];

  public isAdd: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DeviceTypeDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { deviceType: DeviceType }
  ) {
    if (this.data && this.data !== undefined && this.data.deviceType && this.data.deviceType !== undefined) {
      this.isAdd = false;
      this.deviceType = this.data.deviceType;
    }
  }

  ngOnInit(): void {
    if (this.data && this.data !== undefined && this.data.deviceType && this.data.deviceType !== undefined) {
      this.form.controls.name.setValue(this.deviceType.Name);
      this.form.controls.icon.setValue(this.deviceType.Icon);
      this.form.controls.mapPin.setValue(this.deviceType.MapPin);
      this.form.controls.color.setValue(this.deviceType.Color);
    }
  }

  addition() {
    if (this.form.valid) {
      if (!this.deviceType || this.deviceType === undefined) {
        this.deviceType = {
          Id: '',
          Name: this.form.controls.name.value,
          Icon: this.form.controls.icon.value,
          MapPin: this.form.controls.mapPin.value,
          Color: this.form.controls.color.value
        }
      }

      this.dialogRef.close(this.deviceType)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
