import { Component, Inject, OnInit } from '@angular/core';
import { Advertising, AdvertisingType } from '../../models/advertising/advertising';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddAdvertisingRequest } from '../../models/advertising/advertisingRequest';
import { Service } from '../../models/service/service';
import { Configuration } from '../../models/configuration';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'urban-advertising-dialog',
  templateUrl: './advertising-dialog.component.html',
  styleUrls: ['./advertising-dialog.component.scss']
})
export class AdvertisingDialogComponent implements OnInit {
  public advertising: Advertising;
  public advertisingTypes: AdvertisingType[] = [];
  public services: Service[] = [];
  public error: boolean = false;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    type: [{ value: null, disabled: false }, Validators.required],
    message: { value: '', disabled: false },
    image: { value: '', disabled: false },
    startDate: [{ value: null, disabled: false }, Validators.required],
    endDate: [{ value: null, disabled: false }, Validators.required],
    startTime: [{ value: null, disabled: false }, Validators.required],
    endTime: [{ value: null, disabled: false }, Validators.required],
    service: [{ value: '', disabled: false }, Validators.required]
  });
  public minDateRange: Date;
  public appConfig: Configuration;
  public imageError: string;

  constructor(
    private passDataService: PassDataService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AdvertisingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        advertising: Advertising,
        types: AdvertisingType[],
        services: Service[]
      }
  ) { }

  ngOnInit(): void {
    this.advertising = this.data?.advertising;
    this.advertisingTypes = this.data?.types;
    this.services = this.data?.services;

    let now: Date = new Date();
    this.minDateRange = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    if (this.advertising) {
      let startTime: Date = new Date(now);
      let endTime: Date = new Date(now);
      startTime.setHours(this.advertising.StartTime.hours);
      startTime.setMinutes(this.advertising.StartTime.minutes);
      endTime.setHours(this.advertising.EndTime.hours);
      endTime.setMinutes(this.advertising.EndTime.minutes);

      this.form.setValue({
        name: this.advertising.Name,
        type: this.advertising.Type.Id,
        message: this.advertising.Message,
        image: this.advertising.Image ? this.advertising.Image : '',
        startDate: new Date(this.advertising.StartDate * 1000),
        endDate: new Date(this.advertising.EndDate * 1000),
        startTime, endTime,
        service: this.advertising.Service.Id
      });
    }

    this.passDataService.appConfiguration$.pipe(first()).subscribe(config => {
      this.appConfig = config;
    });
  }

  public saveAdvertising(): void {
    if (this.advertisingTypes && this.form.valid) {
      this.error = false;
      let startTime: Date = new Date(this.form.controls.startTime.value);
      let endTime: Date = new Date(this.form.controls.endTime.value);

      const formValues: AddAdvertisingRequest = {
        Name: this.form.controls.name.value,
        Type: this.form.controls.type.value,
        Message: this.form.controls.message.value,
        Image: this.form.controls.image.value,
        StartDate: new Date(this.form.controls.startDate.value).getTime() / 1000,
        EndDate: new Date(this.form.controls.endDate.value).getTime() / 1000,
        StartTime: {
          hours: startTime.getHours(),
          minutes: startTime.getMinutes()
        },
        EndTime: {
          hours: endTime.getHours(),
          minutes: endTime.getMinutes()
        },
        Service: this.form.controls.service.value
      };

      this.dialogRef.close(formValues);
    }
    else {
      this.error = true;
    }
  }

  public imageUpload(event: any): void {
    if (event?.target?.files?.length > 0) {
      if (event.target.files[0].size > +this.appConfig.Settings.max_profile_image_size) {
        this.imageError = 'IMAGE_SIZE_MAX';
        if (this.form.controls.image.value !== '') {
          this.form.patchValue({ image: '' });
        }
      } else if (event.target.files[0].size < +this.appConfig.Settings.min_profile_image_size) {
        this.imageError = 'IMAGE_SIZE_MIN';
        if (this.form.controls.image.value !== '') {
          this.form.patchValue({ image: '' });
        }
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (myTarget) => {
          let image = new Image();
          image.src = myTarget.target.result.toString();
          image.onload = () => {
            let imageBaseData: string | ArrayBuffer = reader.result;
            this.form.patchValue({ image: imageBaseData.toString() });
            this.imageError = null;
          }
        };
        reader.onerror = function (error) {
          console.log("Error FileReader: ", error);
        };
      }
    }
  }

  public removeImage(): void {
    if (this.form.controls.image.value !== '') {
      this.form.patchValue({ image: '' });
    }
  }
}
