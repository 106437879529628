<div class="container-fluid">

    <div class="row mb-1 d-flex justify-content-between align-items-baseline bg-light-grey border-radius-15 m-0">
        <span class="h4 color-dark mb-2 mt-2 thin">
            {{ 'DOMAIN.TITLE' | translate }}
        </span>
    </div>
    <br>

    <div class="devices-table mat-elevation-z4">
        <table class="w-100" mat-table [dataSource]="myDataSource">
            <!-- Name Column -->
            <ng-container matColumnDef="DomainName">
                <th mat-header-cell *matHeaderCellDef> {{ 'DOMAIN.DOMAIN_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.DomainName }} </td>
            </ng-container>

            <ng-container matColumnDef="Paths">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="column-with-buttons">
                    <button mat-button matTooltip="{{ 'DOMAIN.PATH_GATEWAY' | translate }}" (click)="goToGateways(element.id)">
                        <i class="urbanicon-building"></i>
                    </button>
                    <button mat-button matTooltip="{{ 'DOMAIN.PATH_USER' | translate }}" (click)="goToUsers(element.id)">
                        <i class="urbanicon-user"></i>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedDomainsColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDomainsColumns;"></tr>
        </table>
    </div>
</div>
