import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../../../shared/dialogs/change-password-dialog/change-password-dialog.component';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from '../../../shared/services/api.service';
import { PhonePrefix } from '../../../shared/models/phonePrefix';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Configuration } from '../../../shared/models/configuration';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/loginResponse';
import { UserRoles } from 'src/app/shared/models/userRoles';

@Component({
  selector: 'urban-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  @Output() closeRightbar: EventEmitter<string> = new EventEmitter();
  @Output() logout: EventEmitter<any> = new EventEmitter();
  @Input('myCurrentDomainName') public myCurrentDomainRef: string = "";

  public isAdmin: boolean = false;
  public isDomainAdmin: boolean = false;
  public appConfig: Configuration;
  public currentUser: User;
  public userRoles: UserRoles['Roles'];
  public selectedFile: File = null;
  public imageBaseData: string | ArrayBuffer = null;
  public profileImage: string | null = null;
  public profilePhoneNumber: string = null;
  public myPrefixesList: PhonePrefix[] = [];
  public dataChanged: boolean;
  public phoneModificationActive: boolean = false;
  public imageError: string = null;
  public colors: Record<string, string>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public phoneForm: UntypedFormGroup = this.formBuilder.group({
    prefix: [{value: null}, Validators.required],
    telephone: [null, Validators.compose([Validators.required, Validators.pattern(/^[(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,6}$/)])]
  });

  constructor(
    private mainService: MainSubscriptionsService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    let rolesColors: string[] = this.getRoleColors();
    this.colors = {
      'Administrators': rolesColors[0].trim(),
      'Domain admin': rolesColors[1].trim(),
      'Translator': rolesColors[2].trim(),
      'App users': rolesColors[3].trim(),
      'Camera viewer': rolesColors[4].trim(),
      'Users': rolesColors[5].trim(),
      'Other': "gray"
    };

    this.phoneForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
      let prefix: string;
      let telephone: string;
  
      if (this.currentUser.Properties?.hasOwnProperty('PhoneNumber')) {
        this.profilePhoneNumber = this.currentUser.Properties.PhoneNumber;

        prefix = this.profilePhoneNumber.split(" ")[0].replace("+","");
        telephone = this.profilePhoneNumber.split(" ")[1];
      }

      const phoneValues = {
        prefix: +prefix ?? null,
        telephone: telephone ?? null
      }

      this.dataChanged = false;

      for (let key in phoneValues) {
        if (phoneValues[key] !== values[key]) {
          this.dataChanged = true;
          break;
        }
      }
    });

    this.loadData();
    this.preparePhoneData();
  }

  private loadData(): void {
    this.passDataService.currentUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.currentUser = res;

        res.Properties.hasOwnProperty('ProfileImage') ? this.profileImage = res.Properties.ProfileImage : null;

        if (res.Properties?.hasOwnProperty('PhoneNumber')) {
          this.profilePhoneNumber = res.Properties.PhoneNumber;

          this.phoneForm.setValue({
            prefix: +this.profilePhoneNumber.split(" ")[0].replace("+",""),
            telephone: this.profilePhoneNumber.split(" ")[1]
          });
        }
      }
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;

      if (res?.some(role => role.Name === 'Administrators')) {
        this.isAdmin = true;
      } else if (res?.some(role => role.Name === 'Domain admin')) {
        this.isDomainAdmin = true;
      }
    });

    this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
      this.appConfig = config;
    });
  }

  private preparePhoneData(): void {
    this.apiService.getPhonePrefixes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.length > 0) {
        this.myPrefixesList = res;
      }
    });
  }

  public openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent);
    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) this.mainService.changePasswordComand(result)
    });
  }

  public profileImageUpload(event: any): void {
    if (event.target.files[0].size > +this.appConfig.Settings.max_profile_image_size) {
      this.imageError = 'IMAGE_SIZE_MAX';
    } else if (event.target.files[0].size < +this.appConfig.Settings.min_profile_image_size) {
      this.imageError = 'IMAGE_SIZE_MIN';
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (myTarget) => {
        let image = new Image();
        image.src = myTarget.target.result.toString();
        image.onload = () => {
          this.imageBaseData = reader.result;
          this.mainService.changeProfileImageComand(this.imageBaseData.toString());
          this.imageError = null;
        }
      };
      reader.onerror = function (error) {
        console.log("Error FileReader: ", error);
      };
    }
  }

  public removePropic(): void {
    if (this.profileImage) {
      this.mainService.removeProfileImageComand();
    }
  }

  public updatePhoneNumber(): void {
    if (this.phoneForm.valid) {
      let formattedPhoneNumber: string = "+" + this.phoneForm.controls.prefix.value + " " + this.phoneForm.controls.telephone.value;
      this.mainService.changeProfilePhoneNumberComand(formattedPhoneNumber);
      this.phoneModificationActive = false;
    }
  }

  private getRoleColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-success-icon-text-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-light-warn-accent-color'));

    return colors;
  }

  public cancelPhoneEdit(): void {
    this.phoneModificationActive = !this.phoneModificationActive;

    if (this.profilePhoneNumber) {
      this.phoneForm.setValue({
        prefix: +this.profilePhoneNumber.split(" ")[0].replace("+",""),
        telephone: this.profilePhoneNumber.split(" ")[1]
      });
    }
  }

  public goToUserDetail(): void {
    if (this.isAdmin) {
      this.mainService.setNavigationInfoComand({ Id: this.currentUser.Id, BackRoute: 'dashboard' });
      this.router.navigate(['main/user-detail']);
    } else if (this.isDomainAdmin) {
      this.mainService.setNavigationInfoComand({ Id: this.currentUser.Id, BackRoute: 'dashboard' });
      this.router.navigate(['main/user-domain-detail']);
    }

    this.closeThisRightbar();
  }

  public closeThisRightbar(): void {
    this.closeRightbar.emit('profile');
  }

  public logoutHandler(): void {
    this.logout.emit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
