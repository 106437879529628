<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'TRANSIT_DASHBOARD.TITLE' | translate }}
        </span>
        <!-- <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span> -->
    </div>

    <urban-searchbar id="intro-transit-dashboard-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event);" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [descriptionText]="alertPanelInput.DescriptionText"
            [buttonLabel]="isDomainAdmin ? ('DEVICE.VIEW_EVENTS' | translate) : null"
            (buttonAction)="isDomainAdmin ? goToDomainEvents() : null" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <div>
        <mat-tab-group id="intro-transit-dashboard-tab" animationDuration="0ms" dynamicHeight="true">
            <mat-tab [label]="'GENERAL.STATUS' | translate">
                <div class="tabContent" *ngIf="transitDataSource">
                    <div *ngIf="transitDataSource.data.length > 0" class="customTable">
                        <table id="intro-transit-table" class="w-100 h-100" mat-table
                        [dataSource]="transitDataSource">

                            <ng-container matColumnDef="device">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'GENERAL.DEVICE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item"> {{item.Device.Name}} </td>
                            </ng-container>

                            <ng-container *ngFor="let type of transitTypes.slice(1)" [matColumnDef]="type">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'TRANSIT_DEVICE.TYPE_'+type.toUpperCase() | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                    {{item.Amounts[type]}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="goToDeviceDetails(row.Device.Id)">
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="transitDataSource.data.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab [label]="'GENERAL.MAP' | translate" #mapTab>
                <div class="tabContent">
                    <urban-parking-maps-widget *ngIf="mapTab.isActive && mapReady" class="mapPanel"
                        [latestEvents]="[]" [devicesList]="transitDevices"
                        [darkThemeActive]="isDarkActive" [passedHeight]="650"></urban-parking-maps-widget>
                </div>
            </mat-tab>

            <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
                <div class="tabContent" *ngIf="chartsTab.isActive && transitions">
                    <div *ngIf="transitions.length > 0">
                        <div class="my-3 toggleButtons">
                            <mat-button-toggle-group aria-label="Transit Type Set" [(ngModel)]="typeSelected" (valueChange)="filterTransitions($event)">
                                <mat-button-toggle *ngFor="let type of transitTypes" [value]="type">
                                    {{ 'TRANSIT_DEVICE.TYPE_'+type.toUpperCase() | translate }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="roundedPanel panelBG chartContainer">
                            <urban-google-columnchart columnChartTitle="TRANSIT_DASHBOARD.10_CAMERAS_LONGEST_AVERAGE_STAYS" [columnChartSubtitle]="columnChartSubtitle"
                                [passedChartData]="averageStaysColumnChartData" columnsTitle="REPORT.camera-bmp" columnChartId="averageStayPerCamera"
                                valuesDescription="TRANSIT_DEVICE.TIME_PERIOD" [passedVAxisTicks]="averageStaysColumnChartTicks"
                                [alreadySorted]="true" [darkThemeActive]="this.isDarkActive">
                            </urban-google-columnchart>
                        </div>
                        <div class="roundedPanel panelBG chartContainer">
                            <urban-google-barchart *ngIf="transitDevicesAmounts !== undefined"
                            [ngClass]="loadingData !== undefined ? (loadingData ? 'disappearing-charts' : 'appearing-charts') : 'initial-charts'"
                                barChartTitle="TRANSIT_DASHBOARD.MOST_CONGESTED"
                                barsTitle="REPORT.camera-bmp" valuesDescription="TRANSIT_DASHBOARD.TRANSIT_AMOUNTS"
                                [passedChartData]="transitDevicesAmounts" [darkThemeActive]="isDarkActive"
                                darkBackgroundColor="#021c34" lightBackgroundColor="#ffffff"
                                (chartDrawn)="chartReady = true">
                            </urban-google-barchart>
                        </div>
                        <div class="my-3">
                            <urban-google-heatmap class="mapPanel"
                            heatmapTitle="TRANSIT_DASHBOARD.TRANSIT_AMOUNTS_DISTRIBUTION"
                            [heatmapElements]='transitHeatmapElements'
                            [maxValuesHeatmap]='heatmapMaxValue'
                            [passedLastUpdate]='heatmapLastUpdate'
                            [darkModeStatus]='isDarkActive'>
                            </urban-google-heatmap>
                        </div>
                        <div class="roundedPanel panelBG chartContainer">
                            <urban-google-columnchart columnChartTitle="TRANSIT_DASHBOARD.LONGEST_STAY_PER_CAMERA" [columnChartSubtitle]="columnChartSubtitle"
                                [passedChartData]="longestStaysColumnChartData" columnsTitle="REPORT.camera-bmp" columnChartId="longestStayPerCamera"
                                valuesDescription="TRANSIT_DEVICE.TIME_PERIOD" [passedVAxisTicks]="longestStaysColumnChartTicks"
                                [alreadySorted]="true" [darkThemeActive]="this.isDarkActive">
                            </urban-google-columnchart>
                        </div>
                    </div>
                    <div *ngIf="transitions.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div>
    <urban-loader [localized]="true" [onContent]="true" [disappearCondition]="loadingData === false">
    </urban-loader>
</div>
