import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WidgetRequest} from "../../models/administrator/widgetRequest";

@Component({
  selector: 'urban-widget-dialog',
  templateUrl: './widget-dialog.component.html',
  styleUrls: ['./widget-dialog.component.scss']
})
export class WidgetDialogComponent implements OnInit {

  private widgetRequest: WidgetRequest;

  public isAdd = true;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    description: [{value: '', disabled: false}, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<WidgetDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        widgetRequest: WidgetRequest
      }
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.widgetRequest) {
      this.widgetRequest = this.data.widgetRequest;
      this.isAdd = false;
      this.form.controls.name.setValue(this.widgetRequest.Name);
      this.form.controls.description.setValue(this.widgetRequest.Description);
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.widgetRequest = {
        Id: this.widgetRequest?.Id || null,
        Name: this.form.controls.name.value,
        Description: this.form.controls.description.value
      };

      this.dialogRef.close(this.widgetRequest);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
