import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { DeviceEventDetailDialogComponent } from '../../../shared/dialogs/device-event-detail-dialog/device-event-detail-dialog.component';
import { Device } from '../../../shared/models/device';
import { DeviceEvent } from '../../../shared/models/deviceEvent';
import { DeviceEventsRequest } from '../../../shared/models/deviceEventsRequest';
import { ApiService } from '../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ManualEventDialogComponent } from 'src/app/shared/dialogs/manual-event-dialog/manual-event-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';

@Component({
  selector: 'urban-device-events',
  templateUrl: './device-events.component.html',
  styleUrls: ['./device-events.component.scss']
})
export class DeviceEventsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) private paginator: MatPaginator;

  public isAdmin: boolean;
  public isDomainAdmin: boolean;
  public events: DeviceEvent[] = [];
  public displayedColumns: string[] = ['Id', 'Created', 'Type', 'Level', 'Body', 'Detail'];
  public eventsDataSource: MatTableDataSource<DeviceEvent>;
  public pageEvent: PageEvent;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageIndex: number = 0;
  public myTotalItems: number = 0;
  public myPageSize: number = 10;
  public orderColumn: string = null;
  public orderDirection: string = null;
  public isOnlyDomainAdmin: boolean = false;
  public currentDevice: Device;
  public firstCall: boolean = undefined;
  public currentLanguage: string;
  public latestDataLoaded: boolean = false;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public startDate: number;
  public endDate: number;
  public loadingData: boolean = false;
  private subscription: Subject<void> = new Subject<void>();
  public eventLevelColors: string[] = [];
  // public selectedEvents: Record<string, boolean>;

  constructor(
    private apiService: ApiService,
    private administratorApiService: AdministratorApiService,
    private apiSync: ApiSynchronizerService,
    private dialog: MatDialog,
    private passDataService: PassDataService,
    private router: Router,
    private mainService: MainSubscriptionsService,
    private translate: TranslateService,
  ) {}


  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isAdmin = res?.length > 0 && res.some(x => x.Name == 'Administrators');
      this.isDomainAdmin = res?.length > 0 && res.some(x => x.Name == 'Domain admin');
      if (!this.isAdmin && !this.isDomainAdmin) {
        this.setErrorAndGoToMain();
        return;
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          deviceId = navInfo.Id;
        } else {
            this.setErrorAndGoToMain();
            return;
        }

        this.loadData(deviceId);
      });
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.passDataService.currentDarkModeStatus$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.eventLevelColors = this.getEventLevelColors();
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, eventListFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        if (checkValues[eventListFeature]) {
          this.myTotalItems = data[eventListFeature].Total;
          this.events = data[eventListFeature].Events;
          this.eventsDataSource = new MatTableDataSource(this.events);
          this.eventsDataSource.paginator = this.paginator;
        }
        else {
          this.eventsDataSource = new MatTableDataSource();
        }
      }
      this.loadingData = false;
    }, context);

    let firstRequest: DeviceEventsRequest = {
      Device: deviceId,
      Page: this.myPageIndex,
      Limit: this.myPageSize,
    };

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        deviceFeature = this.apiSync.loadedFeature(context);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response && response.length > 0) {
        if (response.some(x => x.Name == 'Administrators')) {
          this.isOnlyDomainAdmin = false;
        } else {
          this.isOnlyDomainAdmin = true;
        }

        this.apiService.deviceEventsList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          if (res) {
            eventListFeature = this.apiSync.loadedFeatureWithData(res, context);
          }
          else {
            eventListFeature = this.apiSync.failedFeature(context);
          }
        });
      } else {
        eventListFeature = this.apiSync.failedFeature(context);
      }
    });
  }

  public loadLatestData(): void {
    this.loadingData = true;
    this.clearDate = !this.clearDate;

    let latestEventsRequest: DeviceEventLatestRequest = { DeviceId: this.currentDevice.Id };

    this.apiService.getDeviceEventLatest24Hours(latestEventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      if(res && res.Devices?.length > 0) {
        this.setLatestDates(res);

        this.myTotalItems = res.Devices[0].Events.length;
        this.events = this.mapToEventList(res);
        this.eventsDataSource = new MatTableDataSource(this.events);
        timer(0).subscribe(() => {
          this.eventsDataSource.paginator = this.paginator;
        });
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.paginator?.firstPage();
    }

    let serverRequest: DeviceEventsRequest = {
      Device: this.currentDevice.Id,
      Page: this.myPageIndex,
      Limit: this.myPageSize,
      Start: this.startDate,
      End: this.endDate
    }

    this.apiService.deviceEventsList(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res) {
        this.myTotalItems = res.Total;
        this.events = res.Events;
        this.eventsDataSource = new MatTableDataSource(this.events);
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  public addEvent(): void {
    if (this.isAdmin) {
      const addEventDialog = this.dialog.open(ManualEventDialogComponent, {
        disableClose: false,
        data:{
          deviceId: this.currentDevice.Id
        }
      });

      addEventDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(deviceEvent => {
        if (deviceEvent) {
          const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          })

          confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if(result){
              this.administratorApiService.deviceEventAdd(deviceEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                this.loadLatestData();
              });
            }
          });
        }
      });
    }

  }

  private mapToEventList(res: DeviceEventLatestResponse): DeviceEvent[] {
    let deviceEvents: DeviceEvent[] = [];

    res.Devices[0].Events.forEach(event => {
      deviceEvents.push({
        Id: event.Id,
        DeviceId: res.Devices[0].Id,
        DeviceName: res.Devices[0].Name,
        DeviceType: res.Devices[0].Model.Type.Name,
        Type: event.Type,
        Level: event.Level,
        Created: event.CreatedTimestamp,
        Body: JSON.stringify(event.Body),
        Icon: res.Devices[0].Model.Type.Icon
      })
    });

    return deviceEvents
  }

  public setupFilter() {
    this.eventsDataSource.filterPredicate = (d: any, filter: string) => {
      let created: string = new Date(d.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/');
      return (d.Id.toLowerCase().includes(filter)) || (created.toLowerCase().includes(filter)) ||
      (d.Type.toLowerCase().includes(filter)) || (d.Level.toLowerCase().includes(filter)) ||
      (d.Body.toLowerCase().includes(filter))
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.eventsDataSource.filter = filterValue.trim().toLowerCase();
  }

  public applyFilterString(filter: string) {
    const filterValue = filter;
    this.eventsDataSource.filter = filterValue.trim().toLowerCase();
  }

  public getServerData(event: PageEvent) {
    if (event.previousPageIndex === event.pageIndex - 1) {
      this.myPageIndex = this.myPageIndex + 1;
    } else if (event.previousPageIndex === event.pageIndex + 1) {
      this.myPageIndex = this.myPageIndex - 1;
    } else if (event.pageIndex === Math.floor(event.length / event.pageSize)) {
      this.myPageIndex = Math.floor(event.length / event.pageSize);
    } else if (event.pageIndex === 0) {
      this.myPageIndex = 0;
    }
    this.myPageSize = event.pageSize;
    if (this.loadingData === false) {
      this.newSearch();
    }
  }

  private getEventLevelColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push('#BC9A29');

    return colors;
  }

  public openDetailDialog(singleEvent: DeviceEvent): void {
    this.dialog.open(DeviceEventDetailDialogComponent, {
      data: { targetEvent: singleEvent },
      maxWidth: 1000,
      width: '70%'
    });
  }

  // public selectEvent(eventId: string, event: Event): void {
  //   event.preventDefault();
  //   if (this.selectedEvents === undefined) {
  //     this.selectedEvents = {};
  //   }

  //   this.selectedEvents[eventId] = this.selectedEvents[eventId] !== true;

  //   if (Object.values(this.selectedEvents).every(value => !value)) {
  //     this.selectedEvents = undefined;
  //   }
  // }

  // public deselectEvents(event: Event): void {
  //   event.preventDefault();
  //   this.selectedEvents = undefined;
  // }

  // public deleteEvents(): void {
  //   const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
  //     disableClose: false
  //   })


  //   let eventsIds: string = Object.keys(this.selectedEvents)
  //   .filter((key: string) => this.selectedEvents[key]).join(',');
  //   console.log(eventsIds);

  //   confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
  //     if(result){
  //       let eventsIds: string = Object.keys(this.selectedEvents)
  //         .filter((key: string) => this.selectedEvents[key]).join(',');
  //       this.administratorApiService.deviceEventDelete(eventsIds)
  //         .pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
  //         this.selectedEvents = undefined;
  //         this.loadLatestData();
  //       });
  //     }
  //   });
  // }

  public deleteEvent(eventId: string): void {
    if (this.isAdmin) {
      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      })

      confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if(result){
          this.administratorApiService.deviceEventDelete(eventId)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadLatestData();
          });
        }
      });
    }
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
