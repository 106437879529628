<h1 *ngIf="this.isAdd" mat-dialog-title>{{ 'DEVICE_MODEL.ADD' | translate }}</h1>
<h1 *ngIf="!this.isAdd" mat-dialog-title>{{ 'DEVICE_MODEL.UPDATE' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'DEVICE_MODEL.BRAND' | translate }}</mat-label>
            <input matInput formControlName="brand" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select formControlName="type">
                <mat-option *ngFor="let type of deviceTypes" value={{type.Id}}>
                    {{type.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.PROPERTIES' | translate }}</mat-label>
            <input matInput formControlName="properties" autocomplete="off">
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button *ngIf="this.isAdd" type="submit" class="btn actionButton" (click)="addition()">
        {{ 'GENERAL.ADD' | translate }}
    </button>
    <button mat-button *ngIf="!this.isAdd" type="submit" class="btn actionButton" (click)="addition()">
        {{ 'GENERAL.UPDATE' | translate }}
    </button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
