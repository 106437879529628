import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api.service';
import { PasswordValidator } from './password-validator';
import { TranslateService } from '@ngx-translate/core';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit, OnDestroy {
  public error: string | null = null;
  public success: string | null;
  public hideNewPassword: boolean = true;
  public hideConfirmedNewPassword: boolean = true;
  public token: string | null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isLittleScreen: boolean;
  public regexPassword: string = '';
  public form: UntypedFormGroup;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  constructor(
    public fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private translate: TranslateService,
    private passDataService: PassDataService
  ) {
    if (window.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  ngOnInit(): void {
    this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
      this.regexPassword = config?.Settings?.regex_password;
    });

    this.form = this.fb.group({
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.pattern(this.regexPassword)])],
      confirmedNewPassword: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.pattern(this.regexPassword)])]
    }, { validator: PasswordValidator.MatchPassword }
    );

    this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      this.token = resp.params['token'];
      this.apiService.checkToken(this.token).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        success => { },
        err => this.router.navigate(['forgot-password'])
      )
    })
    //funzione utile per tirare fuori i parametri dall'url durante il cambio pagina
  }

  async submit() {
    if (this.form.valid) {
      let newPassword = await this.getSha256(this.form.get('newPassword').value);
      this.apiService.resetPassword(newPassword, this.token)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            if (res.Result === "fail") {
              this.success = null;
              this.error = 'ERROR_FAIL';
            } else {
              this.error = null;
              this.success = 'SUCCESS';

              setTimeout(() => {
                this.router.navigate(['login'])
              }, 1500);
            }
          },
        );
    }

    else {
      if (this.form.controls.newPassword.errors?.required == true || this.form.controls.confirmedNewPassword.errors?.required == true) {
        this.translate.get('CHANGE_PASSWORD_DIALOG.ERROR_EMPTY').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          this.error = res;
        });
      }
      else {
        if (this.form.controls.newPassword.errors?.minlength) {
          this.translate.get('CREATE_DASHBOARD_DIALOG.ERROR').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.error = res;
          });
        }
        else {
          if (this.form.controls.confirmedNewPassword.errors?.MatchPassword) {
            this.translate.get('CHANGE_PASSWORD_DIALOG.ERROR_EQUAL').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
              this.error = res;
            });
          }
          else if (this.form.controls.newPassword.errors?.pattern) {
            this.translate.get('CHANGE_PASSWORD_DIALOG.ERROR_SYNTAX').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
              this.error = res;
            });
          }
        }
      }
    }
  }

  async getSha256(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);

    let hashBuffer: ArrayBuffer = await window.crypto.subtle.digest('SHA-256', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
