import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartData, LinearScale, SankeyControllerDatasetOptions, SankeyDataPoint, Tooltip} from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';
import { SankeyDataList } from '../../models/ChartDataList';
import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-sankey-diagram-chart',
  templateUrl: './sankey-diagram-chart.component.html',
  styleUrls: ['./sankey-diagram-chart.component.scss']
})
export class SankeyDiagramChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input('sankeyDiagramChartTitle') public chartTitle: string = 'Sankey Diagram Chart Title';
  @Input('passedDataList') private passedDataList: SankeyDataList = {};
  @Input('passedSubtitle') public chartSubtitle: string = null;
  @Input('darkThemeActive') public darkThemeActive: boolean;

  @HostBinding('style.--container-height') private containerHeight: string = '0';

  private chart: Chart;
  private dataListEmpty: boolean = true;
  public noDataOnChart: boolean = false;
  private chartTitleTranslated: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private colors: Record<number, string> = {
    0: this.getChartColors()[6].trim(),
    1: this.getChartColors()[3].trim(),
    2: this.getChartColors()[0].trim(),
    3: this.getChartColors()[1].trim(),
    4: "gray"
  };

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    Chart.register(SankeyController, Flow, LinearScale, Tooltip );
    this.getTranslations();
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
      this.drawChart();
    });
  }

  getTranslations(): void {
    this.translate.get(this.chartTitle).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.chartTitleTranslated = res;
    });
  }

  private drawChart(): void {
    this.clearChart();
    if(!this.dataListEmpty) {
      this.noDataOnChart = false;

      let colorLinks: Record<string, number> = {};
      let data: Array<SankeyDataPoint> = [];

      let priority: Record<string, number> = {};
      let exitPriorityCounter = 0;

      let entranceCheck: Record<string, boolean> = {};
      let exitCheck: Record<string, boolean> = {};

      Object.keys(this.passedDataList).forEach((entrance: string, index: number) => {
        priority[entrance] = index;
        entranceCheck[entrance] = false;
        colorLinks[entrance] = index;
        Object.keys(this.passedDataList[entrance]).forEach((exit: string) => {
          if(priority[exit+' '] !== undefined) {
            priority[exit+' '] = exitPriorityCounter++;
          }
          if(exitCheck[exit] === undefined) {
            exitCheck[exit] = false;
          }
          let flow = this.passedDataList[entrance][exit];
          if(flow > 0) {
            entranceCheck[entrance] = true;
            exitCheck[exit] = true;
            data.push({
              from: entrance,
              to: exit+' ',
              flow
            });
          }
        });
      });

      let entranceHeight: number = Object.keys(entranceCheck).filter(key => entranceCheck[key]).length;
      let exitHeight: number = Object.keys(exitCheck).filter(key => exitCheck[key]).length;

      let maxNodesHeight: number = Math.max(
        entranceHeight,
        exitHeight
      );

      this.containerHeight = `${maxNodesHeight * 100}px`;

      let dataset: SankeyControllerDatasetOptions = {
        label: this.chartTitleTranslated,
          data,
          priority,
          colorMode: "gradient",
          colorFrom: (singleFlow) => {
            let labelKey: string = singleFlow.raw['from'];
            let colorIndex: number = colorLinks[labelKey];
            return this.colors[colorIndex];
          },
          colorTo: (singleFlow) => {
            let labelKey: string = (singleFlow.raw['to']).slice(0,-1);
            let colorIndex: number = colorLinks[labelKey];
            return this.colors[colorIndex];
          },
          size: 'max', // or 'min' if flow overlap is preferred,
          borderWidth: 0
      };

      let chartData: ChartData = {
        datasets: [ dataset ]
      };

      var canvas: HTMLCanvasElement = <HTMLCanvasElement> document.getElementById("sankeyChart");
      var context: CanvasRenderingContext2D = canvas.getContext("2d");
      this.chart = new Chart(
        context,
        {
          type: 'sankey',
          data: chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false
          },
        }
      );
    }
    else {
      this.noDataOnChart = true;
    }
  }

  private clearChart(): void {
    if(this.chart) {
      this.chart.destroy();
      this.containerHeight = '0';
    }
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    
    return colors;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['darkThemeActive']) {
      const isDarkActive = changes['darkThemeActive'];
      if (isDarkActive.currentValue !== isDarkActive.previousValue && isDarkActive.firstChange == false) {
        this.drawChart();
      }
    }
    if (changes['passedDataList']) {
      const updatedDataList = changes['passedDataList'];
      if (updatedDataList.currentValue !== updatedDataList.previousValue && updatedDataList.firstChange == false) {
        let dataList: SankeyDataList = updatedDataList.currentValue;
        this.dataListEmpty = true;
        for(let entrance in dataList) {
          for(let exit in dataList[entrance]) {
            if(dataList[entrance][exit] > 0) {
              this.dataListEmpty = false;
              break;
            }
          }
          if(!this.dataListEmpty) {
            break;
          }
        }
        this.drawChart();
      }
    }
  }

  ngOnDestroy(): void {
    this.clearChart();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
