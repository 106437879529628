<h1 mat-dialog-title>{{ 'GENERAL.EXECUTE_OPERATION' | translate }} : {{this.action}}</h1>
<mat-dialog-content>
    <form>
        <div *ngIf="this.parameters && this.hasLength(this.parameters)">
            <div class="separator">{{ 'GENERAL.PARAMETERS' | translate }}</div>
            <br>
            <mat-form-field *ngFor="let parameter of getKeys(this.parameters)">
                <mat-label>{{parameter}}</mat-label>
                <input matInput value="{{this.parameters[parameter]}}" id="{{parameter}}" type="text"
                    autocomplete="off">
            </mat-form-field>
            <br>
        </div>

        <div *ngIf="this.body && this.hasLength(this.body)">

            <div class="separator">{{ 'GENERAL.BODY' | translate }}</div>
            <br>
            <ng-container *ngFor="let bodyKey of getKeys(this.body)">
                <mat-form-field *ngIf="!this.isObject(this.body, bodyKey)">
                    <mat-label>{{bodyKey}}</mat-label>
                    <input *ngIf="this.getType(this.body, bodyKey) !== 'number'" matInput value="{{this.body[bodyKey]}}"
                        id="{{bodyKey}}" type="text" autocomplete="off">
                    <input *ngIf="this.getType(this.body, bodyKey) === 'number'" matInput value="{{this.body[bodyKey]}}"
                        id="{{bodyKey}}" type="number" autocomplete="off">
                </mat-form-field>

                <div *ngIf="this.isObject(this.body, bodyKey)" id={{bodyKey}}>
                    <mat-expansion-panel class="mat-elevation-z0">
                        <mat-expansion-panel-header class="pl-0">
                            <mat-panel-title>
                                {{bodyKey}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="p-0" *ngFor="let subKey of getKeys(this.body[bodyKey])">
                            <mat-form-field style="display: block;">
                                <mat-label>{{subKey}}</mat-label>
                                <input matInput *ngIf="this.getType(this.body[bodyKey], subKey) !== 'number'"
                                    value="{{this.body[bodyKey][subKey]}}" id="{{bodyKey + subKey}}" type="text"
                                    autocomplete="off">
                                <input matInput *ngIf="this.getType(this.body[bodyKey], subKey) === 'number'"
                                    value="{{this.body[bodyKey][subKey]}}" id="{{bodyKey + subKey}}" type="number"
                                    autocomplete="off">
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                </div>
            </ng-container>
        </div>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="continue()">{{ 'GENERAL.CONTINUE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
