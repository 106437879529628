import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { GetAdvertisingsEventsRequest } from '../../../shared/models/advertising/advertisingRequest';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdvertisingEvent } from 'src/app/shared/models/advertising/advertising';
import { GetAdvertisingsEventsResponse, GetAdvertisingsEventsResponseItem } from 'src/app/shared/models/advertising/advertisingResponse';
import { Domain } from 'src/app/shared/models/domain';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'urban-advertising-event-list',
  templateUrl: './advertising-event-list.component.html',
  styleUrls: ['./advertising-event-list.component.scss']
})
export class AdvertisingEventListComponent implements OnInit {
  @ViewChild('filterInput') private filterInput: MatInput;

  public isAdmin: boolean = false;
  public eventColumns: string[] = ['Id', 'AdvertisingId', 'Created', 'Domain', 'IP address'];
  public events: AdvertisingEvent[] = [];
  public domains: Domain[] = [];
  public startDate: number;
  public endDate: number;
  public actualFilter: string = '';
  public lastFilter: string;
  public filterForm: UntypedFormGroup = this.formBuilder.group({
    domain: ''
  });
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (!res.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.loadData();
    });

    this.filterForm.valueChanges.subscribe((newFilterValues: Record<string,string>) => {
      if (Object.values(newFilterValues).some(value => value !== null)) {
        this.config.currentPage = 1;
        this.newSearch();
      }
    });
  }

  private loadData(): void {
    let advEventsApi: number;
    let advEventsContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, advEventsContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues.every(value => value)) {
        this.events = data[advEventsApi].map((item: GetAdvertisingsEventsResponseItem) => {
          return {
            ...item,
            Domain: this.domains.find((domain: Domain) => domain.Id === item.DomainId)
          };
        });
      }

      this.loadingData = false;
    }, advEventsContext);

    this.apiService.getAdvertisingsEvents().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.Items?.length > 0) {
        advEventsApi = this.apiSync.loadedFeatureWithData(res.Items, advEventsContext);
        this.config.totalItems = res.Total;
      }
      else {
        advEventsApi = this.apiSync.failedFeature(advEventsContext);
      }
    });

    this.apiService.getDomainsAsAdmin().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Domain[]) => {
      if (res?.length > 0) {
        this.domains = res;
        this.apiSync.loadedFeature(advEventsContext);
      }
      else {
        this.apiSync.failedFeature(advEventsContext);
      }
    });
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.filterForm.reset();
      this.applyFilter('');
      this.config.currentPage = 1;
    }

    let getAdvertisingEventsRequest: GetAdvertisingsEventsRequest = {
      Page: this.config.currentPage - 1,
      Limit: this.config.itemsPerPage,
      Filter: this.actualFilter,
      Start: this.startDate,
      End: this.endDate,
      Domain: this.filterForm.controls.domain.value
    }

    this.apiService.getAdvertisingsEvents(getAdvertisingEventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
      .subscribe((res: GetAdvertisingsEventsResponse) => {
        if (res && res.Items?.length > 0) {
          this.events = res.Items.map((item: GetAdvertisingsEventsResponseItem) => {
            return {
              ...item,
              Domain: this.domains.find((domain: Domain) => domain.Id === item.DomainId)
            };
          });
          this.config.totalItems = res.Total;
          this.lastFilter = this.actualFilter;
        }
        else {
          this.events = [];
          this.config.itemsPerPage = 10;
          this.config.currentPage = 1;
          this.config.totalItems = 0;
        }

        this.loadingData = false;
      });
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.actualFilter = filterValue;

    if (filterValue === '' && this.filterInput?.value && this.filterInput.value.trim() !== '') {
      this.filterInput.value = '';
    }
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
    this.newSearch();
  }

  public paginatorOnShownChange(): void {
    this.config.currentPage = 1;
    this.newSearch();
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.config.totalItems / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
