import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {PaginationInstance} from "ngx-pagination";
import {AdministratorApiService} from "../../../../shared/services/administrator-api.service";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {Configuration} from "../../../../shared/models/configuration";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {ThemeRequest} from "../../../../shared/models/administrator/themeRequest";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {Router} from "@angular/router";

@Component({
  selector: 'urban-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss']
})
export class ThemesComponent implements OnInit, OnDestroy {

  columns = ['Id', 'Hostname', 'Detail'];
  public themes: ThemeRequest[] = [];
  public filteredData: ThemeRequest[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  private appConfig: Configuration;

  constructor(
    private administratorApiService: AdministratorApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._initThemes();

    this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
      this.appConfig = config;
    });
  }

  private _initThemes(): void {
    this.administratorApiService.themeList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      this.themes = result;
      this.filteredData = this.themes;
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.themes?.filter((theme: ThemeRequest) =>
      [theme?.Hostname?.toLowerCase(), theme?.Id?.toString().toLowerCase()]
        .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public detail(theme: ThemeRequest): void {
    if (!theme) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: theme.Id.toString(), BackRoute: 'themes'});
    this.router.navigate(['main/theme-detail']);
  }

  public add(): void {
    this.mainService.setNavigationInfoComand({BackRoute: 'themes'});
    this.router.navigate(['main/theme-detail']);
  }

  public delete(theme: ThemeRequest): void {
    if (theme) {
      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorApiService.themeDelete(theme.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initThemes();
            }
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
