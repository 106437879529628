import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMain from './main.reducer';

const mainSelector = createFeatureSelector<fromMain.MainState>(fromMain.mainFeatureKey);

export const getResources = createSelector(mainSelector, fromMain.selectResourcesList);

export const getGoogleMapsKey = createSelector(mainSelector, fromMain.selectGoogleMapsKey)

export const getDashboards = createSelector(mainSelector, fromMain.selectDashboardList);

export const getWidgets = createSelector(mainSelector, fromMain.selectWidgetList);

export const getDashboardToShow = createSelector(mainSelector, fromMain.selectDashboardToShow);

export const getDevices = createSelector(mainSelector, fromMain.selectDeviceList);

export const getBrands = createSelector(mainSelector, fromMain.selectBrandsList);

export const getModels = createSelector(mainSelector, fromMain.selectModelsList);

export const getClientSettings = createSelector(mainSelector, fromMain.selectClientSettings);

export const getRCServices = createSelector(mainSelector, fromMain.selectRCServices);

export const getWSSensors = createSelector(mainSelector, fromMain.selectWSSensors);

export const getNotificationToShow = createSelector(mainSelector, fromMain.selectNotificationToShow);

export const getCustomDomainStyle = createSelector(mainSelector, fromMain.selectCustomDomainStyle);

export const getLocations = createSelector(mainSelector, fromMain.selectLocationList);

export const getNavigationInfo = createSelector(mainSelector, fromMain.selectNavigationInfo);

export const getSuccess = createSelector(mainSelector, fromMain.selectSuccess);

export const getError = createSelector(mainSelector, fromMain.selectError);
