import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'urban-remote-control-loader',
  templateUrl: './remote-control-loader.component.html',
  styleUrls: ['./remote-control-loader.component.scss']
})
export class RemoteControlLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
