<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span *ngIf="currentDevice" class="title2 appearingTextRight">
                {{ this.currentDevice.Name + ' - '}}
            </span>
            <span class="title2">{{passedBacnetDevice}}</span>
        </div>
    </div>

    <urban-searchbar id="intro-device-monitor-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
            [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
            (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <mat-tab-group id="intro-devices-table-tab" animationDuration="0ms" dynamicHeight="true">
        <mat-tab [label]="'GENERAL.EVENTS' | translate">
            <div class="tabContent">
                <div class="customTable mt-5">
                    <div class="d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
                        <span class="title3">{{ 'DEVICE.EVENTS' | translate }}</span>

                        <mat-form-field class="tableSearch" appearance="outline">
                            <div matPrefix class="prefix">
                                <i class="urbanicon-search"></i>
                            </div>
                            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                            <input matInput (keyup)="applyFilter($event)" (paste)="applyFilter($event)"
                                autocomplete="off" [(ngModel)]="actualFilter">
                            <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                                <i class="urbanicon-close"></i>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="tableHeight" *ngIf="bacnetEvents && bacnetEvents.length > 0">
                        <table class="w-100 h-100" mat-table [dataSource]="filteredData | paginate: config">

                            <ng-container matColumnDef="Device">
                                <th mat-header-cell *matHeaderCellDef style="width: 15%"> {{'GENERAL.DEVICE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item"> {{item.Device}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Created">
                                <th mat-header-cell *matHeaderCellDef style="width: 15%"> {{'GENERAL.CREATED' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item"> {{item.Created * 1000 | date: 'short'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.NAME' | translate }} </th>
                                <td mat-cell *matCellDef="let item">
                                    {{item.Name}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Property">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.PROPERTY' | translate }} </th>
                                <td mat-cell *matCellDef="let item">
                                    <span style="word-break: keep-all;">{{item.Property}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Value">
                                <th mat-header-cell *matHeaderCellDef style="width: 15%"> {{'GENERAL.VALUE' | translate }} </th>
                                <td mat-cell *matCellDef="let item">
                                    {{item.Value}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                    <div *ngIf="bacnetEvents && bacnetEvents.length === 0"
                        class="d-flex flex-column align-items-center justify-content-center
                        noDataIllustration responsiveIllustration py-2 customTableRadius">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex">
                            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>

                    <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
                        <div class="d-flex flex-wrap my-3 my-sm-0">
                            <pagination-controls class="customNgxPaginator"
                                [ngClass]="{'disabled' : (bacnetEvents && bacnetEvents.length === 0)}"
                                [maxSize]="7"
                                [directionLinks]="true"
                                [previousLabel]="''"
                                [nextLabel]="''"
                                [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                                [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                                [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                                (pageChange)="paginatorOnPageChange($event)">
                            </pagination-controls>

                            <div class="paginatorPageCounter">
                                {{config.currentPage}} {{'GENERAL.OF' | translate}}
                                {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                                {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                            </div>
                        </div>

                        <div>
                            <mat-form-field class="customSelect" appearance="fill">
                                <mat-select [(value)]="config.itemsPerPage">
                                    <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                                        {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
            <div class="tabContent" *ngIf="chartsTab.isActive && lineChartData">
                <div class="roundedPanel panelBG chartContainer" *ngFor="let chart of lineChartData | keyvalue; let i = index">
                    <urban-google-linechart valueDescription="LINECHART.SENSOR_VALUE"
                        [startDate]="startDate" [endDate]="endDate" [lineChartMultiDataList]="chart.value"
                        [darkThemeActive]="isDarkActive" [linechartId]="i" [propertiesList]="propertiesList[chart.key]" [multiChartTitle]="chart.key">
                    </urban-google-linechart>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
