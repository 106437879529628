import { SearchDates } from 'src/app/shared/models/searchDates';
import { first, takeUntil } from 'rxjs/operators';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Device } from 'src/app/shared/models/device';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DeviceEvent } from 'src/app/shared/models/deviceEvent';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { BacnetEventsRequest } from 'src/app/shared/models/bacnet/bacnetEventsRequest';
import { PaginationInstance } from 'ngx-pagination';
import { BacnetEvents } from 'src/app/shared/models/bacnet/bacnetEvents';
import { BacnetEvent } from 'src/app/shared/models/bacnet/bacnetEvent';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'urban-bacnet-monitor',
  templateUrl: './bacnet-monitor.component.html',
  styleUrls: ['./bacnet-monitor.component.scss']
})
export class BacnetMonitorComponent implements OnInit {
  public bacnetDevices: BacnetEvent[];
  public filteredData: BacnetEvent[];
  public startDate: number;
  public endDate: number;
  public lastCreated: number;
  public loadingData: boolean;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public last24hSearch: boolean = true;
  public currentDevice: Device;
  public displayedColumns: string[] = ['Device', 'Detail'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private apiSync: ApiSynchronizerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    let deviceId: string;
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if(navInfo?.Id) {
        deviceId = navInfo.Id;
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadData(deviceId);
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  public loadData(deviceId: string): void {
    let deviceFeature: number, eventsFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        if (checkValues[eventsFeature]) {
          this.bacnetDevices = this.formattedBacnetEvents(data[eventsFeature].Events);
          this.filteredData = this.bacnetDevices;
        }
      }
      this.loadingData = false;
    }, context);

    let now = Math.round(Date.now() / 1000);
    let yesterday = now - (24 * 3600);

    let firstRequest: BacnetEventsRequest = {
      DeviceId: deviceId,
      Start: now,
      End: yesterday,
      Minutes: 1
    }

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        deviceFeature = this.apiSync.loadedFeature(context);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.apiService.bacnetEventsList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.resetAllData();

      if (res?.Events && res.Events.length > 0) {
        eventsFeature = this.apiSync.loadedFeatureWithData(res, context);
      } else {
        eventsFeature = this.apiSync.failedFeature(context);
      }
    });
  }

  public loadLatestData(): void {
    this.loadingData = true;
    this.clearDate = !this.clearDate;

    let latestEventsRequest: DeviceEventLatestRequest = { DeviceId: this.currentDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(latestEventsRequest, this.isAProperEvent)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
      .subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if(res && res.Devices?.length > 0) {
        this.setLatestDates(res);

        this.bacnetDevices = this.mapToBacnetLatestEvents(res);
        this.filteredData = this.bacnetDevices;
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
    }

    let serverRequest: BacnetEventsRequest = {
      DeviceId: this.currentDevice.Id,
      Start: this.startDate,
      End: this.endDate,
      Minutes: 1
    }

    this.apiService.bacnetEventsList(serverRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.resetAllData();

      if (res) {
        this.bacnetDevices = this.formattedBacnetEvents(res.Events);
        this.filteredData = this.bacnetDevices;
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  private resetAllData(): void {
    this.bacnetDevices = [];
    this.filteredData = [];
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event));
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find((event: EventLatest) =>
          event.Level === 'Info' && this.isAProperEvent(event));
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private formattedBacnetEvents(events: BacnetEvents[]): BacnetEvent[] {
    let bacnetDevices: BacnetEvent[] = [];

    events.forEach((event) => {
      bacnetDevices.push({
        Device: event.Device
      });
    });

    return bacnetDevices;
  }

  private isAProperEvent(event: EventLatest): boolean {
    let eventBody = event.Body;
    return eventBody &&
    ['Device', 'Values'].every((key: string) => Object.keys(eventBody).includes(key)) &&
    Array.isArray(eventBody.Values) && eventBody.Values.length > 0 &&
    eventBody.Values.every(value => ['Name', 'Property', 'Value']
      .every((key: string) => Object.keys(value).includes(key)) &&
    typeof value.Value === 'number')
  }

  private mapToBacnetLatestEvents(res: DeviceEventLatestResponse): BacnetEvent[] {
    let bacnetDevices: BacnetEvent[] = [];

    res.Devices[0].Events.forEach(event => {
      let eventBody = event.Body;

      if (eventBody && !bacnetDevices.some(el => el.Device.includes(eventBody.Device)) &&
        ['Device', 'Values'].every((key: string) => Object.keys(eventBody).includes(key)) &&
        Array.isArray(eventBody.Values) && eventBody.Values.length > 0 &&
        eventBody.Values.every(value => ['Name', 'Property', 'Value']
          .every((key: string) => Object.keys(value).includes(key)) &&
        typeof value.Value === 'number')
      )
      {
        bacnetDevices.push({
          Device: event.Body.Device
        });
      }
    });

    return bacnetDevices;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.bacnetDevices.filter((device: BacnetEvent) =>
      [
        device.Device.toLowerCase()
      ].some((x) => x?.includes(filterValue.toLowerCase()))
    );

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToBacnetDetail(device: string): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, Type: device, BackRoute: 'bacnet-monitor' });
    this.router.navigate(['main/bacnet-detail']);
  }

  public goToDeviceEvents(): void {
    if (this.currentDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'bacnet-monitor' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
