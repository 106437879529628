import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'urban-device-group-detail-dialog',
  templateUrl: './device-group-detail-dialog.component.html',
  styleUrls: ['./device-group-detail-dialog.component.scss']
})
export class DeviceGroupDetailDialogComponent implements OnInit {

  public deviceGroup: string;

  constructor(public dialogRef: MatDialogRef<DeviceGroupDetailDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        deviceGroup: string
      }) {
    this.deviceGroup = data.deviceGroup;
  }

  ngOnInit(): void {
  }

  go() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }

}
