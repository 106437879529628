export interface Note {
  id: number,
  title: string,
  content: string,
  color: string,
  created?: number,
  position?: number,
  colorActive: number,
}

export const NEW_NOTE: Note = {
  id: null,
  title: '',
  content: '',
  color: 'lavender',
  colorActive: 0,
}
