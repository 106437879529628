import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomainProperty } from '../../models/domainProperty';
import { Resource } from '../../models/resource';
import { StyleModel } from '../../models/styleModel';
import { MatTooltip } from '@angular/material/tooltip';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'urban-add-domain-property-dialog',
  templateUrl: './add-domain-property-dialog.component.html',
  styleUrls: ['./add-domain-property-dialog.component.scss']
})
export class AddDomainPropertyDialogComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup = this.formBuilder.group({
    key: [{ value: '', disabled: false }, Validators.required],
    themeCheck: { value: false, disabled: false},
    sidebarCheck: { value: false, disabled: false },
    value: [{ value: '', disabled: false }, Validators.required]
  });

  public error: string | null;
  public sidebarResources: Resource[];
  public sidebarAllowedResources: Record<string, boolean>;
  private themeString: string = 'ThemeVariables';
  private sidebarString: string = 'sidebarResources';
  private tempString: string = '';
  public themeCase: boolean = false;
  public sidebarCase: boolean = false;
  public styleModels: StyleModel[] = [];
  public sidebarCheckboxes: boolean[] = [];
  public domainProperty: DomainProperty;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AddDomainPropertyDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        sidebarResources: Resource[]
      }
  ) {
    if (this.data.sidebarResources) {
      this.sidebarResources = this.data.sidebarResources;
    }
  }


  ngOnInit(): void {
    this.form.controls.key.valueChanges.subscribe(value => {
      let themeCheck: boolean = this.form.controls.themeCheck.value;
      let sidebarCheck: boolean = this.form.controls.sidebarCheck.value;
      if (!themeCheck && value.includes(this.themeString)) {
        this.form.patchValue({ themeCheck: true });
        this.setThemeInputs();
      }
      else if (themeCheck && !value.includes(this.themeString)) {
        this.form.patchValue({ themeCheck: false });
        this.unsetThemeInputs();
      }

      if (!sidebarCheck && value === this.sidebarString) {
        this.form.patchValue({ sidebarCheck: true });
        this.setSidebarInputs();
      }
      else if (sidebarCheck && value !== this.sidebarString) {
        this.form.patchValue({ sidebarCheck: false });
        this.unsetSidebarInputs();
      }
    });

    this.form.controls.themeCheck.valueChanges.subscribe(check => {
      let key: string = this.form.controls.key.value;
      if (!key.includes(this.themeString) && check) {
        if (this.tempString === '') {
          this.tempString = key;
        }
        this.form.patchValue({ key: this.tempString + this.themeString });
        this.setThemeInputs();
      }
      else if (key.includes(this.themeString) && !check) {
        this.form.patchValue({ key: this.tempString });
        this.tempString = '';
        this.unsetThemeInputs();
      }
    });

    this.form.controls.sidebarCheck.valueChanges.subscribe(check => {
      let key: string = this.form.controls.key.value;
      if (key !== this.sidebarString && check) {
        if (this.tempString === '' && key !== this.sidebarString) {
          if (key.includes(this.themeString)) {
            this.tempString = key.replace(this.themeString, '');
          }
          else {
            this.tempString = key;
          }
        }
        this.form.patchValue({ key: this.sidebarString });
        this.setSidebarInputs();
      }
      else if (key === this.sidebarString && !check) {
        this.form.patchValue({ key: this.tempString });
        this.tempString = '';
        this.unsetSidebarInputs();
      }
    });
  }

  private setThemeInputs(): void {
    let propertyValue: string = this.form.controls.value.value;
    if (propertyValue && propertyValue !== '') {
      let styleVariables: StyleModel[] = [];

      propertyValue.split(';').forEach(styleString => {
        let fields: string[] = styleString.split(':').map(element => element.trim());
        if (fields?.length === 2 && fields.every(field => field && field !== '')) {
          let styleModel: StyleModel = {
            Variable: fields[0],
            Value: fields[1]
          };
          styleVariables.push(styleModel);
        }
      });
      this.styleModels = styleVariables;
    }
    this.themeCase = true;
  }

  private unsetThemeInputs(): void {
    this.updatePropertyValue();
    this.themeCase = false;
  }

  private setSidebarInputs(): void {
    let propertyValue: string = this.form.controls.value.value;
    this.sidebarAllowedResources = {};

    if (propertyValue && propertyValue !== '') {
      let allowedResources: string[];
      this.sidebarAllowedResources = {};

      try {
        allowedResources = JSON.parse(propertyValue);
      }
      catch {
        allowedResources = [];
      }
      finally {
        allowedResources.forEach((allowedResource) => {
          this.sidebarAllowedResources[allowedResource] = true;
        });
      }
    }

    this.sidebarCase = true;
  }

  private unsetSidebarInputs(): void {
    this.updatePropertyValue();
    this.sidebarCase = false;
  }

  private updatePropertyValue(): void {
    let propertyValue: string = '';

    if (this.themeCase) {
      this.styleModels.forEach((styleModel: StyleModel) => {
        propertyValue += ' ' + styleModel.Variable + ': ' + styleModel.Value + ';';
      });
    }
    else if (this.sidebarCase) {
      propertyValue = JSON.stringify(Object.keys(this.sidebarAllowedResources));
    }

    this.form.patchValue({ value: propertyValue });
  }

  public checkStyleVariableName(variableName: any, index: number, inputRef: HTMLInputElement, tooltip: MatTooltip): void {
    let newValue: string = variableName.target.value;
    if(newValue !== this.styleModels[index].Variable && !newValue.includes('--custom-chart-')) {
      let stylePropertyCount: number = this.styleModels.filter((styleProperty, i) => styleProperty.Variable.split('#')[0] === newValue && i !== index).length;
      if (stylePropertyCount > 0) {
        let newDuplicatedValue: string = newValue + '#' + (stylePropertyCount + 1);
        this.styleModels[index].Variable = newDuplicatedValue;
        inputRef.value = newDuplicatedValue;

        if (this.form.controls.key.value === this.themeString) {
          let styleProperty: string = window.getComputedStyle(document.body).getPropertyValue(newDuplicatedValue);
          if (styleProperty && styleProperty !== '') {
            this.unsetWarningInput(inputRef, tooltip);
          }
          else {
            this.setWarningInput(inputRef, tooltip);
          }
        }
      }
      else {
        let styleProperty: string = window.getComputedStyle(document.body).getPropertyValue(newValue);
        if (this.form.controls.key.value === this.themeString) {
          this.styleModels[index].Variable = newValue;
        }
        else if (styleProperty && styleProperty !== '') {
          this.styleModels[index].Variable = newValue;

          this.unsetWarningInput(inputRef, tooltip);
        }
        else {
          if (tooltip.disabled) {
            const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
              disableClose: false,
              data: { message: 'DOMAIN_DETAIL.NOT_STYLE_PROPERTY'}
            });

            confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                this.styleModels[index].Variable = newValue;

                this.setWarningInput(inputRef, tooltip);
              }
              else {
                inputRef.value = this.styleModels[index].Variable;

                this.unsetWarningInput(inputRef, tooltip);
              }
            });
          }
          else {
            this.styleModels[index].Variable = newValue;
          }
        }
      }
    }
  }

  public addStyleVariable(): void {
    let newStyleVariable: StyleModel = {
      Variable: ' ',
      Value: null
    }
    this.styleModels.push(newStyleVariable);
  }

  public removeStyleVariable(index: number): void {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.styleModels.splice(index, 1);
      }
    });
  }

  public checkSidebarResource(resourceUrl: string, checked: boolean): void {
    if (checked && this.sidebarAllowedResources[resourceUrl] === undefined) {
      this.sidebarAllowedResources[resourceUrl] = true;
    }
    else if (!checked && this.sidebarAllowedResources[resourceUrl] !== undefined) {
      delete this.sidebarAllowedResources[resourceUrl];
    }
  }

  private setWarningInput(inputRef: HTMLInputElement, tooltip: MatTooltip) {
    if (!inputRef.classList.contains('inputWarning')) {
      inputRef.classList.add('inputWarning');
    }
    tooltip.disabled = false;
  }

  private unsetWarningInput(inputRef: HTMLInputElement, tooltip: MatTooltip) {
    if (inputRef.classList.contains('inputWarning')) {
      inputRef.classList.remove('inputWarning');
    }
    tooltip.disabled = true;
  }

  addition() {
    if(this.themeCase || this.sidebarCase) {
      this.updatePropertyValue();
    }

    if (this.form.valid) {
      this.dialogRef.close({ key: this.form.controls.key.value, value: this.form.controls.value.value })
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
