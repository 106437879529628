import { takeUntil } from 'rxjs/operators';
import { Component, Input, SimpleChanges, OnChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ServiceEventItem } from '../../models/service/serviceEventResponse';
import { WeatherValues } from '../../models/weatherstationHeatmapElement';
import { WeatherStationApiService } from '../../services/weatherstation-api.service';
import { Subject } from 'rxjs';
import { WeatherStationLimit } from '../../models/weatherStationLimit';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-weather-widget',
  templateUrl: './weather-widget.component.html',
  styleUrls: ['./weather-widget.component.scss']
})
export class WeatherWidgetComponent implements OnChanges, OnDestroy {
  @Input('weatherEvents') public weatherEvents: ServiceEventItem[];
  @ViewChild('arrowIcon') arrowIcon: ElementRef<HTMLElement>;

  public averageWeatherValues: WeatherValues;
  public weatherLimits: WeatherStationLimit[] = [];
  public dataLoading = true;
  public dataReady: boolean = false;
  public limitsReady: boolean = false;
  public airQuality: string = '';
  public arrowDirection: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private weatherApi: WeatherStationApiService, 
    private mainService: MainSubscriptionsService,
    private router: Router) 
  { }

  private calculateAverageStats(weatherEvents: ServiceEventItem[]): WeatherValues {
    let values: WeatherValues[] = [];

    weatherEvents.forEach((device) => {
      device.Events.forEach((event) => {
        values.push(event.Body);
      });
    });

    const totals = values.reduce((acc, current) => {
      Object.keys(current).forEach(key => {
        if (acc[key]) {
          acc[key] += current[key];
        } else {
          acc[key] = current[key];
        }
      });
      return acc;
    }, {} as WeatherValues);

    const averages = {} as WeatherValues;

    Object.keys(totals).forEach(key => {
      averages[key] = Math.round(totals[key] / values.length);
    });

    return averages;
  }

  public findInfo(key: string): WeatherStationLimit {
    let info = {} as WeatherStationLimit;
    info = this.weatherLimits.find(info => info.Key === key);

    return info
  }

  public calculateAirQuality(): void {
    let totalAirQualityValue = 0;
    let totalAirQualityLimit = 0;

    const relevantValues = {
      CO2: this.averageWeatherValues.CO2,
      NO2: this.averageWeatherValues.NO2,
      PM10: this.averageWeatherValues.PM10,
      PM25: this.averageWeatherValues.PM25,
      TVOC: this.averageWeatherValues.TVOC
    };

    Object.keys(relevantValues).forEach((key) => {
      if (!isNaN(relevantValues[key])) {
        totalAirQualityValue += relevantValues[key];
      }
    });
    
    Object.keys(relevantValues).forEach((key) => {
      this.weatherLimits.forEach((limit) => {
        let foundRelevantKey = limit.Key === key;

        if (foundRelevantKey && !isNaN(+limit.Value)) {
          totalAirQualityLimit += +limit.Value;
        }
      });
    });


    let ratio = totalAirQualityValue / totalAirQualityLimit;

    if (ratio < 0.5) {
      this.airQuality = "GOOD";
    } else if (ratio < 0.75) {
      this.airQuality = "MEDIUM";
    } else {
      this.airQuality = "BAD";
    }
  }

  private getArrowClass(): string { 
    let direction = this.averageWeatherValues?.WindDirection;
  
    switch (true) {
      case (direction >=0 && direction < 40):
        return 'north';
      case (direction >= 40 && direction < 90):     
        return 'north-east';
      case (direction>= 90 && direction < 130):
        return 'east';     
      case (direction >= 130 && direction < 180):
        return 'south-east';            
      case (direction >= 180 && direction < 230):     
        return 'south';      
      case (direction >= 230 && direction < 270):              
        return 'south-west';               
      case (direction >= 270 && direction < 320):               
        return 'west';                                  
      case (direction >= 320 && direction < 360):
        return 'north-west';       
      default:      
        return '';
    }
  }  

  ngOnChanges(changes: SimpleChanges) {
    if (changes['weatherEvents']) {
      const weatherEvents = changes['weatherEvents'];
      if (weatherEvents.currentValue !== weatherEvents.previousValue && weatherEvents.firstChange == false) {
        if (this.weatherEvents?.length > 0) {
          this.dataLoading = true;

          this.weatherApi.weatherStationLimits().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (res: WeatherStationLimit[]) => {
              if (res) {
                this.weatherLimits = res;

                this.averageWeatherValues = this.calculateAverageStats(this.weatherEvents);
                this.arrowDirection = this.getArrowClass();
                this.calculateAirQuality();

                this.limitsReady = true;
                this.dataReady = true;
                this.dataLoading = false;
              }
            }, error: () => { }
          });
        }
      } else {
        this.dataLoading = false;
      }
    }
  }

  public goToWeatherStationDashboard(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'dashboard' });
    this.router.navigate(['main/heatmap']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
