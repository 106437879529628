import { OnDestroy } from '@angular/core';
import { PassDataService } from './../../services/pass-data/pass-data.service';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input('localized') public localized: boolean = false;
  @Input('onContent') public onContent: boolean = false;
  @Input('disappearCondition') public disappear: boolean = false;

  public isDarkActive: boolean = true;
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private passDataService: PassDataService
  ) { }

  ngOnInit(): void {
    if(this.localized) {
      this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.isDarkActive = res === true;
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
