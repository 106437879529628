import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceRequest } from '../../models/administrator/resourceRequest';

@Component({
  selector: 'urban-resource-dialog',
  templateUrl: './resource-dialog.component.html',
  styleUrls: ['./resource-dialog.component.scss']
})
export class ResourceDialogComponent implements OnInit {

  public error: string | null;
  private resource: ResourceRequest;
  public isAdd: boolean = true;
  private resourceId: string;
  public parentResources: ResourceRequest[];

  public form: UntypedFormGroup = this.formBuilder.group({
    url: [{ value: '', disabled: false }, Validators.required],
    label: [{ value: '', disabled: false }],
    title: [{ value: '', disabled: false }],
    image: [{ value: '', disabled: false }],
    type: [{ value: '', disabled: false }],
    isPublic: [''],
    requireToken: [''],
    parentSelected: [''],
    order: [0]
  });

  constructor(
    public dialogRef: MatDialogRef<ResourceDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        resource: ResourceRequest,
        resources: ResourceRequest[]
      }
  ) {
    if (this.data && this.data !== undefined && this.data.resource && this.data.resource !== undefined) {
      this.resource = this.data.resource;
      this.isAdd = false;
      this.resourceId = this.data.resource.Id;
    }

    this.parentResources = this.data.resources;
  }

  ngOnInit(): void {
    if (!this.isAdd) {
      this.form.controls.url.setValue(this.resource.Url);
      this.form.controls.label.setValue(this.resource.Label);
      this.form.controls.title.setValue(this.resource.Title);
      this.form.controls.image.setValue(this.resource.Image);
      this.form.controls.type.setValue(this.resource.Type);
      this.form.controls.isPublic.setValue((this.resource.IsPublic ? "1" : "0"));
      this.form.controls.requireToken.setValue((this.resource.RequireToken ? "1" : "0"));
      this.form.controls.order.setValue(this.resource.Order);
      this.form.controls.parentSelected.setValue(this.resource.ParentId);
    }
  }

  addition() {
    if (this.form.valid) {
      this.resource = {
        Id: this.resourceId,
        Url: this.form.controls.url.value,
        Label: this.form.controls.label.value,
        Title: this.form.controls.title.value,
        Image: this.form.controls.image.value,
        Type: this.form.controls.type.value,
        IsPublic: +this.form.controls.isPublic.value == 1,
        RequireToken: +this.form.controls.requireToken.value == 1,
        Order: this.form.controls.order.value,
        ParentId: this.form.controls.parentSelected.value
      }

      this.dialogRef.close(this.resource);
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
