import {AbstractControl} from '@angular/forms';
export class PasswordValidator {

    static MatchPassword(AC: AbstractControl) {
       let newPassword = AC.get('newPassword').value; // to get value in input tag
       let confirmedNewPassword = AC.get('confirmedNewPassword').value; // to get value in input tag
        if(newPassword != confirmedNewPassword) {
            AC.get('confirmedNewPassword').setErrors( {MatchPassword: true} )
        } else {
            return null
        }
    }
}