<mat-toolbar class="toolbarContent">
    <mat-toolbar-row class="p-0">
        <button id="intro-header-toggler" mat-mini-fab class="my-fab" (click)="toggleSidebarButton()">
            <img [src]="this.myDomainLogo ? this.myDomainLogo : logoButtonToShow"/>
        </button>

        <span class="ml-3 logo-text pointer" (click)="goToMain()" *ngIf="sidebarIsOpen">
            <span class="d-none d-sm-block webAppName">
                <span *ngIf="this.myTitle" style="font-weight: 700;">{{this.myTitle}}</span>
                <span *ngIf="!this.myTitle" style="font-weight: 700;">Urban</span><span *ngIf="!myTitle">vision</span>
            </span>
        </span>

        <button type="button" class="backButton d-flex align-items-center" (click)="back()"
            [ngClass]="{'backButtonSidebarClosed' : sidebarIsOpen === false}" [disabled]="!(navigationInfo?.BackRoute)">
            <i class="noSelection urbanicon-back"></i>
            <span class="ml-1 backButtonLabel">{{'GENERAL.BACK' | translate}}</span>
        </button>

        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" class="headerActions">
            <ul class="mb-0 d-inline-flex">
                <li id="intro-header-dashboard">
                    <button type="button" mat-button class="my-custom-button" (click)="toggleUserBar('dashboard')">
                        <i class="urbanicon-dashboard-outline"></i>
                    </button>
                </li>
                <li id="intro-header-settings">
                    <button type="button" mat-button class="my-custom-button" (click)="toggleUserBar('settings')">
                        <i class="urbanicon-settings-outline"></i>
                    </button>
                </li>
                <li id="intro-header-profile">
                    <button type="button" mat-button class="topProfileButton pb-0 px-0 my-custom-button" (click)="toggleUserBar('profile')">
                        <img id="topProfile" *ngIf="myProfileImage" src="{{ myProfileImage }}" class="img-fluid" alt="Profile Image">
                        <i *ngIf="!myProfileImage" [ngClass]="(selectedMenu === 'profile') ? 'urbanicon-profile' : 'urbanicon-profile-outline'"></i>
                    </button>
                </li>
            </ul>
        </div>

    </mat-toolbar-row>
</mat-toolbar>
