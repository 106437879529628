<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">
        <mat-card class="d-flex flex-column p-0 m-4">
            <div class="appSupportExtraSection d-flex flex-column p-4">
                <h6>{{'APP_SUPPORT.TITLE' | translate}}</h6>
<!--                 <div class="custom-button">
                    <button mat-button class="mat-primary">SCOPRICI</button>
                </div> -->
                <br>
            </div>
            <div class="d-flex flex-column appSupportMainSection p-4 text-white">
                <mat-card-title style="font-size: larger;">{{ 'APP_SUPPORT.CONTACT' | translate}}</mat-card-title>
                <mat-card-content>
                    <div id="scrollable">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                            molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                            numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                            optio, eaque rerum! Provident similique accusantium nemo autem.Veritatis
                            obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                            nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
                            tenetur error, harum nesciunt ipsum debitis quas aliquid.
                        </p>
                    </div>
                </mat-card-content>
            </div>
        </mat-card>
            <br>
            <div id="logoEnvision">
                <a href="http://envisioncompany.it/" target="_blank">
                    <img [src]="'/assets/img/brand/envision-logo.png'" />
                </a>
            </div>

    </div>

</div>
