import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from '../../../shared/mat/mat.module';
import { RouterModule } from '@angular/router';
import { ResellersComponent } from '../../../pages/main/wifi/resellers/resellers.component';
import { WifiRoutingModule } from './wifi-routing.module';
import { ManagersComponent } from '../../../pages/main/wifi/managers/managers.component';
import { SharedModule } from '../../../shared/shared.module';
import { DomainsComponent } from '../../../pages/main/wifi/domains/domains.component';
import { GatewaysComponent } from '../../../pages/main/wifi/gateways/gateways.component';
import { UsersComponent } from '../../../pages/main/wifi/users/users.component';
import { WifiComponent } from '../../../pages/main/wifi/wifi.component';



@NgModule({
    declarations: [
        WifiComponent,
        ResellersComponent, 
        ManagersComponent,
        DomainsComponent,
        GatewaysComponent,
        UsersComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        WifiRoutingModule,
        MatModule,
        SharedModule
    ]
})
export class WifiModule { }
