import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThemeRequest} from "../../../../shared/models/administrator/themeRequest";
import {Configuration} from "../../../../shared/models/configuration";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {AdministratorApiService} from "../../../../shared/services/administrator-api.service";
import {Router} from "@angular/router";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {UserRoles} from "../../../../shared/models/userRoles";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'urban-theme-detail',
  templateUrl: './theme-detail.component.html',
  styleUrls: ['./theme-detail.component.scss']
})
export class ThemeDetailComponent implements OnInit, OnDestroy {

  private theme: ThemeRequest;

  public isAdd = true;

  private appConfig: Configuration;

  public logoBaseData: string;

  public splashScreenLogoBaseData: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    hostname: [{value: '', disabled: false}, Validators.required],
    scssVariables: [{value: '', disabled: false}, Validators.required],
    translations: [{value: '[]', disabled: false}, Validators.required]
  });

  public error: string | null;
  public logoError: string = null;
  public splashLogoError: string = null;
  public userRoles: UserRoles['Roles'];
  private themeId: number;

  constructor(
    private apiService: AdministratorApiService,
    private router: Router,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          this.themeId = +navInfo.Id;
          this.isAdd = false;
        }

        this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
          this.appConfig = config;
        })

        if (!this.isAdd) {
          this._initialize();
        }
      });
    });
  }

  private _initialize(): void {
    this.apiService.themeDetail(this.themeId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeResult => {
      if (themeResult) {
        this.theme = themeResult;
        this.form.controls.hostname.setValue(this.theme.Hostname);
        this.form.controls.scssVariables.setValue(this.theme.ScssVariables);
        this.form.controls.translations.setValue(this.theme.Translations);
        this.logoBaseData = this.theme.Logo;
        this.splashScreenLogoBaseData = this.theme.SplashScreenLogo;
      }
    });
  }

  public addition(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {

          this.theme = {
            Id: this.theme?.Id && this.theme?.Id > 0 ? this.theme?.Id : 0,
            Hostname: this.form.controls.hostname.value,
            ScssVariables: this.form.controls.scssVariables.value,
            Logo: this.logoBaseData,
            SplashScreenLogo: this.splashScreenLogoBaseData,
            Translations: this.form.controls.translations.value
          };

          if (this.theme?.Id) {
            this.apiService.themeUpdate(this.theme).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                this._initialize();
              }
            });
          } else {
            this.apiService.themeAdd(this.theme).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                this._initialize();
              }
            });
          }
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  public logoImageUpload(event: any): void {
    if (event.target.files[0].size > +this.appConfig.Settings.max_profile_image_size) {
      this.logoError = 'IMAGE_SIZE_MAX';
    } else if (event.target.files[0].size < +this.appConfig.Settings.min_profile_image_size) {
      this.logoError = 'IMAGE_SIZE_MIN';
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (myTarget) => {
        let image = new Image();
        image.src = myTarget.target.result.toString();
        image.onload = () => {
          if (Math.abs(image.width - image.height) < +this.appConfig.Settings.max_profile_image_delta) {
            this.logoBaseData = reader.result.toString();
            this.logoError = null;
          } else {
            this.logoError = 'IMAGE_RESOLUTION';
          }
        }
      };
      reader.onerror = function (error) {
        console.log("Error FileReader: ", error);
      };
    }
  }

  public splashScreenLogoImageUpload(event: any): void {
    if (event.target.files[0].size > +this.appConfig.Settings.max_splash_logo_image_size) {
      this.splashLogoError = 'IMAGE_SIZE_MAX';
    } else if (event.target.files[0].size < +this.appConfig.Settings.min_splash_logo_image_size) {
      this.splashLogoError = 'IMAGE_SIZE_MIN';
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (myTarget) => {
        let image = new Image();
        image.src = myTarget.target.result.toString();
        image.onload = () => {
          this.splashScreenLogoBaseData = reader.result.toString();
          this.splashLogoError = null;
        }
      };
      reader.onerror = function (error) {
        console.log("Error FileReader: ", error);
      };
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
