import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdvertisingDialogComponent } from 'src/app/shared/dialogs/advertising-dialog/advertising-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Advertising, AdvertisingType } from 'src/app/shared/models/advertising/advertising';
import { AddAdvertisingEventRequest, AddAdvertisingRequest, GetAdvertisingsRequest, UpdateAdvertisingRequest } from 'src/app/shared/models/advertising/advertisingRequest';
import { AdvertisingEventsCountResponse, AdvertisingEventsCountResponseItem, AdvertisingsEventsCountsResponse, GetAdvertisingTypesResponse, GetAdvertisingsResponse } from 'src/app/shared/models/advertising/advertisingResponse';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { GetServiceResponse, Service } from 'src/app/shared/models/service/service';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-advertising-list',
  templateUrl: './advertising-list.component.html',
  styleUrls: ['./advertising-list.component.scss']
})
export class AdvertisingListComponent implements OnInit, OnDestroy {
  @ViewChild('filterInput') private filterInput: MatInput;

  public isAdmin: boolean = false;
  public columns = ['Name', 'Type', 'Dates', 'Time range', 'Created', 'Service', 'Count', 'Actions'];
  public advertisings: Advertising[] = [];
  public services: Service[] = [];
  public advertisingTypes: AdvertisingType[] = [];
  private countsByAdvertising: Record<string, number> = {};
  public startDate: number;
  public endDate: number;
  public actualFilter: string = '';
  public lastFilter: string;
  public filterForm: UntypedFormGroup = this.formBuilder.group({
    service: ''
  });
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isAdmin = res.some(x => x.Name === 'Administrators');

      if (!this.isAdmin && !res.some(x => x.Name === 'Domain admin')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.loadData();
      this.loadDialogData();
    });

    this.filterForm.valueChanges.subscribe((newFilterValues: Record<string,string>) => {
      if (Object.values(newFilterValues).some(value => value !== null)) {
        this.config.currentPage = 1;
        this.newSearch();
      }
    });
  }

  private loadData(): void {
    let advContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, advContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues.every(value => value)) {
        this.updatedAdvViewsCounts();
      }

      this.loadingData = false;
    }, advContext);

    this.apiService.getAdvertisings().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetAdvertisingsResponse) => {
      if (res?.Items) {
        this.advertisings = res.Items;
        this.apiSync.loadedFeature(advContext);
        this.config.totalItems = res.Total;
      }
      else {
        this.apiSync.failedFeature(advContext);
      }
    });

    this.apiService.getAdvertisingsEventsCounts().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: AdvertisingsEventsCountsResponse) => {
      if (res?.Items?.length > 0) {
        res.Items.forEach((item: AdvertisingEventsCountResponseItem) => {
          this.countsByAdvertising[item.AdvertisingId] = item.Count;
        });
        this.apiSync.loadedFeature(advContext);
      }
      else {
        this.apiSync.failedFeature(advContext);
      }
    });
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.filterForm.reset();
      this.applyFilter('');
      this.config.currentPage = 1;
    }

    let getAdvertisingsRequest: GetAdvertisingsRequest = {
      Page: this.config.currentPage - 1,
      Limit: this.config.itemsPerPage,
      Filter: this.actualFilter,
      Start: this.startDate,
      End: this.endDate,
      Service: this.filterForm.controls.service.value
    }

    this.apiService.getAdvertisings(getAdvertisingsRequest)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetAdvertisingsResponse) => {
        if (res?.Items) {
          this.advertisings = res.Items;
          this.config.totalItems = res.Total;
          this.updatedAdvViewsCounts();

          this.loadingData = false;
        }
        else {
          this.advertisings = [];
          this.config.itemsPerPage = 10;
          this.config.currentPage = 1;
          this.config.totalItems = 0;
        }
    });
  }

  private loadDialogData(): void {
    this.apiService.getServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceResponse) => {
      if (res?.Items) {
        this.services = res.Items.filter((service: Service) => service.ServiceType.Name === 'advertising');
      }
    });

    this.apiService.getAdvertisingTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetAdvertisingTypesResponse) => {
      if (res?.Items) {
        this.advertisingTypes = res.Items;
      }
    });
  }

  public addAdvertising(): void {
    const addAdvDialogRef = this.dialog.open(AdvertisingDialogComponent, {
      data: {
        types: this.advertisingTypes,
        services: this.services
      }
    });

    addAdvDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newAdvertising: AddAdvertisingRequest) => {
      if (newAdvertising) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.addAdvertising(newAdvertising).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.newSearch();
              },
              error: () => {}
            });
          }
        });
      }
    });
  }

  public updateAdvertising(advertising: Advertising): void {
    const updateAdvDialogRef = this.dialog.open(AdvertisingDialogComponent, {
      data: {
        advertising,
        types: this.advertisingTypes,
        services: this.services
      }
    });

    updateAdvDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newAdvertising: AddAdvertisingRequest) => {
      if (newAdvertising) {
        const updateAdvConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        updateAdvConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let updateAdvertisingRequest: UpdateAdvertisingRequest = {
              ...newAdvertising,
              Id: advertising.Id
            };

            this.apiService.updateAdvertising(updateAdvertisingRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.newSearch();
              },
              error: () => {}
            });
          }
        });
      }
    });
  }

  public deleteAdvertising(advertisingId: string): void {
    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.deleteAdvertising(advertisingId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.newSearch();
          },
          error: () => {}
        })
      }
    });
  }

  public addAdvertisingEvent(advertisingId: string): void {
    const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        let addAdvertisingEventRequest: AddAdvertisingEventRequest = {
          Advertising: advertisingId
        };

        this.apiService.addAdvertisingEvent(addAdvertisingEventRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.apiService.getAdvertisingEventsCount(advertisingId).pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((res: AdvertisingEventsCountResponse) => {
                if (res) {
                  this.countsByAdvertising[res.AdvertisingId] = res.Count;
                  let advViewed: Advertising = this.advertisings.find(adv => adv.Id === res.AdvertisingId);
                  if (advViewed !== undefined) {
                    advViewed.ViewsCount = this.countsByAdvertising[advViewed.Id];
                  }
                }
              });
          },
          error: () => {}
        })
      }
    });
  }

  private updatedAdvViewsCounts(): void {
    this.advertisings = this.advertisings.map(adv => {
      return {
        ...adv,
        ViewsCount: this.countsByAdvertising[adv.Id] ? this.countsByAdvertising[adv.Id] : 0
      };
    });
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.actualFilter = filterValue;

    if (filterValue === '' && this.filterInput?.value && this.filterInput.value.trim() !== '') {
      this.filterInput.value = '';
    }
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
    this.newSearch();
  }

  public paginatorOnShownChange(): void {
    this.config.currentPage = 1;
    this.newSearch();
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.config.totalItems / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }


  public goToAdvertisingEvents(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'advertising-list' });
    this.router.navigate(['main/advertising-event-list']);
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
