import { Router } from '@angular/router';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { Device } from 'src/app/shared/models/device';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { takeUntil, first } from 'rxjs/operators';
import { DeviceEvent } from './../../../shared/models/deviceEvent';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { BacnetEvents } from 'src/app/shared/models/bacnet/bacnetEvents';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { PaginationInstance } from 'ngx-pagination';
import { BacnetEventsRequest } from 'src/app/shared/models/bacnet/bacnetEventsRequest';
import { BacnetEvent } from 'src/app/shared/models/bacnet/bacnetEvent';
import { ChartMultiDataList } from 'src/app/shared/models/ChartDataList';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'urban-bacnet-detail',
  templateUrl: './bacnet-detail.component.html',
  styleUrls: ['./bacnet-detail.component.scss']
})
export class BacnetDetailComponent implements OnInit {
  public bacnetEvents: BacnetEvent[];
  public filteredData: BacnetEvent[];
  public propertiesList: Record<string, string[]> = {};
  public lineChartData: Record<string, ChartMultiDataList> = {};
  public startDate: number;
  public endDate: number;
  public lastCreated: number;
  public loadingData: boolean;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public last24hSearch: boolean = true;
  public currentDevice: Device;
  public passedBacnetDevice: string;
  public displayedColumns: string[] = ['Device', 'Created', 'Name', 'Property', 'Value'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private apiSync: ApiSynchronizerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if(navInfo?.Id && navInfo?.Type) {
        deviceId = navInfo.Id;
        this.passedBacnetDevice = navInfo.Type;
      } else {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadData(deviceId, this.passedBacnetDevice);
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  public loadData(deviceId: string, bacnetDevice: string): void {
    let deviceFeature: number, eventsFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        if (checkValues[eventsFeature]) {
          this.bacnetEvents = this.formattedBacnetEvents(data[eventsFeature].Events, bacnetDevice);
          this.filteredData = this.bacnetEvents;
        }
      }
      this.loadingData = false;
    }, context);

    let now = Math.round(Date.now() / 1000);
    let yesterday = now - (24 * 3600);

    let firstRequest: BacnetEventsRequest = {
      DeviceId: deviceId,
      Start: now,
      End: yesterday,
      Minutes: 1
    }

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        deviceFeature = this.apiSync.loadedFeature(context);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.apiService.bacnetEventsList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.resetAllData();

      if (res?.Events && res.Events.length > 0) {
        eventsFeature = this.apiSync.loadedFeatureWithData(res, context);
      } else {
        eventsFeature = this.apiSync.failedFeature(context);
      }
    });
  }

  public loadLatestData(): void {
    this.loadingData = true;
    this.clearDate = !this.clearDate;

    let latestEventsRequest: DeviceEventLatestRequest = { DeviceId: this.currentDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(
      latestEventsRequest,
      (res) => this.isAProperEvent(res, this.passedBacnetDevice)
    ).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
      .subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if(res && res.Devices?.length > 0) {
        this.setLatestDates(res);

        this.bacnetEvents = this.mapToBacnetLatestEvents(res, this.passedBacnetDevice);
        this.filteredData = this.bacnetEvents;
        this.lineChartData = this.setChartData(this.bacnetEvents);
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
    }

    let serverRequest: BacnetEventsRequest = {
      DeviceId: this.currentDevice.Id,
      Start: this.startDate,
      End: this.endDate,
      Minutes: 1
    }

    this.apiService.bacnetEventsList(serverRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.resetAllData();

      if (res) {
        this.bacnetEvents = this.formattedBacnetEvents(res.Events, this.passedBacnetDevice);
        this.filteredData = this.bacnetEvents;
        this.lineChartData = this.setChartData(this.bacnetEvents);
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  private resetAllData(): void {
    this.bacnetEvents = [];
    this.filteredData = [];
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event, this.passedBacnetDevice));
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find((event: EventLatest) =>
          event.Level === 'Info' && this.isAProperEvent(event, this.passedBacnetDevice));
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private formattedBacnetEvents(events: BacnetEvents[], passedBacnetDevice: string): BacnetEvent[] {
    let bacnetEvent: BacnetEvent[] = [];

    events.forEach((event) => {
      if (event.Device === passedBacnetDevice) {
        event.Values.forEach((value) => {
          bacnetEvent.push({
            Device: event.Device,
            Name: value.Name,
            Property: value.Property,
            Value: value.Value,
            Created: value.Created
          });
        });
      }
    });

    return bacnetEvent;
  }

  private isAProperEvent(event: EventLatest, passedBacnetDevice: string): boolean {
    let eventBody = event.Body;
    return eventBody &&
    ['Device', 'Values'].every((key: string) => Object.keys(eventBody).includes(key)) &&
    eventBody.Device === passedBacnetDevice &&
    Array.isArray(eventBody.Values) && eventBody.Values.length > 0 &&
    eventBody.Values.every(value => ['Name', 'Property', 'Value']
      .every((key: string) => Object.keys(value).includes(key)) &&
    typeof value.Value === 'number')
  }

  private mapToBacnetLatestEvents(res: DeviceEventLatestResponse, passedBacnetDevice: string): BacnetEvent[] {
    let formattedEvents: BacnetEvent[] = [];

    res.Devices[0].Events.forEach((event: EventLatest) => {
      let eventBody = event.Body;

      if (eventBody &&
        ['Device', 'Values'].every((key: string) => Object.keys(eventBody).includes(key)) &&
        eventBody.Device === passedBacnetDevice &&
        Array.isArray(eventBody.Values) && eventBody.Values.length > 0 &&
        eventBody.Values.every(value => ['Name', 'Property', 'Value']
          .every((key: string) => Object.keys(value).includes(key)) &&
        typeof value.Value === 'number')
      ) {
        eventBody.Values.forEach((value: any) => {
          formattedEvents.push({
            Device: eventBody.Device,
            Created: event.CreatedTimestamp,
            Name: value.Name,
            Property: value.Property,
            Value: value.Value,
          });
        });
      }
    });

    return formattedEvents
  }

  public setChartData(events: BacnetEvent[]): Record<string, ChartMultiDataList> {
    let lineChartData: Record<string, ChartMultiDataList> = {};

    events.filter((event) => !Number.isNaN(parseInt(event.Value))).forEach((event: BacnetEvent) => {
      if (this.propertiesList[event.Name] === undefined) {
        this.propertiesList[event.Name] = [];
      }

      if (!this.propertiesList[event.Name].includes(event.Property)) {
        this.propertiesList[event.Name].push(event.Property);
      }

      if (lineChartData[event.Name] === undefined) {
        lineChartData[event.Name] = {}
      }

      if (lineChartData[event.Name][event.Created] === undefined) {
        lineChartData[event.Name][event.Created] = {}
      }

      lineChartData[event.Name][event.Created][event.Property] = +event.Value;
    });

    return lineChartData
  };

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.bacnetEvents.filter((event: BacnetEvent) =>
      [
        event.Device.toLowerCase(),
        event.Name.toLowerCase(),
        event.Property.toLowerCase(),
        event.Value.toLowerCase(),
        event.Created ? new Date(event.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : ''
      ].some((x) => x?.includes(filterValue.toLowerCase()))
    );

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public goToDeviceEvents(): void {
    if (this.currentDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'bacnet-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
