import {Component, Inject, OnInit} from '@angular/core';
import {BroadcastClientResponse} from "../../models/content-management/broadcastClientResponse";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ChannelResponse} from "../../models/content-management/channelResponse";
import {ChannelRequest} from "../../models/content-management/channelRequest";
import {Language} from "../../models/language";

@Component({
  selector: 'urban-channel-dialog',
  templateUrl: './channel-dialog.component.html',
  styleUrls: ['./channel-dialog.component.scss']
})
export class ChannelDialogComponent implements OnInit {

  private channel: ChannelResponse;

  private channelRequest: ChannelRequest;

  public isAdd: boolean = true;

  public broadcastClients: BroadcastClientResponse[] = [];

  public languages: Language[] = [];

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    description: [{value: '', disabled: false}, Validators.required],
    language: [{value: 0, disabled: false}, Validators.required],
    broadcastClient: [{value: '', disabled: false}, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<ChannelDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        broadcastClients: BroadcastClientResponse[],
        languages: Language[],
        channel: ChannelResponse
      }
  ) {
  }

  ngOnInit(): void {
    this.broadcastClients = this.data?.broadcastClients;
    this.languages = this.data?.languages;

    if (this.data && this.data.channel) {
      this.channel = this.data.channel;
      this.isAdd = false;
      this.form.controls.name.setValue(this.channel.Name);
      this.form.controls.description.setValue(this.channel.Description);
      this.form.controls.language.setValue(this.channel.Language.Id.toString());
      this.form.controls.broadcastClient.setValue(this.channel.BroadcastClientId);
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.channelRequest = {
        id: this.channel?.Id,
        broadcastClient: this.form.controls.broadcastClient.value,
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
        language: +this.form.controls.language.value
      };

      this.dialogRef.close(this.channelRequest);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
