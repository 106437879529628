import { HeatmapElement } from './../../models/ChartDataList';
import { timer } from 'rxjs';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Router } from '@angular/router';

@Component({
  selector: 'urban-google-heatmap',
  templateUrl: './google-heatmap.component.html',
  styleUrls: ['./google-heatmap.component.scss']
})
export class GoogleHeatmapComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap;
  public heatmap: google.maps.visualization.HeatmapLayer;
  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public noDataOnChart: boolean;
  public mapZoom: number;
  public gradient: string[] = [
    "rgba(0, 255, 255, 0)",
    "#69D27D",
    "#E7CD77",
    "#EB565D"
  ]
  @Input('heatmapElements') public passedHeatmapElements: HeatmapElement[] = [];
  @Input('maxValuesHeatmap') public heatmapMaxValues: number;
  @Input('passedLastUpdate') public passedLastUpdate: number;
  @Input('heatmapTitle') public title: string = "";
  @Input('darkModeStatus') public isDarkActive: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.mapOptions = {
      mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
      gestureHandling: "cooperative",
      mapTypeId: "roadmap"
    } as google.maps.MapOptions
  }

  ngAfterViewInit(): void {
    if (this.passedHeatmapElements.length > 0) {
      this.initMap();
    } else {
      this.noDataOnChart = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.noDataOnChart = false;
    timer(0).subscribe(() => {
      if (changes.passedHeatmapElements && changes.passedHeatmapElements.currentValue !== changes.passedHeatmapElements.previousValue && changes.passedHeatmapElements.firstChange === false) {
        if (this.heatmap) {
          this.heatmap.setMap(null)
        }
        if (this.passedHeatmapElements.length > 0) {
          this.setHeatMap();
        } else {
          this.noDataOnChart = true;
        }
      }
    });

    if (changes.isDarkActive && changes.isDarkActive.currentValue !== changes.isDarkActive.previousValue && !changes.isDarkActive.firstChange) {
      this.mapZoom = this.map.getZoom();
      
      this.mapOptions = {
        mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
        gestureHandling: "cooperative",
        mapTypeId: "roadmap",
        zoom: this.mapZoom
      } as google.maps.MapOptions,

      this.mapBounds = this.map.getBounds();
      this.reInitMap();
    }
  }

  public initMap(): void {
    this.setHeatMap();

    this.setBounds();
    timer(0).subscribe(() => {
      this.map.fitBounds(this.mapBounds);
    });
  }

  public reInitMap(): void {
    this.map.googleMap = new google.maps.Map(this.map.googleMap.getDiv(), this.mapOptions);

    this.setHeatMap();
    this.setBounds();

    timer(0).subscribe(() => {
      this.map.fitBounds(this.mapBounds, 0);
    });
  }

  public setHeatMap(): void {
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getPoints(),
      maxIntensity: this.heatmapMaxValues,
      map: this.map.googleMap,
      radius: 70,
      gradient: this.gradient
    });
  }

  private setBounds(): void {
    if (this.mapBounds === undefined) {
      this.mapBounds = new google.maps.LatLngBounds();
      if (this.map && this.mapBounds && this.passedHeatmapElements != null && this.passedHeatmapElements.length !== undefined) {
        this.passedHeatmapElements.forEach((el: HeatmapElement) => {
          this.mapBounds.extend(new google.maps.LatLng(el.Latitude, el.Longitude));
        });
        this.map.fitBounds(this.mapBounds);
      }
    }
  }

  public getPoints() {
    let pointsToShow: google.maps.visualization.WeightedLocation[] = [];

    this.passedHeatmapElements.forEach((el: HeatmapElement) => {
      pointsToShow.push({
        location: new google.maps.LatLng(el.Latitude, el.Longitude),
        weight: el.Value
      });
    });

    return pointsToShow;
  }
}
