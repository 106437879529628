import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserRoles} from "../../../../shared/models/userRoles";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {AdministratorApiService} from "../../../../shared/services/administrator-api.service";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {takeUntil} from "rxjs/operators";
import { ConfirmationDialogComponent } from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {TemplateItem} from "../../../../shared/models/administrator/templateItem";
import {TemplateDialogComponent} from "../../../../shared/dialogs/template-dialog/template-dialog.component";
import {Language} from "../../../../shared/models/language";
import {NameEntity} from "../../../../shared/models/nameEntity";
import {ApiService} from "../../../../shared/services/api.service";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit, OnDestroy {

  templatesColumns = ['Name', 'Language', 'Type', 'Format', 'Detail'];
  public templates: TemplateItem[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: TemplateItem[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public languages: Language[] = [];
  public templateTypes : NameEntity<number>[] = [];
  public templateFormats : NameEntity<number>[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private administratorService: AdministratorApiService,
    private apiService: ApiService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {}

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.administratorService.listTemplateFormat().pipe(takeUntil(this.ngUnsubscribe)).subscribe(formats => {
        if(formats){
          this.templateFormats = formats;
        }
      });

      this.administratorService.listTemplateType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(types => {
        if(types){
          this.templateTypes = types;
        }
      });

      this.apiService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
        this.languages = x.List;
      });

      this._initList();
    });
  }

  public add(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const templateDialogRef = this.dialog.open(TemplateDialogComponent, {
        data: {
          languages: this.languages,
          templateTypes: this.templateTypes,
          templateFormats: this.templateFormats
        },
        width: '80%',
        maxWidth: 1000
      });

      templateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newTemplate => {
        if (newTemplate) {
          const templateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          templateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.administratorService.addTemplate(newTemplate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            }
          });
        }
      });

    }
  }

  private _initList(): void {
    this.administratorService.listTemplate().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.templates = x;
        this.filteredData = this.templates;
      }
    });
  }

  public detail(template: TemplateItem): void {
    if (!template || template === undefined) {
      return;
    }

    const updateDialogRef = this.dialog.open(TemplateDialogComponent, {
      data: {
        templateItem: template,
        languages: this.languages,
        templateTypes: this.templateTypes,
        templateFormats: this.templateFormats
      },
      width: '80%',
      maxWidth: 1000
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorService.updateTemplate(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initList();
              }
            });
          }
        });
      }
    });
  }

  public delete(template: TemplateItem): void {
    if (!template || template === undefined) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorService.deleteTemplate(template.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  public formats(): void {
    this.mainService.setNavigationInfoComand({BackRoute: 'templates'});
    this.router.navigate(['main/template-formats']);
  }

  public types(): void {
    this.mainService.setNavigationInfoComand({BackRoute: 'templates'});
    this.router.navigate(['main/template-types']);
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.templates?.filter((template: TemplateItem) =>
      [
        template.Name?.toLowerCase(), 
        template.Type?.Name?.toLowerCase(), 
        template.Language?.Name?.toLowerCase(), 
        template.Format?.Name?.toLowerCase()
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
