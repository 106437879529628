import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAppStore from './app.reducer';
import { AppState } from './app.state';

const appSelector = createFeatureSelector<AppState>(fromAppStore.appFeatureKey);

export const getConfiguration = createSelector(appSelector, fromAppStore.selectConfiguration);
export const getTheme = createSelector(appSelector, fromAppStore.selectTheme);

