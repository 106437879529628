import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BroadcastResponse} from "../../models/content-management/broadcastResponse";
import {BroadcastRequest} from "../../models/content-management/broadcastRequest";
import {ScheduleRule} from "../../models/content-management/scheduleRule";
import {ContentResponse} from "../../models/content-management/contentResponse";
import {ChannelResponse} from "../../models/content-management/channelResponse";

@Component({
  selector: 'urban-broadcast-dialog',
  templateUrl: './broadcast-dialog.component.html',
  styleUrls: ['./broadcast-dialog.component.scss']
})
export class BroadcastDialogComponent implements OnInit {

  private broadcast : BroadcastResponse;

  private broadcastRequest : BroadcastRequest;

  public isAdd : boolean = true;

  public scheduleRules : ScheduleRule[] = [];

  public contents: ContentResponse[] = [];

  public channels: ChannelResponse[] = [];

  public form: UntypedFormGroup = this.formBuilder.group({
    start: [{ value: new Date().toString(), disabled: false }, Validators.required],
    end: [{ value: new Date().toString(), disabled: false }, Validators.required],
    channel: [{value: '', disabled: false}, Validators.required],
    content: [{value: '', disabled: false}, Validators.required],
    duration: [{value: 0, disabled: false}, Validators.required],
    priority: [{value: 0, disabled: false}, Validators.required],
    scheduleRule: [{value: 0, disabled: false}, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<BroadcastDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        broadcast: BroadcastResponse,
        scheduleRules: ScheduleRule[],
        contents: ContentResponse[],
        channels: ChannelResponse[]
      }
  ) {
  }

  public setDate(date: Date): number {
    return +date;
  }

  ngOnInit(): void {
    this.scheduleRules = this.data?.scheduleRules;
    this.contents = this.data?.contents;
    this.channels = this.data?.channels;

    if (this.data && this.data.broadcast) {
      this.broadcast = this.data.broadcast;
      this.isAdd = false;
      this.form.controls.start.setValue(new Date(this.broadcast.Start * 1000));
      this.form.controls.end.setValue(new Date(this.broadcast.End * 1000));
      this.form.controls.channel.setValue(this.broadcast.ChannelId);
      this.form.controls.content.setValue(this.broadcast.ContentId);
      this.form.controls.scheduleRule.setValue(this.broadcast.ScheduleRule);
      this.form.controls.duration.setValue(this.broadcast.Duration);
      this.form.controls.priority.setValue(this.broadcast.Priority);
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.broadcastRequest = {
        content: this.form.controls.content.value,
        channel: this.form.controls.channel.value,
        start:  +this.form.controls.start.value,
        end: +this.form.controls.end.value,
        scheduleRule: this.form.controls.scheduleRule.value,
        duration: this.form.controls.duration.value,
        priority: this.form.controls.priority.value
      };

      this.dialogRef.close(this.broadcastRequest);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
