<div class="componentContainer d-flex flex-column justify-content-start">
    <i class="urbanicon-back" (click)="closeThisRightbar()"></i>

    <div class="sidebarInfo">
        <h3 class="title3 mb-0">{{ 'DASHBOARD_MANAGEMENT.DASHBOARDS' | translate }}</h3>
        <span class="paragraphText secondaryTextColor">{{ 'DASHBOARD_MANAGEMENT.SELECT_DASHBOARD' | translate }}</span>
    </div>

    <button type="button" mat-button class="btn actionButton mb-4" (click)="createDashboard()" [disabled]="!myDevices">
        {{ 'DASHBOARD_MANAGEMENT.ADD_DASHBOARD' | translate }} <i class="urbanicon-add"></i>
    </button>

    <div *ngFor="let dashboard of myDashboardList" class="dashboardPreview panelAccentBG mb-3">
        <div class="d-flex justify-content-between align-items-center ml-1">
            <span class="captionText secondaryTextColor">{{ 'STATISTICS.CREATED' | translate }} {{dashboard?.Created * 1000 | date: 'shortDate' }}</span>

            <button mat-icon-button [matMenuTriggerFor]="dashboardActions" disableRipple>
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #dashboardActions="matMenu">
                <button mat-menu-item (click)="editDashboard(dashboard)">
                    <span class="linkText">{{ 'DASHBOARD.EDIT' | translate }}</span>
                </button>
                <button mat-menu-item (click)="deleteDashboard(dashboard.Id)">
                    <span class="linkText">{{ 'DASHBOARD.DELETE_DASHBOARD' | translate }}</span>
                </button>
            </mat-menu>
        </div>

        <div class="mb-4">
            <span class="substrongText d-block mb-2 text-break">{{dashboard.Name}}</span>
        
            <p class="paragraphboldText widgetCounter mb-0">{{dashboard.Widgets?.length}} widget</p>
        </div>

        <div class="d-flex justify-content-end">
            <button type="button" mat-button class="btn outlineActionButton" [disabled]="dashboard.Id === currentDashboardId" (click)="goToSelectedDashboard(dashboard)">Usa dashboard</button>
        </div>
    </div>
</div>