<h1 mat-dialog-title>{{ 'LOGIN.CHOOSE_DOMAIN_TITLE' | translate }}</h1>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>{{ 'LOGIN.CHOOSE_DOMAIN' | translate }}</mat-label>
        <mat-select>
            <mat-option class="user-select-none" *ngFor="let domain of data.domains" [value]="domain.Id"
                (click)="setDomain(domain)">{{domain.Name}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="button" class="btn actionButton" [disabled]="!domainChoosed"
        (click)="continue()">{{ 'LOGIN.CONTINUE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
