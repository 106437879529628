<div class="container-fluid px-1 px-md-3" [ngClass]="{'h-100' : eventsDataSource?.data.length === 0}">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span class="title2">{{'DEVICE_EVENTS.TITLE' | translate }}</span>
            <span *ngIf="currentDevice" class="title2 appearingTextRight">
                {{ ' - ' + this.currentDevice?.Name }}
            </span>
        </div>

        <button *ngIf="this.isAdmin" mat-button type="button"
            id="addEventButton" class="btn actionButton mr-2 mt-2" (click)="addEvent()">
            {{ 'DEVICE.ADD_EVENT' | translate }}
        </button>
    </div>

    <urban-searchbar id="intro-device-events-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div *ngIf="eventsDataSource?.data.length > 0" class="d-flex flex-column">

        <div class="d-flex justify-content-between align-items-start mt-5">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (focus)="setupFilter()" (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>

            <mat-paginator showFirstLastButtons [pageSize]="myPageSize" [pageSizeOptions]="[10, 20, 50, 100]"
                [pageIndex]="myPageIndex" [length]="myTotalItems" (page)="getServerData($event)">
            </mat-paginator>
        </div>

        <div class="customTable">
            <table class="w-100 h-100 noTableHover" mat-table [dataSource]="eventsDataSource">

                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{item.Id}} </td>
                </ng-container>

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.CREATED' | translate }} </th>
                    <!-- <td mat-cell *matCellDef="let item"> {{item.Created | date: "short" : "" : "it"}} </td> -->
                    <!-- <td mat-cell *matCellDef="let item"> {{item.Created | date: 'dd:MMM:yyyy hh-mm-ss z'}} </td> -->
                    <td mat-cell *matCellDef="let item" style="word-break: keep-all;"> {{item.Created*1000 | date: 'short'}} </td>
                </ng-container>

                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef> {{'DEVICE_EVENTS.TYPE' | translate }} </th>
                    <td mat-cell *matCellDef="let item">{{item.Type}}</td>
                </ng-container>


                <ng-container matColumnDef="Level">
                    <th mat-header-cell *matHeaderCellDef> {{'DEVICE_EVENTS.LEVEL' | translate }} </th>
                    <td class="eventLevel" mat-cell *matCellDef="let item" style="word-break: keep-all;">
                        <span [ngStyle]="item.Level === 'Error' && {'color':eventLevelColors[0], 'border-color':eventLevelColors[0], 'background-color':eventLevelColors[0] + '1A'} ||
                         item.Level === 'Warning' && {'color':eventLevelColors[3], 'border-color':eventLevelColors[3], 'background-color':eventLevelColors[3] + '0D'} ||
                         item.Level === 'Info' && {'color':eventLevelColors[1], 'border-color':eventLevelColors[1], 'background-color':eventLevelColors[1] + '1A'} ||
                         item.Level === 'Debug' && {'color':eventLevelColors[2], 'border-color':eventLevelColors[2], 'background-color':eventLevelColors[2] + '1A'}">
                         {{item.Level}}
                        </span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="Body">
                    <th mat-header-cell *matHeaderCellDef> {{'DEVICE_EVENTS.BODY' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.Body" [matTooltipPosition]="'above'">
                        {{item.Body?.length > 15 ? item.Body.slice(0,15) + "..." : item.Body }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1">
                        <!-- <i *ngIf="selectedEvents" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                            (click)="deleteEvents()" (contextmenu)="deselectEvents($event)"
                            class="iconDeleteButton urbanicon-delete">
                        </i> -->
                    </th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="openDetailDialog(item)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                            <i *ngIf="this.isAdmin"
                                matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="deleteEvent(item.Id)"
                                class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="eventsDataSource?.data.length === 0">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
        <div class="d-flex">
            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                {{ 'GENERAL.NEW_SEARCH' | translate }}
            </button>
            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                {{ 'GENERAL.SHOW_DATA' | translate }}
            </button>
        </div>
    </div>
</div>
