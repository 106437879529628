<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span class="title2">{{ 'HISTORY.TITLE' | translate }}</span>
            <span *ngIf="currentDevice" class="title2 appearingTextRight">
                {{ ' - ' + currentDevice.Name}}
            </span>
        </div>
    </div>

    <div>
        <mat-form-field appearance="fill">
            <mat-label>{{'HISTORY.DATE' | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" [formControl]="date"
                (dateChange)="changeDate()" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker">keyboard_arrow_down</mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="m-0 overlayTable w-100">
        <div class="justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="videos?.length === 0 ? 'd-none' : 'd-flex'">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight" *ngIf="videos?.length > 0">
            <table class="w-100 noTableHover" mat-table [dataSource]="filteredData | paginate: config">
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> {{'HISTORY.FILENAME' | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef> {{'HISTORY.DATE' | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.VideoDate}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Download">
                    <th mat-header-cell *matHeaderCellDef> {{'HISTORY.DOWNLOAD' | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <a href="{{element.DownloadUrl}}" title="{{'HISTORY.DOWNLOAD' | translate}}" download><span class="urbanicon-download"></span></a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedVideosHistoryColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedVideosHistoryColumns;"></tr>
            </table>
        </div>

        <div class="justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="videos?.length === 0 ? 'd-none' : 'd-flex'">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="videos?.length === 0">
        <img [src]="isDarkModeActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <div>
            <h2>{{ ('GENERAL.FILTER_NO_DATA') | translate }}</h2>
            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN_NO_RANGE' | translate }}</h6>
            <div class="d-flex noDataActions">
                <button type="button" mat-button class="btn outlineActionButton" (click)="returnBack()">
                    {{ 'GENERAL.RETURN_BACK' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
