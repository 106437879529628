<mat-dialog-content class="container-fluid">
    <div class="d-flex flex-row justify-content-between align-items-center mb-2">
        <h6 class="ml-2 mb-1">{{ deviceName }} </h6>
        <i (click)="back()" class="close-button mr-1 mb-1 urbanicon-close"
            matTooltip="{{ 'GENERAL.CLOSE' | translate }}">
        </i>
    </div>
    
    <div> 
        <video id="videoContent" class="video-content" [ngClass]="{'d-none' : noVideo}">

        </video>

        <div class="position-relative" *ngIf="noVideo">
            <img [src]="'./../../../../assets/img/streaming-default0.png'" alt="Preview streaming camera" class="w-100">
            <button type="button" class="btn playBtn" [disabled]="true">
                <i class="iconPlayButton urbanicon-play" matTooltip="{{ 'CAMERA.NO_VIDEO' | translate }}"></i>
            </button>
        </div>
    </div>
</mat-dialog-content>