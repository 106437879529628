import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../shared/services/api.service';
import * as AuthReducer from '../../../store/auth/auth.reducer';
import * as AuthSelectors from '../../../store/auth/auth.selectors';
import { AuthSubscriptionsService } from '../../../shared/services/auth-subscriptions/auth-subscriptions.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  public error: string = '';
  public hide: boolean = true;
  public regexPassword: string = '';
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public fb: UntypedFormBuilder,
    private authService: AuthSubscriptionsService,
    public apiService: ApiService,
    public router: Router,
    private authStore: Store<AuthReducer.AuthState>,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.authService.setErrorComand({ error: '' });
    this.form = this.fb.group({
      username: new UntypedFormControl(null, Validators.required),
      password: ['', Validators.required],
    });
    //-----------------Gestione degli errori
    this.authStore.select(AuthSelectors.error).pipe(takeUntil(this.ngUnsubscribe)).subscribe(err => {
      if (err && err != '') {
        if (err == 'customError') {
          //Nel caso in cui l'errore non fosse esposto dal Server, abbiamo fatto sì che gli errori diventino 'customError'.
          //Ora sfruttiamo il metodo get del TranslateService che ci torna come Observable, la stringa con la chiave specificata a cui fare il subscribe.
          //A questo punto il nostro error assumerà il valore del risultato del nostro subscribe.
          this.translate.get('ERROR.SOMETHING_WRONG').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.error = res;
          })
        } else {
          //caso in cui l'errore sia già esposto dal Server.
          this.error = err
        }
      }
    });
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      let password = await this.getSha256(this.form.get('password').value);
      const bodyLogin = {
        username: this.form.get('username').value,
        password: password
      }
      this.authService.requestLoginComand(bodyLogin)
    } else if (this.form.controls.password.errors?.pattern) {
      this.translate.get('CHANGE_PASSWORD_DIALOG.ERROR_SYNTAX').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.error = res;
      })
    }
  }

  deleteError(): void {
    this.error = null;
  }

  async getSha256(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);

    let hashBuffer: ArrayBuffer = await window.crypto.subtle.digest('SHA-256', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));                     
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); 
    return hashHex;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
