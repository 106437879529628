<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.DURATION' | translate }}</mat-label>
            <input type="number" min="0" step="0.1" matInput formControlName="duration" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.TAGS' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput formControlName="tags"
                      matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=20 autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'CONTENT_MANAGEMENT.BROADCAST_CLIENT' | translate }}</mat-label>
            <mat-select formControlName="broadcastClient">
                <mat-option *ngFor="let client of clients" value={{client.Id}}>
                    {{client.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.IS_PUBLIC' | translate }}</mat-label>
            <mat-select formControlName="isPublic">
                <mat-option value="false">{{ 'GENERAL.NO' | translate }}</mat-option>
                <mat-option value="true">{{ 'GENERAL.YES' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ error | translate }}
        </mat-error>
    </form>

    <div class="d-flex flex-column justify-content-between align-items-center mx-0">
        <div>
            <div class="mt-3">
                <input style="display: none;" type="file" (change)="contentUpload($event)" accept="{{this.acceptedFileExtensions}}" #contentInput>
                <button mat-button class="btn actionButton" (click)="contentInput.click()">
                    {{ 'CONTENT_MANAGEMENT.ADD_FILE' | translate }}
                </button>
            </div>
        </div>
        <mat-label *ngIf="fileName">
            {{ fileName }}
        </mat-label>
        <span class="my-2">{{ 'GENERAL.OR' | translate }}</span>
        <div [matTooltip]="'CONTENT_MANAGEMENT.FIRST_COMPILE_FIELDS' | translate" [matTooltipDisabled]="form.valid">
            <button mat-button class="btn actionButton" [disabled]="!form.valid" (click)="compileData()">
                {{ 'CONTENT_MANAGEMENT.COMPILE_DATA' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
