import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SetEntityStateRequest } from '../../models/siralab/setEntityStateRequest';

@Injectable({
  providedIn: 'root'
})
export class SiralabSubscriptionsService {
  private subjectGetDeviceReport = new Subject<string>();
  private subjectSetEntityState = new Subject<SetEntityStateRequest>();
  private subjectDeleteRemoteControlState = new Subject<boolean>();

  constructor() { }

  public getDeviceReportComand(deviceId: string): void {
    this.subjectGetDeviceReport.next(deviceId);
  }
  public getGetDeviceReportComand(): Observable<string> {
    return this.subjectGetDeviceReport.asObservable();
  }

  public deleteRemoteControlStateComand(comand: boolean): void {
    this.subjectDeleteRemoteControlState.next(comand);
  }
  public getDeleteRemoteControlStateComand():Observable<boolean>  {
    return this.subjectDeleteRemoteControlState.asObservable();
  }

  public setEntityStateComand(request: SetEntityStateRequest ): void {
    this.subjectSetEntityState.next(request);
  }
  public getSetEntityStateComand(): Observable<SetEntityStateRequest> {
    return this.subjectSetEntityState.asObservable();
  }
}
