import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DomainsComponent } from '../../../pages/main/wifi/domains/domains.component';
import { GatewaysComponent } from '../../../pages/main/wifi/gateways/gateways.component';
import { ManagersComponent } from '../../../pages/main/wifi/managers/managers.component';
import { UsersComponent } from '../../../pages/main/wifi/users/users.component';
import { ResellersComponent } from '../../../pages/main/wifi/resellers/resellers.component';
import { WifiComponent } from '../../../pages/main/wifi/wifi.component';

/* const routes: Routes = [
    { path: '', redirectTo: 'resellers', pathMatch: 'full' },
    { path: 'resellers', component: ResellersComponent },
    { path: 'managers', component: ManagersComponent },
    { path: 'domains', component: DomainsComponent },
    { path: 'gateways', component: GatewaysComponent },
    { path: 'users', component: UsersComponent },
    { path: '**', redirectTo: 'resellers' }
]; */


const routes: Routes = [
  { path: '',
    component: WifiComponent,
    children: [
      { path: '', redirectTo: 'gateways', pathMatch: 'full' },
      { path: 'resellers', component: ResellersComponent },
      { path: 'managers', component: ManagersComponent },
      { path: 'domains', component: DomainsComponent },
      { path: 'gateways', component: GatewaysComponent },
      { path: 'users', component: UsersComponent },
      { path: '**', redirectTo: 'resellers' }
    ]
  }
];
@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WifiRoutingModule { }
