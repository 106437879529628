<div class="container-fluid">
    <div class="row d-flex justify-content-start mx-0 pageTop">
        <span class="title2">
            <span *ngIf="service?.Name" class="title2 appearingTextLeft">
                {{ service.Name + ' -' }}
            </span>{{ 'GENERAL.DETAIL' | translate }}
        </span>
    </div>

    <div class="serviceInfo panelBG">
        <form [formGroup]="form">
            <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                <span class="title3 secondaryTextColor">{{ 'GENERAL.INFO' | translate }}</span>

                <div>
                    <button type="button" mat-button class="btn actionButton" *ngIf="editMode" (click)="saveInfo()" [disabled]="!dataChanged">
                        {{ 'GENERAL.SAVE' | translate }}
                    </button>

                    <button type="button" mat-button class="btn actionButton" *ngIf="!editMode" (click)="enableEdit()">
                        {{ 'GENERAL.EDIT' | translate }}
                    </button>

                    <button  type="button" mat-button class="btn outlineActionButton ml-0 cancelBtn mt-2 mt-sm-0" *ngIf="editMode" (click)="cancelEdit()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                </div>
            </div>

            <div class="d-flex infoCol">
                <div class="col-6 customCol d-flex flex-column pl-0">
                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.ID' | translate }}</mat-label>
                        <input matInput readonly class="paragraphText" [value]="service?.Id" disabled/>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.CREATED' | translate }}</mat-label>
                        <input matInput readonly class="paragraphText" [value]="service?.Created * 1000 | date: 'short'" disabled/>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.NAME' | translate }}</mat-label>
                        <input matInput formControlName="name" [readonly]="!editMode" class="paragraphText"/>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': !editMode}">
                        <mat-label class="secondaryTextColor paragraphText">{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
                        <input matInput formControlName="description" [readonly]="!editMode" class="paragraphText"/>
                    </mat-form-field>
                </div>

                <div class="col-6 customColTwo d-flex flex-column">
                    <div class="d-flex align-items-start flex-wrap">
                        <mat-form-field [ngClass]="{'noUnderline': !editMode}" class="shrinkField selectReadonly mr-4 pt-1">
                            <mat-label class="secondaryTextColor paragraphText">{{ 'SERVICE_TYPES.SERVICE_TYPES' | translate }}</mat-label>
                            <mat-select formControlName="typeId">
                                <mat-option *ngFor="let type of serviceTypes" [value]="type.Id">
                                    <span class="paragraphText">{{type.Name | titlecase }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button *ngIf="isAdmin" type="button" mat-button class="btn outlineActionButton" (click)="goToServiceTypes()">
                            {{ 'SERVICE_DETAIL.GO_TO_TYPES' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title3">{{ 'SERVICE_DETAIL.SERVICE_DEVICE_GROUPS' | translate }}</span>

        <button mat-button class="mat-raised-button actionButton" (click)="addDeviceGroupToService()" [disabled]="deviceGroups?.length === 0 || deviceGroups?.length === service.DeviceGroups?.length">
            {{ 'GENERAL.ADD' | translate }}
        </button>
    </div>

    <div class="customTable">
        <div class="customTableRadius d-flex justify-content-start align-items-start px-4 pt-lg-4">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="usernameShown">{{ element.Name }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 10%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                class="iconInfoButton urbanicon-info-outline" (click)="goToDeviceGroupDetail(element.Id)">
                            </i>
                            <i matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="removeDeviceGroupFromService(element.Id)"
                                class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
