import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceEvent } from '../../models/deviceEvent';

@Component({
  selector: 'urban-device-event-detail-dialog',
  templateUrl: './device-event-detail-dialog.component.html',
  styleUrls: ['./device-event-detail-dialog.component.scss']
})
export class DeviceEventDetailDialogComponent implements OnInit {

  myDeviceEvent: DeviceEvent = {
    Id: "",
    DeviceId: "",
    DeviceName: "",
    DeviceType: "",
    Type: "",
    Level: "",
    Created: null,
    Body: "",
    Icon: ""
  }

  public detailGroup: UntypedFormGroup = this.formBuilder.group({
    id: [{ value: '', disabled: false }],
    deviceId: [{ value: '', disabled: false }],
    deviceName: [{ value: '', disabled: false }],
    type: [{ value: '', disabled: false }],
    level: [{ value: '', disabled: false }],
    created: [{ value: '', disabled: false }],
    body: [{ value: '', disabled: false }]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<DeviceEventDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { targetEvent: DeviceEvent }
  ) {
    this.myDeviceEvent = data.targetEvent;
  }

  ngOnInit(): void {
    if (this.myDeviceEvent.Id) this.detailGroup.controls.id.setValue(this.myDeviceEvent.Id);
    if (this.myDeviceEvent.DeviceId) this.detailGroup.controls.deviceId.setValue(this.myDeviceEvent.DeviceId);
    if (this.myDeviceEvent.DeviceName) this.detailGroup.controls.deviceName.setValue(this.myDeviceEvent.DeviceName);
    if (this.myDeviceEvent.Type) this.detailGroup.controls.type.setValue(this.myDeviceEvent.Type);
    if (this.myDeviceEvent.Created) this.detailGroup.controls.created.setValue(this.datePipe.transform(this.myDeviceEvent.Created*1000, 'short'));
    if (this.myDeviceEvent.Level) this.detailGroup.controls.level.setValue(this.myDeviceEvent.Level);
    if (this.myDeviceEvent.Body) this.detailGroup.controls.body.setValue(this.myDeviceEvent.Body);
  }
}
