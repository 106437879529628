import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceBrand } from '../../models/deviceBrand';

@Component({
  selector: 'urban-device-brand-dialog',
  templateUrl: './device-brand-dialog.component.html',
  styleUrls: ['./device-brand-dialog.component.scss']
})
export class DeviceBrandDialogComponent implements OnInit {

  private deviceBrand: DeviceBrand;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<DeviceBrandDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void { }

  addition() {
    if (this.form.valid) {
      if (!this.deviceBrand || this.deviceBrand === undefined) {
        this.deviceBrand = {
          Id: "",
          Name: ""
        }
      }

      this.deviceBrand.Name = this.form.controls.name.value;
      this.dialogRef.close(this.deviceBrand)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
