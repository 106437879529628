import { PassDataService } from './../../../../shared/services/pass-data/pass-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AddDomainPropertyDialogComponent } from '../../../../shared/dialogs/add-domain-property-dialog/add-domain-property-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DomainPropertyDialogComponent } from '../../../../shared/dialogs/domain-property-dialog/domain-property-dialog.component';
import { Domain } from '../../../../shared/models/domain';
import { DomainProperty } from '../../../../shared/models/domainProperty';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { ApiService } from '../../../../shared/services/api.service';
import { AuthState } from '../../../../store/auth/auth.reducer';
import * as AuthActions from '../../../../store/auth/auth.actions';
import {MainState} from '../../../../store/main/main.reducer';
import * as MainActions from '../../../../store/main/main.actions';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DomainVisibility} from "../../../../shared/models/domainVisibility";
import {AddDomainRequest} from "../../../../shared/models/administrator/addDomainRequest";
import { PaginationInstance } from 'ngx-pagination';
import { Resource } from 'src/app/shared/models/resource';

@Component({
  selector: 'urban-domain-detail',
  templateUrl: './domain-detail.component.html',
  styleUrls: ['./domain-detail.component.scss']
})
export class DomainDetailComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public domainProperties: DomainProperty[] = [];
  private domainId: String;
  public domainLogo: string;
  public editMode: boolean = false;
  public dataChanged: boolean;
  public currentDomain: Domain;
  public domainResources: Resource[];
  public filteredData: DomainProperty[] = [];
  public displayedColumns = ['Key', 'Value', 'Detail'];
  public imageError: string = null;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public isDarkModeActive: boolean;
  public domainVisibilityItems: DomainVisibility[] = [];
  private upsertDomainRequest: AddDomainRequest;
  public parents: Domain[] = [];

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required], 
    visibilitySelected: [{value: '', disabled: true}],  
    parentSelected: [{value: '', disabled: true}]
  });

  constructor(
    private administratorApiService: AdministratorApiService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private authStore: Store<AuthState>,
    private mainStore: Store<MainState>,
    private passDataService: PassDataService,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        this.domainId = navInfo.Id;
      }

      this._init();

      this.passDataService.resources$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.domainResources = res;
      });

      this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.isDarkModeActive = res == true;
      });

      this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
        const domainValues = {
          name: this.currentDomain.Name,
          visibilitySelected: this.currentDomain.DomainVisibility?.Id?.toString() ?? '',
          parentSelected: this.currentDomain.ParentDomainId
        }

        this.dataChanged = false;

        for (let key in domainValues) {
          if (domainValues[key] !== values[key]) {
            this.dataChanged = true;
            break;
          }
        }
      });
    });
  }

  private _init(): void {
    this.administratorApiService.domainVisibilityList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(items => {
      if (items) {
        this.domainVisibilityItems = items;
      }
    });

    this.administratorApiService.domainList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.currentDomain = x.find(x => x.Id == this.domainId);

        this.parents = x.filter(x => x.Id != this.domainId);

        this.form.setValue({
          name: this.currentDomain.Name,
          visibilitySelected: this.currentDomain.DomainVisibility?.Id?.toString() ?? '',
          parentSelected: this.currentDomain.ParentDomainId
        });

        this._loadProperties();

        this.dataChanged = false;
        this.editMode = false;
      }
    });
  }

  public openAddProperty(): void {
    let sidebarResources: Resource[] = this.getSortedSidebarResources();
    const addPropertyDialog = this.dialog.open(AddDomainPropertyDialogComponent, {
      disableClose: false,
      data: { sidebarResources }
    });

    addPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newKeyValue => {
      if (newKeyValue) {
        const addPropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        addPropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.setDomainProperty(this.currentDomain.Id, newKeyValue.key, newKeyValue.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                if (newKeyValue.key === 'ThemeVariables') {
                  this.mainStore.dispatch(MainActions.reInitDomainStyle());
                }
                this.authStore.dispatch(AuthActions.getCurrentDomainProperties({domainId: this.currentDomain.Id}));
                this._loadProperties();
              }
            });
          }
        });
      }
    });
  }

  public openDetailProperty(domainProperty: DomainProperty): void {
    let sidebarResources: Resource[] = this.getSortedSidebarResources();
    const detailPropertyDialog = this.dialog.open(DomainPropertyDialogComponent, {
      data: {
        domainProperty,
        sidebarResources
      },
      width: '40%',
      disableClose: false
    });

    detailPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedKeyValue => {
      if (updatedKeyValue) {
        const updatePropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        updatePropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            if (updatedKeyValue.key.includes('ThemeVariables') && updatedKeyValue.key === domainProperty.Key) {
              let backupKey: string = 'Backup' + domainProperty.Key;
              this.administratorApiService.setDomainProperty(this.currentDomain.Id, backupKey, domainProperty.Value)
              .pipe(takeUntil(this.ngUnsubscribe)).subscribe();
            }
            this.administratorApiService.setDomainProperty(this.currentDomain.Id, updatedKeyValue.key, updatedKeyValue.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                if (updatedKeyValue.key === 'ThemeVariables') {
                  this.mainStore.dispatch(MainActions.reInitDomainStyle());
                }
                this.authStore.dispatch(AuthActions.getCurrentDomainProperties({domainId: this.currentDomain.Id}));
                this._loadProperties();
              }
            });
          }
        });
      }
    });
  }

  private getSortedSidebarResources(): Resource[] {
    let sidebarResources: Resource[] = [];
    let administrationResource: Resource = this.domainResources.find(resource => resource.Url === 'administration');

    this.domainResources.filter(domainResource => (domainResource.Image || domainResource.Label) &&
      !(administrationResource !== undefined && [domainResource.Id, domainResource.ParentId].includes(administrationResource.Id))
    ).forEach((domainResource: Resource) => {
      if (!sidebarResources.some((sidebarResource: Resource) => sidebarResource.Url === domainResource.Url)) {
        sidebarResources.push(domainResource);
      }
    });

    return sidebarResources.sort((a, b) => a.Url.localeCompare(b.Url));
  }

  public logoImageUpload(event: any): void {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        if (image.width === image.height) {
          let imageBaseData: string | ArrayBuffer = reader.result;
          this.administratorApiService.setDomainProperty(this.currentDomain.Id, 'Logo', imageBaseData.toString()).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this.authStore.dispatch(AuthActions.getCurrentDomainProperties({domainId: this.currentDomain.Id}));
              this._loadProperties();
            }
          });
          this.imageError = null;
        } else {
          this.imageError = 'IMAGE_RESOLUTION';
        }
      }
    };

    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  public openDeleteDialog(key: string): void {
    if (!key && key === undefined) {
      return;
    }

    const deleteDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.administratorApiService.setDomainProperty(this.currentDomain.Id, key, '').pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
          if (response) {
            if (key === 'ThemeVariables') {
              this.mainStore.dispatch(MainActions.reInitDomainStyle());
            }
            this.authStore.dispatch(AuthActions.getCurrentDomainProperties({domainId: this.currentDomain.Id}));
            this._loadProperties();
          }
        });
      }
    });
  }

  public update(): void {
    if (this.form.valid) {

      const updateDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.upsertDomainRequest = {
            Id: this.currentDomain.Id,
            Name: this.form.controls.name.value,
            Parent: this.form.controls.parentSelected.value,
            Visibility: +this.form.controls.visibilitySelected.value
          }

          this.administratorApiService.updateDomain(this.upsertDomainRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
            if (response) {
              this._init();
            }
          });
        }
      });
    }
  }

  private _loadProperties(): void {
    this.apiService.getDomainProperties(this.currentDomain.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(properties => {
      this.domainProperties = properties;
      this.filteredData = this.domainProperties;
      this.domainLogo = this.domainProperties.find(property => property.Key === 'Logo')?.Value || '';
    });
  }

  public enableEdit(): void {
    this.form.controls['visibilitySelected'].enable();
    this.form.controls['parentSelected'].enable();
    this.editMode = true;
  }

  public cancelEdit(): void {
    this.form.setValue({
      name: this.currentDomain.Name,
      visibilitySelected: this.currentDomain.DomainVisibility?.Id?.toString() ?? '',
      parentSelected: this.currentDomain.ParentDomainId
    });

    this.form.controls['visibilitySelected'].disable();
    this.form.controls['parentSelected'].disable();
    this.dataChanged = false;
    this.editMode = false;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.domainProperties?.filter((property: DomainProperty) =>
      [
        property.Key?.toLowerCase(), 
        property.Value?.toLowerCase()
      ].some((field: string) => field.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
