import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserRoles} from "../../../../shared/models/userRoles";
import {Subject} from "rxjs";
import {PaginationInstance} from "ngx-pagination";
import {Router} from "@angular/router";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {ContentResponse} from "../../../../shared/models/content-management/contentResponse";
import {BroadcastClientResponse} from "../../../../shared/models/content-management/broadcastClientResponse";
import {Configuration} from "../../../../shared/models/configuration";
import {ContentDialogComponent} from "../../../../shared/dialogs/content-dialog/content-dialog.component";
import { ContentDataDialogComponent } from 'src/app/shared/dialogs/content-data-dialog/content-data-dialog.component';
import { ContentRequest, FileContentRequest } from 'src/app/shared/models/content-management/contentRequest';
import { EntityField } from 'src/app/shared/models/content-management/entityField';
import { EntityResponse } from 'src/app/shared/models/content-management/entityResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'urban-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss']
})
export class ContentsComponent implements OnInit, OnDestroy {

  columns = ['Name', 'MimeType', 'Created', 'Updated', 'Detail'];
  public contents: ContentResponse[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: ContentResponse[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  private broadcastClients: BroadcastClientResponse[] = [];
  private appConfig: Configuration;

  constructor(
    private router: Router,
    private contentManagementService: ContentManagementService,
    private passDataService: PassDataService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this._initList();

      this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
        this.appConfig = config;
      })
    });
  }

  public add(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const addDialogRef = this.dialog.open(ContentDialogComponent, {
        data: {
          broadcastClients: this.broadcastClients,
          appConfig: this.appConfig
        }
      });

      addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newContent => {
        if (newContent) {
          if (newContent.request.file !== undefined) {
            this.openConfirmationDialog(() => {
              this.contentManagementService.addContent(newContent.request).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            });
          }
          else {
            this.dataCompilation(newContent.request);
          }
        }
      });
    }
  }

  private dataCompilation(contentRequest: ContentRequest): void {
    this.contentManagementService.listEntity().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: EntityResponse[]) => {
      if (res && res.length > 0) {
        let contentEntity: EntityResponse = res.find(entity => contentRequest.tags.toLowerCase().includes(entity.Name.toLowerCase()));
        if (contentEntity?.Content) {
          let contentModel: EntityField[];
          try {
            contentModel = JSON.parse(contentEntity.Content) as EntityField[];
            res = res.filter(entity => entity.Name !== contentEntity.Name);
            this.nestInternalEntities(contentModel, res);
          }
          catch (e) {
            this.mainService.setCustomErrorComand(e);
            return;
          }
          const contentDataDialogRef = this.dialog.open(ContentDataDialogComponent, {
            disableClose: false,
            width: '80%',
            data: {
              contentName: contentRequest.name,
              contentModel
            }
          });

          contentDataDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((fileContent: FileContentRequest) => {
            if (fileContent) {
              contentRequest.file = fileContent;

              this.openConfirmationDialog(() => {
                this.contentManagementService.addContent(contentRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                  if (x) {
                    this._initList();
                  }
                });
              });
            }
          });
        }
        else {
          this.translate.get('CONTENT_MANAGEMENT.NO_ENTITY_ERROR').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            if (res) {
              this.mainService.setCustomErrorComand(res);
            }
          });
        }
      }
    });
  }

  private nestInternalEntities(entityModel: EntityField[], entities: EntityResponse[]): void {
    entityModel.forEach((entityField: EntityField) => {
      let entityType: string;

      if (entityField.type.includes('[]')) {
        entityType = entityField.type.replace('[]', '');
      }
      else {
        entityType = entityField.type;
      }

      if (['string', 'number'].includes(entityType)) {
        return;
      }

      if (entityType === 'object' && entityField.children?.length > 0) {
        this.nestInternalEntities(entityField.children, entities);
      }
      else {
        let entityFound: EntityResponse = entities.find(entityToCheck => entityToCheck.Name === entityType);

        if (entityFound?.Content) {
          try {
            entityField.children = JSON.parse(entityFound.Content) as EntityField[];
            entityField.type = entityField.type.replace(entityType, 'object');
            this.nestInternalEntities(entityField.children, entities.filter(entity => entity.Name !== entityFound.Name));
          }
          catch (_) {
            return;
          }
        }
      }
    });
  }

  private openConfirmationDialog(afterConfirmed: () => void): void {
    const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      width: '50%',
      maxWidth: 500,
    });

    addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
          afterConfirmed();
      }
    });
  }

  private _initList(): void {
    this.contentManagementService.listContent().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x && x.length > 0) {
        this.contents = x;
        this.filteredData = this.contents;
      }
    });

    this._initBroadcastClients();
  }

  private _initBroadcastClients(): void {
    this.contentManagementService.listBroadcastClient().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.broadcastClients = x;
      }
    });
  }

  public detail(content: ContentResponse): void {
    if (!content) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: content.Id, BackRoute: 'content-management/contents'});
    this.router.navigate(['main/content-management/content']);
  }

  public delete(content: ContentResponse): void {
    if (!content) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {
      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.contentManagementService.deleteContent(content.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.contents?.filter((key: ContentResponse) =>
      [
        key.Name?.toLowerCase(), 
        key.MimeType?.toLowerCase(),
        key.Created ? new Date(key.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : '',
        key.Updated ? new Date(key.Updated * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : ''
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
