import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SMSSAdvertiser } from '../../../../shared/models/smss/smssAdvertiser';
import { UserRoles } from '../../../../shared/models/userRoles';
import { ApiService } from '../../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-advertiser',
  templateUrl: './advertiser.component.html',
  styleUrls: ['./advertiser.component.scss']
})
export class AdvertiserComponent implements OnInit, OnDestroy {

  public advertiser: SMSSAdvertiser;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public error: string | null;
  public userRoles: UserRoles['Roles'];

  public form: UntypedFormGroup = this.formBuilder.group({
    companyName: [{ value: '' }, Validators.required],
    address: [''],
    zip: [''],
    city: [''],
    state: [''],
    country: [''],
    vat: [''],
    phone: [''],
    fax: [''],
    web: [''],
    email: [''],
    firstname: [''],
    lastname: [''],
    timezone: [''],
    customCss: [''],
    maxCampaigns: [0],
    publicAdvCampaigns: [0],
    erpCode: [''],
    notes: [''],
    locked: ['false']
  });

  public advertiserId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this._initialize();
  }

  private _initialize(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;

      const advertiserId = this.route.snapshot.paramMap.get('id');
      if (advertiserId === undefined || advertiserId === null || advertiserId === '')
        this.router.navigate(['main/dashboard']);

      this.apiService.advertiserRead(advertiserId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.advertiser = result;

          this.form.controls.companyName.setValue(this.advertiser.CompanyName);
          this.form.controls.address.setValue(this.advertiser.Address);
          this.form.controls.zip.setValue(this.advertiser.Zip);
          this.form.controls.city.setValue(this.advertiser.City);
          this.form.controls.state.setValue(this.advertiser.State);
          this.form.controls.country.setValue(this.advertiser.Country);
          this.form.controls.vat.setValue(this.advertiser.VAT);
          this.form.controls.phone.setValue(this.advertiser.Phone);
          this.form.controls.fax.setValue(this.advertiser.Fax);
          this.form.controls.web.setValue(this.advertiser.Web);
          this.form.controls.email.setValue(this.advertiser.EmailAddress);
          this.form.controls.firstname.setValue(this.advertiser.FirstName);
          this.form.controls.lastname.setValue(this.advertiser.LastName);
          this.form.controls.timezone.setValue(this.advertiser.TimeZone);
          this.form.controls.customCss.setValue(this.advertiser.CustomCSS);
          this.form.controls.maxCampaigns.setValue(+this.advertiser.MaxActiveCampaigns);
          this.form.controls.publicAdvCampaigns.setValue(+this.advertiser.PublicAdvCampaigns);
          this.form.controls.erpCode.setValue(this.advertiser.ERPCode);
          this.form.controls.notes.setValue(this.advertiser.Notes);
          this.form.controls.locked.setValue(this.advertiser.Locked);
        }
      })
    })
  }

  update() {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.advertiser = {
            id: this.advertiser.id,
            CreationDate: this.advertiser.CreationDate,
            CompanyName: this.form.controls.companyName.value,
            ResellerID: this.advertiser.ResellerID,
            Address: this.form.controls.address.value,
            Zip: this.form.controls.zip.value,
            City: this.form.controls.city.value,
            State: this.form.controls.state.value,
            Country: this.form.controls.country.value,
            VAT: this.form.controls.vat.value,
            Phone: this.form.controls.phone.value,
            Fax: this.form.controls.fax.value,
            Web: this.form.controls.web.value,
            EmailAddress: this.form.controls.email.value,
            FirstName: this.form.controls.firstname.value,
            LastName: this.form.controls.lastname.value,
            TimeZone: this.form.controls.timezone.value,
            CustomCSS: this.form.controls.customCss.value,
            MaxActiveCampaigns: this.form.controls.maxCampaigns.value.toString(),
            PublicAdvCampaigns: this.form.controls.publicAdvCampaigns.value.toString(),
            ERPCode: this.form.controls.erpCode.value,
            Notes: this.form.controls.notes.value,
            Locked: this.form.controls.locked.value
          }

          this.apiService.advertiserWrite(this.advertiser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
              this.mainService.setSuccessMessageComand(message);

              this._initialize();
            }
          })
        }
      });
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  goToCampaigns() {
    this.router.navigate(['main/campaigns', { advertiserId: this.advertiser.id }]);
  }

  back() {
    this.router.navigate(['main/advertisers', { id: this.advertiser.ResellerID }]);
  }
}
