import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ServiceEventItem } from '../../models/service/serviceEventResponse';
import { Observable, Subject, Subscription } from 'rxjs';
import { ChartDataList } from '../../models/ChartDataList';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'urban-trash-bin-widget',
  templateUrl: './trash-bin-widget.component.html',
  styleUrls: ['./trash-bin-widget.component.scss']
})
export class TrashBinWidgetComponent implements OnChanges {
  @Input('darkModeStatus') public isDarkActive: boolean;
  @Input('trashBinEvents') public trashBinEvents: ServiceEventItem[];

  public trashBinData: ChartDataList = {};
  public dataDescriptions: string[] = [];
  public dataUnit: string = 'FILL_PERCENTAGE';
  public dataReady: boolean = false;
  public translationsReady: boolean = false;
  public noDataOnChart: boolean = true;
  private translateSubscription: Subscription = new Subscription();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private mainService: MainSubscriptionsService,
    private router: Router
  ) {}

  private calculateAverage(data: ServiceEventItem[]): ChartDataList {
    let averageData: ChartDataList = {};

    data.forEach(device => {
      const { Name: name, Events: events } = device;

      const { totalRate, count } = events.reduce((totals, event) => {
        const rate = event.Body?.filling_rate || 0;
        totals.totalRate += parseInt(rate);
        totals.count++;
        return totals;
      }, { totalRate: 0, count: 0 });

      if (count > 0) {
        averageData[name] = totalRate / count;
      }

      this.dataDescriptions.push(name);
    });

    this.dataReady = true;
    return averageData;
  }

  private initializeData(trashBinEvents: ServiceEventItem[]): void {
    this.translateSubscription.unsubscribe();
    let phrase: string = 'TRASH_BIN.FILL_PERCENTAGE';

    this.getTranslations(phrase).subscribe(() => {
      this.trashBinData = this.calculateAverage(trashBinEvents);
    })

    this.translateSubscription = this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrase).subscribe();
    });
  }

  private getTranslations(phrase: string): Observable<void> {
    return new Observable<void>(translations => {
      this.translate.get(phrase).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.dataUnit = res;
        this.translationsReady = true;

        translations.next();
        translations.complete();
      });
    });
  }

  public goToTrashBinDashboard(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'dashboard' });
    this.router.navigate(['main/trash-bin']);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['trashBinEvents']) {
      const trashBinEvents = changes['trashBinEvents'];
      if (trashBinEvents.currentValue !== trashBinEvents.previousValue && trashBinEvents.firstChange == false) {
        if (this.trashBinEvents?.length > 0) {
          this.noDataOnChart = false;
          this.initializeData(this.trashBinEvents);
        } else {
          this.noDataOnChart = true;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
