<google-map *ngIf="!refreshMap" width="100%" [options]="mapOptions" [height]="passedHeight ? passedHeight : 750">
    <map-marker #marker="mapMarker" *ngFor="let myMarker of markers; let i = index" [position]="myMarker.position"
        [options]="myMarker.options" [label]="myMarker.label" [title]="myMarker.title"
        (mapClick)="openInfoWindow(marker, i, myMarker.info)">

        <map-info-window>
            <div class="text-center p-2">
                <a href="javascript:void(0);" (click)="openInfo(myMarker.info)" style="font-size: large;">
                    {{ myMarker.title }}
                </a>
                <hr class="mb-3 mt-2">

                <!-- <div class="d-flex flex-row justify-content-center">
                    <div class="d-inline-flex d-row align-items-center">
                        <span class="cerchio mr-2" [ngStyle]="{'background-color': '#349b34'}"></span>
                        <span class="darkText mr-2">{{ myMarker.free }}</span>
                    </div>
                    <div class="d-inline-flex d-row align-items-center">
                        <span class="cerchio mr-2" [ngStyle]="{'background-color': '#bb2d2d'}"></span>
                        <span class="darkText mr-2">{{ myMarker.total - myMarker.free }}</span>
                    </div>
                </div> -->
            </div>

            <div *ngIf="myMarker.groups && myMarker.groups !== undefined && myMarker.groups.length > 0"
                class="darkText text-center p-2">
                <div class="d-flex flex-column justify-content-center">
                    <div class="d-inline-flex d-row align-items-center" *ngFor="let group of myMarker.groups">
                        <div class="cerchio mr-2" [ngStyle]="{'background-color': '#' + group.Color}"
                            (click)="openDeviceGroupDetail(group.Id)"></div>
                        <a href="javascript:void(0);" class="mr-2"
                            (click)="openDeviceGroupDetail(group.Id)">{{ group.Name }}</a>
                    </div>
                </div>
            </div>
        </map-info-window>
    </map-marker>

</google-map>

<div *ngIf="refreshMap" style="width:'100%'" [ngStyle]="{'height': (passedHeight ? passedHeight : 750) + 'px'}">