<div class="text-center">
    <h1 mat-dialog-title>{{ 'DASHBOARD.ADD_WIDGET' | translate }}</h1>
    <p>{{ 'ADD_WIDGET_DIALOG.SELECT_WIDGET' | translate }}</p>
</div>

<mat-dialog-content>
    <mat-selection-list class="overflow-auto d-flex flex-column py-4" #widget (selectionChange)="onGroupsChange(widget.selectedOptions.selected)">
        <mat-list-option [disableRipple]="true" *ngFor="let widget of passedWidgets" [value]="widget">
            {{widget.Name}}
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button class="btn actionButton" (click)="addWidget()">{{ 'DASHBOARD.ADD_WIDGET' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate}}</button>
</div>
