import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceGroupDialogComponent } from '../../../shared/dialogs/device-group-dialog/device-group-dialog.component';
import { DeviceGroup } from '../../../shared/models/deviceGroup';
import { AdministratorApiService } from '../../../shared/services/administrator-api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-device-group-list',
  templateUrl: './device-group-list.component.html',
  styleUrls: ['./device-group-list.component.scss']
})
export class DeviceGroupListComponent implements OnInit, OnDestroy {

  groupsColumns = ['Name', 'Created', 'Detail'];
  public deviceGroups: DeviceGroup[] = [];
  public filteredData: DeviceGroup[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private administratorApiService: AdministratorApiService,
    public dialog: MatDialog,
    private router: Router,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.administratorApiService.deviceGroupList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.deviceGroups = result;
        this.filteredData = this.deviceGroups;
      }
    });
  }

  public addGroup(): void {
    const addDeviceGroupDialogRef = this.dialog.open(DeviceGroupDialogComponent, {
      width: '50%'
    });

    addDeviceGroupDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newGroup => {
      if (newGroup) {
        const addSettingConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addSettingConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.deviceGroupAdd(newGroup.name, newGroup.color).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this.loadData();
              }
            });
          }
        });
      }
    });
  }

  public deviceGroupDelete(deviceGroup: DeviceGroup): void {
    if (deviceGroup) {
      const deleteDeviceGroupConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteDeviceGroupConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorApiService.deviceGroupDelete(deviceGroup.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this.loadData();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceGroups?.filter((group: DeviceGroup) =>
      [
        group.Name?.toLowerCase(), 
        group.Created ? new Date(group.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : ''
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public deviceGroupDetail(deviceGroup: DeviceGroup): void {
    this.mainService.setNavigationInfoComand({ Id: deviceGroup.Id, BackRoute: 'device-group-list' });
    this.router.navigate(['main/device-group-detail']);
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
