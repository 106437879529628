import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device } from '../../models/device';
import { DeviceGroup } from '../../models/deviceGroup';

@Component({
  selector: 'urban-device-group-set-dialog',
  templateUrl: './device-group-set-dialog.component.html',
  styleUrls: ['./device-group-set-dialog.component.scss']
})
export class DeviceGroupSetDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    groupSelected: [{ value: '', disabled: false }, Validators.required],
    deviceSelected: [{ value: '', disabled: false }, Validators.required]
  });

  public devices: Device[];
  public groups: DeviceGroup[] = [];
  public error: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<DeviceGroupSetDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        groups: DeviceGroup[],
        devices: Device[],
        fromDevice: boolean,
        fromGroup: boolean
      }) { }


  ngOnInit(): void {
    this.groups = this.data.groups;
    this.devices = this.data.devices;

    if(this.data.fromGroup){
      this.form.controls.groupSelected.setValue(this.groups[0].Id);
      this.form.controls.groupSelected.disable();
    }

    if(this.data.fromDevice){
      this.form.controls.deviceSelected.setValue(this.devices[0].Id);
      this.form.controls.deviceSelected.disable();
    }
  }

  addition() {
    if (this.form.valid) {
      this.dialogRef.close({ groupId: this.form.controls.groupSelected.value, deviceId: this.form.controls.deviceSelected.value  })
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
