<h1 mat-dialog-title *ngIf="advertisingTypes">{{ 'GENERAL.ADD' | translate }} {{ 'ADVERTISING_DIALOG.ADVERTISING' | translate | lowercase }}</h1>

<mat-dialog-content>
    <form [formGroup]="form" class="d-flex flex-column">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
            <mat-select formControlName="type" required>
                <mat-option *ngFor="let type of advertisingTypes" [value]="type.Id">
                    {{type.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.MESSAGE' | translate }}</mat-label>
            <input matInput formControlName="message" autocomplete="off">
        </mat-form-field>

        <input style="display: none;" type="file" (change)="imageUpload($event)" accept=".png, .jpg, .jpeg, .gif" #imageInput id="imageInput">

        <div class="d-flex flex-row justify-content-center">
            <button mat-button type="submit" class="btn actionButton" (click)="imageInput.click()">
                {{ (form.controls.image.value === '' ? 'GENERAL.UPLOAD_IMAGE' : 'GENERAL.CHANGE_IMAGE') | translate }}
            </button>

            <mat-error *ngIf="imageError" class="ml-3 d-flex flex-row align-items-center">
                {{ 'ERROR.' + imageError | translate }}
            </mat-error>

            <div *ngIf="form.controls.image.value !== ''" class="ml-3 d-flex flex-row">
                <img [src]="form.controls.image.value"
                    class="img-fluid advertisingImage" alt="Advertising Image">
                <i class="urbanicon-remove" (click)="removeImage()"></i>
            </div>
        </div>
        <mat-form-field class="d-none">
            <mat-label>{{ 'GENERAL.IMAGE' | translate }}</mat-label>
            <input matInput formControlName="image" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="customRangeInput">
            <mat-label>{{ 'ADVERTISING_LIST.DATE_RANGE' | translate }}</mat-label>
            <div class="d-flex flex-row justify-content-between align-items-center">
                <mat-date-range-input [rangePicker]="picker" [min]="minDateRange">
                    <input matStartDate formControlName="startDate" [placeholder]="'GENERAL.START_DATE' | translate">
                    <input matEndDate formControlName="endDate" [placeholder]="'GENERAL.END_DATE' | translate">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            </div>
            <mat-hint>{{ 'GENERAL.DATE_HINT' | translate }} – {{ 'GENERAL.DATE_HINT' | translate }}</mat-hint>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <div class="d-flex flex-row justify-content-between mt-3">
            <mat-label class="d-flex flex-row align-items-center">{{ 'GENERAL.TIME_RANGE' | translate }}:</mat-label>
            <mat-form-field class="ml-4">
                <mat-label>{{ 'GENERAL.START' | translate }}</mat-label>
                <input formControlName="startTime" matTimepicker required/>
                <mat-hint>{{ 'GENERAL.TIME_HINT' | translate }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="ml-4">
                <mat-label>{{ 'GENERAL.END' | translate }}</mat-label>
                <input formControlName="endTime" matTimepicker required/>
                <mat-hint>{{ 'GENERAL.TIME_HINT' | translate }}</mat-hint>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{ 'ADVERTISING_LIST.SERVICE' | translate }}</mat-label>
            <mat-select formControlName="service" required>
                <mat-option *ngFor="let service of services" [value]="service.Id">
                    {{service.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </form>

    <mat-error *ngIf="error">
        {{ 'GENERAL.ERROR_ALL_FIELDS_REQUIRED' | translate }}
    </mat-error>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="saveAdvertising()">
        {{ (advertising ? 'GENERAL.UPDATE' : 'GENERAL.ADD') | translate }}
    </button>
    <button mat-button class="btn outlineActionButton ml-2" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
