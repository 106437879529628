<div class="container">
    <div class="d-flex justify-content-between">
        <h1 mat-dialog-title>{{'DEVICE_EVENTS.TITLE' | translate }}</h1>
    </div>

    <mat-dialog-content>
        <form [formGroup]="detailGroup">
            <div class="myFormContainer">
                <div class="d-flex flex-column justify-content-between box">
                    <mat-form-field>
                        <mat-label>{{'GENERAL.ID' | translate }}</mat-label>
                        <input matInput formControlName="id" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field *ngIf="detailGroup.controls.deviceId.value">
                        <mat-label>{{'GENERAL.DEVICE_ID' | translate }}</mat-label>
                        <input matInput formControlName="deviceId" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field *ngIf="detailGroup.controls.deviceName.value">
                        <mat-label>{{'GENERAL.DEVICE_NAME' | translate }}</mat-label>
                        <input matInput formControlName="deviceName" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'DEVICE_EVENTS.TYPE' | translate }}</mat-label>
                        <input matInput formControlName="type" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'DEVICE_EVENTS.LEVEL' | translate }}</mat-label>
                        <input matInput formControlName="level" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'GENERAL.CREATED' | translate }}</mat-label>
                        <input matInput formControlName="created" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ 'DEVICE_EVENTS.BODY' | translate }}</mat-label>
                        <textarea class="tableEvidenceBG" matInput formControlName="body" matTextareaAutosize matAutosizeMinRows=15 matAutosizeMaxRows=30 readonly></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-button class="btn outlineActionButton" [mat-dialog-close]="true">{{'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>
