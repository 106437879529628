import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'urban-invisible-child',
  templateUrl: './invisible-child.component.html',
  styleUrls: ['./invisible-child.component.scss']
})
export class InvisibleChildComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
