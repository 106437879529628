import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientSettingRequest } from '../../models/administrator/clientSettingRequest';

@Component({
  selector: 'urban-client-setting-dialog',
  templateUrl: './client-setting-dialog.component.html',
  styleUrls: ['./client-setting-dialog.component.scss']
})
export class ClientSettingDialogComponent implements OnInit {

  public error: string | null;
  private clientSetting: ClientSettingRequest;
  public isAdd: boolean = true;

  public form: UntypedFormGroup = this.formBuilder.group({
    key: [{ value: '', disabled: false }, Validators.required],
    value: [{ value: '', disabled: false }, Validators.required]
  });

  constructor(
    public dialogRef: MatDialogRef<ClientSettingDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        setting: ClientSettingRequest
      }
  ) {
    if (this.data && this.data !== undefined && this.data.setting && this.data.setting !== undefined) {
      this.clientSetting = this.data.setting;
      this.isAdd = false;
    }

  }

  ngOnInit(): void {
    if (!this.isAdd) {
      this.form.controls.key.setValue(this.clientSetting.Key);
      this.form.controls.value.setValue(this.clientSetting.Value);
    }
  }

  addition() {
    if (this.form.valid) {
      this.clientSetting = {
        Key: this.form.controls.key.value,
        Value: this.form.controls.value.value
      };

      this.dialogRef.close(this.clientSetting)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
