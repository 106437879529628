import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SendMessageRequest } from '../../../shared/models/sendMessageRequest';
import { ApiService } from '../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup = this.formBuilder.group({
    message: [{ value: '', disabled: false }, Validators.required]
  });

  private sendMessageRequest: SendMessageRequest;
  public error: string | null = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private passDataService: PassDataService) { }

  ngOnInit(): void {
  }

  send() {
    if (this.form.valid) {
      this.sendMessageRequest = {
        Message: this.form.controls.message.value.toString()
      }

      const sendMessageConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      sendMessageConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.apiService.sendMessage(this.sendMessageRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.mainService.setSuccessMessageComand('messageSent');
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
