import {Component, Inject, OnInit} from '@angular/core';
import {NameEntity} from "../../models/nameEntity";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TemplateItem} from "../../models/administrator/templateItem";
import {Language} from "../../models/language";
import {TemplateRequest} from "../../models/administrator/templateRequest";
import {DeviceCommandType} from "../../models/device-command/deviceCommandType";

@Component({
  selector: 'urban-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss']
})
export class TemplateDialogComponent implements OnInit {

  private templateItem: TemplateItem;
  private templateItemRequest: TemplateRequest;

  public languages: Language[] = [];
  public templateTypes: NameEntity<number>[] = [];
  public templateFormats: NameEntity<number>[] = [];
  public isAdd = true;
  public options: { [key: string]: boolean } = {}

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    templateTypeSelected: [{value: 0, disabled: 0}, Validators.required],
    templateFormatSelected: [{value: 0, disabled: 0}, Validators.required],
    languageSelected: [{value: 0, disabled: 0}, Validators.required],
    content: [{value: '', disabled: false}, Validators.required],
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<TemplateDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        templateItem: TemplateItem,
        languages: Language[],
        templateTypes: NameEntity<number>[],
        templateFormats: NameEntity<number>[]
      }
  ) {
  }

  ngOnInit(): void {
    this.languages = this.data?.languages;
    this.templateFormats = this.data?.templateFormats;
    this.templateTypes = this.data?.templateTypes;

    if (this.data && this.data.templateItem) {
      this.templateItem = this.data.templateItem;
      this.isAdd = false;
      this.form.controls.name.setValue(this.templateItem.Name);
      this.form.controls.content.setValue(this.templateItem.Content);
      this.form.controls.templateTypeSelected.setValue(this.templateItem.Type.Id?.toString());
      this.form.controls.templateFormatSelected.setValue(this.templateItem.Format.Id?.toString());
      this.form.controls.languageSelected.setValue(this.templateItem.Language.Id.toString()?.toString());

      this.loadTemplateTypeOptions();
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.templateItemRequest = {
        Id: this.templateItem?.Id || 0,
        Name: this.form.controls.name.value,
        Content: this.form.controls.content.value,
        Language: +this.form.controls.languageSelected.value,
        Type: +this.form.controls.templateTypeSelected.value,
        Format: +this.form.controls.templateFormatSelected.value
      };

      for (let key of Object.keys(this.options)) {
        const value = (<HTMLInputElement>document.getElementById(key))?.value;
        if (value) {
          if (!this.templateItemRequest.Options) {
            this.templateItemRequest.Options = {};
          }

          this.templateItemRequest.Options[key] = value;
        }
      }

      this.dialogRef.close(this.templateItemRequest);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.options = {};
    this.dialogRef.close();
  }

  public getKeys(element: object): string[] {
    if (element === undefined || element === null || element === {}) {
      return [];
    }

    return Object.keys(element);
  }

  public hasLength(element: object): boolean {
    return Object.keys(element).length > 0;
  }

  public loadTemplateTypeOptions() {
    if (this.form.controls.templateTypeSelected.value) {
      const templateType: NameEntity<number> = this.templateTypes.find(x => x.Id === +this.form.controls.templateTypeSelected.value);

      this.options = templateType.Options;
    }
  }
}
