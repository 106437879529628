import {TransitDashboardComponent} from '../../pages/main/transit-dashboard/transit-dashboard.component';
import {TransitDetailComponent} from '../../pages/main/transit-detail/transit-detail.component';
import {SmartParkingDetailComponent} from '../../pages/main/smart-parking-detail/smart-parking-detail.component';
import {NewsComponent} from '../../pages/main/news/news.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from '../../pages/main/main.component';
import {MainRoutingModule} from './main-routing.module';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {DashboardComponent} from '../../pages/main/dashboard/dashboard.component';
import {ProfileComponent} from '../../pages/main/profile/profile.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {WipComponent} from '../../pages/main/wip/wip.component';
import {StoreModule} from '@ngrx/store';
import * as fromMain from '../../store/main/main.reducer';
import {EffectsModule} from '@ngrx/effects';
import {MainEffects} from '../../store/main/main.effects';
import {DeviceComponent} from '../../pages/main/device/device.component';
import {DevicesTableComponent} from '../../pages/main/devices-table/devices-table.component';
import {HistoryComponent} from '../../pages/main/history/history.component';
import {RemoteControlDetailComponent} from '../../pages/main/remote-control-detail/remote-control-detail.component';
import {WeatherStationDetailComponent} from '../../pages/main/weatherstation-detail/weatherstation-detail.component';
import {WifiModule} from './wifi/wifi.module';
import {UserDetailComponent} from '../../pages/main/user-detail/user-detail.component';
import {RoleManagementComponent} from '../../pages/main/administration/role-management/role-management.component';
import {DomainManagementComponent} from '../../pages/main/administration/domain-management/domain-management.component';
import {UserManagementComponent} from '../../pages/main/administration/user-management/user-management.component';
import {RoleDetailComponent} from '../../pages/main/role-detail/role-detail.component';
import {UserListComponent} from '../../pages/main/user-list/user-list.component';
import {UserDomainDetailComponent} from '../../pages/main/user-domain-detail/user-domain-detail.component';
import {MessageComponent} from '../../pages/main/message/message.component';
import {PlatformStatisticsComponent} from '../../pages/main/platform-statistics/platform-statistics.component';
import {
  PlatformStatisticsBarchartComponent
} from '../../pages/main/platform-statistics-barchart/platform-statistics-barchart.component';
import {LogsStatisticsComponent} from '../../pages/main/logs-statistics/logs-statistics.component';
import {NotificationListComponent} from '../../pages/main/administration/notification-list/notification-list.component';
import {
  NotificationDetailComponent
} from '../../pages/main/administration/notification-detail/notification-detail.component';
import {DomainDetailComponent} from '../../pages/main/administration/domain-detail/domain-detail.component';
import {PhraseManagementComponent} from '../../pages/main/administration/phrase-management/phrase-management.component';
import {
  ClientSettingManagementComponent
} from '../../pages/main/administration/client-setting-management/client-setting-management.component';
import {
  ResourceManagementComponent
} from '../../pages/main/administration/resource-management/resource-management.component';
import {
  UpdateMissingPhrasesComponent
} from '../../pages/main/administration/phrase-management/update-missing-phrases/update-missing-phrases.component';
import {DeviceEventsComponent} from '../../pages/main/device-events/device-events.component';
import {DomainDeviceEventsComponent} from '../../pages/main/domain-device-events/domain-device-events.component';
import {HeatmapDevicesComponent} from '../../pages/main/heatmap-devices/heatmap-devices.component';
import {DeviceBrandsComponent} from '../../pages/main/administration/device-brands/device-brands.component';
import {DeviceBrandComponent} from '../../pages/main/device-brand/device-brand.component';
import {DeviceTypesComponent} from '../../pages/main/administration/device-types/device-types.component';
import {DevicesModelComponent} from '../../pages/main/administration/devices-model/devices-model.component';
import {HeatmapPeopleCounterComponent} from '../../pages/main/heatmap-people-counter/heatmap-people-counter.component';
import {DeviceTypeComponent} from '../../pages/main/device-type/device-type.component';
import {DeviceGroupListComponent} from '../../pages/main/device-group-list/device-group-list.component';
import {DeviceGroupDetailComponent} from '../../pages/main/device-group-detail/device-group-detail.component';
import {AdvertiserComponent} from '../../pages/main/smss/advertiser/advertiser.component';
import {AdvertisersComponent} from '../../pages/main/smss/advertisers/advertisers.component';
import {CampaignComponent} from '../../pages/main/smss/campaign/campaign.component';
import {CampaignsComponent} from '../../pages/main/smss/campaigns/campaigns.component';
import {LocationsComponent} from '../../pages/main/locations/locations.component';
import {CalendarsComponent} from '../../pages/main/calendars/calendars.component';
import {GatewayComponent} from '../../pages/main/smss/gateway/gateway.component';
import {WptemplateComponent} from '../../pages/main/smss/wptemplate/wptemplate.component';
import {
  SmartParkingDashboardComponent
} from '../../pages/main/smart-parking-dashboard/smart-parking-dashboard.component';
import {CrosswalkDashboardComponent} from '../../pages/main/crosswalk-dashboard/crosswalk-dashboard.component';
import {CrosswalkDetailComponent} from '../../pages/main/crosswalk-detail/crosswalk-detail.component';
import {TrafficDashboardComponent} from '../../pages/main/traffic-dashboard/traffic-dashboard.component';
import {TrafficDetailComponent} from '../../pages/main/traffic-detail/traffic-detail.component';
import {RoadRiskComponent} from '../../pages/main/road-risk/road-risk.component';
import {TaskScheduleComponent} from '../../pages/main/task-schedule/task-schedule.component';
import {TaskScheduleDetailComponent} from '../../pages/main/task-schedule-detail/task-schedule-detail.component';
import {TrashBinDetailComponent} from '../../pages/main/trash-bin-detail/trash-bin-detail.component';
import {TrashBinDashboardComponent} from '../../pages/main/trash-bin-dashboard/trash-bin-dashboard.component';
import {DeviceNotificationsComponent} from '../../pages/main/device-notifications/device-notifications.component';
import {DeviceCommandsComponent} from '../../pages/main/device-commands/device-commands.component';
import {DeviceCommandComponent} from '../../pages/main/device-command/device-command.component';
import {DeviceCommandDetailComponent} from '../../pages/main/device-command-detail/device-command-detail.component';
import {
  DeviceCommandTypeComponent
} from "../../pages/main/administration/device-command-type/device-command-type.component";
import {WidgetsComponent} from '../../pages/main/administration/widgets/widgets.component';
import {ServerSettingsComponent} from '../../pages/main/administration/server-settings/server-settings.component';
import {TemplatesComponent} from '../../pages/main/administration/templates/templates.component';
import {TemplateFormatsComponent} from '../../pages/main/administration/template-formats/template-formats.component';
import {TemplateTypesComponent} from '../../pages/main/administration/template-types/template-types.component';
import {LocationEventsComponent} from "../../pages/main/location-events/location-events.component";
import {LocationEventComponent} from "../../pages/main/location-event/location-event.component";
import {LocationComponent} from "../../pages/main/location/location.component";
import {
  DomainVisibilityItemsComponent
} from "../../pages/main/administration/domain-visibility-items/domain-visibility-items.component";
import {ThemesComponent} from "../../pages/main/administration/themes/themes.component";
import {ThemeDetailComponent} from "../../pages/main/administration/theme-detail/theme-detail.component";
import { BacnetMonitorComponent } from '../../pages/main/bacnet-monitor/bacnet-monitor.component';
import { BacnetDetailComponent } from '../../pages/main/bacnet-detail/bacnet-detail.component';
import {
  BroadcastClientTypesComponent
} from "../../pages/main/content-management/broadcast-client-types/broadcast-client-types.component";
import {ScheduleRulesComponent} from "../../pages/main/content-management/schedule-rules/schedule-rules.component";
import {
  BroadcastClientsComponent
} from "../../pages/main/content-management/broadcast-clients/broadcast-clients.component";
import {
  BroadcastClientComponent
} from "../../pages/main/content-management/broadcast-client/broadcast-client.component";
import {ChannelsComponent} from "../../pages/main/content-management/channels/channels.component";
import {ChannelComponent} from "../../pages/main/content-management/channel/channel.component";
import {BroadcastsComponent} from "../../pages/main/content-management/broadcasts/broadcasts.component";
import {ContentsComponent} from "../../pages/main/content-management/contents/contents.component";
import {ContentComponent} from "../../pages/main/content-management/content/content.component";
import {EntitiesComponent} from "../../pages/main/content-management/entities/entities.component";
import {EntityComponent} from "../../pages/main/content-management/entity/entity.component";
import { PeoplePassageComponent } from '../../pages/main/people-passage/people-passage.component';
import { NotesComponent } from 'src/app/pages/main/notes/notes.component';
import { UserSettingsComponent } from 'src/app/pages/main/user-settings/user-settings.component';
import { ServiceTypesComponent } from 'src/app/pages/main/administration/service-types/service-types.component';
import { ServiceListComponent } from 'src/app/pages/main/service-list/service-list.component';
import { ServiceDetailComponent } from 'src/app/pages/main/service-detail/service-detail.component';
import { DashboardManagementComponent } from 'src/app/pages/main/dashboard-management/dashboard-management.component';
import { DashboardVideoComponent } from 'src/app/pages/main/dashboard-video/dashboard-video.component';
import { AdvertisingTypeListComponent } from '../../pages/main/administration/advertising-type-list/advertising-type-list.component';
import { DeletedUsersComponent } from 'src/app/pages/main/deleted-users/deleted-users.component';
import { AdvertisingListComponent } from '../../pages/main/advertising-list/advertising-list.component';
import { ConsumerListComponent } from '../../pages/main/administration/consumer-list/consumer-list.component';
import { ConsumerDetailComponent } from '../../pages/main/administration/consumer-detail/consumer-detail.component';
import { AdvertisingEventListComponent } from '../../pages/main/advertising-event-list/advertising-event-list.component';
import { MapComponent } from '../../pages/main/map/map.component';
import { PassageDashboardComponent } from '../../pages/main/passage-dashboard/passage-dashboard.component';
import { PassageDetailComponent } from '../../pages/main/passage-detail/passage-detail.component';

@NgModule({
  declarations: [
    MainComponent,
    DashboardComponent,
    ProfileComponent,
    DeviceComponent,
    WipComponent,
    HistoryComponent,
    DevicesTableComponent,
    RemoteControlDetailComponent,
    WeatherStationDetailComponent,
    UserManagementComponent,
    UserDetailComponent,
    RoleManagementComponent,
    DomainManagementComponent,
    UserManagementComponent,
    RoleDetailComponent,
    UserListComponent,
    UserDomainDetailComponent,
    MessageComponent,
    PlatformStatisticsComponent,
    LogsStatisticsComponent,
    PlatformStatisticsBarchartComponent,
    NotificationListComponent,
    NotificationDetailComponent,
    DomainDetailComponent,
    PhraseManagementComponent,
    ClientSettingManagementComponent,
    ResourceManagementComponent,
    UpdateMissingPhrasesComponent,
    DeviceEventsComponent,
    DomainDeviceEventsComponent,
    HeatmapDevicesComponent,
    DeviceBrandsComponent,
    DeviceBrandComponent,
    DeviceTypesComponent,
    DevicesModelComponent,
    HeatmapPeopleCounterComponent,
    DeviceTypeComponent,
    DeviceGroupListComponent,
    DeviceGroupDetailComponent,
    NewsComponent,
    AdvertiserComponent,
    AdvertisersComponent,
    CampaignComponent,
    CampaignsComponent,
    LocationsComponent,
    CalendarsComponent,
    GatewayComponent,
    WptemplateComponent,
    SmartParkingDashboardComponent,
    SmartParkingDetailComponent,
    CrosswalkDashboardComponent,
    CrosswalkDetailComponent,
    TrafficDashboardComponent,
    TrafficDetailComponent,
    RoadRiskComponent,
    TaskScheduleComponent,
    TaskScheduleDetailComponent,
    TrashBinDashboardComponent,
    TrashBinDetailComponent,
    TransitDashboardComponent,
    TransitDetailComponent,
    DeviceNotificationsComponent,
    DeviceCommandsComponent,
    DeviceCommandComponent,
    DeviceCommandDetailComponent,
    DeviceCommandTypeComponent,
    WidgetsComponent,
    ServerSettingsComponent,
    TemplatesComponent,
    TemplateFormatsComponent,
    TemplateTypesComponent,
    LocationEventsComponent,
    LocationEventComponent,
    LocationComponent,
    DomainVisibilityItemsComponent,
    ThemesComponent,
    ThemeDetailComponent,
    BacnetMonitorComponent,
    BacnetDetailComponent,
    BroadcastClientTypesComponent,
    ScheduleRulesComponent,
    BroadcastClientsComponent,
    BroadcastClientComponent,
    ChannelsComponent,
    ChannelComponent,
    BroadcastsComponent,
    ContentsComponent,
    ContentComponent,
    EntitiesComponent,
    EntityComponent,
    PeoplePassageComponent,
    NotesComponent,
    UserSettingsComponent,
    ServiceTypesComponent,
    ServiceListComponent,
    ServiceDetailComponent,
    DashboardManagementComponent,
    DashboardVideoComponent,
    AdvertisingTypeListComponent,
    DeletedUsersComponent,
    AdvertisingListComponent,
    ConsumerListComponent,
    ConsumerDetailComponent,
    AdvertisingEventListComponent,
    MapComponent,
    PassageDashboardComponent,
    PassageDetailComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    RouterModule,
    SharedModule,
    WifiModule,
    FlexLayoutModule,
    StoreModule.forFeature(fromMain.mainFeatureKey, fromMain.mainReducer),
    EffectsModule.forFeature([MainEffects])
  ],
  providers: [],
})
export class MainModule {
}
