import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ChartDataDescription, ChartDataList } from '../../models/ChartDataList';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { AdvertisingEventsCount } from '../../models/advertising/advertising';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-marketing-widget',
  templateUrl: './marketing-widget.component.html',
  styleUrls: ['./marketing-widget.component.scss']
})
export class MarketingWidgetComponent implements OnChanges, OnDestroy {
  @Input('darkModeStatus') public isDarkActive: boolean;
  @Input('advertisingsEventsCounts') public advertisingsEventsCounts: AdvertisingEventsCount[];

  public advertisingsVisits: ChartDataList = {};
  public dataDescriptions: ChartDataDescription[] = [];
  public dataTranslations: string[] = [];
  private translateSubscription: Subscription = new Subscription();
  public dataReady: boolean = false;
  public translationsReady: boolean = false;
  public noDataOnChart: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private translate: TranslateService
  ) {}

  private initializeData(advertisingsEventsCounts: AdvertisingEventsCount[]): void {
    if (advertisingsEventsCounts?.length > 0) {
      let advertisingTypes: string[] = [];

      advertisingsEventsCounts.forEach(count => {
        if (!advertisingTypes.includes(count.AdvertisingType)) {
          advertisingTypes.push(count.AdvertisingType);
        }
      });

      this.setDataDescriptionsAndCalculateData(advertisingTypes);
    }
  }

  private setDataDescriptionsAndCalculateData(phrases: string[]): void {
    this.translateSubscription.unsubscribe();
    let prefix: string = 'MARKETING_WIDGET.';

    this.getTranslations(phrases, prefix).subscribe(() => {
      this.calculateAdvertisingTypesCounts();
    });

    this.translateSubscription = this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, prefix).subscribe();
    });
  }

  private getTranslations(phrases: string[], prefix: string): Observable<void> {
    return new Observable<void>(translations => {
      this.translate.get([...phrases.map((phrase: string) => prefix + phrase.toUpperCase().replace(' ', '_'))]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.dataDescriptions = [];
        this.dataTranslations = [];

        phrases.forEach((phrase: string) => {
          let translation: string = res[prefix + phrase.toUpperCase().replace(' ', '_')];
          let dataDescription: ChartDataDescription = {
            Original: phrase,
            Translated: translation !== (prefix + phrase.toUpperCase().replace(' ', '_')) ? translation : phrase
          };
          this.dataDescriptions.push(dataDescription);
          this.dataTranslations.push(dataDescription.Translated);
        });

        this.translationsReady = true;

        translations.next();
        translations.complete();
      });
    });
  }

  private calculateAdvertisingTypesCounts(): void {
    this.advertisingsEventsCounts.forEach(advCount => {
      if (this.advertisingsVisits[advCount.AdvertisingType] === undefined) {
        this.advertisingsVisits[advCount.AdvertisingType] = advCount.Count;
      }
      else {
        this.advertisingsVisits[advCount.AdvertisingType] += advCount.Count;
      }
    });

    this.dataReady = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['advertisingsEventsCounts']) {
      const visitsCounts = changes['advertisingsEventsCounts'];
      if (visitsCounts.currentValue !== visitsCounts.previousValue && visitsCounts.firstChange == false) {
        if (this.advertisingsEventsCounts?.length > 0) {
          this.noDataOnChart = false;

          this.initializeData(this.advertisingsEventsCounts);
        }
        else {
          this.noDataOnChart = true;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
