<h1 mat-dialog-title>{{ 'ADMINISTRATION.NEW_DOMAIN' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.DOMAIN_NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.DOMAIN_PARENT' | translate }}</mat-label>
            <mat-select formControlName="parentSelected">
                <mat-option *ngFor="let parentDomain of parents" value={{parentDomain.Id}}>{{parentDomain.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.DOMAIN_VISIBILITY' | translate }}</mat-label>
            <mat-select required formControlName="visibilitySelected">
                <mat-option *ngFor="let visibility of domainVisibilityItems" value={{visibility.Id}}>
                    {{visibility.Description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="addition()">{{ 'DEVICE.ADD' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'DEVICE.CANCEL' | translate }}</button>
</div>
