import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NameEntity} from "../../models/nameEntity";

@Component({
  selector: 'urban-name-entity-dialog',
  templateUrl: './name-entity-dialog.component.html',
  styleUrls: ['./name-entity-dialog.component.scss']
})
export class NameEntityDialogComponent<T> implements OnInit {
  private nameEntity: NameEntity<T>;

  public isAdd : boolean = true;
  public hasOptions : boolean = false;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    options: [{value: '{}', disabled: false}]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<NameEntityDialogComponent<T>>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        nameEntity: NameEntity<T>
      }
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.nameEntity) {
      this.nameEntity = this.data.nameEntity;
      this.isAdd = false;
      this.form.controls.name.setValue(this.nameEntity.Name);

      if (this.nameEntity.Options) {
        this.form.controls.options.setValue(JSON.stringify(this.nameEntity.Options));
        this.hasOptions = true;
      }
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.nameEntity = {
        Id: this.nameEntity?.Id || null,
        Name: this.form.controls.name.value
      };

      if (this.form.controls.options.value) {
        this.nameEntity.Options = JSON.parse(this.form.controls.options.value);
      }

      this.dialogRef.close(this.nameEntity);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
