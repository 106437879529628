<div class="searchDevice">
    <mat-form-field class="tableSearch" appearance="outline">
        <div matPrefix class="prefix">
            <i class="urbanicon-search"></i>
        </div>
        <mat-label>{{ 'DEVICE.SEARCH' | translate }}</mat-label>
        <input matInput [formControl]="myControl" [matAutocomplete]="auto">
        <button matSuffix mat-icon-button *ngIf="myControl.value !== null && myControl.value !== ''" type="submit" (click)="clearSelection()" matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
            <i class="urbanicon-close"></i>
        </button>
        <mat-autocomplete class="overlayBG" #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='goToDeviceDetail($event.option.value)'>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                <span class="urbanicon-{{ option.Model.Type?.Icon }}"></span>
                {{option.Name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<div class="parent">
    <div class="d-flex flex-wrap">
        <div class="boxNotMobile px-0" *ngFor="let type of this.allTypes" [ngClass]="widgetInColumn ? 'customColumnWidgetInCol' : 'customColumn'">
            <div class="box d-flex flex-column justify-content-center searchDeviceBG" (click)="goToTable(type.Name)">
                <div class="customHeight" *ngIf="this.anyDeviceOfType(type.Name)">
                    <h4 class="subheadText stringToShow">{{ 'REPORT.' + this.type.Name | translate }}</h4>
                </div>
                <div class="d-flex flex-row justify-content-between align-item-center">
                    <div>
                        <h1 class="title1 mb-0">{{ this.countDevicesOfType(type.Name) }}</h1>
                        <h5 class="paragraphText secondaryTextColor m-0 text-break">{{ 'REPORT.SUBTITLE_' + this.type.Name | translate }}</h5>
                    </div>
                    <div class="iconCircle flex-shrink-0" [ngStyle]="{'background-color':'#'+type.Color}">
                        <i class="text-center iconToShow urbanicon-{{type.Icon}}"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="boxMobile customColumn px-0" *ngFor="let type of this.allTypes" [ngClass]="widgetInColumn ? 'customColumnWidgetInCol' : 'customColumn'">
            <div class="box d-flex flex-row justify-content-around align-items-center searchDeviceBG" (click)="goToTable(type.Name)">
                <div class="iconCircle" [ngStyle]="{'background-color':'#'+type.Color}">
                    <i [ngStyle]="{'color':'#'+type.Color}" class="text-center iconToShow urbanicon-{{type.Icon}}"></i>
                </div>
                <div class="customHeight" *ngIf="this.anyDeviceOfType(type.Name)">
                    <h4 class="subheadText stringToShow mb-0">{{ 'REPORT.' + this.type.Name | translate }}</h4>
                </div>
                <div>
                    <h1 class="title1 mb-0">{{ this.countDevicesOfType(type.Name) }}</h1>
                    <h5 class="paragraphText titlesecondaryTextColor m-0">{{ 'REPORT.SUBTITLE_' + this.type.Name | translate }}</h5>
                </div>
            </div>
        </div>
    </div>
</div>
