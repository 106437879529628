import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Phrase } from '../../models/administrator/phrase';

@Component({
  selector: 'urban-phrase-dialog',
  templateUrl: './phrase-dialog.component.html',
  styleUrls: ['./phrase-dialog.component.scss']
})
export class PhraseDialogComponent implements OnInit {

  public error: string | null;
  private phrase: Phrase;
  public isAdd: boolean;
  public form: UntypedFormGroup = this.formBuilder.group({
    language: [{ value: '', disabled: false }, Validators.required],
    page: [{ value: '', disabled: false }, Validators.required],
    key: [{ value: '', disabled: false }, Validators.required],
    value: [{ value: '', disabled: false }, Validators.required]
  });
  constructor(
    public dialogRef: MatDialogRef<PhraseDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        language: String,
        key: String,
        page: String,
        value: String
      }
  ) { }

  ngOnInit(): void {
    this.form.controls.language.setValue(this.data.language);
    this.form.controls.language.disable();

    this.form.controls.key.setValue(this.data.key);
    this.form.controls.page.setValue(this.data.page);
    this.form.controls.value.setValue(this.data.value);

    this.isAdd = !this.form.valid;
  }

  addition() {
    if (this.form.valid) {
      this.phrase = {
        Language: this.form.controls.language.value,
        Page: this.form.controls.page.value,
        Key: this.form.controls.key.value,
        Value: this.form.controls.value.value
      }
      this.dialogRef.close(this.phrase)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
