import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoaderService } from "./loader/loader.service";
import { MainState } from '../../store/main/main.reducer';
import { Observable, of, Subject, timer } from "rxjs";
import { UVNotification, UVNotificationCreateRequest, UVNotificationDetail } from "../models/notification";
import { catchError, map, retry, share, switchMap, takeUntil } from "rxjs/operators";
import * as MainActions from '../../store/main/main.actions';
import { PassDataService } from "./pass-data/pass-data.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService implements OnDestroy {

  private notificationsList$: Observable<{Notifications: UVNotification[]}>;
  private stopPolling: Subject<void> = new Subject<void>();
  public pollingSeconds: number;

  constructor(
    private http: HttpClient,
    private readonly loaderService: LoaderService,
    private store: Store<MainState>,
    private passDataService: PassDataService
    ) {

    this.passDataService.appConfiguration$.pipe(takeUntil(this.stopPolling)).subscribe(config => {
      this.pollingSeconds = +config?.Settings?.notification_polling_seconds
    });

    this.notificationsList$ = timer(1,this.pollingSeconds*1000).pipe(
      switchMap(() => http.get<any>(`uv.notification.list.api`)),
      //retry(),
      retry(2),
      share(),
      takeUntil(this.stopPolling)
    )
  }

  public getNotificationsList(): Observable<{Notifications: UVNotification[]}> {
    return this.notificationsList$;
  }

  /* public getNotificationsList(): Observable<UVNotification[]> {
    return this.http.get<any>(
      `uv.notification.list.api`
    ).pipe(
      map((res: any) => res.Notifications),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  } */



  public readNotification(notificationId: string): Observable<any> {
    return this.http.post<any>(
      `uv.notification.read.api`,
      {
        id: notificationId
      }
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getAllNotificationsList(): Observable<UVNotification[]> {
    return this.http.get<any>(
      `uv.notification.all.api`
    ).pipe(
      map((res: any) => res.Notifications),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public notificationDetails(notificationId: string): Observable<UVNotificationDetail[]> {
    return this.http.get<any>(
      `uv.notification.detail.api?id=${notificationId}`
    ).pipe(
      map((res: any) => res.NotificationDetails),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public expireNotification(notificationId: string): Observable<any> {
    return this.http.post<any>(
      `uv.notification.expire.api`,
      {
        id: notificationId
      }
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public createNotification(request: UVNotificationCreateRequest): Observable<any> {
    return this.http.post<any>(
      `uv.notification.create.api`,
      request
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  ngOnDestroy(): void {
    this.stopPolling.next();
    this.stopPolling.complete();
  }

}
