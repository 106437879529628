import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SMSSDomain } from '../../models/smss/smssDomain';
import { SMSSGateway } from '../../models/smss/smssGateway';
import { SMSSWpWalledGarden } from '../../models/smss/smssWpWalledGarden';

@Component({
  selector: 'urban-walled-garden-dialog',
  templateUrl: './walled-garden-dialog.component.html',
  styleUrls: ['./walled-garden-dialog.component.scss']
})
export class WalledGardenDialogComponent implements OnInit {

  private walledGarden: SMSSWpWalledGarden;
  private domain : SMSSDomain;
  private gateway: SMSSGateway;

  public isAdd: boolean = true;

  public form: UntypedFormGroup = this.formBuilder.group({
    DestHost: [{ value: '', disabled: false }, Validators.required],
    DestHostPort: [{ value: '', disabled: false }, Validators.required],
    Allow: [{ value: 0, disabled: false }]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<WalledGardenDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        walledGarden: SMSSWpWalledGarden,
        gateway: SMSSGateway,
        domain: SMSSDomain
      }
  ) { }

  ngOnInit(): void {
    this.domain = this.data.domain;
    this.gateway = this.data.gateway;

    if (this.data !== null && this.data !== undefined && this.data.walledGarden !== null && this.data.walledGarden !== undefined) {
      this.walledGarden = this.data.walledGarden;
      this.isAdd = false;
      this.form.controls.DestHost.setValue(this.walledGarden.DestHost);
      this.form.controls.DestHostPort.setValue(this.walledGarden.DestHostPort);
      this.form.controls.Allow.setValue(this.walledGarden.Allow);
    }
  }

  addition() {
    if (this.form.valid) {

      this.walledGarden = {
        id: this.walledGarden !== null && this.walledGarden !== undefined ? this.walledGarden.id : '',
        DomainID : this.domain.id,
        GatewayID : this.gateway !== null && this.gateway !== undefined ? this.gateway.id : '',
        DestHost: this.form.controls.DestHost.value,
        DestHostPort: this.form.controls.DestHostPort.value.toString(),
        Allow: this.form.controls.Allow.value,
      }

      this.dialogRef.close(this.walledGarden)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
