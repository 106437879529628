import { createReducer, on } from '@ngrx/store';
import { User } from '../../shared/models/loginResponse';
import * as AuthActions from './auth.actions';
import * as AppActions from '../app/app.actions';
import { UserRoles } from '../../shared/models/userRoles';
import { Domain } from '../../shared/models/domain';
import { DomainProperty } from '../../shared/models/domainProperty';

export const authFeatureKey = 'auth';

interface UserProperties {
  [key: string]: string;
}

export interface AuthState {
  isLoading: boolean;
  error: string;
  currentUser: User;
  availablesDomains: Domain[];
  currentDomain: Domain;
  currentDomainProperties: DomainProperty[];
  userRoles: UserRoles['Roles'];
  jwtToken: string;
}

export const initialState: AuthState = {
  isLoading: false,
  error: null,
  currentUser: null,
  availablesDomains: null,
  currentDomain: null,
  currentDomainProperties: null,
  userRoles: null,
  jwtToken: null
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, action) => ({ ...state, currentUser: action.Output.User, jwtToken: action.Bearer, error: null })),
  on(AuthActions.setError, (state, action) => ({ ...state, currentUser: null, error: action.error })),
  on(AuthActions.setAvailablesDomains, (state, domains) => ({ ...state, availablesDomains: domains.domains, error: null })),
  on(AuthActions.setUserDomain, (state, action) => ({ ...state, currentDomain: action.domain, jwtToken: action.bearer, error: null })),
  on(AuthActions.setUserRoles, (state, action) => ({ ...state, userRoles: action.roles, error: null })),
  on(AuthActions.loadCurrentDomainProperties, (state, action) => ({ ...state, currentDomainProperties: action.properties, error: null })),
  on(AuthActions.setUserInfo, (state, action) => ({ ...state, currentUser: action, error: null })),
  on(AuthActions.toggleDarkTheme, (state, action) => ({
    ...state, currentUser: {
      ...state.currentUser,
      Properties: {
        ...state.currentUser.Properties,
        ['DarkThemeActive']: action.darkModeChange, error: null
      }
    }
  })),
  on(AuthActions.changeProfileImage, (state, action) => ({
    ...state, currentUser: {
      ...state.currentUser,
      Properties: {
        ...state.currentUser.Properties,
        ['ProfileImage']: action.base64Image, error: null
      }
    }
  })),
  on(AuthActions.changeProfileLanguage, (state, action) => ({
    ...state, currentUser: {
      ...state.currentUser,
      Properties: {
        ...state.currentUser.Properties,
        ['FavouriteLanguage']: action.favouriteLanguage, error: null
      }
    }
  })),
  on(AuthActions.changeProfilePhoneNumber, (state, action) => ({
    ...state, currentUser: {
      ...state.currentUser,
      Properties: {
        ...state.currentUser.Properties,
        ['PhoneNumber']: action.phoneNumber, error: null
      }
    }
  })),
  on(AuthActions.changeProfileNotes, (state, action) => ({
    ...state, currentUser: {
      ...state.currentUser,
      Properties: {
        ...state.currentUser.Properties,
        ['ProfileNotes']: action.notes, error: null
      }
    }
  })),



  on(AuthActions.logout, () => ({ ...initialState })),
  on(AppActions.resetReducers, () => ({ ...initialState })),
);

export const selectError = (state: AuthState): string => state.error;
export const selectUser = (state: AuthState): User => state.currentUser;
export const selectAvailablesDomains = (state: AuthState): Domain[] => state.availablesDomains;
export const selectDomain = (state: AuthState): Domain => state.currentDomain;
export const selectUserRoles = (state: AuthState): UserRoles['Roles'] => state.userRoles;
export const selectUserProperties = (state: AuthState): UserProperties => state.currentUser?.Properties;
export const selectDarkThemeStatus = (state: AuthState) => state.currentUser?.Properties.DarkThemeActive;
export const selectCurrentDomainLogo = (state: AuthState) => state.currentDomainProperties?.find(function (x) { return x.Key === 'Logo' })?.Value;
export const selectCurrentDomainProperties = (state: AuthState) => state.currentDomainProperties;
//export const selectUserProfileImage = (state: AuthState) => state.currentUser?.Properties.ProfileImage;
export const isAuth = (state: AuthState): boolean => !!state.currentUser;
export const selectJwtToken = (state: AuthState) : string => state.jwtToken;
//export const isAuth = (state: AuthState): boolean => !!state.currentUser && !!state.currentToken;
