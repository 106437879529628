<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'NEWS.TITLE' | translate }}
        </span>

        <button mat-button type="submit" class="btn actionButton"
                (click)="addNews()" *ngIf="isAdmin">
            {{ 'GENERAL.ADD'| translate }}
        </button>
    </div>

    <div class="noTableHover customTable">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100" mat-table [dataSource]="filteredData | paginate: config">
                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.DATE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Date*1000 | date : 'short' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> {{ 'NEWS.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Created*1000 | date : 'short' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Body">
                    <th mat-header-cell *matHeaderCellDef style="width: auto"> {{ 'NEWS.BODY' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="link" (click)="seeMore(element)">
                            {{ element.Title.slice(0,maxLength) }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Source">
                    <th mat-header-cell *matHeaderCellDef> {{ 'NEWS.SOURCE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <a href="{{element.Source}}" target="_blank">
                            <span id="sourceShown">{{ element.Source.slice(0,maxLength) }}</span>
                            <span *ngIf="element.Source.length >= maxLength">...</span></a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center p-1">
                        <i matTooltip="{{'GENERAL.DETAIL' | translate }}" class="iconInfoButton urbanicon-edit"
                            (click)="editNews(item)" *ngIf="isAdmin">
                        </i>
                        <i matTooltip="{{'GENERAL.DELETE' | translate }}" class="iconDeleteButton urbanicon-delete"
                            (click)="deleteNews(item.Id)" *ngIf="isAdmin">
                        </i>
                    </td>
                </ng-container>
            </table>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
