<div class="container-fluid">
    <div
        class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'ADVERTISING_LIST.ADVERTISINGS' | translate }}
        </span>

        <div class="d-flex flex-row">
            <button  *ngIf="isAdmin" mat-button class="btn outlineActionButton"
                (click)="goToAdvertisingEvents()">
                {{ 'ADVERTISING_LIST.VIEW_ADV_EVENTS' | translate }}
            </button>

            <button mat-button class="btn actionButton ml-2"
                [disabled]="services.length === 0" (click)="addAdvertising()">
                {{ 'GENERAL.ADD' | translate }}
            </button>
        </div>

    </div>

    <urban-searchbar id="intro-domain-device-events-search" [passedMaxDaysRange]="3" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24">
    </urban-searchbar>

    <div class="customTable">
        <div class="d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input #filterInput matInput (keyup)="applyFilter($event.target.value)"
                    (keyup.enter)="actualFilter !== '' ? [config.currentPage = 1, newSearch()] : {}"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="applyFilter('')"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>

            <form [formGroup]="filterForm" class="d-flex flex-row flex-wrap filtersGap">
                <mat-form-field *ngIf="services?.length > 0" class="customSelect noBottom" appearance="fill">
                    <mat-label> {{ 'ADVERTISING_LIST.SERVICE' | translate }} </mat-label>
                    <mat-select formControlName="service">
                        <mat-option *ngFor="let service of services" [value]="service.Id">
                            {{ service.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>

        <div class="tableHeight">
            <table class="w-100" mat-table [dataSource]="advertisings | paginate: config">
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.TYPE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Type.Name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Dates">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADVERTISING_LIST.DATE_RANGE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ (element.StartDate * 1000) | date: 'dd/MM/yy' }} - {{ (element.EndDate * 1000) | date: 'dd/MM/yy' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Time range">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.TIME_RANGE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.StartTime.hours }}:{{ element.StartTime.minutes }} - {{ element.EndTime.hours }}:{{ element.EndTime.minutes }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ (element.Created * 1000) | date: 'dd/MM/yy' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Count">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADVERTISING_LIST.VIEWS' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.ViewsCount }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Service">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADVERTISING_LIST.SERVICE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Service.Name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 10%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i *ngIf="isAdmin" matTooltip="{{'GENERAL.ADD_EVENT' | translate }}"
                                (click)="addAdvertisingEvent(element.Id)" class="iconInfoButton urbanicon-add">
                            </i>
                            <i matTooltip="{{'GENERAL.EDIT' | translate }}"
                                (click)="updateAdvertising(element)" class="iconInfoButton urbanicon-edit">
                            </i>
                            <i matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="deleteAdvertising(element.Id)" class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: columns;"></tr>
            </table>
        </div>

        <div class="d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{ (config.totalItems / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage() }}
                    {{ (config.totalItems / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase() }}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage" (valueChange)="paginatorOnShownChange()">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
