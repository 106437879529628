import { PassDataService } from './../../../../shared/services/pass-data/pass-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SMSSDomain } from '../../../../shared/models/smss/smssDomain';
import { SMSSGateway } from '../../../../shared/models/smss/smssGateway';
import { WifiSmssService } from '../../../../shared/services/wifi-smss.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { WalledGardenDialogComponent } from '../../../../shared/dialogs/walled-garden-dialog/walled-garden-dialog.component';
import { SMSSWpWalledGarden } from '../../../../shared/models/smss/smssWpWalledGarden';
import { SMSSWpTemplate } from '../../../../shared/models/smss/smssWpTemplate';

@Component({
  selector: 'urban-gateways',
  templateUrl: './gateways.component.html',
  styleUrls: ['./gateways.component.scss']
})
export class GatewaysComponent implements OnInit, OnDestroy {
  public gateways: SMSSGateway[] = [];
  public displayedGatewaysColumns = ['GatewayName', 'HotspotIPAddress', 'HotspotNetMask', 'Id', 'Detail'];
  public myGatewaysDataSource: MatTableDataSource<SMSSGateway>;
  public walledGardens: SMSSWpWalledGarden[] = [];
  public displayedWalledGardensColumns = ['Id', 'DestHost', 'DestHostPort', 'Detail'];
  public wpWalledGardensDataSource: MatTableDataSource<SMSSWpWalledGarden>;

  public wpTemplates: SMSSWpTemplate[] = [];
  public displayedwpTemplateColumns = ['Id', 'Code', 'Detail'];
  public wpTemplatesDataSource: MatTableDataSource<SMSSWpTemplate>;

  public currentDomain: SMSSDomain;
  public mapOpened: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isDarkModeActive: boolean;

  constructor(
    private router: Router,
    private wifiSMSSService: WifiSmssService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService
  ) { }

  ngOnInit(): void {
    this._initialize();
  }

  openGatewaysMap(): void {
    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.mapOpened = mapLoading;
    });
  }

  private _initialize(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkModeActive = res == true;
    });

    this.wifiSMSSService.getCurrentDomain().pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if(response){
        this.currentDomain = response.Domain;
        this.gateways = response.Gateways;
        this.myGatewaysDataSource = new MatTableDataSource(this.gateways)

        this.wifiSMSSService.getWpWalledGardens(this.currentDomain.id, '').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          this.walledGardens = res;
          this.wpWalledGardensDataSource = new MatTableDataSource(this.walledGardens)
        })

        this.wifiSMSSService.getWpTemplates(this.currentDomain.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          if (res) {
            this.wpTemplates = res;
            this.wpTemplatesDataSource = new MatTableDataSource(this.wpTemplates)
          }
        })
      }
    })
  }

  closeGatewaysMap(): void {
    this.mapOpened = false;
  }

  back(): void {
    this.router.navigate(['main/net-wifi/domains', { id: this.currentDomain.ManagerID }]);
  }

  detail(gateway: SMSSGateway) {
    if (!gateway || gateway === undefined) {
      return;
    }

    this.router.navigate(['main/gateway', { id: gateway.id, manager: gateway.ManagerID, domain: gateway.DomainID }])
  }

  add() {
    if (!this.currentDomain || this.currentDomain === undefined) {
      return;
    }

    this.router.navigate(['main/gateway', { manager: this.currentDomain.ManagerID, domain: this.currentDomain.id }])
  }

  delete(gateway: SMSSGateway) {
    if (!gateway || gateway === undefined) {
      return;
    }

    const deleteGatewayConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteGatewayConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.wifiSMSSService.gatewayDelete(gateway.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            if (x) {
              let message = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
              this.mainService.setSuccessMessageComand(message);
              this._initialize();
            }
          }
        });
      }
    });
  }

  addWalledGarden(): void {
    const addDialogRef = this.dialog.open(WalledGardenDialogComponent, {
      data: {
        domain: this.currentDomain,
        gateway: null
      },
      width: '50%',
      maxWidth: 500
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.wifiSMSSService.wpWalledGardenWrite(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
                this.mainService.setSuccessMessageComand(message);
                if (result.Error !== null && result.Error !== undefined) {
                  this._initialize();
                }
              }
            });
          }
        });
      }
    });
  }

  walledGardenDetail(walledGarden: SMSSWpWalledGarden): void {
    if (!walledGarden || walledGarden === null || walledGarden === undefined) {
      return;
    }

    const detailDialogRef = this.dialog.open(WalledGardenDialogComponent, {
      data: {
        walledGarden: walledGarden,
        domain: this.currentDomain,
        gateway: null
      },
      width: '50%',
      maxWidth: 500
    });

    detailDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.wifiSMSSService.wpWalledGardenWrite(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
                this.mainService.setSuccessMessageComand(message);
                if (result.Error !== null && result.Error !== undefined) {
                  this._initialize();
                }
              }
            });
          }
        });
      }
    });
  }

  walledGardenDelete(walledGarden: SMSSWpWalledGarden) {
    if (!walledGarden || walledGarden === undefined) {
      return;
    }

    const deleteWalledGardenConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteWalledGardenConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.wifiSMSSService.wpWalledGardenDelete(walledGarden.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            if (x) {
              let message = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
              this.mainService.setSuccessMessageComand(message);
              this._initialize();
            }
          }
        });
      }
    });
  }

  addWpTemplate(): void {
    this.router.navigate(['main/wptemplate', { domain: this.currentDomain.id }])
  }

  wpTemplateDetail(wpTemplate: SMSSWpTemplate): void {
    if (!wpTemplate || wpTemplate === null || wpTemplate === undefined) {
      return;
    }

    this.router.navigate(['main/wptemplate', { id: wpTemplate.id, domain: this.currentDomain.id }])
  }

  wpTemplateDelete(wpTemplate: SMSSWpTemplate) {
    if (!wpTemplate || wpTemplate === undefined) {
      return;
    }

    const deleteWalledGardenConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteWalledGardenConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.wifiSMSSService.wpTemplateDelete(wpTemplate.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            if (x) {
              let message = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
              this.mainService.setSuccessMessageComand(message);
              this._initialize();
            }
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
