<div (window:beforeunload)="onUnload()"></div>
<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span class="title2">
                {{ 'REMOTE_CONTROL_DETAIL.TITLE' | translate }}
            </span>
            <span *ngIf="currentDevice" class="title2 appearingTextRight">
                {{ ' - ' + currentDevice.Name }}
            </span>
        </div>
    </div>

    <div class="rc-table mat-elevation-z4">
        <table class="w-100 tableExpandHover" mat-table multiTemplateDataRows [dataSource]="myDataSource">

            <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> {{ 'REMOTE_CONTROL_DETAIL.DESCRIPTION' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
            </ng-container>

            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> {{ 'REMOTE_CONTROL_DETAIL.NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
            </ng-container>

            <ng-container matColumnDef="ServiceType">
                <th mat-header-cell class="text-right" *matHeaderCellDef> {{ 'REMOTE_CONTROL_DETAIL.SERVICE_TYPE' | translate }} </th>
                <td mat-cell class="text-right" *matCellDef="let element"> {{ element.ServiceType}} </td>
            </ng-container>

            <ng-container matColumnDef="ServiceIndex">
                <th mat-header-cell class="text-right" *matHeaderCellDef> {{ 'REMOTE_CONTROL_DETAIL.SERVICE_INDEX' | translate }} </th>
                <td mat-cell class="text-right" *matCellDef="let element"> {{element.ServiceIndex}} </td>
            </ng-container>

            <ng-container matColumnDef="Hash">
                <th mat-header-cell class="text-right" *matHeaderCellDef> {{ 'REMOTE_CONTROL_DETAIL.HASH' | translate }} </th>
                <td mat-cell class="text-right" *matCellDef="let element"> {{element.Hash}} </td>
            </ng-container>

            <ng-container matColumnDef="Unit">
                <th mat-header-cell class="text-right" *matHeaderCellDef style="width: 10%;"> {{ 'REMOTE_CONTROL_DETAIL.UNIT' | translate }} </th>
                <td mat-cell class="text-right" *matCellDef="let element"> {{element.Unit}} </td>
            </ng-container>

            <ng-container matColumnDef="State">
                <th mat-header-cell class="text-right" *matHeaderCellDef style="width: 10%;"> {{ 'REMOTE_CONTROL_DETAIL.STATE' | translate }} </th>
                <td mat-cell class="text-right" *matCellDef="let element"> {{element.State}} </td>
            </ng-container>


            <ng-container matColumnDef="EditState">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;"></th>
                <td mat-cell *matCellDef="let element" class="text-center pr-1">

                    <div *ngIf="element.Sensor?.Control =='slider'">
                        <mat-slider
                            class="my-slider pt-0 pb-0"
                            max="{{element.Sensor.MaxValue}}"
                            min="{{element.Sensor.MinValue}}"
                            step="{{element.Sensor.Step}}"
                            color = "primary"
                            thumbLabel=true
                            tickInterval=100
                            (change)="changeLight($event, element.ServiceType, element.Hash)"
                            value="{{element.State}}"
                        ></mat-slider>
                    </div>
                    <div *ngIf="element.Sensor?.Control =='toggle'">
                        <mat-slide-toggle *ngIf="element.State == 1" checked="true" (change)="toggleRelay($event, element.ServiceType, element.Hash)" color="primary"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="element.State == 0" (change)="toggleRelay($event, element.ServiceType, element.Hash)" color="primary"></mat-slide-toggle>
                    </div>

                </td>
            </ng-container>



            <!-- Expanded Content Column -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell style="border-color: #b7b7b7;" class="pt-0 pb-0"  *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="extended-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div fxFlex fxLayout="column" class=" p-4 col align-items-start justify-content-between">

                            <div class="singleRow justify-content-center">
                                <p class="font-weight-light pr-2">{{ 'REMOTE_CONTROL_DETAIL.NAME' | translate }}:</p>
                                <p>{{ element.Name }}</p>
                            </div>
                            <div class="singleRow justify-content-center">
                                <p class="font-weight-light pr-2">{{ 'REMOTE_CONTROL_DETAIL.SERVICE_TYPE' | translate }}:</p>
                                <p>{{ element.ServiceType }}</p>
                            </div>
                            <div class="singleRow justify-content-center">
                                <p class="font-weight-light pr-2">{{ 'REMOTE_CONTROL_DETAIL.SERVICE_INDEX' | translate }}:</p>
                                <p>{{ element.ServiceIndex }}</p>
                            </div>
                            <div class="singleRow justify-content-center">
                                <p class="font-weight-light pr-2">{{ 'REMOTE_CONTROL_DETAIL.HASH' | translate }}:</p>
                                <p>{{ element.Hash }}</p>
                            </div>

                        </div>
                    </div>



                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="extended-element-row"
                [class.extended-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="extended-detail-row"></tr>
        </table>
    </div>

    <br>
    <button type="button" mat-button class="btn actionButton" (click)="refresh()">{{ 'REMOTE_CONTROL_DETAIL.DETAIL_REFRESH' | translate }}</button>
</div>
