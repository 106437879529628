import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthState } from './auth.reducer';
import * as AuthActions from './auth.actions';
import * as MainActions from '../main/main.actions';
import { ApiService } from '../../shared/services/api.service';
import { of, EMPTY } from 'rxjs';
import { LoaderService } from '../../shared/services/loader/loader.service';
import { User } from '../../shared/models/loginResponse';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly store: Store<AuthState>,
    private readonly loaderService: LoaderService
  ) { }

  readonly tryLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.requestLogin),
      tap(() => this.loaderService.show()),
      exhaustMap(action =>
        this.apiService.login({ username: action.username, password: action.password, bearer: 'true' }).pipe(
          map(loginResponse => AuthActions.loginSuccess(loginResponse)),
          catchError(err => {
            this.loaderService.hide();
            return of(AuthActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  /* readonly getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      exhaustMap(() =>
        this.apiService.getToken().pipe(
          map(token => AuthActions.setToken({ token }),
            catchError(err => {
              this.loaderService.hide();
              return of(AuthActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
            })
          ))
      )
    ),
    { dispatch: true }
  ); */

  readonly logged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      exhaustMap(() =>
        this.apiService.getAvailableDomains().pipe(
          map((domains: any) => {
            const domainsAvailables = [];
            domains.forEach(domain => {
              domainsAvailables.push({ ...domain })
              if (domain.Childs.length > 0) {
                domain.Childs.forEach(childDomain => {
                  domainsAvailables.push({ ...childDomain })
                  if (childDomain.Childs.length > 0) {
                    childDomain.Childs.forEach(secondChildDomain => {
                      domainsAvailables.push({ ...secondChildDomain })
                      if (secondChildDomain.Childs.length > 0) {
                        secondChildDomain.Childs.forEach(thirdChildDomain => {
                          domainsAvailables.push({ ...thirdChildDomain })
                        })
                      }
                    })
                  }
                })
              }
            })
            return AuthActions.setAvailablesDomains({ domains: domainsAvailables })
          }),
          catchError(err => {
            this.loaderService.hide();
            return of(AuthActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  readonly domain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setAvailablesDomains),
      map((domains) => {
        var user: User;
        this.store.select(AuthSelectors.getUser).subscribe(res => user = res)

        if (user) {
          var domainId = user.Domain && user.Domain.Id ? user.Domain.Id : (user.Properties && user.Properties !== undefined ? user.Properties.CurrentDomain : undefined);

          if (domainId === undefined) {
            return AuthActions.setCurrentDomainProperty({ domain: domains.domains[0] });
          }
          else {
            var selectedDomain = domains.domains.find(x => x.Id == domainId);

            return AuthActions.initSetUserDomain(selectedDomain)
          }
        }
      }),
    ),
    { dispatch: true }
  );

  /* readonly multipleDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.multipleDomain),
      tap(() => this.router.navigate(['auth/choose-domain'])),
      tap(() => this.loaderService.hide())
    ),
    { dispatch: false }
  ); */

  readonly setCurrentDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setCurrentDomainProperty),
      tap(() => this.loaderService.show()),
      exhaustMap((domain) => {
        return this.apiService.addUserProperty({ Name: 'CurrentDomain', Value: domain.domain.Id }).pipe(
          switchMap(() => [
            AuthActions.initSetUserDomain(domain.domain)
          ]),
          catchError(err => {
            this.loaderService.hide();
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      }),
    ),
    { dispatch: true }
  );

  readonly setUserDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initSetUserDomain),
      tap(() => this.loaderService.show()),
      exhaustMap((domain) => {
        return this.apiService.setUserDomain(domain.Id).pipe(
          map((res) => AuthActions.setUserDomain({domain: domain, bearer: res.Bearer})),
          catchError(err => {
            this.loaderService.hide();
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      }),
    ),
    { dispatch: true }
  );

  readonly setUserInfo$ = createEffect(() => 
      this.actions$.pipe(
        ofType(AuthActions.initSetUserInfo),
        exhaustMap(() => {
          return this.apiService.getUserInfo().pipe(
            map((user) => AuthActions.setUserInfo(user)),
            catchError(err => {
              this.loaderService.hide();
              return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
            })
          )
        }),
      ),
      { dispatch: true }
  );

  readonly setUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setUserDomain),
      exhaustMap((setDomainRes) =>
        this.apiService.setUserRoles(setDomainRes.domain.Id).pipe(
          switchMap(res => [
            AuthActions.setUserRoles({ roles: res.Roles }),
            AuthActions.getCurrentDomainProperties({ domainId: setDomainRes.domain.Id }),
            AuthActions.initSetUserInfo(),
            MainActions.initDomainStyle(),
            //MainActions.initResources()
            //MainActions.checkTranslatorRole()
          ]),
          catchError(err => {
            this.loaderService.hide();
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  )

  readonly loadCurrentDomainProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCurrentDomainProperties),
      exhaustMap((result) =>
        this.apiService.getDomainProperties(result.domainId).pipe(
          switchMap(res => [
            AuthActions.loadCurrentDomainProperties({ properties: res }),
          ]),
          catchError(err => {
            this.loaderService.hide();
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );


  readonly logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      exhaustMap(() => {
        return this.apiService.tryLogout().pipe(
          switchMap(() => [
            MainActions.logout()
          ]),
        )
      })
    ),
    { dispatch: true }
  );

  readonly changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePassword),
      exhaustMap(res =>
        this.apiService.changePassword(res.changePasswordRequest).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(AuthActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly toggleDarkThemeState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.toggleDarkTheme),
      exhaustMap(res =>
        this.apiService.addUserProperty({ Name: 'DarkThemeActive', Value: res.darkModeChange }).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly changeProfileImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changeProfileImage),
      exhaustMap(res =>
        this.apiService.addUserProperty({ Name: 'ProfileImage', Value: res.base64Image }).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly changeProfileLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changeProfileLanguage),
      exhaustMap(res =>
        this.apiService.addUserProperty({ Name: 'FavouriteLanguage', Value: res.favouriteLanguage }).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly changeProfilePhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changeProfilePhoneNumber),
      exhaustMap(res =>
        this.apiService.addUserProperty({ Name: 'PhoneNumber', Value: res.phoneNumber }).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly changeUserPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changeUserPhoneNumber),
      exhaustMap(res =>
        this.apiService.addSpecificUserProperty(res.request).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly changeProfileNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changeProfileNotes),
      exhaustMap(res =>
        this.apiService.addUserProperty({ Name: 'ProfileNotes', Value: res.notes }).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );
}
