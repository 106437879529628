import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'urban-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialogComponent implements OnInit {

    public confirmMessage: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data:
          {
            message: string
          }
    ) {
    }

    continue() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }

    ngOnInit(): void {
      if(this.data && this.data.message){
        this.confirmMessage = this.data.message;
      }
    }

}
