import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConsumerDialogComponent } from 'src/app/shared/dialogs/consumer-dialog/consumer-dialog.component';
import { Property } from 'src/app/shared/models/Property';
import { Consumer } from 'src/app/shared/models/advertising/advertising';
import { ServiceConsumerRequest, SetConsumerPropertyRequest, UpdateConsumerRequest } from 'src/app/shared/models/advertising/consumerRequest';
import { ConsumerDetailResponse } from 'src/app/shared/models/advertising/consumerResponse';
import { GetServiceResponse, GetServiceTypeResponse, Service } from 'src/app/shared/models/service/service';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-consumer-detail',
  templateUrl: './consumer-detail.component.html',
  styleUrls: ['./consumer-detail.component.scss']
})
export class ConsumerDetailComponent implements OnInit {
  public consumer: Consumer;
  public services: Service[] = [];
  public filteredServicesData: Service[] = [];
  public filteredPropertiesData: Property[] = [];
  public dataChanged: boolean;
  public editMode: boolean = false;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required]
  });

  public servicesColumns = ['Name', 'Description', 'Actions'];
  public propertiesColumns = ['Key', 'Value', 'Actions'];
  public servicesActualFilter: string = '';
  public propertiesActualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public servicesConfig: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  public propertiesConfig: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private passDataService: PassDataService,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (!res.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      let consumerId: string;

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if(navInfo?.Id && navInfo?.Id !== '') {
          consumerId = navInfo.Id;
        }
        else {
          this.setErrorAndGoToMain('Access denied. Retry with proper navigation');
          return;
        }
      });

      this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
        const consumerValues = {
          name: this.consumer.Name,
          description: this.consumer.Description
        }

        this.dataChanged = false;

        for (let key in consumerValues) {
          if (consumerValues[key] !== values[key].trim()) {
            this.dataChanged = true;
            break;
          }
        }
      });

      this.loadData(consumerId);
    });
  }

  private loadData(consumerId: string): void {
    this.apiService.consumerDetail(consumerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: ConsumerDetailResponse) => {
      if (res?.Item) {
        this.consumer = res.Item;
        this.filteredServicesData = this.consumer.Services ? [...this.consumer.Services] : [];
        this.filteredPropertiesData = this.getConsumerPropertiesArray();

        this.form.setValue({
          name: this.consumer.Name,
          description: this.consumer.Description
        });
      } else {
        this.setErrorAndGoToMain('Could not find a consumer with passed ID');
      }
    });

    this.apiService.getServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceResponse) => {
      if (res?.Items?.length > 0) {
        this.services = res.Items.filter((service: Service) => service.ServiceType.Name === 'advertising');
      }
    });
  }

  public saveInfo(): void {
    if (this.form.valid) {
      const updateDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          let request: UpdateConsumerRequest = {
            Id: this.consumer.Id,
            Name: this.form.controls['name'].value.trim(),
            Description: this.form.controls['description'].value.trim()
          };

          this.form.setValue({
            name: request.Name,
            description: request.Description
          });

          this.apiService.updateConsumer(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.dataChanged = false;
              this.editMode = false;

              this.refreshData(this.consumer.Id);
            }, error: () => {}
          });
        }
      });
    }
  }

  public addServiceToConsumer(): void {
    const remainingServices = this.services.filter(service => {
      return !this.consumer.Services.some(present => {
        return present.Id === service.Id;
      });
    });

    const addServiceToConsumerDialogRef = this.dialog.open(ConsumerDialogComponent, {
      data: {
        services: remainingServices
      }
    });

    addServiceToConsumerDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newServiceId: string) => {
      if (newServiceId) {
        const addServiceConsumerConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addServiceConsumerConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            const addServiceConsumer: ServiceConsumerRequest = {
              Service: newServiceId,
              Consumer: this.consumer.Id
            };

            this.apiService.addServiceConsumer(addServiceConsumer).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.refreshData(this.consumer.Id);
              }, error: () => {}
            });
          }
        });
      }
    });
  }

  public setConsumerProperty(currentProperty?: Property): void {
    const consumerPropertyDialogRef = this.dialog.open(ConsumerDialogComponent, {
      data: currentProperty ? { property: currentProperty } :
      {
        properties: Object.keys(this.consumer.Properties).map((key: string) => {
          return {
            Key: key,
            Value: this.consumer.Properties[key]
          };
        })
      }
    });

    consumerPropertyDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newProperty: Property) => {
      if (newProperty) {
        const addPropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addPropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            const setConsumerPropertyRequest: SetConsumerPropertyRequest = {
              Consumer: this.consumer.Id,
              Key: newProperty.Key,
              Value: newProperty.Value
            };

            this.apiService.setConsumerProperty(setConsumerPropertyRequest)
              .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
                next: () => {
                  this.refreshData(this.consumer.Id);
                }, error: () => { }
              });
          }
        });
      }
    });
  }

  public removeServiceFromConsumer(serviceToDeleteId: string): void {
    const removeServiceConsumer = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    removeServiceConsumer.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        const removeServiceConsumerRequest: ServiceConsumerRequest = {
          Service: serviceToDeleteId,
          Consumer: this.consumer.Id
        };

        this.apiService.deleteServiceConsumer(removeServiceConsumerRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.refreshData(this.consumer.Id);
          }
        });
      }
    });
  }

  public removePropertyFromConsumer(propertyKey: string): void {
    const removePropertyFromConsumer = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    removePropertyFromConsumer.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        const removePropertyFromConsumerRequest: SetConsumerPropertyRequest = {
          Consumer: this.consumer.Id,
          Key: propertyKey,
          Value: null
        };

        this.apiService.setConsumerProperty(removePropertyFromConsumerRequest)
          .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.refreshData(this.consumer.Id);
            }
          });
      }
    });
  }

  private refreshData(consumerid: string): void {
    this.apiService.consumerDetail(consumerid).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: ConsumerDetailResponse) => {
      if (res?.Item) {
        this.consumer = res.Item;
        this.filteredServicesData = this.consumer.Services ? this.consumer.Services : [];
        this.filteredPropertiesData = this.getConsumerPropertiesArray();
      }
    });
  }

  public enableEdit(): void {
    this.editMode = true;
  }

  public cancelEdit(): void {
    this.form.setValue({
      name: this.consumer.Name,
      description: this.consumer.Description
    });

    this.dataChanged = false;
    this.editMode = false;
  }

  public applyFilter(event: KeyboardEvent, data: string): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue, data);
  }

  public applyFilterString(filterValue: string, data: string): void {
    if (data === 'servicesData') {
      this.filteredServicesData = this.consumer.Services?.filter((service: Service) =>
        [service.Name?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase()))
      );
      this.servicesConfig.currentPage = 1;
    }
    else if (data === 'propertiesData') {
      this.filteredPropertiesData = Object.keys(this.consumer.Properties)?.filter((key: string) =>
        [
          key?.toLowerCase(),
          this.consumer.Properties[key].toLowerCase()
        ].some((field: string) => field?.includes(filterValue.toLowerCase()))
      ).map((key: string) => {
        return {
          Key: key,
          Value: this.consumer.Properties[key]
        }
      });
      this.propertiesConfig.currentPage = 1;
    }
  }

  public paginatorOnPageChange(number: number, data: string): void {
    if (data === 'servicesData') {
      this.servicesConfig.currentPage = number;
    }
    else if (data === 'propertiesData') {
      this.propertiesConfig.currentPage = number;
    }
  }

  public paginatorGetMaxPage(data: string): number {
    if (data === 'servicesData') {
      let maxPage: number = this.filteredServicesData.length / this.servicesConfig.itemsPerPage;
      maxPage = Math.ceil(maxPage);

      return maxPage;
    }
    else if (data === 'propertiesData') {
      let maxPage: number = this.filteredPropertiesData.length / this.propertiesConfig.itemsPerPage;
      maxPage = Math.ceil(maxPage);

      return maxPage;
    }
  }

  public getConsumerPropertiesArray(): Property[] {
    return this.consumer.Properties ?
    Object.keys(this.consumer.Properties).map((key: string) => {
      return {
        Key: key,
        Value: this.consumer.Properties[key]
      }
    }) : [];
  }

  public goToServiceDetail(serviceId: string): void {
    this.mainService.setNavigationInfoComand({Id: serviceId, BackRoute: 'consumer-detail'});
    this.router.navigate(['main/service-detail']);
  }

  private setErrorAndGoToMain(error: string): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand(error);
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
