import { takeUntil } from 'rxjs/operators';
import { MultiSynchronizer } from './../models/synchronizer';
import { Injectable } from '@angular/core';
import { Subject, forkJoin, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiSynchronizerService {
  private contextNumber: number;
  private syncs: MultiSynchronizer;
  private multiContext: Boolean;

  constructor() {
    this.contextNumber = 0;
    this.syncs = {};
    this.multiContext = false;
  }

  //Sequence: initialize(), addFeatures() (also called more than once), waitFeaturesAndThen(), loadedFeature/failedFeature(), abort() in ngOnDestroy()
  //contextNumber allows to differentiate between more requests

  //it must be called once at the start of the service use
  public initialize(): number {
    if(Object.keys(this.syncs).length > 0 && !this.multiContext) {
      this.multiContext = true;
    }
    const contextNumber = this.contextNumber;
    // console.log(contextNumber+": initializing...");
    this.contextNumber++;
    this.syncs[contextNumber] = {
      areFeaturesReady: [],
      featuresNumber: 0,
      waiting: false,
      data: {},
      unsubscriber: new Subject()
    }
    // this.showSyncs(contextNumber+": initialized", this.syncs, contextNumber);
    return contextNumber;
  }

//it can be called multiple times. In any case its sequence must start after initialize() and end before waitFeaturesAndThen()
  public addFeatures(featuresNumber: number, contextNumber?: number): void {
    if(contextNumber === undefined) {
      if(this.multiContext) {
        this.resetAll();
        console.warn(`Synchronizations aborted. In a multiple-context scenario parameter "contextNumber" must always be defined. Maybe api synchronizer was not aborted during its process`);
        return;
      }
      else {
        contextNumber = 0;
      }
    }
    if(this.syncs[contextNumber] === undefined || this.syncs[contextNumber] === null) {
      console.warn(`Context ${contextNumber}: No feature has been added. ApiSynchronizerService has to be initialized first.`);
      return;
    }
    if(this.syncs[contextNumber].waiting) {
      this.reset(contextNumber);
      console.warn(`Context ${contextNumber}: Synchronization aborted. Features can't be added after waiting started.`);
      return;
    }
    // console.log(contextNumber+": addFeatures "+featuresNumber);

    for(let i: number = 0; i < featuresNumber; i++) {
      this.syncs[contextNumber].areFeaturesReady.push(new Subject<boolean>());
      this.syncs[contextNumber].featuresNumber++;
    }
    // this.showSyncs(contextNumber+": features added", this.syncs, contextNumber);
  }

  //it waits for features loading. Specify what happens then
  //data saved can be used in the callback function
  public waitFeaturesAndThen(afterComplete: (results: Boolean[], data?: any) => void, contextNumber?: number): void {
    if(contextNumber === undefined) {
      if(this.multiContext) {
        this.resetAll();
        console.warn(`Synchronizations aborted. In a multiple-context scenario parameter "contextNumber" must always be defined. Maybe api synchronizer was not aborted during its process`);
        return;
      }
      else {
        contextNumber = 0;
      }
    }
    if(this.syncs[contextNumber] === undefined || this.syncs[contextNumber] === null) {
      console.warn(`Context ${contextNumber}: Synchronization aborted. No waiting ongoing. Api Synchronizer has to be initialized first.`);
      return;
    }
    if(this.syncs[contextNumber]?.featuresNumber < 1) {
      this.reset(contextNumber);
      console.warn(`Context ${contextNumber}: Synchronization aborted. No waiting ongoing. At least one feature has to be added.`);
      return;
    }
    // console.log(contextNumber+": waiting");

    this.syncs[contextNumber].waiting = true;
    forkJoin(this.syncs[contextNumber].areFeaturesReady).pipe(takeUntil(this.syncs[contextNumber].unsubscriber)).subscribe((checkValues: Boolean[]) => {
      // console.log(contextNumber+": waiting just finished");
      timer(0).subscribe(() => {
        if(this.syncs[contextNumber]) {
          if(this.syncs[contextNumber].data) {
            if(this.multiContext) {
              afterComplete(checkValues, this.syncs[contextNumber].data);
            }
            else {
              afterComplete(checkValues, this.syncs[contextNumber].data);
            }
          }
          else {
            afterComplete(checkValues);
          }
        }
        this.reset(contextNumber);
      });
    });
    // this.showSyncs(contextNumber+": waiting", this.syncs, contextNumber);
  }

  //it must be called when a feature is loaded. No effect if waitFeaturesAndThen() is not called before
  public loadedFeature(contextNumber?: number): number {
    if(contextNumber === undefined) {
      if(this.multiContext) {
        this.resetAll();
        console.warn(`Synchronizations aborted. In a multiple-context scenario parameter "contextNumber" must always be defined. Maybe api synchronizer was not aborted during its process`);
        return -1;
      }
      else {
        contextNumber = 0;
      }
    }
    if(this.syncs[contextNumber] === undefined || this.syncs[contextNumber] === null) {
      console.warn(`Context ${contextNumber}: Synchronization aborted. Features' load has no effect until they are expected through addFeatures().`);
      return;
    }
    if(!this.syncs[contextNumber].waiting) {
      this.reset(contextNumber);
      console.warn(`Context ${contextNumber}: Synchronization aborted. Features' load has no effect until they are awaited correctly`);
      return -1;
    }

    this.syncs[contextNumber].featuresNumber--;
    // this.showSyncs(contextNumber+"."+this.syncs[contextNumber].featuresNumber+": loaded", this.syncs, contextNumber);
    this.syncs[contextNumber].areFeaturesReady[this.syncs[contextNumber].featuresNumber].next(true);
    this.syncs[contextNumber].areFeaturesReady[this.syncs[contextNumber].featuresNumber].complete();
    return this.syncs[contextNumber].featuresNumber;   //if the calling component needs to check if a specific feature api succeeded or not
  }

  //same as loadedFeature() but some data can be passed for using it once all features' loading finished
  public loadedFeatureWithData(data: any, contextNumber?: number) {
    if(contextNumber === undefined) {
      if(this.multiContext) {
        this.resetAll();
        console.warn(`Synchronizations aborted. In a multiple-context scenario parameter "contextNumber" must always be defined. Maybe api synchronizer was not aborted during its process`);
        return -1;
      }
      else {
        contextNumber = 0;
      }
    }
    if(this.syncs[contextNumber] === undefined || this.syncs[contextNumber] === null) {
      console.warn(`Context ${contextNumber}: Synchronization aborted. Features' load has no effect until they are expected through addFeatures().`);
      return;
    }
    if(!this.syncs[contextNumber].waiting) {
      this.reset(contextNumber);
      console.warn(`Context ${contextNumber}: Synchronization aborted. Features' load has no effect until they are awaited correctly`);
      return -1;
    }

    this.syncs[contextNumber].featuresNumber--;
    if(data !== null && data !== undefined) {
      this.syncs[contextNumber].data[this.syncs[contextNumber].featuresNumber] = data;
    }
    // this.showSyncs(contextNumber+"."+this.syncs[contextNumber].featuresNumber+": loaded", this.syncs, contextNumber);
    this.syncs[contextNumber].areFeaturesReady[this.syncs[contextNumber].featuresNumber].next(true);
    this.syncs[contextNumber].areFeaturesReady[this.syncs[contextNumber].featuresNumber].complete();
    return this.syncs[contextNumber].featuresNumber;   //if the calling component needs to check if a specific feature api succeeded or not
  }

  //it must be called when a feature fails to load. No effect if waitFeaturesAndThen() is not called before
  public failedFeature(contextNumber?: number): number {
    if(contextNumber === undefined) {
      if(this.multiContext) {
        this.resetAll();
        console.warn(`Synchronizations aborted. In a multiple-context scenario parameter "contextNumber" must always be defined. Maybe api synchronizer was not aborted during its process`);
        return -1;
      }
      else {
        contextNumber = 0;
      }
    }
    if(this.syncs[contextNumber] === undefined || this.syncs[contextNumber] === null) {
      console.warn(`Context ${contextNumber}: Synchronization aborted. Features' failure has no effect until they are expected through addFeatures().`);
      return;
    }
    if(!this.syncs[contextNumber].waiting) {
      this.reset(contextNumber);
      console.warn(`Context ${contextNumber}: Synchronization aborted. Features' failure has no effect until they are awaited correctly`);
      return -1;
    }

    this.syncs[contextNumber].featuresNumber--;
    // this.showSyncs(contextNumber+"."+this.syncs[contextNumber].featuresNumber+": failed", this.syncs, contextNumber);
    this.syncs[contextNumber].areFeaturesReady[this.syncs[contextNumber].featuresNumber].next(false);
    this.syncs[contextNumber].areFeaturesReady[this.syncs[contextNumber].featuresNumber].complete();
    return this.syncs[contextNumber].featuresNumber;   //if the calling component needs to check if a spacific feature api succeeded or not
  }

  private reset(contextNumber: number) {
    if(this.syncs[contextNumber] !== undefined) {
      delete this.syncs[contextNumber];
      if(Object.keys(this.syncs).length === 0) {
        this.multiContext = false;
        this.contextNumber = 0;
      }
    }
  }

  private resetAll(): void {
    Object.keys(this.syncs).forEach((context: string) => {
      if(this.syncs[context]?.unsubscriber !== undefined) {
        this.syncs[context].unsubscriber.next();
        this.syncs[context].unsubscriber.complete();
      }
    });

    this.syncs = {};
    this.multiContext = false;
    this.contextNumber = 0;
  }

  public abort(): void {
    this.resetAll();
  }

  private showSyncs(phrase: string, sync: MultiSynchronizer, num: number): void {
    console.log(
      phrase
      +"\n featuresNum: " + sync[num].featuresNumber
      +"\n waiting: " + sync[num].waiting
      +"\n\n contextNumber: " + this.contextNumber
      +"\n multiContext: " + this.multiContext
    );
  }
}
