<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput formControlName="description"
                      matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=20 autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.LANGUAGE' | translate }}</mat-label>
            <mat-select formControlName="language">
                <mat-option *ngFor="let language of languages" value={{language.Id}}>
                    {{language.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'CONTENT_MANAGEMENT.BROADCAST_CLIENT' | translate }}</mat-label>
            <mat-select formControlName="broadcastClient">
                <mat-option *ngFor="let broadcastClientElement of broadcastClients" value={{broadcastClientElement.Id}}>
                    {{broadcastClientElement.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
