import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DeviceNotification} from "../../models/device-command/deviceNotification";
import {DeviceCommandJson} from "../../models/device-command/deviceCommandJson";

@Component({
  selector: 'urban-device-notification-detail-dialog',
  templateUrl: './device-notification-detail-dialog.component.html',
  styleUrls: ['./device-notification-detail-dialog.component.scss']
})
export class DeviceNotificationDetailDialogComponent implements OnInit {

  myDeviceNotification: DeviceNotification = {
    Id: "",
    DeviceId: "",
    DeviceCommandId: "",
    Created: null,
    Json: ""
  }

  public deviceCommandJson: DeviceCommandJson;

  public showBody : boolean;

  public showError: boolean;

  public detailGroup: UntypedFormGroup = this.formBuilder.group({
    id: [{value: '', disabled: false}],
    deviceId: [{value: '', disabled: false}],
    deviceCommandId: [{value: '', disabled: false}],
    deviceCommandType : [{value: '', disabled: false}],
    created: [{value: '', disabled: false}]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<DeviceNotificationDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { targetNotification: DeviceNotification }
  ) {
    this.myDeviceNotification = data.targetNotification;
  }

  ngOnInit(): void {
    if (this.myDeviceNotification.Id) this.detailGroup.controls.id.setValue(this.myDeviceNotification.Id);
    if (this.myDeviceNotification.DeviceId) this.detailGroup.controls.deviceId.setValue(this.myDeviceNotification.DeviceId);
    if (this.myDeviceNotification.Created) this.detailGroup.controls.created.setValue(this.datePipe.transform(this.myDeviceNotification.Created * 1000, 'short'));
    if (this.myDeviceNotification.DeviceCommandId) this.detailGroup.controls.deviceCommandId.setValue(this.myDeviceNotification.DeviceCommandId);
    if (this.myDeviceNotification.DeviceCommandType) this.detailGroup.controls.deviceCommandType.setValue(this.myDeviceNotification.DeviceCommandType.Name);
    if (this.myDeviceNotification.Json) {
      this.deviceCommandJson = JSON.parse(this.myDeviceNotification.Json);

      this.showError = this.deviceCommandJson.Error !== undefined && this.deviceCommandJson.Error !== null && this.deviceCommandJson.Error !== "\n";

      this.showBody = this.deviceCommandJson.Body !== undefined && this.deviceCommandJson.Body !== null;
    }
  }
}
