<mat-dialog-content class="position-relative d-flex flex-column streamingDialog">
    <h1 mat-dialog-title>{{ 'CAMERA.ADD' | translate }}</h1>
    <p class="secondaryTextColor mb-0">{{ 'ADD_STREAMING_CAMERA.ADD_CAMERA' | translate }}</p>

    <mat-list class="overflow-auto mt-4">
        <mat-radio-group aria-labelledby="streaming-camera" color="primary" class="d-flex flex-column"
            (change)="radioChange($event)">
            <mat-radio-button *ngFor="let camera of cameras" [value]="camera">
                {{camera.Name}}
            </mat-radio-button>
        </mat-radio-group>
    </mat-list>


</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button class="btn actionButton" (click)="addCamera()">{{ 'CAMERA.ADD' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate}}</button>
</div>