<div class="container-fluid h-100 notesContainer">
    <div class="d-flex justify-content-between pageTop">
        <h3 class="title3">Note</h3>
        <button type="button" mat-button class="btn actionButton" (click)="addNote()" [disabled]="temporaryNotes.length === MAX_NOTES_LIMIT">{{ 'NOTE_WIDGET.ADD_NOTE' | translate }}</button>
    </div>

    <div class="d-flex justify-content-start flex-wrap" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <div class="customColumn" *ngFor="let note of temporaryNotes">
            <mat-card class="stickyNote" [ngClass]="note.color+'-note-bg'" cdkDrag [cdkDragDisabled]="!dragEnabled">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <div class="d-flex flex-row">
                        <div class="handlerNote" cdkDragHandle *ngIf="activeNote !== note.id" (mousedown)="onMouseDown()">
                            <i class="urbanicon-omnidirection"></i>
                        </div>
                        <input class="noteTitleInput paragraphText"
                            [(ngModel)]="note.title" maxlength="20"
                            placeholder="{{ 'NOTE_WIDGET.INSERT_TITLE' | translate }}" (input)="addModifiedNote(note.id)"
                            autocomplete="off" (focusin)="activeNote = note.id" />
                        <div class="removerNote">
                            <i class="urbanicon-close" (click)="deleteNote(note.id)"></i>
                        </div>
                    </div>
                </div>

                <mat-card-content class="d-flex flex-column justify-content-center mt-2">
                    <textarea class="paragraphText" [ngClass]="{'activeTextArea': activeNote === note.id}" #txt
                        (focusout)="editContentNote(note.id, txt.value)" (focusin)="activeNote = note.id"
                        (input)="addModifiedNote(note.id)"
                        [value]="note.content"
                        placeholder="{{ 'NOTE_WIDGET.INSERT_TEXT' | translate }}">
                    </textarea>

                    <i class="urbanicon-alert unsavedNote pt-2" *ngIf="touchedNotes.includes(note.id) && activeNote !== note.id"
                        matTooltip="{{'NOTE_WIDGET.UNSAVED' | translate }}">
                    </i>
                    <div class="d-flex justify-content-end mt-2 align-items-center" *ngIf="activeNote === note.id">
                        <div class="colorButtons">
                            <button *ngFor="let color of colors; let i= index" [ngClass]="i === note.colorActive  ? 'activeNote' : ''" (click)="changeColorNote(color, note.id, i)"></button>
                        </div>
                        <div class="ml-auto">
                            <span class="link linkText" (click)="updateNotesArray(note.id)">{{ 'GENERAL.EDIT' | translate}}</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
