import { ChartMultiDataList, DescribedValue, ChartDescribedDataList, ChartDataDescription } from './../../models/ChartDataList';
import { ChartDataList } from '../../models/ChartDataList';
import { takeUntil } from 'rxjs/operators';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'urban-google-columnchart',
  templateUrl: './google-columnchart.component.html',
  styleUrls: ['./google-columnchart.component.scss']
})
export class GoogleColumnchartComponent implements OnInit, OnChanges, OnDestroy {

  @Input('columnChartId') public chartDivId: string = '';
  @Input('columnChartTitle') public title: string = "";
  @Input('columnChartSubtitle') public subtitle: string;
  @Input('columnsTitle') private columnsTitle: string = "Elements";
  @Input('valuesDescription') private valuesDescription: string = "Values";
  @Input('multiValuesDescription') private multiValuesDescription: ChartDataDescription[] = [];
  @Input('passedChartData') private passedData: ChartDataList | ChartDescribedDataList;
  @Input('passedChartMultiData') private passedMultiData: ChartMultiDataList = {};
  @Input('passedVAxisTicks') private vAxisTicks: DescribedValue[];
  @Input('limitValue') private maxValue: number;
  @Input('isLimitedBelow') private limitedBelow: boolean = true;
  @Input('valuesUnit') private unit: string = '';
  @Input('alreadySorted') private dontSort: boolean = false;
  @Input('multipleColumns') private multiColumns: boolean = false;
  @Input('darkThemeActive') private darkThemeActive: boolean;
  @Input('hAxisTicksCanBeHidden') private hAxisTicksCanBeHidden: boolean = false;
  @Input('thinColumns') private areColumnsThin: boolean = false;
  @Input('heightPercent') private chartHeightPercent: number = 70;
  @Input('automaticPadding') public withPadding: boolean = true;
  @Input('chartMarginTop') private marginTop: number;

  @Output('chartDrawn') drawn: EventEmitter<any> = new EventEmitter();

  private translations: string[] = [];
  private chart: google.visualization.ColumnChart;
  private chartOptions: google.visualization.ColumnChartOptions;
  public translationsReady: boolean = false;
  public noDataOnChart: boolean = false;
  public wrongData: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private valuesDescribed: boolean = false;
  private googleChartLoaded: boolean = false;
  private lightPrimaryColor: string;
  private darkPrimaryColor: string;
  private chartColors: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  constructor(private translate: TranslateService) {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.onLoadedChartDraw.bind(this));
  }

  ngOnInit(): void {
    this.lightPrimaryColor = this.getColor('light', 'primary');
    this.darkPrimaryColor = this.getColor('dark', 'primary');
    this.chartColors = this.getChartColors();

    this.chartDivId = `divColumnChart${this.chartDivId}`;
    if(this.multiValuesDescription.length > 0) {
      if(this.maxValue !== undefined) {
        this.maxValue += 5;
      }
    }
    if(this.unit !== '' && this.unit !== '%') {
      this.unit = ` ${this.unit}`;
    }
    this.translations.push(this.columnsTitle, this.valuesDescription);
    this.getTranslations();
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
      this.tryDrawChart();
    });
  }

  tryDrawChart(): void {
    if(this.googleChartLoaded) {
      this.drawChart();
    }
  }

  onLoadedChartDraw(): void {
      this.googleChartLoaded = true;
      this.drawChart();
  }

  getTranslations(): void {
    this.translate.get(this.translations).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.columnsTitle = res[this.translations[0]];
      this.valuesDescription = res[this.translations[1]];
      this.translationsReady = true;
    });
  }

  drawChart(): void {
    if (this.passedMultiData && Object.keys(this.passedMultiData).length > 0 && this.multiValuesDescription.length == 0) {
      this.noDataOnChart = false;
      this.wrongData = true;

      if(this.chart) {
        this.chart.clearChart();
      }

      return;
    }

    if ((this.passedData && Object.keys(this.passedData).length > 0)
      || (this.passedMultiData && Object.keys(this.passedMultiData).length > 0 && this.multiValuesDescription.length > 0)) {
      this.noDataOnChart = false;
      this.wrongData = false;

      let myData = new google.visualization.DataTable();
      myData.addColumn('string', this.columnsTitle);

      if (this.multiValuesDescription.length === 0) {
        let checkKey: string = Object.keys(this.passedData)[0];

        this.valuesDescribed = (typeof this.passedData[checkKey]) !== 'number';

        myData.addColumn('number', this.valuesDescription);

        Object.keys(this.passedData).forEach((key: string, priority: number) => {
          let chartDescribedValue: any;
          if(this.valuesDescribed) {
            let passedData: ChartDescribedDataList = <ChartDescribedDataList> this.passedData;
            chartDescribedValue = { v: passedData[key].Value, f: passedData[key].Description }
          }
          else {
            let value: number = <number> this.passedData[key];
            chartDescribedValue = { v: value, f: `${value}${this.unit}` };
          }

          let chartAbscissa: any;

          if(this.dontSort) {
            chartAbscissa = { v: priority.toString(), f: key };
          }
          else {
            chartAbscissa = key;
          }

          myData.addRow([chartAbscissa, chartDescribedValue]);
        });
      }
      else {
        this.multiValuesDescription.forEach((singleValuesDescription) => {
          myData.addColumn('number', singleValuesDescription.Translated);
        });
        if (!this.multiColumns) {
          myData.addColumn({ role: 'annotation' });
        }
        Object.keys(this.passedMultiData).forEach((multiElementKey: string, priority: number) => {
          let chartAbscissa: any;
          if(this.dontSort) {
            chartAbscissa = { v: priority.toString(), f: multiElementKey };
          }
          else {
            chartAbscissa = multiElementKey;
          }
          let newRow: any[] = [chartAbscissa];
          let total: number = 0;
          this.multiValuesDescription.forEach((singleValueDescription) => {
            let value: number = this.passedMultiData[multiElementKey][singleValueDescription.Original];
            value = value !== undefined ? value : 0;
            total += value;
            let describedValue = { v: value, f: `${value}${this.unit}` };
            newRow.push(describedValue);
          });

          if (!this.multiColumns) {
            total = Math.round(total * 100) / 100;
            if(this.maxValue === undefined || this.maxValue < total + 5) {
              this.maxValue = total + 5;
            }
            newRow.push(`${total}${this.unit}`);
          }

          myData.addRow(newRow);
        });
      }

      let ticks: any[] = [];
      if(this.vAxisTicks) {
        this.vAxisTicks.forEach((oneTick: DescribedValue) => {
          ticks.push({ v: oneTick.Value, f: oneTick.Description });
        });
      }

      if (this.darkThemeActive) {
        this.chartOptions = {
          bar: {
            groupWidth: this.areColumnsThin ? 30 : undefined
          },
          height: 550,
          chartArea: {
            width: this.multiValuesDescription.length > 0 && !this.multiColumns ? '60%' : '80%',
            height: Math.min(this.chartHeightPercent, 80) + '%'
          },
          legend: {
            position: this.multiColumns ? 'none' : (this.multiValuesDescription.length > 0 ? 'right' : 'top'),
            textStyle: { color: 'white' }
          },
          isStacked: this.multiValuesDescription.length > 0 && !this.multiColumns ? true : false,
          backgroundColor: 'transparent',
          hAxis: {
            format: 'dd/MM/yyyy HH:mm',
            textStyle: {
              color: 'white',
              fontSize: 11.2
            },
            textPosition: 'out',
            title: this.columnsTitle,
            titleTextStyle: { color: 'white' },
            maxAlternation: this.hAxisTicksCanBeHidden ? 1 : null,
            showTextEvery: this.hAxisTicksCanBeHidden ? null : 1,
            slantedText: false
          },
          vAxis: {
            textStyle: { color: 'white' },
            viewWindow: {
              min: this.limitedBelow ? 0 : undefined,
              max: this.maxValue ? Number(this.maxValue) : null
            },
            format: this.unit !== '' ? `#'${this.unit}'` : '#',
            ticks: ticks.length > 0 ? ticks : null
          },
          series: {
            0: {
              color: this.darkPrimaryColor ? this.darkPrimaryColor : '#0971ce'
            },
            1: {
              color: this.chartColors[0].trim()
            },
            2: {
              color: this.chartColors[1].trim()
            },
            3: {
              color: this.chartColors[2].trim()
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          },
          annotations: {
            alwaysOutside: true,
            textStyle: {
              fontSize: 16,
              color: 'white',
              bold : true
            },
            stem: {
              color: 'transparent',
              length: 3
            }
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 4.0,
            maxZoomOut: 1.0
          },
        }

      } else {
        this.chartOptions = {
          bar: {
            groupWidth: this.areColumnsThin ? 30 : undefined
          },
          height: 550,
          chartArea: {
            width: this.multiValuesDescription.length > 0 && !this.multiColumns ? '60%' : '80%',
            height: Math.min(this.chartHeightPercent, 80) + '%',
            top: this.marginTop ? this.marginTop : undefined
          },
          legend: {
            position: this.multiColumns ? 'none' : (this.multiValuesDescription.length > 0 ? 'right' : 'top')
          },
          isStacked: this.multiValuesDescription.length > 0 && !this.multiColumns ? true : false,
          backgroundColor: 'transparent',
          hAxis: {
            format: 'dd/MM/yyyy HH:mm',
            textPosition: 'out',
            textStyle: { fontSize: 11.2 },
            title: this.columnsTitle,
            maxAlternation: this.hAxisTicksCanBeHidden ? 1 : null,
            showTextEvery: this.hAxisTicksCanBeHidden ? null : 1,
            slantedText: false
          },
          vAxis: {
            textStyle: { color: '#9F9F9F' },
            gridlines: { color: '#E0E2E7' },
            viewWindow: {
              min: this.limitedBelow ? 0 : undefined,
              max: this.maxValue ? Number(this.maxValue) : null
            },
            format: this.unit !== '' ? `#'${this.unit}'` : '#',
            ticks: ticks.length > 0 ? ticks : null
          },
          series: {
            0: {
              color: this.lightPrimaryColor ? this.lightPrimaryColor : '#0971ce'
            },
            1: {
              color: this.chartColors[0].trim()
            },
            2: {
              color: this.chartColors[1].trim()
            },
            3: {
              color: this.chartColors[2].trim()
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          },
          annotations: {
            alwaysOutside: true,
            textStyle: {
              fontSize: 16,
              color: 'black',
              bold : true
            },
            stem: {
              color: 'transparent',
              length: 3
            }
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 4.0,
            maxZoomOut: 1.0
          }
        }
      }

      let elem: Element = document.getElementById(this.chartDivId);
      if (elem !== null) {
        this.chart = new google.visualization.ColumnChart(elem);
        this.chart.draw(myData, this.chartOptions);
        this.drawn.emit();
      }
    }
    else {
      this.wrongData = false;
      this.noDataOnChart = true;
      if(this.chart) {
        this.chart.clearChart();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isDarkActive = changes['darkThemeActive'];
    if (isDarkActive?.currentValue !== isDarkActive?.previousValue && isDarkActive?.firstChange == false) {
      this.tryDrawChart();
    }
    if (changes['passedData']) {
      const updatedData = changes['passedData'];
      if (updatedData.currentValue !== updatedData.previousValue && updatedData.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['passedMultiData']) {
      const updatedMultiData = changes['passedMultiData'];
      if (updatedMultiData.currentValue !== updatedMultiData.previousValue && updatedMultiData.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['multiValuesDescription']) {
      const updatedTranslations = changes['multiValuesDescription'];
      if (updatedTranslations.currentValue !== updatedTranslations.previousValue && updatedTranslations.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['title']) {
      const updatedTitle = changes['title'];
      if (updatedTitle.currentValue !== updatedTitle.previousValue && updatedTitle.firstChange == false) {
        this.tryDrawChart();
      }
    }
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));

    return colors;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
