import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {ApiService} from "../../../shared/services/api.service";
import {PassDataService} from "../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil, first} from "rxjs/operators";
import {DeviceNotification} from "../../../shared/models/device-command/deviceNotification";
import {DeviceNotificationService} from "../../../shared/services/device-notification.service";
import {DeviceNotificationListRequest} from "../../../shared/models/device-command/deviceNotificationListRequest";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {Device} from "../../../shared/models/device";
import {
  DeviceNotificationDetailDialogComponent
} from "../../../shared/dialogs/device-notification-detail-dialog/device-notification-detail-dialog.component";
import { SearchDates } from 'src/app/shared/models/searchDates';

@Component({
  selector: 'urban-device-notifications',
  templateUrl: './device-notifications.component.html',
  styleUrls: ['./device-notifications.component.scss']
})
export class DeviceNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) private paginator: MatPaginator;

  public deviceNotifications: DeviceNotification[] = [];
  public displayedColumns: string[] = ['Id', 'Created', 'Device', 'Command', 'CommandType', 'Detail'];
  public deviceNotificationsDataSource: MatTableDataSource<DeviceNotification>;
  public pageEvent: PageEvent;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public startDate: number;
  public endDate: number;
  public myPageIndex: number = 0;
  public myTotalItems: number = 0;
  public myPageSize: number = 10;
  public lastPageSize: number;
  public deviceCommandId: string = '';
  public orderColumn: string = null;
  public orderDirection: string = null;
  public isOnlyDomainAdmin: boolean = false;
  public currentDevice: Device;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();

  public formDate: UntypedFormGroup = this.formBuilder.group({
    startDate: [{value: null, disabled: false}],
    endDate: [{value: null, disabled: false}]
  });

  constructor(
    private apiService: ApiService,
    private deviceNotificationApiService: DeviceNotificationService,
    private apiSync: ApiSynchronizerService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private passDataService: PassDataService,
    private translate: TranslateService,
    private router: Router,
    private mainService: MainSubscriptionsService,
  ) {}

  ngOnInit(): void {
    let deviceId: string;
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if(navInfo?.Id) {
        deviceId = navInfo.Id;
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadData(deviceId);
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, notificationsFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        if (checkValues[notificationsFeature]) {
          this.myTotalItems = data[notificationsFeature].Total;
          this.deviceNotifications = data[notificationsFeature].Items;
          this.deviceNotificationsDataSource = new MatTableDataSource(this.deviceNotifications);
          this.deviceNotificationsDataSource.paginator = this.paginator;
        }
        else {
          this.deviceNotificationsDataSource = new MatTableDataSource();
        }
      }
      this.loadingData = false;
    }, context);

    let firstRequest: DeviceNotificationListRequest = {
      Device: deviceId,
      Page: this.myPageIndex,
      Limit: this.myPageSize
    };

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        deviceFeature = this.apiSync.loadedFeature(context);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.isOnlyDomainAdmin = false;
      this.deviceNotificationApiService.list(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res) {
          notificationsFeature = this.apiSync.loadedFeatureWithData(res, context);
        } else {
          notificationsFeature = this.apiSync.failedFeature(context);
        }
      });
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;

    let latestEventsRequest: DeviceNotificationListRequest = {
      Device: this.currentDevice.Id,
      Page: 0,
      Limit: 0,
      DeviceCommand: this.deviceCommandId
    }

    this.deviceNotificationApiService.getLatest24Hours(latestEventsRequest.Device, latestEventsRequest.DeviceCommand).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceNotification[]) => {
      if(res && res.length > 0) {
        this.setLatestDates(res);

        this.myTotalItems = res.length;
        this.deviceNotifications = res;

        this.deviceNotificationsDataSource = new MatTableDataSource(this.deviceNotifications);
        this.lastPageSize = this.myPageSize;
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceNotification[]): void {
    this.endDate = res[0].Created;
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.paginator?.firstPage();
    }

    let serverRequest: DeviceNotificationListRequest = {
      Device: this.currentDevice.Id,
      Page: this.myPageIndex,
      Limit: this.myPageSize,
      Start: this.startDate,
      End: this.endDate,
      DeviceCommand: this.deviceCommandId
    }

    this.deviceNotificationApiService.list(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res) {
        this.myTotalItems = res.Total;
        this.deviceNotifications = res.Items;
        this.deviceNotificationsDataSource = new MatTableDataSource(this.deviceNotifications);
        this.lastPageSize = this.myPageSize;
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  public getServerData(event: PageEvent) {
    if (event.previousPageIndex === event.pageIndex - 1) {
      this.myPageIndex = this.myPageIndex + 1;
    } else if (event.previousPageIndex === event.pageIndex + 1) {
      this.myPageIndex = this.myPageIndex - 1;
    } else if (event.pageIndex === Math.floor(event.length / event.pageSize)) {
      this.myPageIndex = Math.floor(event.length / event.pageSize);
    } else if (event.pageIndex === 0) {
      this.myPageIndex = 0;
    }
    this.myPageSize = event.pageSize;
    this.newSearch();
    return event;
  }

  openDetailDialog(singleNotification: DeviceNotification): void {
    this.dialog.open(DeviceNotificationDetailDialogComponent, {
      data: {targetNotification: singleNotification},
      maxWidth: 1000,
      width: '70%'
    });
  }

  goToDeviceCommandDetail(deviceCommandId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceCommandId, BackRoute: 'device-notifications' });
    this.router.navigate(['main/device-command-detail']);
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
