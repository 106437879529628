import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartDataList } from '../../../shared/models/ChartDataList';

@Component({
  selector: 'urban-platform-statistics-barchart',
  templateUrl: './platform-statistics-barchart.component.html',
  styleUrls: ['./platform-statistics-barchart.component.scss']
})
export class PlatformStatisticsBarchartComponent implements OnInit, OnChanges, OnDestroy {

  @Input('barChartTitle') public barChartTitle: string;
  @Input('barChartError') public barChartError: string;
  @Input('elementsDescription') public vAxisTitle: string = 'Elements';
  @Input('valuesDescription') public hAxisTitle: string = 'Values';
  @Input('barDataPassed') public passedDataList: ChartDataList;
  @Input('limitValue') private maxValue: number = 1;
  @Input('darkThemeActive') public darkThemeActive: boolean;

  private translations: string[] = [];
  public translationsReady: boolean = false;
  private chart: google.visualization.BarChart;
  public chartOptions: google.visualization.BarChartOptions;
  public noDataOnChart: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private googleChartLoaded: boolean = false;
  private lightPrimaryColor: string;
  private darkPrimaryColor: string;
  private chartColors: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  constructor(private translate: TranslateService) {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.onLoadedChartDraw.bind(this));
  }

  ngOnInit(): void {
    this.lightPrimaryColor = this.getColor('light', 'primary');
    this.darkPrimaryColor = this.getColor('dark', 'primary');
    this.chartColors = this.getChartColors();

    this.translations.push(this.vAxisTitle, this.hAxisTitle);
    this.getTranslations();
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations(): void {
    this.translate.get(this.translations).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.vAxisTitle = res[this.translations[0]]
      this.hAxisTitle = res[this.translations[1]];
      this.translationsReady = true;
    });
  }

  tryDrawChart(): void {
    if(this.googleChartLoaded) {
      this.drawChart();
    }
  }

  onLoadedChartDraw(): void {
    this.googleChartLoaded = true;
    this.drawChart();
  }

  private drawChart(): void {
    if (this.passedDataList && this.passedDataList !== undefined && Object.keys(this.passedDataList).length > 0) {
      this.noDataOnChart = false;
      let myData = new google.visualization.DataTable();
      myData.addColumn('string', this.vAxisTitle);
      myData.addColumn('number', this.hAxisTitle);
      myData.addColumn({ role: 'annotation' });
      for (let key in this.passedDataList) {
        let value: number = this.passedDataList[key];
        if(this.maxValue === 1 && value > 0) {
          this.maxValue = null;
        }
        myData.addRow([key, value, value.toString()]);
      }

      if (this.darkThemeActive) {
        this.chartOptions = {
          isStacked: false,
          annotations: {
            alwaysOutside: true,
            textStyle: {
              bold: true,
              color: 'white'
            }
          },
          backgroundColor: 'transparent',
          hAxis: {
            textStyle: { color: 'white' },
            title: this.hAxisTitle,
            titleTextStyle: { color: 'white' },
            format: '#',
            viewWindow: {
              min: 0,
              max: this.maxValue ? Number(this.maxValue) : null
            }
          },
          vAxis: {
            textStyle: { color: 'white' }
          },
          series: {
            0: {
              color: this.darkPrimaryColor ? this.darkPrimaryColor : '#0971ce',
            },
            1: {
              color: this.chartColors[0].trim()
            },
            2: {
              color: this.chartColors[1].trim()
            },
            3: {
              color: this.chartColors[2].trim()
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          },
          legend: { position: 'none' }
        }
      } else {
        this.chartOptions = {
          isStacked: false,
          annotations: {
            alwaysOutside: true,
            textStyle: {
              bold: true,
              color: 'black'
            }
          },
          backgroundColor: 'transparent',
          hAxis: {
            title: this.hAxisTitle,
            format: '#',
            viewWindow: {
              min: 0,
              max: this.maxValue ? Number(this.maxValue) : null
            }
          },
          series: {
            0: {
              color: this.lightPrimaryColor ? this.lightPrimaryColor : '#0971ce'
            },
            1: {
              color: this.chartColors[0].trim()
            },
            2: {
              color: this.chartColors[1].trim()
            },
            3: {
              color: this.chartColors[2].trim()
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          },
          legend: { position: 'none' }
        }
      }
      this.chart = new google.visualization.BarChart(document.getElementById('divBarChart'));
      this.chart.draw(myData, this.chartOptions);
    } else {
      this.noDataOnChart = true;
      if (this.chart) {
        this.chart.clearChart();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['darkThemeActive']) {
      const isDarkActive = changes['darkThemeActive'];
      if (isDarkActive.currentValue !== isDarkActive.previousValue && isDarkActive.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['passedDataList']) {
      const updatedAccessList = changes['passedDataList'];
      if (updatedAccessList.currentValue !== updatedAccessList.previousValue && updatedAccessList.firstChange == false) {
        this.tryDrawChart();
      }
    }
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    
    return colors;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
