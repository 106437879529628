import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataList } from './../../../shared/models/ChartDataList';
import { CrosswalkEvent, CrosswalkData, CrosswalkEventAverage, CrosswalkEventAveragePerColor } from './../../../shared/models/smartCrosswalk';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ApiService } from '../../../shared/services/api.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Device } from '../../../shared/models/device';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import * as introJs from 'intro.js/intro.js';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';

@Component({
  selector: 'urban-crosswalk-dashboard',
  templateUrl: './crosswalk-dashboard.component.html',
  styleUrls: ['./crosswalk-dashboard.component.scss']
})
export class CrosswalkDashboardComponent implements OnInit, OnDestroy {

  public crosswalkDataSource: MatTableDataSource<CrosswalkData>;
  public displayedColumns: string[] = ['Name', 'Average crosswalks', 'Total crosswalks'];
  // public displayedColumns: string[] = ['Name', 'Average crosswalks', 'Total crosswalks', 'Graphic average violations'];
  public crosswalkDevices: Device[] = [];
  public crosswalkEvents: CrosswalkEvent[];
  public crosswalkAverages: CrosswalkEventAverage[];
  public readyToShow: boolean = false;
  public noDataInDate: boolean = false;
  public columnChartData: ChartDataList;
  public columnChartTitle: string = "CROSSWALK.LAST_24H_TOTAL";
  public averageDataToPass: ChartDataList;
  public dataDescriptions: string[];
  public pieChartTitle: string = "CROSSWALK.LAST_24H_AVERAGE_PER_COLOR";
  public translationsReady: boolean = false;
  public loadingData: boolean;
  public mapReady: boolean = false;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  private alertEventsDevicesInvolved: string;
  public alertPanelInput: AlertPanelInput;
  public isDomainAdmin: boolean = false;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  public formDate: UntypedFormGroup = this.formBuilder.group({
    startDate: [{ value: null, disabled: false }],
    endDate: [{ value: null, disabled: false }]
  });

  private introJS = introJs();

  constructor(
    private router: Router,
    private apiService: ApiService,
    private passDataService: PassDataService,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private translate: TranslateService,
    private apiSync: ApiSynchronizerService
    ) {}

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    let colorPhrases: string[] = ['CROSSWALK.GREEN', 'CROSSWALK.YELLOW', 'CROSSWALK.RED'];
    this.setDynamicTranslations(colorPhrases,
      (res: any) => this.afterColorTranslations(res),
      () => this.loadData()
    );

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDomainAdmin = (res && res.some(x => x.Name === 'Administrators' || x.Name == 'Domain admin'));
    });
  }

  private setDynamicTranslations(phrases: string[],
    afterTranslated: (phrasesTranslated: any) => void = () => {},
    afterFirstComplete: () => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => {
      afterTranslated(res);
      afterFirstComplete();
    });
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterColorTranslations(res: any): void {
    this.dataDescriptions = [];
    this.dataDescriptions.push(res['CROSSWALK.GREEN']);
    this.dataDescriptions.push(res['CROSSWALK.YELLOW']);
    this.dataDescriptions.push(res['CROSSWALK.RED']);
    this.translationsReady = true;
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText] +
        ' ' + this.alertEventsDevicesInvolved
    };
  }

  private loadData(): void {
    let deviceEventsFeature: number;
    this.apiSync.initialize();
    this.apiSync.addFeatures(2);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if(checkValues.every((value: boolean) => value)) {
        this.crosswalkEvents = this.mapToCrosswalkEvents(data[deviceEventsFeature], this.crosswalkDevices);
      }

      if (this.crosswalkEvents.length > 0) {
        this.crosswalkAverages = this.calculateCrosswalkEventsAverages(this.crosswalkEvents, this.crosswalkDevices);
        let crosswalkAveragesPerColor: CrosswalkEventAveragePerColor[] = this.calculateCrosswalkEventsAveragesPerColor(this.crosswalkEvents);

        this.dataDescriptions.forEach((color: string, colorIndex: 0 | 1 | 2) => {
          this.averageDataToPass[color] = crosswalkAveragesPerColor[2 - colorIndex].Total
        });

        let crosswalkData: CrosswalkData[] = [];

        this.crosswalkDevices.forEach((singleDevice: Device) => {

          let deviceTotal: number = 0;
          let redTotal: number = 0;
          this.crosswalkEvents.filter((singleEvent: CrosswalkEvent) =>
            singleEvent.Device.Id === singleDevice.Id
          ).forEach((event: CrosswalkEvent) => {
            deviceTotal += event.Total
            if (event.Status === 0) {
              redTotal += event.Total;
            }
          });

          this.columnChartData[singleDevice.Name] = deviceTotal

          let singleCrosswalkData: CrosswalkData = {
            Device: singleDevice,
            Average: 0,
            Total: deviceTotal,
            Red: redTotal
          }
          this.crosswalkAverages.filter(
            (singleAverage: CrosswalkEventAverage) => singleAverage.Device.Id === singleDevice.Id
          ).forEach((averageEvent: CrosswalkEventAverage) => {
            if(averageEvent.Status == 3) { // total case
              singleCrosswalkData.Average += averageEvent.Total;
            }
          });

          crosswalkData.push(singleCrosswalkData);
        });
        this.crosswalkDataSource = new MatTableDataSource<CrosswalkData>(crosswalkData);

        this.readyToShow = true;
      }
      else {
        this.noDataInDate = true
      }

      this.loadingData = false
    });

    this.apiService.getDevicesByType('crosswalk-monitor').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if(res && res.length > 0){
        this.crosswalkDevices = res;

        this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
          this.mapReady = mapLoading;
        });

        this.apiSync.loadedFeature()
      }
      else {
        this.apiSync.failedFeature();
      }
    });

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'crosswalk-monitor'
    };

    this.apiService.getDeviceEvents(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
        this.resetAllData();

        if(res && res.Devices?.length > 0) {
          deviceEventsFeature = this.apiSync.loadedFeatureWithData(res);
        }
        else {
          deviceEventsFeature = this.apiSync.failedFeature();
        }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;

    this.loadingData = true;
    this.noDataInDate = false;

    let eventsRequest: DeviceEventLatestRequest = { DeviceType: 'crosswalk-monitor' };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest, this.isAProperEvent)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
        this.resetAllData();
        this.checkAnomalousEvents(res);

        if(res && res.Devices?.length > 0) {
          this.crosswalkEvents = this.mapToCrosswalkEvents(res, this.crosswalkDevices);
        }

        if (this.crosswalkEvents.length > 0) {
          this.setLatestDates(res);

          this.crosswalkAverages = this.calculateCrosswalkEventsAverages(this.crosswalkEvents, this.crosswalkDevices);
          let crosswalkAveragesPerColor: CrosswalkEventAveragePerColor[] = this.calculateCrosswalkEventsAveragesPerColor(this.crosswalkEvents);

          this.dataDescriptions.forEach((color: string, colorIndex: 0 | 1 | 2) => {
            this.averageDataToPass[color] = crosswalkAveragesPerColor[2 - colorIndex].Total
          });

          let crosswalkData: CrosswalkData[] = [];

          this.crosswalkDevices.forEach((singleDevice: Device) => {

            let deviceTotal: number = 0;
            let redTotal: number = 0;
            this.crosswalkEvents.filter((singleEvent: CrosswalkEvent) =>
              singleEvent.Device.Id === singleDevice.Id
            ).forEach((event: CrosswalkEvent) => {
              deviceTotal += event.Total;
              if (event.Status === 0) {
                redTotal += event.Total;
              }
            });

            this.columnChartData[singleDevice.Name] = deviceTotal;

            let singleCrosswalkData: CrosswalkData = {
              Device: singleDevice,
              Average: 0,
              Total: deviceTotal,
              Red: redTotal
            }

            this.crosswalkAverages.filter(
              (singleAverage: CrosswalkEventAverage) => singleAverage.Device.Id === singleDevice.Id
            ).forEach((averageEvent: CrosswalkEventAverage) => {
              if(averageEvent.Status == 3) { // total case
                singleCrosswalkData.Average += averageEvent.Total;
              }
            });
            crosswalkData.push(singleCrosswalkData);
          });
          this.crosswalkDataSource = new MatTableDataSource<CrosswalkData>(crosswalkData);

          this.readyToShow = true;
        }
        else {
          this.readyToShow = false;
          this.noDataInDate = true;
        }

        this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;
    this.noDataInDate = false;

    if (selectedDates.last24hSearch) {
      this.columnChartTitle = "CROSSWALK.LAST_24H_TOTAL";
      this.pieChartTitle = "CROSSWALK.LAST_24H_AVERAGE_PER_COLOR";
    } else {
      this.columnChartTitle = "CROSSWALK.TOTAL";
      this.pieChartTitle = "CROSSWALK.AVERAGE_PER_COLOR";
    }

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'crosswalk-monitor',
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
        this.resetAllData();

        if(res && res.Devices?.length > 0) {
          this.crosswalkEvents = this.mapToCrosswalkEvents(res, this.crosswalkDevices);
        }

        if (this.crosswalkEvents.length > 0) {
          this.crosswalkAverages = this.calculateCrosswalkEventsAverages(this.crosswalkEvents, this.crosswalkDevices);
          let crosswalkAveragesPerColor: CrosswalkEventAveragePerColor[] = this.calculateCrosswalkEventsAveragesPerColor(this.crosswalkEvents);

          this.dataDescriptions.forEach((color: string, colorIndex: 0 | 1 | 2) => {
            this.averageDataToPass[color] = crosswalkAveragesPerColor[2 - colorIndex].Total
          });

          let crosswalkData: CrosswalkData[] = [];

          this.crosswalkDevices.forEach((singleDevice: Device) => {

            let deviceTotal: number = 0;
            let redTotal: number = 0;
            this.crosswalkEvents.filter((singleEvent: CrosswalkEvent) =>
              singleEvent.Device.Id === singleDevice.Id
            ).forEach((event: CrosswalkEvent) => {
              deviceTotal += event.Total;
              if (event.Status === 0) {
                redTotal += event.Total;
              }
            });

            this.columnChartData[singleDevice.Name] = deviceTotal;

            let singleCrosswalkData: CrosswalkData = {
              Device: singleDevice,
              Average: 0,
              Total: deviceTotal,
              Red: redTotal
            }

            this.crosswalkAverages.filter(
              (singleAverage: CrosswalkEventAverage) => singleAverage.Device.Id === singleDevice.Id
            ).forEach((averageEvent: CrosswalkEventAverage) => {
              if(averageEvent.Status == 3) { // total case
                singleCrosswalkData.Average += averageEvent.Total;
              }
            });
            crosswalkData.push(singleCrosswalkData);
          });
          this.crosswalkDataSource = new MatTableDataSource<CrosswalkData>(crosswalkData);

          this.readyToShow = true;
        }
        else {
          this.last24hSearch = selectedDates.last24hSearch;
          this.readyToShow = false;
          this.noDataInDate = true;
        }

        this.loadingData = false;
    });
  }

  private resetAllData(): void {
    this.crosswalkEvents = [];
    this.columnChartData = {};
    this.averageDataToPass = {};
    this.crosswalkAverages = [];
    this.crosswalkDataSource = new MatTableDataSource();
    this.alertPanelInput = undefined;
    this.alertEventsDevicesInvolved = null;
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEventsDevices: EventsDeviceLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT';

    if (res?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.Devices,
        (event: EventLatest) => event.Level === 'Error');
    }

    if (res?.LatestBadEvents?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
        (event: EventLatest) => event.Level === 'Error', alertEventsDevices);
    }

    if (alertEventsDevices.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event));
      }

      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event),
          alertEventsDevices);
      }

      if (alertEventsDevices.length > 0) {
        //Wrong body
        alertType = 'error';
        eventType = 'WRONG_BODY_EVENT';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.Devices,
            (event: EventLatest) => event.Level === 'Warning');
        }

        if (res?.LatestBadEvents?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
            (event: EventLatest) => event.Level === 'Warning', alertEventsDevices);
        }

        if (alertEventsDevices.length > 0) {
          alertType = 'warning';
          eventType = 'WARNING';
        }
      }
    }

    if (alertEventsDevices.length > 0) {
      let alertEventsNumber: number = alertEventsDevices.reduce((sum, device) => sum + device.Events.length, 0);
      let errorPhrases: string[] = [
        'ALERT_PANEL.' + eventType + (alertEventsNumber > 1 ? 'S' : '') + '_DETECTED',
        'ALERT_PANEL.DEVICE' + (alertEventsDevices.length > 1 ? 'S' : '') + '_INVOLVED'
      ];

      this.alertEventsDevicesInvolved = alertEventsDevices
        .map(device => device.Name).join(', ');

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEventsNumber.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private getCertainEventsDevices(
    devices: EventsDeviceLatest[],
    eventCheck: (event: EventLatest) => boolean,
    initialArray: EventsDeviceLatest[] = []): EventsDeviceLatest[] {
    return devices.reduce<EventsDeviceLatest[]>((accumulator, currentDevice) => {
      let alertEvents: EventLatest[] = currentDevice.Events.filter((event: EventLatest) => eventCheck(event));
      let deviceIndex: number = accumulator.findIndex(device => device.Id === currentDevice.Id);
      if (deviceIndex !== -1) {
        accumulator[deviceIndex].Events.push(...alertEvents.filter(eventToAdd =>
          accumulator[deviceIndex].Events.find(event => event.Id === eventToAdd.Id) === undefined
        ));
        return accumulator;
      }
      else {
        return alertEvents.length > 0 ? [...accumulator, { ...currentDevice, Events: alertEvents } ] : accumulator;
      }
    }, initialArray);
  }

  private isAProperEvent(event: DeviceEventsEvent | EventLatest): boolean {
    return event.Body && Object.keys(event.Body).includes('Total') && [0, 1, 2].includes(event.Body.Status);
  }

  private mapToCrosswalkEvents(res: DeviceEventsResponse, crosswalkDevices: Device[]): CrosswalkEvent[] {
    let formattedEvents: CrosswalkEvent[] = [];

    res.Devices.forEach((device: DeviceEventsDevice) => {
      let crosswalkDevice: Device;
      if ((crosswalkDevice = crosswalkDevices.find(oneDevice => oneDevice.Id === device.Id)) !== undefined) {
        device.Events.forEach((event: DeviceEventsEvent) => {
          if (Object.keys(event.Body).includes('Total') && [0, 1, 2].includes(event.Body.Status)) {
            let crosswalkEvent: CrosswalkEvent = {
              Created: event.CreatedTimestamp,
              Device: crosswalkDevice,
              DomainId: crosswalkDevice.Domain.Id,
              Total: event.Body.Total,
              Status: event.Body.Status
            };

            formattedEvents.push(crosswalkEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  private calculateCrosswalkEventsAverages(crosswalkEvents: CrosswalkEvent[], crosswalkDevices: Device[]): CrosswalkEventAverage[] {
    let averages: CrosswalkEventAverage[] = [];
    crosswalkDevices.forEach((crosswalkDevice: Device) => {
      let deviceEvents: CrosswalkEvent[] = crosswalkEvents.filter((event: CrosswalkEvent) => event.Device.Id === crosswalkDevice.Id);
      if(deviceEvents.length > 0) {
        //let deviceBrandName = crosswalkDevice.Model.Brand.Name;
        let averagesPerStatus: CrosswalkEventAverage[] = []
        let countsPerStatus: number[] = [];
        let statusArray: (0 | 1 | 2 | 3)[] = [0, 1, 2, 3]; // 0 = red, 1 = yellow, 2 = green, 3 = all
        statusArray.forEach((status: 0 | 1 | 2 | 3) => {
          let average: CrosswalkEventAverage = {
            Created: deviceEvents[0].Created,
            Device: crosswalkDevice,
            DomainId: crosswalkDevice.Domain.Id,
            Total: 0,
            Status: status
          };
          averagesPerStatus[status] = average;
          countsPerStatus[status] = 0;
        });
        deviceEvents.forEach((event: CrosswalkEvent) => {
          averagesPerStatus[event.Status].Total += event.Total;
          averagesPerStatus[3].Total += event.Total;
          countsPerStatus[event.Status]++;
        });
        statusArray.forEach((status : 0 | 1 | 2) => {
          if(countsPerStatus[status] !== 0) {
            averagesPerStatus[status].Total = Math.round(averagesPerStatus[status].Total / countsPerStatus[status] * 100) / 100;
          }
        });
        averagesPerStatus[3].Total = Math.round(averagesPerStatus[3].Total / deviceEvents.length * 100) / 100;
        averages.push(...averagesPerStatus);
      }
    });
    return averages;
  }

  private calculateCrosswalkEventsAveragesPerColor(crosswalkEvents: CrosswalkEvent[]): CrosswalkEventAveragePerColor[] {
    let averagesPerColor: CrosswalkEventAveragePerColor[] = [];
    let countsPerStatus: Array<number> = Array<number>(3).fill(0);

    countsPerStatus.forEach((_, status: 0 | 1 | 2) => {
      averagesPerColor[status] = {
        Created: 0,
        Total: 0,
        Status: status
      };
    });

    crosswalkEvents.forEach((crosswalkEvent: CrosswalkEvent) => {
      averagesPerColor[crosswalkEvent.Status].Total += crosswalkEvent.Total;
      countsPerStatus[crosswalkEvent.Status]++;
    });

    countsPerStatus.forEach((_: number, status : 0 | 1 | 2) => {
      if(countsPerStatus[status] !== 0) {
        averagesPerColor[status].Total /= countsPerStatus[status];
      }
    });

    return averagesPerColor;
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.CROSSWALK_WELCOME',
      'INTRO.CROSSWALK_TAB',
      'INTRO.CROSSWALK_TABLE',
      'INTRO.SEARCH'
    ])
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
      this.introJS
      .setOptions({
        steps: [
          {
            title: 'Welcome',
            intro: intros['INTRO.CROSSWALK_WELCOME']
          },
          {
            title: 'Tab bar',
            element: '#intro-crosswalk-tab',
            intro: intros['INTRO.CROSSWALK_TAB'],
            position: 'bottom'
          },
          {
            title: 'Table',
            element: '#intro-crosswalk-table',
            intro: intros['INTRO.CROSSWALK_TABLE'],
            position: 'bottom'
          },
          {
            title: 'Search bar',
            element: '#intro-crosswalk-search',
            intro: intros['INTRO.SEARCH'],
            position: 'right'
          }
        ],
        showProgress: true
      })
      .start();
    });
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.crosswalkEvents.length === 0) {
      this.readyToShow = false;
      this.noDataInDate = true;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceDetails(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: 'smart-crosswalk' });
    this.router.navigate(['main/smart-crosswalk-detail']);
  }

  public goToRoadRisk(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'smart-crosswalk' });
    this.router.navigate(['main/road-risk']);
  }

  public goToDomainEvents(): void {
    if (this.isDomainAdmin) {
      this.mainService.setNavigationInfoComand({ BackRoute: 'smart-crosswalk' });
      this.router.navigate(['main/domain-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
