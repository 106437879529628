import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Sensor } from '../../models/weatherstation/sensor';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { LineChartData } from '../../models/lineChartData';
import { ChartMultiDataList } from '../../models/ChartDataList';

@Component({
  selector: 'urban-google-linechart',
  templateUrl: './google-linechart.component.html',
  styleUrls: ['./google-linechart.component.scss']
})
export class GoogleLinechartComponent implements OnInit, OnChanges {
  @Input('linechartId') public linechartId: string = '';
  @Input('sensor') public sensor: Sensor;
  @Input('lineChartData') public lineChartData: LineChartData;
  @Input('lineChartMultiDataList') private lineChartMultiDataList: ChartMultiDataList = {};
  @Input('propertiesList') private propertiesList: string[] = [];
  @Input('multiChartTitle') private multiChartTitle: string = '';
  @Input('maxValueDescription') public maxValueDescription: string;
  @Input('valueDescription') public valueDescription: string;
  @Input('startDate') public startDate: number;
  @Input('endDate') public endDate: number;
  @Input('darkThemeActive') public darkThemeActive: boolean;

  public chartOptions: any;
  public chart: google.visualization.LineChart;
  public translationsReady: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private translations: string[] = [];
  private timePeriod: string = 'GENERAL.LAST_24H';
  public noDataOnChart: boolean = false;
  private googleChartLoaded: boolean = false;
  private lightPrimaryColor: string;
  private darkPrimaryColor: string;
  private chartColors: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  constructor(private datePipe: DatePipe, private translate: TranslateService) {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.onLoadedChartDraw.bind(this));
  }

  ngOnInit(): void {
    this.lightPrimaryColor = this.getColor('light', 'primary');
    this.darkPrimaryColor = this.getColor('dark', 'primary');
    this.chartColors = this.getChartColors();

    this.linechartId = `divLineChart${this.linechartId}`;
    if (this.sensor && this.sensor.Unit && this.sensor.Unit !== '' && this.sensor.Unit !== '%') {
      this.sensor.Unit = ` ${this.sensor.Unit}`;
    }
    this.translations.push(this.maxValueDescription, this.valueDescription, this.timePeriod);
    this.getTranslations();
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
      this.tryDrawChart();
    });
  }

  getTranslations(): void {
    this.translate.get(this.translations).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.maxValueDescription = res[this.translations[0]];
      this.valueDescription = res[this.translations[1]];
      this.timePeriod = res[this.translations[2]];
      this.translationsReady = true;
    });
  }

  tryDrawChart(): void {
    if(this.googleChartLoaded) {
      this.drawChart();
    }
  }

  onLoadedChartDraw(): void {
    this.googleChartLoaded = true;
    this.drawChart();
  }

  private drawChart() {
    //this.chart.clearChart();  //potrebbe essere utile
    // var formatter = new google.visualization.NumberFormat(
    //   {suffix: '%', pattern: 'short'});

    if (this.sensor && this.sensor !== undefined && this.sensor.Data.length > 0) {
      this.loadFromSensor();
    }
    else if (this.lineChartData && this.lineChartData.Elements && this.lineChartData.Elements.length > 0) {
      this.loadFromLineChartData();
    }
    else if (this.lineChartMultiDataList && Object.keys(this.lineChartMultiDataList).length > 0) {
      this.loadFromMultiDataList();
    }
    else {
      this.noDataOnChart = true;
      if (this.chart) {
        this.chart.clearChart();
      }
    }
  }

  private loadFromSensor() {
    if (this.sensor && this.sensor !== undefined && this.sensor.Data.length > 0) {
      this.noDataOnChart = false;
      let myData = new google.visualization.DataTable();
      myData.addColumn('datetime', 'Sensor Date');
      myData.addColumn('number', this.valueDescription);
      myData.addColumn({ type: 'boolean', role: 'emphasis' }); //column 'emphasis' here to affect the previous one

      let unit: string = '';
      if (this.sensor.Unit !== null && this.sensor.Unit !== undefined && this.sensor.Unit !== '') {
        unit = this.sensor.Unit;
      }

      let valueString = this.sensor.Limit;
      if (unit !== '') {
        valueString = `${valueString} ${unit}`;
      }
      let limit: string = `${this.maxValueDescription} (${valueString})`;
      myData.addColumn('number', limit);
      this.sensor.Data.forEach(sensorData => {
        let utcDate = new Date(sensorData.Created * 1000);
        let date = new Date(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds(), utcDate.getMilliseconds())
        let limitPassed = Number(sensorData.Value) >= Number(this.sensor.Limit);
        valueString = sensorData.Value.toString();
        if (unit !== '') {
          valueString = `${valueString}${unit}`;
          if (unit.toLowerCase() == " deg") {
            valueString = `${valueString} (${this.fromDegreesToCoordinates(Number(sensorData.Value))})`;
          }
        }
        let value = { v: Number(sensorData.Value), f: valueString };

        if (this.sensor.Limit !== 'N.A.') {
          let maxValue = Number(this.sensor.Limit);

          if (limitPassed) {
            myData.addRow([date, value, limitPassed, maxValue]);
          }
          else {
            myData.addRow([date, value, null, maxValue]);
          }
        }
        else {
          myData.addRow([date, value, null, null]);
        }

      });

      if (this.darkThemeActive) {
        this.chartOptions = {
          animation: this.chart ? null : {
            startup: true,
            duration: 1000,
            easing: 'inAndOut'
          },
          // chartArea: {width: '100%', height: '100%'}, questo ci permette di definire alcune proporzioni rispetto al container
          height: 550,
          chartArea: { width: '80%', height: '70%' },
          legend: {
            position: 'top',
            textStyle: { color: 'white' }
          },
          isStacked: true,
          displayAnnotations: true,
          backgroundColor: 'transparent',
          hAxis: {
            format: "HH:mm \n dd/MM/yy",
            textStyle: {
              fontName: 'Poppins',
              color: 'white',
              fontSize: 11.2,
              bold: true
            },
            maxTextLines: 2,
            textPosition: 'out',
            title: (this.startDate && this.endDate) ? this.pipeDate(this.startDate) + ' - ' + this.pipeDate(this.endDate) : this.timePeriod,
            titleTextStyle: { color: 'white' }
          },
          vAxis: {
            textStyle: {
              fontName: 'Poppins',
              color: 'white',
              fontSize: 11.2
            },
            viewWindow: {
              min: 0
            },
            format: unit !== '' ? `#'${unit}'` : '#',
            titleTextStyle: { color: 'white' }
          },
          treadline: null,
          series: {
            0: {
              color: this.darkPrimaryColor ? this.darkPrimaryColor : '#0971ce',
              pointShape: 'circle', // this is the default
              lineWidth: 2 // this is the default
            },
            1: {
              color: this.chartColors[0].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            2: {
              color: this.chartColors[1].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            3: {
              color: this.chartColors[2].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            4: {
              color: this.chartColors[3].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            5: {
              color: this.chartColors[4].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            6: {
              color: this.chartColors[5].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            7: {
              color: this.chartColors[6].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            }
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          },
          //curveType: 'function',
        }

      } else {
        this.chartOptions =  {
          animation: this.chart ? null : {
            startup: true,
            duration: 1000,
            easing: 'inAndOut'
          },
          // chartArea: {width: '100%', height: '100%'}, questo ci permette di definire alcune proporzioni rispetto al container
          height: 550,
          chartArea: { width: '80%', height: '70%' },
          legend: {
            position: 'top'
          },
          isStacked: true,
          displayAnnotations: true,
          backgroundColor: 'transparent',
          hAxis: {
            format: "HH:mm \n dd/MM/yy",
            textStyle: {
              fontName: 'Poppins',
              fontSize: 11.2,
              bold: true
            },
            maxTextLines: 2,
            textPosition: 'out',
            title: (this.startDate && this.endDate) ? this.pipeDate(this.startDate) + ' - ' + this.pipeDate(this.endDate) : this.timePeriod,
            gridlines: { color: '#c5cad8' },
            minorGridlines: { color: '#e2ebf3' }
          },
          vAxis: {
            textStyle: {
              fontName: 'Poppins',
              fontSize: 11.2,
              color: '#5e688b'
            },
            viewWindow: {
              min: 0
            },
            format: unit !== '' ? `#'${unit}'` : '#',
            gridlines: { color: '#c5cad8' },
            minorGridlines: { color: '#e2ebf3' }
          },
          treadline: null,
          series: {
            0: {
              color: this.lightPrimaryColor ? this.lightPrimaryColor : '#0971ce',
              pointShape: 'circle', // this is the default
              lineWidth: 2 // this is the default
            },
            1: {
              color: this.chartColors[0].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            2: {
              color: this.chartColors[1].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            3: {
              color: this.chartColors[2].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            4: {
              color: this.chartColors[3].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            5: {
              color: this.chartColors[4].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            6: {
              color: this.chartColors[5].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            7: {
              color: this.chartColors[6].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            }
          },
          //curveType: 'function',
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          }
        }
      }
      this.chart = new google.visualization.LineChart(document.getElementById(this.linechartId));
      this.chart.draw(myData, this.chartOptions);
    }
    else {
      this.noDataOnChart = true;
      if (this.chart) {
        this.chart.clearChart();
      }
    }
  }

  private loadFromLineChartData() {
    if (this.lineChartData && this.lineChartData.Elements && this.lineChartData.Elements.length > 0) {
      this.noDataOnChart = false;
      let myData = new google.visualization.DataTable();
      myData.addColumn('datetime', 'Sensor Date');
      myData.addColumn('number', this.valueDescription);
      myData.addColumn({ type: 'boolean', role: 'emphasis' }); //column 'emphasis' here to affect the previous one
      let valueString = this.lineChartData.Limit.toString();
      if (this.lineChartData.Unit != '') {
        valueString = `${valueString} ${this.lineChartData.Unit}`;
      }
      let limit: string = `${this.maxValueDescription} (${valueString})`;
      myData.addColumn('number', limit);
      this.lineChartData.Elements.forEach(lineChartDataElement => {
        let utcDate = new Date(lineChartDataElement.Date * 1000);
        let date = new Date(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds(), utcDate.getMilliseconds())
        let limitPassed = Number(lineChartDataElement.Value) >= this.lineChartData.Limit;
        valueString = lineChartDataElement.Value.toString();
        if (this.lineChartData.Unit !== '') {
          valueString = `${valueString}${this.lineChartData.Unit}`;
          if (this.lineChartData.Unit.toLowerCase() == " deg") {
            valueString = `${valueString} (${this.fromDegreesToCoordinates(Number(lineChartDataElement.Value))})`;
          }
        }
        let value = { v: lineChartDataElement.Value, f: valueString };
        let maxValue = this.lineChartData.MaxValue;
        if (limitPassed) {
          myData.addRow([date, value, limitPassed, maxValue]);
        }
        else {
          myData.addRow([date, value, null, maxValue]);
        }
      });

      if (this.darkThemeActive) {
        this.chartOptions = {
          // chartArea: {width: '100%', height: '100%'}, questo ci permette di definire alcune proporzioni rispetto al container
          height: 550,
          chartArea: { width: '80%', height: '70%' },
          legend: {
            position: 'top',
            textStyle: { color: 'white' }
          },
          isStacked: true,
          displayAnnotations: true,
          backgroundColor: 'transparent',
          hAxis: {
            //format: 'dd/MM/yyyy HH:mm:ss',
            format: 'dd/MM/yyyy HH:mm',
            textStyle: { color: 'white' },
            textPosition: 'out',
            title: (this.startDate && this.endDate) ? this.pipeDate(this.startDate) + ' - ' + this.pipeDate(this.endDate) : this.timePeriod,
            titleTextStyle: { color: 'white' }
          },
          vAxis: {
            textStyle: { color: 'white' },
            viewWindow: {
              min: 0
            },
            format: this.lineChartData.Unit && this.lineChartData.Unit !== '' ? `#'${this.lineChartData.Unit}'` : '#',
            titleTextStyle: { color: 'white' }
          },
          treadline: null,
          series: {
            0: {
              color: this.darkPrimaryColor ? this.darkPrimaryColor : '#0971ce',
              pointShape: 'circle', // this is the default
              lineWidth: 2 // this is the default
            },
            1: {
              color: this.chartColors[0].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            2: {
              color: this.chartColors[1].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            3: {
              color: this.chartColors[2].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            4: {
              color: this.chartColors[3].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            5: {
              color: this.chartColors[4].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            6: {
              color: this.chartColors[5].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            7: {
              color: this.chartColors[6].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            }
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          },
          //curveType: 'function',
        }

      } else {
        this.chartOptions = {
          // chartArea: {width: '100%', height: '100%'}, questo ci permette di definire alcune proporzioni rispetto al container
          height: 550,
          chartArea: { width: '80%', height: '70%' },
          legend: {
            position: 'top'
          },
          isStacked: true,
          displayAnnotations: true,
          backgroundColor: 'transparent',
          hAxis: {
            //format: 'dd/MM/yyyy HH:mm:ss',endDate
            format: 'dd/MM/yyyy HH:mm',
            textPosition: 'out',
            title: (this.startDate && this.endDate) ? this.pipeDate(this.startDate) + ' - ' + this.pipeDate(this.endDate) : this.timePeriod,
          },
          vAxis: {
            viewWindow: {
              min: 0
            },
            format: this.lineChartData.Unit && this.lineChartData.Unit !== '' ? `#'${this.lineChartData.Unit}'` : '#',
          },
          treadline: null,
          series: {
            0: {
              color: this.lightPrimaryColor ? this.lightPrimaryColor : '#0971ce',
              pointShape: 'circle', // this is the default
              lineWidth: 2 // this is the default
            },
            1: {
              color: this.chartColors[0].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            2: {
              color: this.chartColors[1].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            3: {
              color: this.chartColors[2].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            4: {
              color: this.chartColors[3].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            5: {
              color: this.chartColors[4].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            6: {
              color: this.chartColors[5].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            7: {
              color: this.chartColors[6].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            }
          },
          //curveType: 'function',
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          }
        }
      }
      this.chart = new google.visualization.LineChart(document.getElementById(this.linechartId));
      this.chart.draw(myData, this.chartOptions);
    }
    else {
      this.noDataOnChart = true;
      if (this.chart) {
        this.chart.clearChart();
      }
    }
  }

  private loadFromMultiDataList(): void {
    if (this.lineChartMultiDataList) {
      this.noDataOnChart = false;

      let myData = new google.visualization.DataTable();

      myData.addColumn('datetime', 'X');

      this.propertiesList.forEach((property) => {
        myData.addColumn('number', property);
      });

      Object.keys(this.lineChartMultiDataList).forEach((date) => {
        let newRow: any[] = [new Date(+date * 1000)];

        this.propertiesList.forEach((property) => {
          newRow.push(this.lineChartMultiDataList[date][property]);
        });

        myData.addRow(newRow);
      });

      if (this.darkThemeActive) {
        this.chartOptions = {
          height: 550,
          chartArea: { width: '80%', height: '70%' },
          legend: {
            position: 'top',
            textStyle: { color: 'white' },
            scrollArrows: { inactiveColor: "white", activeColor: "#0971ce" },
            pagingTextStyle: { color: 'white' },
          },
          isStacked: true,
          displayAnnotations: true,
          backgroundColor: 'transparent',
          hAxis: {
            //format: 'dd/MM/yyyy HH:mm:ss',
            format: 'dd/MM/yyyy HH:mm',
            textStyle: { color: 'white' },
            textPosition: 'out',
            title: this.multiChartTitle,
            titleTextStyle: { color: 'white' }
          },
          vAxis: {
            textStyle: { color: 'white' },
            viewWindow: {
              min: 0
            },
            titleTextStyle: { color: 'white' }
          },
          treadline: null,
          series: {
            0: {
              color: this.darkPrimaryColor ? this.darkPrimaryColor : '#0971ce',
              pointShape: 'circle', // this is the default
              lineWidth: 2 // this is the default
            },
            1: {
              color: this.chartColors[0].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            2: {
              color: this.chartColors[1].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            3: {
              color: this.chartColors[2].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            4: {
              color: this.chartColors[3].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            5: {
              color: this.chartColors[4].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            6: {
              color: this.chartColors[5].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            7: {
              color: this.chartColors[6].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            }
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          },
        }

      } else {
        this.chartOptions = {
          height: 550,
          chartArea: { width: '80%', height: '70%' },
          legend: {
            position: 'top',
            scrollArrows: { inactiveColor: "black", activeColor: "#0971ce" },
            pagingTextStyle: { color: 'black' },
          },
          isStacked: true,
          displayAnnotations: true,
          backgroundColor: 'transparent',
          hAxis: {
            //format: 'dd/MM/yyyy HH:mm:ss',endDate
            format: 'dd/MM/yyyy HH:mm',
            textPosition: 'out',
            title: this.multiChartTitle,
          },
          vAxis: {
            viewWindow: {
              min: 0
            },
          },
          treadline: null,
          series: {
            0: {
              color: this.lightPrimaryColor ? this.lightPrimaryColor : '#0971ce',
              pointShape: 'circle', // this is the default
              lineWidth: 2 // this is the default
            },
            1: {
              color: this.chartColors[0].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            2: {
              color: this.chartColors[1].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            3: {
              color: this.chartColors[2].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            4: {
              color: this.chartColors[3].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            5: {
              color: this.chartColors[4].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            6: {
              color: this.chartColors[5].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            },
            7: {
              color: this.chartColors[6].trim(),
              tooltip: false,
              enableInteractivity: false,
              lineWidth: 1
            }
          },
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 30.0,
            maxZoomOut: 1.0
          }
        }
      }

      this.chart = new google.visualization.LineChart(document.getElementById(this.linechartId));
      this.chart.draw(myData, this.chartOptions);
    } else {
      this.noDataOnChart = true;
      if (this.chart) {
        this.chart.clearChart();
      }
    }
  }

  private fromDegreesToCoordinates(degrees: number): string {
    let directions = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    let i: number = Math.round((degrees + 11.25) / 22.5);
    return directions[i % 16];
  }

  private _formatDate(inputDate: string): String {
    let formattedDate = new Date(inputDate);
    if (!formattedDate && formattedDate === undefined) {
      return '';
    }

    let day = formattedDate.getDate();
    let month = formattedDate.getMonth() + 1;
    let year = formattedDate.getFullYear();
    let hour = formattedDate.getHours();
    let minutes = formattedDate.getMinutes();

    return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes.toString().padStart(2, "0");
  }

  private pipeDate(inputDate: number): string {
    return this.datePipe.transform(inputDate * 1000, 'short')
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));

    return colors;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isDarkActive = changes['darkThemeActive'];
    if (isDarkActive?.currentValue !== isDarkActive?.previousValue && isDarkActive?.firstChange == false) {
      this.tryDrawChart();
    }
    if (changes.sensor?.currentValue !== changes.sensor?.previousValue && changes.sensor?.firstChange == false) {
      this.tryDrawChart();
    }
  }

  /* resetClick(): void {
    this.chart.clearChart();
    this.tryDrawChart();
  } */

}
