import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Widget } from '../../models/dashboardList';
@Component({
  selector: 'urban-remove-widget-dialog',
  templateUrl: './remove-widget-dialog.component.html',
  styleUrls: ['./remove-widget-dialog.component.scss']
})
export class RemoveWidgetDialogComponent implements OnInit {

  widgetToRemove: Widget;

  constructor( public dialogRef: MatDialogRef<RemoveWidgetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {widgetToRemove: Widget} ) {
      this.widgetToRemove = data.widgetToRemove;
    } 

  ngOnInit(): void {
  }
  cancel(){
    this.dialogRef.close();
  }
  remotion(){
    this.dialogRef.close(this.widgetToRemove.Id);
  }
  
}
