import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { Device } from '../../../shared/models/device';
import { RemoteControlService } from '../../../shared/models/siralab/remoteControlService';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SiralabSubscriptionsService } from '../../../shared/services/siralab-subscriptions/siralab-subscriptions.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { takeUntil, first } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
import { MatSliderChange } from '@angular/material/slider';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'urban-remote-control-detail',
  templateUrl: './remote-control-detail.component.html',
  styleUrls: ['./remote-control-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RemoteControlDetailComponent implements OnInit, OnDestroy {
  public currentDevice: Device;
  public myRemoteControls: RemoteControlService[] = [];
  public expandedElement: RemoteControlService | null
  public displayedColumns = ['Description', 'Unit', 'State', 'EditState'];
  public myDataSource: MatTableDataSource<RemoteControlService>;
  private ngRCServicesUnsubscribe: Subject<void> = new Subject<void>();
  private ngRefreshUnsubscribe: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private siralabSubscriptionsService: SiralabSubscriptionsService,
    private router: Router,
    public dialog: MatDialog,
    private passDataService: PassDataService
  ) { }

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
        this.loadData(deviceId);
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }
    });
  }

  private loadData(deviceId: string): void {
    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;

        this.getData();
      }
      else {
        this.setErrorAndGoToMain();
      }
    });


  }

  private getData(): void {
    this.siralabSubscriptionsService.getDeviceReportComand(this.currentDevice.Properties.Id);

    this.passDataService.RCServices$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res) {
          this.myRemoteControls = res;
          this.myDataSource = new MatTableDataSource(this.myRemoteControls)
        }

        this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(settings => {
          let refreshTimeMs = settings?.Settings['remote_control_detail'];
          /* this.interval = setInterval(() => {
            this.refresh();
          }, refreshTimeMs !== null && refreshTimeMs !== undefined && refreshTimeMs !== '0' ? Number(refreshTimeMs) : 30000); */
          const rxjsInterval = interval(refreshTimeMs !== null && refreshTimeMs !== undefined && refreshTimeMs !== '0' ? Number(refreshTimeMs) : 30000);
          rxjsInterval.pipe(takeUntil(this.ngRCServicesUnsubscribe)).subscribe(() => {
            this.refresh();
            this.ngRCServicesUnsubscribe.next();
          });
        })
      });
  }

  public refresh(): void {
    this.siralabSubscriptionsService.getDeviceReportComand(this.currentDevice.Properties.Id);

    this.passDataService.RCServices$
      .pipe(takeUntil(this.ngRefreshUnsubscribe))
      .subscribe(res => {
        if (res) {
          this.myRemoteControls = res;
          this.myDataSource = new MatTableDataSource(this.myRemoteControls)
        }
        this.ngRefreshUnsubscribe.next();
      });
  }


  changeLight(event: MatSliderChange, selectedServiceType: number, selectedHash: number): void {
    const objectToPass = {
      serviceType: selectedServiceType,
      serviceHash: selectedHash,
      value: event.value,
      id: +this.currentDevice.Properties.Id
    }
    this.siralabSubscriptionsService.setEntityStateComand(objectToPass);
  }

  toggleRelay(event: MatSlideToggleChange, selectedServiceType: number, selectedHash: number): void {

    let booleanValue: number;
    if (event.checked == true) {
      booleanValue = 1;
    } else {
      booleanValue = 0;
    }

    const objectToPass = {
      serviceType: selectedServiceType,
      serviceHash: selectedHash,
      value: booleanValue,
      id: +this.currentDevice.Properties.Id
    }
    this.siralabSubscriptionsService.setEntityStateComand(objectToPass);
  }

  onUnload(): void {
    this.siralabSubscriptionsService.deleteRemoteControlStateComand(true);
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngRCServicesUnsubscribe.next();
    this.ngRCServicesUnsubscribe.complete();
    this.ngRefreshUnsubscribe.next();
    this.ngRefreshUnsubscribe.complete();
    this.siralabSubscriptionsService.deleteRemoteControlStateComand(true);
  }
}
