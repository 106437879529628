import { Component, OnDestroy, OnInit } from '@angular/core';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-wip',
  templateUrl: './wip.component.html',
  styleUrls: ['./wip.component.scss']
})
export class WipComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isDarkActive: boolean;

  constructor(
    public router: Router,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
  }

  goToMain(): void {
    let currentUrl: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
    this.mainService.updateAllDataAndGoToMainComand();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
