<div *ngIf="!data.seeMore">
    <h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h1>
    <h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h1>
    <mat-dialog-content>
        <form [formGroup]="form">
            <mat-form-field>
                <mat-label>{{ 'GENERAL.TITLE' | translate }}</mat-label>
                <input matInput formControlName="title" autocomplete="off" required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'GENERAL.SOURCE' | translate }}</mat-label>
                <input matInput formControlName="source" autocomplete="off" required>
            </mat-form-field>
            <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="pl-0">
                    <mat-panel-title class="pl-2">
                        {{ 'GENERAL.IMAGE' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-0 mt-1">
                    <div class="image-container mx-auto d-flex align-items-center">
                        <img *ngIf="this.form.controls.image.value !== ''" src="{{ this.form.controls.image.value }}"
                            class="img-fluid">
                        <p *ngIf="this.form.controls.image.value === ''">{{ 'NEWS.ADD_IMAGE' | translate }}</p>
                        <input style="display: none;" type="file" (change)="imageUpload($event)"
                            accept=".png, .jpg, .jpeg" #imageInput>
                        <button *ngIf="this.form.controls.image.value === ''" mat-mini-fab color="primary"
                            class="my-fab mat-elevation-z2" (click)="imageInput.click()">
                            <i class="urbanicon-edit"></i>
                        </button>
                        <button *ngIf="this.form.controls.image.value !== ''" mat-mini-fab color="primary"
                            class="my-fab mat-elevation-z2 ml-1" (click)="removeImage()">
                            <i class="urbanicon-remove position-relative" style="top: 2px;"></i>
                        </button>

                        <br>
                        <mat-error *ngIf="imageError" class="text-center">
                            {{ 'ERROR.'+imageError | translate }}
                        </mat-error>
                        <br>
                    </div>
                </div>

            </mat-expansion-panel>
            <br>
            <mat-form-field>
                <mat-label>{{ 'GENERAL.BODY' | translate }}</mat-label>
                <textarea class="tableEvidenceBG" matInput formControlName="body"
                          matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=15
                          autocomplete="off" required></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'GENERAL.DATE' | translate }}</mat-label>
                <input matInput [ngxMatDatetimePicker]="edt" (dateChange)="setDate($event.target.value)"
                    formControlName="date" autocomplete="off" readonly>
                <mat-datepicker-toggle matSuffix [for]="edt"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #edt [showSeconds]=false></ngx-mat-datetime-picker>
            </mat-form-field>

            <mat-error *ngIf="error">
                {{ error }}
            </mat-error>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd"
            (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
        <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd"
            (click)="addition()">{{ 'GENERAL.UPDATE' | translate }}</button>
        <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>

<!-- Il dialog che viene  mostrato se si clicca sul contenuto dalla tabella -->
<div *ngIf="data.seeMore">
    <mat-dialog-content>
        <h6> <a href="{{news.Source}}" target="_blank">{{news.Source}}</a> </h6>
        <p> {{news.Body}} </p>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-button class="btn outlineActionButton" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>
