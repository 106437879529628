import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { Device } from 'src/app/shared/models/device';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';
import { DeviceGroup } from 'src/app/shared/models/deviceGroup';
import { DeviceType } from 'src/app/shared/models/deviceType';

@Component({
  selector: 'urban-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public myDevices: Device[] = [];
  public myDeviceTypes: DeviceType[] = [];
  public deviceGroups: DeviceGroup[] = [];
  public deviceToShow: Device[] = [];
  public groupToShow: DeviceGroup[] = [];
  public showGroups: boolean = false;
  public mapReady: boolean = false;
  public editMode: string = '';
  public isDarkActive: boolean;
  public activeTab: number = 0;
  public visibilityStatus: Record<string, boolean> = {};

  constructor(private passDataService: PassDataService, private administratorApiService: AdministratorApiService) { }

  ngOnInit(): void {
    this.passDataService.devices$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response?.length > 0) {
        this.myDevices = [...response];
        this.myDeviceTypes = this.getDeviceTypes(this.myDevices);

        if (Object.keys(this.visibilityStatus)?.length === 0) {
          this.deviceToShow = [...this.myDevices];
        } else {
          if ((Object.keys(this.visibilityStatus)?.length !== this.myDeviceTypes.length)) {
            this.myDevices.forEach(device => {
              const id = device.Model?.Type?.Id;
              if (id && !(id in this.visibilityStatus)) {
                this.visibilityStatus[id] = true;
              }
            });
          }

          this.deviceToShow = this.myDevices.filter(device => {
            return this.visibilityStatus[device.Model?.Type?.Id];
          });

          if (this.activeTab === 1) {
            this.deviceToShow = this.setDeviceToShow();
          }
        }
      }
    });

    this.initializeVisibilityStatus(this.myDevices);

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.mapReady = mapLoading;
    });

    this.administratorApiService.deviceGroupList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(groups => {
      if (groups?.length > 0) {
        this.deviceGroups = groups;
        this.groupToShow = [...this.deviceGroups];
      }
    });
  }

  private getDeviceTypes(devices: Device[]): DeviceType[] {
    let uniqueTypes: DeviceType[] = [];

    devices.forEach(device => {
      const unique = device.Model?.Type;

      if (unique && !uniqueTypes.some(type => type.Id === unique.Id)) {
        uniqueTypes.push(unique);
      }
    });

    return uniqueTypes;
  }

  public onTabChange(event: MatTabChangeEvent): void {
    this.activeTab = event.index;
    this.resetVisibility();

    if (this.activeTab === 1) {
      this.initializeVisibilityStatus(this.deviceGroups);
    } else {
      this.initializeVisibilityStatus(this.myDevices);
    }
  }

  private initializeVisibilityStatus(items: Array<DeviceGroup | Device>): void {
    this.showGroups = this.activeTab === 1;

    this.visibilityStatus = items.reduce((status, item) => {
      let id = item.Id;

      if (this.isDevice(item) && item.Model?.Type?.Id && !status[id]) {
        id = item.Model.Type.Id;
      }

      status[id] = true;
      return status;
    }, {});
  }

  private isDevice(item: Device | DeviceGroup): item is Device {
    return (
      item && typeof item === 'object' && 'Model' in item
    );
  }

  public executeAction(action: string): void {
    this.editMode = action;
  }

  public toggleVisibility(id: string): void {
    this.visibilityStatus[id] = !this.visibilityStatus[id];

    if (this.activeTab === 1) {
      this.updateVisibility(id);
    } else {
      this.updateVisibility();
    }
  }

  private updateVisibility(id?: string): void {
    if (id) {
      const passedGroup = this.deviceGroups.find(group => group.Id === id);

      if (this.visibilityStatus[id]) {
        const missingDevices = passedGroup?.Devices?.filter(device => !this.deviceToShow.some(sd => sd.Id === device.Id));

        if (missingDevices?.length) {
          this.resetVisibility();
        }
      }

      this.deviceToShow = this.setDeviceToShow();
      this.groupToShow = this.deviceGroups.filter(group => this.visibilityStatus[group.Id]);
    } else {
      this.deviceToShow = this.myDevices.filter(device => {
        const typeId = device.Model?.Type.Id;

        return typeId && this.visibilityStatus[typeId];
      });
    }
  }

  public updateDeviceGroups(groups: DeviceGroup[]): void {
    if (groups?.length > 0) {
      this.deviceGroups = groups;

      if (this.showGroups) {
        this.groupToShow = this.deviceGroups.filter(group => this.visibilityStatus[group.Id]);
      } else {
        this.groupToShow = [...groups];
      }
    }
  }

  private setDeviceToShow(): Device[] {
    let filteredDevices: Device[] = [];

    filteredDevices = this.deviceToShow.filter(device => {
      return device.Groups?.length > 0
        ? device.Groups.some(group => this.visibilityStatus[group.Id])
        : true;
    });

    return filteredDevices;
  }

  private resetVisibility(): void {
    this.deviceToShow = [...this.myDevices];
    this.groupToShow = [...this.deviceGroups];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
