<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{deviceType?.Name}}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off"
                required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.ICON' | translate }}</mat-label>
            <input matInput formControlName="icon" autocomplete="off"
                required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.MAP_PIN' | translate }}</mat-label>
            <input matInput formControlName="mapPin"
                autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.COLOR' | translate }}</mat-label>
            <input matInput formControlName="color" autocomplete="off"
                required>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div>
            <button mat-button class="btn actionButton" type="button"
                id="updateButton" (click)="update()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
        </div>
    </form>

        <br>

        <div class="customTable mt-5">
            <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4">
                <span class="title3">
                    {{ 'DEVICE_TYPE.CAPABILITIES' | translate }}
                </span>

                <button mat-button class="btn actionButton" type="button"
                    id="addModelButton" (click)="addCapability()">
                    {{ 'DEVICE_TYPE.ADD_CAPABILITY' | translate }}
                </button>
            </div>

            <div class="d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
                <mat-form-field class="tableSearch" appearance="outline">
                    <div matPrefix class="prefix">
                        <i class="urbanicon-search"></i>
                    </div>
                    <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event)"
                        autocomplete="off" [(ngModel)]="actualFilter">
                    <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                        <i class="urbanicon-close"></i>
                    </button>
                </mat-form-field>
            </div>

            <div class="tableHeight" *ngIf="deviceTypeCapabilities?.length > 0">
                <table [dataSource]="filteredData | paginate: config"
                    mat-table class="w-100 noTableHover">
                    <!-- Name Column -->
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Name }}
                        </td>
                    </ng-container>

                    <!-- Label Column -->
                    <ng-container matColumnDef="Label">
                        <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.LABEL' | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Label }}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ACTION' | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Action.length > 20 ? element.Action.slice(0, 30) +"..." : element.Action }}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="Order">
                        <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ORDER' | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Order }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Detail">
                        <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                        <td mat-cell *matCellDef="let element" class="text-center pr-1">
                            <div class="pr-2">
                                <i class="mr-2" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                    (click)="openCapabilityDetail(element)" class="iconInfoButton urbanicon-info-outline">
                                </i>
                                <i class="mr-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                    (click)="capabilityDelete(element)" class="iconDeleteButton urbanicon-delete">
                                </i>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="capabilityColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let element; columns: capabilityColumns;"></tr>
                </table>
            </div>

            <div class="py-5 text-center" *ngIf="deviceTypeCapabilities?.length === 0">
                {{ 'DEVICE_TYPE.NO_CAPABILITIES_MESSAGE' | translate}}
            </div>

            <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
                <div class="d-flex flex-wrap my-3 my-sm-0">
                    <pagination-controls class="customNgxPaginator"
                        [maxSize]="7"
                        [directionLinks]="true"
                        [previousLabel]="''"
                        [nextLabel]="''"
                        [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                        [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                        [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                        (pageChange)="paginatorOnPageChange($event)">
                    </pagination-controls>

                    <div class="paginatorPageCounter">
                        {{config.currentPage}} {{'GENERAL.OF' | translate}}
                        {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                        {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                    </div>
                </div>

                <div>
                    <mat-form-field class="customSelect" appearance="fill">
                        <mat-select [(value)]="config.itemsPerPage">
                            <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                                {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
</div>
