import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationDialogComponent} from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {LocationDialogComponent} from '../../../shared/dialogs/location-dialog/location-dialog.component';
import {Location} from '../../../shared/models/location';
import {UserRoles} from '../../../shared/models/userRoles';
import {ApiService} from '../../../shared/services/api.service';
import {PassDataService} from '../../../shared/services/pass-data/pass-data.service';
import * as MainActions from '../../../store/main/main.actions';
import {MainState} from '../../../store/main/main.reducer';
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {Router} from "@angular/router";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy {

  locationColumns = ['Name', 'Address', 'Zip', 'City', 'State', 'Country', 'Detail'];
  public locations: Location[] = [];
  public filteredData: Location[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public userRoles: UserRoles['Roles'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private store: Store<MainState>
  ) {
  }

  ngOnInit(): void {
    this._initialize();
  }

  private _initialize(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;


      this.apiService.locations().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.locations = result;
          this.filteredData = this.locations;

          this.store.dispatch(MainActions.setLocations({locations: this.locations}));
        }
      })
    })
  }

  public add(): void {
    const addDialogRef = this.dialog.open(LocationDialogComponent, {
      width: '50%',
      maxWidth: 500
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.locationAdd(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public detail(location: Location): void {
    if (!location) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: location.Id, BackRoute: 'locations'});
    this.router.navigate(['main/location']);
  }

  public delete(location: Location): void {
    if (!location || location === undefined) {
      return;
    }
    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.locationDelete(location.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.locations?.filter((location: Location) =>
      [
        location.Name?.toLowerCase(), 
        location.Address?.toLowerCase(), 
        location.Zip?.toLowerCase(), 
        location.City?.toLowerCase(),
        location.Zip?.toLowerCase(), 
        location.State?.toLowerCase(),
        location.Country?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
