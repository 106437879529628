import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SerializationHelper } from '../../../serializationHelper';

@Component({
  selector: 'urban-smart-dialog',
  templateUrl: './smart-dialog.component.html',
  styleUrls: ['./smart-dialog.component.scss']
})
export class SmartDialogComponent implements OnInit {

  public body: object = {};
  public parameters: object = {};
  public action: string;

  constructor(public dialogRef: MatDialogRef<SmartDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        body: string,
        parameters: string,
        action: string
      }) { }

  ngOnInit(): void {
    this.body = this.data.body !== undefined && this.data.body !== null ? SerializationHelper.toInstance(this.data.body) : undefined;

    let parametersArray: string[] = this.data.parameters !== undefined && this.data.parameters !== null && this.data.parameters !== '' ? this.data.parameters.split('&') : undefined;

    if (parametersArray !== undefined) {
      this._buildParams(parametersArray);
    }

    this.action = this.data.action;
  }

  private _buildParams(parametersArray: string[]) {
    parametersArray.forEach(element => {
      let key = element.split('=')[0];
      let value = element.split('=')[1];

      this.parameters[key] = value;
    })
  }

  getKeys(element: object): string[] {
    if (element === undefined || element === null || element === {}) {
      return [];
    }

    return Object.keys(element);
  }

  isObject(element: object, key: string): boolean {
    return typeof (element[key]) === 'object';
  }

  showAsString(element: object): string {
    return JSON.stringify(element);
  }

  continue() {

    if (this.body !== undefined || this.body !== null) {

      for (let key of Object.keys(this.body)) {
        let value = (<HTMLInputElement>document.getElementById(key))?.value;
        if (this.isObject(this.body, key)) {
          for (let subkey of Object.keys(this.body[key])) {
            let subValue = (<HTMLInputElement>document.getElementById(key + subkey))?.value;

            if (subValue != undefined || subValue != null) {
              let subType: string = this.getType(this.body[key], subkey);
              if (subType === 'number') {
                this.body[key][subkey] = +subValue;
              }
              else {
                if (value === 'true') {
                  this.body[key][subkey] = true;
                }
                else if (value === 'false') {
                  this.body[key][subkey] = true;
                }
                else {
                  this.body[key][subkey] = value;
                }
              }
            }
          }
        }
        else {
          if (value != undefined || value != null) {

            let valueType: string = this.getType(this.body, key);

            if (valueType === 'number') {
              this.body[key] = +value;
            }
            else {
              if (value === 'true') {
                this.body[key] = true;
              }
              else if (value === 'false') {
                this.body[key] = true;
              }
              else {
                this.body[key] = value;
              }
            }
          }
        }
      }
    }

    let params: string = '';
    if (this.parameters !== undefined || this.parameters !== null) {
      for (let key of Object.keys(this.parameters)) {
        let value = (<HTMLInputElement>document.getElementById(key))?.value;
        if (value != undefined || value != null) {
          params = params + key + '=' + value + '&';
        }
      }
    }

    if (params.endsWith('&')) {
      params = params.substr(0, params.length - 1);
    }

    this.dialogRef.close({ body: this.body !== undefined && this.body !== null ? JSON.stringify(this.body) : '', parameters: params })
  }

  public hasLength(element: object): boolean {
    return Object.keys(element).length > 0;
  }

  public getType(element: object, key: string): string {
    let typeResult: string = typeof (element[key])
    return typeResult;
  }

  cancel() {
    this.dialogRef.close();
  }

}
