<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'SMSS.ADVERTISERS.CAMPAIGNS' | translate }} - {{advertiser?.CompanyName}}
        </span>
    </div>

    <div class="types-table mat-elevation-z4">
        <table class="w-100 tableSimpleHover" mat-table [dataSource]="campaignsDataSource">

            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ID' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.id }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.DESCRIPTION' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.Description }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Detail">
                <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                <td mat-cell *matCellDef="let element" class="text-center pr-1">
                    <div class="pr-2">
                        <i class="mr-2 urbanicon-info-outline" matTooltip="{{'GENERAL.DETAIL' | translate }}" (click)="detail(element)"></i>
                        <i class="mr-2 urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="delete(element)"></i>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="campaignColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: campaignColumns;"></tr>
        </table>
    </div>
    <br>
    <div>
        <button mat-button class="btn actionButton ml-2" type="button" id="addModelButton"
            (click)="add()">
            {{ 'SMSS_ADVERTISER.ADD_CAMPAIGN' | translate }}
        </button>
    </div>


</div>
