<div class="container">
    <div class="d-flex justify-content-between">
        <h1 mat-dialog-title>{{'STATISTICS_DIALOG.TITLE' | translate }}</h1>
        <div id="myTopIcons">
            <i *ngIf="myTraceItem.Error" class="urbanicon-alert" matTooltip="{{'STATISTICS.CHECK_ERROR' | translate }}" (click)="toggleErrorSection()"></i>
            <i *ngIf="myTraceItem.Message" class="urbanicon-sms-outline" matTooltip="{{'STATISTICS.CHECK_MESSAGE' | translate }}" (click)="toggleMessageSection()"></i>
        </div>
    </div>

    <mat-dialog-content>
        <form [formGroup]="detailGroup">
            <div class="myFormContainer">
                <div class="d-flex flex-column justify-content-between box">
                    <mat-form-field>
                        <mat-label>{{'STATISTICS.HOSTNAME' | translate }}</mat-label>
                        <input matInput formControlName="hostname" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.RESOURCE' | translate }}</mat-label>
                        <input matInput formControlName="resource" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.CREATED' | translate }}</mat-label>
                        <input matInput formControlName="created" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.MILLIS' | translate }}</mat-label>
                        <input matInput formControlName="millis" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.DOMAIN' | translate }}</mat-label>
                        <input matInput formControlName="domain" autocomplete="off" readonly>
                    </mat-form-field>
                </div>
                <div class="d-flex flex-column justify-content-between box">
                    <mat-form-field>
                        <mat-label>{{'STATISTICS.CLIENTIP' | translate }}</mat-label>
                        <input matInput formControlName="clientIp" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.SESSIONID' | translate }}</mat-label>
                        <input matInput formControlName="sessionId" autocomplete="off"
                            readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.USERNAME' | translate }}</mat-label>
                        <input matInput formControlName="username" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.METHOD' | translate }}</mat-label>
                        <input matInput formControlName="method" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'STATISTICS.CLASS' | translate }}</mat-label>
                        <input matInput formControlName="class" autocomplete="off" readonly>
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div class="myErrorSection warnColorBG d-flex justify-content-center" *ngIf="showError">
            <p class="p-3 m-0">{{ myTraceItem?.Error }}</p>
        </div>
        <div class="myMessageSection accentColorBG d-flex justify-content-center" *ngIf="showMessage">
            <p class="p-3 m-0">{{ myTraceItem?.Message }}</p>
        </div>

    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-button class="btn outlineActionButton" [mat-dialog-close]="true">{{'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>
