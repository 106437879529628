<div class="goToPageBtn">
    <!-- <button mat-button class="btn outlineActionButton" (click)="null">
        {{ 'GENERAL.GO_TO_PAGE' | translate }}
    </button> -->
</div>
<div class="position-relative mt-2" [ngClass]="dataReady && translationsReady ? 'finalHeight' : 'initialHeight'">
    <div *ngIf="dataReady && translationsReady" class="appearing">
        <urban-google-piechart [darkThemeActive]="this.isDarkActive" [pieDataPassed]="advertisingsVisits"
            [passedElementsDescription]="dataTranslations" pieChartId="marketingWidget">
        </urban-google-piechart>

        <p class="paragraphText text-right mr-3 secondaryTextColor">{{ 'GENERAL.LAST_24H' | translate }}</p>
    </div>

    <div *ngIf="noDataOnChart" class="d-flex align-items-center justify-content-center h-100">
        <p class="paragraphText">{{ 'GENERAL.NO_DATA_IN_24H' | translate }}</p>
    </div>

    <urban-loader [localized]="true" [disappearCondition]="(dataReady && translationsReady) || noDataOnChart"></urban-loader>
</div>
