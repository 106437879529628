import { NavigationInfo } from './../../models/navigationExtra';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { DashboardRequest, Dashboard, WidgetToHandle } from '../../models/dashboardList';
import { DeviceCreateRequest } from '../../models/deviceCreateRequest';
import { DeviceDeleteRequest } from '../../models/deviceDeleteRequest';
import { DevicePropertyRequest } from '../../models/devicePropertyRequest';
import { DeviceUpdateRequest } from '../../models/deviceUpdateRequest';
import { Domain } from '../../models/domain';
import { Location } from '../../models/location';
import { Note } from '../../models/Note';
import { UVNotification } from '../../models/notification';
import { PositionUpdater } from '../../models/positionUpdater';
import { SetUserPropertyRequest } from '../../models/setUserPropertyRequest';

@Injectable({
  providedIn: 'root'
})
export class MainSubscriptionsService {
  private subjectAddWidgets = new Subject<WidgetToHandle[]>();
  private subjectRemoveWidget = new Subject<WidgetToHandle>();
  private subjectDeleteDasboard = new Subject<string>();
  private subjectAddDevice = new Subject<DeviceCreateRequest>();
  private subjectUpdateDevice = new Subject<DeviceUpdateRequest>();
  private subjectDeleteDevice = new Subject<DeviceDeleteRequest>();
  private subjectAddDeviceProperty = new Subject<DevicePropertyRequest>();
  private subjectChangePassword = new Subject<ChangePasswordRequest>();
  private subjectGoToSelectedDashboard = new Subject<Dashboard>();
  private subjectUpdateWidgetPosition = new Subject<PositionUpdater>();
  private subjectUpdateAllDataAndNavigate = new Subject<string>();
  private subjectUpdateAllDataAndGoToMain = new Subject<boolean>();
  private subjectSetSuccessMessage = new Subject<string>();
  private subjectSetNotificationToShow = new Subject<UVNotification>();
  private subjectProfileLanguage = new Subject<string>();
  private subjectProfilePhoneNumber = new Subject<string>();
  private subjectUserPhoneNumber = new Subject<SetUserPropertyRequest>();
  private subjectProfileImage = new Subject<string>();
  private subjectRemoveProfileImage = new Subject<string>();
  private subjectProfileNotes = new Subject<Note[]>();
  private subjectSetCurrentDomainProperty = new Subject<Domain>();
  private subjectAddLocation = new Subject<Location>();
  private subjectSetNavigationInfo = new Subject<NavigationInfo>();
  private subjectUpdateLocation = new Subject<Location>();
  private subjectCreateDashboard = new Subject<DashboardRequest>();
  private subjectUpdateDashboard = new Subject<DashboardRequest>();
  private subjectCustomError = new Subject<string>();

  constructor() { }

  public deleteDashboardComand(dashboardId: string): void {
    this.subjectDeleteDasboard.next(dashboardId);
  }
  public getDeleteDashboardComand(): Observable<string> {
    return this.subjectDeleteDasboard.asObservable();
  }

  public addWidgetsComand(WidgetsToHandle: WidgetToHandle[]): void {
    this.subjectAddWidgets.next(WidgetsToHandle);
  }
  public getAddWidgetsComand(): Observable<WidgetToHandle[]> {
    return this.subjectAddWidgets.asObservable();
  }

  public removeWidgetComand(widgetToRemove: WidgetToHandle): void {
    this.subjectRemoveWidget.next(widgetToRemove);
  }
  public getRemoveWidgetComand(): Observable<WidgetToHandle> {
    return this.subjectRemoveWidget.asObservable();
  }

  public addDeviceComand(deviceToAdd: DeviceCreateRequest): void {
    this.subjectAddDevice.next(deviceToAdd);
  }
  public getAddDeviceComand(): Observable<DeviceCreateRequest> {
    return this.subjectAddDevice.asObservable();
  }

  public updateDeviceComand(deviceToUpdate: DeviceUpdateRequest): void {
    this.subjectUpdateDevice.next(deviceToUpdate);
  }
  public getUpdateDeviceComand(): Observable<DeviceUpdateRequest> {
    return this.subjectUpdateDevice.asObservable();
  }

  public deleteDeviceComand(deviceToDelete: DeviceDeleteRequest): void {
    this.subjectDeleteDevice.next(deviceToDelete);
  }
  public getDeleteDeviceComand(): Observable<DeviceDeleteRequest> {
    return this.subjectDeleteDevice.asObservable();
  }

  public addDevicePropertyComand(deviceProperty: DevicePropertyRequest): void {
    this.subjectAddDeviceProperty.next(deviceProperty);
  }
  public getAddDevicePropertyComand(): Observable<DevicePropertyRequest> {
    return this.subjectAddDeviceProperty.asObservable();
  }

  public changePasswordComand(changePasswordRequest: ChangePasswordRequest): void {
    this.subjectChangePassword.next(changePasswordRequest);
  }
  public getChangePasswordComand(): Observable<ChangePasswordRequest> {
    return this.subjectChangePassword.asObservable();
  }

  public goToSelectedDashboardComand(dashboard: Dashboard): void {
    this.subjectGoToSelectedDashboard.next(dashboard);
  }
  public getGoToSelectedDashboardComand(): Observable<Dashboard> {
    return this.subjectGoToSelectedDashboard.asObservable();
  }

  public updateWidgetPositionComand(makeUpdatePosition: PositionUpdater): void {
    this.subjectUpdateWidgetPosition.next(makeUpdatePosition);
  }
  public getUpdateWidgetPositionComand(): Observable<PositionUpdater> {
    return this.subjectUpdateWidgetPosition.asObservable();
  }

  public changeProfileImageComand(base64Image: string): void {
    this.subjectProfileImage.next(base64Image);
  }
  public getChangeProfileImageComand(): Observable<string> {
    return this.subjectProfileImage.asObservable();
  }

  public removeProfileImageComand(): void {
    this.subjectRemoveProfileImage.next();
  }
  public getRemoveProfileImageComand(): Observable<string> {
    return this.subjectRemoveProfileImage.asObservable();
  }

  public updateAllDataAndNavigateComand(path?: string): void {
    if (path) {
      this.subjectUpdateAllDataAndNavigate.next(path);
    } else {
      this.subjectUpdateAllDataAndNavigate.next(null);
    }

  }
  public getUpdateAllDataAndNavigateComand(): Observable<string> {
    return this.subjectUpdateAllDataAndNavigate.asObservable();
  }

  public updateAllDataAndGoToMainComand(): void {
    this.subjectUpdateAllDataAndGoToMain.next(true);
  }
  public getUpdateAllDataAndGoToMainComand(): Observable<boolean> {
    return this.subjectUpdateAllDataAndGoToMain.asObservable();
  }

  public setSuccessMessageComand(successToSet?: string): void {
    if (successToSet) {
      this.subjectSetSuccessMessage.next(successToSet);
    } else {
      this.subjectSetSuccessMessage.next('');
    }
  }
  public getSetSuccessMessageComand(): Observable<string> {
    return this.subjectSetSuccessMessage.asObservable();
  }

  public changeProfileLanguageComand(language: string): void {
    this.subjectProfileLanguage.next(language);
  }
  public getChangeProfileLanguageComand(): Observable<string> {
    return this.subjectProfileLanguage.asObservable();
  }

  public setNotificationToShowComand(notificationToShow: UVNotification): void {
    this.subjectSetNotificationToShow.next(notificationToShow);
  }
  public getSetNotificationToShowComand(): Observable<UVNotification> {
    return this.subjectSetNotificationToShow.asObservable();
  }

  public changeProfilePhoneNumberComand(newPhoneNumber: string): void {
    this.subjectProfilePhoneNumber.next(newPhoneNumber);
  }
  public getChangeProfilePhoneNumberComand(): Observable<string> {
    return this.subjectProfilePhoneNumber.asObservable();
  }

  public changeUserPhoneNumberComand(request: SetUserPropertyRequest): void {
    this.subjectUserPhoneNumber.next(request);
  }
  public getChangeUserPhoneNumberComand(): Observable<SetUserPropertyRequest> {
    return this.subjectUserPhoneNumber.asObservable();
  }
  public changeProfileNotesComand(newNotes: Note[]): void {
    this.subjectProfileNotes.next(newNotes);
  }
  public getChangeProfileNotesComand(): Observable<Note[]> {
    return this.subjectProfileNotes.asObservable()
  }

  public setCurrentDomainPropertyComand(domain: Domain): void {
    this.subjectSetCurrentDomainProperty.next(domain);
  }
  public getSetCurrentDomainPropertyComand(): Observable<Domain> {
    return this.subjectSetCurrentDomainProperty.asObservable();
  }

  public addLocationComand(location: Location): void {
    this.subjectAddLocation.next(location);
  }
  public getAddLocationComand(): Observable<Location> {
    return this.subjectAddLocation.asObservable();
  }

  public setNavigationInfoComand(navigationInfo?: NavigationInfo): void {
    this.subjectSetNavigationInfo.next(navigationInfo);
  }
  public getSetNavigationInfoComand(): Observable<NavigationInfo> {
    return this.subjectSetNavigationInfo.asObservable();
  }

  public updateLocationComand(location: Location): void {
    this.subjectUpdateLocation.next(location);
  }
  public getUpdateLocationComand(): Observable<Location> {
    return this.subjectUpdateLocation.asObservable();
  }

  public createDashboardComand(dashboard: DashboardRequest): void {
    this.subjectCreateDashboard.next(dashboard);
  }
  public getCreateDashboardComand(): Observable<DashboardRequest> {
    return this.subjectCreateDashboard.asObservable();
  }
  
  public updateDashboardComand(dashboard: DashboardRequest): void {
    this.subjectUpdateDashboard.next(dashboard);
  }
  public getUpdateDashboardComand(): Observable<DashboardRequest> {
    return this.subjectUpdateDashboard.asObservable();
  }

  public setCustomErrorComand(error: string): void {
    this.subjectCustomError.next(error);
  }
  public getSetCustomErrorComand(): Observable<string> {
    return this.subjectCustomError.asObservable();
  }

}
