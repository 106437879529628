<div class="container-fluid">
    <div class="d-flex justify-content-end addCamera">
        <button mat-button class="btn actionButton" (click)="addCamera()" *ngIf="addableCameras.length > 0">
            <i class="text-white urbanicon-add mr-2 align-middle"></i>{{ 'CAMERA.ADD' | translate }}
        </button>
    </div>

    <div class="my-2 d-flex justify-content-center flex-wrap">
        <div *ngFor="let camera of selectedCameras" class="videoDiv mr-2 mb-2 d-flex flex-column"
            #videoContainer [data]='camera.Id'>
            <div class="d-flex flex-row justify-content-between align-items-center">
                <h6 class="ml-2 mb-1 subheadText">{{ camera.Name }} </h6>
                <i (click)="close(camera.Id)" class="close-button mr-1 mb-1 urbanicon-close"
                    matTooltip="{{ 'GENERAL.CLOSE' | translate }}">
                </i>
            </div>
            <!-- <div class="previewContainer" *ngIf="noVideo[camera.Id] === undefined || noVideo[camera.Id]">
                <img [src]="'./../../../../assets/img/streaming-default' + getImageId(camera)  + '.png'"  alt="" width="100%">
                <button type="button" class="btn playBtn" [disabled]="noVideo[camera.Id]">
                    <i class="iconPlayButton urbanicon-play" matTooltip="{{ 'CAMERA.NO_VIDEO' | translate }}"></i>
                </button>
            </div> -->
            <div class="previewContainer" *ngIf="noVideo[camera.Id] === undefined || noVideo[camera.Id]">
                <img [src]="'./../../../../assets/img/streaming-default' + getImageId(camera)  + '.png'" alt="Preview streaming camera" width="100%">
                <button type="button" class="btn playBtn" disabled>
                    <i class="iconPlayButton urbanicon-play" matTooltip="{{ 'CAMERA.NO_VIDEO' | translate }}"></i>
                </button>
            </div>
        </div>
    </div>
</div>
