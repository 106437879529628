import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SMSSDomain } from '../models/smss/smssDomain';
import { SMSSReseller } from '../models/smss/smssReseller';
import { SMSSGateway } from '../models/smss/smssGateway';
import { SMSSUser } from '../models/smss/smssUser';
import { SMSSManager } from '../models/smss/smssManager';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from './loader/loader.service';
import * as MainActions from '../../store/main/main.actions';
import { Store } from '@ngrx/store';
import { MainState } from '../../store/main/main.reducer';
import { SmssWriteResponse } from '../models/smss/smssWriteResponse';
import { SMSSWpTemplate } from '../models/smss/smssWpTemplate';
import { SMSSWpWalledGarden } from '../models/smss/smssWpWalledGarden';
import { SMSSGatewayWpLanguages } from '../models/smss/smssGatewayWpLanguages';
import { SMSSCurrentResponse } from '../models/smss/smssCurrentResponse';

@Injectable({
  providedIn: 'root'
})
export class WifiSmssService {

  constructor(private http: HttpClient, private store: Store<MainState>, private readonly loaderService: LoaderService) { }

  public getSMSSResellers(): Observable<SMSSReseller[]> {
    return this.http.get<any>('int.smss.reseller.find.api').pipe(
      map(res => res.Resellers),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getSMSSManagers(resellerId: string): Observable<SMSSManager[]> {
    return this.http.get<any>(`int.smss.manager.find.api?resellerId=${resellerId}`).pipe(
      map(res => res.Managers),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getSSMSDomains(managerId: string): Observable<SMSSDomain[]> {
    return this.http.get<any>(`int.smss.domain.find.api?managerId=${managerId}`).pipe(
      map(res => res.Domains),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getSMSSGateways(domainId: string): Observable<SMSSGateway[]> {
    return this.http.get<any>(`int.smss.gateway.find.api?domainId=${domainId}`).pipe(
      map(res => res.Gateways),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getSMSSUsers(domainId: string): Observable<SMSSUser[]> {
    return this.http.get<any>(`int.smss.user.find.api?domainId=${domainId}`).pipe(
      map(res => res.Users),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public gatewayRead(gatewayId: string): Observable<SMSSGateway> {
    return this.http.get(
      `int.smss.gateway.read.api?id=${gatewayId}`
    ).pipe(
      map((res: any) => res.Gateway),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public gatewayWrite(request: SMSSGateway): Observable<SmssWriteResponse> {
    return this.http.post(
      `int.smss.gateway.write.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public gatewayDelete(gatewayId: string): Observable<any> {
    return this.http.get(
      `int.smss.gateway.delete.api?id=${gatewayId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public domainRead(domainId: string): Observable<SMSSDomain> {
    return this.http.get(
      `int.smss.domain.read.api?id=${domainId}`
    ).pipe(
      map((res: any) => res.Domain),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public siceHardwareList(): Observable<{ [key: string]: number; }> {
    return this.http.get(
      `int.smss.hardware.list.api`
    ).pipe(
      map((res: any) => res.HardwareList),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public managerRead(managerId: string): Observable<SMSSManager> {
    return this.http.get(
      `int.smss.manager.read.api?id=${managerId}`
    ).pipe(
      map((res: any) => res.Manager),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getWpTemplates(domainId: string, gatewayId? : string): Observable<SMSSWpTemplate[]> {
    return this.http.get<any>(`int.smss.wptemplate.find.api?domainId=${domainId}&gateway=${gatewayId}`).pipe(
      map(res => res.Templates),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public wpTemplateRead(templateId: string): Observable<SMSSWpTemplate> {
    return this.http.get(
      `int.smss.wptemplate.read.api?id=${templateId}`
    ).pipe(
      map((res: any) => res.Template),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public wpTemplateDelete(templateId: string): Observable<any> {
    return this.http.get(
      `int.smss.wptemplate.delete.api?id=${templateId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public wpTemplateWrite(request: SMSSWpTemplate): Observable<SmssWriteResponse> {
    return this.http.post(
      `int.smss.wptemplate.write.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getWpWalledGardens(domainId: string, gatewayId? : string): Observable<SMSSWpWalledGarden[]> {
    return this.http.get<any>(`int.smss.wpwalledgarden.find.api?domainId=${domainId}&gatewayId=${gatewayId}`).pipe(
      map(res => res.WalledGardens),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public wpWalledGardenDelete(walledGardenId: string): Observable<any> {
    return this.http.get(
      `int.smss.wpwalledgarden.delete.api?id=${walledGardenId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public wpWalledGardenWrite(request: SMSSWpWalledGarden): Observable<SmssWriteResponse> {
    return this.http.post(
      `int.smss.wpwalledgarden.write.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public gatewayWpLanguagesRead(gatewayId: string): Observable<SMSSGatewayWpLanguages> {
    return this.http.get(
      `int.smss.gateway.wp.languages.read.api?id=${gatewayId}`
    ).pipe(
      map((res: any) => res.Language),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public gatewayWpLanguagesWrite(request: SMSSGatewayWpLanguages): Observable<SmssWriteResponse> {
    return this.http.post(
      `int.smss.gateway.wp.languages.write.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getCurrentDomain(): Observable<SMSSCurrentResponse> {
    return this.http.get(
      `int.smss.domain.current.api`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

}
