import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MainSubscriptionsService} from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import {
  ConfirmationDialogComponent
} from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {UserRoles} from '../../../../shared/models/userRoles';
import {AdministratorApiService} from '../../../../shared/services/administrator-api.service';
import {ApiService} from '../../../../shared/services/api.service';
import {PassDataService} from '../../../../shared/services/pass-data/pass-data.service';
import {Widget} from "../../../../shared/models/dashboardsAndWidgets";
import {WidgetDialogComponent} from "../../../../shared/dialogs/widget-dialog/widget-dialog.component";
import {WidgetRequest} from "../../../../shared/models/administrator/widgetRequest";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit, OnDestroy {

  widgetColumns = ['Name', 'Id', 'Detail'];
  public widgets: WidgetRequest[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: WidgetRequest[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private administratorService: AdministratorApiService,
    private apiService: ApiService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
      this._initWidgetList();
    });
  }

  public addWidget(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const addWidgetDialogRef = this.dialog.open(WidgetDialogComponent);

      addWidgetDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newWidget => {
        if (newWidget) {
          const addWidgetConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          addWidgetConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.administratorService.addWidget(newWidget).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initWidgetList();
                }
              });
            }
          });
        }
      });
    }
  }

  private _initWidgetList(): void {
    this.apiService.getWidgetList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x && x.widgets) {
        this.widgets = [];

        for (const widget of x.widgets['Widgets']) {
          this.widgets.push({Id: widget.Id, Name: widget.Name, Description: widget.Description});
        }

        this.filteredData = this.widgets;
      }
    });
  }

  public widgetDetail(widget: Widget): void {
    if (!widget || widget === undefined) {
      return;
    }
    const updateDialogRef = this.dialog.open(WidgetDialogComponent, {
      data: {
        widgetRequest: {Id: widget.Id, Name: widget.Name, Description: widget.Description}
      },
      width: '50%',
      maxWidth: 500
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorService.updateWidget(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initWidgetList()
              }
            });
          }
        });
      }
    });
  }

  public widgetDelete(widget: Widget): void {
    if (!widget || widget === undefined) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const deleteWidgetConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteWidgetConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorService.deleteWidget(widget.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initWidgetList();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.widgets?.filter((widget: WidgetRequest) =>
      [widget.Name?.toLowerCase(), widget.Id?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
