<div class="container-fluid px-0 px-md-3" [ngClass]="{'h-100' : tracesDataSource?.data.length === 0}">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'LOGS_STATISTICS.TITLE' | translate }}
        </span>
    </div>

    <urban-searchbar id="intro-logs-statistics-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div *ngIf="tracesDataSource?.data.length > 0" class="d-flex flex-column">
        <div class="d-flex justify-content-between align-items-start mt-5">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput id="filterInput" (keyup)="applyFilter($event.target.value)" (keyup.enter)="newSearch()"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilter('')">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>

            <div class="mt-1">
                <mat-paginator showFirstLastButtons [pageSize]="myPageSize" [pageSizeOptions]="[10, 20, 50, 100]"
                    [pageIndex]="myPageIndex" [length]="myTotalItems" (page)="pageEvent = changePage($event)">
                </mat-paginator>
            </div>
        </div>

        <div class="customTable">
            <table class="w-100 h-100 noTableHover" mat-table [dataSource]="tracesDataSource" matSort
                (matSortChange)="sortData($event)">

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%"> {{'STATISTICS.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{ item.Created*1000 | date : 'short' }} </td>
                </ng-container>

                <ng-container matColumnDef="ClientIp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATISTICS.CLIENTIP' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <a class="pointer" (click)="fromTableToFilter(item.ClientIp)">{{ item.ClientIp }}</a>
                    </td>
                </ng-container>


                <ng-container matColumnDef="SessionId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATISTICS.SESSIONID' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.SessionId" [matTooltipPosition]="'above'">
                        <a class="pointer" (click)="fromTableToFilter(item.SessionId)">
                            {{ item.SessionId?.length > 15 ? item.SessionId.slice(0,15) + "..." : item.SessionId }}
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Url">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATISTICS.URL' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.Resource" [matTooltipPosition]="'above'">
                        {{ item.Resource }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATISTICS.USERNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <a class="pointer" (click)="fromTableToFilter(item.Username)">{{ item.Username }}</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Domain">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATISTICS.DOMAIN' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{ item.Domain }} </td>
                </ng-container>

                <ng-container matColumnDef="Millis">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATISTICS.MILLIS' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{ item.Millis }} ms </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2 d-flex justify-content-start">
                            <i *ngIf="item.Error" matTooltip="{{'STATISTICS.CHECK_ERROR' | translate }}"
                                (click)="openDetailDialog(item, 'error')" class="iconDeleteButton urbanicon-alert">
                            </i>
                            <i *ngIf="item.Message" class="iconInfoButton urbanicon-sms-outline" matTooltip="{{'STATISTICS.CHECK_MESSAGE' | translate }}"
                                (click)="openDetailDialog(item, 'message')">
                            </i>
                            <i matTooltip="{{'STATISTICS.DETAIL' | translate }}"
                                (click)="openDetailDialog(item, '')" class="iconInfoButton urbanicon-info-outline">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="tracesDataSource?.data.length === 0">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
        <div class="d-flex">
            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                {{ 'GENERAL.NEW_SEARCH' | translate }}
            </button>
        </div>
    </div>
</div>
