import { GoogleMarker } from '../../models/googleMarker';
import { timer, Subscription } from 'rxjs';
import { AfterViewInit, Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { PeopleCounterHeatmapElement } from './../../models/peopleCounterHeatmapElement';
import { DangerRateDomainRequest } from '../../models/roadRisk';

@Component({
  selector: 'urban-heatmap-people-maps',
  templateUrl: './heatmap-people-maps.component.html',
  styleUrls: ['./heatmap-people-maps.component.scss']
})
export class HeatmapPeopleMapsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  public heatmap: google.maps.visualization.HeatmapLayer;
  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public mapZoom: number;
  private timerSubscription: Subscription = new Subscription();
  private firstTimeZoomed: boolean = false;
  public refreshMap: boolean = false;
  public gradient: string[] = [
    "rgba(0, 255, 255, 0)",
    "#69D27D",
    "#E7CD77",
    "#EB565D"
  ]
  @Input('heatmapElements') public passedHeatmapElements: PeopleCounterHeatmapElement[];
  @Input('maxValueHeatmap') public heatmapMaxValue: number;
  @Input('passedLastUpdate') public passedLastUpdate: number;
  @Input('passedMarkers') public markers: GoogleMarker[] = [];
  @Output('mapDragEnd') private mapDragEnd: EventEmitter<DangerRateDomainRequest> = new EventEmitter<DangerRateDomainRequest>();
  @Input('darkModeStatus') public isDarkActive: boolean;

  constructor() { }

  ngOnInit(): void {
    this.mapOptions = {
      mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
      gestureHandling: "cooperative",
      mapTypeId: "roadmap"
    } as google.maps.MapOptions
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  public initMap(): void {
    this.setHeatMap();
    let positions: google.maps.LatLng[] = [
      ...this.passedHeatmapElements.map(el => new google.maps.LatLng(el.lat, el.lng)),
      ...this.markers.map((marker => marker.position))
    ]

    this.setBounds(positions);
  }

  public reInitMap(): void {
    this.setBounds(null, false);

    timer(0).subscribe(() => {
      this.setHeatMap();
    });
  }

  public setHeatMap(): void {
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getPoints(),
      maxIntensity: this.heatmapMaxValue,
      map: this.map.googleMap,
      radius: 70,
      gradient: this.gradient
    });
  }

  private setBounds(positions: google.maps.LatLng[], padding: boolean = true): void {
    if (positions?.length) {
      this.mapBounds = new google.maps.LatLngBounds();
      positions.forEach(position => {
        this.mapBounds.extend(position);
      });
    }

    if (this.mapBounds) {
      timer(0).subscribe(() => {
        if (padding) {
          this.map.fitBounds(this.mapBounds);
        } else {
          this.map.fitBounds(this.mapBounds, 0);
        }
      });
    }

  }

  public getPoints() {
    let pointsToShow: google.maps.visualization.WeightedLocation[] = [];
    for (let element of this.passedHeatmapElements) {
      pointsToShow.push({
        location: new google.maps.LatLng(element.lat, element.lng),
        weight: element.value
      });
    }
    return pointsToShow;
  }

  onViewChanged(): void {
    this.timerSubscription = timer(2000).subscribe(() => {
      let position: google.maps.LatLng = this.map.getCenter();
      let bounds: google.maps.LatLngBounds = this.map.getBounds();
      let latRange: number = Math.abs(bounds.getNorthEast().lat() - position.lat());
      let lngRange: number = Math.abs(bounds.getNorthEast().lng() - position.lng());
      let range: number = Math.sqrt(Math.pow(latRange, 2) + Math.pow(lngRange, 2));
      range = (range * 111139) / 1000;
      this.mapDragEnd.emit({ Latitude: position.lat(), Longitude: position.lng(), Range: range });
      this.mapBounds = undefined;
      this.timerSubscription.unsubscribe();
    });
  }

  onMapDragStart(): void {
    this.timerSubscription.unsubscribe();
  }

  onZoomChanged(): void {
    if (this.firstTimeZoomed) {
      this.timerSubscription.unsubscribe();
      this.onViewChanged();
    }
    else {
      this.firstTimeZoomed = true;
    }
  }

  openInfoWindow(marker: MapMarker, windowIndex: number, info: string) {
    if (info.startsWith('Location:')) {
      return
    }
    else {
      /// stores the current index in forEach
      let curIdx = 0;
      this.infoWindowsView.forEach((window: MapInfoWindow) => {
        if (windowIndex === curIdx) {
          window.open(marker);
          curIdx++;
        } else {
          curIdx++;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['passedHeatmapElements'] && !changes['passedHeatmapElements'].firstChange
      && changes['passedHeatmapElements'].currentValue !== changes['passedHeatmapElements'].previousValue) {
      this.setHeatMap();
    }

    if (changes.isDarkActive && changes.isDarkActive.currentValue !== changes.isDarkActive.previousValue && !changes.isDarkActive.firstChange) {
      this.mapZoom = this.map.getZoom();
      this.mapBounds = this.map.getBounds();

      this.refreshMap = true;
      timer(0).subscribe(() => {
        this.mapOptions = {
          mapId: this.isDarkActive ? 'af8729d51fc92cc9' : '805d80ad475b1388',
          gestureHandling: "cooperative",
          mapTypeId: "roadmap",
          zoom: this.mapZoom
        } as google.maps.MapOptions;

        this.refreshMap = false;
        this.reInitMap()
      });
    }
  }
}
