export class GoogleMarker {
    position: google.maps.LatLng;
    label: Label;
    title: string;
    options: Options;
    info?: string;
    childs?: InfoContentData[];
    groups?: DeviceGroupData[];

    constructor(
        newPosition: google.maps.LatLng,
        newLabel: Label,
        newTitle: string,
        newOptions: Options,
        newInfo: string,
        childs: InfoContentData[]
    ) {
        this.position = newPosition;
        this.label = newLabel;
        this.title = newTitle;
        this.options = newOptions;
        this.info = newInfo;
        this.childs = childs;
    }
}

export class ParkingGoogleMarker {
  position: google.maps.LatLng;
  label: Label;
  title: string;
  options: Options;
  info?: string;
  childs?: InfoContentData[];
  groups?: DeviceGroupData[];
  free?: number;
  occupied?: number;

  constructor(
      newPosition: google.maps.LatLng,
      newLabel: Label,
      newTitle: string,
      newOptions: Options,
      newInfo: string,
      childs: InfoContentData[],
      newFree?: number,
      newOccupied?: number
  ) {
      this.position = newPosition;
      this.label = newLabel;
      this.title = newTitle;
      this.options = newOptions;
      this.info = newInfo;
      this.childs = childs;
      this.free = newFree;
      this.occupied = newOccupied;
  }
}

interface Label {
    color: string,
    text: string,
    fontSize: string,
    fontWeight: string,
    labelOrigin: google.maps.Point,
    anchor: google.maps.Point,
    origin: google.maps.Point,
    scaledSize: google.maps.Size
}

interface Options {
    animation?: google.maps.Animation;
    icon: google.maps.Icon;
    draggable?: boolean;
}

export class InfoContentData {
    Name: string
    Icon: string
    Elements: number;

    constructor(name: string, icon: string) {
        this.Name = name;
        this.Icon = icon;
        this.Elements = 1;
    }
}

export class DeviceGroupData{
    Id : string
    Name: string
    Color: string

    constructor(id: string, name: string, color: string) {
        this.Id = id;
        this.Name = name;
        this.Color = color;
    }
}
