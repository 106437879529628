import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ApiService } from '../../../shared/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { TraceItem, TraceListRequest } from '../../../shared/models/traceListElements';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Domain } from '../../../shared/models/domain';
import { MatDialog } from '@angular/material/dialog';
import { PlatformStatisticsDetailDialogComponent } from '../../../shared/dialogs/platform-statistics-detail-dialog/platform-statistics-detail-dialog.component';
import { Role } from 'src/app/shared/models/userRoles';
import { SearchDates } from 'src/app/shared/models/searchDates';

@Component({
  selector: 'urban-logs-statistics',
  templateUrl: './logs-statistics.component.html',
  styleUrls: ['./logs-statistics.component.scss']
})
export class LogsStatisticsComponent implements OnInit, OnDestroy {

  public myTraces: TraceItem[] = [];
  public displayedColumns: string[] = ['Created', 'ClientIp', 'SessionId', 'Url', 'Username', 'Domain', 'Millis', 'Detail'];
  public tracesDataSource: MatTableDataSource<TraceItem>;
  public pageEvent: PageEvent;
  public sortEvent: Sort;
  public isDarkActive: boolean;

  @ViewChild(MatPaginator) private paginator: MatPaginator;
  @ViewChild(MatSort) private sort: MatSort;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public myPageIndex: number = 0;
  public myTotalItems: number = 0;
  public myPageSize: number = 10;
  public lastPageSize: number;
  public actualFilter: string = '';
  public lastFilter: string;
  public orderColumn: string = null;
  public orderDirection: string = null;
  public myAvailableDomains: Domain[] = [];
  public myCurrentDomain: Domain = null;
  public isOnlyDomainAdmin: boolean = false;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public startDate: number;
  public endDate: number;
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private passDataService: PassDataService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.loadData(response);
    });

    this.passDataService.currentDarkModeStatus$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private loadData(roles: Role[]): void {
    this.loadingData = true;

    let firstRequest: TraceListRequest = {
      page: this.myPageIndex,
      limit: this.myPageSize,
      filter: '',
      order: 'Created',
      direction: 'DESC'
    }

    if (roles.some(x => x.Name == 'Administrators')) {
      this.isOnlyDomainAdmin = false;
      this.apiService.getTracesList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if(res?.Traces?.length > 0) {
          this.myTotalItems = res.Total;
          this.myTraces = res.Traces;
          this.tracesDataSource = new MatTableDataSource(this.myTraces);
          this.setPaginatorAndSort();
        }
        else {
          this.tracesDataSource = new MatTableDataSource();
        }
        this.loadingData = false;
      });
    } else {
      this.isOnlyDomainAdmin = true;
      this.apiService.getDomainTracesList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if(res?.Traces?.length > 0) {
          this.myTotalItems = res.Total;
          this.myTraces = res.Traces;
          this.tracesDataSource = new MatTableDataSource(this.myTraces);
          this.setPaginatorAndSort();
        }
        else {
          this.tracesDataSource = new MatTableDataSource();
        }
        this.loadingData = false;
      });
    }
  }

  private setPaginatorAndSort(): void {
    this.tracesDataSource.paginator = this.paginator;
    timer(0).subscribe(() => {
      this.tracesDataSource.sort = this.sort;
      this.sort.disableClear = true;
    });
  }

  newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    // questo if fa ripartire la tabella dalla prima pagina se nota che il filtro è diverso
    if (this.lastFilter !== this.actualFilter) {
      this.myPageIndex = 0;
    }
    // questo if fa ripartire la tabella dalla prima pagina se nota che il numero di items per page è diverso
    if (this.lastPageSize !== this.myPageSize) {
      this.myPageIndex = 0;
    }
    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.applyFilter('');
      this.paginator?.firstPage();
    }
    
    let serverRequest: TraceListRequest = {
      page: this.myPageIndex,
      limit: this.myPageSize,
      filter: this.actualFilter,
      order: this.orderColumn ? this.orderColumn : 'Created',
      direction: this.orderDirection ? this.orderDirection : 'DESC',
      start: this.startDate,
      end: this.endDate
    }

    if (this.isOnlyDomainAdmin) {
      this.apiService.getDomainTracesList(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
        if (res) {
          this.myTotalItems = res.Total;
          this.myTraces = res.Traces;
          this.tracesDataSource = new MatTableDataSource(this.myTraces);
          this.lastFilter = this.actualFilter;
          this.lastPageSize = this.myPageSize;
        }

        if (selectedDates !== null && selectedDates !== undefined) {
          this.last24hSearch = selectedDates.last24hSearch; 
        }
        this.loadingData = false;
      });
    }
    else {
      this.apiService.getTracesList(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
        if (res) {
          this.myTotalItems = res.Total;
          this.myTraces = res.Traces;
          this.tracesDataSource = new MatTableDataSource(this.myTraces);
          this.lastFilter = this.actualFilter;
          this.lastPageSize = this.myPageSize;
        }

        if (selectedDates !== null && selectedDates !== undefined) {
          this.last24hSearch = selectedDates.last24hSearch; 
        }
        this.loadingData = false;
      });
    }
  }

  public changePage(event: PageEvent) {
    if (event.previousPageIndex === event.pageIndex - 1) {
      this.myPageIndex = this.myPageIndex + 1;
    } else if (event.previousPageIndex === event.pageIndex + 1) {
      this.myPageIndex = this.myPageIndex - 1;
    } else if (event.pageIndex === Math.floor(event.length / event.pageSize)) {
      this.myPageIndex = Math.floor(event.length / event.pageSize);
    } else if (event.pageIndex === 0) {
      this.myPageIndex = 0;
    }
    this.myPageSize = event.pageSize;
    this.newSearch();
    return event;
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace....useful?
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.actualFilter = filterValue;
    // per ricaricare la tabella se il filtro viene svuotato
    if (filterValue === '') {
      let input = (<HTMLInputElement>document.getElementById('filterInput'));

      if (input && input !== undefined) {
        input.value = filterValue;
      }
    }
  }

  fromTableToFilter(newFilter: string): void {
    this.actualFilter = newFilter;
    (document.getElementById('filterInput') as HTMLInputElement).value = newFilter;
    this.newSearch();
  }

  sortData(event: Sort) {
    this.orderColumn = event.active;
    this.orderDirection = event.direction.toUpperCase();
    this.newSearch();
    return;
  }

  openDetailDialog(singleTrace: TraceItem, highlight: string): void {
    this.dialog.open(PlatformStatisticsDetailDialogComponent, {
      data: { targetTrace: singleTrace, highlight },
      maxWidth: 1000,
      width: '70%'
    });
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
