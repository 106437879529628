import {Component, OnDestroy, OnInit} from '@angular/core';
import {BroadcastClientResponse} from "../../../../shared/models/content-management/broadcastClientResponse";
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserRoles} from "../../../../shared/models/userRoles";
import {PaginationInstance} from "ngx-pagination";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {Router} from "@angular/router";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {PropertyDialogComponent} from "../../../../shared/dialogs/property-dialog/property-dialog.component";
import {ChannelPropertyRequest} from "../../../../shared/models/content-management/channelPropertyRequest";
import {ChannelRequest} from "../../../../shared/models/content-management/channelRequest";
import {ChannelResponse} from "../../../../shared/models/content-management/channelResponse";
import {ApiService} from "../../../../shared/services/api.service";
import {Language} from "../../../../shared/models/language";

@Component({
  selector: 'urban-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit, OnDestroy {


  public properties: ChannelPropertyRequest[] = [];
  public filteredData: ChannelPropertyRequest[] = [];
  public displayedColumns = ['Key', 'Value', 'Detail'];
  private channelRequest: ChannelRequest;
  public channel: ChannelResponse;
  public broadcastClients: BroadcastClientResponse[] = [];
  public languages: Language[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    description: [{value: '', disabled: false}, Validators.required],
    language: [{value: 0, disabled: false}, Validators.required],
    broadcastClient: [{value: '', disabled: false}, Validators.required]
  });

  public error: string | null;
  public userRoles: UserRoles['Roles'];
  private channelId: string;
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];

  constructor(
    private apiService: ApiService,
    private contentManagementService: ContentManagementService,
    private router: Router,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          this.channelId = navInfo.Id;
        }

        if (!this.channelId) {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
        }

        this._initialize();
      });
    });
  }

  private _initialize(): void {
    this.contentManagementService.getChannel(this.channelId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(channel => {
      if (channel) {
        this.channel = channel;

        if (this.channel) {
          this.properties = [];
          this.form.controls.name.setValue(this.channel.Name);
          this.form.controls.description.setValue(this.channel.Description);
          this.form.controls.language.setValue(this.channel.Language.Id.toString());
          this.form.controls.broadcastClient.setValue(this.channel.BroadcastClientId);

          for (const key of Object.keys(this.channel.Properties)) {
            this.properties.push({
              channel: this.channelId,
              key: key,
              value: this.channel.Properties[key]
            })
          }

          this.filteredData = this.properties;
        } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
        }
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
    });

    this.contentManagementService.listBroadcastClient().pipe(takeUntil(this.ngUnsubscribe)).subscribe(broadcastClients => {
      if (broadcastClients) {
        this.broadcastClients = broadcastClients;
      }
    });

    this.apiService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)).subscribe(languages => {
      if (languages) {
        this.languages = languages.List;
      }
    });
  }

  public update(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {

          this.channelRequest = {
            id: this.channelId,
            broadcastClient: this.form.controls.broadcastClient.value,
            language: +this.form.controls.language.value,
            name: this.form.controls.name.value,
            description: this.form.controls.description.value
          };

          this.contentManagementService.updateChannel(this.channelRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this._initialize();
            }
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  public openAddProperty(): void {
    const addPropertyDialog = this.dialog.open(PropertyDialogComponent, {
      disableClose: false
    });

    addPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newKeyValue => {
      if (newKeyValue) {
        const addPropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        addPropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.contentManagementService.propertySetChannel({
              channel: this.channelId,
              key: newKeyValue.key,
              value: newKeyValue.value
            }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public openDetailProperty(property: ChannelPropertyRequest): void {
    if (!property) {
      return;
    }

    const detailPropertyDialog = this.dialog.open(PropertyDialogComponent, {
      data: {key: property.key, value: property.value},
      width: '40%',
      disableClose: false
    });

    detailPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedKeyValue => {
      if (updatedKeyValue) {
        const updatePropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        updatePropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.contentManagementService.propertySetChannel({
              channel: this.channelId,
              key: updatedKeyValue.key,
              value: updatedKeyValue.value
            }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public deleteProperty(property: ChannelPropertyRequest): void {
    if (!property) {
      return;
    }

    const deletePropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    deletePropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.contentManagementService.propertySetChannel({
          channel: this.channelId,
          key: property.key
        }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    })
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.properties?.filter((property: ChannelPropertyRequest) =>
      [property.key?.toLowerCase(), property.value?.toLowerCase()]
        .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
