import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertEventsDialogComponent } from '../../dialogs/alert-events-dialog/alert-events-dialog.component';
import { DeviceEventLatestResponse, EventsDeviceLatest } from '../../models/deviceEventLatest';
import { warningsPerType } from '../../models/deviceWarningEvent';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'urban-alert-events-widget',
  templateUrl: './alert-events-widget.component.html',
  styleUrls: ['./alert-events-widget.component.scss']
})
export class AlertEventsWidgetComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public deviceWarnings: warningsPerType[] = [];
  public warningPerType: Record<string, number> = {};

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.apiService.getDeviceByEventsException().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventLatestResponse) => {
      if (res) {
        res.Devices.forEach((device) => {
          let index: number = this.deviceWarnings.findIndex(w => w.Type === device.Model.Type.Name);
          
          if (index !== -1) {
            this.deviceWarnings[index].Devices.push(device);
          } else {
              this.deviceWarnings.push({
              Type: device.Model.Type.Name,
              Devices: [device]
            });
          }
        });

        this.warningPerType = this.countErrorForDeviceType(this.deviceWarnings);
      }
    });
  }

  public countErrorForDeviceType(deviceWarnings: warningsPerType[]): Record<string, number> {
    let warningPerType: Record<string, number> = {};

    warningPerType = deviceWarnings.reduce((acc, warning) => {
      warning.Devices.forEach(device => {
        acc[device.Model.Type.Name] = (acc[device.Model.Type.Name] || 0) + device.Events.length;
      });
      return acc;
    }, {});

    return warningPerType;
  }

  public goToDetailDialog(type: EventsDeviceLatest[]): void {
    this.dialog.open(AlertEventsDialogComponent, {
      data: { selectedType: type },
      width: '680px'
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
