import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SMSSUser } from '../../../../shared/models/smss/smssUser';
import { WifiSmssService } from '../../../../shared/services/wifi-smss.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SMSSDomain } from '../../../../shared/models/smss/smssDomain';

@Component({
  selector: 'urban-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public users: SMSSUser[] = [];
  public displayedUsersColumns = ['Username', 'Id', 'Country'];
  public myUsersDataSource: MatTableDataSource<SMSSUser>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public domain: SMSSDomain;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wifiSMSSService: WifiSmssService
  ) { }

  ngOnInit(): void {
    const domainId = this.route.snapshot.paramMap.get('id');
    if (domainId === undefined || domainId === null || domainId === '') this.router.navigate(['main/dashboard'])

    this.wifiSMSSService.domainRead(domainId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainResult => {
      if (domainResult) {
        this.domain = domainResult;

        this.wifiSMSSService.getSMSSUsers(domainId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          this.users = res;
          this.myUsersDataSource = new MatTableDataSource(this.users);
        })
      }
    })

  }

  back(): void {
    this.router.navigate(['main/net-wifi/domain', { id: this.domain.ManagerID }]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
