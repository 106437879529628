import { Router } from '@angular/router';
import { MainSubscriptionsService } from './../../../shared/services/main-subscriptions/main-subscriptions.service';
import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ApiService } from '../../../shared/services/api.service';
import { Domain } from '../../../shared/models/domain';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccessCountResponse, AccessListResponse } from '../../../shared/models/accessListElements';
import * as introJs from 'intro.js/intro.js';
import { SearchDates } from 'src/app/shared/models/searchDates';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 500,
  touchendHideDelay: 500,
};

@Component({
  selector: 'urban-platform-statistics',
  templateUrl: './platform-statistics.component.html',
  styleUrls: ['./platform-statistics.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ],
})
export class PlatformStatisticsComponent implements OnInit, OnDestroy {

  private introJS = introJs();
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public myAvailableDomains: Domain[] = [];
  public myCurrentDomain: Domain = null;
  public isOnlyDomainAdmin: boolean = false;
  public myAccessList: AccessListResponse;
  public myOperationsList: AccessCountResponse;
  public isChartDataReady: boolean = false;
  public formGraph: UntypedFormGroup = this.formBuilder.group({
    selectedDomainField: [{ value: null, disabled: this.isOnlyDomainAdmin }],
  });
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private apiSync: ApiSynchronizerService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response.some(x => x.Name == 'Administrators')) {
        this.isOnlyDomainAdmin = false;
        this.passDataService.availableDomains$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
          this.myAvailableDomains = response;
          this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
            this.myCurrentDomain = response;
            this.formGraph.setValue({ selectedDomainField: response.Name });
            this.callApiAsGeneralAdmin();
          });
        });
      } else {
        this.isOnlyDomainAdmin = true;
        this.passDataService.availableDomains$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
          this.myAvailableDomains = response;
          this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
            this.myCurrentDomain = response;
            this.formGraph.setValue({ selectedDomainField: response.Name });
            this.callApiAsDomainAdmin();
          });
        });
      }
    });

    this.passDataService.currentDarkModeStatus$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private apiInitializationAndThenChartReady(selectedDates?: SearchDates): void {
    this.apiSync.initialize();
    this.apiSync.addFeatures(2);
    this.apiSync.waitFeaturesAndThen(() => {
      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch; 
      }
      this.loadingData = false;
      this.isChartDataReady = true;
    });
  }

  private callApiAsGeneralAdmin(selectedDates?: SearchDates): void {
    this.apiInitializationAndThenChartReady(selectedDates);

    this.apiService.getAccessesListAdmin({
      domain: this.formGraph.controls.selectedDomainField.value,
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res && res.Accesses) {
        this.myAccessList = res;
        this.apiSync.loadedFeature();
      }
      else {
        this.myAccessList = { Accesses: null };
        this.apiSync.failedFeature();
      }
    });

    this.apiService.getAccessesCountAdmin({
      domain: this.formGraph.controls.selectedDomainField.value,
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res && res.Accesses) {
        this.myOperationsList = res;
        this.apiSync.loadedFeature();
      }
      else {
        this.myOperationsList = { Accesses: null };
        this.apiSync.failedFeature();
      }
    });
  }

  private callApiAsDomainAdmin(selectedDates?: SearchDates): void {
    this.apiInitializationAndThenChartReady(selectedDates);

    this.apiService.getAccessesListDomainAdmin({
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.Accesses) {
        this.myAccessList = res;
        this.apiSync.loadedFeature();
      }
      else {
        this.myAccessList = { Accesses: null };
        this.apiSync.failedFeature();
      }
    });

    this.apiService.getAccessesCountDomainAdmin({
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.Accesses) {
        this.myOperationsList = res;
        this.apiSync.loadedFeature();
      }
      else {
        this.myOperationsList = { Accesses: null };
        this.apiSync.failedFeature();
      }
    });
  }

  public newSearch(selectedDates: SearchDates) {
    if (!this.isOnlyDomainAdmin) {
      this.loadingData = true;
      this.callApiAsGeneralAdmin(selectedDates);
    }
    else {
      this.loadingData = true;
      this.callApiAsDomainAdmin(selectedDates);
    }
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.STATISTICS_WELCOME',
      'INTRO.STATISTICS_DOMAIN',
      'INTRO.SEARCH'
    ])
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
      this.introJS
      .setOptions({
        steps: [
          {
            title: 'Welcome',
            intro: intros['INTRO.STATISTICS_WELCOME']
          },
          {
            title: 'Domain',
            element: '#intro-statistics-domain',
            intro: intros['INTRO.STATISTICS_DOMAIN'],
            position: 'right',
            scrollTo: "tooltip"
          },
          {
            title: 'Search bar',
            element: '#intro-statistics-search',
            intro: intros['INTRO.SEARCH'],
            position: 'right'
          }
        ],
        showProgress: true
      })
      .start();
    });
  }

  public objectKeysNumber(object: any): number {
    if (object) {
      return Object.keys(object).length;
    }
    else {
      return -1;
    }
  }

  public goToLogsStatistics(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'platform-statistics' });
    this.router.navigate(['main/logs-statistics']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
