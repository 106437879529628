<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'ADVERTISING_EVENT_LIST.TITLE' | translate }}
        </span>
    </div>

    <urban-searchbar id="intro-domain-device-events-search" [passedMaxDaysRange]="3" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24">
    </urban-searchbar>

    <div class="customTable">
        <div class="d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input #filterInput matInput (keyup)="applyFilter($event.target.value)"
                    (keyup.enter)="actualFilter !== '' ? [config.currentPage = 1, newSearch()] : {}"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="applyFilter('')"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>

            <form [formGroup]="filterForm" class="d-flex flex-row flex-wrap filtersGap">
                <mat-form-field *ngIf="domains?.length > 0" class="customSelect noBottom" appearance="fill">
                    <mat-label> {{ 'GENERAL.DOMAIN' | translate }} </mat-label>
                    <mat-select formControlName="domain">
                        <mat-option *ngFor="let domain of domains" [value]="domain.Id">
                            {{ domain.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>


        <div class="tableHeight">
            <table class="w-100" style="table-layout: fixed" mat-table [dataSource]="events | paginate: config">
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">
                        {{ 'GENERAL.ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="text-truncate">
                        {{ element.Id }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="AdvertisingId">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">
                        {{ 'ADVERTISING_EVENT_LIST.ADVERTISING_ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="text-truncate">
                        {{ element.AdvertisingId }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Created * 1000 | date: 'short' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Domain">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.DOMAIN' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Domain.Name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="IP address">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADVERTISING_EVENT_LIST.IP_ADDRESS' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.IpAddress }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="eventColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: eventColumns;"></tr>
            </table>
        </div>

        <div class="d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{ (config.totalItems / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage() }}
                    {{ (config.totalItems / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase() }}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage" (valueChange)="paginatorOnShownChange()">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
