<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span *ngIf="this.isAdd" class="title2">
         {{ 'GENERAL.ADD' | translate }} {{ 'CONTENT_MANAGEMENT.ENTITY' | translate }}
        </span>
        <span *ngIf="!this.isAdd" class="title2">
         {{ 'GENERAL.DETAIL' | translate }} {{ 'CONTENT_MANAGEMENT.ENTITY' | translate }} : {{entityResponse?.Name}}
        </span>

    </div>

    <form [formGroup]="form">
        <div class="d-flex flex-row justify-content-between align-items-start">
            <mat-form-field class="mr-5">
                <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                <input matInput formControlName="name" autocomplete="off" required>
            </mat-form-field>

            <div>
                <button *ngIf="this.isAdd"  mat-button class="btn actionButton mb-3" type="button" id="addButton"
                        (click)="add()">
                    {{ 'GENERAL.ADD' | translate }}
                </button>
                <button *ngIf="!this.isAdd" mat-button class="btn actionButton mb-3" type="button" id="updateButton"
                        (click)="update()">
                    {{ 'GENERAL.UPDATE' | translate }}
                </button>
                <mat-error *ngIf="error">
                    {{ 'GENERAL.' + error | translate }}
                </mat-error>
            </div>
        </div>
    </form>

    <h6 [ngClass]="isDarkActive ? 'headlineDark' : 'headlineLight'">
        {{ 'GENERAL.CONTENT' | translate }}
    </h6>
    <ul *ngIf="entityModel">
        <ng-container *ngFor="let field of entityModel; let i = index; trackBy: trackByFn" [ngTemplateOutlet]="treeNode"
            [ngTemplateOutletContext]="{ $implicit: field, index: i }">
        </ng-container>
    </ul>
    <button mat-button class="btn actionButton squareButton mt-2" (click)="addFirstChildField()"> + </button>
</div>

<ng-template #treeNode let-field let-i="index" let-parent="parent">
    <li class="d-flex flex-row align-items-center">
        <div class="p-3 m-2" [ngClass]="[isDarkActive ? 'objectArrayCardDark' : 'objectArrayCardLight',
            parent && !['object', 'object[]'].includes(parent.type) ? 'errorChild' : '' ]"
            [matTooltip]="parent && !['object', 'object[]'].includes(parent.type) ? ('CONTENT_MANAGEMENT.ERROR_CHILDREN' | translate) : ''">
            <mat-form-field class="mb-3" style="width: 400px">
                <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                <input matInput type="text" [(ngModel)]="field.name" (change)="$event.target.value.trim() === '' ? field.name = 'field' + i.toString() : {}"/>
            </mat-form-field>

            <mat-form-field class="mb-3" style="width: 400px">
                <mat-label>{{ 'GENERAL.TYPE' | translate }}</mat-label>
                <mat-select [(ngModel)]="field.type">
                    <mat-option *ngFor="let type of fieldTypes" [value]="type">{{ type }}</mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="['object', 'object[]'].includes(field.type) || field.children?.length > 0">
                <h6>{{ 'GENERAL.CHILDREN' | translate }}</h6>
                <ul *ngIf="field.children">
                    <ng-container *ngFor="let child of field.children; let j = index; trackBy: trackByFn" [ngTemplateOutlet]="treeNode"
                        [ngTemplateOutletContext]="{ $implicit: child, index: j, parent: field }">
                    </ng-container>
                </ul>
                <button mat-button class="btn actionButton squareButton mt-2" (click)="addChildField(field)"> + </button>
            </ng-container>
        </div>

        <button mat-button class="btn warnButton squareButton ml-2" (click)="removeChildField(parent, i)"> - </button>
    </li>
</ng-template>
