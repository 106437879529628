import { Component, OnInit, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchDates } from '../../models/searchDates';

@Component({
  selector: 'urban-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit, OnChanges {

  @Input('passedMaxDaysRange') private passedMaxDaysRange: number;
  @Input('latestDates') private dates: number;
  @Input('buttonToggleSelected') public buttonToggleSelected: number = null;
  @Input('clearDateUnsubscribeEvent') public clearDateUnsubscribeEvent: boolean;
  @Input('clearDateEvent') public clearDateEvent: boolean;
  @Input('setLatestDates') public setLatestDates: boolean;
  @Input('loadingEvents') public loadingEvents: boolean = false;
  @Input('overlayBG') public overlayBG: boolean = false;
  @Output('newSearch') newSearch = new EventEmitter<any>();
  @Output('stopPreviousSearch') stopPreviousSearch = new EventEmitter<any>();

  public startDate: number;
  public endDate: number;
  public minEndDate: Date;
  public maxEndDate: Date;
  public minStartDate: Date;
  public maxStartDate: Date;
  private previousButtonToggle: number;
  public filterPeriods: number[] = [2,24,48,72];
  public last24hSearch: boolean;
  public defaultHoursRange: number;

  public formDate: UntypedFormGroup = this.formBuilder.group({
    startDate: [{ value: null, disabled: false }],
    endDate: [{ value: null, disabled: false }]
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.buttonToggleSelected === null) {
      this.previousButtonToggle = null;
      this.defaultHoursRange = this.passedMaxDaysRange * 24;
      this.initDates(this.defaultHoursRange);
    } else {
      this.defaultHoursRange = this.buttonToggleSelected;
    }
    
    this.resetLimitDates();
  }

  public onSearch(): void {
    if (this.formDate.controls.startDate.value && this.formDate.controls.endDate.value) {
      this.startDate = this.reformatStart(this.formDate.controls.startDate.value.toString());
      this.endDate = this.reformatEnd(this.formDate.controls.endDate.value.toString());

      if (this.buttonToggleSelected === 24) {
        this.last24hSearch = true;
      } else {
        this.last24hSearch = false;
      }
      
      let selectedDates: SearchDates = { startDate: this.startDate, endDate: this.endDate, last24hSearch: this.last24hSearch };
      this.newSearch.emit(selectedDates);
    } else {
      this.buttonToggleSelected = 24;
    }
  }

  private initDates(hoursRange: number): void {
    this.formDate.reset();
    let now = new Date();
    now.setHours(now.getHours() - hoursRange);
    this.formDate.patchValue({
      startDate: new Date(now)
    });
    this.onStartDateUpdate(now);
  }

  public setTimeRange(event: number): void {
    if (event !== undefined) {
      if (event !== this.previousButtonToggle) {
        this.initDates(event);

        if(this.previousButtonToggle !== undefined) {
          this.onSearch();
        }

        this.previousButtonToggle = event;
      }
    }
  }

  public onStartDateUpdate(datePassed: Date): void {
    this.minEndDate = new Date(datePassed);
    let now = new Date();
    let supposedMaxEndDate = new Date(datePassed);
    supposedMaxEndDate.setDate(datePassed.getDate() + this.passedMaxDaysRange);
    if (now.getTime() > supposedMaxEndDate.getTime()) {
      this.maxEndDate = new Date(supposedMaxEndDate);
    }
    else {
      this.maxEndDate = new Date(now);
    }
    if (this.formDate.controls.endDate.value == null) {
      this.formDate.patchValue({ endDate: new Date(this.maxEndDate) });
      this.onEndDateUpdate(this.maxEndDate);
    }
  }
  public onEndDateUpdate(datePassed: Date): void {
    this.maxStartDate = new Date(datePassed);
    let firstDayOfTheYear = new Date(datePassed.getFullYear(), 0, 1);
    let supposedMinStartDate = new Date(datePassed);
    supposedMinStartDate.setDate(datePassed.getDate() - this.passedMaxDaysRange);
    if (firstDayOfTheYear.getTime() < supposedMinStartDate.getTime()) {
      this.minStartDate = new Date(supposedMinStartDate);
    }
    else {
      this.minStartDate = new Date(firstDayOfTheYear);
    }
    if (this.formDate.controls.startDate.value == null) {
      this.formDate.patchValue({ startDate: new Date(this.minStartDate) });
      this.onStartDateUpdate(this.minStartDate)
    }
  }

  public removeRangeBtnActive() {
    this.previousButtonToggle = null;
    this.buttonToggleSelected = null;
  }

  public reformatStart(dateToFormat: string): number {
    let currentDate = new Date(dateToFormat);
    return Math.floor(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).getTime() / 1000 - 1);
  }

  public reformatEnd(dateToFormat: string): number {
    let currentDate = new Date(dateToFormat);
    return Math.ceil(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).getTime() / 1000 + 1);
  }

  public clearDateAndUnsubscribe(): void {
    this.stopPreviousSearch.emit();
    this.formDate.reset();
    this.removeRangeBtnActive();
  }

  public clearDate(): void {
    this.formDate.reset();
    this.removeRangeBtnActive();
  }

  public onBlankDatesFocus(): void {
    if (this.formDate.controls.startDate.value == null && this.formDate.controls.endDate.value == null) {
      this.resetLimitDates();
    }
    if (this.filterPeriods.includes(this.buttonToggleSelected)) {
      this.resetLimitDates();
      this.clearDateAndUnsubscribe();
    }
  }

  public resetLimitDates(): void {
    this.maxStartDate = new Date();
    this.minStartDate = new Date(this.maxStartDate.getFullYear(), 0, 1);
    this.minEndDate = new Date(this.minStartDate);
    this.maxEndDate = new Date(this.maxStartDate);
  }

  public calcLatestEventDates(): void {
    let start: Date = new Date(this.dates);
    start.setHours(start.getHours() - this.defaultHoursRange);
    let end: Date = new Date(this.dates);
    
    this.onStartDateUpdate(start);
    this.onEndDateUpdate(end);

    this.formDate.patchValue({
      startDate: start,
      endDate: end
    });
  }

  public setLatestEventDates(): void {
    this.calcLatestEventDates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dates']) {
      const dates = changes['dates'];
      if (dates.currentValue !== dates.previousValue && dates.firstChange == false) {
        this.calcLatestEventDates();
      }
    }

    if (changes['setLatestDates']) {
      const setLatestDates = changes['setLatestDates'];
      if (setLatestDates.currentValue !== setLatestDates.previousValue && setLatestDates.firstChange == false) {
        this.setLatestEventDates();
      }
    }

    if (changes['clearDateUnsubscribeEvent']) {
      const clearDateUnsubscribeEvent = changes['clearDateUnsubscribeEvent'];
      if (clearDateUnsubscribeEvent.currentValue !== clearDateUnsubscribeEvent.previousValue && clearDateUnsubscribeEvent.firstChange == false) {
        this.clearDateAndUnsubscribe();
      }
    }

    if (changes['clearDateEvent']) {
      const clearDateEvent = changes['clearDateEvent'];
      if (clearDateEvent.currentValue !== clearDateEvent.previousValue && clearDateEvent.firstChange == false) {
        this.clearDate();
      }
    }
  }

}
