import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { Device } from '../../models/device';
import { GoogleMarker, InfoContentData } from '../../models/googleMarker';

@Component({
  selector: 'urban-devices-map-dialog',
  templateUrl: './devices-map-dialog.component.html',
  styleUrls: ['./devices-map-dialog.component.scss']
})
export class DevicesMapDialogComponent implements OnInit, AfterViewInit {

  public devices: Device[] = [];
  public deviceType: string;
  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  public heatmap: google.maps.visualization.HeatmapLayer;
  public mapBounds: google.maps.LatLngBounds;
  public markers: Array<GoogleMarker> = [];
  private guidPattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

  constructor(
    public dialogRef: MatDialogRef<DevicesMapDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { devices: Device[], deviceType: string }
  ) {

  }

  ngAfterViewInit(): void {
    this.setBounds();
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.devices = this.data?.devices;
    this.deviceType = this.data?.deviceType;

    if (this.devices && this.devices.length > 0) {

      for (let i = 0; i < this.devices.length; i++) {
        let children = this._buildDevices(this.devices[i].Childs);

        this.markers.push(
          new GoogleMarker(
            new google.maps.LatLng(this.devices[i].Latitude, this.devices[i].Longitude),
            {
              color: 'black',
              text: this.devices[i].Name.length > 12 ? this.devices[i].Name.slice(0, 12) + '...' : this.devices[i].Name,
              fontSize: '0px',
              fontWeight: 'normal',
              scaledSize: new google.maps.Size(80, 80),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(20, 0),
              labelOrigin: null
            },
            this.devices[i].Name,
            { animation: google.maps.Animation.DROP, icon: this.getIcon(this.devices[i].Model.Type.MapPin) },
            this.devices[i].Id,
            this._buildInfoContentArray(children)
          )
        );
      }

    }
  }

  private setBounds(): void {
    this.mapBounds = new google.maps.LatLngBounds();

    if (this.mapBounds && this.devices != null && this.devices.length !== undefined) {
      for (let i = 0; i < this.devices.length; i++) {
        this.mapBounds.extend(new google.maps.LatLng(this.devices[i].Latitude, this.devices[i].Longitude));
      }
      timer(0).subscribe(() => {
        this.map.fitBounds(this.mapBounds);
      });
    }

    this.map.options = {
      gestureHandling: "cooperative",
      mapTypeId: "roadmap",
    };
  }

  private getIcon(deviceTypeMapPin: string): google.maps.Icon {
    if (deviceTypeMapPin.includes('_')) deviceTypeMapPin.replace('_', '-')
    return {
      url: "/assets/img/icons/" + deviceTypeMapPin + ".png",
      labelOrigin: new google.maps.Point(90, 20)
    };
  }

  openInfoWindow(marker: MapMarker, windowIndex: number, info: string) {
    /// stores the current index in forEach
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        curIdx++;
      }
    });

  }

  openInfo(content: string) {
    if (content != undefined && content != null && content != '' && content.match(this.guidPattern)) {
      this.goToDeviceDetail(content);
    }
  }

  goToDeviceDetail(device: string): void {

    const deviceToShow = this.devices.find(x => x.Id == device);

    this.dialogRef.close(deviceToShow);
  }

  private _buildInfoContentArray(devices: Device[]): InfoContentData[] {

    if (!devices || devices === undefined) {
      return [];
    }

    let elements: Array<InfoContentData> = [];

    devices.forEach((element) => {
      if (elements.some(function (item) {
        return item.Name == element.Model.Type.Name
      })) {
        elements.find(x => x.Name == element.Model.Type.Name).Elements++;
      }
      else {
        elements.push(new InfoContentData(element.Model.Type.Name, element.Model.Type.Icon))
      }
    });

    return elements;
  }


  private _buildDevices(devices: Device[]): Device[] {
    if (!devices || devices === undefined) {
      return [];
    }

    let childs: Device[] = [];

    for (let device of devices) {
      if (device.Childs?.length > 0) {
        childs.push(device);
        for (let child of device.Childs) {
          childs.push(child);
        }
      }
      else {
        childs.push(device);
      }
    }

    return childs;
  }


}
