<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span *ngIf="!this.isAdd" class="title2">
            {{this.theme?.Hostname}}
        </span>
        <span *ngIf="this.isAdd" class="title2">
            {{ 'GENERAL.ADD' | translate }} -   {{ 'GENERAL.THEME' | translate }}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.HOSTNAME' | translate }}</mat-label>
            <input matInput formControlName="hostname"
                   autocomplete="off"
                   required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'GENERAL.STYLE' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput
                      formControlName="scssVariables" matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=15
                      autocomplete="off"></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'GENERAL.TRANSLATIONS' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput
                      formControlName="translations" matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=15
                      autocomplete="off"></textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ error }}
        </mat-error>

        <div class="image-container mx-auto text-center">
            <mat-label>{{ 'GENERAL.LOGO' | translate }}</mat-label>
            <img *ngIf="this.logoBaseData" src="{{ this.logoBaseData }}" class="img-fluid" alt="Profile Image">
            <img *ngIf="!this.logoBaseData" src="assets/img/default-profile.png" class="img-fluid" alt="Profile Image">
            <input style="display: none;" type="file" (change)="logoImageUpload($event)"
                   accept=".png, .jpg, .jpeg, .gif" #imageInput required>
            <button mat-mini-fab color="primary" class="my-fab" (click)="imageInput.click()">
                <i class="urbanicon-edit"></i>
            </button>
        </div>

        <br>
        <mat-error *ngIf="logoError" class="text-center">
            {{ 'ERROR.' + logoError | translate }}
        </mat-error>

        <div class="image-container mx-auto text-center">
            <mat-label>{{ 'GENERAL.SPLASHSCREEN_LOGO' | translate }}</mat-label>
            <img *ngIf="this.splashScreenLogoBaseData" src="{{ this.splashScreenLogoBaseData }}" class="img-fluid" alt="Profile Image">
            <img *ngIf="!this.splashScreenLogoBaseData" src="assets/img/default-profile.png" class="img-fluid" alt="Profile Image">
            <input style="display: none;" type="file" (change)="splashScreenLogoImageUpload($event)"
                   accept=".png, .svg, .jpg, .jpeg, .gif" #imageSplashInput required>
            <button mat-mini-fab color="primary" class="my-fab" (click)="imageSplashInput.click()">
                <i class="urbanicon-edit"></i>
            </button>
        </div>

        <br>
        <mat-error *ngIf="splashLogoError" class="text-center">
            {{ 'ERROR.' + splashLogoError | translate }}
        </mat-error>

        <!-- <div class="buttonArea d-flex justify-content-start align-items-center pb-2"> -->
        <div class="d-flex flex-column flex-md-row flex-md-wrap">
            <button *ngIf="!this.isAdd" mat-button class="btn actionButton mr-2 mt-2"
                    type="button" id="updateButton" (click)="addition()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>

            <button *ngIf="this.isAdd" mat-button class="btn actionButton mr-2 mt-2"
                    type="button" id="addButton" (click)="addition()">
                {{ 'GENERAL.ADD' | translate }}
            </button>
        </div>
    </form>
</div>
