import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { Domain } from 'src/app/shared/models/domain';
import { Language } from 'src/app/shared/models/language';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'urban-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  @Input('myCurrentDomain') public myCurrentDomainRef: Domain;
  @Input('darkModeStatus') public darkModeStatus: boolean;
  @Input('availableLanguages') public availableLanguages: Language[];
  @Output() toggleDarkMode: EventEmitter<boolean> = new EventEmitter();
  @Output() closeRightbar: EventEmitter<string> = new EventEmitter();

  public languages: Language[] = [];
  public currentLanguage: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public availableDomains: Domain[] = [];

  constructor(
    private apiService: ApiService,
    private mainService: MainSubscriptionsService,
    private translate: TranslateService
    ) 
  { }

  ngOnInit(): void {
    this.languages = this.availableLanguages;
    this.currentLanguage = this.translate.currentLang.slice(-2);

    this.apiService.getAvailableDomains().pipe(takeUntil(this.ngUnsubscribe)).subscribe(domains =>{
      if (domains) {
        this.availableDomains = domains;
      }
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });
  }

  public setDomain(domain: Domain): void {
    this.mainService.setCurrentDomainPropertyComand(domain);
  }

  public toggleDarkModeSlide(): void {
    this.toggleDarkMode.emit(!this.darkModeStatus);
  }

  public closeThisRightbar(): void {
    this.closeRightbar.emit('settings');
  }

  public changeLang(lang: string): void {
    if (lang === 'it' || lang === 'en') {
      let langToSet = '?language=' + lang;
      this.mainService.changeProfileLanguageComand(langToSet);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
