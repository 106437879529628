export class SerializationHelper {
    static toInstance<T>(json: string): T {
        try {
            var jsonObj = JSON.parse(json);

            return jsonObj;
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
}