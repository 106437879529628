<div class="alertEventsContainer">
    <div class="errorsContainer d-flex flex-wrap pointer" *ngIf="deviceWarnings?.length > 0">
        <div *ngFor="let deviceType of deviceWarnings" class="error" (click)="goToDetailDialog(deviceType.Devices)">
            <div class="iconContainer mb-2 position-relative">
                <i class="position-relative iconToShow urbanicon-{{deviceType.Devices[0].Model.Type.Icon}}">
                </i>
            
                <div class="position-absolute backgroundDimmed">
                </div>
            </div>

            <div class="d-flex flex-column">
                <h3 class="title3 secondaryTextColor">{{warningPerType[deviceType.Devices[0].Model.Type.Name]}}
                    <span *ngIf="warningPerType[deviceType.Devices[0].Model.Type.Name] > 1" class="title3">{{
                        'ALERT_EVENTS_WIDGET.ERRORS' | translate | lowercase }}
                    </span>
                    <span *ngIf="warningPerType[deviceType.Devices[0].Model.Type.Name] === 1" class="title3">{{
                        'ALERT_EVENTS_WIDGET.ERROR' | translate | lowercase }}
                    </span>
                </h3>
                <span class="linkText secondaryTextColor">{{ 'REPORT.' + deviceType.Devices[0].Model.Type.Name | translate }}</span>
            </div>
        </div>
    </div>

    <div *ngIf="deviceWarnings?.length === 0"
        class="d-flex justify-content-center align-items-center noErrorIllustration text-center flex-column">
        <img class="img-fluid" [src]="'/assets/img/noAlertEvents.png'" />
        <span class="substrongText">{{'ALERT_EVENTS_WIDGET.NO_ERRORS' | translate}}</span>
    </div>
</div>