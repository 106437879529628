import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SMSSDomain } from '../../../../shared/models/smss/smssDomain';
import { SMSSManager } from '../../../../shared/models/smss/smssManager';
import { WifiSmssService } from '../../../../shared/services/wifi-smss.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'urban-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit, OnDestroy {
  public domains: SMSSDomain[] = [];
  public displayedDomainsColumns = ['DomainName', 'Paths'];
  public myDataSource: MatTableDataSource<SMSSDomain>;
  public currentManager: SMSSManager;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wifiSMSSService: WifiSmssService
  ) { }

  ngOnInit(): void {
    const managerId = this.route.snapshot.paramMap.get('id');
    if (managerId === undefined || managerId === null || managerId === '') this.router.navigate(['main/dashboard'])

    this.wifiSMSSService.managerRead(managerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(manager => {
      if(manager){
        this.currentManager = manager;

        this.wifiSMSSService.getSSMSDomains(managerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe( res => {
          this.domains = res;
          this.myDataSource = new MatTableDataSource(this.domains)
        });
      }
    });
  }

  public goToGateways(domainId: string): void {
    this.router.navigate(['main/net-wifi/gateways', { id: domainId }]);
  }

  public goToUsers(domainId: string): void {
    this.router.navigate(['main/net-wifi/users', { id: domainId }]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
