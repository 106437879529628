import {BacnetDetailComponent} from './../../pages/main/bacnet-detail/bacnet-detail.component';
import {BacnetMonitorComponent} from './../../pages/main/bacnet-monitor/bacnet-monitor.component';
import {TrafficDetailComponent} from '../../pages/main/traffic-detail/traffic-detail.component';
import {TransitDashboardComponent} from '../../pages/main/transit-dashboard/transit-dashboard.component';
import {TransitDetailComponent} from '../../pages/main/transit-detail/transit-detail.component';
import {NewsComponent} from '../../pages/main/news/news.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WipComponent} from '../../pages/main/wip/wip.component';
import {DashboardComponent} from '../../pages/main/dashboard/dashboard.component';
import {DeviceComponent} from '../../pages/main/device/device.component';
import {DevicesTableComponent} from '../../pages/main/devices-table/devices-table.component';
import {HistoryComponent} from '../../pages/main/history/history.component';
import {RemoteControlDetailComponent} from '../../pages/main/remote-control-detail/remote-control-detail.component';
import {WeatherStationDetailComponent} from '../../pages/main/weatherstation-detail/weatherstation-detail.component';
import {MainComponent} from '../../pages/main/main.component';
import {InvisibleChildComponent} from '../../shared/components/invisible-child/invisible-child.component';
import {UserDetailComponent} from '../../pages/main/user-detail/user-detail.component';
import {RoleManagementComponent} from '../../pages/main/administration/role-management/role-management.component';
import {DomainManagementComponent} from '../../pages/main/administration/domain-management/domain-management.component';
import {UserManagementComponent} from '../../pages/main/administration/user-management/user-management.component';
import {AdminGuard} from '../../shared/services/guards/admin.guard';
import {RoleDetailComponent} from '../../pages/main/role-detail/role-detail.component';
import {UserListComponent} from '../../pages/main/user-list/user-list.component';
import {DomainAdminGuard} from '../../shared/services/guards/domainadmin.guard';
import {UserDomainDetailComponent} from '../../pages/main/user-domain-detail/user-domain-detail.component';
import {MessageComponent} from '../../pages/main/message/message.component';
import {PlatformStatisticsComponent} from '../../pages/main/platform-statistics/platform-statistics.component';
import {LogsStatisticsComponent} from '../../pages/main/logs-statistics/logs-statistics.component';
import {NotificationListComponent} from '../../pages/main/administration/notification-list/notification-list.component';
import {
  NotificationDetailComponent
} from '../../pages/main/administration/notification-detail/notification-detail.component';
import {DomainDetailComponent} from '../../pages/main/administration/domain-detail/domain-detail.component';
import {PhraseManagementComponent} from '../../pages/main/administration/phrase-management/phrase-management.component';
import {
  ClientSettingManagementComponent
} from '../../pages/main/administration/client-setting-management/client-setting-management.component';
import {
  ResourceManagementComponent
} from '../../pages/main/administration/resource-management/resource-management.component';
import {
  UpdateMissingPhrasesComponent
} from '../../pages/main/administration/phrase-management/update-missing-phrases/update-missing-phrases.component';
import {DeviceEventsComponent} from '../../pages/main/device-events/device-events.component';
import {DomainDeviceEventsComponent} from '../../pages/main/domain-device-events/domain-device-events.component';
import {HeatmapDevicesComponent} from '../../pages/main/heatmap-devices/heatmap-devices.component';
import {DeviceBrandsComponent} from '../../pages/main/administration/device-brands/device-brands.component';
import {DeviceBrandComponent} from '../../pages/main/device-brand/device-brand.component';
import {DeviceTypesComponent} from '../../pages/main/administration/device-types/device-types.component';
import {DevicesModelComponent} from '../../pages/main/administration/devices-model/devices-model.component';
import {HeatmapPeopleCounterComponent} from '../../pages/main/heatmap-people-counter/heatmap-people-counter.component';
import {DeviceTypeComponent} from '../../pages/main/device-type/device-type.component';
import {DeviceGroupListComponent} from '../../pages/main/device-group-list/device-group-list.component';
import {DeviceGroupDetailComponent} from '../../pages/main/device-group-detail/device-group-detail.component';
import {AdvertisersComponent} from '../../pages/main/smss/advertisers/advertisers.component';
import {CampaignsComponent} from '../../pages/main/smss/campaigns/campaigns.component';
import {AdvertiserComponent} from '../../pages/main/smss/advertiser/advertiser.component';
import {CampaignComponent} from '../../pages/main/smss/campaign/campaign.component';
import {LocationsComponent} from '../../pages/main/locations/locations.component';
import {CalendarsComponent} from '../../pages/main/calendars/calendars.component';
import {GatewayComponent} from '../../pages/main/smss/gateway/gateway.component';
import {WptemplateComponent} from '../../pages/main/smss/wptemplate/wptemplate.component';
import {
  SmartParkingDashboardComponent
} from '../../pages/main/smart-parking-dashboard/smart-parking-dashboard.component';
import {SmartParkingDetailComponent} from 'src/app/pages/main/smart-parking-detail/smart-parking-detail.component';
import {CrosswalkDashboardComponent} from '../../pages/main/crosswalk-dashboard/crosswalk-dashboard.component';
import {CrosswalkDetailComponent} from 'src/app/pages/main/crosswalk-detail/crosswalk-detail.component';
import {TrafficDashboardComponent} from '../../pages/main/traffic-dashboard/traffic-dashboard.component';
import {RoadRiskComponent} from '../../pages/main/road-risk/road-risk.component';
import {TaskScheduleComponent} from '../../pages/main/task-schedule/task-schedule.component';
import {TaskScheduleDetailComponent} from '../../pages/main/task-schedule-detail/task-schedule-detail.component';
import {TrashBinDashboardComponent} from '../../pages/main/trash-bin-dashboard/trash-bin-dashboard.component';
import {TrashBinDetailComponent} from 'src/app/pages/main/trash-bin-detail/trash-bin-detail.component';
import {DeviceNotificationsComponent} from '../../pages/main/device-notifications/device-notifications.component';
import {DeviceCommandComponent} from '../../pages/main/device-command/device-command.component';
import {DeviceCommandsComponent} from '../../pages/main/device-commands/device-commands.component';
import {DeviceCommandDetailComponent} from '../../pages/main/device-command-detail/device-command-detail.component';
import {
  DeviceCommandTypeComponent
} from '../../pages/main/administration/device-command-type/device-command-type.component';
import {WidgetsComponent} from '../../pages/main/administration/widgets/widgets.component';
import {ServerSettingsComponent} from "../../pages/main/administration/server-settings/server-settings.component";
import {TemplatesComponent} from "../../pages/main/administration/templates/templates.component";
import {TemplateFormatsComponent} from "../../pages/main/administration/template-formats/template-formats.component";
import {TemplateTypesComponent} from "../../pages/main/administration/template-types/template-types.component";
import {LocationEventsComponent} from "../../pages/main/location-events/location-events.component";
import {LocationEventComponent} from "../../pages/main/location-event/location-event.component";
import {LocationComponent} from "../../pages/main/location/location.component";
import {
  DomainVisibilityItemsComponent
} from "../../pages/main/administration/domain-visibility-items/domain-visibility-items.component";
import {ThemesComponent} from "../../pages/main/administration/themes/themes.component";
import {ThemeDetailComponent} from "../../pages/main/administration/theme-detail/theme-detail.component";
import {
  BroadcastClientTypesComponent
} from "../../pages/main/content-management/broadcast-client-types/broadcast-client-types.component";
import {ScheduleRulesComponent} from "../../pages/main/content-management/schedule-rules/schedule-rules.component";
import {
  BroadcastClientsComponent
} from "../../pages/main/content-management/broadcast-clients/broadcast-clients.component";
import {
  BroadcastClientComponent
} from "../../pages/main/content-management/broadcast-client/broadcast-client.component";
import {ChannelsComponent} from "../../pages/main/content-management/channels/channels.component";
import {ChannelComponent} from "../../pages/main/content-management/channel/channel.component";
import {BroadcastsComponent} from "../../pages/main/content-management/broadcasts/broadcasts.component";
import {ContentsComponent} from "../../pages/main/content-management/contents/contents.component";
import {ContentComponent} from "../../pages/main/content-management/content/content.component";
import {EntitiesComponent} from "../../pages/main/content-management/entities/entities.component";
import {EntityComponent} from "../../pages/main/content-management/entity/entity.component";
import { PeoplePassageComponent } from 'src/app/pages/main/people-passage/people-passage.component';
import { NotesComponent } from 'src/app/pages/main/notes/notes.component';
import { ServiceTypesComponent } from 'src/app/pages/main/administration/service-types/service-types.component';
import { ServiceListComponent } from 'src/app/pages/main/service-list/service-list.component';
import { ServiceDetailComponent } from 'src/app/pages/main/service-detail/service-detail.component';
import { DashboardVideoComponent } from 'src/app/pages/main/dashboard-video/dashboard-video.component';
import { DeletedUsersComponent } from 'src/app/pages/main/deleted-users/deleted-users.component';
import { ConsumerListComponent } from 'src/app/pages/main/administration/consumer-list/consumer-list.component';
import { ConsumerDetailComponent } from 'src/app/pages/main/administration/consumer-detail/consumer-detail.component';
import { AdvertisingListComponent } from 'src/app/pages/main/advertising-list/advertising-list.component';
import { AdvertisingEventListComponent } from 'src/app/pages/main/advertising-event-list/advertising-event-list.component';
import { AdvertisingTypeListComponent } from 'src/app/pages/main/administration/advertising-type-list/advertising-type-list.component';
import { MapComponent } from 'src/app/pages/main/map/map.component';
import { PassageDashboardComponent } from 'src/app/pages/main/passage-dashboard/passage-dashboard.component';
import { PassageDetailComponent } from 'src/app/pages/main/passage-detail/passage-detail.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'wip', component: WipComponent},
      {path: 'wip-smart-pole', component: WipComponent},
      {path: 'wip-environment-monitoring', component: WipComponent},
      {path: 'wip-citizens-services', component: WipComponent},
      {path: 'wip-diagnostics', component: WipComponent},
      {path: 'wip-social-network', component: WipComponent},
      {path: 'wip-flow', component: WipComponent},
      {path: 'wip-sm-gas', component: WipComponent},
      {path: 'wip-sm-energy', component: WipComponent},
      {path: 'wip-green-areas', component: WipComponent},
      {path: 'wip-facial-recognition', component: WipComponent},
      {path: 'wip-lte-4g', component: WipComponent},
      {path: 'wip-transactions', component: WipComponent},
      {path: 'wip-traffic-data', component: WipComponent},
      {path: 'wip-air-quality', component: WipComponent},
      {path: 'wip-sm-water', component: WipComponent},
      {path: 'wip-recycling', component: WipComponent},
      {path: 'wip-delivered', component: WipComponent},
      {path: 'wip-open-data', component: WipComponent},
      {path: 'wip-gateway-lora', component: WipComponent},
      {path: 'wip-sensors', component: WipComponent},
      {path: 'wip-sm-heat', component: WipComponent},
      {path: 'wip-proximity-services', component: WipComponent},
      {path: 'wip-activity-index', component: WipComponent},
      {path: 'wip-public-transportation', component: WipComponent},
      {path: 'wip-backoffice', component: WipComponent},
      {path: 'wip-social-distancing', component: WipComponent},
      {path: 'wip-diagnostics', component: WipComponent},
      {path: 'wip-withlicense-transportation', component: WipComponent},
      {path: 'wip-withoutlicense-transportation', component: WipComponent},
      {path: 'wip-maintenance', component: WipComponent},
      {path: 'wip-3dmodels', component: WipComponent},
      {path: 'wip-vehicle-recognition', component: WipComponent},
      {path: 'wip-plates-recognition', component: WipComponent},
      {path: 'wip-object-detection', component: WipComponent},
      {path: 'wip-access-control', component: WipComponent},
      {path: 'wip-smartgrid', component: WipComponent},
      {path: 'wip-predictive-models', component: WipComponent},
      {path: 'wip-transitions', component: WipComponent},
      {path: 'wip-viability', component: WipComponent},
      {path: 'wip-climate-data', component: WipComponent},
      {path: 'wip-infrastructures-diagnostics', component: WipComponent},
      {path: 'electric-charge', component: WipComponent},
      {path: 'device', component: DeviceComponent},
      {path: 'devices-table', component: DevicesTableComponent},
      {path: 'device-history', component: HistoryComponent},
      {path: 'rc-detail', component: RemoteControlDetailComponent},
      {path: 'ws-detail', component: WeatherStationDetailComponent},
      {path: 'invisible-child', component: InvisibleChildComponent},
      {path: 'user-management', canActivate: [AdminGuard], component: UserManagementComponent},
      {path: 'user-detail', canActivate: [AdminGuard], component: UserDetailComponent},
      {path: 'role-management', canActivate: [AdminGuard], component: RoleManagementComponent},
      {path: 'domain-management', canActivate: [AdminGuard], component: DomainManagementComponent},
      {path: 'role-detail', canActivate: [AdminGuard], component: RoleDetailComponent},
      {path: 'user-list', canActivate: [DomainAdminGuard], component: UserListComponent},
      {path: 'user-domain-detail', canActivate: [DomainAdminGuard], component: UserDomainDetailComponent},
      {path: 'platform-statistics', component: PlatformStatisticsComponent},
      {path: 'logs-statistics', component: LogsStatisticsComponent},
      {path: 'message', component: MessageComponent},
      {path: 'notification-list', component: NotificationListComponent},
      {path: 'notification-detail', component: NotificationDetailComponent},
      {path: 'domain-detail', component: DomainDetailComponent},
      {path: 'phrase-management', component: PhraseManagementComponent},
      {path: 'missing-phrases', component: UpdateMissingPhrasesComponent},
      {path: 'client-setting-management', component: ClientSettingManagementComponent},
      {path: 'resource-management', component: ResourceManagementComponent},
      {path: 'device-events', component: DeviceEventsComponent},
      {path: 'domain-events', component: DomainDeviceEventsComponent},
      {path: 'device-brands', component: DeviceBrandsComponent},
      {path: 'device-brand', component: DeviceBrandComponent},
      {path: 'device-types', component: DeviceTypesComponent},
      {path: 'devices-model', component: DevicesModelComponent},
      {path: 'heatmap', component: HeatmapDevicesComponent},
      {path: 'people-counter', component: HeatmapPeopleCounterComponent},
      {path: 'device-type', component: DeviceTypeComponent},
      {path: 'device-group-list', component: DeviceGroupListComponent},
      {path: 'device-group-detail', component: DeviceGroupDetailComponent},
      {path: 'news', component: NewsComponent},
      {path: 'advertisers', component: AdvertisersComponent},
      {path: 'campaigns', component: CampaignsComponent},
      {path: 'advertiser', component: AdvertiserComponent},
      {path: 'campaign', component: CampaignComponent},
      {path: 'locations', component: LocationsComponent},
      {path: 'calendars', component: CalendarsComponent},
      {path: 'gateway', component: GatewayComponent},
      {path: 'wptemplate', component: WptemplateComponent},
      {path: 'smart-parking', component: SmartParkingDashboardComponent},
      {path: 'smart-parking-detail', component: SmartParkingDetailComponent},
      {path: 'smart-crosswalk', component: CrosswalkDashboardComponent},
      {path: 'smart-crosswalk-detail', component: CrosswalkDetailComponent},
      {path: 'smart-traffic', component: TrafficDashboardComponent},
      {path: 'smart-traffic-detail', component: TrafficDetailComponent},
      {path: 'road-risk', component: RoadRiskComponent},
      {path: 'task-schedule', component: TaskScheduleComponent},
      {path: 'task-schedule-detail', component: TaskScheduleDetailComponent},
      {path: 'trash-bin', component: TrashBinDashboardComponent},
      {path: 'trash-bin-detail', component: TrashBinDetailComponent},
      {path: 'transit-dashboard', component: TransitDashboardComponent},
      {path: 'transit-detail', component: TransitDetailComponent},
      {path: 'device-notifications', component: DeviceNotificationsComponent},
      {path: 'device-command', component: DeviceCommandComponent},
      {path: 'device-commands', component: DeviceCommandsComponent},
      {path: 'device-command-detail', component: DeviceCommandDetailComponent},
      {path: 'device-command-types', component: DeviceCommandTypeComponent},
      {path: 'widgets', component: WidgetsComponent},
      {path: 'server-settings', component: ServerSettingsComponent},
      {path: 'templates', component: TemplatesComponent},
      {path: 'template-formats', component: TemplateFormatsComponent},
      {path: 'template-types', component: TemplateTypesComponent},
      {path: 'location-events', component: LocationEventsComponent},
      {path: 'location-event', component: LocationEventComponent},
      {path: 'location', component: LocationComponent},
      {path: 'domain-visibility-items', component: DomainVisibilityItemsComponent},
      {path: 'themes', component: ThemesComponent},
      {path: 'theme-detail', component: ThemeDetailComponent},
      {path: 'bacnet-monitor', component: BacnetMonitorComponent},
      {path: 'bacnet-detail', component: BacnetDetailComponent},
      {path: 'content-management/broadcast-client-types', component: BroadcastClientTypesComponent},
      {path: 'content-management/schedule-rules', component: ScheduleRulesComponent},
      {path: 'content-management/broadcast-clients', component: BroadcastClientsComponent},
      {path: 'content-management/broadcast-client', component: BroadcastClientComponent},
      {path: 'content-management/channels', component: ChannelsComponent},
      {path: 'content-management/channel', component: ChannelComponent},
      {path: 'content-management/broadcasts', component: BroadcastsComponent},
      {path: 'content-management/contents', component: ContentsComponent},
      {path: 'content-management/content', component: ContentComponent},
      {path: 'content-management/entities', component: EntitiesComponent},
      {path: 'content-management/entity', component: EntityComponent},
      {path: 'people-passage', component: PeoplePassageComponent},
      {path: 'notes', component: NotesComponent},
      {path: 'service-types', component: ServiceTypesComponent},
      {path: 'service-list', component: ServiceListComponent},
      {path: 'service-detail', component: ServiceDetailComponent},
      {path: 'dashboard-video', component: DashboardVideoComponent},
      {path: 'advertising-type-list', component: AdvertisingTypeListComponent},
      {path: 'deleted-users', component: DeletedUsersComponent},
      {path: 'advertising-list', component: AdvertisingListComponent},
      {path: 'consumer-list', component: ConsumerListComponent},
      {path: 'consumer-detail', component: ConsumerDetailComponent},
      {path: 'advertising-event-list', component: AdvertisingEventListComponent},
      {path: 'map', component: MapComponent},
      {path: 'passage-dashboard', component: PassageDashboardComponent},
      {path: 'passage-detail', component: PassageDetailComponent},
      {
        path: 'net-wifi',
        //component: WifiComponent,
        loadChildren: () => import('./wifi/wifi.module').then(m => m.WifiModule)
      },
      {path: '**', redirectTo: 'dashboard'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
