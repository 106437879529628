<div class="container-fluid">
    <div class="pageTop">
        <span class="title2">{{ 'GENERAL.MAP' | translate }}</span>
    </div>

    <mat-tab-group id="intro-devices-table-tab" animationDuration="0ms" dynamicHeight="true" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="{{ 'GENERAL.DEVICES' | translate }}">
        </mat-tab>

        <mat-tab label="{{ 'DEVICE.GROUPS' | translate }}">
        </mat-tab>
    </mat-tab-group>

    <div class="tabContent panelBG p-4 customTableRadius">
        <ng-container [ngTemplateOutlet]="map"></ng-container>
    </div>
</div>

<ng-template #map>
    <div class="d-flex justify-content-between mb-4 flex-wrap">
        <h3 class="title3">{{ (activeTab === 0 ? 'GENERAL.DEVICES' : 'DEVICE.GROUPS') | translate }}</h3>

        <div>
            <div *ngIf="!editMode">
                <button type="button" mat-button class="btn actionButton mr-1" (click)="executeAction('drag')">{{ 'GENERAL.EDIT' | translate }}</button>
                <button type="button" mat-button class="btn actionButton mr-1 my-1 my-sm-0" (click)="executeAction('device')">{{ 'GENERAL.ADD' | translate }} device</button>
                <button type="button" mat-button class="btn actionButton" (click)="executeAction('location')">{{ 'GENERAL.ADD' | translate }} location</button>
            </div>

            <button *ngIf="editMode" type="button" mat-button class="btn actionButton" (click)="executeAction('')">{{ 'GENERAL.CANCEL' | translate }}</button>
        </div>
    </div>

    <div class="d-flex flex-wrap mb-4">
        <ng-container *ngIf="activeTab === 0" [ngTemplateOutlet]="deviceTypeChips"></ng-container>
        <ng-container *ngIf="activeTab === 1" [ngTemplateOutlet]="groupChips"></ng-container>
    </div>

    <urban-maps-widget *ngIf="myDevices && mapReady" [showGroups]="showGroups" [devicesList]="deviceToShow"
        [darkModeStatus]="isDarkActive" [editMode]="editMode" [deviceGroupList]="groupToShow"
        (changeDeviceGroupsPosition)="updateDeviceGroups($event)">
    </urban-maps-widget>
</ng-template>

<ng-template #deviceTypeChips>
    <div *ngFor="let type of myDeviceTypes" class="chip d-flex align-items-center mr-1 mb-1 pointer" [ngClass]="{'activeBG' : visibilityStatus[type.Id]}" (click)="toggleVisibility(type.Id)">
        <span class="linkText">{{ type.Name | titlecase }}</span>

        <i [ngClass]="visibilityStatus[type.Id] ? 'urbanicon-visibility-outline' : 'urbanicon-visibility-off-outline'"></i>
    </div>
</ng-template>

<ng-template #groupChips>
    <div *ngFor="let group of deviceGroups" class="chip d-flex align-items-center mr-1 mb-1 pointer" [ngClass]="{'activeBG' : visibilityStatus[group.Id]}" (click)="toggleVisibility(group.Id)">
        <span class="linkText">{{group.Name}}</span>

        <i [ngClass]="visibilityStatus[group.Id] ? 'urbanicon-visibility-outline' : 'urbanicon-visibility-off-outline'"></i>
    </div>
</ng-template>
