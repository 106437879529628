import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { GetDeviceReportResponseModel } from '../models/siralab/remoteControlService';
import { SetEntityStateRequest } from '../models/siralab/setEntityStateRequest';

@Injectable({
  providedIn: 'root'
})
export class SiralabApiService {

  constructor(private http: HttpClient) { }

  public getDeviceReport(deviceId: number, delayMilliseconds : number = 0): Observable<GetDeviceReportResponseModel> {
    return this.http.post<GetDeviceReportResponseModel>(
      `int.sl.getdevicereport.api`,
      { id: deviceId }
    ).pipe(delay(delayMilliseconds))
  }

  public setEntityState(request: SetEntityStateRequest): Observable<any> {
    return this.http.post(
      `int.sl.setentitystate.api`,
      {
        "serviceType": request.serviceType,
        "serviceHash": request.serviceHash,
        "value": request.value,
        "id": request.id
      }
    )
  }
}
