import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserRoles} from "../../../../shared/models/userRoles";
import {Subject} from "rxjs";
import {PaginationInstance} from "ngx-pagination";
import {Router} from "@angular/router";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {BroadcastClientResponse} from "../../../../shared/models/content-management/broadcastClientResponse";
import {
  BroadcastClientDialogComponent
} from "../../../../shared/dialogs/broadcast-client-dialog/broadcast-client-dialog.component";
import {BroadcastClientType} from "../../../../shared/models/content-management/broadcastClientType";

@Component({
  selector: 'urban-broadcast-clients',
  templateUrl: './broadcast-clients.component.html',
  styleUrls: ['./broadcast-clients.component.scss']
})
export class BroadcastClientsComponent implements OnInit, OnDestroy {

  typesColumns = ['Name', 'Type', 'Detail'];
  public broadcastClients: BroadcastClientResponse[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: BroadcastClientResponse[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private broadcastClientTypes: BroadcastClientType[] = [];

  constructor(
    private router: Router,
    private contentManagementService: ContentManagementService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this._initList();
    });
  }

  public add(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const addDialogRef = this.dialog.open(BroadcastClientDialogComponent, {
        data: {
          types: this.broadcastClientTypes
        }
      });

      addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newClient => {
        if (newClient) {
          const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false,
            width: '50%',
            maxWidth: 500,
          });

          addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.contentManagementService.addBroadcastClient(newClient).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            }
          });
        }
      });
    }
  }

  private _initList(): void {
    this.contentManagementService.listBroadcastClient().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.broadcastClients = x;
        this.filteredData = this.broadcastClients;
      }
    });

    this._initTypesList();
  }

  private _initTypesList(): void {
    this.contentManagementService.listBroadcastClientTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.broadcastClientTypes = x;
      }
    });
  }

  public detail(client: BroadcastClientResponse): void {
    if (!client) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: client.Id, BackRoute: 'content-management/broadcast-clients'});
    this.router.navigate(['main/content-management/broadcast-client']);
  }

  public delete(client: BroadcastClientResponse): void {
    if (!client) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {
      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.contentManagementService.deleteBroadcastClient(client.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.broadcastClients?.filter((key: BroadcastClientResponse) =>
      [key.Name?.toLowerCase()]
        .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
