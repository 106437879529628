<h1 mat-dialog-title>{{ 'GENERAL.ADD' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <input matInput formControlName="description"
                autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'GENERAL.START' | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startPicker" formControlName="startDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="startPicker">keyboard_arrow_down</mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'GENERAL.END' | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="endPicker" formControlName="endDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="endPicker">keyboard_arrow_down</mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'SMSS.TIMETABLES' | translate }}</mat-label>
            <input matInput formControlName="timetable" type="number" min="0" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'SMSS.FREQUENCY' | translate }}</mat-label>
            <input matInput
                formControlName="frequency" type="number" min="0" max="100" autocomplete="off">
        </mat-form-field>


        <mat-form-field class="bodyArea">
            <mat-label>{{ 'GENERAL.NOTES' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput formControlName="notes" 
                matTextareaAutosize matAutosizeMinRows=15 matAutosizeMaxRows=30></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'SMSS.LOCKED' | translate }}</mat-label>
            <mat-select formControlName="locked">
                <mat-option value="false">
                    {{ 'GENERAL.FALSE' | translate }}
                </mat-option>
                <mat-option value="true">
                    {{ 'GENERAL.TRUE' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
