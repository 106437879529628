import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { AddRoleDialogComponent } from '../../../../shared/dialogs/add-role-dialog/add-role-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Role, UserRoles } from '../../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementComponent implements OnInit, OnDestroy {

  public roleColumns = ['Name'];
  public roles: Role[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: Role[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private apiService: AdministratorApiService,
    private router: Router,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (this.userRoles.some(x => x.Name === 'Administrators')) {
        this.isAdmin = true;
      }
    });

    this.apiService.roleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.roles = x;
        this.filteredData = this.roles;
      }
    });
  }

  public addRole(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {
      const addDomainDialogRef = this.dialog.open(AddRoleDialogComponent);
      addDomainDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newRole => {
        if (newRole) {
          const addRoleConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          addRoleConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.apiService.addRole(newRole).pipe(takeUntil(this.ngUnsubscribe)).subscribe(addRoleResult => {
                this.apiService.roleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                  if (x) {
                    this.roles = x;
                    this.filteredData = this.roles;
                  }
                });
              });
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.roles?.filter((role: Role) =>
      [role.Name?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public goToRoleDetail(id: string, name: string): void {
    this.mainService.setNavigationInfoComand({ Id: id, Type: name, BackRoute: 'role-management' });
    this.router.navigate(['main/role-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}


