import { createAction, props } from '@ngrx/store';
import { UserRoles } from '../../shared/models/userRoles';
import { LoginResponse, User } from '../../shared/models/loginResponse';
import { Domain } from '../../shared/models/domain';
import { ChangePasswordRequest } from '../../shared/models/changePasswordRequest';
import { DomainProperty } from '../../shared/models/domainProperty';
import { SetUserPropertyRequest } from '../../shared/models/setUserPropertyRequest';

interface Credential {
  username: string,
  password: string
}

interface Token {
  token: string
}

export const requestLogin = createAction(
  '[Auth] Login Request',
  props<Credential>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<LoginResponse>()
);

export const setError = createAction(
  '[Auth] Error',
  props<{ error: string }>()
);

export const setToken = createAction(
  '[Auth] Set Token',
  props<Token>()
);

export const setAvailablesDomains = createAction(
  '[Auth] Set Availables Domains',
  props<{ domains: Domain[] }>()
);

export const setUserRoles = createAction(
  '[Auth] Set User Roles',
  props<{ roles: UserRoles['Roles'] }>()
);

export const logout = createAction(
  '[Auth] Logout'
);

export const setUserDomain = createAction(
  '[Auth] Set User Domain',
  props<{ domain: Domain, bearer: string }>()
);

/* export const multipleDomain = createAction(
    '[Auth] Multiple Availables Domain'
) */

export const initSetUserDomain = createAction(
  '[Auth] Init Set User Domain',
  props<Domain>()
);

export const changePassword = createAction(
  '[Auth] Change Password',
  props<{ changePasswordRequest: ChangePasswordRequest }>()
);

export const setCurrentDomainProperty = createAction(
  '[Auth] Set Current Domain Property',
  props<{ domain: Domain }>()
);

export const toggleDarkTheme = createAction(
  '[Auth/Theme] Toggle Dark Theme',
  props<{ darkModeChange: string }>()
);

export const changeProfileImage = createAction(
  '[Auth/Profile] Change Profile Image',
  props<{ base64Image: string }>()
);

export const changeProfileLanguage = createAction(
  '[Auth/Profile] Change Profile Language',
  props<{ favouriteLanguage: string }>()
);

export const changeProfilePhoneNumber = createAction(
  '[Auth/Profile] Change Profile Phone',
  props<{ phoneNumber: string }>()
);

export const changeUserPhoneNumber = createAction(
  '[Auth/Profile] Change User Phone',
  props<{ request: SetUserPropertyRequest }>()
);

export const changeProfileNotes = createAction(
  '[Auth/Profile] Change Profile Notes',
  props<{ notes: string }>()
);

export const getCurrentDomainProperties = createAction(
  '[Auth] Get Domain Properties',
  props<{ domainId: string }>()
);

export const loadCurrentDomainProperties = createAction(
  '[Auth] Load Domain Properties',
  props<{ properties: DomainProperty[] }>()
);

export const setUserInfo = createAction(
  '[Auth] Set User Info',
  props<User>()
);

export const initSetUserInfo = createAction(
  '[Auth] Init Set User Info'
);