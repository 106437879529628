import {Location} from "@angular/common";
import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import * as AppSelectors from "../../store/app/app.selectors";
import {takeUntil} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app/app.state";
import {Subject} from "rxjs";
import {StyleHelper} from "../../helpers/style-helper";

enum SplashAnimationType {
  SlideLeft = "slide-left",
  SlideRight = "slide-right",
  FadeOut = "fade-out"
}

@Component({
  selector: "urban-splash-screen",
  templateUrl: "./splash-screen.component.html",
  styleUrls: ["./splash-screen.component.scss"]
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  windowWidth: string;
  splashTransition: string;
  opacityChange: number = 1;
  showSplash = true;
  public subscribedDomainLogo: string;
  public subscribedThemeTitle: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input('animationDuration') animationDuration: number;
  @Input('duration') duration: number;
  @Input('animationType') animationType: SplashAnimationType = SplashAnimationType.SlideLeft;

  constructor(private location: Location, private appStore: Store<AppState>) {
  }

  ngOnInit(): void {
    this.appStore.select(AppSelectors.getTheme).pipe(takeUntil(this.ngUnsubscribe)).subscribe(theme => {
      if (theme && theme?.SplashScreenLogo) {
        this.subscribedDomainLogo = theme.SplashScreenLogo
        const appLogo = document.getElementById('app-logo');
        if (appLogo){
          const i = new Image();

          i.onload = () => {
            appLogo.style.background = `url(${this.subscribedDomainLogo}) no-repeat center`;
            appLogo.style.width = `${i.width}px`;
            appLogo.style.height = `${i.height}px`;
          };

          i.src = theme.SplashScreenLogo;
        }
      }
      if (theme && theme?.Translations.find(x => x.Key === 'title')){
        this.subscribedThemeTitle = theme?.Translations.find(x => x.Key === 'title')?.Value;
      }

      if (this.location.path(false).includes('auth') || this.location.path(false) === '') {
        setTimeout(() => {
          let transitionStyle = "";
          switch (this.animationType) {
            case SplashAnimationType.SlideLeft:
              this.windowWidth = "-" + window.innerWidth + "px";
              transitionStyle = "left " + this.animationDuration + "s";
              break;
            case SplashAnimationType.SlideRight:
              this.windowWidth = window.innerWidth + "px";
              transitionStyle = "left " + this.animationDuration + "s";
              break;
            case SplashAnimationType.FadeOut:
              transitionStyle = "opacity " + this.animationDuration + "s";
              this.opacityChange = 0;
          }

          this.splashTransition = transitionStyle;

          setTimeout(() => {
            this.showSplash = !this.showSplash;
          }, this.animationDuration * 1000);
        }, this.duration * 1000);
      } else {
        this.showSplash = false;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
