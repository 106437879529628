import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {PrivacyPolicyResponse} from "../../shared/models/privacyPolicyResponse";
import {ApiService} from "../../shared/services/api.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'urban-app-privacy',
  templateUrl: './app-privacy.component.html',
  styleUrls: ['./app-privacy.component.scss']
})
export class AppPrivacyComponent implements OnInit, OnDestroy {

  public isLittleScreen: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  public privacyPolicyResponse: PrivacyPolicyResponse;

  constructor(private apiService: ApiService,
              private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      const target: string = resp.params['t'];
      const language: string = navigator.language.split('-')[0];
      if (target)
        this.apiService.getPrivacyPolicy(language, target).pipe(takeUntil(this.ngUnsubscribe)).subscribe(privacyPolicy => {
          if (privacyPolicy) {
            this.privacyPolicyResponse = privacyPolicy;
          }
        });
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
