import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SMSSAdvertiser } from '../../../../shared/models/smss/smssAdvertiser';
import { SMSSCampaign } from '../../../../shared/models/smss/smssCampaign';
import { UserRoles } from '../../../../shared/models/userRoles';
import { ApiService } from '../../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy {

  public imageBaseData: string | ArrayBuffer = null;
  public imageError: string = null;

  public form: UntypedFormGroup = this.formBuilder.group({
    description: ['', Validators.required],
    frequency: [''],
    timezone: [''],
    startDate: [''],
    endDate: [''],
    timetable: [0],
    domain: [''],
    gateway: [''],
    managerType: [''],
    url: [''],
    totalBudget: [0],
    dailyBudget: [0],
    clickPrice: [0],
    impressionPrice: [0],
    address: [''],
    city: [''],
    country: [''],
    longitude: [''],
    latitude: [''],
    radiusInMeters: [0],
    notes: [''],
    locked: ['false'],
    portalIntroType: [''],
    portalIntroImageFromFileImgType: [''],
    portalIntroStaticImageURL: [''],
    portalIntroVideoPosterImgType: [''],
    portalIntroFlickrPhotoSet: [''],
    portalIntroYoutubeVideoID: [''],
    portalIntroExternalURL: [''],
    portalIntroURL: [''],
    portalIntroVideoURL: [''],
    portalIntroMinSeconds: [0],
    impressionTime: [0],
    topBannerImgType: [''],
    topBannerImgLink: [''],
    leftBannerImgType: [''],
    leftBannerImgLink: [''],
    rightBannerImgType: [''],
    rightBannerImgLink: [''],
    coinType: [''],
    coinSkipTime: [''],
    coinYoutubeVideoID: [''],
    coinExternalURLLink: [''],
    coinVideoURL: [''],
    coinVideoPosterImgType: [''],
    coinImage1Position: [''],
    coinImage1Size: [''],
    coinImage1Link: [''],
    coinImage1Type: [''],
    coinImage2Position: [''],
    coinImage2Size: [''],
    coinImage2Link: [''],
    coinImage2Type: [''],
    coinImage3Position: [''],
    coinImage3Size: [''],
    coinImage3Link: [''],
    coinImage3Type: [''],
    coinExternalURLPosition: [''],
    coinExternalURLSize: [''],
    portalIntroImageFromFileImg: [''],
    portalIntroVideoPosterImg: [''],
    coinVideoPosterImg: [''],
    topBannerImg: [''],
    leftBannerImg: [''],
    rightBannerImg: [''],
    coinImage1: [''],
    coinImage2: [''],
    coinImage3: ['']
  });

  public campaign: SMSSCampaign;
  public advertiser: SMSSAdvertiser;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public error: string | null;
  public userRoles: UserRoles['Roles'];

  public maxDate: Date;

  public minDate: Date;

  private advertiserId: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService
  ) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  ngOnInit(): void {
    this._init();
  }

  private _init(): void {
    let now = new Date();
    this.minDate = now;
    this.maxDate = new Date(now.setDate(now.getDate() + 30));

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;

      const advertiserId = this.route.snapshot.paramMap.get('advertiserId');
      if (advertiserId === undefined || advertiserId === null || advertiserId === '')
        this.router.navigate(['main/dashboard']);

      let campaignId = this.route.snapshot.paramMap.get('campaignId');
      if (campaignId === undefined || campaignId === null || campaignId === '')
        campaignId = '0';

      this.apiService.advertiserRead(advertiserId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.advertiser = result;

          if (campaignId !== '0') {
            this.apiService.campaignRead(campaignId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(campaignRes => {
              if (campaignRes) {
                this.campaign = campaignRes;

                this._loadForm();
              }
            })
          }
        }
      })
    })
  }

  private _loadForm(): void {
    this.form.controls.description.setValue(this.campaign.Description);
    this.form.controls.frequency.setValue(this.campaign.Frequency);
    this.form.controls.timezone.setValue(this.campaign.TimeZone);
    this.form.controls.startDate.setValue(this.campaign.StartDate);
    this.form.controls.endDate.setValue(this.campaign.EndDate);
    this.form.controls.timetable.setValue(+this.campaign.Timetables);
    this.form.controls.domain.setValue(this.campaign.DomainID);
    this.form.controls.gateway.setValue(this.campaign.GatewayID);
    this.form.controls.managerType.setValue(this.campaign.ManagerTypeID);
    this.form.controls.url.setValue(this.campaign.URL);
    this.form.controls.totalBudget.setValue(+this.campaign.TotalBudget);
    this.form.controls.dailyBudget.setValue(+this.campaign.DailyBudget);
    this.form.controls.clickPrice.setValue(+this.campaign.ClickPrice);
    this.form.controls.impressionPrice.setValue(+this.campaign.ImpressionPrice);
    this.form.controls.address.setValue(this.campaign.Address);
    this.form.controls.city.setValue(this.campaign.City);
    this.form.controls.country.setValue(this.campaign.Country);
    this.form.controls.longitude.setValue(this.campaign.Longitude);
    this.form.controls.latitude.setValue(this.campaign.Latitude);
    this.form.controls.radiusInMeters.setValue(+this.campaign.RadiusInMeters);
    this.form.controls.notes.setValue(this.campaign.Notes);
    this.form.controls.locked.setValue(this.campaign.Locked);;
    this.form.controls.portalIntroType.setValue(this.campaign.PortalIntroType);
    this.form.controls.portalIntroImageFromFileImgType.setValue(this.campaign.PortalIntroImageFromFileImgType);
    this.form.controls.portalIntroImageFromFileImgType.disable();
    this.form.controls.portalIntroStaticImageURL.setValue(this.campaign.PortalIntroStaticImageURL);
    this.form.controls.portalIntroVideoPosterImgType.setValue(this.campaign.PortalIntroVideoPosterImgType);
    this.form.controls.portalIntroVideoPosterImgType.disable();
    this.form.controls.portalIntroFlickrPhotoSet.setValue(this.campaign.PortalIntroFlickrPhotoSet);
    this.form.controls.portalIntroYoutubeVideoID.setValue(this.campaign.PortalIntroYoutubeVideoID);
    this.form.controls.portalIntroExternalURL.setValue(this.campaign.PortalIntroExternalURL);
    this.form.controls.portalIntroURL.setValue(this.campaign.PortalIntroURL);
    this.form.controls.portalIntroVideoURL.setValue(this.campaign.PortalIntroVideoURL);
    this.form.controls.portalIntroMinSeconds.setValue(+this.campaign.PortalIntroMinSeconds);
    this.form.controls.impressionTime.setValue(+this.campaign.ImpressionTime);
    this.form.controls.topBannerImgType.setValue(this.campaign.TopBannerImgType);
    this.form.controls.topBannerImgType.disable();
    this.form.controls.topBannerImgLink.setValue(this.campaign.TopBannerImgLink);
    this.form.controls.leftBannerImgType.setValue(this.campaign.LeftBannerImgType);
    this.form.controls.leftBannerImgType.disable();
    this.form.controls.leftBannerImgLink.setValue(this.campaign.LeftBannerImgLink);
    this.form.controls.rightBannerImgType.setValue(this.campaign.RightBannerImgType);
    this.form.controls.rightBannerImgType.disable();
    this.form.controls.rightBannerImgLink.setValue(this.campaign.RightBannerImgLink);
    this.form.controls.coinType.setValue(this.campaign.COINType);
    this.form.controls.coinSkipTime.setValue(this.campaign.COINSkipTime);
    this.form.controls.coinYoutubeVideoID.setValue(this.campaign.COINYoutubeVideoID);
    this.form.controls.coinExternalURLLink.setValue(this.campaign.COINExternalURLLink);
    this.form.controls.coinVideoURL.setValue(this.campaign.COINVideoURL);
    this.form.controls.coinVideoPosterImgType.setValue(this.campaign.COINVideoPosterImgType);
    this.form.controls.coinVideoPosterImgType.disable();
    this.form.controls.coinImage1Position.setValue(this.campaign.COINImage1Position);
    this.form.controls.coinImage1Size.setValue(this.campaign.COINImage1Size);
    this.form.controls.coinImage1Link.setValue(this.campaign.COINImage1Link);
    this.form.controls.coinImage1Type.setValue(this.campaign.COINImage1Type);
    this.form.controls.coinImage1Type.disable();
    this.form.controls.coinImage2Position.setValue(this.campaign.COINImage2Position);
    this.form.controls.coinImage2Size.setValue(this.campaign.COINImage2Size);
    this.form.controls.coinImage2Link.setValue(this.campaign.COINImage2Link);
    this.form.controls.coinImage2Type.setValue(this.campaign.COINImage2Type);
    this.form.controls.coinImage2Type.disable();
    this.form.controls.coinImage3Position.setValue(this.campaign.COINImage3Position);
    this.form.controls.coinImage3Size.setValue(this.campaign.COINImage3Size);
    this.form.controls.coinImage3Link.setValue(this.campaign.COINImage3Link);
    this.form.controls.coinImage3Type.setValue(this.campaign.COINImage3Type);
    this.form.controls.coinImage3Type.disable();
    this.form.controls.coinExternalURLPosition.setValue(this.campaign.COINExternalURLPosition);
    this.form.controls.coinExternalURLSize.setValue(this.campaign.COINExternalURLSize);
    this.form.controls.portalIntroImageFromFileImg.setValue(this.campaign.PortalIntroImageFromFileImg);
    this.form.controls.portalIntroVideoPosterImg.setValue(this.campaign.PortalIntroVideoPosterImg);
    this.form.controls.coinVideoPosterImg.setValue(this.campaign.COINVideoPosterImg);
    this.form.controls.topBannerImg.setValue(this.campaign.TopBannerImg);
    this.form.controls.leftBannerImg.setValue(this.campaign.LeftBannerImg);
    this.form.controls.rightBannerImg.setValue(this.campaign.RightBannerImg);
    this.form.controls.coinImage1.setValue(this.campaign.COINImage1);
    this.form.controls.coinImage2.setValue(this.campaign.COINImage2);
    this.form.controls.coinImage3.setValue(this.campaign.COINImage3);
  }

  back() {
    this.router.navigate(['main/campaigns', { advertiserId: this.advertiser.id }]);
  }

  update() {
    if (this.form.valid) {
      this.campaign = {
        id: this.campaign.id,
        CreationDate: this.campaign.CreationDate,
        AdvertiserId: this.advertiserId,
        Description: this.form.controls.description.value,
        Frequency: this.form.controls.frequency.value,
        TimeZone: this.form.controls.timezone.value,
        StartDate: this.form.controls.startDate.value,
        EndDate: this.form.controls.endDate.value,
        Timetables: this.form.controls.timetables.value.toString(),
        DomainID: this.form.controls.domain.value,
        GatewayID: this.form.controls.gateway.value,
        ManagerTypeID: this.form.controls.managerType.value,
        URL: this.form.controls.url.value,
        TotalBudget: this.form.controls.totalBudget.value.toString(),
        DailyBudget: this.form.controls.dailyBudget.value.toString(),
        ClickPrice: this.form.controls.clickPrice.value.toString(),
        ImpressionPrice: this.form.controls.impressionPrice.value.toString(),
        Address: this.form.controls.address.value,
        City: this.form.controls.city.value,
        Country: this.form.controls.country.value,
        Longitude: this.form.controls.longitude.value,
        Latitude: this.form.controls.latitude.value,
        RadiusInMeters: this.form.controls.radiusInMeters.value.toString(),
        Notes: this.form.controls.notes.value,
        Locked: this.form.controls.locked.value,
        PortalIntroType: this.form.controls.portalIntroType.value,
        PortalIntroImageFromFileImgType: this.form.controls.portalIntroImageFromFileImgType.value,
        PortalIntroStaticImageURL: this.form.controls.portalIntroStaticImageURL.value,
        PortalIntroVideoPosterImgType: this.form.controls.portalIntroVideoPosterImgType.value,
        PortalIntroFlickrPhotoSet: this.form.controls.portalIntroFlickrPhotoSet.value,
        PortalIntroYoutubeVideoID: this.form.controls.portalIntroYoutubeVideoID.value,
        PortalIntroExternalURL: this.form.controls.portalIntroExternalURL.value,
        PortalIntroURL: this.form.controls.portalIntroURL.value,
        PortalIntroVideoURL: this.form.controls.portalIntroVideoURL.value,
        PortalIntroMinSeconds: this.form.controls.portalIntroMinSeconds.value.toString(),
        ImpressionTime: this.form.controls.impressionTime.value.toString(),
        TopBannerImgType: this.form.controls.topBannerImgType.value,
        TopBannerImgLink: this.form.controls.topBannerImgLink.value,
        LeftBannerImgType: this.form.controls.leftBannerImgType.value,
        LeftBannerImgLink: this.form.controls.leftBannerImgLink.value,
        RightBannerImgType: this.form.controls.rightBannerImgType.value,
        RightBannerImgLink: this.form.controls.rightBannerImgLink.value,
        COINType: this.form.controls.coinType.value,
        COINSkipTime: this.form.controls.coinSkipTime.value,
        COINYoutubeVideoID: this.form.controls.coinYoutubeVideoID.value,
        COINExternalURLLink: this.form.controls.coinExternalURLLink.value,
        COINVideoURL: this.form.controls.coinVideoURL.value,
        COINVideoPosterImgType: this.form.controls.coinVideoPosterImgType.value,
        COINImage1Position: this.form.controls.coinImage1Position.value,
        COINImage1Size: this.form.controls.coinImage1Size.value,
        COINImage1Link: this.form.controls.coinImage1Link.value,
        COINImage1Type: this.form.controls.coinImage1Type.value,
        COINImage2Position: this.form.controls.coinImage2Position.value,
        COINImage2Size: this.form.controls.coinImage2Size.value,
        COINImage2Link: this.form.controls.coinImage2Link.value,
        COINImage2Type: this.form.controls.coinImage2Type.value,
        COINImage3Position: this.form.controls.coinImage3Position.value,
        COINImage3Size: this.form.controls.coinImage3Size.value,
        COINImage3Link: this.form.controls.coinImage3Link.value,
        COINImage3Type: this.form.controls.coinImage3Type.value,
        COINExternalURLPosition: this.form.controls.coinExternalURLPosition.value,
        COINExternalURLSize: this.form.controls.coinExternalURLSize.value,
        PortalIntroImageFromFileImg: this.form.controls.portalIntroImageFromFileImg.value,
        PortalIntroVideoPosterImg: this.form.controls.portalIntroVideoPosterImg.value,
        COINVideoPosterImg: this.form.controls.coinVideoPosterImg.value,
        TopBannerImg: this.form.controls.topBannerImg.value,
        LeftBannerImg: this.form.controls.leftBannerImg.value,
        RightBannerImg: this.form.controls.rightBannerImg.value,
        COINImage1: this.form.controls.coinImage1.value,
        COINImage2: this.form.controls.coinImage2.value,
        COINImage3: this.form.controls.coinImage3.value
      }

      this.apiService.campaignWrite(this.campaign).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
          this.mainService.setSuccessMessageComand(message);
          this._init();
        }
      })
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  portalIntroImageUpload(event: any): void {

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        this.imageBaseData = reader.result;
        this.form.controls.portalIntroImageFromFileImg.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removePortalIntroImage(): void {
    this.form.controls.portalIntroImageFromFileImg.setValue('');
  }

  portalIntroVideoPosterImgUpload(event: any): void {

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        this.imageBaseData = reader.result;
        this.form.controls.portalIntroVideoPosterImg.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removePortalVideoPosterIntroImg(): void {
    this.form.controls.portalIntroVideoPosterImg.setValue('');
  }

  coinVideoPosterImgUpload(event: any): void {

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        this.imageBaseData = reader.result;
        this.form.controls.coinVideoPosterImg.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeCoinVideoPosterIntroImg(): void {
    this.form.controls.coinVideoPosterImg.setValue('');
  }

  topBannerImgUpload(event: any): void {
    this.imageError = null;
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        if (Math.abs(image.width) > 720 && Math.abs(image.height) > 200) {
          this.imageError = 'IMAGE_RESOLUTION_BANNER_TOP';
          return;
        }
        this.imageBaseData = reader.result;
        this.form.controls.topBannerImg.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeTopBannerImg(): void {
    this.form.controls.topBannerImg.setValue('');
  }

  leftBannerImgUpload(event: any): void {
    this.imageError = null;
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        if (Math.abs(image.width) > 200 && Math.abs(image.height) > 720) {
          this.imageError = 'IMAGE_RESOLUTION_BANNER_SIDE';
          return;
        }
        this.imageBaseData = reader.result;
        this.form.controls.leftBannerImg.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeLeftBannerImg(): void {
    this.form.controls.leftBannerImg.setValue('');
  }

  rightBannerImgUpload(event: any): void {
    this.imageError = null;
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        if (Math.abs(image.width) > 200 && Math.abs(image.height) > 720) {
          this.imageError = 'IMAGE_RESOLUTION_BANNER_SIDE';
          return;
        }
        this.imageBaseData = reader.result;
        this.form.controls.rightBannerImg.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeRightBannerImg(): void {
    this.form.controls.rightBannerImg.setValue('');
  }

  coinImage1Upload(event: any): void {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        this.imageBaseData = reader.result;
        this.form.controls.coinImage1.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeCoinImage1(): void {
    this.form.controls.coinImage1.setValue('');
  }

  coinImage2Upload(event: any): void {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        this.imageBaseData = reader.result;
        this.form.controls.coinImage2.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeCoinImage2(): void {
    this.form.controls.coinImage2.setValue('');
  }

  coinImage3Upload(event: any): void {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        this.imageBaseData = reader.result;
        this.form.controls.coinImage3.setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeCoinImage3(): void {
    this.form.controls.coinImage3.setValue('');
  }
}
