import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BubblesData, BubbleChartDescriptions, BubbleChartColorLegend } from './../../models/ChartDataList';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BubbleChartDataList } from '../../models/ChartDataList';

@Component({
  selector: 'urban-google-bubblechart',
  templateUrl: './google-bubblechart.component.html',
  styleUrls: ['./google-bubblechart.component.scss']
})
export class GoogleBubblechartComponent implements OnInit, OnChanges, OnDestroy {

  @Input('bubbleChartTitle') public title: string = "";
  @Input('bubbleChartSubtitle') public subtitle: string;
  @Input('bubbleChartId') public chartDivId: string = '';
  @Input('passedChartData') private passedData: BubbleChartDataList | BubblesData;
  @Input('chartLegend') public colorLegend: BubbleChartColorLegend = {
    MinAmount: '0%',
    MaxAmount: '100%'
  }
  @Input('passedDataDescriptions') private dataDescriptions: BubbleChartDescriptions;
  @Input('darkThemeActive') public darkThemeActive: boolean;
  @Input('selectedType') public selectedType: string;
  @Input('chartPrecision') private bubblesPerSide: number = 10;
  @Input('amountLimit') private maxAmount: number;
  @Input('chartWidth') private width: number = 10000;
  @Input('chartHeight') private height: number = 10000;
  @Input('flipChart') private flip: 'X' | 'Y';
  @Input('sheerLayout') public sheer: boolean = false;
  @Input('compressedLayout') public compressed: boolean = false;
  @Input('nestedChart') public nested: boolean = false;
  @Output('chartDrawn') drawn: EventEmitter<any> = new EventEmitter();

  @HostBinding('style.--container-height') private containerHeight: string;
  @HostBinding('style.--container-slope') private containerSlope: number;

  @ViewChild('chartContainer') private container: ElementRef;

  private chart: google.visualization.BubbleChart;
  private chartOptions: google.visualization.BubbleChartOptions;
  public noDataOnChart: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private googleChartLoaded: boolean = false;
  private translations: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  constructor(private translate: TranslateService) {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.onLoadedChartDraw.bind(this));
  }

  ngOnInit(): void {
    this.chartDivId = `divbubbleChart${this.chartDivId}`;

    if (this.width && this.height && this.height > 0 && this.width > this.height) {
      this.containerSlope = this.height / this.width;
      this.containerHeight = (100 * (this.containerSlope)) + '%';
    }
    else {
      this.containerSlope = 1;
      this.containerHeight = '100%';
    }

    Object.keys(this.dataDescriptions).forEach((key: string) => {
      this.translations.push(this.dataDescriptions[key]);
    })
    this.getTranslations();
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
      this.tryDrawChart();
    });
  }

  getTranslations(): void {
    this.translate.get(this.translations).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      Object.keys(this.dataDescriptions).forEach((key: string, index: number) => {
        this.dataDescriptions[key] = res[this.translations[index]];
      })
    });
  }

  tryDrawChart(): void {
    if (this.googleChartLoaded) {
      this.drawChart();
    }
  }

  onLoadedChartDraw(): void {
    this.googleChartLoaded = true;
    this.drawChart();
  }

  drawChart(): void {
    if (this.passedData && this.passedData !== undefined && Object.keys(this.passedData).length > 0) {

      let maxSize: number;
      if (this.bubblesPerSide !== 0 && this.dataDescriptions.sizeAxis) {
        maxSize = this.container.nativeElement.offsetWidth / this.bubblesPerSide * 0.4;
      } else {
        maxSize = this.container.nativeElement.offsetWidth * 0.04;
      }

      this.noDataOnChart = false;

      let myData = new google.visualization.DataTable();

      myData.addColumn('string', 'ID');
      myData.addColumn('number', this.dataDescriptions.hAxis);
      myData.addColumn('number', this.dataDescriptions.vAxis);
      myData.addColumn('number', this.dataDescriptions.colorAxis);
      if (this.dataDescriptions.sizeAxis) {
        myData.addColumn('number', this.dataDescriptions.sizeAxis);
      }

      let bubblesData: BubblesData = this.selectedType ? this.passedData[this.selectedType] : this.passedData;
      let maxBubbleAmount: number;
      if (bubblesData !== undefined) {
        if (this.maxAmount === undefined) {
          maxBubbleAmount = 0;
          Object.keys(bubblesData).forEach((keyX: string) => {
            Object.keys(bubblesData[keyX]).forEach((keyY: string) => {
              if (bubblesData[keyX][keyY].Value > maxBubbleAmount) {
                maxBubbleAmount = bubblesData[keyX][keyY].Value;
              }
            });
          });
        }
        else {
          maxBubbleAmount = this.maxAmount;
        }

        Object.keys(bubblesData).forEach((keyX: string) => {
          Object.keys(bubblesData[keyX]).forEach((keyY: string) => {
            let describedAmount = { v: bubblesData[keyX][keyY].Value, f: bubblesData[keyX][keyY].Description };
            let amountPercentage : number = Math.floor(describedAmount.v / maxBubbleAmount * 100);
            let colorAndPercentage = { v: amountPercentage, f: (amountPercentage < 1 ? '<1%' : `${amountPercentage}%`) };
            let rowToAdd: any[] = ['', +keyX, +keyY, colorAndPercentage];
            if (this.dataDescriptions.sizeAxis) {
              rowToAdd.push(describedAmount);
            }
            myData.addRow(rowToAdd);
          });
        });
      }

      if (this.darkThemeActive) {
        this.chartOptions = {
          chartArea: {
            width: this.sheer ? '100%' : '80%',
            height: this.sheer ? '100%' : '80%'
          },
          bubble: {
            textStyle: {
              fontSize: 12,
              color: 'white',
            },
          },
          backgroundColor: 'transparent',
          legend: {
            position: 'none'
          },
          colorAxis: {
            legend: { position: 'none' },
            colors: ['#3D43B3', 'red'],
            minValue: 49,
            maxValue: 100
          },
          hAxis: {
            textPosition: this.sheer ? 'none' : null,
            viewWindow: {
              min: 0,
              max: this.width
            },
            direction: this.flip !== undefined && this.flip === 'X' ? -1 : 1,
            baselineColor: this.sheer ? 'transparent' : null,
            gridlines: this.sheer ? {
              count: 0
            } : null,
            minorGridlines: this.sheer ? {
              count: 0
            } : null
          },
          vAxis: {
            textPosition: this.sheer ? 'none' : null,
            viewWindow: {
              min: 0,
              max: this.height
            },
            direction: this.flip !== undefined && this.flip === 'Y' ? -1 : 1,
            baselineColor: this.sheer ? 'transparent' : null,
            gridlines: this.sheer ? {
              count: 0
            } : null,
            minorGridlines: this.sheer ? {
              count: 0
            } : null
          },
          sizeAxis: {
            maxSize: maxSize ? maxSize : null
          }
        }

      } else {
        this.chartOptions = {
          chartArea: {
            width: this.sheer ? '100%' : '80%',
            height: this.sheer ? '100%' : '80%'
          },
          bubble: {
            textStyle: {
              fontSize: 12,
              color: 'white',
            },
          },
          backgroundColor: 'transparent',
          legend: {
            position: 'none'
          },
          colorAxis: {
            legend: { position: 'none' },
            colors: ['#3D43B3', 'red'],
            minValue: 49,
            maxValue: 100
          },
          hAxis: {
            textPosition: this.sheer ? 'none' : null,
            viewWindow: {
              min: 0,
              max: this.width
            },
            direction: this.flip !== undefined && this.flip === 'X' ? -1 : 1,
            baselineColor: this.sheer ? 'transparent' : null,
            gridlines: this.sheer ? {
              count: 0
            } : null,
            minorGridlines: this.sheer ? {
              count: 0
            } : null
          },
          vAxis: {
            textPosition: this.sheer ? 'none' : null,
            viewWindow: {
              min: 0,
              max: this.height
            },
            direction: this.flip !== undefined && this.flip === 'Y' ? -1 : 1,
            baselineColor: this.sheer ? 'transparent' : null,
            gridlines: this.sheer ? {
              count: 0
            } : null,
            minorGridlines: this.sheer ? {
              count: 0
            } : null
          },
          sizeAxis: {
            maxSize: maxSize ? maxSize : null
          }
        }
      }

      let elem: Element = document.getElementById(this.chartDivId);
      if (elem !== null) {
        this.chart = new google.visualization.BubbleChart(elem);
        this.chart.draw(myData, this.chartOptions);
        this.drawn.emit();
      }
    } else {
      this.noDataOnChart = true;
      if (this.chart) {
        this.chart.clearChart();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isDarkActive = changes['darkThemeActive'];
    if (isDarkActive?.currentValue !== isDarkActive?.previousValue && isDarkActive?.firstChange == false) {
      this.tryDrawChart();
    }
    if (changes['passedData']) {
      const updatedData = changes['passedData'];
      if (updatedData.currentValue !== updatedData.previousValue && updatedData.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['selectedType']) {
      const selectedType = changes['selectedType'];
      if (selectedType.currentValue !== selectedType.previousValue && selectedType.firstChange == false) {
        this.tryDrawChart();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
