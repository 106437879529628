<h1 mat-dialog-title>{{ 'NOTIFICATION_ADD.ADD' | translate }}</h1>

<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'NOTIFICATION_ADD.DESTINATION_TYPE' | translate }}</mat-label>
            <mat-select formControlName="destinationTypeSelected" (selectionChange)="setDestination()" required>
                <mat-option value="u">{{ 'GENERAL.USER' | translate }}
                </mat-option>
                <mat-option value="d">{{ 'GENERAL.DOMAIN' | translate }}
                </mat-option>
                <mat-option value="a">{{ 'GENERAL.BROADCAST' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'NOTIFICATION_ADD.DESTINATION' | translate }}</mat-label>
            <mat-select formControlName="destinationSelected" required>
                <mat-option *ngFor="let user of userList" value={{user.Id}}>{{user.Firstname}} {{user.Lastname}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'NOTIFICATION_ADD.PAYLOAD' | translate }}</mat-label>
            <textarea required class="tableEvidenceBG" matInput formControlName="payload" matTextareaAutosize matAutosizeMinRows=15 matAutosizeMaxRows=30></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'NOTIFICATION_ADD.EXPIRE_DATE' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="sdt"
                [min]="minDate"
                formControlName="expirationDate" autocomplete="off"
                readonly
                required>
            <mat-datepicker-toggle matSuffix [for]="sdt"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #sdt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>

<div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button class="btn outlineActionButton ml-2" matDialogClose>{{ 'GENERAL.CANCEL' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" (click)="add()">{{ 'GENERAL.ADD'|translate }}</button>
</div>
