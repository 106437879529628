import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceTypeCapabilityDialogComponent } from '../../../shared/dialogs/device-type-capability-dialog/device-type-capability-dialog.component';
import { DeviceType } from '../../../shared/models/deviceType';
import { DeviceTypeCapability } from '../../../shared/models/deviceTypeCapability';
import { UserRoles } from '../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../shared/services/administrator-api.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-device-type',
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.scss']
})
export class DeviceTypeComponent implements OnInit, OnDestroy {

  public deviceType: DeviceType;
  public deviceTypeCapabilities: DeviceTypeCapability[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public error: string | null;
  public userRoles: UserRoles['Roles'];
  public filteredData: DeviceTypeCapability[] = [];
  capabilityColumns = ['Name', 'Label', 'Action', 'Order', 'Detail'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    icon: [{ value: '', disabled: false }, Validators.required],
    mapPin: [{ value: '', disabled: false }, Validators.required],
    color: [{ value: '', disabled: false }, Validators.required],
  });

  constructor(
    private router: Router,
    private administratorApiService: AdministratorApiService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
    this._initialize();
  }

  public update(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.deviceType = {
            Id: this.deviceType.Id,
            Name: this.form.controls.name.value,
            Icon: this.form.controls.icon.value,
            MapPin: this.form.controls.mapPin.value,
            Color: this.form.controls.color.value
          }

          this.administratorApiService.updateDeviceType(this.deviceType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this._initialize();
            }
          });
        }
      });

    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  public addCapability(): void {
    const addDialogRef = this.dialog.open(DeviceTypeCapabilityDialogComponent, {
      data: { type: this.deviceType },
      width: '50%'
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newCapability => {
      if (newCapability) {
        const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.addDeviceTypeCapability(newCapability).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public openCapabilityDetail(capability: DeviceTypeCapability): void {
    const updateDialogRef = this.dialog.open(DeviceTypeCapabilityDialogComponent, {
      data: { type: this.deviceType, capability: capability },
      width: '50%'
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedCapability => {
      if (updatedCapability) {
        const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.updateDeviceTypeCapability(updatedCapability).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }


  public capabilityDelete(capability: DeviceTypeCapability): void {
    if (!capability || capability === undefined) {
      return;
    }

    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.administratorApiService.deleteDeviceTypeCapability(capability.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    });
  }

  private _initialize(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      let deviceTypeId: string;

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          deviceTypeId = navInfo.Id;
        } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
          return;
        }

        this.administratorApiService.deviceTypeGet(deviceTypeId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.deviceType = result;
            if (this.deviceType.Capabilities) {
              this.deviceTypeCapabilities = this.deviceType.Capabilities;
            }
            this.filteredData = this.deviceTypeCapabilities;

            this.form.controls.name.setValue(this.deviceType.Name);
            this.form.controls.icon.setValue(this.deviceType.Icon);
            this.form.controls.mapPin.setValue(this.deviceType.MapPin);
            this.form.controls.color.setValue(this.deviceType.Color);
          }
        });
      });
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceTypeCapabilities?.filter((deviceType: DeviceTypeCapability) =>
      [
        deviceType.Action?.toLowerCase(),
        deviceType.Label?.toLowerCase(),
        deviceType.Name?.toLowerCase(),
        deviceType.Order?.toString()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
