<div class="container-fluid px-1 px-md-3">
    <div
        class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'TRASH_BIN.TITLE' | translate }}
        </span>
        <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span>
    </div>

    <mat-tab-group id="intro-trash-bin-tab" animationDuration="0ms" dynamicHeight="true" class="mb-3 position-relative">
        <mat-tab [label]="'GENERAL.STATUS' | translate" #statusTab>
            <div class="tabContent position-relative">
                <div id="tableDataContent" class="position-relative">
                    <div [ngClass]="trashBinDataSource === undefined ? 'initialHeight' : 'finalHeight'" class="customTable">
                        <table *ngIf="trashBinDataSource?.data.length > 0" id="intro-trash-bin-table"
                            class="w-100 h-100 appearing" mat-table [dataSource]="trashBinDataSource">

                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'GENERAL.NAME' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item"> {{item.Device.Name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Read">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'GENERAL.DATE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item"> {{ item.Read*1000 | date : 'short' }} </td>
                            </ng-container>

                            <ng-container matColumnDef="Percentage">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'TRASH_BIN.PERCENTAGE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                    {{item.Percentage}}%
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDeviceDetails(row.Device.Id)"></tr>
                        </table>
                    </div>
                    <urban-loader [localized]="true" [disappearCondition]="loadingLatestData === false">
                    </urban-loader>
                </div>

                <div class="heatmapContainer" [ngClass]="{'mt-0': mapReady && latestTrashBinEvents?.length === 0}">
                    <div class="mapContainer" [ngClass]="mapReady && latestTrashBinEvents !== undefined  ? 'finalHeight' : 'initialHeight'">
                        <urban-heatmap-trash-bin-maps class="appearing mapPanel"
                            *ngIf="mapReady && latestTrashBinEvents?.length > 0"
                            [trashBinElements]='latestTrashBinEvents' [deviceId]='this.selectedTrashBin?.Id'
                            [maxValueHeatmap]='100' [passedLastUpdate]='lastUpdate' [darkModeStatus]="isDarkActive">
                        </urban-heatmap-trash-bin-maps>
                    </div>
                    <urban-loader [localized]="true" [disappearCondition]="mapReady && latestTrashBinEvents !== undefined">
                    </urban-loader>
                </div>

                <div class="d-flex flex-column align-items-center noDataAppearing noDataIllustration" *ngIf="mapReady && latestTrashBinEvents?.length === 0">
                    <img class="mt-2" [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                </div>
            </div>
        </mat-tab>

        <mat-tab [label]="'GENERAL.MAP' | translate" #mapTab>
                <div class="tabContent position-relative">
                    <urban-parking-maps-widget *ngIf="mapTab.isActive && mapReady" class="mapPanel"
                        [latestEvents]="[]" [devicesList]="trashBinDevices"
                        [darkThemeActive]="isDarkActive" [passedHeight]="650">
                    </urban-parking-maps-widget>
                </div>
        </mat-tab>

        <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
            <div class="tabContent position-relative">
                <urban-searchbar id="intro-trash-bin-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingEventsData !== false || loadingEventsData !== false"
                    (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
                </urban-searchbar>

                <div class="" *ngIf="alertPanelInput !== undefined">
                    <urban-alert-panel
                        [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
                        [titleText]="alertPanelInput.TitleText | translate" [descriptionText]="alertPanelInput.DescriptionText"
                        [buttonLabel]="isDomainAdmin ? ('DEVICE.VIEW_EVENTS' | translate) : null"
                        (buttonAction)="isDomainAdmin ? goToDomainEvents() : null" (close)="alertPanelInput = undefined">
                    </urban-alert-panel>
                </div>

                <div #trashBinAveragePanel class="roundedPanel panelBG chartContainer position-relative" *ngIf="chartsTab.isActive"
                    [ngClass]="{'initialHeight': loadingEventsData === undefined || chartReady === undefined, 'finalHeight': loadingEventsData === false && chartReady, 'loadingHeight': loadingEventsData === true}">

                    <urban-loader [localized]="true" [disappearCondition]="loadingEventsData === false && chartReady">
                    </urban-loader>

                    <urban-google-barchart *ngIf="objectKeysNumber(averageTrashBinPercentages) > 0"
                        id="trashBinAverage-content" class="appearing"
                        [barChartTitle]="chosenDatesSearch ? 'TRASH_BIN.AVERAGE' : 'TRASH_BIN.LAST_24H_AVERAGE'"
                        barsTitle="REPORT.trash-bin" valuesDescription="TRASH_BIN.PERCENTAGE"
                        [passedChartData]="averageTrashBinPercentages" [limitValue]="100" valuesUnit="%"
                        [darkThemeActive]="isDarkActive" darkBackgroundColor="#021c34" lightBackgroundColor="#ffffff"
                        (chartDrawn)="chartReady = true">
                    </urban-google-barchart>
                </div>

                <div class="d-flex align-items-center justify-content-center noDataInitial noDataIllustrationInTabs"
                    [ngClass]="{'noDataAppearing': objectKeysNumber(averageTrashBinPercentages) === 0, 'noDataDisappearing': loadingEventsData}">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <div>
                        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex noDataActions">
                            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>

    </mat-tab-group>
</div>
