import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddServiceRequest, ServiceType } from '../../models/service/service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceGroup } from '../../models/deviceGroup';

@Component({
  selector: 'urban-service-dialog',
  templateUrl: './service-dialog.component.html',
  styleUrls: ['./service-dialog.component.scss']
})
export class ServiceDialogComponent implements OnInit {

  public serviceTypes: ServiceType[] = [];
  public deviceGroups: DeviceGroup[] = [];
  public error: boolean = false;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    description: [{ value: '', disabled: false }, Validators.required],
    type: [{ value: null, disabled: false }, Validators.required],
  });

  public serviceGroupForm: UntypedFormGroup = this.formBuilder.group({
    group: ['', Validators.required],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        types: ServiceType[]
        groups: DeviceGroup[]
      }
  ) { }

  ngOnInit(): void {
    this.serviceTypes = this.data?.types;
    this.deviceGroups = this.data?.groups;
  }

  public executeAction(): void {
    if (this.serviceTypes && this.form.valid) {
      this.handleAddServiceForm();
    } else if (this.deviceGroups && this.serviceGroupForm.valid) {
      this.handleAddServiceGroupForm();
    } else {
      this.error = true;
    }
  }

  private handleAddServiceForm(): void {
    this.error = false;

    const formValues: AddServiceRequest = {
      Name: this.form.controls.name.value,
      Description: this.form.controls.description.value,
      Type: this.form.controls.type.value
    }

    this.dialogRef.close(formValues);
  }

  private handleAddServiceGroupForm(): void {
    this.error = false;

    const groupToAdd: string = this.serviceGroupForm.controls.group.value;
    this.dialogRef.close(groupToAdd);
  }
}
