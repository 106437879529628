import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { MatTableDataSource } from '@angular/material/table';
import { Device } from './../../../shared/models/device';
import { TranslateService } from '@ngx-translate/core';
import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { CrosswalkDetailData, CrosswalkEvent, CrosswalkEventAverage, CrosswalkEventAveragePerColor, CrosswalksPerStatus } from './../../../shared/models/smartCrosswalk';
import { ChartDataList } from '../../../shared/models/ChartDataList';
import { first, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';


@Component({
  selector: 'urban-crosswalk-detail',
  templateUrl: './crosswalk-detail.component.html',
  styleUrls: ['./crosswalk-detail.component.scss']
})
export class CrosswalkDetailComponent implements OnInit, OnDestroy {

  public crosswalkDevice: Device;
  public crosswalkEvents: CrosswalkEvent[] = [];
  public crosswalkAveragesPerColor: CrosswalkEventAverage[];
  public historyDataToPass: ChartDataList[] = [];
  public averageDataToPass: ChartDataList = {};
  public dataDescriptions: string[];
  public crosswalkDataSource: MatTableDataSource<CrosswalkDetailData>;
  public displayedColumns: string[] = ['Traffic light color', 'Total crosswalks', 'Percentage crosswalks'];
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  public readyToShow: boolean = false;
  public translationsReady: boolean = false;
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private passDataService: PassDataService,
    private translate: TranslateService,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService
  ) {}

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadData(deviceId);
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    let colorPhrases: string[] = ['CROSSWALK.GREEN', 'CROSSWALK.YELLOW', 'CROSSWALK.RED'];
    this.setDynamicTranslations(colorPhrases, (res: any) => this.afterColorTranslations(res));

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterColorTranslations(res: any): void {
    this.dataDescriptions = [];
    this.dataDescriptions.push(res['CROSSWALK.GREEN']);
    this.dataDescriptions.push(res['CROSSWALK.YELLOW']);
    this.dataDescriptions.push(res['CROSSWALK.RED']);
    this.translationsReady = true;
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  private loadData(deviceId: string): void {
    let deviceEventsFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues.every(value => value)) {
        this.crosswalkEvents = this.mapToCrosswalkEvents(data[deviceEventsFeature], [this.crosswalkDevice]);
      }

      if (this.crosswalkEvents.length > 0) {
        this.setAllData();
        this.readyToShow = true;
      }
      else {
        this.readyToShow = false;
      }

      this.loadingData = false;
    }, context);

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device) => {
      if (res) {
        this.crosswalkDevice = res;
        this.apiSync.loadedFeature(context);
      }
      else {
        this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.apiService.getDeviceEvents({ DeviceId: deviceId })
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, context);
      }
      else {
        this.apiSync.failedFeature(context);
      }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;

    let eventsRequest: DeviceEventLatestRequest = { DeviceId: this.crosswalkDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest, this.isAProperEvent)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if(res && res.Devices?.length > 0) {
        this.crosswalkEvents = this.mapToCrosswalkEvents(res, [this.crosswalkDevice]);
      }

      if (this.crosswalkEvents.length > 0) {
        this.setLatestDates(res);
        this.setAllData();

        this.readyToShow = true;
      }
      else {
        this.readyToShow = false;
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;

    let eventsRequest: DeviceEventRequest = {
      DeviceId: this.crosswalkDevice.Id,
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
        this.resetAllData();
        if (res && res.Devices?.length > 0) {
          this.crosswalkEvents = this.mapToCrosswalkEvents(res, [this.crosswalkDevice]);
        }

        if (this.crosswalkEvents.length > 0) {
          this.setAllData();

          this.readyToShow = true;
        }
        else {
          this.last24hSearch = selectedDates.last24hSearch;
          this.readyToShow = false;
        }
        this.loadingData = false;
    });
  }

  private resetAllData(): void {
    this.crosswalkEvents = [];
    this.historyDataToPass = [];
    this.averageDataToPass = {};
    this.crosswalkDataSource = new MatTableDataSource<CrosswalkDetailData>();
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private setAllData(): void {
    this.setStatusTableData();
    this.setChartsData();
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event));
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find((event: EventLatest) => event.Level === 'Info' && this.isAProperEvent(event));
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private setStatusTableData(): void {
    let crosswalksPerColor: CrosswalksPerStatus = this.calculateTotalCrosswalksPerColor(this.crosswalkEvents);
    let crosswalkDetailData: CrosswalkDetailData[] = this.getCrosswalkData(crosswalksPerColor);
    this.crosswalkDataSource = new MatTableDataSource<CrosswalkDetailData>(crosswalkDetailData);
  }

  private setChartsData(): void {
    this.setHistoryChartData();
    this.setAverageChartData();
  }

  private setHistoryChartData(): void {
    let roundedConvertedTimes: string[] = this.calculateRoundedAndConvertedTimes(this.crosswalkEvents);
    let trafficLightColorsData: Record<number, ChartDataList> = this.getCrosswalksPerColor(this.crosswalkEvents)
    this.historyDataToPass = this.getHistoryChartData(roundedConvertedTimes, trafficLightColorsData);
  }

  private setAverageChartData(): void {
    let crosswalkAveragesPerColor: CrosswalkEventAveragePerColor[] = this.calculateCrosswalkEventsAveragesPerColor(this.crosswalkEvents);
    this.averageDataToPass = this.getAverageChartData(crosswalkAveragesPerColor, this.dataDescriptions);
  }

  private isAProperEvent(event: DeviceEventsEvent | EventLatest): boolean {
    return event.Body && Object.keys(event.Body).includes('Total') && [0, 1, 2].includes(event.Body.Status);
  }

  private mapToCrosswalkEvents(res: DeviceEventsResponse, crosswalkDevices: Device[]): CrosswalkEvent[] {
    let formattedEvents: CrosswalkEvent[] = [];

    res.Devices.forEach((device: DeviceEventsDevice) => {
      let crosswalkDevice: Device;
      if ((crosswalkDevice = crosswalkDevices.find(oneDevice => oneDevice.Id === device.Id)) !== undefined) {
        device.Events.forEach((event: DeviceEventsEvent) => {
          if (Object.keys(event.Body).includes('Total') && [0, 1, 2].includes(event.Body.Status)) {
            let crosswalkEvent: CrosswalkEvent = {
              Created: event.CreatedTimestamp,
              Device: crosswalkDevice,
              DomainId: crosswalkDevice.Domain.Id,
              Total: event.Body.Total,
              Status: event.Body.Status
            };

            formattedEvents.push(crosswalkEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  private calculateTotalCrosswalksPerColor(crosswalkEvents: CrosswalkEvent[]): CrosswalksPerStatus {
    let crosswalksPerColor: CrosswalksPerStatus = {
      0: 0,
      1: 0,
      2: 0
    };

    crosswalkEvents.forEach((event: CrosswalkEvent) => {
      crosswalksPerColor[event.Status] += event.Total;
    });

    return crosswalksPerColor;
  }

  private calculateCrosswalkEventsAveragesPerColor(events: CrosswalkEvent[]): CrosswalkEventAveragePerColor[] {
    let averagesPerColor: CrosswalkEventAveragePerColor[] = [];
    let countsPerStatus: Record<0 | 1 | 2, number> = {
      0: 0, //red
      1: 0, //yellow
      2: 0  //green
    };

    for (let status in countsPerStatus) {
      averagesPerColor[status] = {
        Created: 0,
        Total: 0,
        Status: status
      };
    }
    events.forEach((event: CrosswalkEvent) => {
      averagesPerColor[event.Status].Total += event.Total;
      countsPerStatus[event.Status]++;
    });
    for (let status in countsPerStatus) {
      if(countsPerStatus[status] !== 0) {
        averagesPerColor[status].Total = Math.round(averagesPerColor[status].Total / countsPerStatus[status] * 100) / 100;
      }
    }

    return averagesPerColor;
  }

  private getCrosswalkData(crosswalksPerColor: CrosswalksPerStatus): CrosswalkDetailData[] {
    let crosswalkData: CrosswalkDetailData[] = [];
    let colorsStrings: string[] = [ 'red', 'yellow', 'green'];
    let totalCrosswalks: number = 0;

    Object.keys(crosswalksPerColor).forEach((status: string) => {
      totalCrosswalks += crosswalksPerColor[status];
    });

    Object.keys(crosswalksPerColor).forEach((status: string) => {
      crosswalkData.push({
        Color: colorsStrings[+status],
        Total: crosswalksPerColor[status],
        Percentage: Math.round(crosswalksPerColor[status] / totalCrosswalks * 100 * 100) / 100
      })
    });

    return crosswalkData;
  }

  private getAverageChartData(averagesPerColor: CrosswalkEventAveragePerColor[], descriptions: string[]): ChartDataList {
    let averageData: ChartDataList = {};

    descriptions.forEach((color: string, colorIndex: 0 | 1 | 2) => {
      averageData[color] = averagesPerColor[2 - colorIndex].Total
    });

    return averageData;
  }

  private calculateRoundedAndConvertedTimes(crosswalkEvents: CrosswalkEvent[]): string[] {
    let timeInSeconds: number = this.roundTime(crosswalkEvents[crosswalkEvents.length - 1].Created);
    let roundedConvertedTimes: string[] = [this.convertTime(timeInSeconds)];

    while (timeInSeconds < this.roundTime(crosswalkEvents[0].Created)) {
      timeInSeconds += 5 * 60;
      roundedConvertedTimes.push(this.convertTime(timeInSeconds));
    }

    return roundedConvertedTimes;
  }

  private getCrosswalksPerColor(crosswalkEvents: CrosswalkEvent[]): Record<number, ChartDataList> {
    let colorsData: Record<number, ChartDataList> = {
      0: {},  //red
      1: {},  //yellow
      2: {}   //green
    }

    crosswalkEvents.slice().reverse().forEach((singleEvent: CrosswalkEvent) => {
      let roundedTime: string = this.convertTime(this.roundTime(singleEvent.Created));
      if (colorsData[singleEvent.Status][roundedTime] === undefined) {
        colorsData[singleEvent.Status][roundedTime] = singleEvent.Total;
      }
      else {
        colorsData[singleEvent.Status][roundedTime] += singleEvent.Total;
      }
    });

    return colorsData;
  }

  private getHistoryChartData(times: string[], colorsData: Record<number, ChartDataList>): ChartDataList[] {
    let historyData: ChartDataList[] = [];

    times.forEach((oneTime: string) => {
      historyData[oneTime] = {};
      historyData[oneTime][this.dataDescriptions[0]] = colorsData[2][oneTime] ? colorsData[2][oneTime] : null;
      historyData[oneTime][this.dataDescriptions[1]] = colorsData[1][oneTime] ? colorsData[1][oneTime] : null;
      historyData[oneTime][this.dataDescriptions[2]] = colorsData[0][oneTime] ? colorsData[0][oneTime] : null;
    });

    return historyData;
  }

  roundTime(seconds: number): number {
    return seconds - (seconds % (5 * 60));
  }

  convertTime(seconds: number): string {
    return new Date(seconds * 1000).toString();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.crosswalkEvents.length === 0) {
      this.readyToShow = false;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceEvents(): void {
    if (this.crosswalkDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.crosswalkDevice.Id, BackRoute: 'smart-crosswalk-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
