import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChartDescribedDataList, SankeyDataList, DescribedValue, ChartDataList, Path, Point, BubbleChartDataList, PathsChartLegend, BubbleChartDescriptions, BubbleChartColorLegend } from './../../../shared/models/ChartDataList';
import { TransitionsPerTrackerID, TransitDirection, CardinalPoint, TypeTransitPercentage, TransitAmountsPerType, TransitStay, TimeSpan, TransitStop, PathsIndexesBySlopeInterceptType } from './../../../shared/models/transit';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsResponse, DeviceEventsEvent } from './../../../shared/models/deviceEvent';
import { MainSubscriptionsService } from './../../../shared/services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';
import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { ApiService } from './../../../shared/services/api.service';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Device } from 'src/app/shared/models/device';
import { takeUntil, first } from 'rxjs/operators';
import { Transition } from 'src/app/shared/models/transit';
import { Sensor } from 'src/app/shared/models/weatherstation/sensor';
import { SensorData } from 'src/app/shared/models/weatherstation/sensorData';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { TimedPosition } from 'src/app/shared/models/timedPosition';

@Component({
  selector: 'urban-transit-detail',
  templateUrl: './transit-detail.component.html',
  styleUrls: ['./transit-detail.component.scss']
})
export class TransitDetailComponent implements OnInit, OnDestroy {
  public transitDevice: Device;
  public transitions: Transition[];
  public transitTypes: string[];
  public typeSelected: string = 'all';
  public pathsChartTypeSelected: string;
  public filteredTransitions: Transition[];
  private transitDirections: TransitDirection[];
  private transitStays: TransitStay[];
  public transitDataSource: MatTableDataSource<TypeTransitPercentage>;
  public displayedColumns: string[] = ['Type', 'Percentage'];
  public sankeyDataList: SankeyDataList = {};
  public sankeySubtitle: string = null;
  public columnChartData: ChartDescribedDataList;
  public columnChartSubtitle: string = null;
  public columnChartVAxisTicks: DescribedValue[];
  public lineChartData: Sensor;
  public lineChartTimeLimits: Record<'start' | 'end', number>;
  public historyPeriod: number = 30;
  public hourlyColumnChartData: ChartDataList;
  public spatialPrecision: number = 10;
  private minAreaLimit: Point = { X: 0, Y: 0 };
  private maxAreaLimit: Point;
  private limitsError: boolean = false;
  public pathsChartData: Path[];
  public pathsChartLegend: PathsChartLegend = {
    Transparent: 'TRANSIT_DEVICE.PAST_PATH',
    Opaque: 'TRANSIT_DEVICE.RECENT_PATH',
    Thin: 'TRANSIT_DEVICE.MIN_AMOUNT_PATH',
    Thick: 'TRANSIT_DEVICE.MAX_AMOUNT_PATH',
    TypePrefix: 'TRANSIT_DEVICE.TYPE_'
  };
  private transitStops: TransitStop[];
  public stopBubbleChartData: BubbleChartDataList;
  public bubbleChartLegend: BubbleChartColorLegend = {
    MinAmount: 'TRANSIT_DEVICE.SHORTER_STOP',
    MaxAmount: 'TRANSIT_DEVICE.LONGER_STOP'
  }
  public bubbleChartDescriptions: BubbleChartDescriptions = {
    hAxis: 'X',
    vAxis: 'Y',
    colorAxis: 'TRANSIT_DEVICE.STAY_PERCENTAGE',
    sizeAxis: 'TRANSIT_DEVICE.PERIOD_OF_STAY'
  }
  public startDate: number;
  public endDate: number;
  public isDarkActive: boolean;
  public firstCall: boolean = undefined;
  public firstCallPathsChart: boolean = undefined;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean
  public setDates: boolean;
  public loadingData: boolean;
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private passDataService: PassDataService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;

        this.loadData(deviceId);
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }
    });

    this.passDataService.currentDarkModeStatus$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, deviceEventsFeature: number;

    const syncContext = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        this.resetAllData();

        let widthProp: string, heightProp: string;
        if(
          (widthProp = this.transitDevice.Properties.Width) &&
          (heightProp = this.transitDevice.Properties.Height) &&
          this.minAreaLimit.X < +widthProp &&
          this.minAreaLimit.X < +heightProp
        ) {
          this.maxAreaLimit = {
            X: +widthProp,
            Y: +heightProp
          }
        }
        else {
          this.limitsError = true;
        }

        if (checkValues[deviceEventsFeature]) {
          this.transitions = this.mapToTransitions(data[deviceEventsFeature], [this.transitDevice]);
        }

        if (this.transitions.length > 0) {
          this.filteredTransitions = this.transitions;
          this.setAllData();
        }
      }
      this.loadingData = false;
    });

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.transitDevice = device;

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
        this.setErrorAndGoToMain();
      }
    });

    let eventsRequest: DeviceEventRequest = { DeviceId: deviceId };

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      if (res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        deviceEventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;
    this.startDate = selectedDates.startDate;
    this.endDate = selectedDates.endDate;

    let eventsRequest: DeviceEventRequest = {
      DeviceId: this.transitDevice.Id,
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();
      if (res && res.Devices?.length > 0) {
        this.transitions = this.mapToTransitions(res, [this.transitDevice]);
      }

      if(this.transitions.length > 0) {
        if (this.typeSelected === 'all') {
          this.filteredTransitions = this.transitions;
        }
        else {
          this.filteredTransitions = this.transitions.filter((transition: Transition) => transition.Type === this.typeSelected);
        }
        this.setAllData();
      }

      this.loadingData = false;
      this.last24hSearch = selectedDates.last24hSearch;
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;

    let eventsRequest: DeviceEventLatestRequest = {
      DeviceId: this.transitDevice.Id
    }

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest, this.isAProperEvent)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.transitions = this.mapToTransitions(res, [this.transitDevice]);
      }

      if (this.transitions.length > 0) {
        this.setLatestDates(res);

        if (this.typeSelected === 'all') {
          this.filteredTransitions = this.transitions;
        }
        else {
          this.filteredTransitions = this.transitions.filter((transition: Transition) => transition.Type === this.typeSelected);
        }
        this.setAllData();
      }
      this.loadingData = false;
    });
  }

  private setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  private resetAllData(): void {
    this.transitions = [];
    this.filteredTransitions = [];
    this.transitTypes = [];
    this.transitDirections = [];
    this.transitStays = [];
    this.sankeyDataList = {};
    this.columnChartData = {};
    this.lineChartData = null;
    this.hourlyColumnChartData = {};
    this.pathsChartData = [];
    this.transitStops = [];
    this.stopBubbleChartData = {};
    this.transitDataSource = new MatTableDataSource<TypeTransitPercentage>();
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private setAllData(): void {
    this.setTransitTypes()
    this.setStatusTableData();
    this.setChartsData();
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event));
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find((event: EventLatest) => event.Level === 'Info' && this.isAProperEvent(event));
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private setTransitTypes(): void {
    this.transitTypes = ['all'];
    this.transitTypes.push(...this.transitions.map(x => x.Type).filter((v, i, a) => a.indexOf(v) === i));

    this.pathsChartTypeSelected = this.transitTypes[1];
  }

  private setChartsData(): void {
    if(this.limitsError == false) {
      this.setDirectionsChartData();
      this.setPathsChartData();
      this.setStopsChartData();
    }
    this.setStaysChartData();
    this.setHistoryChartData();
    this.setHourlyAveragesChartData();
  }

  private setStatusTableData(): void {
    let transitAmountsPerType: TransitAmountsPerType = {};

    this.transitTypes.slice(1).forEach((type: string) => {
      transitAmountsPerType[type] = 0;
    });

    this.transitions.forEach((transition: Transition) => {
        transitAmountsPerType[transition.Type]++;
    });

    let typesTransitsPercentages: TypeTransitPercentage[] = [];

    let entitiesNumber: number = this.transitions.length;
    Object.keys(transitAmountsPerType).forEach((type: string) => {
      let typeTransitPercentage: number = transitAmountsPerType[type] * 100 / entitiesNumber
      typesTransitsPercentages.push({
        Type: type,
        Percentage: Math.round(typeTransitPercentage * 100) / 100
      });
    });

    this.transitDataSource = new MatTableDataSource<TypeTransitPercentage>(typesTransitsPercentages);
  }

  private setDirectionsChartData(): void {
    this.transitDirections = this.calculateTransitsDirection(this.filteredTransitions, this.minAreaLimit, this.maxAreaLimit);
    this.sankeyDataList = this.calculateTransitAmountsPerDirection(this.transitDirections);
  }

  private setStaysChartData(): void {
    this.transitStays = this.calculateStays(this.filteredTransitions);
    this.columnChartData = this.orderAndDescribeStays(this.transitStays);
  }

  private setHistoryChartData(): void {
    this.lineChartTimeLimits = this.calculateTimePeriodLimits(this.transitStays, this.historyPeriod);
    let historyData: Record<number, number> = this.calculateTransitAmountsPerPeriod(this.transitStays, this.historyPeriod);
    this.lineChartData = this.prepareSensor(historyData);
  }

  private setHourlyAveragesChartData(): void {
    let periodsAverages: Record<number, number> = this.calculateTransitAmountsPerPeriod(this.transitStays, 60);
    this.hourlyColumnChartData = this.getHourlyAveragesData(periodsAverages);
  }

  private setPathsChartData(): void {
    let pathsData: Path[] = this.getPathsData(this.transitions, this.transitTypes.slice(1), this.spatialPrecision, this.minAreaLimit, this.maxAreaLimit);
    // console.log(pathsData);
    this.pathsChartData = pathsData;
  }

  private setStopsChartData(): void {
    this.transitStops = this.calculateStops(this.transitions, this.spatialPrecision, this.maxAreaLimit, this.minAreaLimit);
    this.stopBubbleChartData = this.groupStops(this.transitStops);
  }

  private isAProperEvent(event: DeviceEventsEvent | EventLatest): boolean {
    return event.Body.detections?.length > 0 &&
      event.Body.detections.every(detection =>
        ['attributes', 'type'].every(
          (key:string) => Object.keys(detection).includes(key)
        ) &&
        ['trackerID', 'timestamp', 'x_position', 'y_position'].every(
          (key: string) => Object.keys(detection.attributes).includes(key)
        )
      );
  }

  private mapToTransitions(res: DeviceEventsResponse, transitDevices: Device[]): Transition[] {
    let transitions: Transition[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let transitDevice: Device = transitDevices.find(oneDevice => oneDevice.Id === device.Id);
      if (transitDevice !== undefined) {
        let transitionsList: TransitionsPerTrackerID = {};
        device.Events.forEach((event: DeviceEventsEvent) => {
          let body = event.Body;
          if (body.detections?.length > 0) {
            body.detections.forEach(detection => {
              if (
                ['attributes', 'type'].every((key:string) => Object.keys(detection).includes(key)) &&
                ['trackerID', 'timestamp', 'x_position', 'y_position'].every(
                  (key: string) => Object.keys(detection.attributes).includes(key)
                )
              ) {
                let trackerId: number = detection.attributes.trackerID;

                if(transitionsList[trackerId] === undefined) {
                  transitionsList[trackerId] = {
                    Type: detection.type,
                    Positions: []
                  }
                }

                let position: TimedPosition = {
                  Created: detection.attributes.timestamp,
                  X: detection.attributes.x_position,
                  Y: detection.attributes.y_position
                }
                transitionsList[trackerId].Positions.push(position);
              }
            });
          }
        });

        Object.keys(transitionsList).forEach((trackerId: string) => {
          let transition: Transition = {
            Device: this.transitDevice,
            TrackerId: +trackerId,
            Type: transitionsList[+trackerId].Type,
            Positions: transitionsList[+trackerId].Positions
          };
          transitions.push(transition);
        });
      }

    });

    return transitions;
  }

  private calculateTransitsDirection(transitions: Transition[], areaMin: Point, areaMax: Point): TransitDirection[] {
    let transitsDirections: TransitDirection[] = [];
    if(transitions?.length > 0) {
      let diagonalSlope: number = (areaMax.Y - areaMin.Y) / (areaMax.X - areaMin.X);
      let mainDiagonalY: Function = (x: number) => {
        return ((x - areaMin.X) * diagonalSlope + areaMin.Y);
      }
      let secondaryDiagonalY: Function = (x: number) => {
        return ((areaMax.X - x) * diagonalSlope + areaMin.Y);
      }

      transitions.forEach((transition: Transition) => {
        let entranceExit: CardinalPoint[] = [];
        let firstLastPositions: TimedPosition[] = [transition.Positions[transition.Positions.length - 1], transition.Positions[0]];

        firstLastPositions.forEach((position: TimedPosition) => {
          if(position.Y > mainDiagonalY(position.X)) {
            if(position.Y < secondaryDiagonalY(position.X)){
              //West
              entranceExit.push('W');
            }
            else {
              //South
              entranceExit.push('S');
            }
          }
          else {
            if(position.Y < secondaryDiagonalY(position.X)){
              //North
              entranceExit.push('N');
            }
            else {
              //East
              entranceExit.push('E');
            }
          }
        });

        let transitionDirection: TransitDirection = {
          Device: transition.Device,
          TrackerId: transition.TrackerId,
          Type: transition.Type,
          EntranceDirection: entranceExit[0],
          ExitDirection:entranceExit[1]
        }
        transitsDirections.push(transitionDirection);
      });
    }

    return transitsDirections;
  }

  private calculateTransitAmountsPerDirection(transitDirections: TransitDirection[]): SankeyDataList {
    let directionsDataList: SankeyDataList = {};
    if(transitDirections?.length > 0) {
      const directions: CardinalPoint[] = ['N', 'S', 'E', 'W'];
      directions.forEach((entrance: CardinalPoint) => {
        directionsDataList[entrance] = {};
        directions.forEach((exit: CardinalPoint) => {
          directionsDataList[entrance][exit] = 0;
        });
      });
    }

    transitDirections.forEach((direction: TransitDirection) => {
      directionsDataList[direction.EntranceDirection][direction.ExitDirection]++;
    });

    return directionsDataList;
  }

  private calculateStays(transitions: Transition[]): TransitStay[] {
    let transitsStays: TransitStay[] = []
    transitions.forEach((transition: Transition) => {
      let stayInMilliseconds: number = transition.Positions[0].Created - transition.Positions[transition.Positions.length - 1].Created;

      let stay: TimeSpan = this.fromMillisecondsToTimeSpan(stayInMilliseconds);

      let transitStay: TransitStay = {
        Device: transition.Device,
        TrackerId: transition.TrackerId,
        Stay: stay,
        Start: transition.Positions[transition.Positions.length - 1].Created,
        End: transition.Positions[0].Created
      }

      transitsStays.push(transitStay);
    });

    return transitsStays;
  }

  private orderAndDescribeStays(stays: TransitStay[]): ChartDescribedDataList {
    let orderedAndDescribedStays: ChartDescribedDataList = {};
    const staysLength: number = stays.length;

    if(staysLength > 0) {
      stays.sort((a, b) => b.Stay.TotalMilliseconds - a.Stay.TotalMilliseconds);
      this.columnChartVAxisTicks = this.getMostSensibleTimeTicks(stays[0].Stay);

      if(staysLength > 10) {
        stays = stays.slice(0, 10);
      }
      stays.forEach((stay: TransitStay) => {
        let stayPeriod: TimeSpan = stay.Stay;
        orderedAndDescribedStays[stay.TrackerId+" "] = {
          Value: stayPeriod.TotalMilliseconds,
          Description: this.fromTimeSpanToString(stayPeriod)
        };
      });
    }

    return orderedAndDescribedStays;
  }

  private calculateTimePeriodLimits(stays: TransitStay[], period: number): Record<'start' | 'end', number> {
    let timePeriodLimits: Record<'start' | 'end', number>;

    if(stays?.length > 0) {
      // pick min start date and max end date, then set period limits
      let startMilliseconds: number = stays[0].Start;
      let endMilliseconds: number = stays[0].End;
      stays.slice(1).forEach((stay: TransitStay) => {
        if(startMilliseconds > stay.Start) {
          startMilliseconds = stay.Start;
        }
        if(endMilliseconds < stay.End) {
          endMilliseconds = stay.End;
        }
      });

      timePeriodLimits = {
        'start': this.roundTimeByHistoryPeriodAndGetSeconds(startMilliseconds, period),
        'end': this.roundTimeByHistoryPeriodAndGetSeconds(endMilliseconds, period)
      };
    }

    return timePeriodLimits;
  }

  private calculateTransitAmountsPerPeriod(stays: TransitStay[], period: number): Record<string, number> {
    let amountsPerPeriod: Record<number, number> = {};
    if(stays?.length > 0) {
      stays.forEach((stay: TransitStay) => {
        let startInSeconds: number = this.roundTimeByHistoryPeriodAndGetSeconds(stay.Start, period);
        const endInSeconds: number = this.roundTimeByHistoryPeriodAndGetSeconds(stay.End, period);

        while(startInSeconds <= endInSeconds) {
          if(amountsPerPeriod[startInSeconds] === undefined) {
            amountsPerPeriod[startInSeconds] = 1;
          }
          else {
            amountsPerPeriod[startInSeconds]++;
          }
          startInSeconds += period * 60;
        }
      });
    }
    return amountsPerPeriod;
  }

  private prepareSensor(sensorData: Record<number, number>): Sensor {
    let transitHistory: Sensor = null;

    if(sensorData && Object.keys(sensorData).length > 0) {
      transitHistory = {
        Name: this.transitDevice.Name,
        Limit: 'N.A.',
        Unit: '',
        Data: []
      };

      let transitHistoryData: SensorData[] = [];
      Object.keys(sensorData).forEach((created: string) => {
        transitHistoryData.push({ Created: +created, Value: sensorData[created].toString() });
      });

      transitHistory.Data = transitHistoryData;
    }

    return transitHistory;
  }

  private getHourlyAveragesData(periodAverages: Record<number, number>): ChartDataList {
    let hourlyAveragesData: ChartDataList = {};

    if(periodAverages && Object.keys(periodAverages).length > 0) {
      let hourlyEventsCount: Array<number> = Array(24).fill(0);

      for (let i: number = 0; i < 10; i++) {
        hourlyAveragesData[`0${i}:00`] = 0;
      }
      for (let i: number = 10; i < 24; i++) {
        hourlyAveragesData[`${i}:00`] = 0;
      }

      Object.keys(periodAverages).forEach((totalSeconds: string) => {
        let eventHour: number = new Date(+totalSeconds * 1000).getHours();
        let eventHourString: string;
        if(eventHour < 10) {
          eventHourString = `0${eventHour}:00`
        }
        else {
          eventHourString = `${eventHour}:00`
        }
        hourlyEventsCount[eventHour]++;
        hourlyAveragesData[eventHourString] += periodAverages[totalSeconds];
      });

      for (let i: number = 0; i < 10; i++) {
        if(hourlyEventsCount[i] !== 0) {
          hourlyAveragesData[`0${i}:00`] /= hourlyEventsCount[i];
        }
      }
      for (let i: number = 10; i < 24; i++) {
        if(hourlyEventsCount[i] !== 0) {
          hourlyAveragesData[`${i}:00`] /= hourlyEventsCount[i];
        }
      }
    }

    return hourlyAveragesData;
  }

  private getPathsData(transitions: Transition[], types: string[], pathsAmount: number, areaMin: Point, areaMax: Point): Path[] {
    let paths: Path[] = [];
    //the indexes of the paths (in the same line) are grouped by type, slope and intercept
    let pathsLines: PathsIndexesBySlopeInterceptType = {};
    //initialization by type
    types.forEach((type: string) => {
      pathsLines[type] = {};
    });

    transitions.forEach((transition: Transition) => {
      if (transition.Positions.length > 1 && this.transitTypes.includes(transition.Type)) { //check if type is correct
        //first point from where a path start, converted in the range chosen
        let prevPoint: Point = this.translatePositionToRangedPoint(transition.Positions[transition.Positions.length - 1], pathsAmount, areaMax, areaMin, true);

        transition.Positions.slice(0, -1).reverse().forEach((position: TimedPosition) => {
          let pointA: Point = prevPoint;
          let pointB: Point = this.translatePositionToRangedPoint(position, pathsAmount, areaMax, areaMin, true);

          if(!this.arePointsEqual(pointA, pointB)) { //check if 2 points are not coincident (no path)
            //console.log(`P1: {x: ${pointA.X}, y: ${pointA.Y}}, P2: {x: ${pointB.X}, y: ${pointB.Y}}`);

            //index for a possible concident path already in the "paths" array
            let pathIndex: number = paths.findIndex(path => (
              (
                (this.arePointsEqual(path.PositionA, pointA) && this.arePointsEqual(path.PositionB, pointB))
                ||
                (this.arePointsEqual(path.PositionA, pointB) && this.arePointsEqual(path.PositionB, pointA))
              )
              &&
              transition.Type === path.Type
            ));

            //calculate importance of a path: it leads to the opacity of the path displayed
            let importance: number = this.getImportance(position, this.startDate, this.endDate);

            if(pathIndex === -1) {
              //if the path is not in the "paths" array yet, then add it
              let path: Path = {
                PositionA: pointA,
                PositionB: pointB,
                OneWay: true,
                Amount: 1,
                Importance: importance,
                Type: transition.Type
              };

              //the slope and the intercept of the line which the path belongs to
              let pathSlope: number = this.getSlope(path, 6);
              if(pathSlope !== null) {
                path.Slope = pathSlope;
              }
              let slopeString: string = this.getSlopeString(pathSlope);

              let pathIntercept: number = this.getIntercept(path, 6);

              paths.push(path);

              //prepare data to analyze for grouping paths
              if(pathsLines[transition.Type][slopeString] === undefined) {
                pathsLines[transition.Type][slopeString] = {};
              }
              if(pathsLines[transition.Type][slopeString][pathIntercept] === undefined) {
                pathsLines[transition.Type][slopeString][pathIntercept] = [];
              }
              pathsLines[transition.Type][slopeString][pathIntercept].push(paths.length - 1);
            }
            else {
              //if the path is already in the "paths" array, then update it
              paths[pathIndex].Amount++;
              //if the coincident path has reverse direction, the result is a two-way path
              if(paths[pathIndex].OneWay && this.arePointsEqual(paths[pathIndex].PositionA, pointB)) {
                paths[pathIndex].OneWay = false;
              }
              paths[pathIndex].Importance = importance;
            }

            prevPoint = pointB;
          }
        });
      }
    });

    //GROUPING THE PATHS
    let newPaths: Path[] = [];
    this.accessToSinglePathIndexes(pathsLines, (indexes: number[], slope: string, _, type: string) => {
      //for each line, step by step from the first point to the last one, the paths coincident are evaluated
      let groupedPaths: Path[] = [];
      let axis: string = slope !== 'inf' ? 'X' : 'Y' //the steps are based on the horizontal axis, except for the vertical line case
      let linePaths: Path[] = paths.filter((_, index) => indexes.includes(index)); //get paths that are in the current line

      //steps depend on the start/end points of the paths
      let pointsSteps: Point[] = this.getSortedPointsSteps(linePaths, axis);

      pointsSteps.slice(0, -1).forEach((pointStep, index) => {
        let nextPointStep: Point = pointsSteps[index + 1];
        //paths involved in that certain segment between two adjacent points
        let involvedPathsIndexes: number[] = indexes.filter(pathIndex =>
          Math.min(paths[pathIndex].PositionA[axis], paths[pathIndex].PositionB[axis]) <= pointStep[axis] &&
          Math.max(paths[pathIndex].PositionA[axis], paths[pathIndex].PositionB[axis]) >= nextPointStep[axis]
        );

        if (involvedPathsIndexes.length > 0) { //check if it is not an empty segment
          let newPathAmount: number = 0;
          involvedPathsIndexes.forEach(index => {
            newPathAmount += paths[index].Amount;
          });

          //calculate if the resulting path is one-way or two-way
          let oneWay: boolean = involvedPathsIndexes.find(pathIndex => !paths[pathIndex].OneWay) === undefined;
          let pathPositive: boolean; //used to determine the direction of a one-way path
          if (oneWay) {
            let firstPath: Path = paths[involvedPathsIndexes[0]];
            pathPositive = firstPath.PositionA[axis] < firstPath.PositionB[axis];
            //if even one path has reverse direction, then the result is a two-way path
            if (
              involvedPathsIndexes.length > 1
              &&
              involvedPathsIndexes.slice(1).find(
                pathIndex =>
                  pathPositive !==
                  paths[pathIndex].PositionA[axis] < paths[pathIndex].PositionB[axis]
              ) !== undefined
            ) {
              oneWay = false;
            }
          }

          //if there is a previous adjacent sub-path, whose direction and amount coincide with the ones of the new sub-path,
          //then combine them together. Else create a new path to add
          let previousGroupedPath: Path = groupedPaths.length > 0 ? groupedPaths[groupedPaths.length - 1] : null;
          if (previousGroupedPath && previousGroupedPath.PositionB[axis] === pointStep[axis]
            && previousGroupedPath.Amount === newPathAmount
            && (
              (!previousGroupedPath.OneWay && !oneWay)
              ||
              pathPositive === previousGroupedPath.PositionA[axis] < previousGroupedPath.PositionB[axis]
            )
          ) {
            previousGroupedPath.PositionB = { ...nextPointStep };
          }
          else {
            let newPath: Path = {
              //if it is a one-way path with a reverse direction, reverse stepping points order
              PositionA: !oneWay || pathPositive ? { ...pointStep } : { ...nextPointStep },
              PositionB: !oneWay || pathPositive ? { ...nextPointStep } : { ...pointStep },
              Slope: slope !== 'inf' ? +slope : null,
              OneWay: oneWay,
              Amount: newPathAmount,
              //get importance of the most recent path
              Importance: Math.max(...involvedPathsIndexes.map(index => paths[index].Importance)),
              Type: type
            };
            groupedPaths.push(newPath);
          }
        }
      });

      newPaths.push(...groupedPaths);
    });

    return newPaths;
  }

  private calculateStops(transitions: Transition[], stopsAmount: number, areaMax: Point, areaMin: Point): TransitStop[] {
    let stops: TransitStop[] = [];

    transitions.forEach((transition: Transition) => {
      let prevPosition: TimedPosition = transition.Positions[transition.Positions.length - 1];
      let prevPoint: Point = this.translatePositionToRangedPoint(prevPosition, stopsAmount, areaMax, areaMin, false);
      let tempMs: number = 0;
      transition.Positions.slice(0, -1).reverse().forEach((position: TimedPosition) => {
        let pointA: Point = prevPoint;
        let pointB: Point = this.translatePositionToRangedPoint(position, stopsAmount, areaMax, areaMin, false);

        if(this.arePointsEqual(pointA, pointB)) {
          tempMs += position.Created - prevPosition.Created;
        }
        else {
          if(tempMs !== 0) {
            let stop: TransitStop = {
              PercentagePoint: pointA,
              Period: this.fromMillisecondsToTimeSpan(tempMs),
              TrackerId: transition.TrackerId,
              Type: transition.Type
            };
            stops.push(stop);
            tempMs = 0;
          }

          prevPoint = pointB;
        }

        prevPosition = position;
      });
    })

    return stops;
  }

  private groupStops(stops: TransitStop[]): BubbleChartDataList {
    let groupedStops: BubbleChartDataList = {};

    stops.forEach((stop: TransitStop) => {
      let point: Point = stop.PercentagePoint;
      if(groupedStops[stop.Type] === undefined) {
        groupedStops[stop.Type] = {};
      }
      if(groupedStops[stop.Type][point.X] === undefined) {
        groupedStops[stop.Type][point.X] = {};
      }
      if(groupedStops[stop.Type][point.X][point.Y] === undefined) {
        groupedStops[stop.Type][point.X][point.Y] = {
          Value: stop.Period.TotalMilliseconds,
          Description: ''
        };
      }
      else {
        groupedStops[stop.Type][point.X][point.Y].Value += stop.Period.TotalMilliseconds;
      }
    });

    for (let type in groupedStops) {
      for (let x in groupedStops[type]) {
        for (let y in groupedStops[type][x]) {
          groupedStops[type][x][y].Description = this.fromMillisecondsToTimePeriodString(groupedStops[type][x][y].Value);
        }
      }
    }

    return groupedStops;
  }

  private fromTimeSpanToString(period: TimeSpan): string {
    let periodString: string = period.Hours < 10 ? '0' : '';
    periodString += period.Hours + ':';

    periodString += period.Minutes < 10 ? '0' : '';
    periodString += period.Minutes + ':';

    periodString += period.Seconds < 10 ? '0' : '';
    periodString += period.Seconds;

    if(periodString === '00:00:00') {
      periodString += '.'+period.Milliseconds;
    }

    return periodString;
  }

  private fromMillisecondsToTimeSpan(milliseconds: number): TimeSpan {
    let timePeriod: TimeSpan;

    const msIn1Hour: number = 1000 * 60 * 60, msIn1Minute = 1000 * 60, msIn1Second = 1000;
    let hours: number = Math.floor(milliseconds / msIn1Hour);
    let remainingMilliseconds: number = milliseconds - (hours * msIn1Hour);
    let minutes: number = Math.floor(remainingMilliseconds / msIn1Minute);
    remainingMilliseconds -= (minutes * msIn1Minute);
    let seconds: number = Math.floor((remainingMilliseconds) / msIn1Second);
    remainingMilliseconds -= (seconds * msIn1Second);

    timePeriod = {
      Hours: hours,
      Minutes: minutes,
      Seconds: seconds,
      Milliseconds: remainingMilliseconds,
      TotalMilliseconds: milliseconds
    };

    return timePeriod;
  }

  private fromMillisecondsToTimePeriodString(milliseconds: number): string {
    let periodString: string;

    const msIn1Hour: number = 1000 * 60 * 60, msIn1Minute = 1000 * 60, msIn1Second = 1000;
    let hours: number = Math.floor(milliseconds / msIn1Hour);
    let remainingMilliseconds: number = milliseconds - (hours * msIn1Hour);
    let minutes: number = Math.floor(remainingMilliseconds / msIn1Minute);
    remainingMilliseconds -= (minutes * msIn1Minute);
    let seconds: number = Math.floor((remainingMilliseconds) / msIn1Second);
    remainingMilliseconds -= (seconds * msIn1Second);

    periodString = hours < 10 ? '0' : '';
    periodString += hours + ':';

    periodString += minutes < 10 ? '0' : '';
    periodString += minutes + ':';

    periodString += seconds < 10 ? '0' : '';
    periodString += seconds;

    if(periodString === '00:00:00') {
      periodString += '.' + remainingMilliseconds;
    }

    return periodString;
  }

  private getMostSensibleTimeTicks(timePeriod: TimeSpan): DescribedValue[] {
    let ticks: DescribedValue[] = [];
    let step: number, maxCounter: number, maxTick: number, msMultiplier: number, unit: string;
    const msIn1Hour: number = 1000 * 60 * 60, msIn1Minute = 1000 * 60, msIn1Second = 1000;

    if(timePeriod.Hours > 50) {
      msMultiplier = msIn1Hour;
      let roundedHours: number = Math.ceil(timePeriod.Hours / 10) * 10;
      step = roundedHours / 10;
      maxCounter = roundedHours + step;
      unit = 'h';
    }
    else if(timePeriod.Hours > 10) {
      msMultiplier = msIn1Hour;
      step = 5;
      maxCounter = timePeriod.Hours + step;
      unit = 'h';
    }
    else if(timePeriod.Hours > 0) {
      msMultiplier = msIn1Hour;
      step = 1;
      maxCounter = timePeriod.Hours + step;
      unit = 'h';
    }
    else if(timePeriod.Minutes > 30) {
      msMultiplier = msIn1Minute;
      step = 10;
      maxCounter = 50;
      unit = 'm';
      ticks.push({ Value: 1000, Description: '1h' });
    }
    else if(timePeriod.Minutes > 10) {
      msMultiplier = msIn1Minute;
      step = 5;
      maxCounter = timePeriod.Minutes + step
      unit = 'm';
    }
    else if(timePeriod.Minutes > 0) {
      msMultiplier = msIn1Minute;
      step = 1
      maxCounter = timePeriod.Minutes + step;
      unit = 'm';
    }
    else if(timePeriod.Seconds > 30) {
      msMultiplier = msIn1Second;
      step = 10;
      maxCounter = 50;
      unit = 's';
      ticks.push({ Value: 1000, Description: '1m' });
    }
    else if(timePeriod.Seconds > 10) {  //qui per i secondi
      msMultiplier = msIn1Second;
      step = 5;
      maxCounter = timePeriod.Seconds + step
      unit = 's';
    }
    else if(timePeriod.Seconds > 0) {
      msMultiplier = msIn1Second;
      step = 1
      maxCounter = timePeriod.Seconds + step;
      unit = 's';
    }
    else {
      msMultiplier = 1;
      step = 100;
      maxCounter = 900;
      unit = 'ms';
      ticks.push({ Value: 1000, Description: '1s' });
    }

    let time: number;
    for(time = step; time <= maxCounter; time += step) {
      ticks.push({ Value: time * msMultiplier, Description: time+unit });
    }
    maxTick = ticks[ticks.length - 1].Value;

    return ticks;
  }

  private getSlope(path: Path, decimalPrecision: number = 2): number {
    let slope: number | null;

    if(path.PositionB.X - path.PositionA.X !== 0) {
      slope = (path.PositionB.Y - path.PositionA.Y) / (path.PositionB.X - path.PositionA.X);
      let precision: number = Math.pow(10, decimalPrecision);
      slope = Math.round(slope * precision) / precision;
    }
    else {
      slope = null; //infinite slope
    }

    return slope;
  }

  private getIntercept(path: Path, decimalPrecision: number = 2): number {
    let intercept: number;

    if(path.Slope !== undefined && path.Slope !== null) {
      //the path is not vertical
      intercept = path.PositionA.Y - (path.Slope * path.PositionA.X);
      let precision: number = Math.pow(10, decimalPrecision);
      intercept = Math.round(intercept * precision) / precision;
    }
    else {
      //the path is vertical (intercept gets x-axis interception)
      intercept = path.PositionA.X;
    }

    return intercept;
  }

  private getImportance(position: TimedPosition, startSeconds: number, endSeconds: number): number {
    let importance: number;

    let totalMilliseconds: number, offsetMilliseconds: number;
    if (startSeconds && endSeconds) {
      totalMilliseconds = (endSeconds - startSeconds) * 1000;
      offsetMilliseconds = startSeconds * 1000;
    }
    else {
      totalMilliseconds = 1000 * 60 * 60 * 24; //milliseconds in 24h
      offsetMilliseconds = Math.round((new Date()).getTime() - totalMilliseconds);
    }

    if (position.Created <= offsetMilliseconds) {
      importance = 0;
    }
    else {
      importance = (position.Created - offsetMilliseconds) / totalMilliseconds;
      importance = Math.round(importance * 100) / 100;
    }

    return importance;
  }

  private getSlopeString(slope: number): string {
    let slopeString: string;

    if(slope !== null) {
      slopeString = slope.toString();
    }
    else {
      slopeString = 'inf'; //infinite = vertical path
    }

    return slopeString;
  }

  private getSortedPointsSteps(paths: Path[], axis: string): Point[] {
    let points: Point[] = [];

    paths.forEach((path: Path) => {
      if (points.find(step => step[axis] === path.PositionA[axis]) === undefined) {
        points.push({ ...path.PositionA });
      }
      if (points.find(step => step[axis] === path.PositionB[axis]) === undefined) {
        points.push({ ...path.PositionB });
      }
    });
    points.sort((a, b) => a[axis] - b[axis]);

    return points;
  }

  filterTransitions(type: string): void {
    if(type !== undefined && this.transitTypes.includes(type)) {
      if(this.transitions?.length > 0) {
        if(type === 'all') {
          this.filteredTransitions = this.transitions;
        }
        else {
          this.filteredTransitions = this.transitions.filter((transition: Transition) => transition.Type === type);
        }
        this.setChartsData();
      }
      this.sankeySubtitle = 'TRANSIT_DEVICE.TYPE_'+type.toUpperCase();
      this.columnChartSubtitle = 'TRANSIT_DEVICE.TYPE_'+type.toUpperCase();
    }
  }

  setPathsChartType(event: string) {
    if (event !== undefined) {
      if (this.firstCallPathsChart === undefined || this.firstCallPathsChart === null ) {
        this.firstCallPathsChart = true;
      } else {
        this.pathsChartTypeSelected = event;
      }
    }
  }

  public changeHistoryPeriod(): void {
    this.setHistoryChartData();
  }

  public changeSpatialPrecision(): void {
    this.setPathsChartData();
    this.setStopsChartData();
  }

  private roundTimeByHistoryPeriodAndGetSeconds(milliseconds: number, period: number): number {
    milliseconds -= (milliseconds % (period * 60 * 1000));
    return (milliseconds / 1000);
  }

  private translatePositionToRangedPoint(position: TimedPosition, totalPoints: number, maxLimit: Point, minLimit: Point, percent: boolean): Point {
    let x: number = position.X;
    let y: number = position.Y;
    let rangeX: number = maxLimit.X - minLimit.X;
    let rangeY: number = maxLimit.Y - minLimit.Y;
    //minLimit is an offset, so that the new minimum value is 0
    x -= minLimit.X;
    y -= minLimit.Y;
    //scaled point position to give it a sort of a range
    x = Math.floor(x * totalPoints / rangeX);
    y = Math.floor(y * totalPoints / rangeY);
    //translated point to start of the centering process:
    //gave it a distance (half range unit) from top/left border of the range
    x += 0.5;
    y += 0.5;

    if (percent) {
      //range for percentage
      rangeX = 100;
      rangeY = 100;
    }

    //made range wider to complete the centering process
    //(and optionally converted to percentage range)
    let point: Point = {
      X: x * rangeX / (totalPoints + 1),
      Y: y * rangeY / (totalPoints + 1)
    };

    return point;
  }

  private arePointsEqual(pointA: Point, pointB: Point): boolean {
    return (pointA.X === pointB.X && pointA.Y === pointB.Y);
  }

  //similar to "forEach" but for "PathsIndexesBySlopeInterceptType" struct
  private accessToSinglePathIndexes(pathsLines: PathsIndexesBySlopeInterceptType, callback: (indexes: number[], slope: string, intercept: number, type: string) => void): void {
    Object.keys(pathsLines).forEach((type: string) => {
      Object.keys(pathsLines[type]).forEach((slope: string) => {
        Object.keys(pathsLines[type][slope]).forEach((intercept: string) => {
          callback(pathsLines[type][slope][intercept], slope, +intercept, type);
        });
      });
    });
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceEvents(): void {
    if (this.transitDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.transitDevice.Id, BackRoute: 'transit-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
