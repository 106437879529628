import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {UserRoles} from "../../../shared/models/userRoles";
import {ApiService} from "../../../shared/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {PassDataService} from "../../../shared/services/pass-data/pass-data.service";
import {first, takeUntil} from "rxjs/operators";
import {ConfirmationDialogComponent} from "../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {LocationEvent} from "../../../shared/models/locationEvent";
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {Router} from "@angular/router";
import {LocationEventDialogComponent} from "../../../shared/dialogs/location-event-dialog/location-event-dialog.component";
import {AdministratorApiService} from "../../../shared/services/administrator-api.service";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-location-events',
  templateUrl: './location-events.component.html',
  styleUrls: ['./location-events.component.scss']
})
export class LocationEventsComponent implements OnInit, OnDestroy {

  locationEventColumns = ['Name', 'Start', 'End', 'Detail'];
  public locationEvents: LocationEvent[] = [];
  public filteredData: LocationEvent[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public userRoles: UserRoles['Roles'];
  private locationId: string;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }


  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private administrationApiService: AdministratorApiService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        this.locationId = navInfo.Id;
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this._initialize();
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
    });
  }

  private _initialize(): void {
    this.apiService.locationEvents(this.locationId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(locationEvents => {
      if (locationEvents) {
        this.locationEvents = locationEvents;
        this.filteredData = this.locationEvents;
      }
    })
  }

  public add(): void {
    const addDialogRef = this.dialog.open(LocationEventDialogComponent, {
      width: '50%',
      maxWidth: 500,
      data: {
        locationId: this.locationId
      }
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administrationApiService.locationEventAdd(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public detail(locationEvent: LocationEvent): void {
    if (!locationEvent) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: locationEvent.Id, BackRoute: 'location-events'});
    this.router.navigate(['main/location-event']);
  }

  public delete(locationEvent: LocationEvent): void {
    if (!locationEvent) {
      return;
    }
    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.administrationApiService.locationEventDelete(locationEvent.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.locationEvents?.filter((event: LocationEvent) =>
      [
        event.Name?.toLowerCase(), 
        event.Start ? new Date(event.Start * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): '',
        event.End ? new Date(event.End * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): ''
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
