import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { LatestVideosRequest } from './../../../shared/models/latestVideosRequest';
import { Device } from '../../../shared/models/device';
import { UntypedFormControl } from '@angular/forms';
import { VideoListModel } from '../../../shared/models/videoListModel';
import { ApiService } from '../../../shared/services/api.service';
import { SearchVideoRequest } from '../../../shared/models/searchVideoRequest';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit, OnDestroy {

  public currentDevice: Device;
  public date: UntypedFormControl;
  public videos: VideoListModel[] = [];
  public displayedVideosHistoryColumns: string[] = ['Name', 'Date', 'Download'];
  public filteredData: VideoListModel[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public maxDate: Date;
  public minDate: Date;
  public isDarkModeActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private router: Router,
    private passDataService: PassDataService
  ) { }

  ngOnInit(): void {
    let currentDate = new Date();
    currentDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()));
    this.date = new UntypedFormControl(new Date(currentDate));
    this.minDate = new Date(currentDate.getFullYear() - 1, 0, 1);
    this.maxDate = currentDate;

    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadVideos(deviceId);
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkModeActive = res === true;
    });
  }

  private loadVideos(deviceId : string): void {
    let latestVideosFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if(checkValues.every(value => value)) {
        this.videos = data[latestVideosFeature].map((oneVideo) => {
          let video: VideoListModel = {
            VideoDate: this.getReadableDate(oneVideo.Name),
            Name: oneVideo.Name,
            DownloadUrl: `${environment.apiUrl}/download/video?device=${this.currentDevice.Id}&name=${oneVideo.Name}`
          };
          return video;
        });

        this.filteredData = this.videos;
      }
    }, context);

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;

        this.apiSync.loadedFeature(context);
      }
      else {
        this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    let latestVideosRequest: LatestVideosRequest = { Device: deviceId }

    this.apiService.latestVideos(latestVideosRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res !== undefined && res.length > 0) {
        latestVideosFeature = this.apiSync.loadedFeatureWithData(res, context);
      }
      else {
        latestVideosFeature = this.apiSync.failedFeature(context);
      }
    });
  }

  public changeDate(): void {
    if (this.date.value !== undefined) {
      let currentDate = new Date(this.date.value.toISOString());

      let day = currentDate.getUTCDate();
      let month = currentDate.getUTCMonth();
      let year = currentDate.getUTCFullYear();

      let searchRequest: SearchVideoRequest = {
        Device: this.currentDevice.Id,
        Start: Math.round(new Date(year, month, day, 24, 50, 0).getTime() / 1000),
        End: Math.round(new Date(year, month, (day + 1), 1, 10, 0).getTime() / 1000)
      };

      this.apiService.searchVideos(searchRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res !== undefined && res.length > 0) {
          this.videos = res.map((oneVideo) => {
            let video: VideoListModel = {
              VideoDate: this.getReadableDate(oneVideo.Name),
              Name: oneVideo.Name,
              DownloadUrl: `${environment.apiUrl}/download/video?device=${this.currentDevice.Id}&name=${oneVideo.Name}`
            };
            return video;
          });

          this.filteredData = this.videos;
        }
      });
    }
  }

  private getReadableDate(name: string): string {
    if (name !== '' && name !== undefined) {
      let year = name.substr(0, 4);
      let month = name.substr(4, 2);
      let day = name.substr(6, 2);
      let hour = name.substr(8, 2);
      let minutes = name.substr(10, 2);
      let seconds = name.substr(12, 2);

      return (year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds);
    }
  }

  public returnBack(): void {
    this.mainService.setNavigationInfoComand({ Back: true});
    this.router.navigate(['main/device']);
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.videos?.filter((video: VideoListModel) =>
      [video.Name?.toLowerCase(), video.VideoDate?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
