import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Device } from '../../models/device';
import { DeviceBrand } from '../../models/deviceBrand';
import { DeviceModel } from '../../models/deviceModel';
import { DeviceCreateRequest } from '../../models/deviceCreateRequest';

@Component({
  selector: 'urban-add-device-dialog',
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss']
})
export class AddDeviceDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    brandSelected: [{ value: '', disabled: false }, Validators.required],
    modelSelected: ['', Validators.required],
    parentSelected: [''],
    latitude: ['', Validators.required],
    longitude: ['', Validators.required]
  });
  public listBrands: DeviceBrand[];
  private listModels: DeviceModel[];
  public modelsSelected: DeviceModel[];
  public parents: Device[];
  public lat: number;
  public lng: number;
  public properties: Map<string, string>;
  private createModel: DeviceCreateRequest;

  constructor(
    public dialogRef: MatDialogRef<AddDeviceDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        latitude: number,
        longitude: number,
        parents: Device[],
        brands: DeviceBrand[],
        models: DeviceModel[],
        parent: Device
      }
  ) {
    this.lat = data.latitude;
    this.lng = data.longitude;

    this.parents = [];

    if (data.parents && data.parents.length > 0) {
      for (let device of data.parents) {
        if (device.Childs?.length > 0) {
          this.parents.push(device);
          for (let child of device.Childs) {
            this.parents.push(child);
          }
        }
        else {
          this.parents.push(device);
        }
      }
    }

    this.listBrands = data.brands;
    this.listModels = data.models;

    let selected: string = data.parent != null && data.parent !== undefined && data.parent.Id != '00000000-0000-0000-0000-000000000000' && data.parent.Id != null ? data.parent.Id : ''

    this.form.setValue({
      name: '',
      brandSelected: '',
      modelSelected: '',
      parentSelected: selected,
      latitude: data.latitude,
      longitude: data.longitude
    })

    if (selected !== '') {
      this.form.controls.parentSelected.disable();
      this.form.controls.latitude.disable();
      this.form.controls.longitude.disable();

      if (this.parents.find(x => x.Id === selected) === null || this.parents.find(x => x.Id === selected) === undefined) {
        this.parents.push(data.parent);
      }
    }
  }

  ngOnInit(): void {
  }

  updateModels() {
    if (this.form.controls.brandSelected.value != '')
      this.modelsSelected = this.listModels.filter(x => x.Brand.Id == this.form.controls.brandSelected.value);
  }

  updateParent() {
    if (this.form.controls.parentSelected.value != '') {
      let parentDevice = this.parents.find(x => x.Id == this.form.controls.parentSelected.value);

      if (parentDevice != null && parentDevice !== undefined) {
        let latitude = parentDevice.Latitude;
        let longitude = parentDevice.Longitude;

        this.form.controls.latitude.setValue(latitude);
        this.form.controls.longitude.setValue(longitude);
      }
    }
    else {
      this.form.controls.latitude.setValue(this.lat);
      this.form.controls.longitude.setValue(this.lng);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  addition() {
    if (this.form.valid) {
      this.createModel = {
        Name: this.form.controls.name.value,
        ModelId: this.form.controls.modelSelected.value,
        ParentId: this.form.controls.parentSelected.value,
        Latitude: this.form.controls.latitude.value.toString(),
        Longitude: this.form.controls.longitude.value.toString()
      }
      this.dialogRef.close(this.createModel)
    }
  }
}
