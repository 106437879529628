import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientSettingDialogComponent } from '../../../../shared/dialogs/client-setting-dialog/client-setting-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ClientSettingRequest } from '../../../../shared/models/administrator/clientSettingRequest';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { PaginationInstance } from 'ngx-pagination';
@Component({
  selector: 'urban-client-setting-management',
  templateUrl: './client-setting-management.component.html',
  styleUrls: ['./client-setting-management.component.scss']
})
export class ClientSettingManagementComponent implements OnInit, OnDestroy {

  settingsColumns = ['Key', 'Value', 'Detail'];
  public settings: ClientSettingRequest[] = [];
  public filteredData: ClientSettingRequest[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private administratorApiService: AdministratorApiService,
    public dialog: MatDialog)
    { }

  ngOnInit(): void {
    this._initClientSettings();
  }

  private _initClientSettings(): void {
    this.administratorApiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      let keys = Object.keys(result);

      this.settings = [];

      for (let i = 0; i < keys.length; i++) {
        this.settings.push({ Key: keys[i], Value: result[keys[i]] });
      }

      this.filteredData = this.settings;
    })
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.settings?.filter((setting: ClientSettingRequest) =>
      [setting.Key?.toLowerCase(), setting.Value?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public addSetting(): void {
    const addSettingDialogRef = this.dialog.open(ClientSettingDialogComponent, {
      width: '50%'
    });

    addSettingDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newSetting => {
      if (newSetting) {
        const addSettingConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addSettingConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.setClientSetting(newSetting).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initClientSettings();
              }
            });
          }
        });
      }
    });
  }

  public settingDetail(setting: ClientSettingRequest): void {
    const updateSettingDialogRef = this.dialog.open(ClientSettingDialogComponent, {
      data: { setting: setting },
      width: '50%'
    });

    updateSettingDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedSetting => {
      if (updatedSetting) {
        const updateSettingConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        updateSettingConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.setClientSetting(updatedSetting).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initClientSettings();
              }
            });
          }
        });
      }
    });
  }

  public settingDelete(setting: ClientSettingRequest): void {
    if (setting) {
      const deleteSettingConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteSettingConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          setting.Value = '';

          this.administratorApiService.setClientSetting(setting).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initClientSettings();
            }
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
