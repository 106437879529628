import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ResourceDialogComponent } from '../../../../shared/dialogs/resource-dialog/resource-dialog.component';
import { ResourceRequest } from '../../../../shared/models/administrator/resourceRequest';
import { Resource } from '../../../../shared/models/resource';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-resource-management',
  templateUrl: './resource-management.component.html',
  styleUrls: ['./resource-management.component.scss']
})
export class ResourceManagementComponent implements OnInit, OnDestroy {

  resourcesColumns = ['Url', 'Title', 'Label', 'Detail'];
  public resources: Resource[] = [];
  public filteredData: Resource[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private administratorApiService: AdministratorApiService,
    public dialog: MatDialog
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  ngOnInit(): void {
    this._initResources();
  }

  private _initResources(): void {
    this.administratorApiService.getResources().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      result => {
        if (result) {
          this.resources = result;
          this.filteredData = this.resources;
        }
      }
    )
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.resources?.filter((resource: Resource) =>
      [
        resource.Url?.toLowerCase(),
        resource.Title?.toLowerCase(),
        resource.Label?.toLowerCase(),
      ].some((field: string) => field?.includes(filterValue.toLowerCase()))
    );

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public addResource(): void {
    const addResourceDialogRef = this.dialog.open(ResourceDialogComponent, {
      data: { resources: this.resources },
      width: '60%'
    });

    addResourceDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newResource => {
      if (newResource) {
        const addResourceConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addResourceConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.addResource(newResource).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initResources();
              }
            });
          }
        });
      }
    });
  }

  public resourceDetail(resource: ResourceRequest): void {
    const updateResourceDialogRef = this.dialog.open(ResourceDialogComponent, {
      data: { resource: resource, resources: this.resources },
      width: '60%'
    });

    updateResourceDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedResource => {
      if (updatedResource) {
        const updateResourceConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        updateResourceConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.updateResource(updatedResource).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initResources();
              }
            });
          }
        });
      }
    });
  }

  public resourceDelete(resource: ResourceRequest): void {
    if (resource) {
      const deleteResourceConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteResourceConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorApiService.deleteResource(resource.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initResources();
            }
          });
        }
      });
    }
  }
}
