<h1 mat-dialog-title>{{ 'STREAMING_DIALOG.TITLE' | translate }}</h1>
<mat-dialog-content>
    <p>
        {{ 'STREAMING_DIALOG.SUBTITLE' | translate }}
    </p>
    <form [formGroup]="form">
        <mat-form-field>
            <div class="d-flex justify-content-between flex-wrap">
                <div class="w-50 streamingLink">
                    <mat-label>{{ 'STREAMING_DIALOG.LINK' | translate }}</mat-label>
                    <input matInput formControlName="link" autocomplete="off" class="pt-2">
                </div>
                <button mat-button type="submit" class="btn outlineActionButton" (click)="copyToClipboard()">{{ 'GENERAL.COPY_TO_CLIPBOARD'| translate }}</button>
            </div>
        </mat-form-field>

        <mat-label *ngIf="copied" class="captionText">
            {{ 'GENERAL.COPIED_TO_CLIPBOARD' | translate }}
        </mat-label>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button class="btn outlineActionButton" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>

