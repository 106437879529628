import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomainVisibility} from "../../models/domainVisibility";

@Component({
  selector: 'urban-domain-visibility-dialog',
  templateUrl: './domain-visibility-dialog.component.html',
  styleUrls: ['./domain-visibility-dialog.component.scss']
})
export class DomainVisibilityDialogComponent implements OnInit {

  private domainVisibility: DomainVisibility;

  public isAdd : boolean = true;

  public form: UntypedFormGroup = this.formBuilder.group({
    description: [{value: '', disabled: false}, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<DomainVisibilityDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        domainVisibility: DomainVisibility
      }
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.domainVisibility) {
      this.domainVisibility = this.data.domainVisibility;
      this.isAdd = false;
      this.form.controls.description.setValue(this.domainVisibility.Description);
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.domainVisibility = {
        Id: this.domainVisibility?.Id || null,
        Description: this.form.controls.description.value
      };

      this.dialogRef.close(this.domainVisibility);
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
