<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'CALENDARS.SINGLE_TITLE' | translate }}</mat-label>
            <input matInput formControlName="title" autocomplete="off"
                required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'CALENDARS.LOCATION' | translate }}</mat-label>
            <mat-select formControlName="location">
                <mat-option *ngFor="let location of locations" value={{location.Id}}>
                    {{location.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="sdt" [min]="this.minDate" [max]="this.maxDate"
                formControlName="start" autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="sdt"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #sdt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="edt" [min]="this.minDate" [max]="this.maxDate"
                formControlName="end" autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="edt"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #edt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'CALENDARS.PRIORITY' | translate }}</mat-label>
            <mat-select formControlName="priority" [(value)]="currentColor">
                <mat-option *ngFor="let priority of priorities" value={{priority.Value}}>
                    {{ 'CALENDARS.PRIORITY_' + priority.Key | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="cerchio mr-2" [style.background]="'#' + currentColor">
            <input #colorPicker type="color" [value]="'#' + currentColor"
                (change)='updateColor(colorPicker.value)'>
        </div>
        <mat-form-field>
            <mat-label>{{ 'CALENDARS.TEXT' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput formControlName="text"
                matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=15
                autocomplete="off"></textarea>
        </mat-form-field>

        <mat-error class="pb-2" *ngIf="error">
            {{ error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="addition()">
        {{ isAdd ? ('GENERAL.ADD' | translate) : ('GENERAL.UPDATE' | translate) }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
</div>
