import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
  public isLoading: Subject<boolean> = new Subject<boolean>();
  public RCisLoading: Subject<boolean> = new Subject<boolean>();
  private requestsCount: number = 0;
  private enabled: boolean = true;

  public show(): void {
    if(this.enabled) {
      this.requestsCount++;
      this.isLoading.next(true);
    }
  }

  public hide(): void {
    if(this.requestsCount > 1) {
      this.requestsCount--;
    }
    else if(this.requestsCount === 1) {
      this.requestsCount = 0;
      this.isLoading.next(false);
    }
  }

  public enable(): void {
    this.enabled = true;
  }

  public disable(): void {
    this.enabled = false;
  }

  public forceHiding(): void {
    this.requestsCount = 0;
    this.isLoading.next(false);
  }

  public RCLoaderShow(): void {
    this.RCisLoading.next(true);
  }

  public RCLoaderHide(): void {
    this.RCisLoading.next(false);
  }
}
