<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'SMSS.ADVERTISERS.TITLE' | translate }}
        </span>
    </div>

    <div class="types-table mat-elevation-z4">
        <table class="w-100 tableSimpleHover" mat-table [dataSource]="advertisersDataSource">

            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ID' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.id }}
                </td>
            </ng-container>

            <ng-container matColumnDef="CompanyName">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.COMPANY_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.CompanyName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.EMAIL' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.EmailAddress }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Firstname">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.FIRSTNAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.FirstName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Lastname">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.LASTNAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.LastName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Detail">
                <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 10%"></th>
                <td mat-cell *matCellDef="let element" class="text-center pr-1">
                    <div class="pr-2">
                        <i class="urbanicon-info-outline" matTooltip="{{'GENERAL.DETAIL' | translate }}" (click)="detail(element)"></i>
                        <i class="urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="delete(element)"></i>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="advertiserColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: advertiserColumns;"></tr>
        </table>
    </div>
    <br>
    <button mat-button class="btn actionButton ml-2" (click)="add()">
        {{ 'GENERAL.ADD' | translate }}
    </button>
</div>
