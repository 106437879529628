<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'NOTIFICATION_ALL.TITLE' | translate }}
        </span>

        <button type="button" class="btn actionButton" (click)="openAddNotificationDialog()">
            {{ 'NOTIFICATION_ALL.ADD' | translate }}
        </button>
    </div>

    <div class="customTable">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'NOTIFICATION_ALL.NOTIFICATION' | translate }}</span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 h-100" mat-table [dataSource]="filteredData | paginate: config">
                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'NOTIFICATION_ALL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                        <span class="paragraphText">{{ item.Created * 1000 | date : 'short' }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="DestinationType">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'NOTIFICATION_ALL.DESTINATION_TYPE' | translate }} </th>

                    <td mat-cell *matCellDef="let item">
                        <div class="paragraphText">
                            {{ 'GENERAL.' + item.DestinationType.toUpperCase() | translate }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Kind">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'NOTIFICATION_ALL.KIND' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <div class="paragraphText" *ngIf="item.Kind === 'u'">
                            {{'GENERAL.USER_INTERFACE' | translate }}
                        </div>
                        <div class="paragraphText" *ngIf="item.DestinationType === 'i'">
                            {{'GENERAL.INTERNAL' | translate }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Expiration">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'NOTIFICATION_ALL.EXPIRATION' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <div *ngIf="item.Expiration > 0" class="d-flex">
                            <div class="pr-3">
                                <span [ngClass]="{ 'notificationExpired' : (item.Expiration * 1000) < todayTimestamp }" class="paragraphText text-break pr-3">
                                    {{ item.Expiration * 1000 | date : "dd-MM-yyyy" }}
                                </span>

                                <span *ngIf="(item.Expiration * 1000) < todayTimestamp" class="notificationExpired pr-3 text-break">{{ 'NOTIFICATION_LIST.EXPIRED' | translate }}</span>

                                <i *ngIf="(item.Expiration * 1000) < todayTimestamp" class="urbanicon-done expiredCheck"></i>
                            </div>

                            <span *ngIf="(item.Expiration * 1000) > todayTimestamp" class="link linkText" (click)="setToExpired(item)">
                                {{'NOTIFICATION_LIST.EXPIRE' | translate }}
                            </span>
                        </div>

                        <div *ngIf="item.Expiration <= 0">
                            <span class="paragraphText notificationExpired pr-3">
                                {{ 'NOTIFICATION_LIST.FORCED_EXPIRATION' | translate }}
                            </span>

                            <i class="urbanicon-done expiredCheck"></i>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef style="width: 8%"></th>
                    <td mat-cell *matCellDef="let item">
                        <div class="text-end p-0">
                            <i class="iconInfoButton urbanicon-info"
                                matTooltip="{{'NOTIFICATION_ALL.DETAIL' | translate }}"
                                (click)="goToNotificationDetail(item)">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
