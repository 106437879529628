import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserRoles} from "../../../../shared/models/userRoles";
import {Subject} from "rxjs";
import {PaginationInstance} from "ngx-pagination";
import {Router} from "@angular/router";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {ChannelResponse} from "../../../../shared/models/content-management/channelResponse";
import {Language} from "../../../../shared/models/language";
import {ChannelDialogComponent} from "../../../../shared/dialogs/channel-dialog/channel-dialog.component";
import {ApiService} from "../../../../shared/services/api.service";
import {BroadcastClientResponse} from "../../../../shared/models/content-management/broadcastClientResponse";

@Component({
  selector: 'urban-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit, OnDestroy {

  typesColumns = ['Name', 'Language', 'Detail'];
  public channels: ChannelResponse[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: ChannelResponse[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  private languages: Language[] = [];
  private broadcastClients: BroadcastClientResponse[] = [];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private contentManagementService: ContentManagementService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this._initList();
    });
  }

  public add(): void {
    if (this.userRoles.some(x => x.Name === 'Administrators')) {

      const addDialogRef = this.dialog.open(ChannelDialogComponent, {
        data: {
          broadcastClients: this.broadcastClients,
          languages: this.languages
        }
      });

      addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newChannel => {
        if (newChannel) {
          const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false,
            width: '50%',
            maxWidth: 500,
          });

          addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.contentManagementService.addChannel(newChannel).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                if (x) {
                  this._initList();
                }
              });
            }
          });
        }
      });
    }
  }

  private _initList(): void {
    this.contentManagementService.listChannel().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.channels = x;
        this.filteredData = this.channels;
      }
    });

    this._initLanguages();

    this._initBroadcastClients();
  }

  private _initLanguages(): void {
    this.apiService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x && x.List) {
        this.languages = x.List;
      }
    });
  }

  private _initBroadcastClients(): void {
    this.contentManagementService.listBroadcastClient().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.broadcastClients = x;
      }
    });
  }

  public detail(channel: ChannelResponse): void {
    if (!channel) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: channel.Id, BackRoute: 'content-management/channels'});
    this.router.navigate(['main/content-management/channel']);
  }

  public delete(channel: ChannelResponse): void {
    if (!channel) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {
      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.contentManagementService.deleteChannel(channel.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.channels?.filter((key: ChannelResponse) =>
    [key.Name?.toLowerCase()]
        .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
