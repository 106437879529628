import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceTypeDialogComponent } from '../../../../shared/dialogs/device-type-dialog/device-type-dialog.component';
import { DeviceType } from '../../../../shared/models/deviceType';
import { UserRoles } from '../../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { ApiService } from '../../../../shared/services/api.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-device-types',
  templateUrl: './device-types.component.html',
  styleUrls: ['./device-types.component.scss']
})
export class DeviceTypesComponent implements OnInit,OnDestroy {

  typeColumns = ['Name', 'Icon', 'MapPin', 'Color', 'Detail'];
  public types: DeviceType[] = [];
  public filteredData: DeviceType[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public userRoles: UserRoles['Roles'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private administratorService: AdministratorApiService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
      this.apiService.getDeviceType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
        if (x) {
          this.types = x;
          this.filteredData = this.types;
        }
      });
    });
  }

  public addType(): void {

    const addTypeDialogRef = this.dialog.open(DeviceTypeDialogComponent);

    addTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newType => {
      if (newType) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorService.addDeviceType(newType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this.apiService.getDeviceType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
                  if (x) {
                    this.types = x;
                    this.filteredData = this.types;
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  public typeDetail(type: DeviceType): void {
    if (!type || type === undefined) {
      return;
    }

    this.mainService.setNavigationInfoComand({ Id: type.Id, BackRoute: 'device-types' });
    this.router.navigate(['main/device-type']);
  }

  public typeDelete(type: DeviceType): void {
    if (!type || type === undefined) {
      return;
    }

    const deleteTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.administratorService.deleteDeviceType(type.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this.apiService.getDeviceType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this.types = x;
                this.filteredData = this.types;
              }
            });
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.types?.filter((key: DeviceType) =>
      [
        key.Name?.toLowerCase(), 
        key.Icon?.toLowerCase(), 
        key.MapPin?.toLowerCase(), 
        key.Color?.toLowerCase()
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
