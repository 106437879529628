<div class="dashboardVideoContainer">
    <div class="d-flex justify-content-between mx-3 pageTop">
        <span class="title2">
            {{ 'SIDEBAR.VIDEOSURVEILLANCE$STREAMING' | translate }}
        </span>
        
        <button type="button" mat-button class="btn actionButton" (click)="addCamera()" [disabled]="addableCameras.length === 0">{{ 'CAMERA.ADD' | translate }}</button>
    </div>

    <div class="d-flex flex-row flex-wrap justify-content-start">
        <div class="customColumn panelBG" *ngFor="let camera of selectedCameras; let i = index">
            <div class="flex-column position-relative">
                <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                    <span class="title3">{{camera.Name}}</span>
    
                    <div class="d-flex">
                        <button type="button" mat-button class="outlineActionButton mr-2" (click)="loadStreaming(camera)">{{ 'DEVICE.LIVE_STREAMING' | translate }}</button>
                        <button type="button" mat-button class="outlineActionButton" (click)="openHistory(camera)">{{ 'DEVICE.HISTORY' | translate }}</button>
                    </div>
                </div>
    
                <div>
                    <img [src]="'./../../../../assets/img/streaming-default' + (i % 3) + '.png'" alt="Preview streaming camera" width="100%">
                    <button type="button" mat-button class="btn actionButton playStreamingBtn" disabled>
                        {{ 'CAMERA.PLAY' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>