import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Device } from 'src/app/shared/models/device';
import { AddStreamingCameraDialogComponent } from 'src/app/shared/dialogs/add-streaming-camera-dialog/add-streaming-camera-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StreamingLinkDialogComponent } from 'src/app/shared/dialogs/streaming-link-dialog/streaming-link-dialog.component';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'urban-dashboard-video',
  templateUrl: './dashboard-video.component.html',
  styleUrls: ['./dashboard-video.component.scss']
})
export class DashboardVideoComponent implements OnInit {

  public allCameras: Device[] = [];
  public selectedCameras: Device[] = [];
  public addableCameras: Device[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private mainService: MainSubscriptionsService,
    private router: Router,
    private apiService: ApiService,
    public dialog: MatDialog) 
  { }

  ngOnInit(): void {
    this.apiService.getDevicesByType('camera').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res?.length > 0) {
        this.allCameras = res;
        this.addableCameras = res;
      }
    });
  }

  public addCamera(): void {
    const addSettingDialogRef = this.dialog.open(AddStreamingCameraDialogComponent, {
      data: { addableCameras: this.addableCameras },
      width: '428px'
    });

    addSettingDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedCamera => {
      if (selectedCamera) {
        this.selectedCameras.push(selectedCamera);
        this.addableCameras = this.allCameras.filter(camera => this.selectedCameras.every(x => x.Id !== camera.Id));
      } 
    });
  }

  public loadStreaming(camera: Device): void {
    this.dialog.open(StreamingLinkDialogComponent, {
      width: '25%',
      data: {
        link: camera.Properties['Url'] ?? ''
      }
    });
  }

  public openHistory(camera: Device): void {
    this.mainService.setNavigationInfoComand({ Id: camera.Id, Type: camera.Model?.Type?.Name, BackRoute: 'dashboard-video' });
    this.router.navigate(['main/device-history']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
