<div class="goToPageBtn">
    <button mat-button class="btn outlineActionButton" (click)="goToWeatherStationDashboard()">
        {{ 'GENERAL.GO_TO_PAGE' | translate }}
    </button>
</div>

<div class="container-fluid position-relative">
    <div *ngIf="dataReady && limitsReady">
        <div class="d-flex text-center flex-column weatherTemp">
            <span class="subheadText">
                {{ 'GENERAL.LAST_24H' | translate }}
            </span>
    
            <h1 class="title1">{{ averageWeatherValues['Temperature'] }}°</h1>
        </div>
    
        <div class="d-flex weatherData">
            <div class="element">
                <i class="urbanicon-wind"></i>

                <div class="d-flex flex-wrap align-items-baseline">
                    <span class="subheadText mr-1">{{ averageWeatherValues.WindSpeed }}</span>
                    <span class="captionText">{{ findInfo("WindSpeed").Unit }}</span>
                    <div class="arrowContainer" [ngClass]="arrowDirection">
                        <i class="urbanicon-back"></i>
                    </div>
                </div>

                <p class="paragraptext secondaryTextColor">{{ 'WEATHER_WIDGET.WIND' | translate }}</p>
            </div>
    
            <div class="element">
                <i class="urbanicon-rainfall"></i>

                <div class="d-flex align-items-baseline">
                    <span class="subheadText mr-1">{{ averageWeatherValues.SolarRadiationIntensity }}</span>
                    <span class="captionText">{{ findInfo("SolarRadiationIntensity").Unit }}</span>
                </div>

                <p class="paragraptext secondaryTextColor">{{ 'WEATHER_WIDGET.UV_INDEX' | translate }}</p>
            </div>
    
            <div class="element">
                <i class="urbanicon-humidity"></i>

                <div class="d-flex align-items-baseline">
                    <span class="subheadText mr-1">{{ averageWeatherValues.Humidity }}</span>
                    <span class="captionText">{{ findInfo("Humidity").Unit }}</span>
                </div>

                <p class="paragraptext secondaryTextColor">{{ 'WEATHER_WIDGET.HUMIDITY' | translate }}</p>
            </div>
    
            <div class="element">
                <i class="urbanicon-pressure"></i>

                <div class="d-flex align-items-baseline">
                    <span class="subheadText mr-1">{{ averageWeatherValues.Pressure }}</span>
                    <span class="captionText">{{ findInfo("Pressure").Unit }}</span>
                </div>

                <p class="paragraptext secondaryTextColor">{{ 'WEATHER_WIDGET.PRESSURE' | translate }}</p>
            </div>
    
            <div class="element">
                <i class="urbanicon-uv"></i>

                <div class="d-flex align-items-baseline">
                    <span class="subheadText mr-1">{{ averageWeatherValues.RainfallMinute }}</span>
                    <span class="captionText">{{ findInfo("RainfallMinute").Unit }}</span>
                </div>

                <p class="paragraptext secondaryTextColor">{{ 'WEATHER_WIDGET.RAINFALL' | translate }}</p>
            </div>
        </div>

        <div class="airQuality d-flex w-100">
            <div class="d-flex align-items-center qualityInfo w-100">
                <i class="urbanicon-air-quality" [ngClass]="
                                airQuality === 'BAD' ? 'badAirQuality' :
                                airQuality === 'MEDIUM' ? 'mediumAirQuality' :
                                'goodAirQuality'">
                </i>

                <div>
                    <h3 class="title3 mb-0">{{ 'WEATHER_WIDGET.' + airQuality | translate }}</h3>
                    <span class="subheadText airQualityLabel">{{ 'WEATHER_WIDGET.AIR_QUALITY' | translate }}</span>
                </div>
            </div>

            <div class="d-flex flex-column w-100">
                <div class="d-flex">
                    <div class="mr-3">
                        <p class="paragraphText">TV0C:<span class="paragraphboldText pl-1">{{ averageWeatherValues.TVOC }}</span></p>
                    </div>
    
                    <div>
                        <p class="paragraphText">N02:<span class="paragraphboldText pl-1">{{ averageWeatherValues.NO2 }}</span></p>
                    </div>
                </div>

                <div class="d-flex flex-wrap">
                    <div class="mr-3">
                        <p class="paragraphText">PM10:<span class="paragraphboldText pl-1">{{ averageWeatherValues.PM10 }}</span></p>
                    </div>
    
                    <div class="mr-3">
                        <p class="paragraphText">CO2:<span class="paragraphboldText pl-1">{{ averageWeatherValues.CO2 }}</span></p>
                    </div>

                    <div>
                        <p class="paragraphText">PM25:<span class="paragraphboldText pl-1">{{ averageWeatherValues.PM25 }}</span></p>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!averageWeatherValues" class="d-flex justify-content-center align-items-center noDataHeight mt-2">
        <p class="paragraphText">{{ 'GENERAL.NO_DATA_IN_24H' | translate }}</p>
    </div>

    <urban-loader [localized]="true" [disappearCondition]="!dataLoading"></urban-loader>
</div>