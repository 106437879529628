import { takeUntil } from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren, AfterViewInit } from '@angular/core';
import { Device } from '../../models/device';
import Hls from 'hls.js'
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddStreamingCameraDialogComponent } from '../../dialogs/add-streaming-camera-dialog/add-streaming-camera-dialog.component';



@Component({
  selector: 'urban-streaming-widget',
  templateUrl: './streaming-widget.component.html',
  styleUrls: ['./streaming-widget.component.scss']
})
export class StreamingWidgetComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input('devicesList') public listDevices: Device[];
  @ViewChildren('videoContainer') videoContainers: QueryList<ElementRef>;
  
  private cameras: Device[] = [];
  public addableCameras: Device[] = [];
  public selectedCameras: Device[] = [];
  public videoDivs: ElementRef[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public noVideo: boolean[] = [];

  constructor(private renderer: Renderer2, public dialog: MatDialog) { }


  ngOnInit(): void {
    this.loadDevices(this.listDevices);
    this.addableCameras = this.cameras;
  }
  ngAfterViewInit(): void {
    this.videoContainers.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
      this.videoDivs = resp.toArray();


      if (this.videoDivs.length > 0) {
        const lastVideoContainer = this.videoDivs[this.videoDivs.length - 1].nativeElement
        const videoChild = lastVideoContainer.children.namedItem('video-streaming');
        if (!videoChild) {
          this._loadStreaming(lastVideoContainer.data);
        }

      }

    })
  }
  
  addCamera(): void {
    const addSettingDialogRef = this.dialog.open(AddStreamingCameraDialogComponent, {
      data: { addableCameras: this.addableCameras },
      width: '428px'
    });

    addSettingDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedCamera => {
      if (selectedCamera) {
        this.selectedCameras.push(selectedCamera);
        this.addableCameras = this.cameras.filter(camera => this.selectedCameras.every(x => x.Id !== camera.Id));
      } 
    });
  }


  getImageId(camera: Device): number {
    let cameraIndex = this.selectedCameras.indexOf(camera);
    let imageId = cameraIndex % 3;
    
    return imageId
  }

  private _loadStreaming(id: string): void {

    let videoUrl = 'https://video.urbanvision.app/' + id + '/proxy/video.m3u8';
    let container = this.videoDivs[this.videoDivs.length - 1].nativeElement;

    if (container === null || container === undefined) {
      return;
    }

    if (Hls.isSupported()) {
      let hls = new Hls();
      hls.loadSource(videoUrl);

      hls.on(Hls.Events.ERROR, () => {
        this.noVideo[id] = true;
      });
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        let videoContainer: HTMLVideoElement = this.renderer.createElement('video');
        this.renderer.setProperty(videoContainer, 'id', 'video-streaming');
        this.renderer.setAttribute(videoContainer, 'class', 'video-content');
        this.renderer.setAttribute(videoContainer, 'controls', 'auto');
        this.renderer.appendChild(container, videoContainer);
        hls.attachMedia(videoContainer);
        this.noVideo[id] = false;
        videoContainer.play();
      });
    }
    else {
      let videoContainer: HTMLVideoElement = this.renderer.createElement('video');
      this.renderer.setProperty(videoContainer, 'id', 'video-streaming');
      this.renderer.setAttribute(videoContainer, 'class', 'video-content');
      this.renderer.appendChild(container, videoContainer);
      if (videoContainer.canPlayType('application/vnd.apple.mpegurl')) {
        videoContainer.src = videoUrl;
        videoContainer.addEventListener('loadedmetadata', () => {
          this.noVideo[id] = false;
          videoContainer.play();
        });
      }
    }
  }

  public close(id: string): void {
    let container = this.videoDivs.find(video => video.nativeElement.data === id);
    if (container === null || container === undefined) {
      return;
    }
    let videoContainer = container.nativeElement.children.namedItem('video-streaming');
    if (videoContainer !== null && videoContainer !== undefined) {
      this.renderer.removeChild(container, videoContainer);
    }
    if (this.noVideo[id] !== null && this.noVideo[id] !== undefined) {
      delete this.noVideo[id];
    }
    const choosenCamera: Device = this.cameras.find(camera => camera.Id === id)
    this.addableCameras.push(choosenCamera);
    this.selectedCameras.splice(this.selectedCameras.indexOf(choosenCamera), 1);
  }
  private loadDevices(devicesToIterate: Device[]): void {
    if (devicesToIterate && devicesToIterate.length > 0) {
      for (let device of devicesToIterate) {
        if (device.Childs?.length > 0) {
          if (device.Model.Type.Name === 'camera') {
            this.cameras.push(device);
          }
          for (let child of device.Childs) {
            if (child.Model.Type.Name === 'camera') {
              this.cameras.push(child);
            }
            if (child.Childs?.length > 0) {
              this.loadDevices(child.Childs)
            }
          }
        }
        else {
          if (device.Model.Type.Name === 'camera') {
            this.cameras.push(device);
          }
        }
      }
      //ordinamento in ordine alfabetico sul Name
      this.cameras.sort((a, b) => a.Name.localeCompare(b.Name))
    }
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
