import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { ChartDataDescription } from './../../../shared/models/ChartDataList';
import { ApiService } from './../../../shared/services/api.service';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from './../../../shared/services/main-subscriptions/main-subscriptions.service';
import { TrafficCategory, TrafficEventAverage } from './../../../shared/models/smartTraffic';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { ChartDataList } from '../../../shared/models/ChartDataList';
import { TrafficEvent } from '../../../shared/models/smartTraffic';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { Device } from 'src/app/shared/models/device';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { MatTableDataSource } from '@angular/material/table';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';

@Component({
  selector: 'urban-traffic-detail',
  templateUrl: './traffic-detail.component.html',
  styleUrls: ['./traffic-detail.component.scss']
})
export class TrafficDetailComponent implements OnInit, OnDestroy {
  public trafficDevice: Device;
  public trafficEvents: TrafficEvent[];
  public trafficAverage: TrafficEventAverage;
  public loadingData: boolean;
  public trafficDataSource: MatTableDataSource<TrafficCategory>;
  public displayedColumns: string[] = ['Vehicle', 'Percentage level'];
  public historyDataToPass: ChartDataList[];
  public averageDataToPass: ChartDataList;
  public dataDescriptions: ChartDataDescription[] = [];
  public dataTranslations: string[] = [];
  public trafficLevelTranslation: string;
  public readyToShow: boolean = false;
  public noDataInDate: boolean = false;
  public currentLanguage: string;
  public isDarkActive: boolean;
  public translationsReady: boolean = false;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private translateSubscription: Subscription = new Subscription();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;

        this.loadData(deviceId);
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDataDescriptions(phrases: string[], afterComplete: () => void = () => {}): void {
    phrases.splice(0, 0, 'free_traffic');
    this.translateSubscription.unsubscribe();
    let prefix: string = 'SMART_TRAFFIC.';

    this.setDynamicTranslations(
      [...phrases.map((phrase: string) => prefix + phrase.toUpperCase()), prefix + 'LEVEL'],
      (res: any) => this.afterCategoriesTranslations(res, phrases, prefix),
      afterComplete
    );
  }

  private setDynamicTranslations(
    phrases: string[],
    afterTranslated: (phrasesTranslated: any) => void = () => {},
    afterFirstComplete: () => void = () => {}
  ): void {
    this.getTranslations(phrases, (res: any) => {
      afterTranslated(res);
      afterFirstComplete();
    });
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res)
    });
  }

  private afterCategoriesTranslations(res: any, phrases: string[], prefix: string): void {
    this.dataDescriptions = [];
    this.dataTranslations = [];
    phrases.forEach((phrase: string) => {
      let translation: string = res[prefix + phrase.toUpperCase()];
      let dataDescription: ChartDataDescription = {
        Original: phrase,
        Translated: translation !== (prefix + phrase.toUpperCase()) ? translation : phrase
      };
      this.dataDescriptions.push(dataDescription);
      this.dataTranslations.push(dataDescription.Translated);
    });
    this.trafficLevelTranslation = res[prefix + 'LEVEL'];
    this.translationsReady = true;
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, deviceEventsFeature: number;

    const syncContext = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        this.resetAllData();

        if (checkValues[deviceEventsFeature]) {
          this.trafficEvents = this.mapToTrafficEvents(data[deviceEventsFeature], [this.trafficDevice]);
        }

        if (this.trafficEvents.length > 0) {
          this.setAllData();

          this.noDataInDate = false;
          this.readyToShow = true;
        }
        else {
          this.readyToShow = false;
          this.noDataInDate = true
        }
        this.loadingData = false;
      }
    }, syncContext);

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.trafficDevice = device;

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
        this.setErrorAndGoToMain();
      }
    });

    let eventsRequest: DeviceEventRequest = { DeviceId: deviceId };

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      if (res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        deviceEventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;

    let eventsRequest: DeviceEventLatestRequest = { DeviceId: this.trafficDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest, this.isAProperEvent)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if(res && res.Devices?.length > 0) {
        this.trafficEvents = this.mapToTrafficEvents(res, [this.trafficDevice]);
      }

      if(this.trafficEvents.length > 0) {
        this.setLatestDates(res);

        this.setAllData();

        this.noDataInDate = false;
        this.readyToShow = true;
      }
      else {
        this.readyToShow = false;
        this.noDataInDate = true
      }

      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;

    let eventsRequest: DeviceEventRequest = {
      DeviceId: this.trafficDevice.Id,
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();
      if(res && res.Devices?.length > 0) {
        this.trafficEvents = this.mapToTrafficEvents(res, [this.trafficDevice]);
      }

      if(this.trafficEvents.length > 0) {
        this.setAllData();

        this.noDataInDate = false;
        this.readyToShow = true;
      }
      else {
        this.last24hSearch = selectedDates.last24hSearch;
        this.readyToShow = false;
        this.noDataInDate = true
      }
      this.loadingData = false;
    });
  }

  private resetAllData(): void {
    this.trafficEvents = [];
    this.trafficAverage = null;
    this.historyDataToPass = [];
    this.averageDataToPass = {};
    this.trafficDataSource = new MatTableDataSource<TrafficCategory>();
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private setAllData(): void {
    let trafficCategories: string[] = this.getVehiclesCategories(this.trafficEvents);
    this.setDataDescriptions(trafficCategories, () => {
      let averages: TrafficEventAverage[] = this.calculateTrafficDeviceAverage(this.trafficEvents, [this.trafficDevice]);
      this.trafficAverage  = averages[0];

      this.setStatusTableData();
      this.setChartsData();
    });
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event));
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find((event: EventLatest) => event.Level === 'Info' && this.isAProperEvent(event));
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private setStatusTableData(): void {
    let trafficData: TrafficCategory[] = this.trafficAverage.Categories.slice().sort((a, b) => b.Level - a.Level);
    this.trafficDataSource = new MatTableDataSource<TrafficCategory>(trafficData);
  }

  private setChartsData(): void {
    this.setHistoryChartData();
    this.setAverageChartData();
  }

  private setHistoryChartData(): void {
    let timeInSeconds: number = this.roundTime(this.trafficEvents[this.trafficEvents.length - 1].Created);
    let roundedConvertedTime: string;
    while (timeInSeconds <= this.trafficEvents[0].Created) {
      roundedConvertedTime = this.convertTime(timeInSeconds);
      this.historyDataToPass[roundedConvertedTime] = {};
      this.historyDataToPass[roundedConvertedTime]['Level'] = 0;
      timeInSeconds += 5 * 60;
    }
    timeInSeconds = this.roundTime(this.trafficEvents[this.trafficEvents.length - 1].Created);
    let dataAmountPerPeriod: number = 0;
    this.trafficEvents.slice().reverse().forEach((singleEvent: TrafficEvent) => {
      roundedConvertedTime = this.convertTime(this.roundTime(singleEvent.Created));
      if (roundedConvertedTime !== this.convertTime(timeInSeconds)) {
        this.historyDataToPass[this.convertTime(timeInSeconds)]['Level'] /= dataAmountPerPeriod; //percentageData average
        dataAmountPerPeriod = 1;
        do {
          timeInSeconds += 5 * 60;
        }
        while (roundedConvertedTime !== this.convertTime(timeInSeconds));
      }
      else {
        dataAmountPerPeriod++;
      }
      this.historyDataToPass[roundedConvertedTime]['Level'] += singleEvent.Level;
    });
    this.historyDataToPass[roundedConvertedTime]['Level'] /= dataAmountPerPeriod; //percentageData average
  }

  private setAverageChartData(): void {
    this.averageDataToPass[this.dataTranslations[0]] = 100 - this.trafficAverage.Level;
    this.dataTranslations.slice(1).forEach(category => {
      this.averageDataToPass[category] = 0;
    });
    this.trafficAverage.Categories.forEach(category => {
      let translation: string = this.getDataTranslation(category.Name);
      if(Object.keys(this.averageDataToPass).includes(translation)) {
        this.averageDataToPass[translation] = (category.Level * this.trafficAverage.Level) / 100;
      }
    });
  }

  private getDataTranslation(dataName: string): string {
    let translation: string;

    let dataDescription: ChartDataDescription = this.dataDescriptions.find(
      (description: ChartDataDescription) => description.Original === dataName
    );
    if (dataDescription !== undefined) {
      translation = dataDescription.Translated;
    }

    return translation;
  }

  private isAProperEvent(event: DeviceEventsEvent | EventLatest): boolean {
    return ['Categories', 'Level'].every(key => Object.keys(event.Body).includes(key)) &&
      event.Body.Categories.length > 0 &&
      event.Body.Categories.every((category: TrafficCategory) =>
        ['Name', 'Level', 'Icon'].every(key => Object.keys(category).includes(key)))
  }

  private mapToTrafficEvents(res: DeviceEventsResponse, trafficDevices: Device[]): TrafficEvent[] {
    let formattedEvents: TrafficEvent[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let trafficDevice: Device = trafficDevices.find(oneDevice => oneDevice.Id === device.Id);
      if (trafficDevice !== undefined) {
        device.Events.forEach((event: DeviceEventsEvent) => {
          if (['Categories', 'Level'].every(key => Object.keys(event.Body).includes(key)) && event.Body.Categories.length > 0) {
            let categories: TrafficCategory[] = event.Body.Categories.filter(
              (category: TrafficCategory) => ['Name', 'Level', 'Icon'].every(key => Object.keys(category).includes(key)));
            let trafficEvent: TrafficEvent = {
              Categories: categories,
              Created: event.CreatedTimestamp,
              Device: trafficDevice,
              DomainId: trafficDevice.Domain.Id,
              Id: '',
              Level: event.Body.Level
            };

            formattedEvents.push(trafficEvent);
          }

        });
      }
    });
    return formattedEvents;
  }

  private getVehiclesCategories(trafficEvents: TrafficEvent[]): string[] {
    let categories: string[] = [];

    trafficEvents.forEach((trafficEvent: TrafficEvent) => {
      trafficEvent.Categories.forEach((category: TrafficCategory) => {
        if (!categories.includes(category.Name)) {
          categories.push(category.Name);
        }
      });
    });

    return categories;
  }

  private calculateTrafficDeviceAverage(trafficEvents: TrafficEvent[], trafficDevices: Device[]): TrafficEventAverage[] {
    let averages: TrafficEventAverage[] = [];
    trafficDevices.forEach((trafficDevice: Device) => {
      //let deviceBrandName = trashBinDevice.Model.Brand.Name;
      let deviceEvents: TrafficEvent[] = trafficEvents.filter((event: TrafficEvent) => event.Device.Id === trafficDevice.Id);
      if(deviceEvents.length > 0) {
        let average: TrafficEventAverage = {
          Device: trafficDevice,
          Created: deviceEvents[0].Created,
          Level: 0,
          Categories: []
        };
        let categoriesLevels: {[key: string]: number} = {};

        deviceEvents.forEach((event: TrafficEvent) => {
          average.Level += event.Level;
          event.Categories.forEach(category => {
            if(!Object.keys(categoriesLevels).includes(category.Name)) {
              average.Categories.push({
                Name: category.Name,
                Level: 0,
                Icon: category.Icon
              });
              categoriesLevels[category.Name] = 0;
            }
            categoriesLevels[category.Name] += category.Level;
          });
        });
        average.Level = Math.round(average.Level / deviceEvents.length * 100) / 100;

        average.Categories.forEach((category: TrafficCategory) => {
          category.Level = Math.round(categoriesLevels[category.Name] / deviceEvents.length * 100) / 100;
        });

        averages.push(average);
      }
    });
    return averages;
  }

  roundTime(seconds: number): number {
    return seconds - (seconds % (5 * 60));
  }

  convertTime(seconds: number): string {
    return new Date(seconds * 1000).toString();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.trafficEvents.length === 0) {
      this.readyToShow = false;
      this.noDataInDate = true
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceEvents(): void {
    if (this.trafficDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.trafficDevice.Id, BackRoute: 'smart-traffic-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
