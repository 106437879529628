import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Subject} from "rxjs";
import {Device} from "../../../shared/models/device";
import {ApiService} from "../../../shared/services/api.service";
import {PassDataService} from "../../../shared/services/pass-data/pass-data.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {DeviceCommandService} from "../../../shared/services/device-command.service";
import {DeviceCommandListRequest} from "../../../shared/models/device-command/deviceCommandListRequest";
import {DeviceCommand} from "../../../shared/models/device-command/deviceCommand";
import {ConfirmationDialogComponent} from "../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import { SearchDates } from 'src/app/shared/models/searchDates';

@Component({
  selector: 'urban-device-commands',
  templateUrl: './device-commands.component.html',
  styleUrls: ['./device-commands.component.scss']
})
export class DeviceCommandsComponent implements OnInit {

  @ViewChild(MatPaginator) private paginator: MatPaginator;

  public deviceCommands: DeviceCommand[] = [];
  public displayedColumns: string[] = ['Id', 'Created', 'Device', 'DeviceCommandType', 'Command', 'Detail'];
  public deviceCommandsDataSource: MatTableDataSource<DeviceCommand>;
  public pageEvent: PageEvent;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public startDate: number;
  public endDate: number;
  public myPageIndex: number = 0;
  public myTotalItems: number = 0;
  public myPageSize: number = 10;
  public currentDevice: Device;
  public actualFilter: string = '';
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private deviceCommandApiService: DeviceCommandService,
    private passDataService: PassDataService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    let deviceId: string;
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
      } else {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadData(deviceId);
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, commandsFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        if (checkValues[commandsFeature]) {
          this.myTotalItems = data[commandsFeature].Total;
          this.deviceCommands = data[commandsFeature].DeviceCommands;

          for (const deviceCommand of this.deviceCommands) {
            deviceCommand.DeviceCommandJson = JSON.parse(deviceCommand.Json);
          }

          this.deviceCommandsDataSource = new MatTableDataSource(this.deviceCommands);
          this.deviceCommandsDataSource.paginator = this.paginator;
        }
        else {
          this.deviceCommandsDataSource = new MatTableDataSource();
        }
      }
      this.loadingData = false;
    }, context);

    let firstRequest: DeviceCommandListRequest = {
      Device: deviceId,
      Page: this.myPageIndex,
      Limit: this.myPageSize
    };

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        deviceFeature = this.apiSync.loadedFeature(context);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.deviceCommandApiService.list(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        commandsFeature = this.apiSync.loadedFeatureWithData(res, context);
      } else {
        commandsFeature = this.apiSync.failedFeature(context);
      }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;

    let latestEventsRequest: string = this.currentDevice.Id;

    this.deviceCommandApiService.getLatest24Hours(latestEventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceCommand[]) => {
      if (res && res.length > 0) {
        this.setLatestDates(res);

        this.myTotalItems = res.length;
        this.deviceCommands = res;

        for (const deviceCommand of this.deviceCommands) {
          deviceCommand.DeviceCommandJson = JSON.parse(deviceCommand.Json);
        }

        this.deviceCommandsDataSource = new MatTableDataSource(this.deviceCommands);
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceCommand[]): void {
    this.endDate = res[0].Created;
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.paginator?.firstPage();
    }

    let serverRequest: DeviceCommandListRequest = {
      Device: this.currentDevice.Id,
      Page: this.myPageIndex,
      Limit: this.myPageSize,
      Start: this.startDate,
      End: this.endDate
    }

    this.deviceCommandApiService.list(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res) {
        this.myTotalItems = res.Total;
        this.deviceCommands = res.DeviceCommands;

        for (const deviceCommand of this.deviceCommands) {
          deviceCommand.DeviceCommandJson = JSON.parse(deviceCommand.Json);
        }

        this.deviceCommandsDataSource = new MatTableDataSource(this.deviceCommands);
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  public getServerData(event: PageEvent) {
    if (event.previousPageIndex === event.pageIndex - 1) {
      this.myPageIndex = this.myPageIndex + 1;
    } else if (event.previousPageIndex === event.pageIndex + 1) {
      this.myPageIndex = this.myPageIndex - 1;
    } else if (event.pageIndex === Math.floor(event.length / event.pageSize)) {
      this.myPageIndex = Math.floor(event.length / event.pageSize);
    } else if (event.pageIndex === 0) {
      this.myPageIndex = 0;
    }
    this.myPageSize = event.pageSize;
    this.newSearch();
    return event;
  }

  public goToDetail(deviceCommand: DeviceCommand): void {
    this.mainService.setNavigationInfoComand({Id: deviceCommand.Id, BackRoute: 'device-commands'});
    this.router.navigate(['main/device-command-detail']);
  }

  commandDelete(deviceCommand: DeviceCommand) {
    if (!deviceCommand) {
      return;
    }

    const deleteCommandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteCommandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.deviceCommandApiService.delete(deviceCommand.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this.newSearch();
          }
        });
      }
    });
  }

  setupFilter() {
    this.deviceCommandsDataSource.filterPredicate = (d: any, filter: string) => {
      let created: string = new Date(d.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/');
      return (d.Id.toLowerCase().includes(filter)) || (created.toLowerCase().includes(filter)) ||
      (d.Device.Name.toLowerCase().includes(filter)) || (d.DeviceCommandType.Name.toLowerCase().includes(filter)) ||
      (d.DeviceCommandJson?.Body.toLowerCase().includes(filter))
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.deviceCommandsDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterString(filter: string) {
    const filterValue = filter;
    this.deviceCommandsDataSource.filter = filterValue.trim().toLowerCase();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
