import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PasswordValidator } from './password-validator';

@Component({
  selector: 'urban-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public error: string | null;
  //public success: string | null;

  public hideOldPassword: boolean = true;
  public hideNewPassword: boolean = true;
  public hideConfirmedNewPassword: boolean = true;

  public oldPassword: string;
  public newPassword: string;
  public confirmedNewPassword: string;
  private regexPassword: string;

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.regexPassword = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$";
    this.form = fb.group({
      oldPassword: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.pattern(this.regexPassword)])],
      confirmedNewPassword: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.pattern(this.regexPassword)])]
    }, {
      validator: PasswordValidator.MatchPassword // your validation method
    }
  )
  }

  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close();
  }

  async change(){
    if(this.form.valid){
      this.form.controls.oldPassword.setValue(await this.getSha256(this.form.controls.oldPassword.value));
      this.form.controls.newPassword.setValue(await this.getSha256(this.form.controls.newPassword.value));
      this.form.controls.confirmedNewPassword.setValue(await this.getSha256(this.form.controls.confirmedNewPassword.value));

      this.dialogRef.close(this.form.value)
    } else {

      if(this.form.controls.oldPassword.errors?.required == true ||
        this.form.controls.newPassword.errors?.required == true ||
        this.form.controls.confirmedNewPassword.errors?.required == true
        ) {
          this.error = "ERROR_EMPTY";
      }
      else {
        if(this.form.controls.oldPassword.errors?.minlength){
          this.error = "ERROR_LENGTH_1";
        }
        else {
          if(this.form.controls.oldPassword.errors?.pattern){
            this.error = "ERROR_SYNTAX";
          }
          else {
            if(this.form.controls.newPassword.errors?.minlength){
              this.error = "ERROR_LENGTH_2";
            }
            else {
              if(this.form.controls.newPassword.errors?.pattern){
                this.error = "ERROR_SYNTAX";
              }
              else {
                if (this.form.controls.confirmedNewPassword.errors?.MatchPassword == true) {
                  this.error = "ERROR_EQUAL";
                }
              }
            }
          }
        }
      }
    }
  }

  async getSha256(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);

    let hashBuffer: ArrayBuffer = await window.crypto.subtle.digest('SHA-256', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));                     
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); 
    return hashHex;
  }
}
