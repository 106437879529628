<div class="settingsContainer d-flex flex-column justify-content-start">
    <i class="urbanicon-back" (click)="closeThisRightbar()"></i>

    <h3 class="title3 sidebarTitle">{{ 'DASHBOARD.SETTINGS' | translate }}</h3>
    <span class="subheadText mb-4">{{ 'LOGIN.CHOOSE_DOMAIN' | translate }}</span>

    <div class="position-relative mb-5">
        <mat-form-field class="w-100 userSettingsSelect" appearance="outline">
            <mat-select [value]="myCurrentDomainRef.Id">
                <mat-option class="user-select-none" *ngFor="let domain of availableDomains" [value]="domain.Id"
                    (click)="setDomain(domain)">{{domain.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <urban-loader [localized]="true" [disappearCondition]="availableDomains.length > 0">
        </urban-loader>
    </div>

    <div class="d-flex flex-column mb-5">
        <div class="d-flex">
            <span class="subheadText mb-4">{{ 'USER_SETTINGS.SCREEN_MODE' | translate }}</span>
        </div>

        <mat-slide-toggle class="mb-1" [checked]="this.darkModeStatus" (toggleChange)="toggleDarkModeSlide()"
            labelPosition="before" color="primary">
        </mat-slide-toggle>

        <p class="paragraphText">{{ 'USER_SETTINGS.SCREEN_MODE_SWITCH' | translate }}</p>
    </div>

    <div>
        <span class="subheadText d-block mb-4">{{ 'GENERAL.LANGUAGE' | translate }}</span>

        <div class="d-flex flex-column">
            <span class="lang linkText d-block mb-3 text-decoration-none" *ngFor="let language of languages" (click)="changeLang(language.Name)" 
                [ngClass]="{'link' : currentLanguage === language.Name, 'disabledLang' : language.Name !== 'it' && language.Name !== 'en'}">
                {{language.Description}}
            </span>
        </div>
    </div>
</div>