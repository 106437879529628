<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span *ngIf="transitDevice" class="title2 appearingTextLeft">
                {{ this.transitDevice.Name + ' -' }}
            </span>
            <span class="title2">{{ 'TRANSIT_DEVICE.TITLE' | translate }}</span>
        </div>

        <!-- <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span> -->
    </div>

    <urban-searchbar id="intro-transit-detail-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
            [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
            (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <div>
        <mat-tab-group id="intro-transit-detail-tab" animationDuration="0ms" dynamicHeight="true">
            <mat-tab [label]="'GENERAL.STATUS' | translate">
                <div class="tabContent" *ngIf="transitDataSource">
                    <div *ngIf="transitDataSource.data.length > 0" class="customTable">
                        <table id="intro-transit-detail-table" class="w-100 h-100 noTableHover" mat-table
                        [dataSource]="transitDataSource">

                            <ng-container matColumnDef="Type">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                    {{'GENERAL.TYPE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">
                                    {{ 'TRANSIT_DEVICE.TYPE_'+item.Type.toUpperCase() | translate }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Percentage">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                    {{'GENERAL.PERCENTAGE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">
                                    {{ item.Percentage }}%
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <div *ngIf="transitDataSource.data.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
                <div *ngIf="chartsTab.isActive && transitions" class="tabContent">
                    <div *ngIf="transitions.length > 0">
                        <div class="my-3 toggleButtons">
                            <mat-button-toggle-group aria-label="Transit Type Set" [(ngModel)]="typeSelected" (valueChange)="filterTransitions($event)">
                                <mat-button-toggle *ngFor="let type of transitTypes" [value]="type">
                                    {{ 'TRANSIT_DEVICE.TYPE_'+type.toUpperCase() | translate }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="roundedPanel panelBG chartContainer">
                            <urban-google-columnchart columnChartTitle="TRANSIT_DEVICE.10_LONGEST_STAYS"
                                [columnChartSubtitle]="columnChartSubtitle" columnChartId="10LongestStays"
                                [passedChartData]="columnChartData" columnsTitle="TRANSIT_DEVICE.TRACKER_ID"
                                valuesDescription="TRANSIT_DEVICE.TIME_PERIOD" [passedVAxisTicks]="columnChartVAxisTicks"
                                [alreadySorted]="true" [darkThemeActive]="this.isDarkActive">
                            </urban-google-columnchart>
                        </div>
                        <div class="roundedPanel panelBG chartContainer" *ngIf="limitsError == false">
                            <urban-sankey-diagram-chart
                                [passedDataList]="sankeyDataList" [passedSubtitle]="sankeySubtitle"
                                sankeyDiagramChartTitle="TRANSIT_DEVICE.TRANSIT_DIRECTIONS" [darkThemeActive]="isDarkActive"
                            ></urban-sankey-diagram-chart>
                        </div>
                        <div class="roundedPanel panelBG chartContainer">
                            <span class="pt-3 pl-3 subheadText d-block">{{ 'TRANSIT_DEVICE.TRANSIT_AMOUNTS_HISTORY' | translate }} - {{ ('TRANSIT_DEVICE.TYPE_'+(typeSelected.toUpperCase())) | translate}}</span>

                            <urban-google-linechart [sensor]="lineChartData" valueDescription="TRANSIT_DEVICE.TRANSIT_AMOUNT"
                                [startDate]="startDate" [endDate]="endDate" [darkThemeActive]="this.isDarkActive">
                            </urban-google-linechart>

                            <div class="ml-3" *ngIf="lineChartData">
                                <span>{{ 'TRANSIT_DEVICE.TIME_FREQUENCY' | translate }}</span>
                                <mat-slider
                                    min="1" max="60" step="1" color="primary" thumbLabel=true
                                    (change)="changeHistoryPeriod()" [(ngModel)]="historyPeriod">
                                </mat-slider>
                                <span>{{ historyPeriod }} {{ 'GENERAL.MINUTES' | translate }}</span>
                            </div>
                        </div>

                        <div class="roundedPanel panelBG chartContainer">
                            <urban-google-columnchart columnChartTitle="TRANSIT_DEVICE.TRANSIT_AVERAGE_PER_HOUR"
                                [columnChartSubtitle]="columnChartSubtitle" columnChartId="averagePerHour"
                                [passedChartData]="hourlyColumnChartData" columnsTitle="GENERAL.DAILY_TIME_BANDS"
                                valuesDescription="TRANSIT_DASHBOARD.TRANSIT_AMOUNTS" [hAxisTicksCanBeHidden]="true"
                                [darkThemeActive]="this.isDarkActive">
                            </urban-google-columnchart>
                        </div>

                        <div class="mt-5" *ngIf="limitsError == false">
                            <div class="d-flex flex-row align-items-center justify-content-between mb-3">
                                <h3>{{ 'TRANSIT_DEVICE.TRANSIT_FLOWS' | translate }}</h3>

                                <div>
                                    <div *ngIf="transitTypes.length > 0" class="ml-2 mr-3"
                                        >
                                        <mat-button-toggle-group aria-label="Chart type Set" class="searchRangeBtn"
                                        (valueChange)="setPathsChartType($event)" [(ngModel)]="pathsChartTypeSelected">
                                            <mat-button-toggle *ngFor="let type of transitTypes.slice(1)" [value]="type">
                                                <div
                                                    [ngClass]="{'button-toggle-wrap lineheightIT' : currentLanguage === 'it', 'fontSizeToggleEn' : currentLanguage === 'en'}">
                                                    <span>{{ 'TRANSIT_DEVICE.TYPE_'+type.toUpperCase() | translate }}</span>
                                                </div>
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-row flex-wrap panelBG">
                                <div class="flowChart" *ngIf="pathsChartData">
                                    <urban-paths-chart
                                        [passedDataList]="pathsChartData" [selectedType]="pathsChartTypeSelected" [pathsPrecision]="spatialPrecision"
                                        pathsChartTitle="TRANSIT_DEVICE.PATHS" [nestedChart]="true" [darkThemeActive]="isDarkActive"
                                        [chartWidth]="maxAreaLimit.X - minAreaLimit.X" [chartHeight]="maxAreaLimit.Y - minAreaLimit.Y"
                                        [chartLegend]="pathsChartLegend"
                                    ></urban-paths-chart>
                                </div>
                                <div class="flowChart" *ngIf="stopBubbleChartData">
                                    <urban-google-bubblechart
                                        bubbleChartTitle="TRANSIT_DEVICE.STOPS" [passedChartData]="stopBubbleChartData"
                                        [selectedType]="pathsChartTypeSelected" [passedDataDescriptions]="bubbleChartDescriptions"
                                        [chartWidth]="maxAreaLimit.X - minAreaLimit.X" [chartHeight]="maxAreaLimit.Y - minAreaLimit.Y"
                                        [darkThemeActive]="this.isDarkActive" [chartPrecision]="spatialPrecision"
                                        flipChart="Y" [sheerLayout]="true" [nestedChart]="true" [chartLegend]="bubbleChartLegend"
                                    ></urban-google-bubblechart>
                                </div>
                            </div>
                            <div class="ml-3 mt-2">
                                <span>{{ 'TRANSIT_DEVICE.SPATIAL_PRECISION' | translate }}</span>
                                <mat-slider
                                    min="6" max="14" step="1" color="primary" thumbLabel=true
                                    (change)="changeSpatialPrecision()" [(ngModel)]="spatialPrecision">
                                </mat-slider>
                                <span>{{ spatialPrecision }} {{ 'TRANSIT_DEVICE.POSITIONS_PER_SIDE' | translate }}</span>
                            </div>
                        </div>
                        <div class="mt-2" *ngIf="limitsError">
                            <h6 class="error">{{ 'TRANSIT_DEVICE.PROPERTIES_ERROR' | translate }}</h6>
                        </div>
                    </div>
                    <div *ngIf="transitions.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
