<h1 mat-dialog-title>{{ 'GENERAL.ADD' | translate }}</h1>
<mat-dialog-content>
    <div *ngIf="services !== undefined; else notAddService">
        <form [formGroup]="serviceForm">
            <mat-form-field class="w-100">
                <mat-label class="secondaryTextColor paragraphText">{{ 'CONSUMER_DIALOG.SERVICE' | translate }}</mat-label>
                <mat-select formControlName="service">
                    <mat-option *ngFor="let service of services" [value]="service.Id">
                        <span class="paragraphText">{{service.Name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>

    <ng-template #notAddService>
        <form *ngIf="currentProperties || property; else addConsumerContainer" [formGroup]="propertyForm">
            <mat-form-field>
                <mat-label>{{ 'GENERAL.KEY' | translate }}</mat-label>
                <input matInput formControlName="key" autocomplete="off" required>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'GENERAL.VALUE' | translate }}</mat-label>
                <input matInput formControlName="value" autocomplete="off" required>
            </mat-form-field>

            <mat-error *ngIf="error">
                {{ 'GENERAL.' + error | translate }}
            </mat-error>
        </form>

        <ng-template #addConsumerContainer>
            <form [formGroup]="consumerForm">
                <mat-form-field>
                    <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                    <input matInput formControlName="name" autocomplete="off" required>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
                    <input matInput formControlName="description" autocomplete="off" required>
                </mat-form-field>

                <mat-error *ngIf="error">
                    {{ 'GENERAL.' + error | translate }}
                </mat-error>
            </form>
        </ng-template>
    </ng-template>


</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton"
        (click)="services ? addServiceToConsumer() : (
            currentProperties || property !== undefined ?
                addOrUpdatePropertyToConsumer() : addConsumer()
    )">{{ (property === undefined ? 'GENERAL.ADD' : 'GENERAL.UPDATE') | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
