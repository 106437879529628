import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DeviceNotification} from "../../../shared/models/device-command/deviceNotification";
import {MatTableDataSource} from "@angular/material/table";
import {interval, Subject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {ApiService} from "../../../shared/services/api.service";
import {AdministratorApiService} from "../../../shared/services/administrator-api.service";
import {DeviceNotificationService} from "../../../shared/services/device-notification.service";
import {DeviceCommandService} from "../../../shared/services/device-command.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {PassDataService} from "../../../shared/services/pass-data/pass-data.service";
import {map, first, switchMap, takeUntil, tap} from "rxjs/operators";
import {
  DeviceNotificationDetailDialogComponent
} from "../../../shared/dialogs/device-notification-detail-dialog/device-notification-detail-dialog.component";
import {DeviceCommand} from "../../../shared/models/device-command/deviceCommand";
import {DeviceNotificationPollingServiceService} from "../../../shared/services/device-notification-polling.service";
import {LoaderService} from "../../../shared/services/loader/loader.service";
import {ClientSettings} from "../../../shared/models/clientSettings";
import {DeviceCommandJson} from "../../../shared/models/device-command/deviceCommandJson";
import {DeviceNotificationListResponse} from "../../../shared/models/device-command/deviceNotificationListResponse";

@Component({
  selector: 'urban-device-command-detail',
  templateUrl: './device-command-detail.component.html',
  styleUrls: ['./device-command-detail.component.scss']
})
export class DeviceCommandDetailComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup = this.formBuilder.group({
    id: [{value: '', disabled: false}],
    deviceId: [{value: '', disabled: false}],
    deviceName: [{value: '', disabled: false}],
    deviceCommandType: [{value: '', disabled: false}],
    created: [{value: 0, disabled: false}],
    delivered: [{value: null, disabled: false}],
    command: [{value: '', disabled: false}],
    expiration: [{value: 0, disabled: false}]
  });

  public deviceNotifications: DeviceNotification[] = [];
  displayedColumns: string[] = ['Id', 'Created', 'Device', 'Command', 'Detail'];
  deviceNotificationsDataSource = new MatTableDataSource<DeviceNotification>();

  public deviceCommand: DeviceCommand = null;
  public error: string | null = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public currentLanguage: string;
  private notificationPollingIntervalMs: number = 10000;
  private notificationsPolling$: Subject<void> = new Subject<void>();
  private clientSettings: ClientSettings = new ClientSettings();

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private administrationService: AdministratorApiService,
    private deviceNotificationService: DeviceNotificationService,
    private deviceNotificationPollingService: DeviceNotificationPollingServiceService,
    private deviceCommandService: DeviceCommandService,
    private translate: TranslateService,
    private router: Router,
    private passDataService: PassDataService,
    private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    let deviceCommandId: string;
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if(navInfo?.Id) {
        deviceCommandId = navInfo.Id;
      }
      else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.loadData(deviceCommandId);
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private loadData(deviceCommandId: string): void {
    this.deviceCommandService.get(deviceCommandId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(deviceCommandResult => {
      if (deviceCommandResult && deviceCommandResult.DeviceCommand) {
        this.deviceCommand = deviceCommandResult.DeviceCommand;

        this.form.setValue({
          id: this.deviceCommand.Id,
          deviceId: this.deviceCommand.Device.Id,
          deviceName: this.deviceCommand.Device.Name,
          deviceCommandType: this.deviceCommand.DeviceCommandType.Name,
          command: this.deviceCommand.Json,
          created: new Date(this.deviceCommand.Created * 1000),
          delivered: this.deviceCommand.Delivered && this.deviceCommand.Delivered > 0 ? new Date(this.deviceCommand.Delivered * 1000) : null,
          expiration: this.deviceCommand.Expiration && this.deviceCommand.Expiration > 0 ? new Date(this.deviceCommand.Expiration * 1000) : null
        });

        this.apiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clientSettings => {
          if (clientSettings !== undefined) {
            this.clientSettings.Settings = clientSettings;

            if (this.clientSettings.Settings["notification_polling_ms"]) {
              this.notificationPollingIntervalMs = +this.clientSettings.Settings["notification_polling_ms"];
            }

            this.loaderService.disable();

            this.getNotifications().subscribe();

            interval(this.notificationPollingIntervalMs).pipe(
              takeUntil(this.notificationsPolling$),
              switchMap(() => this.getNotifications())
            ).subscribe();
          }
        });
      }
    });
  }

  openNotificationDetailDialog(singleNotification: DeviceNotification): void {
    this.dialog.open(DeviceNotificationDetailDialogComponent, {
      data: {targetNotification: singleNotification},
      maxWidth: 1000,
      width: '70%'
    });
  }

  private getNotifications() {
    return this.deviceNotificationPollingService.polling({
      DeviceCommand: this.deviceCommand.Id,
      Page: 0,
      Limit: 1000
    }).pipe(map((response: DeviceNotificationListResponse) => {
      this.deviceNotifications = response.Items?.filter(x => x.DeviceCommandId === this.deviceCommand.Id);
      this.deviceNotificationsDataSource = new MatTableDataSource<DeviceNotification>(this.deviceNotifications);
    }));
  }

  ngOnDestroy(): void {
    this.loaderService.enable();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.notificationsPolling$.next();
    this.notificationsPolling$.complete();
  }
}
