import { DeviceEventsEvent, DeviceEventsResponse } from './../../../shared/models/deviceEvent';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, interval, Subject } from 'rxjs';
import { takeUntil, takeWhile, first } from 'rxjs/operators';
import { Device } from '../../../shared/models/device';
import { PeopleCounterHeatmapElement } from '../../../shared/models/peopleCounterHeatmapElement';
import { ApiService } from '../../../shared/services/api.service';
import { DeviceEventRequest } from '../../../shared/models/deviceEvent';
import { Sensor, SensorContent } from '../../../shared/models/weatherstation/sensor';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import * as introJs from 'intro.js/intro.js';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';

const secondsToRefresh = 90; //ogni quanto bisogna refreshare ---- prenderlo da BE
@Component({
  selector: 'urban-heatmap-people-counter',
  templateUrl: './heatmap-people-counter.component.html',
  styleUrls: ['./heatmap-people-counter.component.scss']
})
export class HeatmapPeopleCounterComponent implements OnInit {
  public parentDevicesList: Device[] = [];
  public pcHeatmapElements: PeopleCounterHeatmapElement[] = [];
  public peopleCounterMaxValue: number;
  public devicePCEvents: DeviceEventsEvent[] = [];
  public myPCSensor: Sensor = {
    Name: '',
    Unit: '',
    Limit: '',
    Data: []
  };
  public isASingleDevice: boolean;
  public singlePeopleCounterDevice: Device;
  public buttonToggleSelected: number = 24;
  public isChecked = true;
  public readyToShow: boolean = false;
  public chartReadyToShow: boolean = false;
  public noDataIn24h: boolean = false;
  public noDataInDate: boolean = false;
  public lastCreated: number;
  public lastUpdate: number = null;
  public last24hSearch: boolean = true;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public currentLanguage: string;
  public isDarkActive: boolean = false;
  public startDate: number;
  public endDate: number;
  public loadingData: boolean;
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  private introJS = introJs();

  constructor(
    private router: Router,
    private apiService: ApiService,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    let peopleCounterId : string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        peopleCounterId = navInfo.Id;
        this.isASingleDevice = true;
        this.loadSingleDeviceData(peopleCounterId);
      } else {
        this.isASingleDevice = false;
        this.loadDevicesData();
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translate
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  private showMap(): void {
    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.readyToShow = mapLoading;
    });
  }

  private loadSingleDeviceData(peopleCounterId: string): void {
    this.apiService.getDevice(peopleCounterId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.singlePeopleCounterDevice = device;
        this.extractSingleLocation(true);
        this.autoRefreshDataSingleDevice();
        this.newSearch();
      }
      else {
        this.setErrorAndGoToMain();
      }
    });
  }

  private loadDevicesData(): void  {
    this.apiService.getDevices().pipe(takeUntil(this.ngUnsubscribe)).subscribe(devices => {
      this.parentDevicesList = devices;
      this.extractLocations(true);
      interval(secondsToRefresh * 1000).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.extractLocations(false);
      });
    });
  }

  private autoRefreshDataSingleDevice(): void {
    if (this.isChecked == true) {
        interval(secondsToRefresh * 1000).pipe(takeUntil(this.ngUnsubscribe), takeWhile(() => this.isChecked)).subscribe(() => {
        this.extractSingleLocation(false);
        this.buttonToggleSelected = null;
      });
    }
  }

  public slideOnToggle() {
    if(this.isChecked == true) {
      this.autoRefreshDataSingleDevice();
    }
  }

  public newSearch (selectedDates?: SearchDates): void {
    this.loadingData = true;
    this.readyToShow = false;
    this.noDataInDate = false;
    this.chartReadyToShow = false;
    this.isChecked = false;

    this.startDate = selectedDates?.startDate ? selectedDates.startDate : undefined;
    this.endDate = selectedDates?.endDate ? selectedDates.endDate : undefined;

    let deviceEventsRequest: DeviceEventRequest = {
      DeviceId: this.singlePeopleCounterDevice.Id,
      Start: selectedDates?.startDate ? selectedDates.startDate : undefined,
      End: selectedDates?.endDate ? selectedDates.endDate : undefined
    }

    this.apiService.getDeviceEvents(deviceEventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        this.devicePCEvents = res.Devices[0].Events.filter((event: DeviceEventsEvent) =>
          event.Body?.Sensors?.some(
            bodySensor => ['Limit', 'Value'].every((field: string) => Object.keys(bodySensor).includes(field))
          )
        );
      }

      if(this.devicePCEvents.length > 0) {
        let updatedPCSensor: Sensor = {
          Name: '',
          Unit: '',
          Limit: '',
          Data: []
        };
        let eventBody = this.devicePCEvents[0].Body;
        updatedPCSensor.Name = eventBody.Sensors[0].Name;
        updatedPCSensor.Unit = eventBody.Sensors[0].Unit;
        updatedPCSensor.Limit = eventBody.Sensors[0].Limit;
        this.devicePCEvents.forEach(element => {
          let sensorContent: SensorContent = element.Body;
          if (sensorContent && sensorContent !== undefined) {
            sensorContent.Sensors.forEach(sensorContentData => {
              updatedPCSensor.Data.push({ Value: sensorContentData.Value, Created: this.reformatCreated(element.Created) });
            })
          }
        })
        this.myPCSensor = updatedPCSensor;

        let temporaryLAT: number;
        let temporaryLNG: number;
        let heatMapEventBody = this.devicePCEvents[0].Body;
        if (this.singlePeopleCounterDevice.Latitude === 0 && this.singlePeopleCounterDevice.Longitude === 0) {
          this.apiService.getDevice(this.singlePeopleCounterDevice.ParentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(singleParent => {
            temporaryLAT = singleParent.Latitude;
            temporaryLNG = singleParent.Longitude;
            let myPcHeatmapElement: PeopleCounterHeatmapElement = {
              lat: temporaryLAT,
              lng: temporaryLNG,
              value: +heatMapEventBody.Sensors[0].Value
            }
            this.peopleCounterMaxValue = +heatMapEventBody.Sensors[0].Limit;
            this.pcHeatmapElements.push(myPcHeatmapElement);
            this.lastUpdate = this.devicePCEvents[0].CreatedTimestamp;
            this.showMap();
          });

        } else {
          temporaryLAT = this.singlePeopleCounterDevice.Latitude;
          temporaryLNG = this.singlePeopleCounterDevice.Longitude;
          let myPcHeatmapElement: PeopleCounterHeatmapElement = {
            lat: temporaryLAT,
            lng: temporaryLNG,
            value: +heatMapEventBody.Sensors[0].Value
          }
          this.peopleCounterMaxValue = +heatMapEventBody.Sensors[0].Limit;
          this.pcHeatmapElements.push(myPcHeatmapElement);
          this.lastUpdate = this.devicePCEvents[0].CreatedTimestamp;
          this.showMap();
        }

        this.chartReadyToShow = true;
      }
      else {
        if (selectedDates !== null && selectedDates !== undefined) {
          this.last24hSearch = selectedDates.last24hSearch;
        }

        this.noDataInDate = true;
      }
      this.loadingData = false;
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;
    this.noDataInDate = false;
    this.chartReadyToShow = false;
    this.readyToShow = false;
    this.isChecked = false;

    let eventsRequest: DeviceEventLatestRequest = { DeviceId: this.singlePeopleCounterDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest, this.isAProperEvent)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      this.pcHeatmapElements = [];
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.devicePCEvents = res.Devices[0].Events.filter((event: DeviceEventsEvent) =>
          event.Body?.Sensors?.some(
            bodySensor => ['Limit', 'Value'].every((field: string) => Object.keys(bodySensor).includes(field))
          )
        );
      }

      if(this.devicePCEvents.length > 0) {
        this.setLatestDates(res);
        let updatedPCSensor: Sensor = {
          Name: '',
          Unit: '',
          Limit: '',
          Data: []
        };
        let eventBody = this.devicePCEvents[0].Body;
        updatedPCSensor.Name = eventBody.Sensors[0].Name;
        updatedPCSensor.Unit = eventBody.Sensors[0].Unit;
        updatedPCSensor.Limit = eventBody.Sensors[0].Limit;
        this.devicePCEvents.forEach(element => {
          let sensorContent: SensorContent = element.Body;
          if (sensorContent && sensorContent !== undefined) {
            sensorContent.Sensors.forEach(sensorContentData => {
              updatedPCSensor.Data.push({ Value: sensorContentData.Value, Created: this.reformatCreated(element.Created) });
            })
          }
        });
        this.myPCSensor = updatedPCSensor;

        let temporaryLAT: number;
        let temporaryLNG: number;
        let heatMapEventBody = this.devicePCEvents[0].Body;
        if (this.singlePeopleCounterDevice.Latitude === 0 && this.singlePeopleCounterDevice.Longitude === 0) {
          this.apiService.getDevice(this.singlePeopleCounterDevice.ParentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(singleParent => {
            temporaryLAT = singleParent.Latitude;
            temporaryLNG = singleParent.Longitude;
            let myPcHeatmapElement: PeopleCounterHeatmapElement = {
              lat: temporaryLAT,
              lng: temporaryLNG,
              value: +heatMapEventBody.Sensors[0].Value
            }
            this.peopleCounterMaxValue = +heatMapEventBody.Sensors[0].Limit;
            this.pcHeatmapElements.push(myPcHeatmapElement);
            this.lastUpdate = this.devicePCEvents[0].CreatedTimestamp;
            this.showMap();
          });

        } else {
          temporaryLAT = this.singlePeopleCounterDevice.Latitude;
          temporaryLNG = this.singlePeopleCounterDevice.Longitude;
          let myPcHeatmapElement: PeopleCounterHeatmapElement = {
            lat: temporaryLAT,
            lng: temporaryLNG,
            value: +heatMapEventBody.Sensors[0].Value
          }
          this.peopleCounterMaxValue = +heatMapEventBody.Sensors[0].Limit;
          this.pcHeatmapElements.push(myPcHeatmapElement);
          this.lastUpdate = this.devicePCEvents[0].CreatedTimestamp;
          this.showMap();
        }

        this.chartReadyToShow = true;
      }
      else {
        this.noDataInDate = true;
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public extractSingleLocation(firstCall: boolean): void {
    let deviceEventRequest: DeviceEventRequest = { DeviceId: this.singlePeopleCounterDevice.Id }

    if (!firstCall) {
      deviceEventRequest.End = Math.round(Date.now() / 1000),
        deviceEventRequest.Start = deviceEventRequest.End - (3600);
    }
    this.apiService.getDeviceEvents(deviceEventRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.pcHeatmapElements = [];
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        this.devicePCEvents = res.Devices[0].Events.filter((event: DeviceEventsEvent) =>
          event.Body?.Sensors?.some(
            bodySensor => ['Limit', 'Value'].every((field: string) => {
              return Object.keys(bodySensor).includes(field)
            })
          )
        );
      }

      if(this.devicePCEvents.length > 0) {
        let temporaryLAT: number;
        let temporaryLNG: number;
        let eventBody = this.devicePCEvents[0].Body;
        if (this.singlePeopleCounterDevice.Latitude === 0 && this.singlePeopleCounterDevice.Longitude === 0) {
          this.apiService.getDevice(this.singlePeopleCounterDevice.ParentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(singleParent => {
            temporaryLAT = singleParent.Latitude;
            temporaryLNG = singleParent.Longitude;
            let myPcHeatmapElement: PeopleCounterHeatmapElement = {
              lat: temporaryLAT,
              lng: temporaryLNG,
              value: +eventBody.Sensors[0].Value
            }
            this.peopleCounterMaxValue = +eventBody.Sensors[0].Limit;
            this.pcHeatmapElements.push(myPcHeatmapElement);
            this.lastUpdate = (res as DeviceEventsResponse).Devices[0].Events[0].CreatedTimestamp;
            this.showMap();
          })

        } else {
          temporaryLAT = this.singlePeopleCounterDevice.Latitude;
          temporaryLNG = this.singlePeopleCounterDevice.Longitude;
          let myPcHeatmapElement: PeopleCounterHeatmapElement = {
            lat: temporaryLAT,
            lng: temporaryLNG,
            value: +eventBody.Sensors[0].Value
          }
          this.peopleCounterMaxValue = +eventBody.Sensors[0].Limit;
          this.pcHeatmapElements.push(myPcHeatmapElement);
          this.lastUpdate = (res as DeviceEventsResponse).Devices[0].Events[0].CreatedTimestamp;
          this.showMap();
        }
        this.noDataIn24h = false;
      }
      else {
        if (firstCall) {
          this.noDataIn24h = true;
        }
      }
    });
  }

  public extractLocations(firstCall: boolean): void {
    this.pcHeatmapElements = [];
    let areDevicesReady: Subject<boolean>[] = [];
    let devicesNumber: number = 0;
    if (this.parentDevicesList && this.parentDevicesList.length > 0) {
      for (let parent of this.parentDevicesList) {
        if (parent.Childs?.length > 0) {
          for (let child of parent.Childs) {
            if (child.Model.Type.Name === 'people-counter') {
              let deviceEventRequest: DeviceEventRequest = { DeviceId: child.Id }
              if (!firstCall) {
                deviceEventRequest.End = Math.round(Date.now() / 1000),
                  deviceEventRequest.Start = deviceEventRequest.End - (3600);
              }
              const tempDevicePosition: number = devicesNumber;
              areDevicesReady.push(new Subject<boolean>());   //ogni device attinente ha una Subject corrispondente che segnalerà la presenza di dati
              devicesNumber++;
              let temporaryLAT: number;
              let temporaryLNG: number;
              if (child.Latitude === 0 && child.Longitude === 0) {
                temporaryLAT = parent.Latitude;
                temporaryLNG = parent.Longitude;
              } else {
                temporaryLAT = child.Latitude;
                temporaryLNG = child.Longitude;
              }
              this.apiService.getDeviceEvents(deviceEventRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.resetAllData();

                if (res && res.Devices?.length > 0) {
                  this.devicePCEvents = res.Devices[0].Events.filter((event: DeviceEventsEvent) =>
                    event.Body?.Sensors?.some(
                      bodySensor => ['Limit', 'Value'].every((field: string) => Object.keys(bodySensor).includes(field))
                    )
                  );
                }

                if(this.devicePCEvents.length > 0) {
                  let eventBody = this.devicePCEvents[0].Body;
                  let myPcHeatmapElement: PeopleCounterHeatmapElement = {
                    lat: temporaryLAT,
                    lng: temporaryLNG,
                    value: +eventBody.Sensors[0].Value
                  }
                  this.peopleCounterMaxValue = +eventBody.Sensors[0].Limit;
                  this.pcHeatmapElements.push(myPcHeatmapElement);
                  areDevicesReady[tempDevicePosition].next(true);   //Subject corrente segnala presenza di dati

                }
                else {
                  areDevicesReady[tempDevicePosition].next(false);  //Subject corrente segnala assenza di dati
                }
                areDevicesReady[tempDevicePosition].complete();     //Subject corrente va in complete
              });
            }
          }
        }
      }
    }
    //al complete delle Subject verrà creato un observable di boolean[] (grazie alla forkJoin) e sottoscritto per accedere ai singoli boolean
    forkJoin(areDevicesReady).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data.includes(true)) {
        this.showMap();
        this.lastUpdate = Math.round(Date.now() / 1000);
        this.noDataIn24h = false;
      }
      else {
        if (firstCall) {
          this.noDataIn24h = true;
        }
      }
    })
  }

  private resetAllData() {
    this.devicePCEvents = [];
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.PEOPLECOUNTER_WELCOME',
      'INTRO.SEARCH_DATE'
    ])
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
      this.introJS
      .setOptions({
        steps: [
          {
            title: 'Welcome',
            intro: intros['INTRO.PEOPLECOUNTER_WELCOME']
          },
          {
            title: 'Search bar',
            element: '#intro-people-counter-search',
            intro: intros['INTRO.SEARCH_DATE'],
            position: 'right'
          }
        ],
        showProgress: true
      })
      .start();
    });
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.pcHeatmapElements.length === 0 && this.devicePCEvents.length === 0) {
      this.noDataInDate = false;
      this.chartReadyToShow = false;
      this.readyToShow = false;
      this.isChecked = false;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event));
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find((event: EventLatest) => event.Level === 'Info' && this.isAProperEvent(event));
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private isAProperEvent(event: DeviceEventsEvent | EventLatest): boolean {
    let eventBody = event.Body;
    return eventBody?.Sensors?.length > 0 && eventBody?.Sensors?.every(
      bodySensor => ['Limit', 'Value'].every((field: string) => Object.keys(bodySensor).includes(field))
    )
  }

  public reformatCreated(createdToFormat: string): number {
    let currentDate = new Date(createdToFormat);
    return Math.ceil(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).getTime() / 1000 + 1);
  }

  public goToDeviceEvents(): void {
    if (this.singlePeopleCounterDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.singlePeopleCounterDevice.Id, BackRoute: 'people-counter' });
      this.router.navigate(['main/device-events']);
    }
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public goToMain(): void {
    let currentUrl: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
    this.mainService.updateAllDataAndGoToMainComand();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
