import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddRoleRequest } from '../../models/administrator/addRoleRequest';

@Component({
  selector: 'urban-add-role-dialog',
  templateUrl: './add-role-dialog.component.html',
  styleUrls: ['./add-role-dialog.component.scss']
})
export class AddRoleDialogComponent implements OnInit {

  private createModel: AddRoleRequest;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<AddRoleDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
  }

  addition() {
    if (this.form.valid) {
      this.createModel = {
        Name: this.form.controls.name.value
      }
      this.dialogRef.close(this.createModel)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
