import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConsumerDialogComponent } from 'src/app/shared/dialogs/consumer-dialog/consumer-dialog.component';
import { AdvertisingType, Consumer } from 'src/app/shared/models/advertising/advertising';
import { AddConsumerRequest, UpdateConsumerRequest } from 'src/app/shared/models/advertising/consumerRequest';
import { GetConsumersResponse } from 'src/app/shared/models/advertising/consumerResponse';
import { UserRoles } from 'src/app/shared/models/userRoles';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-consumer-list',
  templateUrl: './consumer-list.component.html',
  styleUrls: ['./consumer-list.component.scss']
})
export class ConsumerListComponent implements OnInit, OnDestroy {
  typeColumns = ['Name', 'Description', 'Detail'];
  public consumers: Consumer[] = [];
  public filteredData: Consumer[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public userRoles: UserRoles['Roles'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
      this.apiService.getConsumers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res && res.Items?.length > 0) {
          this.consumers = res.Items;
          this.filteredData = this.consumers;
        }
      });
    });
  }

  public addConsumer(): void {
    const addConsumerDialogRef = this.dialog.open(ConsumerDialogComponent);

    addConsumerDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newConsumer => {
      if (newConsumer) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let addConsumerRequest: AddConsumerRequest = {
              Name: newConsumer.Name,
              Description: newConsumer.Description
            };

            this.apiService.addConsumer(addConsumerRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.apiService.getConsumers().pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res: GetConsumersResponse) => {
                  if (res?.Items?.length > 0) {
                    this.consumers = res.Items;
                    this.filteredData = this.consumers;
                  }
                });
            });
          }
        });
      }
    });
  }

  public goToDetail(consumerId: string): void {
    this.mainService.setNavigationInfoComand({ Id: consumerId, BackRoute: 'consumer-list' });
    this.router.navigate(['main/consumer-detail']);
  }

  public deleteConsumer(consumer: Consumer): void {
    if (!consumer || consumer === undefined) {
      return;
    }

    const deleteConsumerConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConsumerConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.deleteConsumer(consumer.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.apiService.getConsumers().pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: GetConsumersResponse) => {
              if (res?.Items?.length > 0) {
                this.consumers = res.Items;
                this.filteredData = this.consumers;
              }
            });
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.consumers?.filter((consumer: Consumer) =>
      [
        consumer.Name?.toLowerCase(),
        consumer.Description?.toLowerCase()
      ].some(x => x?.includes(filterValue.toLowerCase()))
    );

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
