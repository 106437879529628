<h1 *ngIf="this.isAdd" mat-dialog-title>{{ 'GENERAL.ADD' | translate }}</h1>
<h1 *ngIf="!this.isAdd" mat-dialog-title>{{ 'GENERAL.UPDATE' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.LABEL' | translate }}</mat-label>
            <input matInput formControlName="label" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.ISVISIBLE' | translate }}</mat-label>
            <mat-select formControlName="isVisible">
                <mat-option value="1">
                    {{ 'GENERAL.YES' | translate }}
                </mat-option>
                <mat-option value="0">
                    {{ 'GENERAL.NO' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.ORDER' | translate }}</mat-label>
            <input matInput type="number" formControlName="order" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'DEVICE_TYPE.ACTION_COMPLEXITY' | translate }}</mat-label>
            <mat-select (selectionChange)="changeActionType()" formControlName="complexAction">
                <mat-option value="false">{{ 'DEVICE_TYPE.SINGLE_ACTION' | translate }}
                </mat-option>
                <mat-option value="true">{{ 'DEVICE_TYPE.COMPLEX_ACTION' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="!this.complexAction">
            <mat-form-field>
                <mat-label>{{ 'GENERAL.ACTION' | translate }}</mat-label>
                <input matInput formControlName="action" autocomplete="off">
            </mat-form-field>
        </div>

        <div *ngIf="this.complexAction">
            <mat-form-field>
                <mat-label>{{ 'GENERAL.URL' | translate }}</mat-label>
                <input matInput formControlName="path" autocomplete="off">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'GENERAL.PARAMETERS' | translate }}</mat-label>
                <input matInput formControlName="parameters" autocomplete="off">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'GENERAL.ICON' | translate }}</mat-label>
                <input matInput formControlName="capabilityIcon" autocomplete="off">
            </mat-form-field>
            <mat-form-field class="bodyArea">
                <mat-label>{{ 'GENERAL.BODY' | translate }}</mat-label>
                <textarea class="tableEvidenceBG" matInput formControlName="body"
                          matTextareaAutosize matAutosizeMinRows=15 matAutosizeMaxRows=30></textarea>
            </mat-form-field>
        </div>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button *ngIf="this.isAdd" type="submit" class="btn actionButton" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button *ngIf="!this.isAdd" type="submit" class="btn actionButton" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
