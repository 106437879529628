<div class="container-fluid py-3" [ngClass]="nested ? 'panelBG' : ''">
    <span class="title subheadText d-block">{{ chartTitle | translate }}{{ chartSubtitle ? ' - ' + (chartSubtitle | translate) : '' }}</span>
    <div class="d-flex chartInfo">
        <div>
            <div class="d-flex align-items-start">
                <p>{{ legend.Transparent | translate }}</p>
                <div class="opacityRectangle ml-3"></div>
            </div>
            <div class="d-flex align-items-start">
                <p>{{ legend.Opaque | translate }}</p>
                <div class="opacityRectangle2 ml-3"></div>
            </div>
        </div>
        <div class="ml-4">
            <div class="d-flex align-items-start">
                <p>{{ legend.Thin | translate }} {{ selectedType && legend.TypePrefix ? (legend.TypePrefix+(selectedType.toUpperCase()) | translate).toLowerCase() : '' }}</p>
                <div class="minAmountLine ml-3"></div>
            </div>
            <div class="d-flex align-items-start">
                <p>{{ legend.Thick | translate }} {{ selectedType && legend.TypePrefix ? (legend.TypePrefix+(selectedType.toUpperCase()) | translate).toLowerCase() : '' }}</p>
                <div class="opacityRectangle2 ml-3"></div>
            </div>
        </div>
    </div>
    <div class="panelBG" [ngClass]="nested ? '' : 'parent'">
        <div #pathsContainer class="pathsChartContainer">
            <svg *ngFor="let direction of arrowsFormatted; let i = index;" xmlns="http://www.w3.org/2000/svg"
                version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs"
                [attr.viewBox]="'0 0 ' + pathsContainer.offsetWidth + ' ' + pathsContainer.offsetHeight">
                <g [attr.stroke-width]="direction.Amount" stroke="#5352E4" fill="none" stroke-linecap="round"
                    stroke-linejoin="round" [attr.opacity]="direction.Importance">
                    <line [attr.x1]="direction.PositionA.X + '%'" [attr.y1]="direction.PositionA.Y + '%'"
                        [attr.x2]="direction.PositionB.X + '%'" [attr.y2]="direction.PositionB.Y + '%'"
                        [attr.marker-start]="'url(#SvgjsMarker9880'+i+')'"
                        [attr.marker-end]="'url(#SvgjsMarker9881' +i+')'">
                    </line>
                </g>
                <defs>
                    <marker *ngIf="direction.OneWay === false" [attr.markerWidth]="direction.Amount >= 3 ? 3 : 11" [attr.markerHeight]="direction.Amount >= 3 ? 3 : 11" refX="2" refY="3" viewBox="0 0 6 6" orient="auto" [attr.id]="'SvgjsMarker9880' + i">
                        <polygon points="0,3 2,1 2,5" fill="#5352E4"></polygon>
                    </marker>
                    <marker [attr.markerWidth]="direction.Amount >= 3 ? 3 : 11" [attr.markerHeight]="direction.Amount >= 3 ? 3 : 11" refX="4" refY="3" viewBox="0 0 6 6" orient="auto" [attr.id]="'SvgjsMarker9881' + i">
                        <polygon points="4,5 4,1 6,3" fill="#5352E4"></polygon>
                    </marker>
                </defs>
            </svg>
        </div>
    </div>

    <!-- <div *ngIf="noDataOnChart" class="my-3">
        <p *ngIf="noDataOnChart">{{ 'STATISTICS.NO_CHART_DATA' | translate }}</p>
    </div> -->
</div>
