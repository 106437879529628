<h1 mat-dialog-title>{{ this.deviceType }}</h1>
<mat-dialog-content class="position-relative" style="overflow: hidden;">
    <google-map width="100%" [height]="900">
        <map-marker #marker="mapMarker" *ngFor="let myMarker of markers; let i = index" [position]="myMarker.position"
            [options]="myMarker.options" [label]="myMarker.label" [title]="myMarker.title"
            (mapClick)="openInfoWindow(marker, i, myMarker.info)">
    
            <map-info-window>
                <div class="darkText text-center p-2">
                    <a href="javascript:void(0);" (click)="openInfo(myMarker.info)" style="font-size: large;">{{
                        myMarker.title }}</a>
                    <hr class="mb-3 mt-2">
                    <span *ngFor="let child of myMarker.childs" class="urbanicon-{{ child.Icon }} pl-2 pr-2"
                        style="font-size: large;">{{ child.Elements }}</span>
                    <mat-icon *ngIf="myMarker.childs.length == 0">block</mat-icon>
                </div>
            </map-info-window>
        </map-marker>
    </google-map>

</mat-dialog-content>
