import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddDomainRequest } from '../../models/administrator/addDomainRequest';
import { Domain } from '../../models/domain';
import {DomainVisibility} from "../../models/domainVisibility";

@Component({
  selector: 'urban-add-domain-dialog',
  templateUrl: './add-domain-dialog.component.html',
  styleUrls: ['./add-domain-dialog.component.scss']
})
export class AddDomainDialogComponent implements OnInit {

  private createModel: AddDomainRequest;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    parentSelected: [''],
    visibilitySelected: [{ value: '', disabled: false }, Validators.required]
  });

  public parents: Domain[] = [];
  public domainVisibilityItems: DomainVisibility[] = [];

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<AddDomainDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { parents: Domain[], domainVisibilityItems: DomainVisibility[] }
  ) { }

  ngOnInit(): void {
    this.parents = this.data.parents;
    this.domainVisibilityItems = this.data.domainVisibilityItems;
  }

  addition() {
    if (this.form.valid) {

      this.createModel = {
        Name: this.form.controls.name.value,
        Parent: this.form.controls.parentSelected.value,
        Visibility: +this.form.controls.visibilitySelected.value
      }

      this.dialogRef.close(this.createModel)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
