import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TraceItem } from '../../models/traceListElements';

@Component({
  selector: 'urban-platform-statistics-detail-dialog',
  templateUrl: './platform-statistics-detail-dialog.component.html',
  styleUrls: ['./platform-statistics-detail-dialog.component.scss']
})
export class PlatformStatisticsDetailDialogComponent implements OnInit {

  highlightSection: string;
  myTraceItem: TraceItem = {
    Created: null,
    Class: '',
    Method: '',
    Hostname: '',
    ClientIp: '',
    SessionId: '',
    Username: '',
    Domain: '',
    Resource: '',
    Message: '',
    Error: '',
    Millis: null
  };
  showError: boolean = false;
  showMessage: boolean = false;


  public detailGroup: UntypedFormGroup = this.formBuilder.group({
    hostname: [{ value: '', disabled: false }],
    resource: [{ value: '', disabled: false }],
    created: [{ value: '', disabled: false }],
    millis: [{ value: '', disabled: false }],
    domain: [{ value: '', disabled: false }],
    class: [{ value: '', disabled: false }],
    clientIp: [{ value: '', disabled: false }],
    sessionId: [{ value: '', disabled: false }],
    method: [{ value: '', disabled: false }],
    username: [{ value: '', disabled: false }]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<PlatformStatisticsDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {targetTrace: TraceItem, highlight: string}
    ) {
      this.highlightSection = data.highlight;
      this.myTraceItem = data.targetTrace;

    }

  ngOnInit(): void {
      if(this.myTraceItem.Hostname) this.detailGroup.controls.hostname.setValue(this.myTraceItem.Hostname);
      if(this.myTraceItem.Resource) this.detailGroup.controls.resource.setValue(this.myTraceItem.Resource);
      if(this.myTraceItem.Created) this.detailGroup.controls.created.setValue(this.datePipe.transform(this.myTraceItem.Created*1000, 'short'));
      if(this.myTraceItem.Millis) this.detailGroup.controls.millis.setValue(this.myTraceItem.Millis);
      if(this.myTraceItem.Domain) this.detailGroup.controls.domain.setValue(this.myTraceItem.Domain);
      if(this.myTraceItem.Class) this.detailGroup.controls.class.setValue(this.myTraceItem.Class);
      if(this.myTraceItem.ClientIp) this.detailGroup.controls.clientIp.setValue(this.myTraceItem.ClientIp);
      if(this.myTraceItem.SessionId) this.detailGroup.controls.sessionId.setValue(this.myTraceItem.SessionId);
      if(this.myTraceItem.Method) this.detailGroup.controls.method.setValue(this.myTraceItem.Method);
      if(this.myTraceItem.Username) this.detailGroup.controls.username.setValue(this.myTraceItem.Username);

      if (this.highlightSection === 'error') {
        this.showError = true;
      } else if (this.highlightSection === 'message') {
        this.showMessage = true;
      }
  }

  toggleErrorSection(): void {
    this.showError = !this.showError;
  }

  toggleMessageSection(): void {
    this.showMessage = !this.showMessage;
  }


}
