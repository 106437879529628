<div class="h-100 d-flex flex-column">
    <div class="flex-grow-1 marginTop74">
        <google-map *ngIf="!refreshMap" [height]="passedHeight === 0 ? '100%' : (passedHeight ? passedHeight : 750)"
            width="100%" [options]="mapOptions" class="test" (boundsChanged)="saveBounds()">
            <map-marker
                #marker="mapMarker" *ngFor="let myMarker of markers;" [position]="myMarker.position"
                [options]="myMarker.options" [label]="myMarker.label" [title]="myMarker.title">
            </map-marker>

            <map-circle *ngFor="let circle of circles; let i = index" [options]="circle.Options" (circleClick)="openInfoWindow(i)">
                <map-info-window>
                    <div class="d-flex flex-column darkText text-left p-2">
                        <a href="javascript:void(0);" (click)="openInfo(circle.Id)" class="substrongText">
                            {{ circle.Name }}</a>
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <span class="title3">{{ circle.Options.radius }}m</span>
                            <span class="captionText tertiaryTextColor marginLeft10">
                                {{ 'WIFI_WIDGET.RANGE' | translate }}</span>
                        </div>
                    </div>
                </map-info-window>
            </map-circle>
        </google-map>
    </div>

    <div *ngIf="refreshMap" style="width:'100%';" [ngStyle]="{'height:' : passedHeight ? passedHeight : '750px' }"></div>
</div>
