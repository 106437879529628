import { PassDataService } from './../../services/pass-data/pass-data.service';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AroundMeDialogComponent } from '../../dialogs/around-me-dialog/around-me-dialog.component';
import { Device } from '../../models/device';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'urban-around-me-widget',
  templateUrl: './around-me-widget.component.html',
  styleUrls: ['./around-me-widget.component.scss']
})
export class AroundMeWidgetComponent implements OnInit, OnDestroy {
  private currentPosition: {lat: number, lng: number} = null;
  public typePosition = null;
  public devices: Device[] | null = null;
  public verticalAlign: boolean = false;
  public errorGeolocation = '';
  public mapReady: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input('darkModeStatus') public isDarkActive: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 435) {
      this.verticalAlign = false;
    } else this.verticalAlign = true;
  }

  constructor (
    public dialog: MatDialog,
    public loaderService: LoaderService,
    private passDataService: PassDataService
  ) {
    if (window.innerWidth > 435) {
      this.verticalAlign = false;
    } else this.verticalAlign = true;
  }

  ngOnInit(): void {
    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.mapReady = mapLoading;
    });
  }

  public updatePositionType(type?: string): void {
    if (type) this.typePosition = type;
    this.openAroundMeDialog();
  }

  public getPosition(): Promise<{lng: number, lat: number}> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }


  public openAroundMeDialog(): void {
    this.loaderService.show()
    this.getPosition()
    .then(pos => {
      this.errorGeolocation = '';
      this.currentPosition = {
        lat: pos.lat,
        lng: pos.lng
      }
      const dialogRef = this.dialog.open(AroundMeDialogComponent, {
        data: {
          positionType: this.typePosition,
          lat: this.typePosition === 'current' ? this.currentPosition.lat : 0,
          lng: this.typePosition === 'current' ? this.currentPosition.lng : 0,
        },
        width: '50%',
        minWidth: 300,
        maxWidth: 400
      });

      dialogRef.afterOpened().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        () => setTimeout(() => {
          this.loaderService.hide()
        }, 500)
      )

      dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(devicesFinded => {
        this.devices = null;
        if (
          devicesFinded &&
          devicesFinded !== 'Momentaneamente il server non risponde. Riprova più tardi.' &&
          devicesFinded !== 'cancel' &&
          devicesFinded.length > 0
        ) {
          this.devices = devicesFinded;
        } else {
          if (devicesFinded?.length === 0) {
            this.errorGeolocation ='ERROR_NO_FOUND';
          }
        }
      });
    })
    .catch(err => {
        setTimeout(() => {
          this.loaderService.hide()
          this.errorGeolocation = 'ERROR_NO_POSITION';
        }, 500)
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
