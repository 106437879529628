import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { News } from '../../models/news';

@Component({
  selector: 'urban-news-dialog',
  templateUrl: './news-dialog.component.html',
  styleUrls: ['./news-dialog.component.scss']
})
export class NewsDialogComponent implements OnInit {

  private maxFileSize : number;
  public error: string | null;
  public imageError: string | null;
  private news: News;
  public isAdd: boolean;
  public form: UntypedFormGroup = this.formBuilder.group({
    title: [{ value: '', disabled: false }, Validators.required],
    source: [{ value: '', disabled: false }, Validators.required],
    date: [{ value: new Date().toString(), disabled: false }, Validators.required],
    body: [{ value: '', disabled: false }, Validators.required],
    image: [{ value: '', disabled: false }]
  });
  constructor(
    public dialogRef: MatDialogRef<NewsDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        currentNews: News,
        seeMore: boolean,
        maxFileSize: number
      }
  ) { }

  ngOnInit(): void {
    this.news = this.data.currentNews;
    if (this.news && this.news !== undefined) {
      this.form.controls.title.setValue(this.news.Title);
      this.form.controls.date.setValue(new Date(this.news.Date * 1000));
      this.form.controls.source.setValue(this.news.Source);
      this.form.controls.body.setValue(this.news.Body);
      this.form.controls.image.setValue(this.news.Image);
    }
    this.isAdd = !this.form.valid;
    this.maxFileSize = this.data.maxFileSize;
  }

  public addition() {
    if (this.form.valid) {
      this.news = {
        Title: this.form.controls.title.value,
        Source: this.form.controls.source.value,
        Date: Math.floor(+this.form.controls.date.value / 1000),
        Body: this.form.controls.body.value,
        Image: this.form.controls.image.value,
        Id: this.news && this.news !== undefined ? this.news.Id : '',
        Creator: this.news && this.news !== undefined ? this.news.Creator : '',
        Created: this.news && this.news !== undefined ? this.news.Created : 0,
        DomainId: this.news && this.news !== undefined ? this.news.DomainId : ''
      }
      this.dialogRef.close(this.news)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }
  public setDate(date: Date): number {
    return +date;
  }

  imageUpload(event: any): void {
    if (event.target.files[0].size > this.maxFileSize) {
      this.imageError = 'IMAGE_SIZE_MAX';
    }
    else{
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (myTarget) => {
        let image = new Image();
        image.src = myTarget.target.result.toString();
        image.onload = () => {
          let imageBaseData = reader.result;
          this.form.controls.image.setValue(imageBaseData);
        }
      };
      reader.onerror = function (error) {
        console.log("Error FileReader: ", error);
      };
    }
  }

  removeImage(): void {
    this.form.controls.image.setValue('');
  }

  public cancel() {
    this.dialogRef.close();
  }

}
