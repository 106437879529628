<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'PEOPLE_PASSAGE.TITLE' | translate }}
        </span>
    </div>

    <urban-searchbar id="intro-people-passage-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div *ngIf="eventsMapped?.length > 0 && translationsReady" class="d-flex flex-row justify-content-start align-items-start">
        <div *ngIf="legendColors && legendColors.In !== undefined && legendColors.Out !== undefined && legendColors.Total !== undefined"
            class="d-flex flex-column chartInfo">
            <div *ngFor="let legendKey of legendKeys" class="d-flex align-items-start mb-4">
                <div class="legendRectangle" [ngStyle]="{'background-color': legendColors[legendKey]}"></div>
                <div class="d-flex flex-column">
                    <span class="paragraphText">{{ ('IMX_CAMERA_WIDGET.' + legendKey.toUpperCase()) | translate }}</span>
                    <span class="substrongText">{{ allCamerasPassage[legendKey] }}</span>
                </div>
            </div>
        </div>

        <urban-google-columnchart id="peoplePassage-content"
            class="appearing flex-grow-1 mt-4" [multipleColumns]="true" [thinColumns]="true" columnsTitle="IMX_CAMERA_WIDGET.IMX_CAMERAS"
            [multiValuesDescription]="dataDescriptions" [passedChartMultiData]="totalPeoplePassages" [isLimitedBelow]="false"
            [darkThemeActive]="isDarkActive" [heightPercent]="100" [automaticPadding]="false" [chartMarginTop]="20">
        </urban-google-columnchart>
    </div>

    <button type="button" class="btn outlineActionButton" *ngIf="this.hasElements()" (click)="downloadSelection()">
        {{ 'GENERAL.EXPORT_SELECTION' | translate }}
    </button>

    <div *ngIf="eventsMapped?.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <div>
            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
            <div class="d-flex noDataActions">
                <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div>
    <urban-loader [localized]="true" [onContent]="true" [disappearCondition]="loadingData === false && translationsReady">
    </urban-loader>
</div>
