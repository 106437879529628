import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuthStore from './auth.reducer';

const authSelector = createFeatureSelector<fromAuthStore.AuthState>(fromAuthStore.authFeatureKey);

export const error = createSelector(authSelector, fromAuthStore.selectError);
export const getUser = createSelector(authSelector, fromAuthStore.selectUser);
export const getDomains = createSelector(authSelector, fromAuthStore.selectAvailablesDomains);
export const getDomain = createSelector(authSelector, fromAuthStore.selectDomain);
export const getUserRoles = createSelector(authSelector, fromAuthStore.selectUserRoles);
export const getUserProperties = createSelector(authSelector, fromAuthStore.selectUserProperties);
export const getDarkThemeStatus = createSelector(authSelector, fromAuthStore.selectDarkThemeStatus);
export const getCurrentDomainLogo = createSelector(authSelector, fromAuthStore.selectCurrentDomainLogo);
export const getCurrentDomainProperties = createSelector(authSelector, fromAuthStore.selectCurrentDomainProperties);
export const getCurrentJwtToken = createSelector(authSelector, fromAuthStore.selectJwtToken);
export const isAuth = createSelector(authSelector, fromAuthStore.isAuth);
