<h1 mat-dialog-title>{{ 'CONFIRMATION.CONFIRM' | translate }}</h1>
<mat-dialog-content>
    <p>{{ 'CONFIRMATION.CONTINUE' | translate }}</p>
    <p *ngIf="confirmMessage">{{ '' + confirmMessage +'' | translate }}</p>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="continue()">{{ 'CONFIRMATION.YES' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'CONFIRMATION.NO' | translate }}</button>
</div>

