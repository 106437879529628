import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddAuthorizationRequest } from '../../models/addAuthorizationRequest';
import { Domain } from '../../models/domain';
import { User } from '../../models/loginResponse';
import { Role } from '../../models/userRoles';

@Component({
  selector: 'urban-add-authorization-user-dialog',
  templateUrl: './add-authorization-user-dialog.component.html',
  styleUrls: ['./add-authorization-user-dialog.component.scss']
})
export class AddAuthorizationUserDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    roleSelected: [{ value: '', disabled: false }, Validators.required]
  });

  public domain: Domain;
  public roles: Role[] = [];
  public currentUser: User;
  private authorizeRequest: AddAuthorizationRequest;
  public error: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<AddAuthorizationUserDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        user: User,
        domain: Domain,
        roles: Role[]
      }) { }

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.domain = this.data.domain;
    this.roles = this.data.roles;
  }

  add() {
    if (this.form.valid) {

      this.authorizeRequest = {
        User: this.currentUser.Id,
        Role: this.form.controls.roleSelected.value
      }

      this.dialogRef.close(this.authorizeRequest)
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
