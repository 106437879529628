import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'urban-alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss']
})
export class AlertPanelComponent implements OnInit {
  @Input('boldPrefix') public boldPrefix: string = '';
  @Input('titleText') public titleText: string = '';
  @Input('descriptionText') public descriptionText: string = '';
  @Input('alertType') public type: 'error' | 'warning' | 'info';
  @Input('buttonLabel') public buttonLabel: string;
  @Input('overlayPanel') public overlayPanel: boolean = false;

  @Output('buttonAction') private actionEvent = new EventEmitter();
  @Output('close') private closeEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public execAction(): void {
    this.actionEvent.emit();
  }

  public closePanel(): void {
    this.closeEvent.emit();
  }
}
