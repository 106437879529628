import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SerializationHelper } from '../../../serializationHelper';
import { DeviceGroupCapability } from '../../models/deviceGroupCapability';
import { DeviceType } from '../../models/deviceType';
import { DeviceTypeCapability } from '../../models/deviceTypeCapability';

@Component({
  selector: 'urban-device-type-capability-dialog',
  templateUrl: './device-type-capability-dialog.component.html',
  styleUrls: ['./device-type-capability-dialog.component.scss']
})
export class DeviceTypeCapabilityDialogComponent implements OnInit {

  public deviceType: DeviceType;
  public deviceTypeCapability: DeviceTypeCapability;
  public complexAction: boolean = false;
  public deviceGroupCapability: DeviceGroupCapability;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    label: [{ value: '', disabled: false }, Validators.required],
    action: [{ value: '', disabled: false }],
    order: [{ value: '', disabled: false }, Validators.required],
    complexAction: [{ value: 'false', disabled: false }, Validators.required],
    path: [{ value: '', disabled: false }],
    body: [{ value: '', disabled: false }],
    parameters: [{ value: '', disabled: false }],
    capabilityIcon: [{ value: '', disabled: false }],
    isVisible: [{ value: true, disabled: false }]
  });

  public error: string | null;

  public isAdd: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DeviceTypeCapabilityDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { type: DeviceType, capability: DeviceTypeCapability }
  ) {
    if (this.data && this.data !== undefined && this.data.type && this.data.type !== undefined) {
      this.deviceType = this.data.type;

      if (this.data.capability && this.data.capability !== undefined) {
        this.deviceTypeCapability = this.data.capability;
        this.isAdd = false;
      }
    }
  }

  ngOnInit(): void {
    if (this.deviceTypeCapability && this.deviceTypeCapability !== undefined) {
      this.deviceGroupCapability = this.deviceTypeCapability.Action.startsWith('{') ? SerializationHelper.toInstance(this.deviceTypeCapability.Action) : undefined;

      if (this.deviceGroupCapability === undefined) {

        this.form = this.formBuilder.group({
          name: [{ value: this.deviceTypeCapability.Name, disabled: false }, Validators.required],
          label: [{ value: this.deviceTypeCapability.Label, disabled: false }, Validators.required],
          action: [{ value: this.deviceTypeCapability.Action, disabled: false }, Validators.required],
          order: [{ value: this.deviceTypeCapability.Order, disabled: false }, Validators.required],
          complexAction: [{ value: 'false', disabled: false }, Validators.required],
          path: [{ value: '', disabled: false }],
          body: [{ value: '', disabled: false }],
          parameters: [{ value: '', disabled: false }],
          capabilityIcon: [{ value: '', disabled: false }],
          isVisible: [{ value: "1", disabled: false }]
        });
      }
      else {

        this.form = this.formBuilder.group({
          name: [{ value: this.deviceTypeCapability.Name, disabled: false }, Validators.required],
          label: [{ value: this.deviceTypeCapability.Label, disabled: false }, Validators.required],
          action: [{ value: '', disabled: false }],
          order: [{ value: this.deviceTypeCapability.Order, disabled: false }, Validators.required],
          complexAction: [{ value: 'true', disabled: false }, Validators.required],
          path: [{ value: this.deviceGroupCapability.Path, disabled: false }, Validators.required],
          body: [{ value: this.deviceGroupCapability.Body, disabled: false }],
          parameters: [{ value: this.deviceGroupCapability.Parameters, disabled: false }],
          capabilityIcon: [{ value: this.deviceGroupCapability.Icon, disabled: false }],
          isVisible: [{ value: this.deviceTypeCapability.IsVisible ? "1" : "0", disabled: false }]
        });

        this.complexAction = true;
      }

      this.form.controls.order.setValue(this.deviceTypeCapability.Order);
    }
  }

  addition() {
    if (this.form.valid) {

      let action: string = '';

      if (this.complexAction) {
        this.deviceGroupCapability = {
          Path: this.form.controls.path.value,
          Body: this.form.controls.body.value,
          Parameters: this.form.controls.parameters.value,
          Icon: this.form.controls.capabilityIcon.value
        }

        action = JSON.stringify(this.deviceGroupCapability);
      }

      this.deviceTypeCapability = {
        Id: !this.isAdd ? this.deviceTypeCapability.Id : '',
        Name: this.form.controls.name.value,
        Label: this.form.controls.label.value,
        Action: action !== '' && this.complexAction ? action : this.form.controls.action.value,
        Order: +this.form.controls.order.value,
        DeviceTypeId: this.deviceType.Id,
        IsVisible: this.form.controls.isVisible.value == "1"
      }

      this.dialogRef.close(this.deviceTypeCapability)
    }
    else {
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      this.error = "ERROR_EMPTY " + invalid.join(' ');
    }
  }

  changeActionType(): void {

    let action = this.form.controls.complexAction.value;

    if (this.complexAction && action === 'false') {
      this.complexAction = false;

      this.form = this.formBuilder.group({
        name: [{ value: this.form.controls.name.value, disabled: false }, Validators.required],
        label: [{ value: this.form.controls.label.value, disabled: false }, Validators.required],
        action: [{ value: this.form.controls.action.value, disabled: false }, Validators.required],
        order: [{ value: +this.form.controls.order.value, disabled: false }, Validators.required],
        complexAction: [{ value: 'false', disabled: false }, Validators.required],
        path: [{ value: '', disabled: false }],
        body: [{ value: '', disabled: false }],
        parameters: [{ value: '', disabled: false }],
        capabilityIcon: [{ value: '', disabled: false }],
        isVisible: [{ value: this.form.controls.isVisible.value, disabled: false }]
      });
    }
    else {
      if (this.deviceGroupCapability && this.deviceGroupCapability !== undefined && this.deviceGroupCapability !== null) {
        this.deviceGroupCapability = SerializationHelper.toInstance(this.deviceTypeCapability.Action);
      }
      else {
        this.deviceGroupCapability = undefined;
      }

      this.form = this.formBuilder.group({
        name: [{ value: this.form.controls.name.value, disabled: false }, Validators.required],
        label: [{ value: this.form.controls.label.value, disabled: false }, Validators.required],
        action: [{ value: '', disabled: false }],
        order: [{ value: +this.form.controls.order.value, disabled: false }, Validators.required],
        complexAction: [{ value: 'true', disabled: false }, Validators.required],
        path: [{ value: this.deviceGroupCapability !== undefined ? this.deviceGroupCapability.Path : '', disabled: false }, Validators.required],
        body: [{ value: this.deviceGroupCapability !== undefined ? this.deviceGroupCapability.Body : '', disabled: false }],
        parameters: [{ value: this.deviceGroupCapability !== undefined ? this.deviceGroupCapability.Parameters : '', disabled: false }],
        capabilityIcon: [{ value: this.deviceGroupCapability !== undefined ? this.deviceGroupCapability.Icon : '', disabled: false }],
        isVisible: [{ value: this.form.controls.isVisible.value, disabled: false }]
      });

      this.complexAction = true;
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
