<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span *ngIf="trashBinDevice" class="title2 appearingTextLeft">
                {{ this.trashBinDevice.Name + ' -' }}
            </span>
            <span class="title2">{{ 'GENERAL.DETAIL' | translate }}</span>
        </div>
    </div>

    <urban-searchbar id="intro-trash-bin-detail-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingEventsData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
            [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
            (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <mat-tab-group id="intro-trash-bin-detail-tab" animationDuration="0ms" dynamicHeight="true">
        <mat-tab [label]="'GENERAL.STATUS' | translate">
            <div class="tabContent" *ngIf="trashBinDataSource">
                <div *ngIf="trashBinDataSource.data.length > 0" class="customTable">
                    <table id="intro-trash-bin-detail-table" class="w-100 h-100 noTableHover" mat-table [dataSource]="trashBinDataSource">
                        <ng-container matColumnDef="Read">
                            <th mat-header-cell *matHeaderCellDef>
                                {{'GENERAL.DATE' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                {{ (item.Read * 1000) | date : 'short' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Percentage">
                            <th mat-header-cell *matHeaderCellDef>
                                {{'TRASH_BIN.PERCENTAGE' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item">
                                {{ item.Percentage }}%
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>

                <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="trashBinDataSource.data.length === 0">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <div>
                        <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex noDataActions">
                            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
            <div *ngIf="chartsTab.isActive" class="tabContent">
                <div *ngIf="chartReady && trashBinEvents?.length > 0" class="roundedPanel panelBG chartContainer">
                    <urban-google-areachart areaChartTitle="TRASH_BIN.AREA_CHART_TITLE"
                        [passedElementsDescription]="[trashBinDevice.Name]" [passedDataList]="historyDataToPass"
                        [darkThemeActive]="isDarkActive" [limitValue]="fillMaxValue">
                    </urban-google-areachart>
                </div>

                <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="trashBinEvents?.length === 0">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <div>
                        <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex noDataActions">
                            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
