import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SMSSDomain } from '../../../../shared/models/smss/smssDomain';
import { SMSSGateway } from '../../../../shared/models/smss/smssGateway';
import { SMSSWpTemplate } from '../../../../shared/models/smss/smssWpTemplate';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { WifiSmssService } from '../../../../shared/services/wifi-smss.service';

@Component({
  selector: 'urban-wptemplate',
  templateUrl: './wptemplate.component.html',
  styleUrls: ['./wptemplate.component.scss']
})
export class WptemplateComponent implements OnInit, OnDestroy {

  public gateway: SMSSGateway | null = null;

  public wpTemplate: SMSSWpTemplate;

  public domain: SMSSDomain;
  public imageBaseData: string | ArrayBuffer = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isAdd: boolean = true;
  public error: string | null;
  private wpTemplateId: string | null;

  public form: UntypedFormGroup = this.formBuilder.group({
    id: [{ value: '', disabled: true }],
    CreationDate: [{ value: '', disabled: true }],
    DomainID: [{ value: '', disabled: true }, Validators.required],
    GatewayID: [{ value: '', disabled: true }, Validators.required],
    Code: [{ value: '', disabled: false }, Validators.required],
    UseDefaults: [''],
    Notes: [''],
    PortalTitle: [{ value: '', disabled: false }, Validators.required],
    PortalSubTitle: [{ value: '', disabled: false }, Validators.required],
    PortalTheme: [{ value: '', disabled: false }, Validators.required],
    PortalMainImg: [''],
    PortalMainImgType: [''],
    ShowAppsBar: [''],
    ShowSideBarButton: [''],
    ShowAppsInSidebar: [''],
    EnableWPCustomization: [''],
    WPCustomHelp: [''],
    PortalIntroType: [{ value: '', disabled: false }, Validators.required],
    PortalIntroImageFromFileImgType: [''],
    PortalIntroStaticImageURL: [''],
    PortalIntroVideoPosterImgType: [''],
    PortalIntroFlickrPhotoSet: [''],
    PortalIntroYoutubeVideoID: [''],
    PortalIntroExternalURL: [''],
    PortalIntroURL: [''],
    PortalIntroVideoURL: [''],
    PortaIntroMinSeconds: [''],
    PortalBackgroundType: [''],
    PortalBackgroundImageFromFileImgType: [''],
    PortalBackgroundStaticImageURL: [''],
    PortalBackgroundFlickrPhotoSet: [''],
    DefaultApp: [''],
    DefaultCustomAppID: [''],
    InfoAppHide: [''],
    LoginAppHide: [''],
    SurfAppHide: [''],
    ProfileAppHide: [''],
    WeatherAppHide: [''],
    AroundMeAppHide: [''],
    RouteAppHide: [''],
    DefaultAppAfterLogin: [''],
    DefaultAppAfterLoginCustomAppID: [''],
    AppButtonsStyle: [''],
    AppBarInfoImgType: [''],
    AppBarLoginImgType: [''],
    AppBarSurfImgType: [''],
    AppBarConnStatusImgType: [''],
    AppBarAroundMeImgType: [''],
    AppBarWeatherImgType: [''],
    AppBarUserProfileImgType: [''],
    AppBarRouteImgType: [''],
    AppBarSurveyImgType: [''],
    AppBarCustomImgType: [''],
    WelcomePortalColorType: [''],
    WelcomePortalBackgroundColor: [''],
    HeaderBackgroundColor: [''],
    HeaderForegroundColor: [''],
    AppsBarBackgroundColor: [''],
    AppsBarForegroundColor: [''],
    SidebarBackgroundColor: [''],
    SidebarForegroundColor: [''],
    SidebarTitleBackgroundColor: [''],
    SidebarTitleForegroundColor: [''],
    WPCustomHead: [''],
    WPCustomCSS: [''],
    WPCustomJavascript: [''],
    WPCustomHTML: [''],
    EnableGoogleTagManager: [''],
    GoogleTagManagerID: [''],
    EnableGoogleAnalytics: [''],
    EnableHotJar: [''],
    MaximizeInfoApp: [''],
    InfoCustomHeader: [''],
    InfoHeaderImgType: [''],
    InfoHeaderImgLink: [''],
    InfoFooterImgType: [''],
    InfoFooterImgLink: [''],
    InfoAppWelcomeText: [''],
    InfoAppFooterText: [''],
    InfoAppCustomCSS: [''],
    LoginCustomHeader: [''],
    LoginHeaderImgType: [''],
    LoginHeaderImgLink: [''],
    LoginWelcomeText: [''],
    LoginSMSRequestText: [''],
    LoginFooterText: [''],
    PinRequestText: [''],
    RegistrationRequestAcceptMktTxt: [''],
    IntPhonePrefixSelfUserActivMsg: [''],
    LoginAppCustomCSS: [''],
    LoginAppCustomJavascript: [''],
    ProfileAppEnableChangeProfile: [''],
    ProfileAppEnableChangePassword: [''],
    ProfileAppEnableUserDisconnection: [''],
    ProfileAppEnableDownloadReceipt: [''],
    ConnectionStatusCustomCSS: [''],
    PortalIntroImageFromFileImg: [''],
    PortalIntroVideoPosterImg: [''],
    PortalBackgroundImageFromFileImg: [''],
    InfoHeaderImg: [''],
    InfoFooterImg: [''],
    LoginHeaderImg: [''],
    AppBarInfoImg: [''],
    AppBarLoginImg: [''],
    AppBarSurfImg: [''],
    AppBarConnStatusImg: [''],
    AppBarAroundMeImg: [''],
    AppBarWeatherImg: [''],
    AppBarUserProfileImg: [''],
    AppBarRouteImg: [''],
    AppBarSurveyImg: [''],
    AppBarCustomImg: [''],
  })

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: WifiSmssService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this._init();
  }

  private _loadForm(): void {
    this.form.controls.id.setValue(this.wpTemplate.id);
    this.form.controls.CreationDate.setValue(this.wpTemplate.CreationDate);
    this.form.controls.DomainID.setValue(this.wpTemplate.DomainID);
    this.form.controls.GatewayID.setValue(this.wpTemplate.GatewayID);
    this.form.controls.Code.setValue(this.wpTemplate.Code);
    this.form.controls.UseDefaults.setValue(this.wpTemplate.UseDefaults);
    this.form.controls.Notes.setValue(this.wpTemplate.Notes);
    this.form.controls.Notes.setValue(this.wpTemplate.Notes);
    this.form.controls.PortalTitle.setValue(this.wpTemplate.PortalTitle);
    this.form.controls.PortalSubTitle.setValue(this.wpTemplate.PortalSubTitle);
    this.form.controls.PortalTheme.setValue(this.wpTemplate.PortalTheme);
    this.form.controls.PortalMainImg.setValue(this.wpTemplate.PortalMainImg);
    this.form.controls.PortalMainImgType.setValue(this.wpTemplate.PortalMainImgType);
    this.form.controls.ShowAppsBar.setValue(this.wpTemplate.ShowAppsBar);
    this.form.controls.ShowSideBarButton.setValue(this.wpTemplate.ShowSideBarButton);
    this.form.controls.ShowAppsInSidebar.setValue(this.wpTemplate.ShowAppsInSidebar);
    this.form.controls.EnableWPCustomization.setValue(this.wpTemplate.EnableWPCustomization);
    this.form.controls.WPCustomHelp.setValue(this.wpTemplate.WPCustomHelp);
    this.form.controls.PortalIntroType.setValue(this.wpTemplate.PortalIntroType);
    this.form.controls.PortalIntroImageFromFileImgType.setValue(this.wpTemplate.PortalIntroImageFromFileImgType);
    this.form.controls.PortalIntroStaticImageURL.setValue(this.wpTemplate.PortalIntroStaticImageURL);
    this.form.controls.PortalIntroVideoPosterImgType.setValue(this.wpTemplate.PortalIntroVideoPosterImgType);
    this.form.controls.PortalIntroFlickrPhotoSet.setValue(this.wpTemplate.PortalIntroFlickrPhotoSet);
    this.form.controls.PortalIntroYoutubeVideoID.setValue(this.wpTemplate.PortalIntroYoutubeVideoID);
    this.form.controls.PortalIntroExternalURL.setValue(this.wpTemplate.PortalIntroExternalURL);
    this.form.controls.PortalIntroURL.setValue(this.wpTemplate.PortalIntroURL);
    this.form.controls.PortalIntroVideoURL.setValue(this.wpTemplate.PortalIntroVideoURL);
    this.form.controls.PortaIntroMinSeconds.setValue(this.wpTemplate.PortaIntroMinSeconds);
    this.form.controls.PortalBackgroundType.setValue(this.wpTemplate.PortalBackgroundType);
    this.form.controls.PortalBackgroundImageFromFileImgType.setValue(this.wpTemplate.PortalBackgroundImageFromFileImgType);
    this.form.controls.PortalBackgroundStaticImageURL.setValue(this.wpTemplate.PortalBackgroundStaticImageURL);
    this.form.controls.PortalBackgroundFlickrPhotoSet.setValue(this.wpTemplate.PortalBackgroundFlickrPhotoSet);
    this.form.controls.DefaultApp.setValue(this.wpTemplate.DefaultApp);
    this.form.controls.DefaultCustomAppID.setValue(this.wpTemplate.DefaultCustomAppID);
    this.form.controls.InfoAppHide.setValue(this.wpTemplate.InfoAppHide);
    this.form.controls.LoginAppHide.setValue(this.wpTemplate.LoginAppHide);
    this.form.controls.SurfAppHide.setValue(this.wpTemplate.SurfAppHide);
    this.form.controls.ProfileAppHide.setValue(this.wpTemplate.ProfileAppHide);
    this.form.controls.WeatherAppHide.setValue(this.wpTemplate.WeatherAppHide);
    this.form.controls.AroundMeAppHide.setValue(this.wpTemplate.AroundMeAppHide);
    this.form.controls.RouteAppHide.setValue(this.wpTemplate.RouteAppHide);
    this.form.controls.DefaultAppAfterLogin.setValue(this.wpTemplate.DefaultAppAfterLogin);
    this.form.controls.DefaultAppAfterLoginCustomAppID.setValue(this.wpTemplate.DefaultAppAfterLoginCustomAppID);
    this.form.controls.AppButtonsStyle.setValue(this.wpTemplate.AppButtonsStyle);
    this.form.controls.AppBarInfoImgType.setValue(this.wpTemplate.AppBarInfoImgType);
    this.form.controls.AppBarLoginImgType.setValue(this.wpTemplate.AppBarLoginImgType);
    this.form.controls.AppBarSurfImgType.setValue(this.wpTemplate.AppBarSurfImgType);
    this.form.controls.AppBarConnStatusImgType.setValue(this.wpTemplate.AppBarConnStatusImgType);
    this.form.controls.AppBarAroundMeImgType.setValue(this.wpTemplate.AppBarAroundMeImgType);
    this.form.controls.AppBarWeatherImgType.setValue(this.wpTemplate.AppBarWeatherImgType);
    this.form.controls.AppBarUserProfileImgType.setValue(this.wpTemplate.AppBarUserProfileImgType);
    this.form.controls.AppBarRouteImgType.setValue(this.wpTemplate.AppBarRouteImgType);
    this.form.controls.AppBarSurveyImgType.setValue(this.wpTemplate.AppBarSurveyImgType);
    this.form.controls.AppBarCustomImgType.setValue(this.wpTemplate.AppBarCustomImgType);
    this.form.controls.WelcomePortalColorType.setValue(this.wpTemplate.WelcomePortalColorType);
    this.form.controls.WelcomePortalBackgroundColor.setValue(this.wpTemplate.WelcomePortalBackgroundColor);
    this.form.controls.HeaderBackgroundColor.setValue(this.wpTemplate.HeaderBackgroundColor);
    this.form.controls.HeaderForegroundColor.setValue(this.wpTemplate.HeaderForegroundColor);
    this.form.controls.AppsBarBackgroundColor.setValue(this.wpTemplate.AppsBarBackgroundColor);
    this.form.controls.AppsBarForegroundColor.setValue(this.wpTemplate.AppsBarForegroundColor);
    this.form.controls.SidebarBackgroundColor.setValue(this.wpTemplate.SidebarBackgroundColor);
    this.form.controls.SidebarForegroundColor.setValue(this.wpTemplate.SidebarForegroundColor);
    this.form.controls.SidebarTitleBackgroundColor.setValue(this.wpTemplate.SidebarTitleBackgroundColor);
    this.form.controls.SidebarTitleForegroundColor.setValue(this.wpTemplate.SidebarTitleForegroundColor);
    this.form.controls.WPCustomHead.setValue(this.wpTemplate.WPCustomHead);
    this.form.controls.WPCustomCSS.setValue(this.wpTemplate.WPCustomCSS);
    this.form.controls.WPCustomJavascript.setValue(this.wpTemplate.WPCustomJavascript);
    this.form.controls.WPCustomHTML.setValue(this.wpTemplate.WPCustomHTML);
    this.form.controls.EnableGoogleTagManager.setValue(this.wpTemplate.EnableGoogleTagManager);
    this.form.controls.GoogleTagManagerID.setValue(this.wpTemplate.GoogleTagManagerID);
    this.form.controls.EnableGoogleAnalytics.setValue(this.wpTemplate.EnableGoogleAnalytics);
    this.form.controls.EnableHotJar.setValue(this.wpTemplate.EnableHotJar);
    this.form.controls.MaximizeInfoApp.setValue(this.wpTemplate.MaximizeInfoApp);
    this.form.controls.InfoCustomHeader.setValue(this.wpTemplate.InfoCustomHeader);
    this.form.controls.InfoHeaderImgType.setValue(this.wpTemplate.InfoHeaderImgType);
    this.form.controls.InfoHeaderImgLink.setValue(this.wpTemplate.InfoHeaderImgLink);
    this.form.controls.InfoFooterImgType.setValue(this.wpTemplate.InfoFooterImgType);
    this.form.controls.InfoFooterImgLink.setValue(this.wpTemplate.InfoFooterImgLink);
    this.form.controls.InfoAppWelcomeText.setValue(this.wpTemplate.InfoAppWelcomeText);
    this.form.controls.InfoAppFooterText.setValue(this.wpTemplate.InfoAppFooterText);
    this.form.controls.InfoAppCustomCSS.setValue(this.wpTemplate.InfoAppCustomCSS);
    this.form.controls.LoginCustomHeader.setValue(this.wpTemplate.LoginCustomHeader);
    this.form.controls.LoginHeaderImgType.setValue(this.wpTemplate.LoginHeaderImgType);
    this.form.controls.LoginHeaderImgLink.setValue(this.wpTemplate.LoginHeaderImgLink);
    this.form.controls.LoginWelcomeText.setValue(this.wpTemplate.LoginWelcomeText);
    this.form.controls.LoginSMSRequestText.setValue(this.wpTemplate.LoginSMSRequestText);
    this.form.controls.LoginFooterText.setValue(this.wpTemplate.LoginFooterText);
    this.form.controls.PinRequestText.setValue(this.wpTemplate.PinRequestText);
    this.form.controls.RegistrationRequestAcceptMktTxt.setValue(this.wpTemplate.RegistrationRequestAcceptMktTxt);
    this.form.controls.IntPhonePrefixSelfUserActivMsg.setValue(this.wpTemplate.IntPhonePrefixSelfUserActivMsg);
    this.form.controls.LoginAppCustomCSS.setValue(this.wpTemplate.LoginAppCustomCSS);
    this.form.controls.LoginAppCustomJavascript.setValue(this.wpTemplate.LoginAppCustomJavascript);
    this.form.controls.ProfileAppEnableChangeProfile.setValue(this.wpTemplate.ProfileAppEnableChangeProfile);
    this.form.controls.ProfileAppEnableChangePassword.setValue(this.wpTemplate.ProfileAppEnableChangePassword);
    this.form.controls.ProfileAppEnableUserDisconnection.setValue(this.wpTemplate.ProfileAppEnableUserDisconnection);
    this.form.controls.ProfileAppEnableDownloadReceipt.setValue(this.wpTemplate.ProfileAppEnableDownloadReceipt);
    this.form.controls.ConnectionStatusCustomCSS.setValue(this.wpTemplate.ConnectionStatusCustomCSS);
    this.form.controls.PortalIntroImageFromFileImg.setValue(this.wpTemplate.PortalIntroImageFromFileImg);
    this.form.controls.PortalIntroVideoPosterImg.setValue(this.wpTemplate.PortalIntroVideoPosterImg);
    this.form.controls.PortalBackgroundImageFromFileImg.setValue(this.wpTemplate.PortalBackgroundImageFromFileImg);
    this.form.controls.InfoHeaderImg.setValue(this.wpTemplate.InfoHeaderImg);
    this.form.controls.InfoFooterImg.setValue(this.wpTemplate.InfoFooterImg);
    this.form.controls.LoginHeaderImg.setValue(this.wpTemplate.LoginHeaderImg);
    this.form.controls.AppBarInfoImg.setValue(this.wpTemplate.AppBarInfoImg);
    this.form.controls.AppBarLoginImg.setValue(this.wpTemplate.AppBarLoginImg);
    this.form.controls.AppBarSurfImg.setValue(this.wpTemplate.AppBarSurfImg);
    this.form.controls.AppBarConnStatusImg.setValue(this.wpTemplate.AppBarConnStatusImg);
    this.form.controls.AppBarAroundMeImg.setValue(this.wpTemplate.AppBarAroundMeImg);
    this.form.controls.AppBarWeatherImg.setValue(this.wpTemplate.AppBarWeatherImg);
    this.form.controls.AppBarUserProfileImg.setValue(this.wpTemplate.AppBarUserProfileImg);
    this.form.controls.AppBarRouteImg.setValue(this.wpTemplate.AppBarRouteImg);
    this.form.controls.AppBarSurveyImg.setValue(this.wpTemplate.AppBarSurveyImg);
    this.form.controls.AppBarCustomImg.setValue(this.wpTemplate.AppBarCustomImg);

  }

  public save(): void {
    if (!this.form.valid) {
      this.error = "ERROR_EMPTY";
      return;
    }

    this.wpTemplate = {
      id: this.form.controls.id.value,
      CreationDate: this.form.controls.CreationDate.value,
      DomainID: this.form.controls.DomainID.value,
      GatewayID: this.form.controls.GatewayID.value,
      Code: this.form.controls.Code.value,
      UseDefaults: this.form.controls.UseDefaults.value,
      Notes: this.form.controls.Notes.value,
      PortalTitle: this.form.controls.PortalTitle.value,
      PortalSubTitle: this.form.controls.PortalSubTitle.value,
      PortalTheme: this.form.controls.PortalTheme.value,
      PortalMainImg: this.form.controls.PortalMainImg.value,
      PortalMainImgType: this.form.controls.PortalMainImgType.value,
      ShowAppsBar: this.form.controls.ShowAppsBar.value,
      ShowSideBarButton: this.form.controls.ShowSideBarButton.value,
      ShowAppsInSidebar: this.form.controls.ShowAppsInSidebar.value,
      EnableWPCustomization: this.form.controls.EnableWPCustomization.value,
      WPCustomHelp: this.form.controls.WPCustomHelp.value,
      PortalIntroType: this.form.controls.PortalIntroType.value,
      PortalIntroImageFromFileImgType: this.form.controls.PortalIntroImageFromFileImgType.value,
      PortalIntroStaticImageURL: this.form.controls.PortalIntroStaticImageURL.value,
      PortalIntroVideoPosterImgType: this.form.controls.PortalIntroVideoPosterImgType.value,
      PortalIntroFlickrPhotoSet: this.form.controls.PortalIntroFlickrPhotoSet.value,
      PortalIntroYoutubeVideoID: this.form.controls.PortalIntroYoutubeVideoID.value,
      PortalIntroExternalURL: this.form.controls.PortalIntroExternalURL.value,
      PortalIntroURL: this.form.controls.PortalIntroURL.value,
      PortalIntroVideoURL: this.form.controls.PortalIntroVideoURL.value,
      PortaIntroMinSeconds: this.form.controls.PortaIntroMinSeconds.value,
      PortalBackgroundType: this.form.controls.PortalBackgroundType.value,
      PortalBackgroundImageFromFileImgType: this.form.controls.PortalBackgroundImageFromFileImgType.value,
      PortalBackgroundStaticImageURL: this.form.controls.PortalBackgroundStaticImageURL.value,
      PortalBackgroundFlickrPhotoSet: this.form.controls.PortalBackgroundFlickrPhotoSet.value,
      DefaultApp: this.form.controls.DefaultApp.value,
      DefaultCustomAppID: this.form.controls.DefaultCustomAppID.value,
      InfoAppHide: this.form.controls.InfoAppHide.value,
      LoginAppHide: this.form.controls.LoginAppHide.value,
      SurfAppHide: this.form.controls.SurfAppHide.value,
      ProfileAppHide: this.form.controls.ProfileAppHide.value,
      WeatherAppHide: this.form.controls.WeatherAppHide.value,
      AroundMeAppHide: this.form.controls.AroundMeAppHide.value,
      RouteAppHide: this.form.controls.RouteAppHide.value,
      DefaultAppAfterLogin: this.form.controls.DefaultAppAfterLogin.value,
      DefaultAppAfterLoginCustomAppID: this.form.controls.DefaultAppAfterLoginCustomAppID.value,
      AppButtonsStyle: this.form.controls.AppButtonsStyle.value,
      AppBarInfoImgType: this.form.controls.AppBarInfoImgType.value,
      AppBarLoginImgType: this.form.controls.AppBarLoginImgType.value,
      AppBarSurfImgType: this.form.controls.AppBarSurfImgType.value,
      AppBarConnStatusImgType: this.form.controls.AppBarConnStatusImgType.value,
      AppBarAroundMeImgType: this.form.controls.AppBarAroundMeImgType.value,
      AppBarWeatherImgType: this.form.controls.AppBarWeatherImgType.value,
      AppBarUserProfileImgType: this.form.controls.AppBarUserProfileImgType.value,
      AppBarRouteImgType: this.form.controls.AppBarRouteImgType.value,
      AppBarSurveyImgType: this.form.controls.AppBarSurveyImgType.value,
      AppBarCustomImgType: this.form.controls.AppBarCustomImgType.value,
      WelcomePortalColorType: this.form.controls.WelcomePortalColorType.value,
      WelcomePortalBackgroundColor: this.form.controls.WelcomePortalBackgroundColor.value,
      HeaderBackgroundColor: this.form.controls.HeaderBackgroundColor.value,
      HeaderForegroundColor: this.form.controls.HeaderForegroundColor.value,
      AppsBarBackgroundColor: this.form.controls.AppsBarBackgroundColor.value,
      AppsBarForegroundColor: this.form.controls.AppsBarForegroundColor.value,
      SidebarBackgroundColor: this.form.controls.SidebarBackgroundColor.value,
      SidebarForegroundColor: this.form.controls.SidebarForegroundColor.value,
      SidebarTitleBackgroundColor: this.form.controls.SidebarTitleBackgroundColor.value,
      SidebarTitleForegroundColor: this.form.controls.SidebarTitleForegroundColor.value,
      WPCustomHead: this.form.controls.WPCustomHead.value,
      WPCustomCSS: this.form.controls.WPCustomCSS.value,
      WPCustomJavascript: this.form.controls.WPCustomJavascript.value,
      WPCustomHTML: this.form.controls.WPCustomHTML.value,
      EnableGoogleTagManager: this.form.controls.EnableGoogleTagManager.value,
      GoogleTagManagerID: this.form.controls.GoogleTagManagerID.value,
      EnableGoogleAnalytics: this.form.controls.EnableGoogleAnalytics.value,
      EnableHotJar: this.form.controls.EnableHotJar.value,
      MaximizeInfoApp: this.form.controls.MaximizeInfoApp.value,
      InfoCustomHeader: this.form.controls.InfoCustomHeader.value,
      InfoHeaderImgType: this.form.controls.InfoHeaderImgType.value,
      InfoHeaderImgLink: this.form.controls.InfoHeaderImgLink.value,
      InfoFooterImgType: this.form.controls.InfoFooterImgType.value,
      InfoFooterImgLink: this.form.controls.InfoFooterImgLink.value,
      InfoAppWelcomeText: this.form.controls.InfoAppWelcomeText.value,
      InfoAppFooterText: this.form.controls.InfoAppFooterText.value,
      InfoAppCustomCSS: this.form.controls.InfoAppCustomCSS.value,
      LoginCustomHeader: this.form.controls.LoginCustomHeader.value,
      LoginHeaderImgType: this.form.controls.LoginHeaderImgType.value,
      LoginHeaderImgLink: this.form.controls.LoginHeaderImgLink.value,
      LoginWelcomeText: this.form.controls.LoginWelcomeText.value,
      LoginSMSRequestText: this.form.controls.LoginSMSRequestText.value,
      LoginFooterText: this.form.controls.LoginFooterText.value,
      PinRequestText: this.form.controls.PinRequestText.value,
      RegistrationRequestAcceptMktTxt: this.form.controls.RegistrationRequestAcceptMktTxt.value,
      IntPhonePrefixSelfUserActivMsg: this.form.controls.IntPhonePrefixSelfUserActivMsg.value,
      LoginAppCustomCSS: this.form.controls.LoginAppCustomCSS.value,
      LoginAppCustomJavascript: this.form.controls.LoginAppCustomJavascript.value,
      ProfileAppEnableChangeProfile: this.form.controls.ProfileAppEnableChangeProfile.value,
      ProfileAppEnableChangePassword: this.form.controls.ProfileAppEnableChangePassword.value,
      ProfileAppEnableUserDisconnection: this.form.controls.ProfileAppEnableUserDisconnection.value,
      ProfileAppEnableDownloadReceipt: this.form.controls.ProfileAppEnableDownloadReceipt.value,
      ConnectionStatusCustomCSS: this.form.controls.ConnectionStatusCustomCSS.value,
      PortalIntroImageFromFileImg: this.form.controls.PortalIntroImageFromFileImg.value,
      PortalIntroVideoPosterImg: this.form.controls.PortalIntroVideoPosterImg.value,
      PortalBackgroundImageFromFileImg: this.form.controls.PortalBackgroundImageFromFileImg.value,
      InfoHeaderImg: this.form.controls.InfoHeaderImg.value,
      InfoFooterImg: this.form.controls.InfoFooterImg.value,
      LoginHeaderImg: this.form.controls.LoginHeaderImg.value,
      AppBarInfoImg: this.form.controls.AppBarInfoImg.value,
      AppBarLoginImg: this.form.controls.AppBarLoginImg.value,
      AppBarSurfImg: this.form.controls.AppBarSurfImg.value,
      AppBarConnStatusImg: this.form.controls.AppBarConnStatusImg.value,
      AppBarAroundMeImg: this.form.controls.AppBarAroundMeImg.value,
      AppBarWeatherImg: this.form.controls.AppBarWeatherImg.value,
      AppBarUserProfileImg: this.form.controls.AppBarUserProfileImg.value,
      AppBarRouteImg: this.form.controls.AppBarRouteImg.value,
      AppBarSurveyImg: this.form.controls.AppBarSurveyImg.value,
      AppBarCustomImg: this.form.controls.AppBarCustomImg.value,
    }

    const saveConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    saveConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(dialogResult => {
      if (dialogResult) {
        this.apiService.gatewayWrite(this.gateway).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
            this.mainService.setSuccessMessageComand(message);
            if (result.Error !== null && result.Error !== undefined) {
              this.wpTemplateId = result.Id;
              this._init();
            }
          }
        })
      }
    })
  }

  back(): void {
    if (this.gateway !== null) {
      this.router.navigate(['main/gateway', { id: this.gateway.id, manager: this.gateway.ManagerID, domain: this.gateway.DomainID }]);
    } else {
      this.router.navigate(['main/net-wifi/gateways', { id: this.domain.id }]);
    }
  }


  private _init(): void {
    const wpTemplateId = this.route.snapshot.paramMap.get('id');
    if (wpTemplateId !== undefined && wpTemplateId !== null && wpTemplateId !== '' && this.wpTemplateId !== null)
      this.isAdd = false;

    if (this.wpTemplateId !== undefined && this.wpTemplateId !== null) {
      this.isAdd = false;
    }

    const domainId = this.route.snapshot.paramMap.get('domain');
    if (domainId === undefined || domainId === null || domainId === '')
      this.router.navigate(['main/dashboard']);

    const gatewayId = this.route.snapshot.paramMap.get('gateway');

    this.apiService.domainRead(domainId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainResult => {
      if (domainResult) {
        this.domain = domainResult;
        this.form.controls.DomainID.setValue(this.domain.id);

        if (gatewayId !== null && gatewayId !== undefined && gatewayId !== '') {
          this.apiService.gatewayRead(gatewayId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(gatewayResult => {
            if (gatewayResult) {
              this.gateway = gatewayResult;
              this.form.controls.GatewayID.setValue(this.gateway.id);
            }
          })
        }
        else {
          this.form.controls.GatewayID.setValidators(null);
        }

        if (!this.isAdd) {
          this.apiService.wpTemplateRead(this.wpTemplateId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(wpTemplateResult => {
            if (wpTemplateResult) {
              this.wpTemplate = wpTemplateResult;

              this._loadForm();
            }
          })
        }
      }
    })
  }

  imageUpload(event: any, formControlId: string): void {

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (myTarget) => {
      let image = new Image();
      image.src = myTarget.target.result.toString();
      image.onload = () => {
        this.imageBaseData = reader.result;
        this.form.controls[formControlId].setValue(this.imageBaseData);
      }
    };
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
  }

  removeImage(formControlId: string): void {
    this.form.controls[formControlId].setValue('');
  }
}
