<div class="mt-5">
    <h6>{{ title | translate }}</h6>
    <div *ngIf="!noDataOnChart">
        <div class="mr-2 my-3 float-right" *ngIf="passedLastUpdate && passedLastUpdate !== undefined">
            {{ 'HEATMAP.LAST_UPDATE' | translate }} {{ passedLastUpdate*1000 | date : 'short' }}
        </div>

        <google-map [options]="mapOptions" width="100%" height="750"></google-map>
    </div>

    <p *ngIf="noDataOnChart">{{ 'STATISTICS.NO_CHART_DATA' | translate }}</p>
</div>