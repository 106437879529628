import { NavigationInfo } from './../../models/navigationExtra';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from '../../services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter();
  @Output() toggleUserSidebar: EventEmitter<string> = new EventEmitter<string>();
  @Input('darkModeStatus') public darkModeStatus: boolean;
  @Input('myProfileImage') public myProfileImage: string | null;
  @Input('domainLogo') public myDomainLogo: string;
  @Input('title') public myTitle: string;
  @Input('sidebarOpen') public sidebarIsOpen: boolean;

  public chooseDomains: boolean = true;
  public toggleControl = new UntypedFormControl(false);
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public logoButtonToShow: string = "/assets/img/icons/envision-menu-72px.png";
  public navigationInfo: NavigationInfo = null;
  public selectedMenu: string;

  constructor(
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((navInfo: NavigationInfo) => {
      this.navigationInfo = navInfo;
    });
  }

  public toggleSidebarButton(): void {
    this.sidebarIsOpen = !this.sidebarIsOpen;
    this.toggleSidebar.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
        //this triggers the window resize event in Javascript on modern browsers
      );
    }, 300);
  }

  public toggleUserBar(component: string): void {
    this.toggleUserSidebar.emit(component);
  }

  public goToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.updateAllDataAndGoToMainComand();
  }

  public back(): void {
    if(this.navigationInfo?.BackRoute) {
      let backRoutes: string[] = JSON.parse(this.navigationInfo.BackRoute);
      const back: string = backRoutes[backRoutes.length - 1].split(";", 2)[0];
      const currentRoute: string = this.router.url.split("/").pop().split(";")[0];

      if(currentRoute === back) {
        this.router.navigate(['main/invisible-child'], { skipLocationChange: true }).then(() => {
          this.router.navigate([`main/${back}`]);
        });
      }
      else {
        this.router.navigate([`main/${back}`]);
      }
    }
    this.mainService.setNavigationInfoComand({ Back: true });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
