import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../models/loginResponse';
import { UVNotificationCreateRequest } from '../../models/notification';

@Component({
  selector: 'urban-add-notification-dialog',
  templateUrl: './add-notification-dialog.component.html',
  styleUrls: ['./add-notification-dialog.component.scss']
})
export class AddNotificationDialogComponent implements OnInit {

  public userList: User[] = [];
  public error: string | null = null;
  public minDate: Date;
  public form: UntypedFormGroup = this.formBuilder.group({
    destinationSelected: [{ value: '', disabled: false }],
    destinationTypeSelected: [{ value: '', disabled: false }, Validators.required],
    payload: [{ value: '', disabled: false }, Validators.required],
    expirationDate: [{ value: null, disabled: false }, Validators.required]
  });

  constructor(
    public dialogRef: MatDialogRef<AddNotificationDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        users: User[]
      }) {
    this.userList = data.users;
  }

  ngOnInit(): void {
    let currentDate = new Date();
    this.minDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()));
  }

  public setDestination(): void {
    if (this.form.controls.destinationTypeSelected.value === '') {
      return;
    }

    if (this.form.controls.destinationTypeSelected.value != 'u') {
      this.form.controls.destinationSelected.setValue('');
      this.form.controls.destinationSelected.setValidators([]);
      this.form.controls.destinationSelected.disable()
    }
    else {
      this.form.controls.destinationSelected.enable()
      this.form.controls.destinationSelected.setValidators([Validators.required]);
    }
  }

  public add(): void {
    if (this.form.valid) {
      const addNotificationRequest: UVNotificationCreateRequest = {
        Payload: this.form.controls.payload.value,
        Destination: this.form.controls.destinationTypeSelected.value === 'u' ? this.form.controls.destinationSelected.value : '00000000-0000-0000-0000-000000000000',
        DestinationType: this.form.controls.destinationTypeSelected.value,
        ExpireDate: this.reformatDate(this.form.controls.expirationDate.value)
      }

      this.dialogRef.close(addNotificationRequest);
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  private reformatDate(dateToFormat: string): number {
    const currentDate = new Date(dateToFormat);
    return Math.round(currentDate.getTime() / 1000);
  }
}
