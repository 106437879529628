import {Theme} from "../shared/models/theme";
import {Observable} from "rxjs";

export class StyleHelper {

  static applyCustomStyle(theme: Theme): Observable<void> {
    if (!theme) {
      return;
    }

    document.title = theme?.Translations.find(x => x.Key === 'title')?.Value ?? document.title;

    Object.keys(theme?.ScssVariables).forEach(scssVariable => {
      document.documentElement.style.setProperty(scssVariable, theme?.ScssVariables[scssVariable]);
    });

    //apply translations
    theme?.Translations?.forEach(translation => {
      translation?.Targets?.forEach(target =>{
        const elementToUpdate = document.getElementById(target);
        if (elementToUpdate)
          elementToUpdate.innerText = translation.Value;
      })
    });
  }
}
