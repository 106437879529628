import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { Domain } from '../../models/domain';

interface LoginModel {
  username: string,
  password: string
}

interface ErrorModel {
  error: string
}


@Injectable({
  providedIn: 'root'
})
export class AuthSubscriptionsService {

  private subjectRequestLogin = new Subject<LoginModel>();
  private subjectSetError = new Subject<ErrorModel>();
  private subjectInitSetUserDomain = new Subject<Domain>();
  private subjectChangePassword = new Subject<ChangePasswordRequest>();

  constructor() { }

  public requestLoginComand(bodyLogin: LoginModel): void {
    this.subjectRequestLogin.next(bodyLogin);
  }
  public getRequestLoginComand(): Observable<LoginModel> {
    return this.subjectRequestLogin.asObservable();
  }

  public setErrorComand(errorToSet: ErrorModel): void {
    this.subjectSetError.next(errorToSet);
  }
  public getSetErrorComand(): Observable<ErrorModel> {
    return this.subjectSetError.asObservable();
  }

  public initSetUserDomainComand(domain: Domain): void {
    this.subjectInitSetUserDomain.next(domain);
  }
  public getInitSetUserDomainComand(): Observable<Domain> {
    return this.subjectInitSetUserDomain.asObservable();
  }

  public changePasswordComand(changePasswordRequest: ChangePasswordRequest): void {
    this.subjectChangePassword.next(changePasswordRequest);
  }
  public getChangePasswordComand(): Observable<ChangePasswordRequest> {
    return this.subjectChangePassword.asObservable();
  }
}
