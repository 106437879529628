<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'MESSAGE.TITLE' | translate }}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field class="messageArea">
            <mat-label>{{ 'MESSAGE.REQUEST' | translate }}</mat-label>
            <textarea class="panelBG" matInput
                formControlName="message" matTextareaAutosize matAutosizeMinRows=15 matAutosizeMaxRows=30></textarea>
            <button mat-button *ngIf="this.form.controls.message" matSuffix mat-icon-button (click)="messageInput=''">
                <i class="urbanicon-close"></i>
            </button>
        </mat-form-field>

        <br>
        <mat-error *ngIf="error && !this.form.controls.message.dirty">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>
        <br>
        <!-- <div class="buttonArea d-flex justify-content-start align-items-center pb-2"> -->
        <div class="buttonArea">
            <button mat-button class="btn actionButton ml-2"
                    type="button" id="updateButton" (click)="send()">
                {{ 'MESSAGE.SEND' | translate }}
            </button>
        </div>
    </form>
</div>
