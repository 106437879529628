<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'RESOURCE.ADD' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'RESOURCE.DETAIL' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'RESOURCE.URL' | translate }}</mat-label>
            <input matInput formControlName="url" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.PARENT' | translate }}</mat-label>
            <mat-select formControlName="parentSelected">
                <mat-option *ngFor="let parentResource of parentResources" value={{parentResource.Id}}>
                    {{parentResource.Url}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.LABEL' | translate }}</mat-label>
            <input matInput formControlName="label" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.TITLE' | translate }}</mat-label>
            <input matInput formControlName="title" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.IMAGE' | translate }}</mat-label>
            <input matInput formControlName="image" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.TYPE' | translate }}</mat-label>
            <input matInput formControlName="type" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.ORDER' | translate }}</mat-label>
            <input matInput type="number" formControlName="order"
                autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.IS_PUBLIC' | translate }}</mat-label>
            <mat-select formControlName="isPublic">
                <mat-option value="0">
                    {{ 'GENERAL.NO' | translate }}
                </mat-option>
                <mat-option value="1">
                    {{ 'GENERAL.YES' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'RESOURCE.REQUIRE_TOKEN' | translate }}</mat-label>
            <mat-select formControlName="requireToken">
                <mat-option value="0">
                    {{ 'GENERAL.NO' | translate }}
                </mat-option>
                <mat-option value="1">
                    {{ 'GENERAL.YES' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
