<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center mx-0 pageTop">
        <span class="title2">
            {{this.location?.Name}}
        </span>

        <button mat-button class="btn actionButton mr-2 mt-2" type="button" id="eventsButton" (click)="events()">
            {{ 'DEVICE.VIEW_EVENTS' | translate }}
        </button>
    </div>

    <div class="positionInfo panelBG">
        <form [formGroup]="form">
            <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                <span class="title3 secondaryTextColor">{{ 'GENERAL.INFO' | translate }}</span>

                <div [matTooltip]="(editMode === 'position' ? ('DEVICE.ONGOING_CHANGES_MESSAGE' | translate) : '')">
                    <button type="button" mat-button class="btn actionButton" *ngIf="editMode === 'info'" (click)="sendUpdate(editMode)" [disabled]="!dataInfoChanged">
                        {{ 'GENERAL.SAVE' | translate }}
                    </button>
    
                    <button type="button" mat-button class="btn actionButton" *ngIf="editMode !== 'info'" (click)="enableEdit('info')" [disabled]="editMode === 'position'">
                        {{ 'GENERAL.EDIT' | translate }}
                    </button>
    
                    <button type="button" mat-button class="btn outlineActionButton ml-0 cancelBtn mt-2 mt-sm-0" *ngIf="editMode === 'info'" (click)="cancelInfoEdit()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="d-flex flex-column col-6 pl-0">
                    <mat-form-field [ngClass]="{'noUnderline': editMode !== 'info'}">
                        <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                        <input matInput formControlName="name" autocomplete="off" [required]="editMode === 'info'" [readonly]="editMode !== 'info'">
                    </mat-form-field>
    
                    <mat-form-field [ngClass]="{'noUnderline': editMode !== 'info'}">
                        <mat-label>{{ 'LOCATIONS.ADDRESS' | translate }}</mat-label>
                        <input matInput formControlName="address" autocomplete="off" [required]="editMode === 'info'" [readonly]="editMode !== 'info'">
                    </mat-form-field>
    
                    <mat-form-field [ngClass]="{'noUnderline': editMode !== 'info'}">
                        <mat-label>{{ 'LOCATIONS.ZIP' | translate }}</mat-label>
                        <input matInput formControlName="zip" autocomplete="off" [required]="editMode === 'info'" [readonly]="editMode !== 'info'">
                    </mat-form-field>
    
                    <mat-form-field [ngClass]="{'noUnderline': editMode !== 'info'}">
                        <mat-label>{{ 'LOCATIONS.CITY' | translate }}</mat-label>
                        <input matInput formControlName="city" autocomplete="off" [required]="editMode === 'info'" [readonly]="editMode !== 'info'">
                    </mat-form-field>
                </div>

                <div class="d-flex flex-column col-6">
                    <mat-form-field [ngClass]="{'noUnderline': editMode !== 'info'}">
                        <mat-label>{{ 'LOCATIONS.COUNTRY' | translate }}</mat-label>
                        <input matInput formControlName="country" autocomplete="off" [required]="editMode === 'info'" [readonly]="editMode !== 'info'">
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': editMode !== 'info'}">
                        <mat-label>{{ 'LOCATIONS.STATE' | translate }}</mat-label>
                        <input matInput formControlName="state" autocomplete="off" [required]="editMode === 'info'" [readonly]="editMode !== 'info'">
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'noUnderline': editMode !== 'info'}">
                        <mat-label>{{ 'LOCATIONS.DESCRIPTION' | translate }}</mat-label>
                        <textarea class="tableEvidenceBG" matInput
                                  formControlName="description" matTextareaAutosize matAutosizeMinRows=4.6 matAutosizeMaxRows=15
                                  autocomplete="off">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div class="d-flex flex-wrap infoCol">
        <div class="col-6 customCol section d-flex flex-column mapSection panelBG">
            <form [formGroup]="positionForm">
                <div>
                    <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                        <span class="title3 secondaryTextColor">{{ 'DEVICE.POSITION' | translate }}</span>
            
                        <div [matTooltip]="(editMode === 'info' ? ('DEVICE.ONGOING_CHANGES_MESSAGE' | translate) : '')">
                            <button type="button" mat-button class="btn actionButton" *ngIf="editMode === 'position'" (click)="sendUpdate(editMode)" [disabled]="!dataLocationChanged">
                                {{ 'GENERAL.SAVE' | translate }}
                            </button>
            
                            <button type="button" mat-button class="btn actionButton" *ngIf="editMode !== 'position'" (click)="enableEdit('position')" [disabled]="editMode === 'info'">
                                {{ 'GENERAL.EDIT' | translate }}
                            </button>
            
                            <button type="button" mat-button class="btn outlineActionButton ml-0 cancelBtn mt-2 mt-sm-0" *ngIf="editMode === 'position'" (click)="cancelPositionEdit()">
                                {{ 'GENERAL.CANCEL' | translate }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="d-flex h-100 justify-content-between map-flex">
                    <div class="h-100 w-100 map">
                        <urban-maps-widget *ngIf="mapReady && location" [showInfo]="false" [singleLocation]="location" [initialZoom]="16"
                        [darkModeStatus]="isDarkModeActive" [passedHeight]="'402.817'" [editMode]="editMode === 'position' ? 'drag' : ''"
                        [outputChangePosition]="true" (changeLocationPosition)="updateLocationMarkerPosition($event)">
                        </urban-maps-widget>
                    </div>
        
                    <div class="d-flex flex-column mapInfo">
                        <mat-form-field [ngClass]="{'noUnderline': editMode !== 'position'}">
                            <mat-label>{{ 'LOCATIONS.LATITUDE' | translate }}</mat-label>
                            <input matInput formControlName="latitude" type="number" autocomplete="off" [required]="editMode === 'position'" [readonly]="editMode !== 'position'">
                        </mat-form-field>
        
                        <mat-form-field [ngClass]="{'noUnderline': editMode !== 'position'}">
                            <mat-label>{{ 'LOCATIONS.LONGITUDE' | translate }}</mat-label>
                            <input matInput formControlName="longitude" type="number" autocomplete="off" [required]="editMode === 'position'" [readonly]="editMode !== 'position'">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-6 customColTwo pr-0">
            <div class="customTable">
                <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap mb-2">
                    <span class="title3">
                        {{ 'GENERAL.PROPERTIES' | translate }}
                    </span>

                    <div class="d-flex align-items-center flex-wrap">
                        <mat-form-field class="tableSearch mr-3" appearance="outline" [ngClass]="properties?.length === 0 ? 'd-none' : 'd-block'">
                            <div matPrefix class="prefix">
                                <i class="urbanicon-search"></i>
                            </div>
                            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                            <input matInput (keyup)="applyFilter($event)"
                                autocomplete="off" [(ngModel)]="actualFilter">
                            <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                                <i class="urbanicon-close"></i>
                            </button>
                        </mat-form-field>
    
                        <button mat-button class="btn actionButton" type="button" (click)="addProperty()">
                            {{ 'GENERAL.ADD' | translate }}
                        </button>
                    </div>
                </div>

                <div class="tableHeight" *ngIf="properties?.length > 0">
                    <table [dataSource]="filteredData | paginate: config"
                        mat-table class="w-100 user-select-none noTableHover">
                        <ng-container matColumnDef="Key">
                            <th mat-header-cell *matHeaderCellDef style="width: 25%;"> {{ 'GENERAL.KEY' | translate }} </th>
                            <td mat-cell *matCellDef="let property">{{ property.Key }}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="Value">
                            <th mat-header-cell *matHeaderCellDef style="width: 60%;"> {{ 'GENERAL.VALUE' | translate }} </th>
                            <td mat-cell *matCellDef="let property">{{ property.Value }}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="Actions">
                            <th mat-header-cell *matHeaderCellDef style="width: 15%;"></th>
                            <td mat-cell *matCellDef="let property" class="text-center p-1">
                                <i matTooltip="{{'GENERAL.EDIT' | translate }}" class="iconInfoButton urbanicon-edit"
                                    (click)="openPropertyDetail(property.Key, property.Value)">
                                </i>
                                <i matTooltip="{{'GENERAL.DELETE' | translate }}" class="iconDeleteButton urbanicon-delete"
                                    (click)="deleteProperty(property.Key)">
                                </i>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="propertiesColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: propertiesColumns;"></tr>
                    </table>
                </div>

                <div class="py-5 text-center customTableRadius" *ngIf="properties?.length === 0">
                    {{ 'LOCATION.NO_PROPERTIES_MESSAGE' | translate }}
                </div>

                <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="properties?.length === 0 ? 'd-none' : 'd-flex'">
                    <div class="d-flex flex-wrap my-3 my-sm-0">
                        <pagination-controls class="customNgxPaginator"
                            [maxSize]="7"
                            [directionLinks]="true"
                            [previousLabel]="''"
                            [nextLabel]="''"
                            [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                            [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                            [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                            (pageChange)="paginatorOnPageChange($event)">
                        </pagination-controls>
        
                        <div class="paginatorPageCounter">
                            {{config.currentPage}} {{'GENERAL.OF' | translate}}
                            {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                            {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                        </div>
                    </div>

                    <div>
                        <mat-form-field class="customSelect" appearance="fill">
                            <mat-select [(value)]="config.itemsPerPage">
                                <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                                    {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
