<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span *ngIf="passageDevice" class="title2 appearingTextLeft">
                {{ this.passageDevice.Name + ' -' }}
            </span>
            <span class="title2">{{ 'TRANSIT_DEVICE.TITLE' | translate }}</span>
        </div>

        <!-- <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span> -->
    </div>

    <urban-searchbar id="intro-passage-detail-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
            [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
            (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <div>
        <mat-tab-group id="intro-passage-detail-tab" animationDuration="0ms" dynamicHeight="true">
            <mat-tab [label]="'GENERAL.STATUS' | translate">
                <div class="tabContent" *ngIf="passageDataSource">
                    <div *ngIf="passageDataSource.data.length > 0" class="customTable">
                        <table id="intro-passage-detail-table" class="w-100 h-100 noTableHover" mat-table
                        [dataSource]="passageDataSource">

                            <ng-container matColumnDef="Area">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                    {{'GENERAL.AREA' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">
                                    {{ item.Position }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Percentage">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                    {{'PASSAGE_DASHBOARD.TOTAL_PASSAGES' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">
                                    {{ item.Total }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Total">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                    {{'GENERAL.PERCENTAGE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">
                                    {{ item.Percentage }}%
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <div *ngIf="passageDataSource.data.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
                <div *ngIf="chartsTab.isActive && passages" class="tabContent">
                    <div *ngIf="passages.length > 0">
                        <div *ngIf="passagesBubbleChartData" class="roundedPanel panelBG chartContainer p-3">
                            <urban-google-bubblechart bubbleChartTitle="PASSAGE_DETAIL.PASSAGES_PER_AREA"
                                [passedChartData]="passagesBubbleChartData" [passedDataDescriptions]="bubbleChartDescriptions"
                                [chartWidth]="maxAreaLimit.X - minAreaLimit.X" [chartHeight]="maxAreaLimit.Y - minAreaLimit.Y"
                                [darkThemeActive]="this.isDarkActive" [chartPrecision]="spatialPrecision" flipChart="Y"
                                [sheerLayout]="true" [nestedChart]="false" [compressedLayout]="true"
                                [chartLegend]="bubbleChartLegend" [amountLimit]="passages.length"></urban-google-bubblechart>
                        </div>
                        <div class="ml-3 mt-2">
                            <span>{{ 'TRANSIT_DEVICE.SPATIAL_PRECISION' | translate }}</span>
                            <mat-slider min="6" max="14" step="1" color="primary" thumbLabel=true (change)="changeSpatialPrecision()"
                                [(ngModel)]="spatialPrecision">
                            </mat-slider>
                            <span>{{ spatialPrecision }} {{ 'TRANSIT_DEVICE.POSITIONS_PER_SIDE' | translate }}</span>
                        </div>
                    </div>
                    <div *ngIf="passages.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div>
    <urban-loader [localized]="true" [onContent]="true" [disappearCondition]="loadingData === false">
    </urban-loader>
</div>
