<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.START' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="edt"
                   formControlName="start" autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="edt"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #edt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.END' | translate }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="sdt"
                   formControlName="end" autocomplete="off" readonly>
            <mat-datepicker-toggle matSuffix [for]="sdt"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #sdt [showSeconds]=false></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.PRIORITY' | translate }}</mat-label>
            <input type="number" matInput formControlName="priority" min="1" max="10" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.DURATION' | translate }}</mat-label>
            <input type="number" matInput formControlName="duration" min="0" step="0.1" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'CONTENT_MANAGEMENT.CHANNEL' | translate }}</mat-label>
            <mat-select formControlName="channel">
                <mat-option *ngFor="let channel of channels" value={{channel.Id}}>
                    {{channel.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'CONTENT_MANAGEMENT.CONTENT' | translate }}</mat-label>
            <mat-select formControlName="content">
                <mat-option *ngFor="let content of contents" value={{content.Id}}>
                    {{content.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
