import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssignedWidgets, Dashboard, Widget, DashboardRequest } from '../../models/dashboardList';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { DeviceType } from '../../models/deviceType';

@Component({
  selector: 'urban-dashboard-management-dialog',
  templateUrl: './dashboard-management-dialog.component.html',
  styleUrls: ['./dashboard-management-dialog.component.scss']
})
export class DashboardManagementDialogComponent implements OnInit {
  @ViewChild('selectionList') selectionList: MatSelectionList;

  public editMode: boolean = false;
  public selectedWidgets: Widget[];
  public dashboardManagementView: boolean = true;
  public confirmChoice: boolean = false;
  public error: boolean = false;
  public dashboardForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
    type: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<DashboardManagementDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        dashboard: Dashboard,
        deviceTypes: DeviceType[],
        currentDomain: string,
        widgetList: Widget[]
      }
  ) { }

  ngOnInit(): void {
    if (this.data?.dashboard) {
      this.editMode = true;

      this.dashboardForm.patchValue({
        name: this.data.dashboard.Name,
        type: ''
      });

      this.selectedWidgets = [...this.data.dashboard.Widgets];
    }
  }

  public onGroupsChange(options: MatListOption[]): void {
    this.selectedWidgets = options.map(o => o.value);
  }

  public removeWidget(widgetId: string): void {
    this.selectedWidgets = this.selectedWidgets.filter(widget => widget.Id !== widgetId);
  
    const widgetToRemove = this.selectionList.selectedOptions.selected.find(x => x.value.Id === widgetId);
    if (widgetToRemove) {
      widgetToRemove.selected = false;
    }
  }

  public continue(): void {
    if (!this.dashboardManagementView && !this.confirmChoice) {
      if (this.editMode && !this.checkIfDataChanged()) {
        this.dialogRef.close();
        return;
      }

      this.confirmChoice = true;
      return;
    }

    if (this.dashboardManagementView) {
      this.error = !this.dashboardForm.valid;

      if (this.error) return;

      this.dashboardManagementView = false;
      return;
    }

    if (this.confirmChoice) {
      this.handleConfirmChoice();
    }
  }

  private handleConfirmChoice(): void {
    const dashboardRequest: DashboardRequest = {
      Id: this.editMode ? this.data?.dashboard.Id : undefined,
      Name: this.dashboardForm.controls.name.value,
      Widgets: this.getWidgetPositions(this.selectedWidgets)
    }

    this.dialogRef.close(dashboardRequest);
  }

  private checkIfDataChanged(): boolean {
    const widgetsChanged: boolean = !this.selectedWidgets.every(widget => this.data?.dashboard?.Widgets.includes(widget));

    if (this.dashboardForm.controls.name.value === this.data.dashboard.Name && !widgetsChanged) {
      return false;
    }

    return true;
  }

  private getWidgetPositions(widgets: Widget[]): AssignedWidgets[] {
    let widgetsToAdd: AssignedWidgets[] = [];
    let assignedColumn: number = 1;
    let backupColumn: number = null;
    let widgetPositions: { [key: number]: number } = {};

    if (widgets?.length > 0) {
      widgets.forEach((widget) => {
        if (widget.Name === "Device" || widget.Name === "Imx Monitor") {
          if (this.data.deviceTypes?.length <= 4 && widget.Name === "Device") {
            assignedColumn = assignedColumn % 3 + 1;
          } else {
            backupColumn = assignedColumn;
            assignedColumn = 0;
          }
        }

        let widgetPositionInColumn = (widgetPositions[assignedColumn] || 0) + 1;
        widgetPositions[assignedColumn] = widgetPositionInColumn;

        let widgetToAdd: AssignedWidgets = {
          widgetId: widget.Id,
          Position: widgetPositionInColumn,
          Column: assignedColumn,
          Configuration: widget.Configuration ?? ''
        };

        widgetsToAdd.push(widgetToAdd);

        if (backupColumn) {
          assignedColumn = backupColumn;
          backupColumn = null;
        } else {
          assignedColumn = assignedColumn % 3 + 1;
        }
      });
    }

    return widgetsToAdd;
  }

  public checkIfSelected(): void {
    if (this.editMode) {
      this.selectionList?.selectAll();
      this.selectionList?.selectedOptions.selected.forEach(selectedOption => {
        const isSelected = this.selectedWidgets.map(w => w.Id).includes(selectedOption.value.Id);

        if (!isSelected) {
          selectedOption.selected = false;
        }
      });
    }
  }

  public cancel(): void {
    if (this.dashboardManagementView) {
      this.dialogRef.close();
    } else {
      this.selectedWidgets = [];
      this.confirmChoice = true;
    }
  }
}