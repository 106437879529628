<div class="container-fluid px-1 px-md-3" [ngClass]="{'h-100' : deviceNotificationsDataSource?.data.length === 0}">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span class="title2">{{ 'DEVICE_NOTIFICATIONS.TITLE' | translate }}</span>
            <span *ngIf="currentDevice" class="title2 appearingTextRight">
                {{ ' - ' + this.currentDevice.Name }}
            </span>
        </div>
    </div>

    <urban-searchbar id="intro-device-notifications-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div *ngIf="deviceNotificationsDataSource?.data.length > 0" class="d-flex flex-column">
        <mat-paginator showFirstLastButtons [pageSize]="myPageSize" [pageSizeOptions]="[10, 20, 50, 100]"
            [pageIndex]="myPageIndex" [length]="myTotalItems" (page)="getServerData($event)">
        </mat-paginator>

        <div class="customTable">
            <table class="w-100 h-100" mat-table [dataSource]="deviceNotificationsDataSource">

                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{item.Id}} </td>
                </ng-container>

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item" style="word-break: keep-all;"> {{item.Created * 1000 | date:'short'}} </td>
                </ng-container>

                <ng-container matColumnDef="Device">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{item.DeviceId}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="Command">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE_COMMAND' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <span class="link" style="word-break: keep-all;" (click)="goToDeviceCommandDetail(item.DeviceCommandId)">{{item.DeviceCommandId}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="CommandType">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE_COMMAND_TYPE' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{item.DeviceCommandType.Name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Body">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.JSON' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.Json" [matTooltipPosition]="'above'">
                        {{item.Json?.length > 15 ? item.Json.slice(0, 15) + "..." : item.Json }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}" (click)="openDetailDialog(item)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="deviceNotificationsDataSource?.data.length === 0">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
        <div class="d-flex">
            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                {{ 'GENERAL.NEW_SEARCH' | translate }}
            </button>
            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                {{ 'GENERAL.SHOW_DATA' | translate }}
            </button>
        </div>
    </div>
</div>
