import { MainSubscriptionsService } from './../../services/main-subscriptions/main-subscriptions.service';
import { Note, NEW_NOTE } from './../../models/Note';
import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';

@Component({
  selector: 'urban-note-widget',
  templateUrl: './note-widget.component.html',
  styleUrls: ['./note-widget.component.scss']
})
export class NoteWidgetComponent implements OnInit {

  @Input('notesList') public notes: Note[];

  public activeNote: string = '';
  public touchedNotes: number[] = [];
  public NOTES_LIMIT_VIEW: number = 4;
  public MAX_NOTES_LIMIT: number = 20;
  public temporaryNotes: Note[] = [];
  public visibleNotes: Note[] = [];
  public colors: string[] = ['lavender', 'lightRed', 'blue', 'yellow'];
  public dragEnabled: boolean = false;

  constructor(private mainService: MainSubscriptionsService, private router: Router)
  { }

  ngOnInit(): void {
    this.notes = this.notes.reduce((acc, note) => {
      if (!acc.find(n => n.id === note.id)) {
        acc.push(note);
      }
      return acc;
    }, []);
  
    for (let note of this.notes) {
      this.temporaryNotes.push(note);
    }

    this.visibleNotes = this.notes.slice(-4).slice();
  }

  public onMouseDown(): void {
    this.dragEnabled = true;
  }

  public addNote(): void {
    let newId: number = +Date.now();
    let maxPos = this.temporaryNotes.reduce((max, x) => x.position > max ? x.position : max, 0);

    this.temporaryNotes.push({ ...NEW_NOTE, id: newId, position: maxPos + 1 });
    this.visibleNotes = this.temporaryNotes.slice(-4).slice();
  }

  public deleteNote(passedId: number): void {
    if (this.notes.some(n => n.id === passedId)) {
      this.notes = this.notes.filter(note => note.id !== passedId);
      this.temporaryNotes = this.temporaryNotes.filter(note => note.id !== passedId);
      this.updateProfileNotes();
    } else if (!this.notes.some(n => n.id === passedId) && this.temporaryNotes.some(n => n.id === passedId)) {
      this.temporaryNotes = this.temporaryNotes.filter(note => note.id !== passedId);
    }

    this.visibleNotes = this.temporaryNotes.slice(-4).slice();
  }

  public editContentNote(id: number, text: string): void {
    this.temporaryNotes.find(note => note.id === id).content = text;
  }

  public changeColorNote(color: string, noteId: number, activeId: number): void {
    if (this.temporaryNotes.some(note => note.id === noteId)) {
      this.temporaryNotes.find(x => x.id === noteId).color = color;
      this.temporaryNotes.find(x => x.id === noteId).colorActive = activeId;
    }
    if (this.notes.some(note => note.id === noteId)) {
      this.notes.find(x => x.id === noteId).color = color;
      this.notes.find(x => x.id === noteId).colorActive = activeId;
    }
  }

  public updateNotesArray(passedId: number): void {
    let selectedNote = this.temporaryNotes.find(note => note.id === passedId);
    if (this.notes.includes(selectedNote)) {
      let elementIndex = this.notes.indexOf(selectedNote);
      this.notes.splice(elementIndex, 1, selectedNote);
      this.touchedNotes = this.touchedNotes.filter(x => x !== passedId);
      this.updateProfileNotes();
    } else {
      this.notes.push(selectedNote)
      this.touchedNotes = this.touchedNotes.filter(x => x !== passedId);
      this.updateProfileNotes();
    }
  }

  private updateProfileNotes(): void {
    this.mainService.changeProfileNotesComand(this.notes);
    this.activeNote = '';
  }

  public addModifiedNote(noteId: number): void {
    this.touchedNotes.push(noteId)
  }

  public drop(event: CdkDragDrop<Note[]>): void {
    if (this.dragEnabled) {
      moveItemInArray(this.temporaryNotes, event.previousIndex, event.currentIndex);

      for (let temp of this.temporaryNotes) {
        temp.position = this.temporaryNotes.indexOf(temp);
      }

      this.notes = this.temporaryNotes.filter(note => this.notes.some(x => x.id === note.id));
      this.updateProfileNotes();
      this.dragEnabled = false;
    }
  }

  public goToNotesPage(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'dashboard' });
    this.router.navigate(['main/notes']);
  }
}
