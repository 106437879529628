<div class="bg-login d-flex justify-content-center align-items-center">
    <mat-card class="d-flex flex-row p-0 m-4">
        <div class="recoveryMainSection p-4">
            <mat-card-title>{{ 'RECOVERY.TITLE' | translate }}</mat-card-title>
            <mat-card-content>
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <p>
                        <mat-form-field>
                            <mat-label>{{ 'RECOVERY.NEW_PASSWORD' | translate }}</mat-label>
                            <input [type]="hideNewPassword ? 'password' : 'text'" matInput formControlName="newPassword">
                            <i class="passwordIcon" [ngClass]="hideNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" matSuffix (click)="hideNewPassword = !hideNewPassword"></i>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field>
                            <mat-label>{{ 'RECOVERY.CONFIRMED_NEW_PASSWORD' | translate }}</mat-label>
                            <input [type]="hideConfirmedNewPassword ? 'password' : 'text'" matInput formControlName="confirmedNewPassword">
                            <i class="passwordIcon" [ngClass]="hideConfirmedNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" matSuffix (click)="hideConfirmedNewPassword = !hideConfirmedNewPassword"></i>
                        </mat-form-field>
                    </p>

                    <!-- <p *ngIf="error" class="error">
                        {{ 'RECOVERY.' + error | translate }}
                    </p> -->
                    <mat-error *ngIf="error && error !== undefined && error !== ''" class="error">
                        {{ error }}
                    </mat-error>

                    <p *ngIf="success" class="success">
                        {{ 'RECOVERY.' + success | translate  }}
                    </p>

                    <div class="custom-button d-flex justify-content-between p-4">
                        <button mat-button type="button" class="mat-primary" routerLink="../login">{{ 'GENERAL.BACK' | translate }}</button>
                        <button id="confirmButton" mat-button type="submit">{{ 'RECOVERY.CONFIRM' | translate }}</button>
                    </div>
                </form>
            </mat-card-content>
        </div>
        <div class="recoveryExtraSection p-4 d-flex flex-column" *ngIf="!isLittleScreen">
            <h6>{{ 'RECOVERY.EXTRA_TITLE' | translate }}</h6>
            <p class="darkText">{{ 'RECOVERY.EXTRA_SUBTITLE' | translate }}</p>
        </div>
    </mat-card>
</div>
