import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceEventResponse } from '../../../shared/models/deviceEventListResponse';
import { DeviceEventDetailDialogComponent } from '../../../shared/dialogs/device-event-detail-dialog/device-event-detail-dialog.component';
import { DeviceEvent } from '../../../shared/models/deviceEvent';
import { Domain } from '../../../shared/models/domain';
import { DomainEventsRequest } from '../../../shared/models/domainEventsRequest';
import { ApiService } from '../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { PaginationInstance } from 'ngx-pagination';
import { MatInput } from '@angular/material/input';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';

@Component({
  selector: 'urban-domain-device-events',
  templateUrl: './domain-device-events.component.html',
  styleUrls: ['./domain-device-events.component.scss']
})
export class DomainDeviceEventsComponent implements OnInit, OnDestroy {
  @ViewChild('filterInput') private filterInput: MatInput;

  public events: DeviceEvent[] = [];
  public displayedColumns: string[] = ['Id', 'Device', 'Created', 'Type', 'Level', 'Detail'];
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public startDate: number;
  public endDate: number;
  public actualFilter: string = '';
  public lastFilter: string;
  public deviceTypes: string[] = [];
  public eventTypes: string[] = [];
  public eventLevels: string[] = [];
  public filterForm: UntypedFormGroup = this.formBuilder.group({
    deviceType: '',
    eventType: '',
    eventLevel: ''
  });
  public orderColumn: string = null;
  public orderDirection: string = null;
  public isOnlyDomainAdmin: boolean = false;
  public currentDomain: Domain;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  private subscription: Subject<void> = new Subject<void>();
  public eventLevelColors: string[] = [];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  constructor(
    private apiService: ApiService,
    private administratorApiService: AdministratorApiService,
    private dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domain => {
      if (domain) {
        this.currentDomain = domain;
        let firstRequest: DomainEventsRequest = {
          Page: this.config.currentPage - 1,
          Limit: this.config.itemsPerPage,
          Filter: ''
        };

        this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
          if (response && response.length > 0) {
            if (response.some(x => x.Name == 'Administrators')) {
              this.isOnlyDomainAdmin = false;
            }
            else {
              this.isOnlyDomainAdmin = true;
            }

            if (!(response?.length > 0 && response.some(role => ['Administrators', 'Domainv admin'].includes(role.Name)))) {
              this.setErrorAndGoToMain();
              return;
            }

            this.apiService.domainEvents(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
              if(res) {
                this.config.totalItems = res.Total;
                this.events = res.Events;
                this.setSelectInputs();
              }
              else {
                this.events = [];
              }
            });
          } else {
            this.resetSelectInputs();
          }
        });

        this.passDataService.currentDarkModeStatus$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(res => {
            this.isDarkActive = res === true;
          });
      }
      this.eventLevelColors = this.getEventLevelColors();
      this.loadingData = false;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('GENERAL.FILTER').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Filter') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.filterForm.valueChanges.subscribe((newFilterValues: Record<string,string>) => {
      if (Object.values(newFilterValues).some(value => value !== null)) {
        this.config.currentPage = 1;
        this.newSearch();
      }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;
    this.applyFilter('');
    this.filterForm.reset();

    this.apiService.domainEventsLatest24Hours().pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventResponse) => {
      this.setLatestDates(res);

      if (res && res.Events.length > 0) {
        this.config.totalItems = res.Total;
        this.events = res.Events;
        this.lastFilter = this.actualFilter;
        this.setSelectInputs();
      }
      else {
        this.events = [];
        this.resetSelectInputs();
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventResponse): void {
    this.endDate = res.Events[0].Created;
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.applyFilter('');
      this.filterForm.reset();
    }

    let serverRequest: DomainEventsRequest = {
      Page: this.config.currentPage - 1,
      Limit: this.config.itemsPerPage,
      Filter: this.actualFilter,
      Start: this.startDate,
      End: this.endDate,
      ...this.filterForm.value
    };

    this.apiService.domainEvents(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if(res) {
        this.config.totalItems = res.Total;
        this.events = res.Events;
        this.lastFilter = this.actualFilter;
        this.setSelectInputs();
      }
      else {
        this.events = [];
        this.resetSelectInputs();
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  public getServerData(newPageIndex: number): void {
    this.config.currentPage = newPageIndex;
    this.newSearch();
  }

  private setSelectInputs(): void {
    this.events.forEach((event: DeviceEvent) => {
      if (!this.deviceTypes.includes(event.DeviceType)) {
        this.deviceTypes.push(event.DeviceType);
      }
      if (!this.eventTypes.includes(event.Type)) {
        this.eventTypes.push(event.Type);
      }
      if (!this.eventLevels.includes(event.Level)) {
        this.eventLevels.push(event.Level);
      }
    });
  }

  private resetSelectInputs(): void {
    this.deviceTypes = [];
    this.eventTypes = [];
    this.eventLevels = [];
  }

 applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.actualFilter = filterValue;

    if (filterValue === '' && this.filterInput?.value && this.filterInput.value.trim() !== '') {
      this.filterInput.value = '';
    }
  }

  openDetailDialog(singleEvent: DeviceEvent): void {
    this.dialog.open(DeviceEventDetailDialogComponent, {
      data: { targetEvent: singleEvent },
      maxWidth: 1000,
      width: '70%'
    });
  }

  openDeviceDetail(singleEvent: DeviceEvent): void {
    this.mainService.setNavigationInfoComand({ Id: singleEvent.DeviceId, BackRoute: 'domain-events' });
    this.router.navigate(['main/device']);
  }

  public deleteEvent(eventId: string): void {
    if (!this.isOnlyDomainAdmin) {
      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      })

      confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if(result){
          this.administratorApiService.deviceEventDelete(eventId)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadLatestData();
          });
        }
      });
    }
  }

  // private fromTableToFilter(newFilter: string): void {
  //   this.actualFilter = newFilter;
  //   (document.getElementById('filterInput') as HTMLInputElement).value = newFilter;
  //   this.newSearch();
  // }

  private getEventLevelColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push('#BC9A29');

    return colors;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.config.totalItems / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
