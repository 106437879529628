import { createAction, props } from '@ngrx/store';
import { Configuration } from '../../shared/models/configuration';
import {Theme} from "../../shared/models/theme";

export const getConfiguration = createAction(
    '[App] Get Configuration'
)

export const setConfiguration = createAction(
    '[App] Set Configuration',
    props<Configuration>()
)

export const resetReducers = createAction(
    '[RESET] All'
)

export const getHostname = createAction(
  '[App] Get Hostname'
)

export const setTheme = createAction(
  '[App] Set Theme',
  props<{theme: Theme}>()
)

export const updateHostname = createAction(
  '[App] Update Hostname',
  props<{ hostname: string }>()
)

