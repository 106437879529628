<div>
    <mat-dialog-content class="alertEventDialog position-relative d-flex flex-column">
        <h1 mat-dialog-title>{{ 'REPORT.' + selectedType[0].Model.Type.Name | translate }} - {{'ALERT_EVENTS_WIDGET.ERRORS' | translate}}</h1>

        <div class="containerList mt-4">
            <div class="d-flex" *ngFor="let errorDevice of selectedType">
                <i class="urbanicon-alert"></i>
                <p class="deviceName">{{errorDevice.Model.Name}}</p>
                <p *ngIf="isAdmin" class="ml-auto mr-2 link" (click)="goToDetail(errorDevice.Id)">{{'NOTIFICATION_ALL.DETAIL' | translate}}</p>
            </div>
        </div>


    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-button class="btn outlineActionButton" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate}}</button>
    </div>
</div>
