import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WeatherStationRequest } from '../../models/weatherstation/weatherstationRequest';

@Injectable({
    providedIn: 'root'
  })

  export class WeatherStationSubscriptionsService {
    private subjectGetWSSensors = new Subject<WeatherStationRequest>();
    private subjectDeleteWSSensorsState = new Subject<boolean>();

    constructor() { }
  
    public getWSSensorsComand(request: WeatherStationRequest): void {
      this.subjectGetWSSensors.next(request);
    }
    public getGetWSSensorsComand(): Observable<WeatherStationRequest> {
      return this.subjectGetWSSensors.asObservable();
    }

    public deleteWSSensorsStateComand(comand: boolean): void {
      this.subjectDeleteWSSensorsState.next(comand);
    }
    public getWSSensorsStateComand():Observable<boolean>  {
      return this.subjectDeleteWSSensorsState.asObservable();
    }
  }