import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserRoles} from "../../../../shared/models/userRoles";
import {Subject} from "rxjs";
import {PaginationInstance} from "ngx-pagination";
import {Configuration} from "../../../../shared/models/configuration";
import {Router} from "@angular/router";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {EntityResponse} from "../../../../shared/models/content-management/entityResponse";

@Component({
  selector: 'urban-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit, OnDestroy {

  columns = ['Name', 'Created', 'Updated', 'Detail'];
  public entities: EntityResponse[] = [];
  public userRoles: UserRoles['Roles'];
  public filteredData: EntityResponse[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  private appConfig: Configuration;

  constructor(
    private router: Router,
    private contentManagementService: ContentManagementService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this._initList();

      this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
        this.appConfig = config;
      });
    });
  }

  public add(): void {
    if (!this.userRoles.some(x => x.Name === 'Administrators')) {
      this.mainService.setNavigationInfoComand();
      this.router.navigate(['main/dashboard']);
    }

    this.mainService.setNavigationInfoComand({Id: "0", BackRoute: 'content-management/entities'});
    this.router.navigate(['main/content-management/entity']);
  }

  private _initList(): void {
    this.contentManagementService.listEntity().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x && x.length > 0) {
        this.entities = x;
        this.filteredData = this.entities;
      }
    });
  }

  public detail(entity: EntityResponse): void {
    if (!entity) {
      return;
    }

    if (!this.userRoles.some(x => x.Name === 'Administrators')) {
      this.mainService.setNavigationInfoComand();
      this.router.navigate(['main/dashboard']);
    }

    this.mainService.setNavigationInfoComand({Id: entity.Id.toString(), BackRoute: 'content-management/entities'});
    this.router.navigate(['main/content-management/entity']);
  }

  public delete(entity: EntityResponse): void {
    if (!entity) {
      return;
    }

    if (this.userRoles.some(x => x.Name === 'Administrators')) {
      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.contentManagementService.deleteEntity(entity.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this._initList();
            }
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.entities?.filter((key: EntityResponse) =>
      [
        key.Name?.toLowerCase(), 
        key.Name?.toLowerCase(),
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

