<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">
        <!-- <mat-card *ngIf="stepper === 0" [@stepper] class="d-flex flex-column p-0 m-4"> -->
        <mat-card *ngIf="stepper === 0" class="d-flex flex-column p-0 m-4">
            <div class="forgotExtraSection d-flex flex-column p-4" *ngIf="!isLittleScreen">
                <h6>{{ 'FORGOT.EXTRA_TITLE' | translate }}</h6>
                <p class="darkText">{{ 'FORGOT.EXTRA_SUBTITLE' | translate }}</p>
            </div>
            <div class="d-flex flex-column forgotMainSection p-4">
                <mat-card-title style="font-size: larger;">{{ 'FORGOT.TITLE' | translate}}</mat-card-title>
                <p *ngIf="isLittleScreen">{{ 'FORGOT.EXTRA_SUBTITLE' | translate }}</p>
                <mat-card-content class="position-relative">
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <p>
                            <mat-form-field>
                                <mat-label>{{ 'FORGOT.MAIL' | translate }}</mat-label>
                                <input type="email" matInput formControlName="mail" autocomplete="off">
                            </mat-form-field>
                        </p>

                        <mat-error *ngIf="isLittleScreen && errorInRequest" class="mb-4">
                            {{ 'ERROR.BAD_REQUEST' | translate }}
                        </mat-error>
                        <mat-error *ngIf="isLittleScreen && !this.correctInput" class="mb-4">
                            {{ 'ERROR.WRONG_CREDENTIALS' | translate }}
                        </mat-error>

                        <div class="custom-button d-flex justify-content-between">
                            <button mat-button type="button" class="mat-primary" routerLink="../login">{{ 'GENERAL.BACK' | translate }}</button>
                            <button id="sendButton" mat-button type="submit" [disabled]="isLoading">
                                <span class="material-icons rotating" *ngIf="isLoading">
                                    loop
                                </span>
                                {{ 'FORGOT.SEND' | translate }}
                            </button>
                        </div>
                    </form>

                    <mat-error *ngIf="!isLittleScreen && errorInRequest" class="bigScreenError">
                        {{ 'ERROR.BAD_REQUEST' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!isLittleScreen && !this.correctInput" class="bigScreenError">
                        {{ 'ERROR.WRONG_CREDENTIALS' | translate }}
                    </mat-error>

                </mat-card-content>
            </div>

        </mat-card>
        <!-- <mat-card *ngIf="stepper === 1" [@stepper]> -->
            <mat-card class="checkCard" *ngIf="stepper === 1">
            <mat-card-content>
                <div class="bold">{{ 'FORGOT.CHECK' | translate }}</div>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button type="button" class="mat-primary" routerLink="../login">{{ 'GENERAL.BACK' | translate }}</button>
            </mat-card-actions>
        </mat-card>
        <br>
    </div>

</div>
