<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span *ngIf="crosswalkDevice" class="title2 appearingTextLeft">{{ this.crosswalkDevice.Name }} -</span>
            <span class="title2">{{ 'GENERAL.DETAIL' | translate }}</span>
        </div>
    </div>

    <urban-searchbar id="intro-crosswalk-detail-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
            [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
            (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <mat-tab-group id="intro-crosswalk-detail-tab" animationDuration="0ms" dynamicHeight="true">
        <mat-tab [label]="'GENERAL.LAST_DATA' | translate">
            <div class="tabContent" *ngIf="crosswalkDataSource">
                <div *ngIf="crosswalkDataSource.data.length > 0" class="customTable">
                    <table id="intro-traffic-table" class="w-100 h-100 noTableHover" mat-table [dataSource]="crosswalkDataSource">
                        <ng-container matColumnDef="Traffic light color">
                            <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                {{ 'SMART_TRAFFIC.VEHICLE' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{ ('CROSSWALK.' + item.Color.toUpperCase()) | translate }} </td>
                        </ng-container>

                        <ng-container matColumnDef="Total crosswalks">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                {{ 'SMART_TRAFFIC.PERCENTAGE_LEVEL' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                {{ item.Total }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Percentage crosswalks">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                {{ 'SMART_TRAFFIC.PERCENTAGE_LEVEL' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                {{ item.Percentage }}%
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>

                <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="crosswalkDataSource.data.length === 0">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <div>
                        <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex noDataActions">
                            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
            <div *ngIf="chartsTab.isActive" class="tabContent">
                <div *ngIf="crosswalkEvents" class="mt-3">
                    <div class="roundedPanel panelBG chartContainer">
                        <urban-google-areachart *ngIf="readyToShow && translationsReady" areaChartTitle="CROSSWALK.AREA_CHART_TITLE"
                            [passedElementsDescription]="dataDescriptions" [passedDataList]="historyDataToPass"
                            [darkThemeActive]="isDarkActive">
                        </urban-google-areachart>
                    </div>

                    <div *ngIf="readyToShow && translationsReady" class="roundedPanel panelBG chartContainer">
                        <urban-google-piechart passedPieChartTitle='CROSSWALK.AVERAGE_PER_COLOR'
                            [pieDataPassed]="averageDataToPass" [passedElementsDescription]="dataDescriptions"
                            [darkThemeActive]="this.isDarkActive" [pieChartId]="crosswalkName">
                        </urban-google-piechart>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="readyToShow === false">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <div>
                        <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex noDataActions">
                            <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
