import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {MainState} from "../../store/main/main.reducer";
import {LoaderService} from "./loader/loader.service";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import * as MainActions from "../../store/main/main.actions";
import {BroadcastRequest} from "../models/content-management/broadcastRequest";
import {BroadcastResponse} from "../models/content-management/broadcastResponse";
import {BroadcastClientResponse} from "../models/content-management/broadcastClientResponse";
import {BroadcastClientRequest} from "../models/content-management/broadcastClientRequest";
import {BroadcastClientType} from "../models/content-management/broadcastClientType";
import {ScheduleRule} from "../models/content-management/scheduleRule";
import {ChannelResponse} from "../models/content-management/channelResponse";
import {ChannelRequest} from "../models/content-management/channelRequest";
import {ChannelPropertyRequest} from "../models/content-management/channelPropertyRequest";
import {BroadcastClientPropertyRequest} from "../models/content-management/broadcastClientPropertyRequest";
import {ContentPropertyRequest} from "../models/content-management/contentPropertyRequest";
import {ContentResponse} from "../models/content-management/contentResponse";
import {ContentRequest} from "../models/content-management/contentRequest";
import {EntityRequest} from "../models/content-management/entityRequest";
import {EntityResponse} from "../models/content-management/entityResponse";

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService {

  constructor(private http: HttpClient, private store: Store<MainState>, private readonly loaderService: LoaderService) {
  }

  public addBroadcast(request: BroadcastRequest): Observable<any> {
    return this.http.post<BroadcastRequest>(
      `uv.cms.broadcast.add.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateBroadcast(request: BroadcastRequest): Observable<any> {
    return this.http.post<BroadcastRequest>(
      `uv.cms.broadcast.update.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteBroadcast(content: string, channel: string): Observable<any> {
    return this.http.get<any>(
      `uv.cms.broadcast.delete.api?content=${content}&channel=${channel}`
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listBroadcast(content?: string, channel?: string): Observable<BroadcastResponse[]> {
    return this.http.get<any>(
      `uv.cms.broadcast.list.api?content=${content}&channel=${channel}`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addBroadcastClient(request: BroadcastClientRequest): Observable<any> {
    return this.http.post<BroadcastClientRequest>(
      `uv.cms.broadcast.client.add.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateBroadcastClient(request: BroadcastClientRequest): Observable<any> {
    return this.http.post<BroadcastClientRequest>(
      `uv.cms.broadcast.client.update.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteBroadcastClient(id: string): Observable<any> {
    return this.http.get<any>(
      `uv.cms.broadcast.client.delete.api?id=${id}`
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public propertySetBroadcastClient(request: BroadcastClientPropertyRequest): Observable<any> {
    return this.http.post<BroadcastClientPropertyRequest>(
      `uv.cms.broadcast.client.property.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listBroadcastClient(broadcastClientType: number = 0): Observable<BroadcastClientResponse[]> {
    return this.http.get<any>(
      `uv.cms.broadcast.client.list.api?t=${broadcastClientType}`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getBroadcastClient(id: string): Observable<BroadcastClientResponse> {
    return this.http.get<any>(
      `uv.cms.broadcast.client.get.api?id=${id}`
    ).pipe(
      map((res: any) => res.BroadcastClient),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addBroadcastClientType(request: BroadcastClientType): Observable<any> {
    return this.http.post<BroadcastClientType>(
      `uv.cms.broadcast.client.type.add.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateBroadcastClientType(request: BroadcastClientType): Observable<any> {
    return this.http.post<BroadcastClientType>(
      `uv.cms.broadcast.client.type.update.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteBroadcastClientType(id: string): Observable<any> {
    return this.http.get<any>(
      `uv.cms.broadcast.client.type.delete.api?id=${id}`
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listBroadcastClientTypes(): Observable<BroadcastClientType[]> {
    return this.http.get<any>(
      `uv.cms.broadcast.client.type.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addScheduleRule(request: ScheduleRule): Observable<any> {
    return this.http.post<ScheduleRule>(
      `uv.cms.schedulerule.add.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateScheduleRule(request: ScheduleRule): Observable<any> {
    return this.http.post<ScheduleRule>(
      `uv.cms.schedulerule.update.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteScheduleRule(id: number): Observable<any> {
    return this.http.get<any>(
      `uv.cms.schedulerule.delete.api?id=${id}`
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listScheduleRule(): Observable<ScheduleRule[]> {
    return this.http.get<any>(
      `uv.cms.schedulerule.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addChannel(request: ChannelRequest): Observable<any> {
    return this.http.post<ChannelRequest>(
      `uv.cms.channel.add.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateChannel(request: ChannelRequest): Observable<any> {
    return this.http.post<ChannelRequest>(
      `uv.cms.channel.update.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteChannel(id: string): Observable<any> {
    return this.http.get<any>(
      `uv.cms.channel.delete.api?id=${id}`
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listChannel(): Observable<ChannelResponse[]> {
    return this.http.get<any>(
      `uv.cms.channel.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getChannel(id: string): Observable<ChannelResponse> {
    return this.http.get<any>(
      `uv.cms.channel.get.api?id=${id}`
    ).pipe(
      map((res: any) => res.Channel),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public propertySetChannel(request: ChannelPropertyRequest): Observable<any> {
    return this.http.post<ChannelPropertyRequest>(
      `uv.cms.channel.property.api`,
      request,
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addContent(request: ContentRequest): Observable<any> {
    return this.http.post<any>(
      `uv.cms.content.add.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateContent(request: ContentRequest): Observable<any> {
    return this.http.post<any>(
      `uv.cms.content.update.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteContent(id: string): Observable<any> {
    return this.http.get<any>(
      `uv.cms.content.delete.api?id=${id}`
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listContent(tags: string = '', broadcastClient: string = '', mimeType: string = ''): Observable<ContentResponse[]> {
    return this.http.get<any>(
      `uv.cms.content.list.api?tags=${tags}&client=${broadcastClient}&type=${mimeType}`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getContent(id: string): Observable<ContentResponse> {
    return this.http.get<any>(
      `uv.cms.content.get.api?id=${id}`
    ).pipe(
      map((res: any) => res.Content),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public propertySetContent(request: ContentPropertyRequest): Observable<any> {
    return this.http.post<ContentPropertyRequest>(
      `uv.cms.content.property.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getContentData(contentId: string): Observable<any> {
    return this.http.get<any>(
      `download/cmsweb?id=${contentId}`
    ).pipe(
      map((res: any) => {
        if (!(res instanceof Blob)) {
          return res;
        }
      }),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public downloadContent(contentId: string, fileExtension?: string): Observable<any> {
    const HTTPOptions = fileExtension && fileExtension !== 'json' ? {
      headers: new HttpHeaders({'Accept': `${this._getContentDisposition(fileExtension)}; charset=UTF-8`,}),
      observe: "response" as 'body',// to display the full response & as 'body' for type cast
      'responseType': 'blob' as 'json'
    } : {}

    return this.http.get<any>(
      `download/cmsweb?id=${contentId}`,
      HTTPOptions
    ).pipe(
      map((res: any) => {
        let dataType = '';
        const binaryData = [];
        let filename = '';
        const contentDisposition = res.headers?.get('Content-Disposition');

        if (res instanceof Blob) {
          res.arrayBuffer().then(arrayBuffer => {
            dataType = res.type;
            binaryData.push(arrayBuffer);
            filename = contentDisposition;
          }).catch(err => console.error(err));

        } else {
          dataType = res.body?.type || 'application/json';
          binaryData.push(res.body || this._jsonToByteArray(JSON.stringify(res)));
        }

        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', contentDisposition || `${contentId}.json`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public listEntity(): Observable<EntityResponse[]> {
    return this.http.get<any>(
      `uv.cms.entity.list.api`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getEntity(id: number): Observable<EntityResponse> {
    return this.http.get<any>(
      `uv.cms.entity.get.api?id=${id}`
    ).pipe(
      map((res: any) => res.Item),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public addEntity(request: EntityRequest): Observable<any> {
    return this.http.post<EntityRequest>(
      `uv.cms.entity.add.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public updateEntity(request: EntityRequest): Observable<any> {
    return this.http.post<EntityRequest>(
      `uv.cms.entity.update.api`,
      request
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public deleteEntity(id: number): Observable<any> {
    return this.http.get<any>(
      `uv.cms.entity.delete.api?id=${id}`
    ).pipe(
      map((_: any) => _),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  _jsonToByteArray(jsonContent: string): Uint8Array {
    const utf8Encode = new TextEncoder();
    return utf8Encode.encode(jsonContent);
  }

  _getContentDisposition(fileExtension : string) : string {
    if(!fileExtension){
      return 'application/json';
    }

    switch (fileExtension){
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      case 'mp4':
        return 'video/mp4';
      default:
        return 'application/json';
    }
  }
}
