<div class="container-fluid">
    <div class="row d-flex justify-content-between bg-light-grey border-radius-15 mx-0 pageTop">
        <span class="color-dark title2">
            {{ 'USER.TITLE' | translate }}
        </span>
    </div>

    <div class="devices-table mat-elevation-z4">
        <table class="w-100" mat-table [dataSource]="myUsersDataSource">
            <!-- Name Column -->
            <ng-container matColumnDef="Username">
                <th mat-header-cell *matHeaderCellDef> {{ 'USER.USERNAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <a> {{ element.Username }}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef> {{ 'USER.ID' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="Country">
                <th mat-header-cell *matHeaderCellDef> {{ 'USER.COUNTRY' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.Country}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedUsersColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedUsersColumns;"></tr>
        </table>
    </div>
</div>
