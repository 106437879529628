<div class="container">
    <div class="d-flex justify-content-between">
        <h1 mat-dialog-title>{{'DEVICE_NOTIFICATION.TITLE' | translate }}</h1>
    </div>

    <mat-dialog-content>
        <form [formGroup]="detailGroup">
            <div class="myFormContainer">
                <div class="d-flex flex-column justify-content-between box">
                    <mat-form-field>
                        <mat-label>{{'GENERAL.ID' | translate }}</mat-label>
                        <input matInput formControlName="id" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'GENERAL.DEVICE_ID' | translate }}</mat-label>
                        <input matInput formControlName="deviceId" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'GENERAL.DEVICE_COMMAND_ID' | translate }}</mat-label>
                        <input matInput formControlName="deviceCommandId" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'GENERAL.DEVICE_COMMAND_TYPE' | translate }}</mat-label>
                        <input matInput formControlName="deviceCommandType" autocomplete="off" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'GENERAL.CREATED' | translate }}</mat-label>
                        <input matInput formControlName="created" autocomplete="off" readonly>
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div class="myErrorSection warnColorBG d-flex justify-content-center" *ngIf="showError">
            <span style="white-space: pre-wrap" class="p-3 m-0">{{ this.deviceCommandJson?.Error }}</span>
        </div>
        <div class="myMessageSection panelAccentBG d-flex justify-content-center" *ngIf="showBody">
            <span style="white-space: pre-wrap" class="p-3 m-0">{{ this.deviceCommandJson?.Body }}</span>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-button class="btn outlineActionButton" [mat-dialog-close]="true">{{'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>
