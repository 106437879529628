<div class="container-fluid p-3">
    <span class="m-0 subheadText">{{ chartTitle | translate }}{{ chartSubtitle ? ' - ' + (chartSubtitle | translate) : '' }}</span>
    <div [ngClass]="noDataOnChart ? '' : 'py-4 my-3'">
        <div class="sankeyChartContainer">
            <canvas id="sankeyChart"></canvas>
        </div>
    </div>

    <div *ngIf="noDataOnChart" class="my-3">
        <p *ngIf="noDataOnChart">{{ 'STATISTICS.NO_CHART_DATA' | translate }}</p>
    </div>
</div>
