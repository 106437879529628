<h1 mat-dialog-title>{{ 'AROUND_ME_DIALOG.TITLE' | translate }}</h1>
<mat-dialog-content [formGroup]="form">

    <div *ngIf="addressVisible">
        <!-- <urban-google-address (setLatLng)="setLatLng($event)"></urban-google-address> -->
        <mat-form-field>
            <mat-label>{{ 'DEVICE.ADDRESS' | translate }}</mat-label>
            <input matInput id="autocomplete" type="text">
        </mat-form-field>
    </div>

    <mat-form-field>
        <mat-label>{{ 'DEVICE.LATITUDE' | translate }}</mat-label>
        <input matInput type="number" formControlName="latitude" type="text" autocomplete="off" required [readonly]="positionBlocked">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'DEVICE.LONGITUDE' | translate }}</mat-label>
        <input matInput type="number" formControlName="longitude" type="text" autocomplete="off" required [readonly]="positionBlocked">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Range Km²</mat-label>
        <input matInput type="number" formControlName="range" autocomplete="off">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'DEVICE.FILTER_BY_NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="device" autocomplete="off">
    </mat-form-field>

    <mat-error *ngIf="!correctInput">
        {{ 'AROUND_ME_DIALOG.ERROR' | translate }}
    </mat-error>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button class="btn actionButton" (click)="search()">{{ 'GENERAL.SEARCH' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
