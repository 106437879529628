<div class="container-fluid h-100">
    <div class="d-flex flex-column justify-content-center align-items-center noDataIllustration responsiveIllustration">
        <img [src]="isDarkActive ?  '/assets/img/under-constructionDark.png' : '/assets/img/under-constructionLight.png'" alt="under-costruction"/>
        <h2>{{ 'WIP.UNDER_CONSTRUCTION' | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'WIP.SOON_AVAILABLE' | translate }}</h6>
        <div>
            <button type="button" mat-button class="btn outlineActionButton" (click)="goToMain()">{{ 'GENERAL.RETURN_DASHBOARD' | translate }}</button>
        </div>
    </div>
</div>
