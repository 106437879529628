import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SMSSReseller } from '../../../../shared/models/smss/smssReseller';
import { MatTableDataSource } from '@angular/material/table';
import { WifiSmssService } from '../../../../shared/services/wifi-smss.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'urban-resellers',
  templateUrl: './resellers.component.html',
  styleUrls: ['./resellers.component.scss']
})
export class ResellersComponent implements OnInit, OnDestroy {

  displayedColumns = ['CompanyName', 'EmailAddress', 'Detail'];
  public resellers: SMSSReseller[] = [];
  public myDataSource = new MatTableDataSource(this.resellers);
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private wifiSMSSService: WifiSmssService,
  ) {}

  ngOnInit(): void {
    this.wifiSMSSService.getSMSSResellers().pipe(takeUntil(this.ngUnsubscribe)).subscribe( resellers => {
      this.myDataSource = new MatTableDataSource(resellers),
      this.resellers = resellers
    })
  }

  goToManagers(resellerId: string): void {
    let resellerToShow = this.resellers.find(x => x.id == resellerId);
    //this.wifiSMSSSubscriptionService.setResellerToShowComand(resellerToShow);
    this.router.navigate(['main/net-wifi/managers', { id: resellerToShow.id }]);
  }

  goToAdvertisers(resellerId: string): void {
    let resellerToShow = this.resellers.find(x => x.id == resellerId);
    //this.wifiSMSSSubscriptionService.setResellerToShowComand(resellerToShow);
    this.router.navigate(['main/advertisers', { id: resellerToShow.id }]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
