<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'CONTENT_MANAGEMENT.CHANNEL' | translate }} : {{channel?.Name}}
        </span>

        <div>
            <button mat-button class="btn actionButton" (click)="openAddProperty()">
                {{ 'GENERAL.ADD_PROPERTY' | translate }}
            </button>
        </div>
    </div>

    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput formControlName="description"
                      matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=20 autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.LANGUAGE' | translate }}</mat-label>
            <mat-select formControlName="language">
                <mat-option *ngFor="let language of languages" value={{language.Id}}>
                    {{language.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'CONTENT_MANAGEMENT.BROADCAST_CLIENT' | translate }}</mat-label>
            <mat-select formControlName="broadcastClient">
                <mat-option *ngFor="let broadcastClientElement of broadcastClients" value={{broadcastClientElement.Id}}>
                    {{broadcastClientElement.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <button mat-button class="btn actionButton mb-3" type="button" id="updateButton"
                (click)="update()">
            {{ 'GENERAL.UPDATE' | translate }}
        </button>
    </form>

    <div class="customTable mt-5">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'GENERAL.PROPERTIES' | translate }}</span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                       autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                        (click)="applyFilterString(''); actualFilter = ''"
                        matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none noTableHover" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Key">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.KEY' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.key}} </td>
                </ng-container>

                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.VALUE' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.value.length > 20 ? element.value.slice(0, 20) +
                        "..." : element.value }} </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="iconInfoButton urbanicon-info-outline"
                               matTooltip="{{'GENERAL.DETAIL' | translate }}"
                               (click)="openDetailProperty(element)">
                            </i>
                            <i class="iconDeleteButton urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                               (click)="deleteProperty(element)">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                                     [maxSize]="7"
                                     [directionLinks]="true"
                                     [previousLabel]="''"
                                     [nextLabel]="''"
                                     [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                                     [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                                     [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                                     (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase() : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="config.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
