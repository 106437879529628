<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.OLD_PASSWORD' | translate }}</mat-label>
        <input [type]="hideOldPassword ? 'password' : 'text'" matInput
            formControlName="oldPassword" autocomplete="off">
        <i class="passwordIcon" matSuffix [ngClass]="hideOldPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" (click)="hideOldPassword = !hideOldPassword"></i>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.NEW_PASSWORD' | translate }}</mat-label>
        <input [type]="hideNewPassword ? 'password' : 'text'" matInput
            formControlName="newPassword" autocomplete="off">
        <i class="passwordIcon" matSuffix [ngClass]="hideNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" (click)="hideNewPassword = !hideNewPassword"></i>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'CHANGE_PASSWORD_DIALOG.CONFIRMED_NEW_PASSWORD' | translate }}</mat-label>
        <input [type]="hideConfirmedNewPassword ? 'password' : 'text'" matInput
            formControlName="confirmedNewPassword" autocomplete="off">
        <i class="passwordIcon" matSuffix [ngClass]="hideConfirmedNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" (click)="hideConfirmedNewPassword = !hideConfirmedNewPassword"></i>
    </mat-form-field>

    <mat-error *ngIf="error">
        {{ 'CHANGE_PASSWORD_DIALOG.' + error | translate }}
    </mat-error>

    <!-- <p *ngIf="success" class="success">
        {{ success }}
    </p> -->

</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="change()">{{ 'CHANGE_PASSWORD_DIALOG.CONFIRM' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'CHANGE_PASSWORD_DIALOG.CANCEL' | translate }}</button>
</div>
