<h1 *ngIf="addView" mat-dialog-title>{{ 'GENERAL.ADD' | translate }} {{ 'SERVICE_TYPE_DIALOG.SERVICE_TYPE' | translate | lowercase }}</h1>
<h1 *ngIf="!addView" mat-dialog-title>{{ 'GENERAL.UPDATE' | translate }} {{ 'SERVICE_TYPE_DIALOG.SERVICE_TYPE' | translate | lowercase }}</h1>
<mat-dialog-content>
    <form [formGroup]="form" class="d-flex flex-column">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.ICON' | translate }}</mat-label>
            <input matInput formControlName="icon" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'GENERAL.COLOR' | translate }}</mat-label>
            <input matInput formControlName="color" autocomplete="off" required>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button *ngIf="addView" type="submit" class="btn actionButton" (click)="executeAction()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button *ngIf="!addView" type="submit" class="btn actionButton" (click)="executeAction()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
