import { MainSubscriptionsService } from './../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from './../../../../shared/services/pass-data/pass-data.service';
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { UVNotification, UVNotificationDetail } from '../../../../shared/models/notification';
import { NotificationApiService } from '../../../../shared/services/notification-api.service';
import { MainState } from '../../../../store/main/main.reducer';
import * as MainSelectors from '../../../../store/main/main.selectors';
import { PaginationInstance } from 'ngx-pagination';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'urban-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = ['Firstname', 'Lastname', 'Username', 'Domain', 'Read'];
  public filteredData: UVNotificationDetail[] = [];
  public currentNotification: UVNotification;
  public notificationDetails: UVNotificationDetail[] = [];
  public todayTimestamp: number = 0;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    created: [{ value: '', disabled: true }],
    destinationType: [{ value: '', disabled: true }],
    payload: [{ value: '', disabled: true }],
    expiration: [{ value: '', disabled: true }],
  });

  constructor(
    private store: Store<MainState>,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private notificationApiService: NotificationApiService,
    private router: Router,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    let notificationId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        notificationId = navInfo.Id;

        this.store.select(MainSelectors.getNotificationToShow).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          if (res === null || res === undefined || res.Id !== notificationId) {
            this.mainService.setNavigationInfoComand();
            this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
            this.router.navigate(['main/dashboard']);
            return;
          }

          this.currentNotification = res;
          this.todayTimestamp = new Date()?.getTime();
          this.loadData();
        });
      } else {
        this.mainService.setNavigationInfoComand();
        this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
        this.router.navigate(['main/dashboard']);
      }
    });
  }

  private loadData(): void {
    this.notificationApiService.notificationDetails(this.currentNotification.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res !== undefined) {
        this.notificationDetails = res;
        this.filteredData = this.notificationDetails;
      }
    });

    this.form.patchValue({
      created: this.datePipe.transform(this.currentNotification.Created * 1000, 'short'),
      destinationType: this.currentNotification.DestinationType,
      payload: this.currentNotification.Payload  
    });

    if (this.currentNotification.Expiration <= 0) {
      let phrase: string = 'NOTIFICATION_LIST.FORCED_EXPIRATION';

      this.getTranslation(phrase);

      this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.getTranslation(phrase);
      });
    } else {
      this.form.controls.expiration.setValue(this.datePipe.transform(this.currentNotification.Expiration * 1000, 'short'));
    }
  }

  private getTranslation(phrase: string): void {
    this.translate.get(phrase).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.form.controls.expiration.setValue(res);
      }
    });
  }

  public setToExpired(notification: UVNotification): void {
    if (!notification && notification === undefined) {
      return;
    }

    const expireDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    expireDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.notificationApiService.expireNotification(notification.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(expireResponse => {
          if (expireResponse) {
            this.mainService.setNavigationInfoComand();
            this.router.navigate(['main/notification-list']);
          }
        })
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.notificationDetails?.filter((notification: UVNotificationDetail) =>
      [
        notification.Username?.toLowerCase(), 
        notification.Firstname?.toLowerCase(),
        notification.Lastname?.toLowerCase(), 
        notification.Domain?.toLowerCase(),
        notification.Read ? new Date(notification.Read * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : ''
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
