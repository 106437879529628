import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AddUserDialogComponent } from '../../../../shared/dialogs/add-user-dialog/add-user-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Domain } from '../../../../shared/models/domain';
import { User } from '../../../../shared/models/loginResponse';
import { Role } from '../../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../shared/services/api.service';
import { PhonePrefix } from '../../../../shared/models/phonePrefix';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, OnDestroy {

  public displayedColumns = ['Username', 'Email', 'Firstname', 'Lastname', 'Roles', 'Actions'];
  public users: User[] = [];
  public roles: Role[] = [];
  public domains: Domain[] = [];
  public filteredData: User[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public prefixesList: PhonePrefix[] = [];
  public domainsList: Domain[] = [];
  public rolesList: Role[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public colors: Record<string, string> = {
    'Administrators': this.getRoleColors()[0].trim(),
    'Domain admin': this.getRoleColors()[1].trim(),
    'Translator': this.getRoleColors()[2].trim(),
    'App users': this.getRoleColors()[3].trim(),
    'Camera viewer': this.getRoleColors()[4].trim(),
    'Users': this.getRoleColors()[5].trim(),
    'Other': "gray"
  };

  constructor(
    private adminApiService: AdministratorApiService,
    private apiService: ApiService,
    private router: Router,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.some(x => x.Name === 'Administrators')) {
        this.isAdmin = true;
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }
    });

    this.apiService.getPhonePrefixes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(prefixes => {
      this.prefixesList = prefixes;
    });

    this.adminApiService.domainList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(domains => {
      this.domainsList = domains;
    });

    this.adminApiService.roleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(roles => {
      this.rolesList = roles;
    });

    this.loadData();
  }

  private loadData(): void {
    this.adminApiService.listUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.users = res.filter(user => +user.Deleted < 0);
      this.filteredData = this.users;
    });
  }

  public addUser(): void {
    if (this.isAdmin) {
      const addUserDialogRef = this.dialog.open(AddUserDialogComponent, {
        data: {
          myPrefixesList: this.prefixesList,
          myDomainsList: this.domainsList,
          myRolesList: this.rolesList
        }
      });

      addUserDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(finalResult => {
        if (finalResult) {
          const addUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          addUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(ok => {
            if (ok) {
              this.adminApiService.addUser(finalResult.newUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(newUserId => {
                if (newUserId) {
                  this.adminApiService.authorizeUser({ User: newUserId, Domain: finalResult.domain, Role: finalResult.role }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                    this.apiService.addSpecificUserProperty({ Name: 'PhoneNumber', Value: finalResult.phoneNumber, UserId: newUserId }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                      this.loadData();
                    });
                  });
                }
              });
            }
          });
        }
      });
    }
  }
  
  public deleteUser(userId: string): void {
    if (this.isAdmin) {

      const deleteUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.adminApiService.deleteUser(userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.loadData();
            }
          });
        }
      });
    }
  }

  private getRoleColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-success-icon-text-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-light-warn-accent-color'));

    return colors;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.users?.filter((key: User) =>
      [
        key.Username?.toLowerCase(), 
        key.Firstname?.toLowerCase(), 
        key.Lastname?.toLowerCase(),
        key.Email?.toLowerCase()
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public goToDeletedUsers(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'user-management' });
    this.router.navigate(['main/deleted-users']);
  }

  public goToDetail(id: string): void {
    this.mainService.setNavigationInfoComand({ Id: id, BackRoute: 'user-management' });
    this.router.navigate(['main/user-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
