import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'urban-streaming-link-dialog',
  templateUrl: './streaming-link-dialog.component.html',
  styleUrls: ['./streaming-link-dialog.component.scss']
})
export class StreamingLinkDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    link: [{ value: '', disabled: true }]
  });

  public copied : boolean;

  constructor(public dialogRef: MatDialogRef<StreamingLinkDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private clipboard : Clipboard,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        link: string
      }) {
    this.form.controls.link.setValue(data.link);
  }

  ngOnInit(): void {
  }

  copyToClipboard() {
    this.clipboard.copy(this.form.controls.link.value);
    this.copied = true;
  }


  cancel() {
    this.dialogRef.close();
  }
}
