import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { AddServiceTypeRequest, GetServiceTypeResponse, ServiceType } from 'src/app/shared/models/service/service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ServiceTypeDialogComponent } from 'src/app/shared/dialogs/service-type-dialog/service-type-dialog.component';

@Component({
  selector: 'urban-service-types',
  templateUrl: './service-types.component.html',
  styleUrls: ['./service-types.component.scss']
})
export class ServiceTypesComponent implements OnInit, OnDestroy {
  public columns = ['Id', 'Name', 'Icon', 'Color', 'Detail'];
  public serviceTypes: ServiceType[] = [];
  public filteredData: ServiceType[] = [];
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public actualFilter: string = '';
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (!res.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.loadData();
    });
  }

  private loadData(): void {
    this.apiService.getServiceTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceTypeResponse) => {
      if (res?.Items) {
        this.serviceTypes = res.Items;
        this.filteredData = this.serviceTypes;
      }
    });
  }

  public typeAdd(): void {
    const addTypeDialogRef = this.dialog.open(ServiceTypeDialogComponent);

    addTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newType: AddServiceTypeRequest) => {
      if (newType) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.addServiceType(newType).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.loadData();
              },
              error: () => {}
            });
          }
        });
      }
    });
  }

  public typeEdit(type: ServiceType): void {
    const addTypeDialogRef = this.dialog.open(ServiceTypeDialogComponent, {
      data: type
    });

    addTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newType: ServiceType) => {
      if (newType) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.updateServiceType(newType).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.loadData();
              },
              error: () => {}
            });
          }
        });
      }
    });
  }

  public typeDelete(type: ServiceType): void {
    const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.deleteServiceType(type.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.loadData();
          },
          error: () => {}
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.serviceTypes.filter((type: ServiceType) =>
    [
      type.Id?.toString().toLowerCase(),
      type.Name?.toLowerCase(),
      type.Color?.toLowerCase(),
      type.Icon?.toLowerCase(),
    ].some((x) => x?.includes(filterValue.toLowerCase()))
  );

  this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
