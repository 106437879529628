import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddLanguageDialogComponent } from '../../../../shared/dialogs/add-language-dialog/add-language-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { PhraseDialogComponent } from '../../../../shared/dialogs/phrase-dialog/phrase-dialog.component';
import { Phrase } from '../../../../shared/models/administrator/phrase';
import { Language } from '../../../../shared/models/language';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { ApiService } from '../../../../shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';


export interface DiffLanguages {
  source: string,
  target: string
}

@Component({
  selector: 'urban-phrase-management',
  templateUrl: './phrase-management.component.html',
  styleUrls: ['./phrase-management.component.scss']
})
export class PhraseManagementComponent implements OnInit, OnDestroy {

  public phraseColumns = ['Language', 'Page', 'Key', 'Value', 'Detail'];
  public phrases: Phrase[] = [];
  public filteredData: Phrase[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public languages: Language[] = [];
  public currentLanguage: string;
  public myISOList : string [] = [];
  public languageDiffPhrasesLength: number = 0;
  public updatingDifferences: boolean = false;
  public passedSourceLang: string;
  public missingPhraseWarn: boolean = true;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public form: UntypedFormGroup = this.formBuilder.group({
    languageSelected: ['', Validators.required]
  });

  constructor(
    private administratorApiService: AdministratorApiService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    this.initLanguages();
  }

  private initLanguages(): void {
    this.apiService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.languages = x.List;
      this.currentLanguage = this.translate.currentLang.slice(-2);
      this.form.controls.languageSelected.setValue(this.currentLanguage);
      this.loadData();
    });
  }

  private loadData(): void {
    this.administratorApiService.phraseList(this.currentLanguage).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      this.phrases = result;
      this.filteredData = this.phrases;

      this.actualFilter = '';
      this.missingPhraseWarn = false;
      this.languageDiffPhrasesLength = 0;

      this.checkDifferences('en');
    });
  }

  public updateLanguage(): void {
    if (this.form.valid) {
      this.currentLanguage = this.form.controls.languageSelected.value;
      this.loadData();
    }
  }

  public languageSelectedChange(language: string): void {
    if (language) {
      this.form.setValue({
        languageSelected: language
      });

      this.updateLanguage();
    }
  }

  public checkDifferences(sourceLang: string): void {
    if (this.currentLanguage !== sourceLang) {
      this.passedSourceLang = sourceLang;
      this.apiService.checkLanguageDifferences({ source: sourceLang, target: this.currentLanguage})
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(diff => {
        if (diff?.length > 0) {
          this.languageDiffPhrasesLength = diff.length;
          this.missingPhraseWarn = true;
        } else {
          this.languageDiffPhrasesLength = 0;
          this.missingPhraseWarn = false;        
        }
      });
    }
  }

  private upsertPhrase(phrase: Phrase): void {
    this.administratorApiService.upsertPhrase(phrase).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.loadData();
      }
    });
  }

  public addPhrase(): void {
    const addPhraseDialogRef = this.dialog.open(PhraseDialogComponent, {
      data: { language: this.currentLanguage },
      width: '50%'
    });

    addPhraseDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(phrase => {
      if (phrase) {
        const addPhraseConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addPhraseConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.upsertPhrase(phrase);
          }
        });
      }
    });
  }

  public phraseDetail(phrase: Phrase): void {
    const updatePhraseDialogRef = this.dialog.open(PhraseDialogComponent, {
      data: { language: this.currentLanguage, page: phrase.Page, key: phrase.Key, value: phrase.Value },
      width: '50%'
    });

    updatePhraseDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(phrase => {
      if (phrase) {
        const updatePhraseConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        updatePhraseConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.upsertPhrase(phrase);
          }
        });
      }
    });
  }

  public phraseDelete(phrase: Phrase): void {
    if (phrase) {
      const deletePhraseConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deletePhraseConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          phrase.Value = '';
          this.upsertPhrase(phrase);
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.phrases?.filter((phrase: Phrase) => {
      if (filterValue.includes('.')) {
        const [pageFilterValue, keyFilterValue] = filterValue.split('.');
        return (
          phrase.Page?.toLowerCase().includes(pageFilterValue.toLowerCase()) &&
          phrase.Key?.toLowerCase().includes(keyFilterValue.toLowerCase())
        );
      } else {
        return (
          [
            phrase.Language?.toLowerCase(),
            phrase.Page?.toLowerCase(),
            phrase.Key?.toLowerCase(),
            phrase.Value?.toLowerCase(),
          ].some((field: string) => field?.includes(filterValue.toLowerCase()))
        );
      }
    });

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public sortBy(field: string): void {
    this.filteredData.sort((a, b) =>
      a[field]?.localeCompare(b[field])
    );
  }

  public addNewLanguage(): void {
    this.apiService.getPhonePrefixes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.length > 0) {
        this.myISOList = [...new Set(res.map(a => a.Flag).sort((a, b) => a.localeCompare(b)))];
      } else {
        this.myISOList = [];
      }

      const addLanguageDialogRef = this.dialog.open(AddLanguageDialogComponent, {
        minWidth: 250,
        width: '450px',
        data:{ isoList: this.myISOList }
      });

      addLanguageDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newLang => {
          if (newLang) {
            const addRoleConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
              disableClose: false
            });
            addRoleConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                this.apiService.addRemoveLanguage(
                  {
                    Name: newLang.Name,
                    Description: newLang.Description,
                    Direction: newLang.Direction
                  }
                ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                  this.initLanguages();
                });
              }
            });
          }
        });
    });
  }

  public deleteLanguage(): void {
    const addRoleConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addRoleConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.addRemoveLanguage(
          {
            Name: this.currentLanguage,
            Description: '',
          }
        ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.initLanguages();
        });
      }
    });
  }

  public goToMissingPhrases(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentLanguage, BackRoute: 'phrase-management' });
    this.router.navigate(['main/missing-phrases']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
