import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddRemoveLanguageRequest } from '../../models/language';

@Component({
  selector: 'urban-add-language-dialog',
  templateUrl: './add-language-dialog.component.html',
  styleUrls: ['./add-language-dialog.component.scss']
})
export class AddLanguageDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    description: [{ value: '', disabled: false}, Validators.required],
    direction: [{value: 'L', disabled: false}, Validators.required]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<AddLanguageDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { isoList: string[] }
    ) { }

  ngOnInit(): void {
  }

  addition(): void {
    if (this.form.valid) {
      let createdLanguage: AddRemoveLanguageRequest = {
        Name: this.form.controls.name.value,
        Description: this.form.controls.description.value,
        Direction: this.form.controls.direction.value
      }
      this.dialogRef.close(createdLanguage);
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
