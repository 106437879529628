import { ChartDataList } from '../../models/ChartDataList';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ObjectUnsubscribedError, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'urban-google-piechart',
  templateUrl: './google-piechart.component.html',
  styleUrls: ['./google-piechart.component.scss']
})
export class GooglePiechartComponent implements OnInit, OnChanges, OnDestroy {

  @Input('pieDataPassed') public passedDataList: ChartDataList;
  @Input('darkThemeActive') public darkThemeActive: boolean;
  @Input('passedElementsDescription') public slicesNames: string[] = [];
  @Input('sectionsType') public slicesType: string = "Elements";
  @Input('passedValuesDescription') public valuesDescription: string = "Values";
  @Input('passedPieChartTitle') public pieChartTitle: string = '';
  @Input('pieChartId') public pieChartId: string = '';
  @Input('firstElementIsTransparent') private firstElementTransparent: boolean = false;
  @Input('pieHole') private hole: boolean = true;

  public chartData: Array<any[]> = [];
  public chart: google.visualization.PieChart;
  public chartOptions: google.visualization.PieChartOptions;
  public translationsReady: boolean = false;
  public noDataOnChart: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private googleChartLoaded: boolean = false;
  private lightPrimaryColor: string;
  private darkPrimaryColor: string;
  private chartColors: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  constructor(private translate: TranslateService) {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.onLoadedChartDraw.bind(this));
  }

  ngOnInit(): void {
    this.lightPrimaryColor = this.getColor('light', 'primary');
    this.darkPrimaryColor = this.getColor('dark', 'primary');
    this.chartColors = this.getChartColors();

    this.pieChartId = `divPieChart${this.pieChartId}`;
    this.translate.get([this.slicesType, this.valuesDescription]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.slicesType = res[this.slicesType];
      this.valuesDescription = res[this.valuesDescription];
      this.translationsReady = true;
    });
  }

  tryDrawChart(): void {
    if(this.googleChartLoaded) {
      this.drawChart();
    }
  }

  onLoadedChartDraw(): void {
    this.googleChartLoaded = true;
    this.drawChart();
  }

  private drawChart(): void {
    this.chartData = [];
    if (this.passedDataList && this.passedDataList !== undefined && Object.keys(this.passedDataList).length > 0) {
      this.noDataOnChart = false;
      let myData = new google.visualization.DataTable();
      myData.addColumn('string', this.slicesType);
      myData.addColumn('number', this.valuesDescription);
      let i: number = 0;
      for (let key in this.passedDataList) {
        if (Object.keys(this.passedDataList).length > 0 && this.slicesNames.length === Object.keys(this.passedDataList).length) {
          myData.addRow([this.slicesNames[i], this.passedDataList[key]]);
          i++;
        }
        else {
          myData.addRow([key, this.passedDataList[key]]);
        }
      }

      let pieChartWidth = document.getElementById(this.pieChartId).offsetWidth;

      if (this.darkThemeActive) {
        this.chartOptions = {
          pieHole: this.hole ? 0.4 : 0,
          pieSliceBorderColor: 'transparent',
          pieSliceText: 'none',
          backgroundColor: 'transparent',
          legend: {
            textStyle: {
              fontSize: 16,
              color: '#fff',
              italic: true
            }
          },
          height: 0.7*pieChartWidth,
          chartArea: { left: 10, right: 0, bottom: 20, top: 60 },
          slices: {
            0: {
              color: this.firstElementTransparent ? '#021729' : this.chartColors[2].trim()
            },
            1: {
              color: this.chartColors[1].trim()
            },
            2: {
              color: this.chartColors[0].trim()
            },
            3: {
              color: this.darkPrimaryColor
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          }
        }
      } else {
        this.chartOptions = {
          pieHole: this.hole ? 0.4 : 0,
          pieSliceBorderColor: 'transparent',
          pieSliceText: 'none',
          backgroundColor: 'transparent',
          legend: {
            textStyle: {
              fontSize: 16,
              italic: true
            }
          },
          height: 0.7*pieChartWidth,
          chartArea: { left: 10, right: 0, bottom: 20, top: 60 },
          slices: {
            0: {
              color: this.firstElementTransparent ? '#021729' : this.chartColors[2].trim()
            },
            1: {
              color: this.chartColors[1].trim()
            },
            2: {
              color: this.chartColors[0].trim()
            },
            3: {
              color: this.lightPrimaryColor
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          }
        }
      }
      this.chart = new google.visualization.PieChart(document.getElementById(this.pieChartId));
      this.chart.draw(myData, this.chartOptions);
    } else {
      this.noDataOnChart = true;
      if(this.chart) {
        this.chart.clearChart();
      }
    }
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));

    return colors;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['darkThemeActive']) {
      const isDarkActive = changes['darkThemeActive'];
      if (isDarkActive.currentValue !== isDarkActive.previousValue && isDarkActive.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['passedDataList']) {
      const updatedDataList = changes['passedDataList'];
      if (updatedDataList.currentValue !== updatedDataList.previousValue && updatedDataList.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['slicesNames']) {
      const updatedTranslations = changes['slicesNames'];
      if (updatedTranslations.currentValue !== updatedTranslations.previousValue && updatedTranslations.firstChange == false) {
        this.tryDrawChart();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
