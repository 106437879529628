import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '../../models/location';
import {Router} from "@angular/router";
import {MainSubscriptionsService} from "../../services/main-subscriptions/main-subscriptions.service";

@Component({
  selector: 'urban-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit {

  private location: Location = null;

  public isAdd: boolean = true;

  public form: UntypedFormGroup = this.formBuilder.group({
    address: [{ value: '', disabled: false }, Validators.required],
    zip: [{ value: '', disabled: false }, Validators.required],
    city: [{ value: '', disabled: false }, Validators.required],
    state: [{ value: '', disabled: false }, Validators.required],
    country: [{ value: '', disabled: false }, Validators.required],
    name: [{ value: '', disabled: false }, Validators.required],
    description: [{ value: '', disabled: false }],
    latitude: [{ value: 0, disabled: false }, Validators.required],
    longitude: [{ value: 0, disabled: false }, Validators.required],
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private mainService: MainSubscriptionsService,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        location: Location,
        latitude: number,
        longitude: number
      }
  ) { }

  ngOnInit(): void {
    if (this.data !== null && this.data !== undefined && this.data.location !== null && this.data.location !== undefined) {
      this.location = this.data.location;
      this.isAdd = false;

      this.form.controls.address.setValue(this.location.Address);
      this.form.controls.zip.setValue(this.location.Zip);
      this.form.controls.city.setValue(this.location.City);
      this.form.controls.state.setValue(this.location.State);
      this.form.controls.country.setValue(this.location.Country);
      this.form.controls.name.setValue(this.location.Name);
      this.form.controls.description.setValue(this.location.Description);
      this.form.controls.latitude.setValue(this.location.Latitude);
      this.form.controls.longitude.setValue(this.location.Longitude);
    }

    if(this.data !== null && this.data !== undefined && this.data.latitude !== null && this.data.latitude !== undefined && this.data.longitude !== null && this.data.longitude !== undefined){
      this.form.controls.latitude.setValue(this.data.latitude);
      this.form.controls.longitude.setValue(this.data.longitude);
    }
  }

  addition() {
    if (this.form.valid) {
      this.location = {
        Id: this.location !== null ? this.location.Id : '',
        Address: this.form.controls.address.value,
        City: this.form.controls.city.value,
        Zip: this.form.controls.zip.value,
        State: this.form.controls.state.value,
        Country: this.form.controls.country.value,
        Name: this.form.controls.name.value,
        Latitude: +this.form.controls.latitude.value,
        Longitude: +this.form.controls.longitude.value,
        Description: this.form.controls.description.value
      }

      this.dialogRef.close(this.location)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  events(): void {
    this.dialogRef.close();
    this.mainService.setNavigationInfoComand({Id: this.location.Id, BackRoute: 'dashboard' });
    this.router.navigate(['main/location-events']);
  }

  cancel() {
    this.dialogRef.close();
  }

}
