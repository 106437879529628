import { ChartMultiDataList } from './../../models/ChartDataList';
import { ChartDataList } from '../../models/ChartDataList';
import { takeUntil } from 'rxjs/operators';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'urban-google-barchart',
  templateUrl: './google-barchart.component.html',
  styleUrls: ['./google-barchart.component.scss']
})
export class GoogleBarchartComponent implements OnInit, OnChanges, OnDestroy {

  @Input('barChartTitle') public title: string = "";
  @Input('barsTitle') private barsTitle: string = "Elements";
  @Input('valuesDescription') private valuesDescription: string = "Values";
  @Input('multiValuesDescription') private multiValuesDescription: string[] = [];
  @Input('passedChartData') private passedData: ChartDataList;
  @Input('passedChartMultiData') private passedMultiData: ChartMultiDataList = {};
  @Input('limitValue') private maxValue: number;
  @Input('valuesUnit') private unit: string = '';
  @Input('darkThemeActive') private darkThemeActive: boolean;

  @Output('chartDrawn') drawn: EventEmitter<any> = new EventEmitter();

  private translations: string[] = [];
  private chart: google.visualization.BarChart;
  private chartOptions: google.visualization.BarChartOptions;
  public translationsReady: boolean = false;
  public noDataOnChart: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private googleChartLoaded: boolean = false;
  private lightPrimaryColor: string;
  private darkPrimaryColor: string;
  private chartColors: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  constructor(private translate: TranslateService) {
    google.charts.load('current', { 'packages': ['corechart', 'bar'] });
    google.charts.setOnLoadCallback(this.onLoadedChartDraw.bind(this));
  }

  ngOnInit(): void {
    this.lightPrimaryColor = this.getColor('light', 'primary');
    this.darkPrimaryColor = this.getColor('dark', 'primary');
    this.chartColors = this.getChartColors();

    if(this.multiValuesDescription.length > 0) {
      if(this.maxValue !== undefined) {
        this.maxValue += 5;
      }
    }
    if(this.unit !== '' && this.unit !== '%') {
      this.unit = ` ${this.unit}`;
    }
    this.translations.push(this.barsTitle, this.valuesDescription);
    this.getTranslations();
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
      this.tryDrawChart();
    });
  }

  getTranslations(): void {
    this.translate.get(this.translations).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.barsTitle = res[this.translations[0]];
      this.valuesDescription = res[this.translations[1]];
      this.translationsReady = true;
    });
  }

  tryDrawChart(): void {
    if(this.googleChartLoaded){
      this.drawChart();
    }
  }

  onLoadedChartDraw(): void {
    this.googleChartLoaded = true;
    this.drawChart();
  }

  drawChart(): void {
    if ((this.passedData && this.passedData !== undefined && Object.keys(this.passedData).length > 0)
      || (this.passedMultiData && this.passedMultiData !== undefined && Object.keys(this.passedMultiData).length > 0)) {
      this.noDataOnChart = false;
      let myData = new google.visualization.DataTable();
      myData.addColumn('string', this.barsTitle);

      if (this.multiValuesDescription.length === 0) {
        myData.addColumn('number', this.valuesDescription);
        for (let key in this.passedData) {
          let value: number = this.passedData[key];
          let describedValue = { v: value, f: `${value}${this.unit}` };
          myData.addRow([key, describedValue]);
        }
      }
      else {
        this.multiValuesDescription.forEach((singleValuesDescription) => {
          myData.addColumn('number', singleValuesDescription);
        });
        myData.addColumn({ role: 'annotation' });
        for (let multiElementKey in this.passedMultiData) {
          let newRow: any[] = [multiElementKey];
          let total: number = 0;
          for (let singleElementKey in this.passedMultiData[multiElementKey]) {
            let value: number = this.passedMultiData[multiElementKey][singleElementKey];
            total += value;
            let describedValue = { v: value, f: `${value}${this.unit}` };
            newRow.push(describedValue);
          }
          total = Math.round(total * 100) / 100;
          if(this.maxValue === undefined) {
            this.maxValue = total + 5;
          }
          newRow.push(`${total}${this.unit}`);
          myData.addRow(newRow);
        }

        if (this.unit && this.unit === '%'){
          this.maxValue = 100;
        }
      }

      if (this.darkThemeActive) {
        this.chartOptions = {
          height: 550,
          chartArea: {
            width: this.multiValuesDescription.length > 0 ? '60%' : '70%',
            height: '70%'
          },
          legend: {
            position: this.multiValuesDescription.length > 0 ? 'right' : 'top',
            textStyle: { color: 'white' }
          },
          isStacked: this.multiValuesDescription.length > 0 ? true : false,
          backgroundColor: 'transparent',
          hAxis: {
            textStyle: { color: 'white' },
            viewWindow: {
              min: 0,
              max: this.maxValue ? Number(this.maxValue) : null
            },
            format: this.unit !== '' ? `#'${this.unit}'` : '#',
            title: this.valuesDescription,
            titleTextStyle: { color: 'white' }
          },
          vAxis: {
            textStyle: { color: 'white' },
            textPosition: 'out',
          },
          series: {
            0: {
              color: this.darkPrimaryColor ? this.darkPrimaryColor : '#0971ce',
            },
            1: {
              color: this.chartColors[0].trim()
            },
            2: {
              color: this.chartColors[1].trim()
            },
            3: {
              color: this.chartColors[2].trim()
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          },
          annotations: {
            alwaysOutside: true,
            textStyle: {
              fontSize: 16,
              color: 'white',
              bold : true
            },
            stem: {
              color: 'transparent',
              length: 3
            }
          }
        }

      } else {
        this.chartOptions = {
          height: 550,
          chartArea: {
            width: this.multiValuesDescription.length > 0 ? '60%' : '70%',
            height: '70%'
          },
          legend: {
            position: this.multiValuesDescription.length > 0 ? 'right' : 'top'
          },
          isStacked: this.multiValuesDescription.length > 0 ? true : false,
          backgroundColor: 'transparent',
          hAxis: {
            viewWindow: {
              min: 0,
              max: this.maxValue ? Number(this.maxValue) : null
            },
            format: this.unit !== '' ? `#'${this.unit}'` : '#',
            title: this.valuesDescription
          },
          vAxis: {
            textPosition: 'out',
          },
          series: {
            0: {
              color: this.lightPrimaryColor ? this.lightPrimaryColor : '#0971ce'
            },
            1: {
              color: this.chartColors[0].trim()
            },
            2: {
              color: this.chartColors[1].trim()
            },
            3: {
              color: this.chartColors[2].trim()
            },
            4: {
              color: this.chartColors[3].trim()
            },
            5: {
              color: this.chartColors[4].trim()
            },
            6: {
              color: this.chartColors[5].trim()
            },
            7: {
              color: this.chartColors[6].trim()
            }
          },
          annotations: {
            alwaysOutside: true,
            textStyle: {
              fontSize: 16,
              color: 'black',
              bold : true
            },
            stem: {
              color: 'transparent',
              length: 3
            }
          }
        }
      }

      let elem: Element = document.getElementById("divBarChart");
      this.chart = new google.visualization.BarChart(elem);
      this.chart.draw(myData, this.chartOptions);
      this.drawn.emit();
    }
    else {
      this.noDataOnChart = true;
      if(this.chart) {
        this.chart.clearChart();
      }
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    const isDarkActive = changes['darkThemeActive'];
    if (isDarkActive?.currentValue !== isDarkActive?.previousValue && isDarkActive?.firstChange == false) {
      this.tryDrawChart();
    }
    if (changes['passedData']) {
      const updatedData = changes['passedData'];
      if (updatedData.currentValue !== updatedData.previousValue && updatedData.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['passedMultiData']) {
      const updatedMultiData = changes['passedMultiData'];
      if (updatedMultiData.currentValue !== updatedMultiData.previousValue && updatedMultiData.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['multiValuesDescription']) {
      const updatedTranslations = changes['multiValuesDescription'];
      if (updatedTranslations.currentValue !== updatedTranslations.previousValue && updatedTranslations.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['title']) {
      const updatedTitle = changes['title'];
      if (updatedTitle.currentValue !== updatedTitle.previousValue && updatedTitle.firstChange == false) {
        this.tryDrawChart();
      }
    }
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  private getChartColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-yellow-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-2-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-3-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-4-color'));
    
    return colors;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
