<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'PHRASE.ADD' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'PHRASE.DETAIL' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.LANGUAGE' | translate }}</mat-label>
            <input matInput formControlName="language" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'GENERAL.PAGE' | translate }}</mat-label>
            <input matInput formControlName="page" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'GENERAL.KEY' | translate }}</mat-label>
            <input matInput formControlName="key" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'GENERAL.VALUE' | translate }}</mat-label>
            <input matInput formControlName="value" autocomplete="off" required>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{ 'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{ 'GENERAL.UPDATE' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CANCEL' | translate }}</button>
</div>
