import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api.service';

@Component({
  selector: 'urban-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy {

  public success: string | null;
  public token: string | null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isLittleScreen: boolean;
  public regexPassword: string = '';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private translate: TranslateService,
    private router: Router
  ) {
    if (window.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  ngOnInit(): void {

    this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      this.token = resp.params['t'];
      this.apiService.activateApp(this.token).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        success => {
          this.translate.get('ACTIVATE.SUCCESS').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.success = res;
          });

        },
        err => this.router.navigate(['login'])
      )
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
