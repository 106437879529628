import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LocationEvent} from "../../models/locationEvent";
import {LocationEventRequest} from "../../models/administrator/locationEventRequest";

@Component({
  selector: 'urban-location-event-dialog',
  templateUrl: './location-event-dialog.component.html',
  styleUrls: ['./location-event-dialog.component.scss']
})
export class LocationEventDialogComponent implements OnInit {

  private locationEvent: LocationEvent = null;

  private locationEventRequest: LocationEventRequest;

  public isAdd: boolean = true;

  private locationId: string;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    description: [{value: '', disabled: false}, Validators.required],
    start: [{value: 0, disabled: 0}],
    end: [{value: null, disabled: 0}]
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<LocationEventDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        locationId: string,
        locationEvent: LocationEvent
      }
  ) { }

  ngOnInit(): void {
    if (this.data !== null && this.data !== undefined && this.data.locationEvent !== null && this.data.locationEvent !== undefined) {
      this.locationEvent = this.data.locationEvent;
      this.isAdd = false;

      this.form.controls.name.setValue(this.locationEvent.Name);
      this.form.controls.description.setValue(this.locationEvent.Description);
      this.form.controls.start.setValue(new Date(this.locationEvent.Start * 1000));
      this.form.controls.end.setValue(this.locationEvent.End && this.locationEvent.End > 0 ? new Date(this.locationEvent.End * 1000) : null);
    }

    this.locationId = this.data?.locationId;
  }

  addition() {
    if (this.form.valid) {
      this.locationEventRequest = {
        Id: this.locationEvent?.Id,
        Name: this.form.controls.name.value,
        Description: this.form.controls.description.value,
        Start: this.reformatDate(this.form.controls.start.value),
        End: this.form.controls.end.value ? this.reformatDate(this.form.controls.end.value) : null,
        Location: this.locationEvent?.Location?.Id ?? this.locationId
      }


      this.dialogRef.close(this.locationEventRequest);
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  private reformatDate(dateToFormat: string): number {
    let currentDate = new Date(dateToFormat);
    return Math.round(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).getTime() / 1000);
  }


  public setDate(date: Date): number {
    return +date;
  }
}
