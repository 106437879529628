<div class="container-fluid">

    <div
        class="row mb-1 d-flex justify-content-between align-items-baseline bg-light-grey border-radius-15 m-0">
        <span class="h4 color-dark mb-2 mt-2 thin">
            {{ 'MANAGER.TITLE' | translate }}
        </span>
    </div>
    <br>

    <div class="devices-table mat-elevation-z4">

        <table class="w-100" mat-table [dataSource]="myDataSource" *ngIf="tableToShow === 'managers'">

            <ng-container matColumnDef="CompanyName">
                <th mat-header-cell *matHeaderCellDef> {{ 'MANAGER.COMPANY_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <a> {{ element.CompanyName }}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="Country">
                <th mat-header-cell *matHeaderCellDef> {{ 'MANAGER.COUNTRY' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.Country}} </td>
            </ng-container>

            <ng-container matColumnDef="EmailAddress">
                <th mat-header-cell *matHeaderCellDef> {{ 'MANAGER.EMAIL' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.EmailAddress}} </td>
            </ng-container>

            <ng-container matColumnDef="Detail">
                <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                <td mat-cell *matCellDef="let element" class="text-center pr-1">
                    <div class="pr-2">
                        <i class="mr-2 cursor-custom urbanicon-info-outline" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                            (click)="goToDomains(element.id)">
                        </i>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedManagersColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedManagersColumns;"></tr>
        </table>






        <!-- <table class="w-100" mat-table [dataSource]="myDataSource" *ngIf="tableToShow === 'domains'">

            <ng-container matColumnDef="DomainName">
                <th mat-header-cell *matHeaderCellDef> {{ 'DOMAIN.COMPANY_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <a id="domainDomainNameShown" (click)="goToGateways(element.Id)" > {{ element.DomainName }}</a>
                </td>
            </ng-container>


            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef> {{ 'DOMAIN.EMAIL' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedDomainsColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDomainsColumns;"></tr>
        </table> -->






        <!-- <table class="w-100" mat-table [dataSource]="myDataSource" *ngIf="tableToShow === 'gateways'">

            <ng-container matColumnDef="GatewayName">
                <th mat-header-cell *matHeaderCellDef> {{ 'GATEWAY.COMPANY_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <a id="gatewayDomainNameShown" > {{ element.GatewayName }}</a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedGatewaysColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedGatewaysColumns;"></tr>
        </table> -->
    </div>
</div>
