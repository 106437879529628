<div class="myContainer flex-wrap d-flex flex-column">
    <div class="seeMoreNotes">
        <span class="linkText d-block link" (click)="goToNotesPage()">{{ 'GENERAL.SEE_MORE' | translate}}</span>
    </div>

    <div class="notesContainer d-flex flex-wrap justify-content-start" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <div class="stickyNote darkText" *ngFor="let note of visibleNotes | slice:0:NOTES_LIMIT_VIEW" [ngClass]="note.color+'-note-bg'" cdkDrag [cdkDragDisabled]="!dragEnabled">
            <div class="d-flex justify-content-between align-items-center w-100 mb-1 position-relative" [ngClass]="{'activeTextArea p-2': activeNote === note.id}">
                <div class="d-flex align-items-center w-100">
                    <div class="handlerNote" cdkDragHandle *ngIf="activeNote !== note.id" (mousedown)="onMouseDown()">
                        <i class="urbanicon-omnidirection darkText"></i>
                    </div>

                    <input class="noteTitleInput paragraphText" [(ngModel)]="note.title" maxlength="20" (focusin)="activeNote = note.id"
                        [ngClass]="{'dynamicWidth' : activeNote !== note.id}"
                        placeholder="{{ 'NOTE_WIDGET.INSERT_TITLE' | translate }}" (input)="addModifiedNote(note.id)" autocomplete="off">
                </div>

                <div class="removerNote" *ngIf="activeNote !== note.id">
                    <i class="urbanicon-close darkText" (click)="deleteNote(note.id)"></i>
                </div>
            </div>

            <div class="noteContent">
                <textarea class="paragraphText" [ngClass]="{'activeTextArea textAreaPadding': activeNote === note.id}" #txt
                    (focusout)="editContentNote(note.id, txt.value)" (focusin)="activeNote = note.id"
                    (input)="addModifiedNote(note.id)"
                    [value]="note.content"
                    placeholder="{{ 'NOTE_WIDGET.INSERT_TEXT' | translate }}">
                </textarea>

                <i class="urbanicon-alert unsavedNote pt-2" *ngIf="touchedNotes.includes(note.id) && activeNote !== note.id"
                    matTooltip="{{'NOTE_WIDGET.UNSAVED' | translate }}">
                </i>
                <div class="d-flex justify-content-end mt-2 align-items-center" *ngIf="activeNote === note.id">
                    <div class="colorButtons">
                        <button *ngFor="let color of colors; let i= index" [ngClass]="i === note.colorActive  ? 'activeNote' : ''" (click)="changeColorNote(color, note.id, i)"></button>
                    </div>
                    <div class="ml-auto">
                        <span class="link linkText" (click)="updateNotesArray(note.id)">{{ 'GENERAL.SAVE' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actionDiv d-flex flex-column">
        <button [disabled]="temporaryNotes.length === MAX_NOTES_LIMIT" type="button" mat-button class="btn outlineActionButton" (click)="addNote()">
            {{ 'NOTE_WIDGET.ADD_NOTE' | translate }}
        </button>
    </div>
</div>
