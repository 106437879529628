import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'urban-add-device-alias-dialog',
  templateUrl: './add-device-alias-dialog.component.html',
  styleUrls: ['./add-device-alias-dialog.component.scss']
})
export class AddDeviceAliasDialogComponent implements OnInit {

  public form: UntypedFormGroup = this.formBuilder.group({
    alias: [{ value: '', disabled: false }, Validators.required]
  });

  public error: string | null = null;

  constructor(public dialogRef: MatDialogRef<AddDeviceAliasDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
  }

  addition() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.controls.alias.value);
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
