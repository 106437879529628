import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddConsumerRequest } from '../../models/advertising/consumerRequest';
import { Service } from '../../models/service/service';
import { Property } from '../../models/Property';

@Component({
  selector: 'urban-consumer-dialog',
  templateUrl: './consumer-dialog.component.html',
  styleUrls: ['./consumer-dialog.component.scss']
})
export class ConsumerDialogComponent implements OnInit {
  public consumerForm: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    description: [{ value: '', disabled: false }, Validators.required]
  });
  public services: Service[];
  public serviceForm: UntypedFormGroup = this.formBuilder.group({
    service: [{ value: '', disabled: false }, Validators.required]
  });
  public property: Property;
  public propertyForm: UntypedFormGroup = this.formBuilder.group({
    key: [{ value: '', disabled: false }, Validators.required],
    value: [{ value: '', disabled: false }, Validators.required]
  });
  public currentProperties: Property[];
  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<ConsumerDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
    {
      services: Service[],
      property: Property,
      properties: Property[]
    }
  ) {
  }

  ngOnInit(): void {
    this.services = this.data?.services;
    this.property = this.data?.property;
    this.currentProperties = this.data?.properties;

    if (this.property !== undefined) {
      this.propertyForm.setValue({
        key: this.property.Key,
        value: this.property.Value
      });

      this.propertyForm.controls.key.disable();
    }
  }

  public addConsumer(): void {
    if (this.consumerForm.valid) {
      let consumerToAdd: AddConsumerRequest = {
        Name: this.consumerForm.controls.name.value.trim(),
        Description: this.consumerForm.controls.description.value.trim()
      }

      this.dialogRef.close(consumerToAdd)
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  public addServiceToConsumer(): void {
    if (this.serviceForm.valid) {
      this.dialogRef.close(this.serviceForm.controls.service.value.trim());
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  public addOrUpdatePropertyToConsumer(): void {
    if (!this.propertyForm.valid) {
      this.error = "ERROR_EMPTY";
      return;
    }

    if (this.currentProperties?.find((property: Property) =>
      property.Key === this.propertyForm.controls.key.value.trim()
    )) {
      this.error = "ERROR_DUPLICATE_KEY";
      return;
    }

    let newProperty: Property = {
      Key: this.propertyForm.controls.key.value.trim(),
      Value: this.propertyForm.controls.value.value.trim()
    };

    this.dialogRef.close(newProperty);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
