<h1 mat-dialog-title *ngIf="this.isAdd">{{ 'GENERAL.ADD' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h1>
<h1 mat-dialog-title *ngIf="!this.isAdd">{{ 'GENERAL.DETAIL' | translate }} {{ 'GENERAL.LOCATION' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.ADDRESS' | translate }}</mat-label>
            <input matInput formControlName="address" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.ZIP' | translate }}</mat-label>
            <input matInput formControlName="zip" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.CITY' | translate }}</mat-label>
            <input matInput formControlName="city" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.STATE' | translate }}</mat-label>
            <input matInput formControlName="state" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.COUNTRY' | translate }}</mat-label>
            <input matInput formControlName="country" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.LATITUDE' | translate }}</mat-label>
            <input matInput formControlName="latitude" type="number" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.LONGITUDE' | translate }}</mat-label>
            <input matInput formControlName="longitude" type="number" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LOCATIONS.DESCRIPTION' | translate }}</mat-label>
            <textarea class="tableEvidenceBG" matInput formControlName="description"
                      matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=15 autocomplete="off"></textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ error }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" *ngIf="this.isAdd" (click)="addition()">{{
        'GENERAL.ADD' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton" *ngIf="!this.isAdd" (click)="addition()">{{
        'GENERAL.UPDATE' | translate }}</button>
    <button mat-button type="submit" class="btn actionButton ml-2" *ngIf="!this.isAdd" (click)="events()">{{
        'GENERAL.EVENTS' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'GENERAL.CLOSE' | translate }}</button>
</div>

