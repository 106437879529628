<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'GATEWAY.TITLE' | translate }}
        </span>

        <button mat-button class="btn actionButton" (click)="add()">
            {{ 'GENERAL.ADD' | translate }}
        </button>
    </div>

    <div class="customTable">
        <table class="w-100" mat-table [dataSource]="myGatewaysDataSource">
            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef> {{ 'GATEWAY.ID' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
            </ng-container>
            <ng-container matColumnDef="GatewayName">
                <th mat-header-cell *matHeaderCellDef> {{ 'GATEWAY.NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.GatewayName }} </td>
            </ng-container>
            <ng-container matColumnDef="HotspotIPAddress">
                <th mat-header-cell *matHeaderCellDef> {{ 'GATEWAY.IP_ADDRESS' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.HotspotIPAddress }} </td>
            </ng-container>
            <ng-container matColumnDef="HotspotNetMask">
                <th mat-header-cell *matHeaderCellDef> {{ 'GATEWAY.NET_MASK' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.HotspotNetMask }} </td>
            </ng-container>
            <ng-container matColumnDef="Detail">
                <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                <td mat-cell *matCellDef="let element" class="text-center pr-1">
                    <div class="pr-2">
                        <i class="mr-2" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                            (click)="detail(element)" class="iconInfoButton urbanicon-info-outline">
                        </i>
                        <i class="mr-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                            (click)="delete(element)" class="iconDeleteButton urbanicon-delete">
                        </i>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedGatewaysColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedGatewaysColumns;"></tr>
        </table>
    </div>

    <br>
    <div class="d-flex justify-content-start align-items-center pb-2">
        <button mat-button class="btn actionButton ml-2" (click)="closeGatewaysMap()" [disabled]="!mapOpened">
            {{ 'GATEWAY.CLOSE_MAP' | translate }}
        </button>
        <button mat-button class="btn actionButton ml-2"
                (click)="openGatewaysMap()" [disabled]="mapOpened">
            {{ 'GATEWAY.OPEN_MAP' | translate }}
        </button>
        <button mat-button class="btn actionButton ml-2" (click)="addWalledGarden()">
            {{ 'SMSS.ADD_WALLED_GARDEN' | translate }}
        </button>
        <button mat-button class="btn actionButton ml-2" (click)="addWpTemplate()">
            {{ 'SMSS.ADD_WP_TEMPLATE' | translate }}
        </button>
    </div>

    <br>
    <div class="col-12 mt-3 p-0" *ngIf="mapOpened">
        <urban-maps-widget class="mapPanel" [gatewaysList]="gateways" [darkModeStatus]="isDarkModeActive"></urban-maps-widget>
    </div>

    <ng-template [ngIf]="walledGardens !== null && walledGardens !== undefined && walledGardens.length > 0">
        <br>
        <div class="row mb-1 d-flex justify-content-between align-items-baseline plr-15 pb-0 pt-0 m-0">
            <span class="h5 mb-1 mt-1">
                {{ 'SMSS.WALLED_GARDENS' | translate }}
            </span>
        </div>
        <br>

        <div class="customTable">
            <table class="w-100" mat-table [dataSource]="wpWalledGardensDataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.id }}
                    </td>
                </ng-container>

                <!-- DestHost Column -->
                <ng-container matColumnDef="DestHost">
                    <th mat-header-cell *matHeaderCellDef> {{ 'SMSS.WALLED_GARDEN_DESTHOST' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.DestHost }}
                    </td>
                </ng-container>

                <!-- DestHostPort Column -->
                <ng-container matColumnDef="DestHostPort">
                    <th mat-header-cell *matHeaderCellDef> {{ 'SMSS.WALLED_GARDEN_DESTHOSTPORT' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.DestHostPort }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="mr-2" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="walledGardenDetail(element)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                            <i class="mr-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="walledGardenDelete(element)" class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedWalledGardensColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedWalledGardensColumns;"></tr>
            </table>
        </div>
    </ng-template>

    <ng-template [ngIf]="wpTemplates !== null && wpTemplates !== undefined && wpTemplates.length > 0">
        <br>
        <div
            class="row mb-1 d-flex justify-content-between align-items-baseline plr-15 pb-0 pt-0 m-0">
            <span class="h5 mb-1 mt-1">
                {{ 'SMSS.WP_TEMPLATES' | translate }}
            </span>
        </div>
        <br>

        <div class="customTable">
            <table class="w-100" mat-table [dataSource]="wpTemplatesDataSource">

                 <!-- Id Column -->
                 <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.id }}
                    </td>
                </ng-container>

                <!-- Code Column -->
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef> {{ 'SMSS.WP_CODE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Code }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="mr-2" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="wpTemplateDetail(element)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                            <i class="mr-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="wpTemplateDelete(element)" class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedWpTemplateColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedWpTemplateColumns;"></tr>
            </table>
        </div>
    </ng-template>

</div>
