<h1 mat-dialog-title>{{domain?.Name}} - {{ 'ADMINISTRATION.ADD_USER' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.USERNAME' | translate }}</mat-label>
            <input matInput formControlName="username" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.EMAIL' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" autocomplete="off" email>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.FIRSTNAME' | translate }}</mat-label>
            <input matInput formControlName="firstname" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.LASTNAME' | translate }}</mat-label>
            <input matInput formControlName="lastname" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ADMINISTRATION.ROLE' | translate }}</mat-label>
            <mat-select formControlName="roleSelected">
                <mat-option *ngFor="let role of roles" value={{role.Id}}>{{role.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="d-inline-flex globalPhoneForm">
            <mat-form-field class="pr-1 d-flex globalPrefixFormField" appearance="legacy">
                <mat-label>{{ 'PROFILE.PREFIX' | translate }}</mat-label>
                <mat-select formControlName="phonePrefix">
                    <mat-select-trigger>
                        <span>
                            (+{{ this.form.controls.phonePrefix.value }})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let prefix of prefixes" [value]="prefix.Code">
                        <span class="flag-icon flag-icon-{{ prefix.Flag }}"></span>
                        {{ prefix.Country }}
                        {{ prefix.Code }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="globalPhoneNumberFormField" appearance="legacy">
                <mat-label>{{ 'PROFILE.PHONE' | translate }}</mat-label>
                <input matInput formControlName="phoneNumber" autocomplete="off">
                <i matSuffix class="urbanicon-phone-outline"></i>
            </mat-form-field>
        </div>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

    </form>
</mat-dialog-content>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center pb-2">
    <button mat-button type="submit" class="btn actionButton" (click)="addition()">{{ 'CHANGE_PASSWORD_DIALOG.CONFIRM' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'CHANGE_PASSWORD_DIALOG.CANCEL' | translate }}</button>
</div>
