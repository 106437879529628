<div class="container-fluid px-0">
    <span class="m-0 subheadText">{{ chartTitle | translate }}{{ chartSubtitle ? ' - ' + (chartSubtitle | translate) : '' }}</span>
    
    <div class="radarChartContainer">
        <canvas #radarAreaChartCanvas></canvas>
    </div>

    <div *ngIf="noDataOnChart" class="my-3">
        <p>{{ 'STATISTICS.NO_CHART_DATA' | translate }}</p>
    </div>
</div>