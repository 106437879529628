<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'SMSS.ADVERTISERS.CAMPAIGN' | translate }} - {{advertiser?.CompanyName}}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <input matInput formControlName="description"
                autocomplete="off" required>
        </mat-form-field>

        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.CAMPAIGN_SETTINGS' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="p-0 mt-1">
                <mat-form-field>
                    <mat-label>{{ 'GENERAL.FREQUENCY' | translate }}</mat-label>
                    <mat-select formControlName="frequency">
                        <mat-option value="1">
                            {{ 'GENERAL.VERY_LOW' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'GENERAL.LOW' | translate }}
                        </mat-option>
                        <mat-option value="5">
                            {{ 'GENERAL.MID_LOW' | translate }}
                        </mat-option>
                        <mat-option value="10">
                            {{ 'GENERAL.MID' | translate }}
                        </mat-option>
                        <mat-option value="15">
                            {{ 'GENERAL.MID_HIGH' | translate }}
                        </mat-option>
                        <mat-option value="30">
                            {{ 'GENERAL.HIGH' | translate }}
                        </mat-option>
                        <mat-option value="50">
                            {{ 'GENERAL.VERY_HIGH' | translate }}
                        </mat-option>
                        <mat-option value="100">
                            {{ 'GENERAL.HIGH_PRIORITY' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'GENERAL.TIMEZONE' | translate }}</mat-label>
                    <input matInput formControlName="timezone"
                        autocomplete="off">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'GENERAL.START' | translate}}</mat-label>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startPicker"
                        formControlName="startDate" readonly>
                    <mat-datepicker-toggle matSuffix [for]="startPicker">keyboard_arrow_down</mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{'GENERAL.END' | translate}}</mat-label>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="endPicker"
                        formControlName="endDate" readonly>
                    <mat-datepicker-toggle matSuffix [for]="endPicker">keyboard_arrow_down</mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.TIMETABLES' | translate }}</mat-label>
                    <input matInput formControlName="timetable"
                        type="number" min="1" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS_CAMPAIGN.TIMETABLES' | translate }}</mat-label>
                    <input matInput formControlName="frequency"
                        type="number" min="0" max="100" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.GATEWAY_ID' | translate }}</mat-label>
                    <input matInput formControlName="gateway"
                        autocomplete="off" type="number" min="0">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.MANAGER_TYPE_ID' | translate }}</mat-label>
                    <input matInput type="number" min="0"
                        formControlName="managerType" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.URL' | translate }}</mat-label>
                    <input matInput formControlName="url" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.TOTAL_BUDGET' | translate }}</mat-label>
                    <input matInput type="number" min="0"
                        formControlName="totalBudget" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.DAILY_BUDGET' | translate }}</mat-label>
                    <input matInput type="number" min="0"
                        formControlName="dailyBudget" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.CLICK_PRICE' | translate }}</mat-label>
                    <input matInput type="number" min="0"
                        formControlName="clickPrice" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.IMPRESSION_PRICE' | translate }}</mat-label>
                    <input matInput type="number" min="0"
                        formControlName="impressionPrice" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'GENERAL.ADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="address"
                        autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'GENERAL.CITY' | translate }}</mat-label>
                    <input matInput formControlName="city"
                        autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'GENERAL.COUNTRY' | translate }}</mat-label>
                    <input matInput formControlName="country"
                        autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'GENERAL.LATITUDE' | translate }}</mat-label>
                    <input matInput formControlName="latitude"
                        autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'GENERAL.LONGITUDE' | translate }}</mat-label>
                    <input matInput formControlName="longitude"
                        autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.RADIUS_IN_METERS' | translate }}</mat-label>
                    <input matInput type="number" min="0"
                        formControlName="radiusInMeters" autocomplete="off">
                </mat-form-field>

                <mat-form-field class="bodyArea">
                    <mat-label>{{ 'GENERAL.NOTES' | translate }}</mat-label>
                    <textarea class="tableEvidenceBG" matInput
                        formControlName="notes" matTextareaAutosize matAutosizeMinRows=15
                        matAutosizeMaxRows=30></textarea>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.LOCKED' | translate }}</mat-label>
                    <mat-select formControlName="locked">
                        <mat-option value="false">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="true">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'GENERAL.IMAGES_INFO' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTAL_INTRO_TYPE' | translate }}</mat-label>
                    <mat-select
                        formControlName="portalIntroType">
                        <mat-option value="0">
                            {{ 'SMSS.NONE' | translate }}
                        </mat-option>
                        <mat-option value="5">
                            {{ 'SMSS.IMAGE_FROM_FILE' | translate }}
                        </mat-option>
                        <mat-option value="10">
                            {{ 'SMSS.STATIC_IMAGE' | translate }}
                        </mat-option>
                        <mat-option value="40">
                            {{ 'SMSS.FLICKR_IMAGE' | translate }}
                        </mat-option>
                        <mat-option value="60">
                            {{ 'SMSS.YOUTUBE_VIDEO' | translate }}
                        </mat-option>
                        <mat-option value="70">
                            {{ 'SMSS.EXTERNAL_URL' | translate }}
                        </mat-option>
                        <mat-option value="80">
                            {{ 'SMSS.EXTERNAL_MP4' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROIMAGEFROM0FILEIMGTYPE' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroImageFromFileImgType" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROSTATICIMAGEURL' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroStaticImageURL" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROVIDEOPOSTERIMGTYPE' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroVideoPosterImgType" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROVIDEOPOSTERIMGTYPE' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroVideoPosterImgType" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROFLICKRPHOTOSET' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroFlickrPhotoSet" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROYOUTUBEVIDEOID' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroYoutubeVideoID" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROEXTERNALURL' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroExternalURL" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROURL' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroURL" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROVIDEOURL' | translate }}</mat-label>
                    <input matInput formControlName="portalIntroVideoURL" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.PORTALINTROMINSECONDS' | translate }}</mat-label>
                    <input matInput type="number" min="0"
                        max="30" formControlName="portalIntroMinSeconds" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.IMPRESSIONTIME' | translate }}</mat-label>
                    <input matInput type="number" min="0" max="30"
                        formControlName="impressionTime" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.TOPBANNERIMGTYPE' | translate }}</mat-label>
                    <input matInput formControlName="topBannerImgType" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.TOPBANNERIMGLINK' | translate }}</mat-label>
                    <input matInput formControlName="topBannerImgLink" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.LEFTBANNERIMGTYPE' | translate }}</mat-label>
                    <input matInput formControlName="leftBannerImgType" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.LEFTBANNERIMGLINK' | translate }}</mat-label>
                    <input matInput formControlName="leftBannerImgLink" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.RIGHTBANNERIMGTYPE' | translate }}</mat-label>
                    <input matInput formControlName="rightBannerImgType" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.RIGHTBANNERIMGLINK' | translate }}</mat-label>
                    <input matInput formControlName="rightBannerImgLink" autocomplete="off">
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.COIN_AREA' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <mat-form-field>
                    <mat-label>{{ 'SMSS.COIN_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="coinType">
                        <mat-option value="0">
                            {{ 'SMSS.NONE' | translate }}
                        </mat-option>
                        <mat-option value="10">
                            {{ 'SMSS.STATIC_IMAGE' | translate }}
                        </mat-option>
                        <mat-option value="40">
                            {{ 'SMSS.FLICKR_IMAGE' | translate }}
                        </mat-option>
                        <mat-option value="60">
                            {{ 'SMSS.YOUTUBE_VIDEO' | translate }}
                        </mat-option>
                        <mat-option value="70">
                            {{ 'SMSS.EXTERNAL_URL' | translate }}
                        </mat-option>
                        <mat-option value="80">
                            {{ 'SMSS.EXTERNAL_MP4' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'SMSS.COIN_SKIPTIME' | translate }}</mat-label>
                    <input matInput type="number" min="0" max="30"
                        formControlName="coinSkipTime" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '60'">
                    <mat-label>{{ 'SMSS.COIN_YOUTUBEVIDEOID' | translate }}</mat-label>
                    <input matInput formControlName="coinYoutubeVideoID" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '70'">
                    <mat-label>{{ 'SMSS.COIN_EXTERNALURLLINK' | translate }}</mat-label>
                    <input matInput formControlName="coinExternalURLLink" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '80'">
                    <mat-label>{{ 'SMSS.COIN_VIDEOURL' | translate }}</mat-label>
                    <input matInput formControlName="coinVideoURL"
                        autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '80'">
                    <mat-label>{{ 'SMSS.COIN_VIDEOPOSTERIMGTYPE' | translate }}</mat-label>
                    <input matInput formControlName="coinVideoPosterImgType" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE1POSITION' | translate }}</mat-label>
                    <mat-select formControlName="coinImage1Position">
                        <mat-option value="0">
                            {{ 'SMSS.LOW' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'SMSS.HIGH' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'SMSS.CENTER' | translate }}
                        </mat-option>
                        <mat-option value="4">
                            {{ 'SMSS.FULLSCREEN' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE1SIZE' | translate }}</mat-label>
                    <mat-select formControlName="coinImage1Size">
                        <mat-option value="0">
                            200x200
                        </mat-option>
                        <mat-option value="1">
                            250x250
                        </mat-option>
                        <mat-option value="2">
                            320x75
                        </mat-option>
                        <mat-option value="3">
                            320x100
                        </mat-option>
                        <mat-option value="4">
                            320x200
                        </mat-option>
                        <mat-option value="5">
                            320x450
                        </mat-option>
                        <mat-option value="6">
                            720x200
                        </mat-option>
                        <mat-option value="7">
                            720x300
                        </mat-option>
                        <mat-option value="8">
                            720x450
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE1LINK' | translate }}</mat-label>
                    <input matInput formControlName="coinImage1Link" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE1TYPE' | translate }}</mat-label>
                    <input matInput formControlName="coinImage1Type" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE2POSITION' | translate }}</mat-label>
                    <mat-select formControlName="coinImage2Position">
                        <mat-option value="0">
                            {{ 'SMSS.LOW' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'SMSS.HIGH' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'SMSS.CENTER' | translate }}
                        </mat-option>
                        <mat-option value="4">
                            {{ 'SMSS.FULLSCREEN' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE2SIZE' | translate }}</mat-label>
                    <mat-select formControlName="coinImage2Size">
                        <mat-option value="0">
                            200x200
                        </mat-option>
                        <mat-option value="1">
                            250x250
                        </mat-option>
                        <mat-option value="2">
                            320x75
                        </mat-option>
                        <mat-option value="3">
                            320x100
                        </mat-option>
                        <mat-option value="4">
                            320x200
                        </mat-option>
                        <mat-option value="5">
                            320x450
                        </mat-option>
                        <mat-option value="6">
                            720x200
                        </mat-option>
                        <mat-option value="7">
                            720x300
                        </mat-option>
                        <mat-option value="8">
                            720x450
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE2LINK' | translate }}</mat-label>
                    <input matInput formControlName="coinImage2Link" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE2TYPE' | translate }}</mat-label>
                    <input matInput formControlName="coinImage2Type" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE3POSITION' | translate }}</mat-label>
                    <mat-select formControlName="coinImage3Position">
                        <mat-option value="0">
                            {{ 'SMSS.LOW' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'SMSS.HIGH' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'SMSS.CENTER' | translate }}
                        </mat-option>
                        <mat-option value="4">
                            {{ 'SMSS.FULLSCREEN' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE3SIZE' | translate }}</mat-label>
                    <mat-select formControlName="coinImage3Size">
                        <mat-option value="0">
                            200x200
                        </mat-option>
                        <mat-option value="1">
                            250x250
                        </mat-option>
                        <mat-option value="2">
                            320x75
                        </mat-option>
                        <mat-option value="3">
                            320x100
                        </mat-option>
                        <mat-option value="4">
                            320x200
                        </mat-option>
                        <mat-option value="5">
                            320x450
                        </mat-option>
                        <mat-option value="6">
                            720x200
                        </mat-option>
                        <mat-option value="7">
                            720x300
                        </mat-option>
                        <mat-option value="8">
                            720x450
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE3LINK' | translate }}</mat-label>
                    <input matInput formControlName="coinImage3Link" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_IMAGE3TYPE' | translate }}</mat-label>
                    <input matInput formControlName="coinImage3Type" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '70'">
                    <mat-label>{{ 'SMSS.COIN_EXTERNALURLPOSITION' | translate }}</mat-label>
                    <input matInput formControlName="coinExternalURLPosition" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="this.form.controls.coinType.value === '10'">
                    <mat-label>{{ 'SMSS.COIN_EXTERNALURLSIZE' | translate }}</mat-label>
                    <mat-select formControlName="coinExternalURLSize">
                        <mat-option value="0">
                            200x200
                        </mat-option>
                        <mat-option value="1">
                            250x250
                        </mat-option>
                        <mat-option value="2">
                            320x75
                        </mat-option>
                        <mat-option value="3">
                            320x100
                        </mat-option>
                        <mat-option value="4">
                            320x200
                        </mat-option>
                        <mat-option value="5">
                            320x450
                        </mat-option>
                        <mat-option value="6">
                            720x200
                        </mat-option>
                        <mat-option value="7">
                            720x300
                        </mat-option>
                        <mat-option value="8">
                            720x450
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <br>
        <div class="separator">{{ 'GENERAL.IMAGES' | translate }}</div>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.PORTALINTROIMAGEFROMFILEIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.portalIntroImageFromFileImg.value !== ''"
                        src="{{ this.form.controls.portalIntroImageFromFileImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.portalIntroImageFromFileImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="portalIntroImageUpload($event)"
                        accept=".png, .jpg, .jpeg" #portalIntroImageInput>
                    <button *ngIf="this.form.controls.portalIntroImageFromFileImg.value === ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2" (click)="portalIntroImageInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.portalIntroImageFromFileImg.value !== ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2 ml-1" (click)="removePortalIntroImage()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.PORTALINTROVIDEOPOSTERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.portalIntroVideoPosterImg.value !== ''"
                        src="{{ this.form.controls.portalIntroVideoPosterImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.portalIntroVideoPosterImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="portalIntroVideoPosterImgUpload($event)"
                        accept=".png, .jpg, .jpeg" #portalIntroVideoPosterImgInput>
                    <button *ngIf="this.form.controls.portalIntroVideoPosterImg.value === ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2" (click)="portalIntroVideoPosterImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.portalIntroVideoPosterImg.value !== ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2 ml-1" (click)="removePortalVideoPosterIntroImg()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.COINVIDEOPOSTERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.coinVideoPosterImg.value !== ''"
                        src="{{ this.form.controls.coinVideoPosterImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.coinVideoPosterImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="coinVideoPosterImgUpload($event)"
                        accept=".png, .jpg, .jpeg" #coinVideoPosterImgInput>
                    <button *ngIf="this.form.controls.coinVideoPosterImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="coinVideoPosterImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.coinVideoPosterImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeCoinVideoPosterIntroImg()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.TOPBANNERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.topBannerImg.value !== ''"
                        src="{{ this.form.controls.topBannerImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.topBannerImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="topBannerImgUpload($event)"
                        accept=".png, .jpg, .jpeg" #topBannerImgInput>
                    <button *ngIf="this.form.controls.topBannerImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="topBannerImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.topBannerImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeTopBannerImg()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
                <br>
                <mat-error *ngIf="imageError" class="text-center">
                    {{ 'ERROR.'+imageError | translate }}
                </mat-error>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.LEFTBANNERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.leftBannerImg.value !== ''"
                        src="{{ this.form.controls.leftBannerImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.leftBannerImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="leftBannerImgUpload($event)"
                        accept=".png, .jpg, .jpeg" #leftBannerImgInput>
                    <button *ngIf="this.form.controls.leftBannerImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="leftBannerImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.leftBannerImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeLeftBannerImg()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
                <br>
                <mat-error *ngIf="imageError" class="text-center">
                    {{ 'ERROR.'+imageError | translate }}
                </mat-error>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.RIGHTBANNERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.rightBannerImg.value !== ''"
                        src="{{ this.form.controls.rightBannerImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.rightBannerImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="rightBannerImgUpload($event)"
                        accept=".png, .jpg, .jpeg" #rightBannerImgInput>
                    <button *ngIf="this.form.controls.rightBannerImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="rightBannerImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.rightBannerImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeRightBannerImg()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
                <br>
                <mat-error *ngIf="imageError" class="text-center">
                    {{ 'ERROR.'+imageError | translate }}
                </mat-error>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.COINIMAGE1' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.coinImage1.value !== ''"
                        src="{{ this.form.controls.coinImage1.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.coinImage1.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="coinImage1Upload($event)"
                        accept=".png, .jpg, .jpeg" #coinImage1Input>
                    <button *ngIf="this.form.controls.coinImage1.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="coinImage1Input.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.coinImage1.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeCoinImage1()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
                <br>
                <mat-error *ngIf="imageError" class="text-center">
                    {{ 'ERROR.'+imageError | translate }}
                </mat-error>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.COINIMAGE2' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.coinImage2.value !== ''"
                        src="{{ this.form.controls.coinImage2.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.coinImage2.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="coinImage2Upload($event)"
                        accept=".png, .jpg, .jpeg" #coinImage2Input>
                    <button *ngIf="this.form.controls.coinImage2.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="coinImage2Input.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.coinImage2.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeCoinImage2()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
                <br>
                <mat-error *ngIf="imageError" class="text-center">
                    {{ 'ERROR.'+imageError | translate }}
                </mat-error>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.COINIMAGE3' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.coinImage3.value !== ''"
                        src="{{ this.form.controls.coinImage3.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.coinImage3.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="coinImage3Upload($event)"
                        accept=".png, .jpg, .jpeg" #coinImage3Input>
                    <button *ngIf="this.form.controls.coinImage3.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="coinImage3Input.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.coinImage3.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeCoinImage3()">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
                <br>
                <mat-error *ngIf="imageError" class="text-center">
                    {{ 'ERROR.'+imageError | translate }}
                </mat-error>
            </div>

        </mat-expansion-panel>
        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div class="mt-2">
            <button mat-button class="btn actionButton ml-2" type="button" id="updateButton"
                (click)="update()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
        </div>
    </form>
</div>
