import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'urban-property-dialog',
  templateUrl: './property-dialog.component.html',
  styleUrls: ['./property-dialog.component.scss']
})
export class PropertyDialogComponent implements OnInit {
  public isAdd : boolean = true;
  public hasOptions : boolean = false;

  public form: UntypedFormGroup = this.formBuilder.group({
    key: [{value: '', disabled: false}, Validators.required],
    value: [{value: '', disabled: false}, Validators.required],
  });

  public error: string | null;

  constructor(
    public dialogRef: MatDialogRef<PropertyDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        key: string,
        value: string
      }
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.key && this.data.value) {
      this.isAdd = false;
      this.form.controls.key.setValue(this.data.key);
      this.form.controls.value.setValue(this.data.value);
    }
  }

  addition(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        key: this.form.controls.key.value,
        value: this.form.controls.value.value
      });
    } else {
      this.error = 'ERROR_EMPTY';
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
