<h1 mat-dialog-title>{{ 'DEVICE.NEW_DEVICE' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'DEVICE.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'DEVICE.LATITUDE' | translate }}</mat-label>
            <input matInput formControlName="latitude" type="text" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'DEVICE.LONGITUDE' | translate }}</mat-label>
            <input matInput formControlName="longitude" type="text" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'DEVICE.PARENT' | translate }}</mat-label>
            <mat-select formControlName="parentSelected" (selectionChange)="updateParent()">
                <mat-option *ngFor="let parentDevice of parents" value={{parentDevice.Id}}>
                    <span class="urbanicon-{{ parentDevice.Model.Type?.Icon }}"></span>
                    {{parentDevice.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'DEVICE.BRAND' | translate }}</mat-label>
            <mat-select formControlName="brandSelected" (selectionChange)="updateModels()">
                <mat-option *ngFor="let brand of listBrands" value={{brand.Id}}>{{brand.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'DEVICE.MODEL' | translate }}</mat-label>
            <mat-select formControlName="modelSelected">
                <mat-option *ngFor="let model of modelsSelected" value={{model.Id}}>{{model.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="!form.valid">
            {{ 'DEVICE.FORM_ERROR' | translate }}
        </mat-error>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button type="submit" class="btn actionButton" (click)="addition()">{{ 'DEVICE.ADD' | translate }}</button>
    <button mat-button class="btn outlineActionButton ml-2" (click)="cancel()">{{ 'DEVICE.CANCEL' | translate }}</button>
</div>
