import { MenuList } from './../../../models/sidebar';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { MainSubscriptionsService } from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import { PassDataService } from "src/app/shared/services/pass-data/pass-data.service";
import { first } from 'rxjs/operators';
import { timer } from 'rxjs';


@Component({
  selector: "urban-menu-list",
  templateUrl: "./menu-list.component.html",
  styleUrls: ["./menu-list.component.scss"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      )
    ]),
    trigger("submenuOpen", [
      state("close", style({ height: "0", fontSize: "0", opacity: 0, visibility: 'hidden' })),
      state("open", style({  })),
      transition(
        "close <=> open",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      )
    ])
  ]
})
export class MenuListComponent implements OnChanges {
  public expanded: boolean = false;
  public active: boolean = false;

  @Input() item: MenuList;
  @Input() depth: number;
  @Input() isChild: boolean;
  @Input('expandedItem') expandedItemId: string;
  @Input('activeItem') activeItemId: string;
  @Input('submenuState') public submenuState: string = 'close';
  @Output('sidebarClose') closeSidebar = new EventEmitter<boolean>();
  @Output('clickedToggler') oneClicked = new EventEmitter<string>();
  @Input('sidebarOpened') public isSidebarOpened: boolean = true;

  constructor(
    public router: Router,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  onItemSelected() {
    if (this.item.Childs?.length > 0) {
      this.expanded = !this.expanded;
      this.submenuState = ( this.submenuState === 'close' ? 'open' : 'close');
    }
    else {
      let urlParts: string[] = this.router.url.split('/');
      let currentUrl: string = urlParts[urlParts.indexOf('main') + 1].split(';')[0];

      if (this.item.Url.startsWith('devices-table;type=')) {
        let devicetype = this.item.Url.split(';')[1];
        devicetype = devicetype.substring(devicetype.indexOf('=') + 1);

        if(currentUrl !== 'devices-table') {
          this.mainService.setNavigationInfoComand({ Type: devicetype, BackRoute: currentUrl });
          this.mainService.updateAllDataAndNavigateComand('devices-table');
          this.sidebarCloser();
        }
        else {
          this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
            if(!(navInfo.Type && navInfo.Type === devicetype)) {
              this.mainService.setNavigationInfoComand({ Type: devicetype, BackRoute: currentUrl });
            }
            this.mainService.updateAllDataAndNavigateComand('devices-table');
            this.sidebarCloser();
          });
        }
      }
      else {
        this.sidebarCloser();
        this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
          if (navInfo?.Id || currentUrl !== this.item.Url) {
            this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
          }

          this.router.navigate(['main/invisible-child'], { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl('main/' + this.item.Url);
          });
        });
      }
    }
  }

  public warnAboutSelection(): void {
    if (this.item.Childs?.length) {
      if(this.submenuState === 'open'){
        this.clickedEvent();
      }
      else if(this.submenuState === 'close') {
        this.clickedEvent('');
      }
    }
    else {
      this.clickedEvent();
    }
  }

  public sidebarCloser(): void {
    this.closeSidebar.emit(true);
  }

  public clickedEvent(itemId: string = this.item.Id): void {
    this.oneClicked.emit(itemId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.expandedItemId && changes.expandedItemId.currentValue !== changes.expandedItemId.previousValue){
      if (this.expanded && changes.expandedItemId.currentValue !== this.item.Id && !changes.expandedItemId.firstChange) {
        this.expanded = false;
        this.submenuState = 'close';
      }
      if (changes.expandedItemId.currentValue === this.item.Id) {
        this.expanded = true;
        this.submenuState = 'open';
      }
    }

    if(changes.activeItemId && changes.activeItemId.currentValue !== changes.activeItemId.previousValue){
      if (this.active && changes.activeItemId.currentValue !== this.item.Id && !changes.activeItemId.firstChange) {
        this.active = false;
      }
      if (changes.activeItemId.currentValue === this.item.Id) {
        this.active = true;
        timer(0).subscribe(() => {
          let sidebarItem: HTMLElement = document.getElementById(this.item.ParentId !== '' ? this.item.ParentId : this.item.Id);
          if(sidebarItem) {
            sidebarItem.scrollIntoView({ behavior: "smooth" });
          }
        });
      }
    }
  }
}
