import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './../../../shared/services/loader/loader.service';
import { ChartDescribedDataList, DescribedValue, HeatmapElement, ChartDataAndTicks } from './../../../shared/models/ChartDataList';
import { MainSubscriptionsService } from './../../../shared/services/main-subscriptions/main-subscriptions.service';
import { TransitAmountsPerType, TransitAmountsPerTypePerDevice, DeviceTransitAmount, TransitStay, TimeSpan } from './../../../shared/models/transit';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Device } from 'src/app/shared/models/device';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { Transition, TransitionsPerTrackerID } from 'src/app/shared/models/transit';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { ChartDataList } from 'src/app/shared/models/ChartDataList';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { TimedPosition } from 'src/app/shared/models/timedPosition';

@Component({
  selector: 'urban-transit-dashboard',
  templateUrl: './transit-dashboard.component.html',
  styleUrls: ['./transit-dashboard.component.scss']
})
export class TransitDashboardComponent implements OnInit, OnDestroy {
  public transitDevices: Device[] = [];
  public transitions: Transition[];
  public transitTypes: string[];
  public typeSelected: string = 'all';
  public filteredTransitions: Transition[];
  public startDate: number;
  public endDate: number;
  public transitDataSource: MatTableDataSource<TransitAmountsPerTypePerDevice>;
  public displayedColumns: string[];
  public transitDevicesAmounts: ChartDataList;
  public loadingData: boolean;
  public mapReady: boolean = false;
  public chartReady: boolean;
  public averageStaysColumnChartData: ChartDescribedDataList;
  public longestStaysColumnChartData: ChartDescribedDataList;
  public averageStaysColumnChartTicks: DescribedValue[];
  public longestStaysColumnChartTicks: DescribedValue[];
  private transitStays: TransitStay[];
  private devicesTransitAmounts: DeviceTransitAmount[];
  public transitHeatmapElements: HeatmapElement[];
  public heatmapMaxValue: number;
  public heatmapLastUpdate: number = null;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  private alertEventsDevicesInvolved: string;
  public alertPanelInput: AlertPanelInput;
  public isDomainAdmin: boolean = false;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private router: Router,
    private loader: LoaderService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.loader.disable();

    this.loadData();

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDomainAdmin = (res && res.some(x => x.Name === 'Administrators' || x.Name == 'Domain admin'));
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText] +
        ' ' + this.alertEventsDevicesInvolved
    };
  }

  public loadData(): void {
    let deviceEventsFeature: number;

    this.apiSync.initialize();
    this.apiSync.addFeatures(2);

    this.apiSync.waitFeaturesAndThen((checkValues: Boolean[], data: any) => {
      this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
        this.mapReady = mapLoading;
      });
      if (checkValues.every(value => value)) {
        this.transitions = this.mapToTransitions(data[deviceEventsFeature], this.transitDevices);
      }

      if (this.transitions.length > 0) {
        this.filteredTransitions = this.transitions;

        this.setTransitTypes();
        this.setTableData();
        this.setTransitAmounts();
        this.setBarData();
        this.setHeatmapData();
        this.setStaysChartData();
      }
      else {
        this.chartReady = true;
      }
      this.loadingData = false;
    });

    this.apiService.getDevicesByType('camera-bmp').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if (res && res.length > 0) {
        this.transitDevices = res
        this.apiSync.loadedFeature();
      }
      else {
        this.apiSync.failedFeature();
      }
    });

    let eventsRequest: DeviceEventRequest = { DeviceType: 'camera-bmp' };

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res);
      }
      else {
        this.apiSync.failedFeature();
      }
    });
  }

  public newSearch(selectedDates: SearchDates): void {
    this.chartReady = false;
    this.loadingData = true;

    this.startDate = selectedDates.startDate;
    this.endDate = selectedDates.endDate;

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'camera-bmp',
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        this.transitions = this.mapToTransitions(res, this.transitDevices);
      }

      if (this.transitions.length > 0) {
        this.filteredTransitions = this.transitions;

        this.setTransitTypes();
        this.setTableData();
        this.setTransitAmounts();
        this.setBarData();
        this.setHeatmapData();
        this.setStaysChartData();
      }
      else {
        this.last24hSearch = selectedDates.last24hSearch;
        this.chartReady = true;
      }
      this.loadingData = false;
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.chartReady = false;
    this.loadingData = true;

    let eventsRequest: DeviceEventLatestRequest = { DeviceType: 'camera-bmp' };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest, this.isAProperEvent)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
      .subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if(res && res.Devices?.length > 0) {
        this.transitions = this.mapToTransitions(res, this.transitDevices);
      }

      if(this.transitions.length > 0) {
        this.setLatestDates(res);

        this.filteredTransitions = this.transitions;

        this.setTransitTypes();
        this.setTableData();
        this.setTransitAmounts();
        this.setBarData();
        this.setHeatmapData();
        this.setStaysChartData();
      }
      else {
        this.chartReady = true;
      }
      this.loadingData = false;
    });
  }

  private setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  private resetAllData(): void {
    this.transitions = [];
    this.filteredTransitions = [];
    this.transitTypes = [];
    this.transitDevicesAmounts = {};
    this.devicesTransitAmounts = [];
    this.transitHeatmapElements = [];
    this.averageStaysColumnChartData = {};
    this.longestStaysColumnChartData = {};
    this.transitStays = [];
    this.transitDataSource = new MatTableDataSource<TransitAmountsPerTypePerDevice>();
    this.alertPanelInput = undefined;
    this.alertEventsDevicesInvolved = null;
  }

  private setTransitTypes(): void {
    this.transitTypes = ['all'];
    this.transitTypes.push(...this.transitions.map(x => x.Type).filter((v, i, a) => a.indexOf(v) === i));
  }

  private setTableData(): void {
    let typesAmountsPerDevice: TransitAmountsPerTypePerDevice[] = [];

    this.displayedColumns = ['device', ...this.transitTypes.slice(1).sort((a, b) => a.localeCompare(b))];

    this.transitDevices.forEach((device: Device) => {
      let amountsDefaults: TransitAmountsPerType = {};
      this.transitTypes.slice(1).forEach((type: string) => {
        amountsDefaults[type] = 0;
      });
      typesAmountsPerDevice.push({
        Device: device,
        Amounts: Object.assign(amountsDefaults)
      });
    });

    typesAmountsPerDevice.sort((a, b) => a.Device.Name.localeCompare(b.Device.Name));

    this.transitions.forEach((transition: Transition) => {
      typesAmountsPerDevice.find((typesAmounts: TransitAmountsPerTypePerDevice) => {
        return typesAmounts.Device.Id === transition.Device.Id
      }).Amounts[transition.Type]++;
    });

    this.transitDataSource = new MatTableDataSource<TransitAmountsPerTypePerDevice>(typesAmountsPerDevice);
  }

  private setTransitAmounts(): void {
    let transitAmounts: DeviceTransitAmount[] = [];

    if(this.filteredTransitions.length > 0) {
      this.transitDevices.forEach((device: Device) => {
        transitAmounts.push({
          Device: device,
          Amount: 0
        });
      });

      this.filteredTransitions.forEach((transition: Transition) => {
        transitAmounts.find((transitAmount: DeviceTransitAmount) => transitAmount.Device.Id === transition.Device.Id).Amount++;
      });

      transitAmounts.sort((a, b) => b.Amount - a.Amount);
    }
    this.devicesTransitAmounts = transitAmounts;
  }

  private setBarData(): void {
    let devicesAmounts: ChartDataList = {};

    if(this.filteredTransitions.length > 0) {
      this.devicesTransitAmounts.forEach((transitAmount: DeviceTransitAmount) => {
        devicesAmounts[transitAmount.Device.Name] = transitAmount.Amount;
      });
    }
    this.transitDevicesAmounts = devicesAmounts;
  }

  private setHeatmapData(): void {
    let heatMapElement: HeatmapElement[] = [];

    this.devicesTransitAmounts.forEach((el: DeviceTransitAmount) => {
      heatMapElement.push({
        Latitude: el.Device.Latitude,
        Longitude: el.Device.Longitude,
        Value: el.Amount
      });
    });

    //heatmap limit and last update
    if(!this.last24hSearch){ //case chosen dates
      this.heatmapMaxValue = Math.round((this.endDate - this.startDate) / 432);
      this.heatmapLastUpdate = this.endDate / 1000;
    }
    else {                      //case last 24h
      this.heatmapMaxValue = 200;
      this.heatmapLastUpdate = Math.round(Date.now() / 1000);
    }

    this.transitHeatmapElements = heatMapElement;
  }

  private setStaysChartData(): void {
    this.transitStays = this.calculateStays(this.filteredTransitions);

    let deviceAverageStays: TransitStay[] = this.calculateStaysAverages(this.transitDevices, this.transitStays);
    let deviceLongestStays: TransitStay[] = this.calculateLongestStays(this.transitDevices, this.transitStays);

    let chartDataAndTicks: ChartDataAndTicks = this.orderAndDescribeStays(deviceAverageStays);
    this.averageStaysColumnChartData = chartDataAndTicks.ChartDescribedData;
    this.averageStaysColumnChartTicks = chartDataAndTicks.ChartTicks;

    chartDataAndTicks = this.orderAndDescribeStays(deviceLongestStays);
    this.longestStaysColumnChartData = chartDataAndTicks.ChartDescribedData;
    this.longestStaysColumnChartTicks = chartDataAndTicks.ChartTicks;
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEventsDevices: EventsDeviceLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT';

    if (res?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.Devices,
        (event: EventLatest) => event.Level === 'Error');
    }

    if (res?.LatestBadEvents?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
        (event: EventLatest) => event.Level === 'Error', alertEventsDevices);
    }

    if (alertEventsDevices.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event));
      }

      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) && !this.isAProperEvent(event),
          alertEventsDevices);
      }

      if (alertEventsDevices.length > 0) {
        //Wrong body
        alertType = 'error';
        eventType = 'WRONG_BODY_EVENT';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.Devices,
            (event: EventLatest) => event.Level === 'Warning');
        }

        if (res?.LatestBadEvents?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
            (event: EventLatest) => event.Level === 'Warning', alertEventsDevices);
        }

        if (alertEventsDevices.length > 0) {
          alertType = 'warning';
          eventType = 'WARNING';
        }
      }
    }

    if (alertEventsDevices.length > 0) {
      let alertEventsNumber: number = alertEventsDevices.reduce((sum, device) => sum + device.Events.length, 0);
      let errorPhrases: string[] = [
        'ALERT_PANEL.' + eventType + (alertEventsNumber > 1 ? 'S' : '') + '_DETECTED',
        'ALERT_PANEL.DEVICE' + (alertEventsDevices.length > 1 ? 'S' : '') + '_INVOLVED'
      ];

      this.alertEventsDevicesInvolved = alertEventsDevices
        .map(device => device.Name).join(', ');

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEventsNumber.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private calculateStaysAverages(transitDevices: Device[], transitStays: TransitStay[]): TransitStay[] {
    let transitStaysAverages: TransitStay[] = [];

    if (transitDevices?.length > 0 && transitStays?.length > 0) {
      let transitAverage: Record<string, number> = {};
      let counters: Record<string, number> = {};

      transitDevices.forEach((device: Device) => {
        transitAverage[device.Name] = 0;
        counters[device.Name] = 0;
      });

      transitStays.forEach((transit: TransitStay) => {
        transitAverage[transit.Device.Name] += transit.Stay.TotalMilliseconds;
        counters[transit.Device.Name]++;
      });

      transitDevices.forEach((device: Device) => {
        transitAverage[device.Name] = Math.round(transitAverage[device.Name] / counters[device.Name]);
      })

      transitDevices.forEach((device: Device) => {
        let timeSpan: TimeSpan = this.getTimeSpan(transitAverage[device.Name]);
        transitStaysAverages.push({
          Device: device,
          TrackerId: null,
          Stay: timeSpan,
          Start: null,
          End: null
        });
      });
    }

    return transitStaysAverages;
  }

  private calculateLongestStays(transitDevices: Device[], transitStays: TransitStay[]): TransitStay[] {
    let deviceLongestStays: TransitStay[] = [];

    if (transitDevices?.length > 0 && transitStays?.length > 0) {
      let transitMaxStay: Record<string, TimeSpan> = {}

      transitDevices.forEach((device: Device) => {
        transitMaxStay[device.Name] = {
          Hours: 0,
          Minutes: 0,
          Seconds: 0,
          Milliseconds: 0,
          TotalMilliseconds: 0
        }
      });

      transitStays.forEach((transit: TransitStay) => {
        if (transit.Stay.TotalMilliseconds > transitMaxStay[transit.Device.Name].TotalMilliseconds) {
          transitMaxStay[transit.Device.Name] = transit.Stay;
        }
      });

      transitDevices.forEach((device: Device) => {
        deviceLongestStays.push({
          Device: device,
          TrackerId: null,
          Stay: transitMaxStay[device.Name],
          Start: null,
          End: null
        });
      });
    }

    return deviceLongestStays;
  }

  private getTimeSpan(stayInMilliseconds: number): TimeSpan{
    const msIn1Hour: number = 1000 * 60 * 60, msIn1Minute = 1000 * 60, msIn1Second = 1000;
    let hours: number = Math.floor(stayInMilliseconds / msIn1Hour);
    let remainingMilliseconds: number = stayInMilliseconds - (hours * msIn1Hour);
    let minutes: number = Math.floor(remainingMilliseconds / msIn1Minute);
    remainingMilliseconds -= (minutes * msIn1Minute);
    let seconds: number = Math.floor((remainingMilliseconds) / msIn1Second);
    remainingMilliseconds -= (seconds * msIn1Second);

    let stay: TimeSpan = {
      Hours: hours,
      Minutes: minutes,
      Seconds: seconds,
      Milliseconds: remainingMilliseconds,
      TotalMilliseconds: stayInMilliseconds
    }

    return stay;
  }

  private getCertainEventsDevices(
    devices: EventsDeviceLatest[],
    eventCheck: (event: EventLatest) => boolean,
    initialArray: EventsDeviceLatest[] = []): EventsDeviceLatest[] {
    return devices.reduce<EventsDeviceLatest[]>((accumulator, currentDevice) => {
      let alertEvents: EventLatest[] = currentDevice.Events.filter((event: EventLatest) => eventCheck(event));
      let deviceIndex: number = accumulator.findIndex(device => device.Id === currentDevice.Id);
      if (deviceIndex !== -1) {
        accumulator[deviceIndex].Events.push(...alertEvents.filter(eventToAdd =>
          accumulator[deviceIndex].Events.find(event => event.Id === eventToAdd.Id) === undefined
        ));
        return accumulator;
      }
      else {
        return alertEvents.length > 0 ? [...accumulator, { ...currentDevice, Events: alertEvents } ] : accumulator;
      }
    }, initialArray);
  }

  private isAProperEvent(event: DeviceEventsEvent | EventLatest): boolean {
    return event.Body.detections?.length > 0 &&
      event.Body.detections.every(detection =>
        ['attributes', 'type'].every(
          (key:string) => Object.keys(detection).includes(key)
        ) &&
        ['trackerID', 'timestamp', 'x_position', 'y_position'].every(
          (key: string) => Object.keys(detection.attributes).includes(key)
        )
      );
  }

  private mapToTransitions(res: DeviceEventsResponse, transitDevices: Device[]): Transition[] {
    let transitions: Transition[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let transitDevice: Device;
      if((transitDevice = transitDevices.find(oneDevice => oneDevice.Id === device.Id)) !== undefined) {
        let transitionsList: TransitionsPerTrackerID = {};
        device.Events.forEach((event: DeviceEventsEvent) => {
          let body = event.Body;
          if (body.detections?.length > 0) {
            body.detections.forEach(detection => {
              if (
                ['attributes', 'type'].every((key:string) => Object.keys(detection).includes(key)) &&
                ['trackerID', 'timestamp', 'x_position', 'y_position'].every(
                  (key: string) => Object.keys(detection.attributes).includes(key)
                )
              ) {
                let trackerId: number = detection.attributes.trackerID;
                if(transitionsList[trackerId] === undefined) {
                  transitionsList[trackerId] = {
                    Type: detection.type,
                    Positions: []
                  }
                }

                let position: TimedPosition = {
                  Created: detection.attributes.timestamp,
                  X: detection.attributes.x_position,
                  Y: detection.attributes.y_position
                }
                transitionsList[trackerId].Positions.push(position);
              }
            });
          }
        });

        Object.keys(transitionsList).forEach((trackerId: string) => {
          let transition: Transition = {
            Device: transitDevice,
            TrackerId: +trackerId,
            Type: transitionsList[+trackerId].Type,
            Positions: transitionsList[+trackerId].Positions
          };
          transitions.push(transition);
        });
      }
    });

    return transitions;
  }

  private calculateStays(transitions: Transition[]): TransitStay[] {
    let transitsStays: TransitStay[] = []
    transitions.forEach((transition: Transition) => {
      let stayInMilliseconds: number = transition.Positions[0].Created - transition.Positions[transition.Positions.length - 1].Created;
      const msIn1Hour: number = 1000 * 60 * 60, msIn1Minute = 1000 * 60, msIn1Second = 1000;
      let hours: number = Math.floor(stayInMilliseconds / msIn1Hour);
      let remainingMilliseconds: number = stayInMilliseconds - (hours * msIn1Hour);
      let minutes: number = Math.floor(remainingMilliseconds / msIn1Minute);
      remainingMilliseconds -= (minutes * msIn1Minute);
      let seconds: number = Math.floor((remainingMilliseconds) / msIn1Second);
      remainingMilliseconds -= (seconds * msIn1Second);

      let stay: TimeSpan = {
        Hours: hours,
        Minutes: minutes,
        Seconds: seconds,
        Milliseconds: remainingMilliseconds,
        TotalMilliseconds: stayInMilliseconds
      }
      let transitStay: TransitStay = {
        Device: transition.Device,
        TrackerId: transition.TrackerId,
        Stay: stay,
        Start: transition.Positions[transition.Positions.length - 1].Created,
        End: transition.Positions[0].Created
      }

      transitsStays.push(transitStay);
    });

    return transitsStays;
  }

  private orderAndDescribeStays(stays: TransitStay[]): ChartDataAndTicks {
    let orderedAndDescribedStays: ChartDescribedDataList = {};
    let chartVAxisTicks: DescribedValue[] = [];
    const staysLength: number = stays.length;

    if(staysLength > 0) {
      stays.sort((a, b) => b.Stay.TotalMilliseconds - a.Stay.TotalMilliseconds);
      chartVAxisTicks = this.getMostSensibleTimeTicks(stays[0].Stay);

      if(staysLength > 10) {
        stays = stays.slice(0, 10);
      }
      stays.forEach((stay: TransitStay) => {
        let stayPeriod: TimeSpan = stay.Stay;
        orderedAndDescribedStays[stay.Device.Name] = {
          Value: stayPeriod.TotalMilliseconds,
          Description: this.fromTimeSpanToString(stayPeriod)
        };
      });
    }

    let chartDataAndTicks: ChartDataAndTicks = {
      ChartDescribedData: orderedAndDescribedStays,
      ChartTicks: chartVAxisTicks
    }

    return chartDataAndTicks;
  }

  private fromTimeSpanToString(period: TimeSpan): string {
    let periodString: string = period.Hours < 10 ? '0' : '';
    periodString += period.Hours + ':';

    periodString += period.Minutes < 10 ? '0' : '';
    periodString += period.Minutes + ':';

    periodString += period.Seconds < 10 ? '0' : '';
    periodString += period.Seconds;

    if(periodString === '00:00:00') {
      periodString += '.'+period.Milliseconds;
    }

    return periodString;
  }

  private getMostSensibleTimeTicks(timePeriod: TimeSpan): DescribedValue[] {
    let ticks: DescribedValue[] = [];
    let step: number, maxCounter: number, maxTick: number, msMultiplier: number, unit: string;
    const msIn1Hour: number = 1000 * 60 * 60, msIn1Minute = 1000 * 60, msIn1Second = 1000;

    if(timePeriod.Hours > 50) {
      msMultiplier = msIn1Hour;
      let roundedHours: number = Math.ceil(timePeriod.Hours / 10) * 10;
      step = roundedHours / 10;
      maxCounter = roundedHours + step;
      unit = 'h';
    }
    else if(timePeriod.Hours > 10) {
      msMultiplier = msIn1Hour;
      step = 5;
      maxCounter = timePeriod.Hours + step;
      unit = 'h';
    }
    else if(timePeriod.Hours > 0) {
      msMultiplier = msIn1Hour;
      step = 1;
      maxCounter = timePeriod.Hours + step;
      unit = 'h';
    }
    else if(timePeriod.Minutes > 30) {
      msMultiplier = msIn1Minute;
      step = 10;
      maxCounter = 50;
      unit = 'm';
      ticks.push({ Value: 1000, Description: '1h' });
    }
    else if(timePeriod.Minutes > 10) {
      msMultiplier = msIn1Minute;
      step = 5;
      maxCounter = timePeriod.Minutes + step
      unit = 'm';
    }
    else if(timePeriod.Minutes > 0) {
      msMultiplier = msIn1Minute;
      step = 1
      maxCounter = timePeriod.Minutes + step;
      unit = 'm';
    }
    else if(timePeriod.Seconds > 30) {
      msMultiplier = msIn1Second;
      step = 10;
      maxCounter = 50;
      unit = 's';
      ticks.push({ Value: 1000, Description: '1m' });
    }
    else if(timePeriod.Seconds > 10) {  //qui per i secondi
      msMultiplier = msIn1Second;
      step = 5;
      maxCounter = timePeriod.Seconds + step
      unit = 's';
    }
    else if(timePeriod.Seconds > 0) {
      msMultiplier = msIn1Second;
      step = 1
      maxCounter = timePeriod.Seconds + step;
      unit = 's';
    }
    else {
      msMultiplier = 1;
      step = 100;
      maxCounter = 900;
      unit = 'ms';
      ticks.push({ Value: 1000, Description: '1s' });
    }

    let time: number;
    for(time = step; time <= maxCounter; time += step) {
      ticks.push({ Value: time * msMultiplier, Description: time+unit });
    }
    maxTick = ticks[ticks.length - 1].Value;

    return ticks;
  }

  filterTransitions(type: string): void {
    if(this.transitions?.length > 0) {
      if(this.transitTypes.includes(type)) {
        if(type === 'all') {
          this.filteredTransitions = this.transitions;
        }
        else {
          this.filteredTransitions = this.transitions.filter((transition: Transition) => transition.Type === type);
        }
      }
      this.setTransitAmounts();
      this.setBarData();
      this.setHeatmapData();
      this.setStaysChartData();
    }
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.transitions.length === 0) {
      this.chartReady = true;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceDetails(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: 'transit-dashboard' });
    this.router.navigate(['main/transit-detail']);
  }

  public goToDomainEvents(): void {
    if (this.isDomainAdmin) {
      this.mainService.setNavigationInfoComand({ BackRoute: 'transit-dashboard' });
      this.router.navigate(['main/domain-events']);
    }
  }

  ngOnDestroy(): void {
      this.apiSync.abort();
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
      this.loader.enable();
  }
}
