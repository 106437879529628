import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MainState } from '../../store/main/main.reducer';
import { LoaderService } from './loader/loader.service';

import { setError as MainActionSetError }  from '../../store/main/main.actions';
import { TaskExecution, TaskSchedule, TaskScheduleAddRequest, TaskScheduleExecutionsRequest, TaskScheduleUpdateRequest } from '../models/taskScheduleElements';

@Injectable({
  providedIn: 'root'
})
export class TaskScheduleApiService {

  constructor(private http: HttpClient, private readonly loaderService: LoaderService, private store: Store<MainState>) { }

  public getTaskScheduleList(deviceId?: string): Observable<TaskSchedule[]> {
    return this.http.get(
      `uv.taskschedule.list.api?id=${deviceId}`
    ).pipe(
      map((res: any) => res.Schedules),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActionSetError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getTaskScheduleDetail(tsId: string): Observable<TaskSchedule> {
    return this.http.get(
      `uv.taskschedule.get.api?id=${tsId}`
    ).pipe(
      map((res: any) => res.TaskSchedule),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActionSetError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public getTaskScheduleExecutionsList(execsRequest: TaskScheduleExecutionsRequest): Observable<TaskExecution[]> {
    return this.http.post(
      `uv.taskschedule.execution.list.api`,
      execsRequest
    ).pipe(
      map((res: any) => res.Executions),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActionSetError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public addTaskSchedule(addRequest: TaskScheduleAddRequest): Observable<any> {
    return this.http.post(
      `uv.taskschedule.add.api`,
      addRequest
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActionSetError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public updateTaskSchedule(updateRequest: TaskScheduleUpdateRequest): Observable<any> {
    return this.http.post(
      `uv.taskschedule.update.api`,
      updateRequest
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActionSetError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

  public deleteDeviceTaskSchedule(taskId: string): Observable<any> {
    return this.http.get(
      `uv.taskschedule.delete.api?id=${taskId}`
    ).pipe(
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActionSetError({ error: err?.error?.Message ? err.error.Message : 'customError' })));
      })
    )
  }

}

