import { PathsChartLegend } from './../../models/ChartDataList';
import { Component, ElementRef, HostBinding, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Path } from '../../models/ChartDataList';

@Component({
  selector: 'urban-paths-chart',
  templateUrl: './paths-chart.component.html',
  styleUrls: ['./paths-chart.component.scss']
})
export class PathsChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges  {

  @Input('passedDataList') private passedDataList: Path[];
  @Input('darkThemeActive') public darkThemeActive: boolean;
  @Input('pathsChartTitle') public chartTitle: string;
  @Input('passedSubtitle') public chartSubtitle: string = null;
  @Input('selectedType') public selectedType: string;
  @Input('pathsPrecision') public pathsAmount: number = 10;
  @Input('amountLimit') private maxAmount: number;
  @Input('chartWidth') public width: number = 10000;
  @Input('chartHeight') public height: number = 10000;
  @Input('nestedChart') public nested: boolean = false;
  @Input('chartLegend') public legend: PathsChartLegend = {
    Transparent: 'Transparent',
    Opaque: 'Opaque',
    Thin: 'Thin',
    Thick: 'Thick'
  }

  @HostBinding('style.--container-height') private containerHeight: string;
  @HostBinding('style.--container-slope') private containerSlope: number;

  @ViewChild('pathsContainer') private container: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tryDrawChart();
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public noDataOnChart: boolean = false;
  public arrowsFormatted: Path[] = [];

  constructor() { }

  ngOnInit(): void {
    this.containerSlope = this.height / this.width;
    if (this.width && this.height && this.height > 0 && this.width > this.height) {
      this.containerHeight = (100 * (this.containerSlope)) + '%';
    }
    else {
      this.containerHeight = '100%';
    }
  }

  ngAfterViewInit(): void {
    this.tryDrawChart();
  }

  tryDrawChart(): void {
    if(this.container !== undefined && this.container.nativeElement.offsetWidth > 0) {
      this.drawChart();
    }
  }

  private drawChart(): void {
    // this.arrows.forEach((arrow) => {
    //   if(arrow.PositionA.Y >= arrow.PositionB.Y - 5 && arrow.PositionA.Y <= arrow.PositionB.Y + 5) {
    //       let space: number = 1;
    //       if (arrow.Type === 0) {
    //         space++;
    //       } else if (arrow.Type === 2) {
    //         space--;
    //       }

    //       let newArrow: Path = {
    //         PositionA: {X: arrow.PositionA.X, Y: arrow.PositionA.Y + space },
    //         PositionB: {X: arrow.PositionB.X, Y: arrow.PositionB.Y + space },
    //         OneWay: arrow.OneWay,
    //         Amount: arrow.Amount,
    //         Importance: this.setImportance(arrow.Importance),
    //         Type: arrow.Type
    //       }
    //     this.arrowsFormatted.push(newArrow);
    //   } else if (arrow.PositionA.X >= arrow.PositionB.X - 5 && arrow.PositionA.X <= arrow.PositionB.X + 5) {
    //     let space: number = 1;
    //     if (arrow.Type === 0) {
    //       space++;
    //     } else if (arrow.Type === 2) {
    //       space--;
    //     }

    //     let newArrow: Path = {
    //       PositionA: {X: arrow.PositionA.X + space, Y: arrow.PositionA.Y },
    //       PositionB: {X: arrow.PositionB.X + space, Y: arrow.PositionB.Y },
    //       OneWay: arrow.OneWay,
    //       Amount: arrow.Amount,
    //       Importance: this.setImportance(arrow.Importance),
    //       Type: arrow.Type
    //     }
    //     this.arrowsFormatted.push(newArrow);
    //   } else {
    //     this.arrowsFormatted.push(arrow);
    //   }
    // });
    this.arrowsFormatted = [];
    let maxArrowAmount: number;

    if (this.maxAmount === undefined) {
      maxArrowAmount = Math.max(...this.passedDataList.filter(arrow => arrow.Type === this.selectedType).map(max => max.Amount), 0);
    }
    else {
      maxArrowAmount = this.maxAmount;
    }
    let maxArrowWidth: number = this.container.nativeElement.offsetWidth * 3 / 10 / this.pathsAmount;

    this.passedDataList.forEach((arrow) => {
      if (this.selectedType === arrow.Type) {
        let newAmount: number = Math.ceil(arrow.Amount * maxArrowWidth / maxArrowAmount);

        let offsetX: number = 0;
        let offsetY: number = 0;
        let offsetPercentage = 2 + newAmount / 18;

        if (arrow.PositionA.X < arrow.PositionB.X) {
          offsetX = arrow.Slope === 0 ? offsetPercentage : offsetPercentage / Math.sqrt(1 + Math.pow(arrow.Slope, 2))
        } else if (arrow.PositionA.X > arrow.PositionB.X) {
          offsetX = arrow.Slope === 0 ? -offsetPercentage : -offsetPercentage / Math.sqrt(1 + Math.pow(arrow.Slope, 2))
        }

        if (arrow.PositionA.Y < arrow.PositionB.Y) {
          offsetY = arrow.Slope ? offsetX * arrow.Slope : offsetPercentage;
        } else if (arrow.PositionA.Y > arrow.PositionB.Y) {
          offsetY = arrow.Slope ? offsetX * arrow.Slope : -offsetPercentage;
        }

        let newArrow: Path = {
          PositionA: {X: arrow.PositionA.X + offsetX, Y: arrow.PositionA.Y + offsetY},
          PositionB: {X: arrow.PositionB.X - offsetX, Y: arrow.PositionB.Y - offsetY},
          Slope: arrow.Slope ? arrow.Slope : null,
          OneWay: arrow.OneWay,
          Amount: newAmount,
          Importance: this.setArrowOpacity(arrow.Importance),
          Type: arrow.Type
        }
        this.arrowsFormatted.push(newArrow);
      }
    });
  }

  setArrowOpacity(importance: number): number {
    let arrowOpacity: number;
    arrowOpacity = +((importance * 0.6) + 0.3).toFixed(2);

    return arrowOpacity;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['passedDataList']) {
      const updatedDataList = changes['passedDataList'];
      if (updatedDataList.currentValue !== updatedDataList.previousValue && updatedDataList.firstChange == false) {
        this.tryDrawChart();
      }
    }
    if (changes['selectedType']) {
      const selectedType = changes['selectedType'];
      if (selectedType.currentValue !== selectedType.previousValue && selectedType.firstChange == false) {
        this.tryDrawChart();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
