<div class="container-fluid p-0">
    <mat-dialog-content>
        <div class="d-flex notificationHeader">
            <i class="urbanicon-info"></i>
            <p class="paragraphText">{{ 'NOTIFICATION.NEW_NOTIFICATION' | translate | uppercase }}</p>
        </div>

        <div class="notificationContent">
            <p class="paragraphText mb-3">{{data.singleNotification.Payload}}</p>
        </div>
    
        <p class="paragraphText mb-4">{{ data.singleNotification.Created * 1000 | date : 'short' }}</p>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button mat-button type="button" class="btn outlineActionButton w-100" [matDialogClose]="data.singleNotification?.Id">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</div>