<div class="mr-3 float-right" *ngIf="passedLastUpdate && passedLastUpdate !== undefined">
    {{ 'HEATMAP.LAST_UPDATE' | translate }} {{ passedLastUpdate*1000 | date : 'short' }}
</div>

<google-map *ngIf="!refreshMap" [options]="mapOptions" width="100%" height="600" (mapDragend)="onViewChanged()" (zoomChanged)="onZoomChanged()"
    (mapDragstart)="onMapDragStart()">
    <map-marker #marker="mapMarker" *ngFor="let myMarker of markers; let i = index" [position]="myMarker.position"
        [options]="myMarker.options" [label]="myMarker.label" [title]="myMarker.title"
        (mapClick)="openInfoWindow(marker, i, myMarker.info)">
        <map-info-window>
            <div class="text-center p-2">
                <p style="font-size: large;"> {{ 'ROAD_RISK.SEVERITY_RATE' | translate }} </p>
                <hr class="mb-3 mt-2">
                <p class="mr-2">{{ myMarker.info }}</p>
            </div>
        </map-info-window>
    </map-marker>
</google-map>

<div *ngIf="refreshMap" style="width:'100%'; height:'600px';">

</div>
