<div class="app-splash-screen" [ngStyle]="{ left: windowWidth, opacity: opacityChange, transition: splashTransition }"
    *ngIf="showSplash">
    <div class="app-splash-inner">
        <div id="app-logo" class="app-logo"></div>
        <div *ngIf="!this.subscribedThemeTitle" class="app-label">Urbanvision</div>
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</div>
